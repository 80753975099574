import React, { FunctionComponent, HTMLAttributes, useContext } from 'react';
import styled, { ThemeContext } from 'styled-components';

const StyledSpinner = styled.div<{
  fillColor: string;
  backgroundColor: string;
  size: number;
  thickness: number;
}>`
  width: ${(props) => props.size}px;
  height: ${(props) => props.size}px;
  border: ${(props) => props.thickness}px ${(props) => props.backgroundColor} solid;
  border-top: ${(props) => props.thickness}px ${(props) => props.fillColor} solid;
  border-radius: 50%;
  margin: 0px auto;
  animation: simple-spinner-spin 0.6s infinite linear;

  @keyframes simple-spinner-spin {
    0% {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
`;

const CenteredSpinnerWrapper = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;
`;

interface SpinnerProps extends HTMLAttributes<HTMLDivElement> {
  size?: number;
  fillColor?: string;
  backgroundColor?: string;
  thickness?: number;
}

const Spinner: FunctionComponent<SpinnerProps> = ({
  size = 25,
  fillColor,
  backgroundColor,
  thickness
}) => {
  const themeContext = useContext(ThemeContext);
  fillColor = fillColor ?? themeContext.primary;
  backgroundColor = backgroundColor ?? themeContext.border;
  thickness = thickness ?? 2;
  return (
    <StyledSpinner
      size={size}
      fillColor={fillColor}
      backgroundColor={backgroundColor}
      thickness={thickness}
    />
  );
};

export const CenteredSpinner: FunctionComponent<SpinnerProps> = ({
  size = 25,
  fillColor,
  backgroundColor,
  thickness,
  ...props
}) => {
  const themeContext = useContext(ThemeContext);
  fillColor = fillColor ?? themeContext.primary;
  backgroundColor = backgroundColor ?? themeContext.border;
  thickness = thickness ?? 2;
  return (
    <CenteredSpinnerWrapper {...props}>
      <Spinner
        size={size}
        fillColor={fillColor}
        backgroundColor={backgroundColor}
        thickness={thickness}
      />
    </CenteredSpinnerWrapper>
  );
};

export default Spinner;
