import React, { FunctionComponent } from 'react';
import {
  Column,
  FormFieldWrapper,
  FormModal,
  HorizontalSeparator,
  InputFormikField,
  PageDetails,
  RadioButtonGroupFormikField,
  Row,
  SelectFormikField,
} from 'shared/components';
import * as Yup from 'yup';
import { useNotification } from 'shared/components/notifications';
import { useGetDeal, useGetStates, useUpdateDeal } from 'shared/hooks/api';
import { diff } from 'shared/utils/deepDiff';
import { UpdateDealInput } from 'shared/hooks/api/graphql/generated';
import { PartialNullable } from 'shared/utils/utilityTypes';

interface DealLocationModalProps {
  onClose: () => void;
  onSuccess: () => void;
  oppId: number;
}

type Option = {
  value: number;
  label: string;
};

type BooleanOption = {
  value: boolean;
  label: string;
};

type DealLocationFormValues = {
  usesCoordinates: BooleanOption;
  city?: string | null;
  state?: Option | null;
  address1?: string | null;
  address2?: string | null;
  zipcode?: string | null;
  latitude?: number | null;
  longitude?: number | null;
};

export const dealLocationFormSchema: Yup.ObjectSchema<DealLocationFormValues> = Yup.object()
  .shape({
    usesCoordinates: Yup.object()
    .shape({
      value: Yup.boolean().required(),
      label: Yup.string().required(),
    })
    .required()
    .label("Uses Coordinates"),
    city: Yup.string().nullable().label("City"),
    state: Yup.object()
      .shape({
        value: Yup.number().required(),
        label: Yup.string().required(),
      })
      .label('State')
      .nullable(),
    address1: Yup.string().nullable().label("Address 1"),
    address2: Yup.string().nullable().label("Address 2"),
    zipcode: Yup.string().nullable().label("Zip Code"),
    latitude: Yup.number().nullable().label("Latitude"),
    longitude: Yup.number().nullable().label("Longitude"),
  })
  .required();

const DealLocationModal: FunctionComponent<DealLocationModalProps> = ({
  onClose,
  onSuccess,
  oppId,
}) => {
  const notify = useNotification();

  const { data, refetch } = useGetDeal(Number(oppId), {
    enabled: false,
  });

  const { data: statesData, refetch: refetchStates } = useGetStates({
    manual: true,
  });

  const initialValues: PartialNullable<DealLocationFormValues> = {
    usesCoordinates: data?.deal?.usesCoordinates ? { value: true, label: 'Coordinates' } : { value: false, label: 'Address' },
    address1: data?.deal?.address1,
    address2: data?.deal?.address2,
    city: data?.deal?.city,
    state: data?.deal?.state,
    zipcode: data?.deal?.zipcode,
    longitude: data?.deal?.longitude,
    latitude: data?.deal?.latitude,
  }

  const [updateDeal, { status }] = useUpdateDeal();

  return (
    <FormModal<DealLocationFormValues>
      title={'Change Location'}
      submitTitle={'Save'}
      initialValues={initialValues}
      handleSubmit={async (form, resetForm) => {
        const changes = diff(initialValues, form);
        const input: UpdateDealInput = {
          id: oppId,
          usesCoordinates: changes.usesCoordinates?.value,
          address1: changes.address1,
          address2: changes.address2,
          city: changes.city,
          fkState: changes.state?.value,
          zipcode: changes.zipcode,
          latitude: changes.latitude,
          longitude: changes.longitude,
        }
        try {
          const result = await updateDeal(input);
          if (result.errors) {
            notify({
              title: 'Oops!',
              variant: 'danger',
              message:
                'An error occurred while updating the details of this deal.',
            });
          } else {
            notify({
              title: 'Deal Location Updated!',
              variant: 'success',
            });
            onSuccess();
            onClose();
          }
        } catch (error) {
          notify({
            title: 'Oops!',
            variant: 'danger',
            message:
              'An error occurred while updating the details of this deal.',
          });
        }
      }}
      isWorking={status == 'loading'}
      onClose={onClose}
      validationSchema={dealLocationFormSchema}
    >
      {(props) => (
        <PageDetails>
          <FormFieldWrapper>
            <RadioButtonGroupFormikField
              name={'usesCoordinates'}
              options={[
                { value: false, label: 'Address' },
                { value: true, label: 'Coordinates' },
              ]}
            />
          </FormFieldWrapper>
          <HorizontalSeparator />
          <FormFieldWrapper>
            <Row gap={10}>
              {!props.values.usesCoordinates?.value ? (
                <React.Fragment>
                  <Column>
                    <InputFormikField
                      key={'address1'}
                      name={'address1'}
                      placeholder={'Address Line 1'}
                    />
                  </Column>
                  <Column>
                    <InputFormikField
                      key={'address2'}
                      name={'address2'}
                      placeholder={'Address Line 2'}
                    />
                  </Column>
                  <Column span={4}>
                    <InputFormikField
                      key={'city'}
                      name={'city'}
                      placeholder={'City'}
                    />
                  </Column>
                  <Column span={4}>
                    <SelectFormikField
                      key={'state'}
                      name={'state'}
                      options={statesData?.states || []}
                      loadOptions={refetchStates}
                      placeholder={'State'}
                    />
                  </Column>
                  <Column span={4}>
                    <InputFormikField
                      key={'zipcode'}
                      name={'zipcode'}
                      placeholder={'Zip Code'}
                    />
                  </Column>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <Column span={6}>
                    <InputFormikField
                      key={'latitude'}
                      name={'latitude'}
                      placeholder={'Latitude'}
                    />
                  </Column>
                  <Column span={6}>
                    <InputFormikField
                      key={'longitude'}
                      name={'longitude'}
                      placeholder={'Longitude'}
                    />
                  </Column>
                </React.Fragment>
              )}
            </Row>
          </FormFieldWrapper>
        </PageDetails>
      )}
    </FormModal>
  );
};

export default DealLocationModal;
