import CreateFormikFieldFirmModal from 'App/Organization/ContactManagement/AddressBook/shared/CreateFormikFieldFirmModal';
import { FormikProps } from 'formik';
import React, { FunctionComponent, useState } from 'react';
import { createFilter } from 'react-select';
import { Dialog } from 'shared/components';
import { SelectCreatableFieldProps, SelectCreatableFormikField } from 'shared/components/form/select/Select';
import { useNotification } from 'shared/components/notifications';
import { useCreateFirm } from 'shared/hooks/api';
import { CreateFirmInput } from 'shared/hooks/api/graphql/generated';
import { createQueryParamModalHelpers } from 'shared/utils/queryParamModal';

interface SelectCreatableFormikFirmFieldProps extends SelectCreatableFieldProps {
  form: FormikProps<any>;
  refresh: () => void;
  pipelineId: number;
}

const SelectCreatableFormikFirmField: FunctionComponent<SelectCreatableFormikFirmFieldProps> = ({ form, refresh, name, pipelineId, ...props }) => {
  const [newFirmValue, setNewFirmValue] = useState<CreateFirmInput | undefined>(
    undefined
  );
  const newFirmModalHelpers = createQueryParamModalHelpers('new-firm');

  return (
    <React.Fragment>
      <SelectCreatableFormikField
        filterOption={createFilter({ ignoreAccents: false })}
        name={name}
        onCreateOption={(value) => {
          const input: CreateFirmInput = {
            name: value,
            fkPipeline: pipelineId,
          };
          setNewFirmValue(input);
          newFirmModalHelpers.open();
        }}
        {...props}
      />
      {newFirmModalHelpers.isOpen() && (
        <CreateFormikFieldFirmModal
          onClose={newFirmModalHelpers.close}
          onSuccess={() => { newFirmModalHelpers.close(); }}
          name={newFirmValue?.name || ""}
          pipelineId={pipelineId}
        />
      )}
    </React.Fragment>
  );
};

export default SelectCreatableFormikFirmField;
