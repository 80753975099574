import React, { FunctionComponent } from 'react';
import {
  Button,
  Form,
  HorizontalSeparator,
  PageDetails,
} from 'shared/components';
import { useNotification } from 'shared/components/notifications';
import { useCreateLabel } from 'shared/hooks/api';
import { CreateLabelInput } from 'shared/hooks/api/graphql/generated';
import { PartialNullable } from 'shared/utils/utilityTypes';
import { LabelOption } from './AddLabelButton';
import LabelForm, { labelValidationSchema, LabelValues } from './LabelForm';

interface CreateLabelModalProps {
  onClose: () => void;
  onSuccess: (label: LabelOption) => void;
  initialValues?: PartialNullable<LabelValues>;
}

const CreateLabelModal: FunctionComponent<CreateLabelModalProps> = ({
  onClose,
  onSuccess,
  initialValues,
}) => {
  const notify = useNotification();
  const [createLabel, { status: createLabelStatus }] = useCreateLabel();
  return (
    <Form<LabelValues>
      enableReinitialize={false}
      initialValues={initialValues}
      handleSubmit={async (values) => {
        const input: CreateLabelInput = {
          name: values.name,
          color: values.color,
        };
        try {
          const result = await createLabel(input);
          if (result.errors) {
            const permissionError = result.errors.find(
              (x) => x.extensions.code === 'FORBIDDEN'
            );
            if (permissionError) {
              notify({
                duration: 10000,
                title: 'Access Denied',
                message: permissionError.message,
                variant: 'danger',
              });
            } else {
              notify({
                duration: 5000,
                title: 'Uh-oh!',
                message: 'An unknown error occurred while creating this label!',
                variant: 'danger',
              });
            }
          } else if (result.data?.createLabel) {
            onSuccess({
              value: result.data.createLabel?.id,
              label: result.data.createLabel.name,
              color: result.data.createLabel.color || undefined,
            });
            onClose();
          }
        } catch {
          notify({
            variant: 'danger',
            message: 'An error occurred while creating this label.',
          });
        }
      }}
      validationSchema={labelValidationSchema}
    >
      {(props) => (
        <React.Fragment>
          <LabelForm form={props} />
          <HorizontalSeparator margin={20} />
          <Button
            onClick={props.submitForm}
            color={'success'}
            isWorking={createLabelStatus == 'loading'}
            disabled={!props.dirty || !props.isValid}
          >
            Create Label
          </Button>
        </React.Fragment>
      )}
    </Form>
  );
};

export default CreateLabelModal;
