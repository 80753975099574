import { GraphQLClient } from 'graphql-request';
import { print } from 'graphql';
import { GraphQLError } from 'graphql-request/dist/src/types';
import gql from 'graphql-tag';
export type Maybe<T> = T | null | undefined;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  BigInt: any;
  Byte: any;
  /** The `Date` scalar type represents a year, month and day in accordance with the [ISO-8601](https://en.wikipedia.org/wiki/ISO_8601) standard. */
  Date: string;
  /** The `DateTime` scalar type represents a date and time. `DateTime` expects timestamps to be formatted in accordance with the [ISO-8601](https://en.wikipedia.org/wiki/ISO_8601) standard. */
  DateTime: string;
  /** The `DateTimeOffset` scalar type represents a date, time and offset from UTC. `DateTimeOffset` expects timestamps to be formatted in accordance with the [ISO-8601](https://en.wikipedia.org/wiki/ISO_8601) standard. */
  DateTimeOffset: string;
  Decimal: number;
  Guid: any;
  Long: any;
  /** The `Milliseconds` scalar type represents a period of time represented as the total number of milliseconds. */
  Milliseconds: any;
  SByte: any;
  /** The `Seconds` scalar type represents a period of time represented as the total number of seconds. */
  Seconds: any;
  Short: any;
  UInt: any;
  ULong: any;
  UShort: any;
  Uri: any;
};

export type Actor = {
  /** The id of the actor. */
  displayName: Scalars['String'];
  /** The id of the actor. */
  displayNameAlt: Scalars['String'];
  /** The id of the actor. */
  id: Scalars['Int'];
};

export type AddChecklistResult = DealChecklist | DealChecklistAlreadyExists;

/** An input object used for adding a deal partner. */
export type AddDealPartnerInput = {
  /** The id representing the firm of the partner. */
  fkFirmPartner?: Maybe<Scalars['Int']>;
  /** The id representing the firm of the partner. */
  fkContact?: Maybe<Scalars['Int']>;
  /** The id representing the role of the partner. */
  fkPartnerRole: Scalars['Int'];
  /** The id representing the id of the deal this partner is a part of. */
  fkDeal: Scalars['Int'];
  /** The id of the role that the selected firm's relationship lead should be assigned to. */
  fkRelationshipLeadRole?: Maybe<Scalars['Int']>;
};

export type AddDealPartnerResult = DealPartner | AlreadyExists;

/** An input object used for adding a column to a portfolio view. */
export type AddPortfolioViewColumnInput = {
  /** The id of the underlying attribute that is being added (eg. metrics, events). */
  id: Scalars['Int'];
  /** The name of the column to add. */
  name: Scalars['String'];
  /** The lookup name of the column. Used for adding deal attributes. */
  lookupName: Scalars['String'];
  /** The type of column. */
  type: PortfolioViewColumnTypeEnum;
  /** The id of the portfolio view to add the column to. */
  fkPortfolioView: Scalars['Int'];
};

/** An input object used for adding a team member. */
export type AddTeamMemberInput = {
  /** The id representing the person the team member is. */
  fkUser: Scalars['Int'];
  /** The id representing the role of the team member. */
  fkTeamMemberRole: Scalars['Int'];
  /** The id representing the id of the deal this team member is a part of. */
  fkDeal: Scalars['Int'];
};

export type AddTeamMemberResult = DealTeamMemberUser | AlreadyExists;

/** A result type given when the resource that was attempted to be created already exists. */
export type AlreadyExists = Error & {
  __typename?: 'AlreadyExists';
  message: Scalars['String'];
};

/** Analysis */
export type Analysis = {
  __typename?: 'Analysis';
  /** When the object was created. */
  createdAt: Scalars['DateTimeOffset'];
  createdBy: Actor;
  /** When the object was deleted. */
  deletedAt?: Maybe<Scalars['DateTimeOffset']>;
  deletedBy?: Maybe<Actor>;
  id: Scalars['Int'];
  name: Scalars['String'];
  /** When the object was last updated. */
  updatedAt: Scalars['DateTimeOffset'];
  updatedBy: Actor;
};

/** Analysis Event data. */
export type AnalysisEvent = {
  __typename?: 'AnalysisEvent';
  /** The analysis instance that this event value is associated with. */
  analysis: Analysis;
  /** The Event for this value. */
  event: Event;
  id: Scalars['Int'];
  startDate?: Maybe<Scalars['DateTime']>;
};

/** Analysis metric data. */
export type AnalysisMetric = {
  __typename?: 'AnalysisMetric';
  /** The analysis instance that this metric value is associated with. */
  analysis: Analysis;
  id: Scalars['Int'];
  /** The metric for this value. */
  metric: Metric;
  value?: Maybe<Scalars['Decimal']>;
};

/** Analysis text metric data. */
export type AnalysisTextMetric = {
  __typename?: 'AnalysisTextMetric';
  /** The analysis instance that this metric text value is associated with. */
  analysis: Analysis;
  id: Scalars['Int'];
  /** The metric text for this value. */
  textMetric: TextMetric;
  value?: Maybe<Scalars['String']>;
};

/** An analysis type. */
export type AnalysisType = {
  __typename?: 'AnalysisType';
  acronym?: Maybe<Scalars['String']>;
  /** When the object was created. */
  createdAt: Scalars['DateTimeOffset'];
  createdBy: Actor;
  /** When the object was deleted. */
  deletedAt?: Maybe<Scalars['DateTimeOffset']>;
  deletedBy?: Maybe<Actor>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  name: Scalars['String'];
  /** When the object was last updated. */
  updatedAt: Scalars['DateTimeOffset'];
  updatedBy: Actor;
};

/** A result type given when an analysis type trying to be created or named already exists. */
export type AnalysisTypeAlreadyExists = Error & {
  __typename?: 'AnalysisTypeAlreadyExists';
  /** The existing analysis type with the same name. */
  analysisType?: Maybe<AnalysisType>;
  message: Scalars['String'];
};

/** A result type returning items for the summary on the analytics dashboard. */
export type AnalyticsSummary = {
  __typename?: 'AnalyticsSummary';
  dealCount: Scalars['Int'];
  developmentCosts?: Maybe<MetricValue>;
  totalSquareFootage?: Maybe<MetricValue>;
  totalUnits?: Maybe<MetricValue>;
};

/** ApprovalPackage */
export type ApprovalPackage = {
  __typename?: 'ApprovalPackage';
  /** The amendment number of this approval package. */
  amendmentNumber?: Maybe<Scalars['Int']>;
  /** The linked analysis instance for this approval package. */
  analysis?: Maybe<DealAnalysis>;
  /** The date and time on which this approval package was archived. */
  archiveDate?: Maybe<Scalars['DateTimeOffset']>;
  /** The contributors of the approval package. */
  contributors?: Maybe<Array<ApprovalPackageContributor>>;
  /** When the object was created. */
  createdAt: Scalars['DateTimeOffset'];
  createdBy: Actor;
  /** Whether or not the current user is a contributor on this IC package. */
  currentUserIsContributor: Scalars['Boolean'];
  /** Whether or not the current user is a contributor on this IC package. */
  currentUserIsReviewer: Scalars['Boolean'];
  /** The deal that this approval package belongs to. */
  deal: DealInfo;
  /** The deal type of the associated deal. */
  dealType?: Maybe<DealType>;
  /** When the object was deleted. */
  deletedAt?: Maybe<Scalars['DateTimeOffset']>;
  deletedBy?: Maybe<Actor>;
  /** A list of exhibits for this approval package. */
  exhibits?: Maybe<Array<DocumentTreeItem>>;
  /** The id of the published version of this package if one exists. */
  fkPackagePublished?: Maybe<Scalars['Int']>;
  /** The id of this approval package. */
  id: Scalars['Int'];
  /** A flag indicating whether or not this approval package has been published. */
  isPublished: Scalars['Boolean'];
  /** Whether or not the current user is needing to review this approval package */
  needsCurrentUserReview: Scalars['Boolean'];
  /** The proposed review date for this approval package. */
  reviewDate?: Maybe<Scalars['Date']>;
  /** The reviewers of the approval package. */
  reviewers?: Maybe<Array<ApprovalPackageReviewer>>;
  /** The linked published schedule for this approval package. */
  schedule?: Maybe<PublishedScheduleInstance>;
  /** The sections of the approval package. */
  sections?: Maybe<Array<ApprovalPackageSection>>;
  /** The status of this approval package. */
  status: ApprovalPackageStatusEnum;
  /** The type of the approval package. */
  type: ApprovalPackageType;
  /** When the object was last updated. */
  updatedAt: Scalars['DateTimeOffset'];
  updatedBy: Actor;
  /** Whether or the current user is waiting on others to review this approval package */
  waitingForOthers: Scalars['Boolean'];
};

/** ApprovalPackageChecklistItem */
export type ApprovalPackageChecklistItem = {
  __typename?: 'ApprovalPackageChecklistItem';
  /** The user assigned to the checklist item. */
  assignee?: Maybe<User>;
  /** The category of the checklist item. */
  category?: Maybe<ChecklistCategory>;
  date?: Maybe<Scalars['Date']>;
  name: Scalars['String'];
  /** The status of the checklist item. */
  status: ChecklistItemStatus;
};

/** ApprovalPackageContributor */
export type ApprovalPackageContributor = {
  __typename?: 'ApprovalPackageContributor';
  /** When the object was created. */
  createdAt: Scalars['DateTimeOffset'];
  createdBy: Actor;
  /** When the object was deleted. */
  deletedAt?: Maybe<Scalars['DateTimeOffset']>;
  deletedBy?: Maybe<Actor>;
  /** The id of the package reviewer. */
  id: Scalars['Int'];
  /** When the object was last updated. */
  updatedAt: Scalars['DateTimeOffset'];
  updatedBy: Actor;
  /** The actual user who is providing the review. */
  user: User;
};

/** A result type given when adding or removing entities from an approval package sub-section. */
export type ApprovalPackageEntityResult = {
  __typename?: 'ApprovalPackageEntityResult';
  count: Scalars['Int'];
};

/** ApprovalPackageGcEstimate */
export type ApprovalPackageGcEstimate = {
  __typename?: 'ApprovalPackageGcEstimate';
  comment?: Maybe<Scalars['String']>;
  /** A count of the total number of comments for this GC Estimate. */
  commentCount: Scalars['Int'];
  /** A list of comments for this GC Estimate. */
  comments?: Maybe<Array<Comment>>;
  /** When the object was created. */
  createdAt: Scalars['DateTimeOffset'];
  createdBy: Actor;
  /** When the object was deleted. */
  deletedAt?: Maybe<Scalars['DateTimeOffset']>;
  deletedBy?: Maybe<Actor>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  isApproved: Scalars['Boolean'];
  orderId?: Maybe<Scalars['Decimal']>;
  pricePerNrsf?: Maybe<Scalars['Decimal']>;
  pricePerUnit?: Maybe<Scalars['Decimal']>;
  /** The source (firm) of the GC estimate. */
  source: FirmInfo;
  totalPrice?: Maybe<Scalars['Decimal']>;
  /** The type of a GC estimate. */
  type: EstimateType;
  /** When the object was last updated. */
  updatedAt: Scalars['DateTimeOffset'];
  updatedBy: Actor;
};

/** ApprovalPackageKeyRisk */
export type ApprovalPackageKeyRisk = {
  __typename?: 'ApprovalPackageKeyRisk';
  /** When the object was created. */
  createdAt: Scalars['DateTimeOffset'];
  createdBy: Actor;
  /** When the object was deleted. */
  deletedAt?: Maybe<Scalars['DateTimeOffset']>;
  deletedBy?: Maybe<Actor>;
  id: Scalars['Int'];
  mitigantDescription?: Maybe<Scalars['String']>;
  orderId?: Maybe<Scalars['Decimal']>;
  riskDescription?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  /** When the object was last updated. */
  updatedAt: Scalars['DateTimeOffset'];
  updatedBy: Actor;
};

/** ApprovalPackageMerit */
export type ApprovalPackageMerit = {
  __typename?: 'ApprovalPackageMerit';
  /** When the object was created. */
  createdAt: Scalars['DateTimeOffset'];
  createdBy: Actor;
  /** When the object was deleted. */
  deletedAt?: Maybe<Scalars['DateTimeOffset']>;
  deletedBy?: Maybe<Actor>;
  id: Scalars['Int'];
  meritDescription?: Maybe<Scalars['String']>;
  orderId?: Maybe<Scalars['Decimal']>;
  title: Scalars['String'];
  /** When the object was last updated. */
  updatedAt: Scalars['DateTimeOffset'];
  updatedBy: Actor;
};

/** ApprovalPackageReviewer */
export type ApprovalPackageReviewer = {
  __typename?: 'ApprovalPackageReviewer';
  /** When the object was created. */
  createdAt: Scalars['DateTimeOffset'];
  createdBy: Actor;
  /** When the object was deleted. */
  deletedAt?: Maybe<Scalars['DateTimeOffset']>;
  deletedBy?: Maybe<Actor>;
  /** The firm that the package reviewer is associated with. */
  firm?: Maybe<FirmInfo>;
  /** The id of the package reviewer. */
  id: Scalars['Int'];
  /** The date that a review was provided for this reviewer. */
  reviewedDate?: Maybe<Scalars['DateTime']>;
  /** The status of this reviewer for this approval package. */
  status: ApprovalPackageStatusEnum;
  /** When the object was last updated. */
  updatedAt: Scalars['DateTimeOffset'];
  updatedBy: Actor;
  /** The actual user who is providing the review. */
  user: User;
};

/** ApprovalPackageSection */
export type ApprovalPackageSection = {
  __typename?: 'ApprovalPackageSection';
  /** The content for this section, made up of one or more sub-sections. */
  content?: Maybe<Array<ApprovalPackageSubSectionUnion>>;
  /** The column width of this section based on a 12 column wide grid. */
  gridColumnWidth: Scalars['Int'];
  /** The id of the section */
  id: Scalars['Int'];
  /** The order id of the section. */
  orderId?: Maybe<Scalars['Decimal']>;
  /** The title of this approval package section. */
  title: Scalars['String'];
};

export enum ApprovalPackageStatusEnum {
  Approved = 'APPROVED',
  Rejected = 'REJECTED',
  Pending = 'PENDING'
}

/** ApprovalPackageSubSectionChecklist */
export type ApprovalPackageSubSectionChecklist = {
  __typename?: 'ApprovalPackageSubSectionChecklist';
  /** The column width of this sub-section based on a 12 column wide grid. */
  gridColumnWidth: Scalars['Int'];
  /** The id of the section */
  id: Scalars['Int'];
  /** A list of checklist items for a given approval package. */
  items?: Maybe<Array<ApprovalPackageChecklistItem>>;
  /** The order id of the sub-section. */
  orderId?: Maybe<Scalars['Decimal']>;
  /** The title of this approval package sub-section. */
  title: Scalars['String'];
};

/** A deal attribute and its associated value for a given approval package sub-section. */
export type ApprovalPackageSubSectionDealAttribute = {
  __typename?: 'ApprovalPackageSubSectionDealAttribute';
  /** The attribute for this value. */
  attribute: DealAttribute;
  value?: Maybe<Scalars['String']>;
};

/** ApprovalPackageSubSectionDealBackground */
export type ApprovalPackageSubSectionDealBackground = {
  __typename?: 'ApprovalPackageSubSectionDealBackground';
  /** deal background text */
  dealBackgroundText?: Maybe<Scalars['String']>;
  /** The column width of this sub-section based on a 12 column wide grid. */
  gridColumnWidth: Scalars['Int'];
  /** The id of the section */
  id: Scalars['Int'];
  /** The order id of the sub-section. */
  orderId?: Maybe<Scalars['Decimal']>;
  /** The title of this approval package sub-section. */
  title: Scalars['String'];
};

/** ApprovalPackageSubSectionEarnestMoneyDeposit */
export type ApprovalPackageSubSectionEarnestMoneyDeposit = {
  __typename?: 'ApprovalPackageSubSectionEarnestMoneyDeposit';
  /** earnest money deposit text */
  earnestMoneyDepositText?: Maybe<Scalars['String']>;
  /** The column width of this sub-section based on a 12 column wide grid. */
  gridColumnWidth: Scalars['Int'];
  /** The id of the section */
  id: Scalars['Int'];
  /** The order id of the sub-section. */
  orderId?: Maybe<Scalars['Decimal']>;
  /** The title of this approval package sub-section. */
  title: Scalars['String'];
};

/** An event and its associated value for a given approval package sub-section. */
export type ApprovalPackageSubSectionEvent = {
  __typename?: 'ApprovalPackageSubSectionEvent';
  endDate?: Maybe<Scalars['Date']>;
  /** The event for this value. */
  event: Event;
  startDate?: Maybe<Scalars['Date']>;
};

/** ApprovalPackageSubSectionGcEstimate */
export type ApprovalPackageSubSectionGcEstimate = {
  __typename?: 'ApprovalPackageSubSectionGcEstimate';
  /** A list of gcEstimate items for a given approval package. */
  gcEstimates?: Maybe<Array<ApprovalPackageGcEstimate>>;
  /** The column width of this sub-section based on a 12 column wide grid. */
  gridColumnWidth: Scalars['Int'];
  /** The id of the section */
  id: Scalars['Int'];
  /** The order id of the sub-section. */
  orderId?: Maybe<Scalars['Decimal']>;
  /** The title of this approval package sub-section. */
  title: Scalars['String'];
};

/** ApprovalPackageSubSectionImages */
export type ApprovalPackageSubSectionImages = {
  __typename?: 'ApprovalPackageSubSectionImages';
  /** The column width of this sub-section based on a 12 column wide grid. */
  gridColumnWidth: Scalars['Int'];
  /** The id of the section */
  id: Scalars['Int'];
  /** A list of the images associated with this sub-section. */
  images?: Maybe<Array<IcSubSectionImageContent>>;
  /** The order id of the sub-section. */
  orderId?: Maybe<Scalars['Decimal']>;
  /** The title of this approval package sub-section. */
  title: Scalars['String'];
};

/** ApprovalPackageSubSectionKeyRisks */
export type ApprovalPackageSubSectionKeyRisks = {
  __typename?: 'ApprovalPackageSubSectionKeyRisks';
  /** The column width of this sub-section based on a 12 column wide grid. */
  gridColumnWidth: Scalars['Int'];
  /** The id of the section */
  id: Scalars['Int'];
  /** A list of key risks for a given approval package. */
  keyRisks?: Maybe<Array<ApprovalPackageKeyRisk>>;
  /** The order id of the sub-section. */
  orderId?: Maybe<Scalars['Decimal']>;
  /** The title of this approval package sub-section. */
  title: Scalars['String'];
};

/** ApprovalPackageSubSectionMerits */
export type ApprovalPackageSubSectionMerits = {
  __typename?: 'ApprovalPackageSubSectionMerits';
  /** The column width of this sub-section based on a 12 column wide grid. */
  gridColumnWidth: Scalars['Int'];
  /** The id of the section */
  id: Scalars['Int'];
  /** A list of merits for a given approval package. */
  merits?: Maybe<Array<ApprovalPackageMerit>>;
  /** The order id of the sub-section. */
  orderId?: Maybe<Scalars['Decimal']>;
  /** The title of this approval package sub-section. */
  title: Scalars['String'];
};

/** A metric and its associated value for a given approval package sub-section. */
export type ApprovalPackageSubSectionMetric = {
  __typename?: 'ApprovalPackageSubSectionMetric';
  /** The metric for this value. */
  metric: Metric;
  value?: Maybe<Scalars['Decimal']>;
};

/** ApprovalPackageSubSectionPageBreak */
export type ApprovalPackageSubSectionPageBreak = {
  __typename?: 'ApprovalPackageSubSectionPageBreak';
  /** The id of the section */
  id: Scalars['Int'];
  /** The order id of the sub-section. */
  orderId?: Maybe<Scalars['Decimal']>;
};

/** A partner role and its associated value for a given approval package sub-section. */
export type ApprovalPackageSubSectionPartnerRole = {
  __typename?: 'ApprovalPackageSubSectionPartnerRole';
  names?: Maybe<Scalars['String']>;
  /** The role for this value. */
  role: PartnerRole;
};

/** ApprovalPackageSubSectionTable */
export type ApprovalPackageSubSectionTable = {
  __typename?: 'ApprovalPackageSubSectionTable';
  /** A list of the cells that make up the table of metrics. */
  cells?: Maybe<Array<IcSubSectionTableCell>>;
  /** The number of columns of metrics to show. */
  columns: Scalars['Int'];
  /** The column width of this sub-section based on a 12 column wide grid. */
  gridColumnWidth: Scalars['Int'];
  /** The id of the section */
  id: Scalars['Int'];
  /** The order id of the sub-section. */
  orderId?: Maybe<Scalars['Decimal']>;
  /** The title of this approval package sub-section. */
  title: Scalars['String'];
};

export enum ApprovalPackageSubSectionTableCellEntryEnum {
  Event = 'EVENT',
  Metric = 'METRIC',
  DealAttribute = 'DEAL_ATTRIBUTE',
  TeamMemberRole = 'TEAM_MEMBER_ROLE',
  DealPartnerRole = 'DEAL_PARTNER_ROLE'
}

/** A team member role and its associated value for a given approval package sub-section. */
export type ApprovalPackageSubSectionTeamMemberRole = {
  __typename?: 'ApprovalPackageSubSectionTeamMemberRole';
  names?: Maybe<Scalars['String']>;
  /** The role for this value. */
  role: TeamMemberRole;
};

/** ApprovalPackageSubSectionText */
export type ApprovalPackageSubSectionText = {
  __typename?: 'ApprovalPackageSubSectionText';
  /** The column width of this sub-section based on a 12 column wide grid. */
  gridColumnWidth: Scalars['Int'];
  /** The id of the section */
  id: Scalars['Int'];
  /** The order id of the sub-section. */
  orderId?: Maybe<Scalars['Decimal']>;
  /** The text of this section. */
  text?: Maybe<Scalars['String']>;
  /** The title of this approval package sub-section. */
  title: Scalars['String'];
};

export enum ApprovalPackageSubSectionTypeEnum {
  Table = 'TABLE',
  Schedule = 'SCHEDULE',
  DealInformation = 'DEAL_INFORMATION',
  CustomText = 'CUSTOM_TEXT',
  KeyRisks = 'KEY_RISKS',
  Merits = 'MERITS',
  Images = 'IMAGES',
  PageBreak = 'PAGE_BREAK',
  Checklist = 'CHECKLIST',
  GcEstimates = 'GC_ESTIMATES',
  DealBackground = 'DEAL_BACKGROUND',
  EarnestMoneyDeposit = 'EARNEST_MONEY_DEPOSIT'
}

export type ApprovalPackageSubSectionUnion = ApprovalPackageSubSectionText | ApprovalPackageSubSectionDealBackground | ApprovalPackageSubSectionEarnestMoneyDeposit | ApprovalPackageSubSectionChecklist | ApprovalPackageSubSectionGcEstimate | ApprovalPackageSubSectionKeyRisks | ApprovalPackageSubSectionMerits | ApprovalPackageSubSectionTable | ApprovalPackageSubSectionImages | ApprovalPackageSubSectionPageBreak;

/** ApprovalPackageType */
export type ApprovalPackageType = {
  __typename?: 'ApprovalPackageType';
  /** When the object was created. */
  createdAt: Scalars['DateTimeOffset'];
  createdBy: Actor;
  /** The default template that should be shown when creating an approval package of this type. */
  defaultTemplate?: Maybe<TemplateApprovalPackage>;
  /** When the object was deleted. */
  deletedAt?: Maybe<Scalars['DateTimeOffset']>;
  deletedBy?: Maybe<Actor>;
  /** The event that this approval package type should be associated with on the schedule of a given deal. */
  event?: Maybe<Event>;
  id: Scalars['Int'];
  name: Scalars['String'];
  /** When the object was last updated. */
  updatedAt: Scalars['DateTimeOffset'];
  updatedBy: Actor;
};

/** ApprovalsDashboard */
export type ApprovalsDashboard = {
  __typename?: 'ApprovalsDashboard';
  all: Scalars['Int'];
  completed: Scalars['Int'];
  needsApproval: Scalars['Int'];
  pending: Scalars['Int'];
  waitingForOthers: Scalars['Int'];
};

/** Artifact */
export type Artifact = {
  __typename?: 'Artifact';
  /** When the object was created. */
  createdAt: Scalars['DateTimeOffset'];
  createdBy: Actor;
  /** When the object was deleted. */
  deletedAt?: Maybe<Scalars['DateTimeOffset']>;
  deletedBy?: Maybe<Actor>;
  id: Scalars['Int'];
  name: Scalars['String'];
  /** When the object was last updated. */
  updatedAt: Scalars['DateTimeOffset'];
  updatedBy: Actor;
};

/** AssetClass */
export type AssetClass = {
  __typename?: 'AssetClass';
  /** When the object was created. */
  createdAt: Scalars['DateTimeOffset'];
  createdBy: Actor;
  /** When the object was deleted. */
  deletedAt?: Maybe<Scalars['DateTimeOffset']>;
  deletedBy?: Maybe<Actor>;
  id: Scalars['Int'];
  isDisabled: Scalars['Boolean'];
  name: Scalars['String'];
  /** When the object was last updated. */
  updatedAt: Scalars['DateTimeOffset'];
  updatedBy: Actor;
};


/** Bond */
export type Bond = {
  __typename?: 'Bond';
  acceptedAlternatives?: Maybe<Scalars['String']>;
  bondAmount?: Maybe<Scalars['Decimal']>;
  bondDescription?: Maybe<Scalars['String']>;
  /** The bonding company of a bond. */
  bondingCompany?: Maybe<FirmInfo>;
  breakdownBackup?: Maybe<Scalars['String']>;
  collateralAmount?: Maybe<Scalars['Decimal']>;
  collateralNotes?: Maybe<Scalars['String']>;
  conditionsForRelease?: Maybe<Scalars['String']>;
  /** When the object was created. */
  createdAt: Scalars['DateTimeOffset'];
  createdBy: Actor;
  /** When the object was deleted. */
  deletedAt?: Maybe<Scalars['DateTimeOffset']>;
  deletedBy?: Maybe<Actor>;
  describedWork?: Maybe<Scalars['String']>;
  expectedRelease?: Maybe<Scalars['DateTime']>;
  expires?: Maybe<Scalars['DateTime']>;
  expiresNotes?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  improvementDeadline?: Maybe<Scalars['DateTime']>;
  isComplete: Scalars['Boolean'];
  locAmount?: Maybe<Scalars['Decimal']>;
  name?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  obligeeBeneficiary?: Maybe<Scalars['String']>;
  originalEffectiveDate?: Maybe<Scalars['DateTime']>;
  premium?: Maybe<Scalars['Decimal']>;
  premiumComment?: Maybe<Scalars['String']>;
  premiumRefundDue?: Maybe<Scalars['Decimal']>;
  principalEntity?: Maybe<Scalars['String']>;
  /** When the object was last updated. */
  updatedAt: Scalars['DateTimeOffset'];
  updatedBy: Actor;
};

/** Building */
export type Building = {
  __typename?: 'Building';
  address?: Maybe<Scalars['String']>;
  buildingNumber: Scalars['Int'];
  /** A count of the total number of comments for this building. */
  commentCount: Scalars['Int'];
  /** A list of comments about this building. */
  comments?: Maybe<Array<Comment>>;
  /** When the object was created. */
  createdAt: Scalars['DateTimeOffset'];
  createdBy: Actor;
  /** When the object was deleted. */
  deletedAt?: Maybe<Scalars['DateTimeOffset']>;
  deletedBy?: Maybe<Actor>;
  id: Scalars['Int'];
  isTcoReceived: Scalars['Boolean'];
  name?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  tcoDate?: Maybe<Scalars['Date']>;
  /** The type of this building. */
  type: BuildingType;
  unitCount?: Maybe<Scalars['Int']>;
  /** When the object was last updated. */
  updatedAt: Scalars['DateTimeOffset'];
  updatedBy: Actor;
};

/** BuildingType */
export type BuildingType = {
  __typename?: 'BuildingType';
  /** When the object was created. */
  createdAt: Scalars['DateTimeOffset'];
  createdBy: Actor;
  /** When the object was deleted. */
  deletedAt?: Maybe<Scalars['DateTimeOffset']>;
  deletedBy?: Maybe<Actor>;
  id: Scalars['Int'];
  isDisabled: Scalars['Boolean'];
  name: Scalars['String'];
  /** When the object was last updated. */
  updatedAt: Scalars['DateTimeOffset'];
  updatedBy: Actor;
};


/** ChangeEvent */
export type ChangeEvent = {
  __typename?: 'ChangeEvent';
  /** The cost source for this change event. */
  costSource?: Maybe<GlAccount>;
  /** When the object was created. */
  createdAt: Scalars['DateTimeOffset'];
  createdBy: Actor;
  /** When the object was deleted. */
  deletedAt?: Maybe<Scalars['DateTimeOffset']>;
  deletedBy?: Maybe<Actor>;
  fkPotentialChangeOrder?: Maybe<Scalars['Int']>;
  id: Scalars['Int'];
  isScheduleImpactExcusable?: Maybe<Scalars['Boolean']>;
  number?: Maybe<Scalars['Int']>;
  ownerScheduleImpact?: Maybe<Scalars['Int']>;
  ownerTotal?: Maybe<Scalars['Decimal']>;
  /** The reason for a change event. */
  reason?: Maybe<ChangeEventReason>;
  scheduleImpact?: Maybe<Scalars['Int']>;
  /** The status of a change event. */
  status: ChangeOrderStatus;
  title?: Maybe<Scalars['String']>;
  total?: Maybe<Scalars['Decimal']>;
  /** The type of a change event. */
  type: ChangeEventType;
  /** When the object was last updated. */
  updatedAt: Scalars['DateTimeOffset'];
  updatedBy: Actor;
};

/** ChangeEventReason */
export type ChangeEventReason = {
  __typename?: 'ChangeEventReason';
  /** When the object was created. */
  createdAt: Scalars['DateTimeOffset'];
  createdBy: Actor;
  /** When the object was deleted. */
  deletedAt?: Maybe<Scalars['DateTimeOffset']>;
  deletedBy?: Maybe<Actor>;
  id: Scalars['Int'];
  name: Scalars['String'];
  /** When the object was last updated. */
  updatedAt: Scalars['DateTimeOffset'];
  updatedBy: Actor;
};

/** ChangeEventType */
export type ChangeEventType = {
  __typename?: 'ChangeEventType';
  /** When the object was created. */
  createdAt: Scalars['DateTimeOffset'];
  createdBy: Actor;
  /** When the object was deleted. */
  deletedAt?: Maybe<Scalars['DateTimeOffset']>;
  deletedBy?: Maybe<Actor>;
  id: Scalars['Int'];
  name: Scalars['String'];
  /** When the object was last updated. */
  updatedAt: Scalars['DateTimeOffset'];
  updatedBy: Actor;
};

/** ChangeItemReconciliationDetails */
export type ChangeItemReconciliationDetails = {
  __typename?: 'ChangeItemReconciliationDetails';
  adjustmentsToDate: Scalars['Decimal'];
  balanceAfterForecast: Scalars['Decimal'];
  comparisonBudgetAmount: Scalars['Decimal'];
  /** The cost source for this change event. */
  costSource?: Maybe<GlAccount>;
  currentBudgetAmount: Scalars['Decimal'];
  forecastedChanges: Scalars['Decimal'];
  percentUsedMinusGcPercentComplete: Scalars['Decimal'];
  title: Scalars['String'];
};

/** ChangeOrderPackage */
export type ChangeOrderPackage = {
  __typename?: 'ChangeOrderPackage';
  /** The parent change order that this change order package belongs to. */
  changeOrder?: Maybe<ChangeOrderPackage>;
  /** The cost source for this change order package. */
  costSource?: Maybe<GlAccount>;
  /** When the object was created. */
  createdAt: Scalars['DateTimeOffset'];
  createdBy: Actor;
  /** When the object was deleted. */
  deletedAt?: Maybe<Scalars['DateTimeOffset']>;
  deletedBy?: Maybe<Actor>;
  fkChangeOrderPackageParent?: Maybe<Scalars['Int']>;
  id: Scalars['Int'];
  isIssued?: Maybe<Scalars['Boolean']>;
  isScheduleImpactExcusable?: Maybe<Scalars['Boolean']>;
  number?: Maybe<Scalars['Int']>;
  scheduleImpact?: Maybe<Scalars['Int']>;
  /** The status of a change order package. */
  status: ChangeOrderStatus;
  title?: Maybe<Scalars['String']>;
  total?: Maybe<Scalars['Decimal']>;
  /** When the object was last updated. */
  updatedAt: Scalars['DateTimeOffset'];
  updatedBy: Actor;
};

/** ChangeOrderStatus */
export type ChangeOrderStatus = {
  __typename?: 'ChangeOrderStatus';
  /** When the object was created. */
  createdAt: Scalars['DateTimeOffset'];
  createdBy: Actor;
  /** When the object was deleted. */
  deletedAt?: Maybe<Scalars['DateTimeOffset']>;
  deletedBy?: Maybe<Actor>;
  id: Scalars['Int'];
  name: Scalars['String'];
  /** When the object was last updated. */
  updatedAt: Scalars['DateTimeOffset'];
  updatedBy: Actor;
};

/** A result type returning items for a chart on the analytics dashboard. */
export type ChartDataItem = {
  __typename?: 'ChartDataItem';
  name: Scalars['String'];
  value: Scalars['Int'];
};

/** Checklist */
export type Checklist = {
  __typename?: 'Checklist';
  /** The checklist items associated with this checklist. */
  checklistItems?: Maybe<Array<ChecklistItem>>;
  /** When the object was created. */
  createdAt: Scalars['DateTimeOffset'];
  createdBy: Actor;
  /** When the object was deleted. */
  deletedAt?: Maybe<Scalars['DateTimeOffset']>;
  deletedBy?: Maybe<Actor>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  isDisabled: Scalars['Boolean'];
  name: Scalars['String'];
  /** When the object was last updated. */
  updatedAt: Scalars['DateTimeOffset'];
  updatedBy: Actor;
};

/** A result type given when a checklist trying to be created or named already exists. */
export type ChecklistAlreadyExists = Error & {
  __typename?: 'ChecklistAlreadyExists';
  /** The existing checklist with the same name. */
  checklist?: Maybe<Checklist>;
  message: Scalars['String'];
};

/** ChecklistCategory */
export type ChecklistCategory = {
  __typename?: 'ChecklistCategory';
  /** When the object was created. */
  createdAt: Scalars['DateTimeOffset'];
  createdBy: Actor;
  /** When the object was deleted. */
  deletedAt?: Maybe<Scalars['DateTimeOffset']>;
  deletedBy?: Maybe<Actor>;
  fkChecklist: Scalars['Int'];
  id: Scalars['Int'];
  name: Scalars['String'];
  /** When the object was last updated. */
  updatedAt: Scalars['DateTimeOffset'];
  updatedBy: Actor;
};

/** A result type given when a checklist category trying to be created already exists in the same checklist. */
export type ChecklistCategoryAlreadyExists = Error & {
  __typename?: 'ChecklistCategoryAlreadyExists';
  /** The existing checklist category with the same name. */
  checklistCategory?: Maybe<ChecklistCategory>;
  message: Scalars['String'];
};

/** ChecklistItem */
export type ChecklistItem = {
  __typename?: 'ChecklistItem';
  /** The category of the checklist item. */
  category?: Maybe<ChecklistCategory>;
  /** When the object was created. */
  createdAt: Scalars['DateTimeOffset'];
  createdBy: Actor;
  defaultComment?: Maybe<Scalars['String']>;
  /** When the object was deleted. */
  deletedAt?: Maybe<Scalars['DateTimeOffset']>;
  deletedBy?: Maybe<Actor>;
  id: Scalars['Int'];
  name: Scalars['String'];
  /** The team member role assigned to the checklist item. */
  teamMemberRole?: Maybe<TeamMemberRole>;
  /** When the object was last updated. */
  updatedAt: Scalars['DateTimeOffset'];
  updatedBy: Actor;
};

/** A result type given when a checklist item trying to be created already exists in the same category. */
export type ChecklistItemAlreadyExists = Error & {
  __typename?: 'ChecklistItemAlreadyExists';
  /** The existing checklist item with the same name. */
  checklistItem?: Maybe<ChecklistItem>;
  message: Scalars['String'];
};

/** ChecklistItemStatus */
export type ChecklistItemStatus = {
  __typename?: 'ChecklistItemStatus';
  /** When the object was created. */
  createdAt: Scalars['DateTimeOffset'];
  createdBy: Actor;
  /** When the object was deleted. */
  deletedAt?: Maybe<Scalars['DateTimeOffset']>;
  deletedBy?: Maybe<Actor>;
  id: Scalars['Int'];
  name: Scalars['String'];
  /** When the object was last updated. */
  updatedAt: Scalars['DateTimeOffset'];
  updatedBy: Actor;
};

/** Comment */
export type Comment = {
  __typename?: 'Comment';
  body: Scalars['String'];
  /** When the object was created. */
  createdAt: Scalars['DateTimeOffset'];
  createdBy: Actor;
  /** When the object was deleted. */
  deletedAt?: Maybe<Scalars['DateTimeOffset']>;
  deletedBy?: Maybe<Actor>;
  id: Scalars['Int'];
  /** When the object was last updated. */
  updatedAt: Scalars['DateTimeOffset'];
  updatedBy: Actor;
};

export enum CommentSubjectEnum {
  OpportunityEvent = 'OPPORTUNITY_EVENT',
  Task = 'TASK',
  Transaction = 'TRANSACTION',
  BudgetRevision = 'BUDGET_REVISION',
  InvestmentVision = 'INVESTMENT_VISION',
  DealBackground = 'DEAL_BACKGROUND',
  ChecklistItem = 'CHECKLIST_ITEM',
  GcPricingEstimate = 'GC_PRICING_ESTIMATE',
  Contact = 'CONTACT',
  Building = 'BUILDING',
  DealStatusUpdate = 'DEAL_STATUS_UPDATE'
}

/** ConfigurableEntity */
export type ConfigurableEntity = {
  __typename?: 'ConfigurableEntity';
  /** When the object was created. */
  createdAt: Scalars['DateTimeOffset'];
  createdBy?: Maybe<Actor>;
  /** When the object was deleted. */
  deletedAt?: Maybe<Scalars['DateTimeOffset']>;
  deletedBy?: Maybe<Actor>;
  entityType?: Maybe<ConfigurableEntityEnum>;
  id: Scalars['Int'];
  name: Scalars['String'];
  /** When the object was last updated. */
  updatedAt: Scalars['DateTimeOffset'];
  updatedBy?: Maybe<Actor>;
};

/** A result type given when the configurable entity that was attempted to be created already exists. */
export type ConfigurableEntityAlreadyExists = Error & {
  __typename?: 'ConfigurableEntityAlreadyExists';
  /** The existing entity with matching properties. */
  entity?: Maybe<ConfigurableEntity>;
  message: Scalars['String'];
};

export enum ConfigurableEntityEnum {
  ProductType = 'PRODUCT_TYPE',
  ConstructionType = 'CONSTRUCTION_TYPE',
  HoaType = 'HOA_TYPE',
  DealStage = 'DEAL_STAGE',
  AssetClass = 'ASSET_CLASS',
  AnalysisScenario = 'ANALYSIS_SCENARIO',
  BuildingType = 'BUILDING_TYPE',
  GcEstimateType = 'GC_ESTIMATE_TYPE',
  Fund = 'FUND',
  FundEntity = 'FUND_ENTITY',
  Structure = 'STRUCTURE',
  DealType = 'DEAL_TYPE'
}

/** ConstructionType */
export type ConstructionType = {
  __typename?: 'ConstructionType';
  /** When the object was created. */
  createdAt: Scalars['DateTimeOffset'];
  createdBy: Actor;
  /** When the object was deleted. */
  deletedAt?: Maybe<Scalars['DateTimeOffset']>;
  deletedBy?: Maybe<Actor>;
  id: Scalars['Int'];
  isDisabled: Scalars['Boolean'];
  name: Scalars['String'];
  /** When the object was last updated. */
  updatedAt: Scalars['DateTimeOffset'];
  updatedBy: Actor;
};

/** Contact */
export type Contact = ContactInfo & {
  __typename?: 'Contact';
  address1?: Maybe<Scalars['String']>;
  address2?: Maybe<Scalars['String']>;
  /** The deals that the contact is associated with. */
  associatedDeals: Array<Deal>;
  /** A list of uploaded attachment documents for this contact. */
  attachments?: Maybe<Array<ContactAttachment>>;
  cellPhone?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  /** When the object was created. */
  createdAt: Scalars['DateTimeOffset'];
  createdBy: Actor;
  /** When the object was deleted. */
  deletedAt?: Maybe<Scalars['DateTimeOffset']>;
  deletedBy?: Maybe<Actor>;
  email?: Maybe<Scalars['String']>;
  /** The firm that the contact belongs to. */
  firm?: Maybe<FirmInfo>;
  firstName: Scalars['String'];
  id: Scalars['Int'];
  /** The number of deals that this contact is associated with that are inaccessible to the current user. */
  inaccessibleAssociatedDealsCount?: Maybe<Scalars['Int']>;
  /** Interactions with this contact. */
  interactions: Array<Interaction>;
  isPrimary?: Maybe<Scalars['Boolean']>;
  lastName: Scalars['String'];
  notes?: Maybe<Scalars['String']>;
  officePhone?: Maybe<Scalars['String']>;
  /** The state that this contact resides in. */
  state?: Maybe<State>;
  title?: Maybe<Scalars['String']>;
  /** When the object was last updated. */
  updatedAt: Scalars['DateTimeOffset'];
  updatedBy: Actor;
  zipcode?: Maybe<Scalars['String']>;
};

/** A result type given when the contact that was attempted to be created already exists. */
export type ContactAlreadyExists = Error & {
  __typename?: 'ContactAlreadyExists';
  /** The existing contact with matching properties. */
  contact?: Maybe<ContactInfo>;
  message: Scalars['String'];
};

/** An input object used for assigning/unassigning contacts to a deal. */
export type ContactAssignmentInput = {
  /** A list of identifiers for contacts that should be assigned/unassigned to this deal. */
  contactIds: Array<Scalars['Int']>;
  /** The id of the deal to assign/unassign the contacts to. */
  fkDeal: Scalars['Int'];
};

/** ContactAttachment */
export type ContactAttachment = {
  __typename?: 'ContactAttachment';
  /** When the object was created. */
  createdAt: Scalars['DateTimeOffset'];
  createdBy: Actor;
  /** When the object was deleted. */
  deletedAt?: Maybe<Scalars['DateTimeOffset']>;
  deletedBy?: Maybe<Actor>;
  displayName: Scalars['String'];
  fileExtension?: Maybe<Scalars['String']>;
  fkDocument: Scalars['Int'];
  id: Scalars['Int'];
  /** When the object was last updated. */
  updatedAt: Scalars['DateTimeOffset'];
  updatedBy: Actor;
};

export type ContactInfo = {
  email?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  id: Scalars['Int'];
  lastName: Scalars['String'];
  title?: Maybe<Scalars['String']>;
};

/** ContingencyCategory */
export type ContingencyCategory = {
  __typename?: 'ContingencyCategory';
  /** When the object was created. */
  createdAt: Scalars['DateTimeOffset'];
  createdBy: Actor;
  /** When the object was deleted. */
  deletedAt?: Maybe<Scalars['DateTimeOffset']>;
  deletedBy?: Maybe<Actor>;
  id: Scalars['Int'];
  name: Scalars['String'];
  /** When the object was last updated. */
  updatedAt: Scalars['DateTimeOffset'];
  updatedBy: Actor;
};

/** ContingencyReconciliationSummary */
export type ContingencyReconciliationSummary = {
  __typename?: 'ContingencyReconciliationSummary';
  approvedOwnerContingencies: Scalars['Decimal'];
  balanceAfterForecast: Scalars['Decimal'];
  forecastedUses: Scalars['Decimal'];
  ownerShareOfGcContingencySavings: Scalars['Decimal'];
  totalForeacstedBalanceIncludingSavings: Scalars['Decimal'];
};

/** Contract */
export type Contract = {
  __typename?: 'Contract';
  amount?: Maybe<Scalars['Decimal']>;
  /** When the object was created. */
  createdAt: Scalars['DateTimeOffset'];
  createdBy: Actor;
  /** When the object was deleted. */
  deletedAt?: Maybe<Scalars['DateTimeOffset']>;
  deletedBy?: Maybe<Actor>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  name: Scalars['String'];
  /** A list of transactions for a specific contract. */
  transactions?: Maybe<Array<Transaction>>;
  /** When the object was last updated. */
  updatedAt: Scalars['DateTimeOffset'];
  updatedBy: Actor;
  /** The vendor for this contract. */
  vendor?: Maybe<FirmInfo>;
};

/** CostAccountBudgetSummary */
export type CostAccountBudgetSummary = {
  __typename?: 'CostAccountBudgetSummary';
  /** The cost account for this summary. */
  account: GlAccount;
  amount: Scalars['Decimal'];
};

/** An input object used for creating an analysis event. */
export type CreateAnalysisEventInput = {
  /** The id of the analysis the event belongs to. */
  fkAnalysis: Scalars['Int'];
  /** The id of the event to create. */
  fkEvent: Scalars['Int'];
  /** The start date to set the analysis event to. */
  startDate?: Maybe<Scalars['DateTime']>;
};

/** An input object used for creating an analysis. */
export type CreateAnalysisInput = {
  /** The type of the analysis */
  fkAnalysisType: Scalars['Int'];
  /** The id of the deal to assoctiate the analysis with. */
  fkDeal: Scalars['Int'];
  /** The id of the scenario this analysis has. */
  fkScenarioType?: Maybe<Scalars['Int']>;
  /** The description of the analysis. */
  description?: Maybe<Scalars['String']>;
};

/** An input object used for creating an analysis metric. */
export type CreateAnalysisMetricInput = {
  /** The id of the analysis the metric belongs to. */
  fkAnalysis: Scalars['Int'];
  /** The id of the metric to create. */
  fkMetric: Scalars['Int'];
  /** The value to set the analysis metric to. */
  value?: Maybe<Scalars['Decimal']>;
};

/** An input object used for creating an analysis text metric. */
export type CreateAnalysisTextMetricInput = {
  /** The id of the analysis the metric belongs to. */
  fkAnalysis: Scalars['Int'];
  /** The id of the text metric to create. */
  fkTextMetric: Scalars['Int'];
  /** The value to set the analysis text metric to. */
  value?: Maybe<Scalars['String']>;
};

/** An input for creating an analysis type for use with an analysis. */
export type CreateAnalysisTypeInput = {
  name: Scalars['String'];
  acronym: Scalars['String'];
};

export type CreateAnalysisTypeResult = AnalysisType | AnalysisTypeAlreadyExists;

/** An input object used for creating an analysis with associated Events. */
export type CreateAnalysisWithEventsInput = {
  /** The id of the event to create. */
  fkEvent: Scalars['Int'];
  /** The date to set the analysis event to. */
  startDate?: Maybe<Scalars['DateTime']>;
};

/** An input object used for creating an analysis with associated metrics. */
export type CreateAnalysisWithMetricsInput = {
  /** The id of the metric to create. */
  fkMetric: Scalars['Int'];
  /** The value to set the analysis metric to. */
  value?: Maybe<Scalars['Decimal']>;
};

/** An input object used for creating an analysis with associated Events. */
export type CreateAnalysisWithTextMetricsInput = {
  /** The id of the text to create the analysis text from. */
  fkTextMetric: Scalars['Int'];
  /** The string to set the analysis text to. */
  value?: Maybe<Scalars['String']>;
};

/** An input object used for adding a contributor to an approval package. */
export type CreateApprovalPackageContributorInput = {
  /** The id of the user that is the contributor for the given approval package. */
  fkUser: Scalars['Int'];
  /** The id of the approval package that the contributor should be associated with. */
  fkPackage: Scalars['Int'];
};

/** An input for creating an approval package for a given deal. */
export type CreateApprovalPackageInput = {
  /** The review date for the approval package. */
  reviewDate?: Maybe<Scalars['DateTime']>;
  /** The ammendment number of the the approval package. */
  amendmentNumber?: Maybe<Scalars['Int']>;
  /** The id of the deal that the approval package belongs to. */
  fkDeal: Scalars['Int'];
  /** The id of the type of this approval package. */
  fkPackageType: Scalars['Int'];
  /** The id of the approval package template to use when creating this approval package. */
  fkPackageTemplate?: Maybe<Scalars['Int']>;
};

/** An input object used for adding a reviewer to an approval package. */
export type CreateApprovalPackageReviewerInput = {
  /** The id of the user that is the reviewer for the given approval package. */
  fkUser: Scalars['Int'];
  /** The id of the approval package that the reviewer should be associated with. */
  fkPackage: Scalars['Int'];
  /** The id of the status of the reviewer. */
  fkReviewerStatus: Scalars['Int'];
  /** The id of the firm that the reviewer is associated with. */
  fkFirmRepOrg?: Maybe<Scalars['Int']>;
};

/** An input for creating an approval package section. */
export type CreateApprovalPackageSectionInput = {
  /** The title of the section. */
  title: Scalars['String'];
  /** The id of the approval package that this section should be associated with. */
  fkApprovalPackage: Scalars['Int'];
};

/** An input for creating an approval package template. */
export type CreateApprovalPackageTemplateInput = {
  /** The name of this approval package template. */
  name: Scalars['String'];
};

/** An input object used for creating an approval package type. */
export type CreateApprovalPackageTypeInput = {
  /** The name of the approval package type. */
  name: Scalars['String'];
  /** The id of the default approval package template that should be used when creating an approval package of this type. */
  fkPackageTemplate?: Maybe<Scalars['Int']>;
  /** The id of the event that this approval package type should be associated with on the schedule for a given deal. */
  fkEvent?: Maybe<Scalars['Int']>;
};

/** An input object used for creating a bond. */
export type CreateBondInput = {
  fkDeal: Scalars['Int'];
  name?: Maybe<Scalars['String']>;
  isComplete: Scalars['Boolean'];
  bondDescription?: Maybe<Scalars['String']>;
  principalEntity?: Maybe<Scalars['String']>;
  fkFirmBondingCompany?: Maybe<Scalars['Int']>;
  obligeeBeneficiary?: Maybe<Scalars['String']>;
  premium?: Maybe<Scalars['Decimal']>;
  bondAmount?: Maybe<Scalars['Decimal']>;
  collateralAmount?: Maybe<Scalars['Decimal']>;
  locAmount?: Maybe<Scalars['Decimal']>;
  acceptedAlternatives?: Maybe<Scalars['String']>;
  breakdownBackup?: Maybe<Scalars['String']>;
  describedWork?: Maybe<Scalars['String']>;
  originalEffectiveDate?: Maybe<Scalars['DateTime']>;
  expires?: Maybe<Scalars['DateTime']>;
  expectedRelease?: Maybe<Scalars['DateTime']>;
  conditionsForRelease?: Maybe<Scalars['String']>;
  premiumRefundDue?: Maybe<Scalars['Decimal']>;
  notes?: Maybe<Scalars['String']>;
  improvementDeadline?: Maybe<Scalars['DateTime']>;
  premiumComment?: Maybe<Scalars['String']>;
  collateralNotes?: Maybe<Scalars['String']>;
  expiresNotes?: Maybe<Scalars['String']>;
};

/** An input object used for creating a building. */
export type CreateBuildingInput = {
  /** The id of the deal to assoctiate the building with. */
  fkDeal: Scalars['Int'];
  /** The name of the building. */
  name?: Maybe<Scalars['String']>;
  /** The unit count building. */
  unitCount?: Maybe<Scalars['Int']>;
  /** The address of the building. */
  address?: Maybe<Scalars['String']>;
  /** A field for providing any further details about the building. */
  notes?: Maybe<Scalars['String']>;
  /** The TCO date for the building. */
  tcoDate?: Maybe<Scalars['Date']>;
  /** The id of the building type. */
  fkBuildingType: Scalars['Int'];
  /** A flag of whether or not the TCO has been recieved or not. */
  isTcoReceived: Scalars['Boolean'];
};

/** An input object used for creating a change event. */
export type CreateChangeEventInput = {
  /** The id of the deal to assoctiate the change event with. */
  fkDeal: Scalars['Int'];
  /** The title of the change event. */
  title: Scalars['String'];
  /** The number of the change event. */
  number?: Maybe<Scalars['Int']>;
  /** The total of the change event according to the GC. */
  total?: Maybe<Scalars['Decimal']>;
  /** The schedule impact of the change event according to the GC (in # of days). */
  scheduleImpact?: Maybe<Scalars['Int']>;
  /** The total of the change event according to the owner. */
  ownerTotal?: Maybe<Scalars['Decimal']>;
  /** The schedule impact of the change event according to the owner (in # of days). */
  ownerScheduleImpact?: Maybe<Scalars['Int']>;
  /** The id of the type of the change event. */
  fkChangeEventType: Scalars['Int'];
  /** The id of the status of the change event. */
  fkChangeOrderStatus: Scalars['Int'];
  /** The id of the reason of the change event. */
  fkChangeEventReason?: Maybe<Scalars['Int']>;
  /** Wether or not the schedule impact is excusable. */
  isScheduleImpactExcusable?: Maybe<Scalars['Boolean']>;
  /** The id of the cost account that is the cost source for this change event. */
  fkGlAccountCostSource?: Maybe<Scalars['Int']>;
};

/** An input object used for creating a change order. */
export type CreateChangeOrderPackageInput = {
  /** The id of the deal to assoctiate the change order with. */
  fkDeal: Scalars['Int'];
  /** The title of the change order. */
  title: Scalars['String'];
  /** The number of the change order. */
  number?: Maybe<Scalars['Int']>;
  /** The total of the change order. */
  total?: Maybe<Scalars['Decimal']>;
  /** The schedule event of the change order. */
  scheduleImpact?: Maybe<Scalars['Int']>;
  /** The id of the status of the change order. */
  fkChangeOrderStatus: Scalars['Int'];
  /** Wether or not the schedule impact is excusable. */
  isScheduleImpactExcusable?: Maybe<Scalars['Boolean']>;
  /** The id of the cost account that is the cost source for this change order package. */
  fkGlAccountCostSource?: Maybe<Scalars['Int']>;
  /** The id of the parent change order of the potential change order. */
  fkChangeOrderPackageParent?: Maybe<Scalars['Int']>;
};

/** An input object used for creating a checklist category. */
export type CreateChecklistCategoryInput = {
  /** The name of the checklist category. */
  name: Scalars['String'];
  /** The foreign key of the checklist this category belongs to. */
  fkChecklist: Scalars['Int'];
};

export type CreateChecklistCategoryResult = ChecklistCategory | ChecklistCategoryAlreadyExists;

/** An input object used for creating a checklist. */
export type CreateChecklistInput = {
  /** The name of the checklist. */
  name: Scalars['String'];
  /** A brief description of the checklist. */
  description?: Maybe<Scalars['String']>;
};

/** An input object used for creating a checklist item. */
export type CreateChecklistItemInput = {
  /** The name of the checklist item. */
  name: Scalars['String'];
  /** The checklist that this item belongs to. */
  fkChecklist: Scalars['Int'];
  /** The category that the checklist item is under. */
  fkChecklistCategory: Scalars['Int'];
};

export type CreateChecklistItemResult = ChecklistItem | ChecklistItemAlreadyExists;

export type CreateChecklistResult = Checklist | ChecklistAlreadyExists;

/** An input object used for creating a comment. */
export type CreateCommentInput = {
  /** The id representing the subject of comment. */
  subjectId: Scalars['Int'];
  /** The body of the comment. */
  body: Scalars['String'];
};

export type CreateConfigurableEntityResult = ConfigurableEntity | ConfigurableEntityAlreadyExists;

/** An input object used for creating a contact */
export type CreateContactInput = {
  /** The first name of the contact. */
  firstName: Scalars['String'];
  /** The last name of the contact. */
  lastName: Scalars['String'];
  /** The title of the contact. */
  title?: Maybe<Scalars['String']>;
  /** The first line of the contact's address. */
  address1?: Maybe<Scalars['String']>;
  /** The second line of the contact's address. */
  address2?: Maybe<Scalars['String']>;
  /** The city of the contact's address. */
  city?: Maybe<Scalars['String']>;
  /** The zipcode of the contact's address. */
  zipcode?: Maybe<Scalars['String']>;
  /** The home phone number of the contact. */
  officePhone?: Maybe<Scalars['String']>;
  /** The cell phone number of the contact. */
  cellPhone?: Maybe<Scalars['String']>;
  /** The email address of the contact. */
  email?: Maybe<Scalars['String']>;
  /** The id corresponding to the state of the contact's address. */
  fkFirm?: Maybe<Scalars['Int']>;
  /** The id of the deal to assign the user to when creating. */
  fkDeal?: Maybe<Scalars['Int']>;
  /** The id corresponding to the state of the contact. */
  fkState?: Maybe<Scalars['Int']>;
  /** Notes for the contact. */
  notes?: Maybe<Scalars['String']>;
  /** Whether or not the contact to be created is considered a primary contact for its associated firm. */
  isPrimary?: Maybe<Scalars['Boolean']>;
  /** An identifier representing the pipeline that the contact is associated with. */
  fkPipeline: Scalars['Int'];
};

export type CreateContactResult = Contact | ContactAlreadyExists;

/** An input object used for creating a contract associated with the budget module. */
export type CreateContractInput = {
  /** The id of the deal that this contract should be associated with. */
  fkDeal: Scalars['Int'];
  /** The name of the contract. */
  name: Scalars['String'];
  /** An optional description of the contract. */
  description?: Maybe<Scalars['String']>;
  /** The id of the vendor that the contract is associated with. */
  fkFirm: Scalars['Int'];
  /** The total amount that the contract is for. This is the negotiated total for the contract, which may be different from the sum of all of the transactions associated with a given contract. */
  amount: Scalars['Decimal'];
};

/** An input object used for creating a deal event. */
export type CreateDealEventInput = {
  /** The event to be added to this deal. */
  fkEvent: Scalars['Int'];
  /** The id of the deal to add this event to. */
  fkDeal: Scalars['Int'];
  /** The start date of the event */
  startDate?: Maybe<Scalars['DateTime']>;
  /** The end date of the event */
  endDate?: Maybe<Scalars['DateTime']>;
  /** Whether or not the event is marked as complete */
  isComplete?: Maybe<Scalars['Boolean']>;
};

export type CreateDealEventResult = DealEvent | DealEventAlreadyExists;

/** An input object used for creating a deal */
export type CreateDealInput = {
  /** The name of the deal. */
  name: Scalars['String'];
  /** Another name for the deal (AKA Name). */
  otherName?: Maybe<Scalars['String']>;
  /** The first line of the address of a deal. */
  address1?: Maybe<Scalars['String']>;
  /** The second line of the address of a deal. */
  address2?: Maybe<Scalars['String']>;
  /** A flag to indicate if the deals location is entered as coordinates. */
  usesCoordinates?: Maybe<Scalars['Boolean']>;
  /** The city a deal is located in. */
  city?: Maybe<Scalars['String']>;
  /** The zipcode a deal is located in. */
  zipcode?: Maybe<Scalars['String']>;
  /** The latitude of a deal location. */
  latitude?: Maybe<Scalars['Decimal']>;
  /** The longitude of a deal location. */
  longitude?: Maybe<Scalars['Decimal']>;
  /** The parcel number of a deal location. */
  parcelNumber?: Maybe<Scalars['String']>;
  /** The opportunity zone of a deal. */
  isOpportunityZone?: Maybe<Scalars['Boolean']>;
  /** The date a deal was born on. */
  birthDate?: Maybe<Scalars['DateTime']>;
  /** The date a deal died on. */
  deathDate?: Maybe<Scalars['DateTime']>;
  /** The site location of a deal. */
  siteLocation?: Maybe<Scalars['String']>;
  /** The investment vision of a deal. */
  investmentVision?: Maybe<Scalars['String']>;
  /** The earnest money deposit of a deal. */
  earnestMoneyDeposit?: Maybe<Scalars['String']>;
  /** The background of a deal. */
  dealBackground?: Maybe<Scalars['String']>;
  /** The id representing the state of a deal. */
  fkState?: Maybe<Scalars['Int']>;
  /** The id of the region of a deal. */
  fkRegion?: Maybe<Scalars['Int']>;
  /** The id of the market a deal belongs to. */
  fkMarket?: Maybe<Scalars['Int']>;
  /** The id of the submarket a deal belongs to. */
  fkSubmarket?: Maybe<Scalars['Int']>;
  /** The id representing the msa of a deal. */
  fkMsa?: Maybe<Scalars['Int']>;
  /** The id representing the fund of a given deal. */
  fkFund?: Maybe<Scalars['Int']>;
  /** The id representing the fund entity of a deal. */
  fkFundEntity?: Maybe<Scalars['Int']>;
  /** The id of the hoa of a deal. */
  fkHoa?: Maybe<Scalars['Int']>;
  /** The id of the priority level of a deal. */
  fkPriorityLevel?: Maybe<Scalars['Int']>;
  /** The id of the source of a deal. */
  fkSource?: Maybe<Scalars['Int']>;
  /** The id representing the market segment of a deal. */
  fkMarketSegment?: Maybe<Scalars['Int']>;
  /** The id representing the market type of a deal. */
  fkMarketType?: Maybe<Scalars['Int']>;
  /** The id representing the type of a deal. */
  fkPipeline: Scalars['Int'];
  /** The id representing the proprietary level of a deal. */
  fkProprietaryLevel?: Maybe<Scalars['Int']>;
  /** The id representing the stage of a deal. */
  fkStage?: Maybe<Scalars['Int']>;
  /** The id representing the asset class of a deal. */
  fkAssetClass?: Maybe<Scalars['Int']>;
  /** The id representing the construction type of a deal. */
  fkConstructionType?: Maybe<Scalars['Int']>;
  /** The id representing the product type of a deal. */
  fkProductType?: Maybe<Scalars['Int']>;
  /** The id representing the readon why a deal was killed. */
  fkReasonKilled?: Maybe<Scalars['Int']>;
  /** The id representing the boker firm for a deal. */
  fkBrokerFirm?: Maybe<Scalars['Int']>;
  /** The id representing the broker contact for a deal. */
  fkBrokerContact?: Maybe<Scalars['Int']>;
  /** The id representing the seller firm for a deal. */
  fkSellerFirm?: Maybe<Scalars['Int']>;
  /** The id representing the seller contact for a deal. */
  fkSellerContact?: Maybe<Scalars['Int']>;
  /** The id representing the buyer firm for a deal. */
  fkBuyerFirm?: Maybe<Scalars['Int']>;
  /** The id representing the contact of the buyer for a deal. */
  fkBuyerContact?: Maybe<Scalars['Int']>;
  /** Represents if the deal is public to all members of an organizaton. */
  isPrivate: Scalars['Boolean'];
  /** For a public deal, represents if members of an organization have edit rights for this deal. */
  publicCanEdit: Scalars['Boolean'];
  /** If the deal uses working days vs calendar days for the schedule delay tracking. */
  isWorkingDays?: Maybe<Scalars['Boolean']>;
  /** The Legal Entity of the deal */
  legalEntity?: Maybe<Scalars['String']>;
};

/** An input object used for creating a deal merit */
export type CreateDealMeritInput = {
  /** The title of the merit. */
  title: Scalars['String'];
  /** A brief description of the merit. */
  meritDescription: Scalars['String'];
  /** A key for the deal a merit belongs to. */
  fkDeal: Scalars['Int'];
};

export type CreateDealResult = Deal | DealAlreadyExists;

export type CreateDealStageResult = DealStage | DealStageAlreadyExists;

/** An input object used for creating an event. */
export type CreateEventInput = {
  /** The name of the event. */
  name: Scalars['String'];
  /** The description of the event. */
  description?: Maybe<Scalars['String']>;
  /** The order id of the event. */
  orderId: Scalars['Decimal'];
  type: DealEventTypeEnum;
  /** The id of the category for a given event. */
  fkEventCategory?: Maybe<Scalars['Int']>;
  /** The id of an event that is the parent of this event. */
  fkParent?: Maybe<Scalars['Int']>;
};

export type CreateEventResult = Event | EventAlreadyExists;

/** An input object used for creating a firm */
export type CreateFirmInput = {
  /** The name of the firm. */
  name: Scalars['String'];
  /** The website of the firm. */
  website?: Maybe<Scalars['String']>;
  /** A brief description of the firm. */
  description?: Maybe<Scalars['String']>;
  /** An identifier representing the type of the firm. */
  fkFirmType?: Maybe<Scalars['Int']>;
  /** An identifier representing the MSA where the firm is headquartered. */
  fkMsaHq?: Maybe<Scalars['Int']>;
  /** An identifier representing the Market where the firm is headquartered. */
  fkMarketHq?: Maybe<Scalars['Int']>;
  /** An identifier representing the investment type appetite of the firm. */
  fkInvestmentTypeAppetite?: Maybe<Scalars['Int']>;
  /** An identifier representing the status of the firm from a CRM standpooint. */
  fkCrmStatus?: Maybe<Scalars['Int']>;
  /** An identifier representing the user who is the relationship lead for this firm. */
  fkUserRelationshipLead?: Maybe<Scalars['Int']>;
  /** An identifier representing the pipeline that the firm is associated with. */
  fkPipeline: Scalars['Int'];
  /** An identifier representing the primary region that the firm is associated with. */
  fkPrimaryRegion?: Maybe<Scalars['Int']>;
};

/** An input object used for creating a firm region */
export type CreateFirmRegionInput = {
  /** The id of the firm. */
  fkFirm: Scalars['Int'];
  /** The id of the region. */
  fkRegion: Scalars['Int'];
  /** Whether or not the region is the primary region. */
  isPrimaryRegion?: Maybe<Scalars['Boolean']>;
};

/** An input for creating a forecast rule for use in deal budgets. */
export type CreateForecastRuleInput = {
  description: Scalars['String'];
  fkGlAccount: Scalars['Int'];
  fkForecastRuleGroup?: Maybe<Scalars['Int']>;
  fkForecastRuleType?: Maybe<Scalars['Int']>;
  fkDistributionType: Scalars['Int'];
  fkEventStart: Scalars['Int'];
  fkEventEnd: Scalars['Int'];
  milestoneAdjustmentStart: Scalars['Int'];
  milestoneAdjustmentEnd: Scalars['Int'];
  frequency: Scalars['Int'];
  startMonth?: Maybe<Scalars['Int']>;
  dollarAmount?: Maybe<Scalars['Decimal']>;
  dollarPercent?: Maybe<Scalars['Decimal']>;
  isDisabled: Scalars['Boolean'];
  usesBalance: Scalars['Boolean'];
  isSliding: Scalars['Boolean'];
  phaseSpendingCapPercent?: Maybe<Scalars['Decimal']>;
  fkEventEndUsesStartDate: Scalars['Boolean'];
  fkEventStartUsesStartDate: Scalars['Boolean'];
};

/** An input object used for creating a GC estimate. */
export type CreateGcEstimateInput = {
  /** The id of the deal to assoctiate the GC estimate with. */
  fkDeal: Scalars['Int'];
  /** The id of the type of this estimate. */
  fkEstimateType: Scalars['Int'];
  /** The id of the source of this estimate. */
  fkFirmSource: Scalars['Int'];
  /** The status of the estimate. */
  description?: Maybe<Scalars['String']>;
  /** The total price of the estimate. */
  totalPrice?: Maybe<Scalars['Decimal']>;
  /** The price per unit of the estimate. */
  pricePerUnit?: Maybe<Scalars['Decimal']>;
  /** The price per square foot of the estimate. */
  pricePerNrsf?: Maybe<Scalars['Decimal']>;
  /** Whether or not the estimate has been approved. */
  isApproved?: Maybe<Scalars['Boolean']>;
  /** A comment for the estimate. */
  comment?: Maybe<Scalars['String']>;
};

/** An input for creating an account for use in deal budgets. */
export type CreateGlAccountInput = {
  name: Scalars['String'];
  code: Scalars['String'];
  displayCode?: Maybe<Scalars['String']>;
  fkAccountType: Scalars['Int'];
  fkAccountCategory: Scalars['Int'];
};

export type CreateGlAccountResult = GlAccount | ErrorResult;

/** An input for creating an account tree for use in deal budgets. */
export type CreateGlAccountTreeInput = {
  name: Scalars['String'];
  fkReportType?: Maybe<Scalars['Int']>;
};

export type CreateGlAccountTreeResult = GlAccountTree | ErrorResult;

/** An input object used for creating a group. */
export type CreateGroupInput = {
  /** The name of the group. */
  name: Scalars['String'];
  /** The IDs of the users that should be assigned to the group on creation. */
  userIds: Array<Scalars['Int']>;
};

/** An input object used for creating an interaction with a firm and contacts */
export type CreateInteractionInput = {
  name: Scalars['String'];
  interactionDate: Scalars['Date'];
  fkInteractionType: Scalars['Int'];
  interactionUserIds: Array<Scalars['Int']>;
  interactionContactIds: Array<Scalars['Int']>;
  interactionFirmIds: Array<Scalars['Int']>;
  dealIds: Array<Scalars['Int']>;
  notes: Scalars['String'];
  followUpDate?: Maybe<Scalars['Date']>;
};

/** An input for creating an interest rate. */
export type CreateInterestRateInput = {
  startDate: Scalars['DateTime'];
  value: Scalars['Decimal'];
};

export type CreateInterestRateResult = InterestRate | InterestRateAlreadyExists;

/** An input object used for creating an interest rate transaction. */
export type CreateInterestRateTransactionInput = {
  /** The id of the debt account associated with this transaction. */
  debtAccount: Scalars['Int'];
  /** The id of the deal this transaction is associated with. */
  fkDeal: Scalars['Int'];
  /** The id of the interest account associated with this organization. */
  interestAccount: Scalars['Int'];
  /** The date at the start of the month for this transaction. */
  startOfMonth: Scalars['DateTime'];
};

/** An input object used for creating a key risk */
export type CreateKeyRiskInput = {
  /** The title of the key risk. */
  title: Scalars['String'];
  /** A brief description of the risk. */
  riskDescription: Scalars['String'];
  /** A brief description of the mitigant for this risk. */
  mitigantDescription: Scalars['String'];
  /** A key representing the deal a key risk belongs to. */
  fkDeal: Scalars['Int'];
};

/** An input object used for creating a label. */
export type CreateLabelInput = {
  /** The name of the label. */
  name: Scalars['String'];
  /** The color of the label. */
  color?: Maybe<Scalars['String']>;
};

/** An input for creating a metric for use with an analysis. */
export type CreateMetricInput = {
  name: Scalars['String'];
  format: MetricFormatEnum;
  unit?: Maybe<Scalars['String']>;
  precision?: Maybe<Scalars['Int']>;
  /** The amount to multiply the metric by. */
  scale?: Maybe<Scalars['Decimal']>;
};

export type CreateMetricResult = Metric | MetricAlreadyExists;

export type CreatePackageResult = ApprovalPackage | ErrorResult;

export type CreatePackageTemplateResult = TemplateApprovalPackage | ErrorResult;

export type CreatePackageTypeResult = ApprovalPackageType | ErrorResult;

/** An input object used for creating a partner role. */
export type CreatePartnerRoleInput = {
  /** The name of the role. */
  name: Scalars['String'];
  /** The description of the role. */
  description?: Maybe<Scalars['String']>;
};

/** An input for creating an account cash forecast offset rule for use in deal budgets. */
export type CreatePipelineAccountOffsetInput = {
  fkPipeline: Scalars['Int'];
  offset: Scalars['Int'];
  fkGlAccount: Scalars['Int'];
  fkEventOffsetMilestone?: Maybe<Scalars['Int']>;
  fkCustomFilter?: Maybe<Scalars['Int']>;
};

/** An input for creating an account contingency categorization. */
export type CreatePipelineContingencyCategoryInput = {
  fkPipeline: Scalars['Int'];
  fkGlAccount: Scalars['Int'];
  fkContingencyCategory: Scalars['Int'];
};

/** An input for creating a general cash forecast offset rule for use in deal budgets. */
export type CreatePipelineGeneralOffsetInput = {
  fkPipeline: Scalars['Int'];
  offset: Scalars['Int'];
  fkEventOffsetMilestone?: Maybe<Scalars['Int']>;
  fkCustomFilter?: Maybe<Scalars['Int']>;
};

/** An input object used for creating a pipeline. */
export type CreatePipelineInput = {
  /** The name of the pipeline */
  name: Scalars['String'];
  /** The description of the pipeline. */
  description: Scalars['String'];
};

/** An input group for creating dynamic portfolio filters. */
export type CreatePortfolioFilterGroupInput = {
  clauses: Array<CreatePortfolioFilterInput>;
};

/** An input for creating a filter for a dynamic portfolio. */
export type CreatePortfolioFilterInput = {
  fkCustomFilters: Scalars['Int'];
  type: PortfolioFilterTypeEnum;
  fkSourceAttribute: Scalars['Int'];
  value: Scalars['String'];
  isAnd: Scalars['Boolean'];
};

/** An input object used for creating a portfolio */
export type CreatePortfolioInput = {
  /** The name for the portfolio. */
  name: Scalars['String'];
  /** A brief description of the portfolio. */
  description?: Maybe<Scalars['String']>;
  /** Whether the Portfolio is private or not. */
  isPrivate: Scalars['Boolean'];
  /** Whether members of the organization can edit the portfolio, if the Portfolio is public. */
  publicCanEdit: Scalars['Boolean'];
};

export type CreatePortfolioResult = Portfolio | PortfolioAlreadyExists;

/** An input object used for creating a portfolio view */
export type CreatePortfolioViewInput = {
  /** The id of the portfolio that this view will belong to. */
  fkPortfolio: Scalars['Int'];
  /** The id of the analysis type that this view will display data from. */
  fkAnalysisType?: Maybe<Scalars['Int']>;
  /** The name for the portfolio view */
  name: Scalars['String'];
};

/** An input object used for creating a potential change order. */
export type CreatePotentialChangeOrderInput = {
  /** The id of the deal to assoctiate the potential change order with. */
  fkDeal: Scalars['Int'];
  /** The title of the potential change order. */
  title: Scalars['String'];
  /** The number of the potential change order. */
  number?: Maybe<Scalars['Int']>;
  /** The total of the potential change order according to the GC. */
  total?: Maybe<Scalars['Decimal']>;
  /** The schedule impact of the potential change order according to the GC (in # of days). */
  scheduleImpact?: Maybe<Scalars['Int']>;
  /** The total of the potential change order according to the owner. */
  ownerTotal?: Maybe<Scalars['Decimal']>;
  /** The schedule impact of the potential change order according to the owner (in # of days). */
  ownerScheduleImpact?: Maybe<Scalars['Int']>;
  /** The id of the status of the potential change order. */
  fkChangeOrderStatus: Scalars['Int'];
  /** The id of the type of the potential change order. */
  fkPotentialChangeOrderType: Scalars['Int'];
  /** The id of the parent change order of the potential change order. */
  fkChangeOrderPackage?: Maybe<Scalars['Int']>;
  /** Wether or not the schedule impact is excusable. */
  isScheduleImpactExcusable?: Maybe<Scalars['Boolean']>;
  /** The id of the cost account that is the cost source for this potential change order. */
  fkGlAccountCostSource?: Maybe<Scalars['Int']>;
};

/** An input object used for publishing a capital forecast. */
export type CreatePublishedCapitalForecastInput = {
  /** The comment to attach to the published capital forecast instance that will be created. */
  comment: Scalars['String'];
  /** The id of the deal to publish this capital forecast instance for. */
  fkDeal: Scalars['Int'];
};

/** An input object used for creating a deal event. */
export type CreatePublishedDealEventInput = {
  /** The event to be added to this deal. */
  fkEvent: Scalars['Int'];
  /** The id of the deal to add this event to. */
  fkDeal: Scalars['Int'];
  /** The id of the published schedule instance to add this event to. */
  fkPublishedInstanceSchedule: Scalars['Int'];
  /** The start date of the event */
  startDate?: Maybe<Scalars['DateTime']>;
  /** The end date of the event */
  endDate?: Maybe<Scalars['DateTime']>;
};

export type CreatePublishedDealEventResult = PublishedDealEvent | PublishedDealEventAlreadyExists;

/** An input object used for creating a published schedule instance */
export type CreatePublishedScheduleInstanceInput = {
  /** The id of the deal that the instance will be created for. */
  fkDeal: Scalars['Int'];
  /** A brief description of the published instance */
  description?: Maybe<Scalars['String']>;
};

/** An input object used for creating a revision detail. */
export type CreateRevisionAccountInput = {
  /** The revision that this adjustment applies to. */
  fkRevision: Scalars['Int'];
  /** The account that this revision adjustment applies to. */
  fkGlAccount: Scalars['Int'];
  /** The amount by which the specified account is being adjusted. */
  amount: Scalars['Decimal'];
  /** If the budget is pursuit or not. */
  isPursuit: Scalars['Boolean'];
};

/** An input object used for creating a budget revision. */
export type CreateRevisionInput = {
  /** The name of the revision. */
  name: Scalars['String'];
  /** The identifier of the deal that this budget revision is associated with. */
  fkDeal: Scalars['Int'];
};

/** An input object used for creating a status update */
export type CreateStatusUpdateInput = {
  /** The title of the status update. */
  title: Scalars['String'];
  /** The summary message for this status update. */
  message: Scalars['String'];
  /** The id representing the status of the status update. */
  fkDealStatus: Scalars['Int'];
  /** The id representing the deal that this status update belongs to. */
  fkDeal: Scalars['Int'];
  sections: Array<CreateStatusUpdateSectionInput>;
  labelIds: Array<Scalars['Int']>;
};

/** An input object used for creating a status update section */
export type CreateStatusUpdateSectionInput = {
  /** The title of this deal status update section. */
  title: Scalars['String'];
  /** The content of this deal status update section. */
  content?: Maybe<Scalars['String']>;
};

/** An input object used for creating a status update template */
export type CreateStatusUpdateTemplateInput = {
  /** The title of the status update template. */
  title: Scalars['String'];
  /** The summary message for this status update template. */
  message: Scalars['String'];
  sections: Array<CreateStatusUpdateTemplateSectionInput>;
  labelIds: Array<Scalars['Int']>;
};

/** An input object used for creating a status update template section */
export type CreateStatusUpdateTemplateSectionInput = {
  /** The title of this deal status update template section. */
  title: Scalars['String'];
  /** The content of this deal status update template section. */
  content?: Maybe<Scalars['String']>;
};

/** An input object used for creating a team member role. */
export type CreateTeamMemberRoleInput = {
  /** The name of the role. */
  name: Scalars['String'];
  /** The description of the role. */
  description?: Maybe<Scalars['String']>;
};

/** An input for creating a text metric for use with an analysis. */
export type CreateTextMetricInput = {
  name: Scalars['String'];
};

export type CreateTextMetricResult = TextMetric | TextMetricAlreadyExists;

/** A preview record for details of a transaction. */
export type CreateTransactionDetailInput = {
  /** A month end date that the included amounts are be associated with. */
  date: Scalars['Date'];
  /** The amount that is allocated in the month specified by the date. */
  amount?: Maybe<Scalars['Decimal']>;
  /** The type of this preview record. */
  type: TransactionPreviewTypeEnum;
};

/** An input object used for creating a transaction. */
export type CreateTransactionInput = {
  /** A short description of the transaction. */
  description: Scalars['String'];
  /** The id of the deal this transaction is associated with. */
  fkDeal: Scalars['Int'];
  /** The id of the account that this transaction is for. */
  fkGlAccount: Scalars['Int'];
  /** The amount of the transaction. */
  amount?: Maybe<Scalars['Decimal']>;
  milestoneAdjustmentStart?: Maybe<Scalars['Int']>;
  milestoneAdjustmentEnd?: Maybe<Scalars['Int']>;
  /** The id of the event used to denote the start of this transaction. Only provide this if you are not providing a start date. */
  fkDealEventStart?: Maybe<Scalars['Int']>;
  /** The id of the event used to denote the end of this transaction. Only provide this if you are not providing an end date. */
  fkDealEventEnd?: Maybe<Scalars['Int']>;
  /** The date denoting the end of the transaction. Only provide this if you are not providing an end event. */
  periodEndDateEnd?: Maybe<Scalars['Date']>;
  /** The date denoting the start of the transaction. Only provide this if you are not providing a start event. */
  periodEndDateStart?: Maybe<Scalars['Date']>;
  /** A true value indicates that the start date of the transaction should be derived from the start event's start date. A false value indicates that the end date should be used instead. */
  fkDealEventStartUsesStartDate?: Maybe<Scalars['Boolean']>;
  /** A true value indicates that the end date of the transaction should be derived from the end event's start date. A false value indicates that the end date should be used instead. */
  fkDealEventEndUsesStartDate?: Maybe<Scalars['Boolean']>;
  /** The id of the vendor that the transaction is associated with. */
  fkFirm?: Maybe<Scalars['Int']>;
  /** The id of the contract that the transaction is associated with. */
  fkContract?: Maybe<Scalars['Int']>;
  /** The id of the distribution type of the transaction. */
  fkDistributionType: Scalars['Int'];
  /** The id of the Default Forecast Rule that created this transaction, if any. */
  fkForecastRule?: Maybe<Scalars['Int']>;
  /** A flag denoting if this tranasction should be marked as refundable. */
  isRefundable: Scalars['Boolean'];
  /** A flag denoting if this tranasction has cash payment overrides. */
  hasCashPaymentOverride: Scalars['Boolean'];
  /** A flag denoting if this tranasction is marked as an override. */
  isOverride: Scalars['Boolean'];
};

/** CrmStatus */
export type CrmStatus = {
  __typename?: 'CrmStatus';
  /** When the object was created. */
  createdAt: Scalars['DateTimeOffset'];
  createdBy: Actor;
  /** When the object was deleted. */
  deletedAt?: Maybe<Scalars['DateTimeOffset']>;
  deletedBy?: Maybe<Actor>;
  id: Scalars['Int'];
  name: Scalars['String'];
  /** When the object was last updated. */
  updatedAt: Scalars['DateTimeOffset'];
  updatedBy: Actor;
};

/** CustomFilters */
export type CustomFilters = {
  __typename?: 'CustomFilters';
  /** When the object was created. */
  createdAt: Scalars['DateTimeOffset'];
  createdBy: Actor;
  /** When the object was deleted. */
  deletedAt?: Maybe<Scalars['DateTimeOffset']>;
  deletedBy?: Maybe<Actor>;
  id: Scalars['Int'];
  name: Scalars['String'];
  sign: Scalars['String'];
  /** When the object was last updated. */
  updatedAt: Scalars['DateTimeOffset'];
  updatedBy: Actor;
};




export type Deal = DealInfo & {
  __typename?: 'Deal';
  /** A list of forecasted cost account summaries per period. */
  accountForecasts?: Maybe<Array<GlAccountSummary>>;
  /** A list of actual cost account summaries per period. */
  accountTreeActuals?: Maybe<Array<GlAccountSummary>>;
  /** A list of forecasted cost account summaries per period. */
  accountTreeForecasts?: Maybe<Array<GlAccountSummary>>;
  address1?: Maybe<Scalars['String']>;
  address2?: Maybe<Scalars['String']>;
  /** A list of all analyses for this deal. */
  analyses?: Maybe<Array<DealAnalysis>>;
  /** A given analysis instance for this deal. */
  analysis?: Maybe<DealAnalysis>;
  /** A list of approval packages for a specific deal. */
  approvalPackages?: Maybe<Array<ApprovalPackage>>;
  /** A list of approved budget totals per cost code. */
  approvedBudget?: Maybe<Array<CostAccountBudgetSummary>>;
  archiveDate?: Maybe<Scalars['DateTimeOffset']>;
  archiveReason?: Maybe<Scalars['String']>;
  /** The archive status of this deal. */
  archiveStatus?: Maybe<DealArchiveStatusEnum>;
  /** A list of uploaded artifact documents for this deal. */
  artifacts?: Maybe<Array<DealArtifact>>;
  /** The deal's asset class. */
  assetClass?: Maybe<AssetClass>;
  birthDate: Scalars['Date'];
  /** A specific bond. */
  bond?: Maybe<Bond>;
  /** A list of bonds for a specific deal. */
  bonds?: Maybe<Array<Bond>>;
  /** The assigned brokers main contact. */
  brokerContact?: Maybe<ContactInfo>;
  /** The firm of the broker assigned to this deal. */
  brokerFirm?: Maybe<FirmInfo>;
  /** A specific building. */
  building?: Maybe<Building>;
  /** A list of buildings for a specific deal. */
  buildings?: Maybe<Array<Building>>;
  /** The assigned buyers main contact. */
  buyerContact?: Maybe<ContactInfo>;
  /** The firm of the buyer assigned to this deal. */
  buyerFirm?: Maybe<FirmInfo>;
  /** A specific change event. */
  changeEvent?: Maybe<ChangeEvent>;
  /** A list of change events for a specific deal. */
  changeEvents?: Maybe<Array<ChangeEvent>>;
  /** An ouput reconciling the budget changes from change events, PCOs, and change orders. */
  changeItemReconciliation?: Maybe<Array<ChangeItemReconciliationDetails>>;
  /** A specific change order package. */
  changeOrderPackage?: Maybe<ChangeOrderPackage>;
  /** A list of change order packages for a specific deal. */
  changeOrderPackages?: Maybe<Array<ChangeOrderPackage>>;
  /** A list of checklists for a specific deal. */
  checklists?: Maybe<Array<DealChecklist>>;
  city?: Maybe<Scalars['String']>;
  /** The deal's construction type. */
  constructionType?: Maybe<ConstructionType>;
  /** A list of contacts and that are assigned to a specific deal. */
  contacts?: Maybe<Array<ContactInfo>>;
  /** An ouput summarizing the contingency accounts for a deal. */
  contingencyReconciliationSummary?: Maybe<ContingencyReconciliationSummary>;
  /** A specific budget contract. */
  contract?: Maybe<Contract>;
  /** A list of contracts for a specific deal. */
  contracts?: Maybe<Array<Contract>>;
  /** When the object was created. */
  createdAt: Scalars['DateTimeOffset'];
  createdBy: Actor;
  /** The current analysis for this deal. */
  currentAnalysis?: Maybe<DealAnalysis>;
  currentGcContingencyBudget?: Maybe<Scalars['Decimal']>;
  /** The most current status update for the specified deal. */
  currentStatus?: Maybe<DealStatusUpdate>;
  /** Whether or not the current user has editing rights on this deal. */
  currentUserCanEdit: Scalars['Boolean'];
  /** Whether or not the user is an owner of this deal. */
  currentUserIsOwner: Scalars['Boolean'];
  dealBackground?: Maybe<Scalars['String']>;
  /** A list of merits for a specific deal. */
  dealMerits?: Maybe<Array<Merit>>;
  /** The deal type of the deal. */
  dealType?: Maybe<DealType>;
  deathDate?: Maybe<Scalars['DateTime']>;
  debt?: Maybe<Scalars['Decimal']>;
  /** When the object was deleted. */
  deletedAt?: Maybe<Scalars['DateTimeOffset']>;
  deletedBy?: Maybe<Actor>;
  /** A list of documents for a specific deal. */
  documents?: Maybe<Array<DocumentTreeItem>>;
  earnestMoneyDeposit?: Maybe<Scalars['String']>;
  entryCapRate?: Maybe<Scalars['Decimal']>;
  /** A specific event for a deal. */
  event?: Maybe<DealEvent>;
  /** A list of events for a specific deal. */
  events?: Maybe<Array<DealEvent>>;
  exitCapRate?: Maybe<Scalars['Decimal']>;
  /** The Final Residential TCO event for this deal, if it exists. */
  finalResidentialTCO?: Maybe<DealEvent>;
  forecastedGcContingencyChanges?: Maybe<Scalars['Decimal']>;
  /** A list of forecast rules for a specific deal. */
  forecastRules?: Maybe<Array<ForecastRuleDeal>>;
  /** The fund that the deal belongs to. */
  fund?: Maybe<Fund>;
  /** The fund that the deal belongs to. */
  fundEntity?: Maybe<FundEntity>;
  gcContractSavingsPercentage?: Maybe<Scalars['Decimal']>;
  /** A specific GC estimate. */
  gcEstimate?: Maybe<GcEstimate>;
  /** A list of GC estimates for a specific deal. */
  gcEstimates?: Maybe<Array<GcEstimate>>;
  /** The general contracting firm for this deal. */
  generalContractor?: Maybe<FirmInfo>;
  /** An ouput summarizing the General Contractor Contract. */
  generalContractorSummary?: Maybe<GeneralContractorSummary>;
  /** A list of groups that are assigned to this deal and their associated permissions. */
  groups?: Maybe<Array<DealRoleGroup>>;
  /** A list of hidden events for a specific deal. */
  hiddenEvents?: Maybe<Array<DealEvent>>;
  /** The HOA type of the deal. */
  hoa?: Maybe<Hoa>;
  /** The id of the portfolio. */
  id: Scalars['Int'];
  /** Interactions with this deal. */
  interactions: Array<Interaction>;
  investmentVision?: Maybe<Scalars['String']>;
  irr?: Maybe<Scalars['Decimal']>;
  isOpportunityZone?: Maybe<Scalars['Boolean']>;
  isPrivate: Scalars['Boolean'];
  isWorkingDays?: Maybe<Scalars['Boolean']>;
  /** A list of key risks for a specific deal. */
  keyRisks?: Maybe<Array<KeyRisk>>;
  latitude?: Maybe<Scalars['Decimal']>;
  legalEntity?: Maybe<Scalars['String']>;
  longitude?: Maybe<Scalars['Decimal']>;
  /** The market that the deal belongs to. */
  market?: Maybe<Market>;
  /** The market segment for the deal. */
  marketSegment?: Maybe<MarketSegment>;
  /** The market type for the deal. */
  marketType?: Maybe<MarketType>;
  /** A list of milestones for a specific deal that are due within the next two weeks. */
  milestones?: Maybe<Array<DealEvent>>;
  moic?: Maybe<Scalars['Decimal']>;
  /** The name of the portfolio. */
  name: Scalars['String'];
  nrsf?: Maybe<Scalars['Int']>;
  numberOfUnits?: Maybe<Scalars['Int']>;
  /** The original budget revision */
  originalBudgetRevision?: Maybe<Revision>;
  originalGcContingencyBudget?: Maybe<Scalars['Decimal']>;
  originalGcContractCommittedAmount?: Maybe<Scalars['Decimal']>;
  /** The original GC Contract schedule instance */
  originalGcContractSchedule?: Maybe<PublishedScheduleInstance>;
  otherName?: Maybe<Scalars['String']>;
  parcelNumber?: Maybe<Scalars['String']>;
  /** A list of deal partners and their associated roles for a specific deal. */
  partners?: Maybe<Array<DealPartner>>;
  /** A list of pending budget totals per cost code. */
  pendingBudget?: Maybe<Array<CostAccountBudgetSummary>>;
  /** A list of pinned events for a deal. */
  pinnedEvents?: Maybe<Array<DealPinnedEvent>>;
  /** A list of pinned metrics for a deal. */
  pinnedMetrics?: Maybe<Array<DealPinnedMetric>>;
  /** A list of pinned partners for a deal. */
  pinnedPartnerRoles?: Maybe<Array<DealPinnedPartnerRole>>;
  /** A list of pinned team members for a deal. */
  pinnedTeamMemberRoles?: Maybe<Array<DealPinnedTeamMemberRole>>;
  /** The type of deal. */
  pipeline: Pipeline;
  /** A specific potential change order. */
  potentialChangeOrder?: Maybe<PotentialChangeOrder>;
  /** A list of potential change orders for a specific deal. */
  potentialChangeOrders?: Maybe<Array<PotentialChangeOrder>>;
  /** The priority level of the deal. */
  priorityLevel?: Maybe<DealPriorityLevel>;
  /** The deal's product type. */
  productType?: Maybe<ProductType>;
  /** The proprietary level of the deal. */
  proprietaryLevel?: Maybe<ProprietaryLevel>;
  publicCanEdit: Scalars['Boolean'];
  /** A specific instance of a published capital forecast. */
  publishedCapitalForecast?: Maybe<PublishedCapitalForecast>;
  /** A list of published capital forecasts for a specific deal. */
  publishedCapitalForecasts?: Maybe<Array<PublishedCapitalForecast>>;
  /** A specific published schedule instance. */
  publishedScheduleInstance?: Maybe<PublishedScheduleInstance>;
  /** A list of published schedule instances for this deal. */
  publishedScheduleInstances?: Maybe<Array<PublishedScheduleInstance>>;
  purchasePrice?: Maybe<Scalars['Decimal']>;
  /** The region that this deal resides in. */
  region?: Maybe<Region>;
  requiredEquity?: Maybe<Scalars['Decimal']>;
  /** A revision for a specific deal. */
  revision?: Maybe<Revision>;
  /** A list of revisions for a specific deal. */
  revisions?: Maybe<Array<Revision>>;
  scheduleUpdateNarrativeRequired?: Maybe<Scalars['Boolean']>;
  /** The assigned sellers main contact. */
  sellerContact?: Maybe<ContactInfo>;
  /** The firm of the seller assigned to this deal. */
  sellerFirm?: Maybe<FirmInfo>;
  /** The source of the deal. */
  source?: Maybe<FirmInfo>;
  spread?: Maybe<Scalars['Decimal']>;
  /** The current stage of the deal. */
  stage?: Maybe<DealStage>;
  /** The state that this deal resides in. */
  state?: Maybe<State>;
  /** A list of status updates belonging to a specific deal. */
  statusUpdate?: Maybe<DealStatusUpdate>;
  /** A list of status updates belonging to a specific deal. */
  statusUpdates?: Maybe<Array<DealStatusUpdate>>;
  /** The structure of the deal. */
  structure?: Maybe<Structure>;
  /** The submarket that the deal resides within. */
  submarket?: Maybe<Submarket>;
  /** A list of tasks for a specific deal. */
  tasks?: Maybe<Array<Task>>;
  /** A list of team members and their associated roles for a specific deal. */
  team?: Maybe<Array<DealTeamMemberUser>>;
  /** A list of transactions for a specific deal. */
  transaction?: Maybe<Transaction>;
  /** A list of transaction details for a specific deal, account, and period. */
  transactionPeriodSummaries?: Maybe<Array<TransactionPeriodSummary>>;
  /** A list of transactions for a specific deal. */
  transactions?: Maybe<Array<Transaction>>;
  unleverredIrr?: Maybe<Scalars['Decimal']>;
  /** When the object was last updated. */
  updatedAt: Scalars['DateTimeOffset'];
  updatedBy: Actor;
  /** A list of users that are assigned to this deal and their associated permissions. */
  users?: Maybe<Array<DealRoleUser>>;
  usesCoordinates?: Maybe<Scalars['Boolean']>;
  usesLatestAnalysisAsCurrent?: Maybe<Scalars['Boolean']>;
  yearBuilt?: Maybe<Scalars['Int']>;
  zipcode?: Maybe<Scalars['String']>;
};


export type DealAccountForecastsArgs = {
  accountIds: Array<Scalars['Int']>;
  excludeTransactionId?: Maybe<Scalars['Int']>;
  start?: Maybe<Scalars['Date']>;
  end?: Maybe<Scalars['Date']>;
};


export type DealAccountTreeActualsArgs = {
  fkGlAccountTree: Scalars['Int'];
};


export type DealAccountTreeForecastsArgs = {
  fkGlAccountTree: Scalars['Int'];
};


export type DealAnalysisArgs = {
  id: Scalars['Int'];
};


export type DealApprovedBudgetArgs = {
  fkGlAccountTree: Scalars['Int'];
};


export type DealBondArgs = {
  id: Scalars['Int'];
};


export type DealBuildingArgs = {
  id: Scalars['Int'];
};


export type DealChangeEventArgs = {
  id: Scalars['Int'];
};


export type DealChangeOrderPackageArgs = {
  id: Scalars['Int'];
};


export type DealContractArgs = {
  id: Scalars['Int'];
};


export type DealDocumentsArgs = {
  fkFolder?: Maybe<Scalars['Int']>;
};


export type DealEventArgs = {
  id: Scalars['Int'];
};


export type DealGcEstimateArgs = {
  id: Scalars['Int'];
};


export type DealPendingBudgetArgs = {
  fkGlAccountTree: Scalars['Int'];
};


export type DealPotentialChangeOrderArgs = {
  id: Scalars['Int'];
};


export type DealPublishedCapitalForecastArgs = {
  id: Scalars['Int'];
};


export type DealPublishedScheduleInstanceArgs = {
  id: Scalars['Int'];
};


export type DealRevisionArgs = {
  id: Scalars['Int'];
};


export type DealStatusUpdateArgs = {
  id: Scalars['Int'];
};


export type DealTransactionArgs = {
  id: Scalars['Int'];
};


export type DealTransactionPeriodSummariesArgs = {
  fkGlAccount: Scalars['Int'];
  period: Scalars['Date'];
};

/** A result type given when the deal that was attempted to be created already exists. */
export type DealAlreadyExists = Error & {
  __typename?: 'DealAlreadyExists';
  /** The existing deal with matching properties. */
  deal?: Maybe<Deal>;
  message: Scalars['String'];
};

/** An underwriting analysis instance. */
export type DealAnalysis = {
  __typename?: 'DealAnalysis';
  /** A list of approval packages for a specific deal. */
  archivedApprovalPackages?: Maybe<Array<ApprovalPackage>>;
  /** When the analysis was archived. */
  archiveDate?: Maybe<Scalars['DateTimeOffset']>;
  archivedBy?: Maybe<Actor>;
  /** When the object was created. */
  createdAt: Scalars['DateTimeOffset'];
  createdBy: Actor;
  /** When the object was deleted. */
  deletedAt?: Maybe<Scalars['DateTimeOffset']>;
  deletedBy?: Maybe<Actor>;
  /** The a description of the analysis. */
  description?: Maybe<Scalars['String']>;
  /** A list of the event instances of this analysis and their values. */
  events?: Maybe<Array<AnalysisEvent>>;
  /** The type of the analysis */
  fkAnalysisType?: Maybe<Scalars['Int']>;
  /** The id of the analysis. */
  id: Scalars['Int'];
  /** A list of the metric instances of this analysis and their values. */
  metrics?: Maybe<Array<AnalysisMetric>>;
  /** A list of the pinned metric instances of this analysis and their values. */
  pinnedMetrics?: Maybe<Array<AnalysisMetric>>;
  /** The scenario type of this analysis instance. */
  scenario: ScenarioType;
  /** A list of the text metric instances of this analysis and their values. */
  textMetrics?: Maybe<Array<AnalysisTextMetric>>;
  /** The type of this analysis instance. */
  type: AnalysisType;
  /** When the object was last updated. */
  updatedAt: Scalars['DateTimeOffset'];
  updatedBy: Actor;
};

export enum DealArchiveStatusEnum {
  Completed = 'COMPLETED',
  Abandoned = 'ABANDONED'
}

/** DealArtifact */
export type DealArtifact = {
  __typename?: 'DealArtifact';
  /** When the object was created. */
  createdAt: Scalars['DateTimeOffset'];
  createdBy: Actor;
  /** When the object was deleted. */
  deletedAt?: Maybe<Scalars['DateTimeOffset']>;
  deletedBy?: Maybe<Actor>;
  fileExtension?: Maybe<Scalars['String']>;
  fkArtifact: Scalars['Int'];
  id: Scalars['Int'];
  name: Scalars['String'];
  /** When the object was last updated. */
  updatedAt: Scalars['DateTimeOffset'];
  updatedBy: Actor;
};

/** An attribute and its associated value for a deal. */
export type DealAttribute = {
  __typename?: 'DealAttribute';
  columnName: Scalars['String'];
  dataType: Scalars['String'];
  displayName: Scalars['String'];
  id: Scalars['Int'];
};

/** DealChecklist */
export type DealChecklist = {
  __typename?: 'DealChecklist';
  /** The checklist items associated with this checklist. */
  checklistItems?: Maybe<Array<DealChecklistItem>>;
  /** When the object was created. */
  createdAt: Scalars['DateTimeOffset'];
  createdBy: Actor;
  /** When the object was deleted. */
  deletedAt?: Maybe<Scalars['DateTimeOffset']>;
  deletedBy?: Maybe<Actor>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  name: Scalars['String'];
  /** When the object was last updated. */
  updatedAt: Scalars['DateTimeOffset'];
  updatedBy: Actor;
};

/** A result type given when trying to assign a checklist to a deal that is already assigned. */
export type DealChecklistAlreadyExists = Error & {
  __typename?: 'DealChecklistAlreadyExists';
  /** The added checklist with the same name. */
  dealChecklist?: Maybe<DealChecklist>;
  message: Scalars['String'];
};

/** A result type given when trying to remove a checklist from a deal that has not been assigned to it. */
export type DealChecklistDoesNotExistType = Error & {
  __typename?: 'DealChecklistDoesNotExistType';
  message: Scalars['String'];
};

/** An input object used for creating a checklist item. */
export type DealChecklistInput = {
  /** The checklist id to add. */
  fkChecklist: Scalars['Int'];
  /** The deal id to add the checklist to. */
  fkDeal: Scalars['Int'];
};

/** DealChecklistItem */
export type DealChecklistItem = {
  __typename?: 'DealChecklistItem';
  /** The user assigned to the checklist item. */
  assignee?: Maybe<User>;
  /** The category of the checklist item. */
  category?: Maybe<ChecklistCategory>;
  comment?: Maybe<Scalars['String']>;
  /** When the object was created. */
  createdAt: Scalars['DateTimeOffset'];
  createdBy: Actor;
  /** When the object was deleted. */
  deletedAt?: Maybe<Scalars['DateTimeOffset']>;
  deletedBy?: Maybe<Actor>;
  dueDate?: Maybe<Scalars['Date']>;
  id: Scalars['Int'];
  name: Scalars['String'];
  /** The status of the checklist item. */
  status: ChecklistItemStatus;
  /** When the object was last updated. */
  updatedAt: Scalars['DateTimeOffset'];
  updatedBy: Actor;
};

/** Deal Event */
export type DealEvent = {
  __typename?: 'DealEvent';
  /** The category that for this event. */
  category?: Maybe<EventCategory>;
  /** A count of the total number of comments for this status update. */
  commentCount: Scalars['Int'];
  /** A list of comments updates belonging to this deal event. */
  comments?: Maybe<Array<Comment>>;
  /** When the object was created. */
  createdAt: Scalars['DateTimeOffset'];
  createdBy: Actor;
  dealEventId: Scalars['Int'];
  /** When the object was deleted. */
  deletedAt?: Maybe<Scalars['DateTimeOffset']>;
  deletedBy?: Maybe<Actor>;
  /** The list of events that this event depends on. */
  dependsOn?: Maybe<Array<Event>>;
  endDate?: Maybe<Scalars['Date']>;
  id: Scalars['Int'];
  isComplete?: Maybe<Scalars['Boolean']>;
  isEditable: Scalars['Boolean'];
  /** The type of this event. */
  latestApprovedEvent?: Maybe<PublishedDealEvent>;
  name: Scalars['String'];
  orderId: Scalars['Decimal'];
  /** The state that this deal resides in. */
  parent?: Maybe<Event>;
  startDate?: Maybe<Scalars['Date']>;
  /** The type of this event. */
  type: DealEventTypeEnum;
  /** When the object was last updated. */
  updatedAt: Scalars['DateTimeOffset'];
  updatedBy: Actor;
};

/** A result type given when the deal event that was attempted to be created already exists. */
export type DealEventAlreadyExists = Error & {
  __typename?: 'DealEventAlreadyExists';
  /** The existing event with matching properties. */
  event?: Maybe<DealEvent>;
  message: Scalars['String'];
};

export enum DealEventTypeEnum {
  Milestone = 'MILESTONE',
  Phase = 'PHASE'
}

/** A result type given when adding or removing events from deals. */
export type DealEventsResult = {
  __typename?: 'DealEventsResult';
  count: Scalars['Int'];
};

export type DealInfo = {
  /** When the deal was created. */
  createdAt: Scalars['DateTimeOffset'];
  /** The id of the portfolio. */
  id: Scalars['Int'];
  /** The name of the portfolio. */
  name: Scalars['String'];
  /** When the deal was last updated. */
  updatedAt: Scalars['DateTimeOffset'];
};

/** An input object used for assigning members to a deal. */
export type DealMemberAssignmentInput = {
  /** A list of identifiers for users that should be assigned to this deal. */
  userIds: Array<Scalars['Int']>;
  /** A list of identifiers for groups that should be assigned to this deal. */
  groupIds: Array<Scalars['Int']>;
  /** The id of the deal to assign the users/groups to. */
  fkDeal: Scalars['Int'];
  /** The id of the role that the specified members should be assigned to. */
  fkRole: Scalars['Int'];
};

/** DealMemberAssignmentResult */
export type DealMemberAssignmentResult = {
  __typename?: 'DealMemberAssignmentResult';
  membersUpdated: Scalars['Int'];
};

export enum DealMemberEnum {
  User = 'USER',
  Group = 'GROUP'
}

/** A partner for a specific deal. */
export type DealPartner = {
  __typename?: 'DealPartner';
  /** The contact for the partner. */
  contact?: Maybe<ContactInfo>;
  /** When the object was created. */
  createdAt: Scalars['DateTimeOffset'];
  createdBy: Actor;
  /** When the object was deleted. */
  deletedAt?: Maybe<Scalars['DateTimeOffset']>;
  deletedBy?: Maybe<Actor>;
  /** The firm that is a partner for the specified role. */
  firm?: Maybe<FirmInfo>;
  id: Scalars['Int'];
  /** The role of the partner. */
  role: PartnerRole;
  /** When the object was last updated. */
  updatedAt: Scalars['DateTimeOffset'];
  updatedBy: Actor;
};

/** Deal Pinned Event */
export type DealPinnedEvent = {
  __typename?: 'DealPinnedEvent';
  /** The end date of the event. */
  endDate?: Maybe<Scalars['DateTime']>;
  /** The event for this pinned event. */
  event: Event;
};

/** Deal Pinned Metric */
export type DealPinnedMetric = {
  __typename?: 'DealPinnedMetric';
  /** The value for the associated metric and the current analysis. */
  currentValue?: Maybe<AnalysisMetric>;
  /** The metric for this value. */
  metric: Metric;
};

/** DealPinnedPartnerRole */
export type DealPinnedPartnerRole = {
  __typename?: 'DealPinnedPartnerRole';
  /** The value for the associated partner role. */
  currentValue?: Maybe<Array<Maybe<DealPartner>>>;
  /** The role of the partner. */
  role: PartnerRole;
};

/** DealPinnedTeamMemberRole */
export type DealPinnedTeamMemberRole = {
  __typename?: 'DealPinnedTeamMemberRole';
  /** The value for the associated team member role. */
  currentValue?: Maybe<Array<Maybe<DealTeamMemberUser>>>;
  /** The role of the team member. */
  role: TeamMemberRole;
};

/** DealPriorityLevel */
export type DealPriorityLevel = {
  __typename?: 'DealPriorityLevel';
  /** When the object was created. */
  createdAt: Scalars['DateTimeOffset'];
  createdBy: Actor;
  /** When the object was deleted. */
  deletedAt?: Maybe<Scalars['DateTimeOffset']>;
  deletedBy?: Maybe<Actor>;
  id: Scalars['Int'];
  name: Scalars['String'];
  /** When the object was last updated. */
  updatedAt: Scalars['DateTimeOffset'];
  updatedBy: Actor;
};

/** DealRoleGroup */
export type DealRoleGroup = {
  __typename?: 'DealRoleGroup';
  /** When the object was created. */
  createdAt: Scalars['DateTimeOffset'];
  createdBy: Actor;
  /** When the object was deleted. */
  deletedAt?: Maybe<Scalars['DateTimeOffset']>;
  deletedBy?: Maybe<Actor>;
  group: Group;
  /** The role of the group. */
  role: Role;
  /** When the object was last updated. */
  updatedAt: Scalars['DateTimeOffset'];
  updatedBy: Actor;
};

/** DealRoleUser */
export type DealRoleUser = {
  __typename?: 'DealRoleUser';
  /** When the object was created. */
  createdAt: Scalars['DateTimeOffset'];
  createdBy: Actor;
  /** When the object was deleted. */
  deletedAt?: Maybe<Scalars['DateTimeOffset']>;
  deletedBy?: Maybe<Actor>;
  /** The role of the user. */
  role: Role;
  /** When the object was last updated. */
  updatedAt: Scalars['DateTimeOffset'];
  updatedBy: Actor;
  user: User;
};

/** DealStage */
export type DealStage = {
  __typename?: 'DealStage';
  /** When the object was created. */
  createdAt: Scalars['DateTimeOffset'];
  createdBy: Actor;
  /** When the object was deleted. */
  deletedAt?: Maybe<Scalars['DateTimeOffset']>;
  deletedBy?: Maybe<Actor>;
  fkPipeline?: Maybe<Scalars['Int']>;
  id: Scalars['Int'];
  isDisabled: Scalars['Boolean'];
  name: Scalars['String'];
  orderId?: Maybe<Scalars['Decimal']>;
  /** When the object was last updated. */
  updatedAt: Scalars['DateTimeOffset'];
  updatedBy: Actor;
};

/** A result type given when the DealStage that was attempted to be created already exists. */
export type DealStageAlreadyExists = Error & {
  __typename?: 'DealStageAlreadyExists';
  /** The existing dealStage with matching properties. */
  dealStage?: Maybe<DealStage>;
  message: Scalars['String'];
};

/** A status update for a specific deal. */
export type DealStatus = {
  __typename?: 'DealStatus';
  /** The name of the color variant to use when displaying this status. */
  color?: Maybe<Scalars['String']>;
  /** When the object was created. */
  createdAt: Scalars['DateTimeOffset'];
  createdBy: Actor;
  /** When the object was deleted. */
  deletedAt?: Maybe<Scalars['DateTimeOffset']>;
  deletedBy?: Maybe<Actor>;
  /** The id of the role. */
  id: Scalars['Int'];
  /** The name of the role. */
  name: Scalars['String'];
  /** When the object was last updated. */
  updatedAt: Scalars['DateTimeOffset'];
  updatedBy: Actor;
};

/** A status update for a specific deal. */
export type DealStatusUpdate = {
  __typename?: 'DealStatusUpdate';
  /** A count of the total number of comments for this status update. */
  commentCount: Scalars['Int'];
  /** A list of comments updates belonging to this status update. */
  comments?: Maybe<Array<Comment>>;
  /** When the object was created. */
  createdAt: Scalars['DateTimeOffset'];
  createdBy: Actor;
  /** When the object was deleted. */
  deletedAt?: Maybe<Scalars['DateTimeOffset']>;
  deletedBy?: Maybe<Actor>;
  /** The deal id that this status update belongs to. */
  fkDeal: Scalars['Int'];
  /** The id of the status update. */
  id: Scalars['Int'];
  /** A list of comments updates belonging to this status update. */
  labels?: Maybe<Array<Label>>;
  /** The message content of the status update. */
  message: Scalars['String'];
  /** A list of sections belonging to this status update. */
  sections?: Maybe<Array<DealStatusUpdateSection>>;
  /** The status of this status update. */
  status: DealStatus;
  /** The title of the status update. */
  title: Scalars['String'];
  /** When the object was last updated. */
  updatedAt: Scalars['DateTimeOffset'];
  updatedBy: Actor;
};

/** A section of content for a specific deal status update. */
export type DealStatusUpdateSection = {
  __typename?: 'DealStatusUpdateSection';
  /** The content of the status update section. */
  content?: Maybe<Scalars['String']>;
  /** The content of the status update section (Raw Text). */
  contentRaw?: Maybe<Scalars['String']>;
  /** When the object was created. */
  createdAt: Scalars['DateTimeOffset'];
  createdBy: Actor;
  /** When the object was deleted. */
  deletedAt?: Maybe<Scalars['DateTimeOffset']>;
  deletedBy?: Maybe<Actor>;
  /** The id of the status update that this section belongs to. */
  fkDealStatusUpdate: Scalars['Int'];
  /** The id of the status update section. */
  id: Scalars['Int'];
  /** The title of the status update template section. */
  title: Scalars['String'];
  /** When the object was last updated. */
  updatedAt: Scalars['DateTimeOffset'];
  updatedBy: Actor;
};

/** A status update template. */
export type DealStatusUpdateTemplate = {
  __typename?: 'DealStatusUpdateTemplate';
  /** When the object was created. */
  createdAt: Scalars['DateTimeOffset'];
  createdBy: Actor;
  /** When the object was deleted. */
  deletedAt?: Maybe<Scalars['DateTimeOffset']>;
  deletedBy?: Maybe<Actor>;
  /** The id of the status update template. */
  id: Scalars['Int'];
  /** A list of labels belonging to this status update template. */
  labels?: Maybe<Array<Label>>;
  /** The message content of the status update template. */
  message: Scalars['String'];
  /** A list of sections belonging to this status update template. */
  sections?: Maybe<Array<DealStatusUpdateTemplateSection>>;
  /** The title of the status update template. */
  title: Scalars['String'];
  /** When the object was last updated. */
  updatedAt: Scalars['DateTimeOffset'];
  updatedBy: Actor;
};

/** A section of content for a specific deal status update template. */
export type DealStatusUpdateTemplateSection = {
  __typename?: 'DealStatusUpdateTemplateSection';
  /** The content of the status update template section. */
  content?: Maybe<Scalars['String']>;
  /** The content of the status update template section (Raw Text). */
  contentRaw?: Maybe<Scalars['String']>;
  /** When the object was created. */
  createdAt: Scalars['DateTimeOffset'];
  createdBy: Actor;
  /** When the object was deleted. */
  deletedAt?: Maybe<Scalars['DateTimeOffset']>;
  deletedBy?: Maybe<Actor>;
  /** The id of the status update template that this template section belongs to. */
  fkDealStatusUpdateTemplate: Scalars['Int'];
  /** The id of the status update template section. */
  id: Scalars['Int'];
  /** The title of the status update template section. */
  title: Scalars['String'];
  /** When the object was last updated. */
  updatedAt: Scalars['DateTimeOffset'];
  updatedBy: Actor;
};

/** A team member for a specific deal. */
export type DealTeamMemberUser = {
  __typename?: 'DealTeamMemberUser';
  /** When the object was created. */
  createdAt: Scalars['DateTimeOffset'];
  createdBy: Actor;
  /** When the object was deleted. */
  deletedAt?: Maybe<Scalars['DateTimeOffset']>;
  deletedBy?: Maybe<Actor>;
  /** The role of the team member. */
  role: TeamMemberRole;
  /** When the object was last updated. */
  updatedAt: Scalars['DateTimeOffset'];
  updatedBy: Actor;
  user: User;
};

/** DealType */
export type DealType = {
  __typename?: 'DealType';
  /** When the object was created. */
  createdAt: Scalars['DateTimeOffset'];
  createdBy: Actor;
  /** When the object was deleted. */
  deletedAt?: Maybe<Scalars['DateTimeOffset']>;
  deletedBy?: Maybe<Actor>;
  id: Scalars['Int'];
  name: Scalars['String'];
  /** When the object was last updated. */
  updatedAt: Scalars['DateTimeOffset'];
  updatedBy: Actor;
};

export type DealsResponse = {
  __typename?: 'DealsResponse';
  deals?: Maybe<Array<Deal>>;
  hasMoreDeals?: Maybe<Scalars['Boolean']>;
};


/** A result type returning items for a chart on the analytics dashboard. */
export type DecimalChartDataItem = {
  __typename?: 'DecimalChartDataItem';
  name: Scalars['String'];
  value: Scalars['Decimal'];
};

export type DeleteAnalysisResult = DealAnalysis | ErrorResult;

export type DeleteAnalysisTypeResult = AnalysisType | ErrorResult;

export type DeleteConfigurableEntityResult = ConfigurableEntity | ErrorResult;

export type DeleteContactResult = Contact | ErrorResult;

export type DeleteDealStageResult = DealStage | ErrorResult;

export type DeleteEventResult = Event | ErrorResult;

export type DeleteFirmRegionResult = FirmRegion | ErrorResult;

export type DeleteFirmResult = Firm | ErrorResult;

export type DeleteGlAccountResult = GlAccount | ErrorResult;

export type DeleteGlAccountTreeResult = GlAccountTree | ErrorResult;

export type DeleteInterestRateResult = InterestRate | ErrorResult;

export type DeleteMetricResult = Metric | ErrorResult;

export type DeletePackageResult = ApprovalPackage | ErrorResult;

export type DeletePackageTemplateResult = TemplateApprovalPackage | ErrorResult;

export type DeletePackageTypeResult = ApprovalPackageType | ErrorResult;

export type DeletePartnerRoleResult = PartnerRole | ErrorResult;

export type DeletePipelineResult = Pipeline | ErrorResult;

export type DeleteTeamMemberRoleResult = TeamMemberRole | ErrorResult;

export type DeleteTextMetricResult = TextMetric | ErrorResult;

/** DistributionType */
export type DistributionType = {
  __typename?: 'DistributionType';
  /** When the object was created. */
  createdAt: Scalars['DateTimeOffset'];
  createdBy: Actor;
  /** When the object was deleted. */
  deletedAt?: Maybe<Scalars['DateTimeOffset']>;
  deletedBy?: Maybe<Actor>;
  id: Scalars['Int'];
  name: Scalars['String'];
  /** When the object was last updated. */
  updatedAt: Scalars['DateTimeOffset'];
  updatedBy: Actor;
};

/** DocumentTreeItem */
export type DocumentTreeItem = {
  __typename?: 'DocumentTreeItem';
  /** When the object was created. */
  createdAt: Scalars['DateTimeOffset'];
  createdBy: Actor;
  /** When the object was deleted. */
  deletedAt?: Maybe<Scalars['DateTimeOffset']>;
  deletedBy?: Maybe<Actor>;
  displayName?: Maybe<Scalars['String']>;
  fileExtension?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  name: Scalars['String'];
  type: Scalars['String'];
  /** When the object was last updated. */
  updatedAt: Scalars['DateTimeOffset'];
  updatedBy: Actor;
};

export type Error = {
  message: Scalars['String'];
};

/** A result type given when an error occurred during an operation */
export type ErrorResult = Error & {
  __typename?: 'ErrorResult';
  message: Scalars['String'];
};

/** EstimateType */
export type EstimateType = {
  __typename?: 'EstimateType';
  /** When the object was created. */
  createdAt: Scalars['DateTimeOffset'];
  createdBy: Actor;
  /** When the object was deleted. */
  deletedAt?: Maybe<Scalars['DateTimeOffset']>;
  deletedBy?: Maybe<Actor>;
  id: Scalars['Int'];
  isDisabled: Scalars['Boolean'];
  name: Scalars['String'];
  /** When the object was last updated. */
  updatedAt: Scalars['DateTimeOffset'];
  updatedBy: Actor;
};

/** Event */
export type Event = {
  __typename?: 'Event';
  /** The category that for this event. */
  category?: Maybe<EventCategory>;
  /** When the object was created. */
  createdAt: Scalars['DateTimeOffset'];
  createdBy: Actor;
  /** When the object was deleted. */
  deletedAt?: Maybe<Scalars['DateTimeOffset']>;
  deletedBy?: Maybe<Actor>;
  /** The list of events that this event depends on. */
  dependsOn?: Maybe<Array<Event>>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  isAppDefault: Scalars['Boolean'];
  isDisabled: Scalars['Boolean'];
  isEditable: Scalars['Boolean'];
  name: Scalars['String'];
  orderId: Scalars['Decimal'];
  /** The parent event, e.g. The phase this event belongs to. */
  parent?: Maybe<Event>;
  /** The type of this event. */
  type: DealEventTypeEnum;
  /** When the object was last updated. */
  updatedAt: Scalars['DateTimeOffset'];
  updatedBy: Actor;
};

/** A result type given when the event that was attempted to be created already exists. */
export type EventAlreadyExists = Error & {
  __typename?: 'EventAlreadyExists';
  /** The existing event with matching properties. */
  event?: Maybe<Event>;
  message: Scalars['String'];
};

/** EventCategory */
export type EventCategory = {
  __typename?: 'EventCategory';
  /** When the object was created. */
  createdAt: Scalars['DateTimeOffset'];
  createdBy: Actor;
  /** When the object was deleted. */
  deletedAt?: Maybe<Scalars['DateTimeOffset']>;
  deletedBy?: Maybe<Actor>;
  id: Scalars['Int'];
  name: Scalars['String'];
  /** When the object was last updated. */
  updatedAt: Scalars['DateTimeOffset'];
  updatedBy: Actor;
};

/** firm */
export type Firm = FirmInfo & {
  __typename?: 'Firm';
  /** The deals that the firm is associated with. */
  associatedDeals: Array<Deal>;
  /** A list of uploaded attachment documents for this firm. */
  attachments?: Maybe<Array<FirmAttachment>>;
  /** Contacts associated with the specified firm */
  contacts: Array<Contact>;
  /** When the object was created. */
  createdAt: Scalars['DateTimeOffset'];
  createdBy: Actor;
  /** When the object was deleted. */
  deletedAt?: Maybe<Scalars['DateTimeOffset']>;
  deletedBy?: Maybe<Actor>;
  description?: Maybe<Scalars['String']>;
  /** The Metropolitan Statistical Area that the headquarters of the firm resides in. */
  headquartersMarket?: Maybe<Market>;
  /** The Metropolitan Statistical Area that the headquarters of the firm resides in. */
  headquartersMsa?: Maybe<Msa>;
  /** The id of the portfolio. */
  id: Scalars['Int'];
  /** Interactions with the contacts of the specified firm. */
  interactions: Array<Interaction>;
  /** Contacts associated with the specified firm */
  keyContacts: Array<Contact>;
  /** The name of the portfolio. */
  name: Scalars['String'];
  /** The product appetite of the firm. */
  productAppetite?: Maybe<InvestmentType>;
  /** Contacts associated with the specified firm */
  regions: Array<FirmRegion>;
  /** The user that is the relationship lead for the firm. */
  relationshipLead?: Maybe<User>;
  /** The status of this approval package. */
  status?: Maybe<CrmStatus>;
  /** The type of the firm. */
  type?: Maybe<FirmType>;
  /** When the object was last updated. */
  updatedAt: Scalars['DateTimeOffset'];
  updatedBy: Actor;
  website?: Maybe<Scalars['String']>;
};

/** FirmAttachment */
export type FirmAttachment = {
  __typename?: 'FirmAttachment';
  /** When the object was created. */
  createdAt: Scalars['DateTimeOffset'];
  createdBy: Actor;
  /** When the object was deleted. */
  deletedAt?: Maybe<Scalars['DateTimeOffset']>;
  deletedBy?: Maybe<Actor>;
  displayName: Scalars['String'];
  fileExtension?: Maybe<Scalars['String']>;
  fkDocument: Scalars['Int'];
  id: Scalars['Int'];
  /** When the object was last updated. */
  updatedAt: Scalars['DateTimeOffset'];
  updatedBy: Actor;
};

export type FirmInfo = {
  /** The id of the portfolio. */
  id: Scalars['Int'];
  /** The name of the portfolio. */
  name: Scalars['String'];
};

/** FirmRegion */
export type FirmRegion = {
  __typename?: 'FirmRegion';
  /** When the object was created. */
  createdAt: Scalars['DateTimeOffset'];
  createdBy: Actor;
  /** When the object was deleted. */
  deletedAt?: Maybe<Scalars['DateTimeOffset']>;
  deletedBy?: Maybe<Actor>;
  id: Scalars['Int'];
  isPrimaryRegion: Scalars['Boolean'];
  name: Scalars['String'];
  /** When the object was last updated. */
  updatedAt: Scalars['DateTimeOffset'];
  updatedBy: Actor;
};

/** FirmType */
export type FirmType = {
  __typename?: 'FirmType';
  /** When the object was created. */
  createdAt: Scalars['DateTimeOffset'];
  createdBy: Actor;
  /** When the object was deleted. */
  deletedAt?: Maybe<Scalars['DateTimeOffset']>;
  deletedBy?: Maybe<Actor>;
  id: Scalars['Int'];
  name: Scalars['String'];
  /** When the object was last updated. */
  updatedAt: Scalars['DateTimeOffset'];
  updatedBy: Actor;
};

/** Folder */
export type Folder = {
  __typename?: 'Folder';
  /** When the object was created. */
  createdAt: Scalars['DateTimeOffset'];
  createdBy: Actor;
  /** When the object was deleted. */
  deletedAt?: Maybe<Scalars['DateTimeOffset']>;
  deletedBy?: Maybe<Actor>;
  id: Scalars['Int'];
  name: Scalars['String'];
  /** When the object was last updated. */
  updatedAt: Scalars['DateTimeOffset'];
  updatedBy: Actor;
};

/** A result type providing the forecast IR status for a deal. */
export type ForecastInterestAccountStatusType = {
  __typename?: 'ForecastInterestAccountStatusType';
  sessionId: Scalars['String'];
  transactionsLeft: Scalars['Int'];
  transactionsTotal: Scalars['Int'];
};

export type ForecastRule = {
  __typename?: 'ForecastRule';
  /** The account for this offset rule. */
  account: GlAccount;
  description: Scalars['String'];
  /** The distribution type for this transaction. */
  distributionType: DistributionType;
  dollarAmount?: Maybe<Scalars['Decimal']>;
  dollarPercent?: Maybe<Scalars['Decimal']>;
  /** The end event for this forecast rule. */
  endEvent: Event;
  fkEventEndUsesStartDate: Scalars['Boolean'];
  fkEventStartUsesStartDate: Scalars['Boolean'];
  fkForecastRuleGroup?: Maybe<Scalars['Int']>;
  fkForecastRuleType?: Maybe<Scalars['Int']>;
  frequency: Scalars['Int'];
  id: Scalars['Int'];
  isDisabled: Scalars['Boolean'];
  isSliding: Scalars['Boolean'];
  milestoneAdjustmentEnd: Scalars['Int'];
  milestoneAdjustmentStart: Scalars['Int'];
  phaseSpendingCapPercent?: Maybe<Scalars['Decimal']>;
  /** The start event for this forecast rule. */
  startEvent: Event;
  startMonth?: Maybe<Scalars['Int']>;
  usesBalance: Scalars['Boolean'];
};

export type ForecastRuleDeal = {
  __typename?: 'ForecastRuleDeal';
  /** When the object was created. */
  createdAt: Scalars['DateTimeOffset'];
  createdBy: Actor;
  /** When the object was deleted. */
  deletedAt?: Maybe<Scalars['DateTimeOffset']>;
  deletedBy?: Maybe<Actor>;
  /** The forecast rule. */
  forecastRule: ForecastRule;
  id: Scalars['Int'];
  isEnabled?: Maybe<Scalars['Boolean']>;
  /** When the object was last updated. */
  updatedAt: Scalars['DateTimeOffset'];
  updatedBy: Actor;
};

export type ForecastRuleExecutionResult = {
  __typename?: 'ForecastRuleExecutionResult';
  transactionsCreated: Scalars['Int'];
};

/** Fund */
export type Fund = {
  __typename?: 'Fund';
  /** When the object was created. */
  createdAt: Scalars['DateTimeOffset'];
  createdBy: Actor;
  /** When the object was deleted. */
  deletedAt?: Maybe<Scalars['DateTimeOffset']>;
  deletedBy?: Maybe<Actor>;
  id: Scalars['Int'];
  isDisabled: Scalars['Boolean'];
  name: Scalars['String'];
  /** When the object was last updated. */
  updatedAt: Scalars['DateTimeOffset'];
  updatedBy: Actor;
};

/** FundEntity */
export type FundEntity = {
  __typename?: 'FundEntity';
  /** When the object was created. */
  createdAt: Scalars['DateTimeOffset'];
  createdBy: Actor;
  /** When the object was deleted. */
  deletedAt?: Maybe<Scalars['DateTimeOffset']>;
  deletedBy?: Maybe<Actor>;
  id: Scalars['Int'];
  isDisabled: Scalars['Boolean'];
  name: Scalars['String'];
  /** When the object was last updated. */
  updatedAt: Scalars['DateTimeOffset'];
  updatedBy: Actor;
};

/** GcEstimate */
export type GcEstimate = {
  __typename?: 'GcEstimate';
  comment?: Maybe<Scalars['String']>;
  /** A count of the total number of comments for this GC Estimate. */
  commentCount: Scalars['Int'];
  /** A list of comments for this GC Estimate. */
  comments?: Maybe<Array<Comment>>;
  /** When the object was created. */
  createdAt: Scalars['DateTimeOffset'];
  createdBy: Actor;
  /** When the object was deleted. */
  deletedAt?: Maybe<Scalars['DateTimeOffset']>;
  deletedBy?: Maybe<Actor>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  isApproved: Scalars['Boolean'];
  pricePerNrsf?: Maybe<Scalars['Decimal']>;
  pricePerUnit?: Maybe<Scalars['Decimal']>;
  /** The source (firm) of the GC estimate. */
  source: FirmInfo;
  totalPrice?: Maybe<Scalars['Decimal']>;
  /** The type of a GC estimate. */
  type: EstimateType;
  /** When the object was last updated. */
  updatedAt: Scalars['DateTimeOffset'];
  updatedBy: Actor;
};

/** GeneralContractorSummary */
export type GeneralContractorSummary = {
  __typename?: 'GeneralContractorSummary';
  gcPercentComplete: Scalars['Decimal'];
  issuedChangeOrdersAmount: Scalars['Decimal'];
  originalContractAmount: Scalars['Decimal'];
};

/** GlAccount */
export type GlAccount = {
  __typename?: 'GlAccount';
  /** The category of this account. */
  category?: Maybe<GlAccountCategory>;
  code: Scalars['String'];
  displayCode?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  isAppDefault: Scalars['Boolean'];
  isDisabled: Scalars['Boolean'];
  name: Scalars['String'];
  /** The type of this account. */
  type?: Maybe<GlAccountType>;
};

/** GlAccountCategory */
export type GlAccountCategory = {
  __typename?: 'GlAccountCategory';
  /** When the object was created. */
  createdAt: Scalars['DateTimeOffset'];
  createdBy: Actor;
  /** When the object was deleted. */
  deletedAt?: Maybe<Scalars['DateTimeOffset']>;
  deletedBy?: Maybe<Actor>;
  id: Scalars['Int'];
  name: Scalars['String'];
  /** When the object was last updated. */
  updatedAt: Scalars['DateTimeOffset'];
  updatedBy: Actor;
};

/** GlAccountSummary */
export type GlAccountSummary = {
  __typename?: 'GlAccountSummary';
  /** The cost account for this summary. */
  account: GlAccount;
  amount: Scalars['Decimal'];
  postDate: Scalars['Date'];
};

/** GlAccountTree */
export type GlAccountTree = {
  __typename?: 'GlAccountTree';
  /** The branches of this account tree. */
  branches?: Maybe<Array<GlAccountTreeBranch>>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  isAppDefault: Scalars['Boolean'];
  isDisabled: Scalars['Boolean'];
  name: Scalars['String'];
  /** The report type of this account tree */
  reportType?: Maybe<ReportType>;
};

/** GlAccountTreeBranch */
export type GlAccountTreeBranch = {
  __typename?: 'GlAccountTreeBranch';
  /** The cost account for this summary. */
  account: GlAccount;
  fkParentBranch?: Maybe<Scalars['Int']>;
  id: Scalars['Int'];
  level: Scalars['Int'];
  /** A flag indicating wether or not the value for the primary account of this tree branch should be flipped when displayed. */
  reverseFromDrCr: Scalars['Boolean'];
};

/** GlAccountType */
export type GlAccountType = {
  __typename?: 'GlAccountType';
  /** When the object was created. */
  createdAt: Scalars['DateTimeOffset'];
  createdBy: Actor;
  /** When the object was deleted. */
  deletedAt?: Maybe<Scalars['DateTimeOffset']>;
  deletedBy?: Maybe<Actor>;
  id: Scalars['Int'];
  name: Scalars['String'];
  /** When the object was last updated. */
  updatedAt: Scalars['DateTimeOffset'];
  updatedBy: Actor;
};

/** Group */
export type Group = {
  __typename?: 'Group';
  /** When the object was created. */
  createdAt: Scalars['DateTimeOffset'];
  createdBy: Actor;
  /** When the object was deleted. */
  deletedAt?: Maybe<Scalars['DateTimeOffset']>;
  deletedBy?: Maybe<Actor>;
  id: Scalars['Int'];
  /** The number of users that belong to this group. */
  memberCount: Scalars['Int'];
  name: Scalars['String'];
  /** When the object was last updated. */
  updatedAt: Scalars['DateTimeOffset'];
  updatedBy: Actor;
  /** The users that belong to this group. */
  users?: Maybe<Array<User>>;
};


/** Hoa */
export type Hoa = {
  __typename?: 'Hoa';
  /** When the object was created. */
  createdAt: Scalars['DateTimeOffset'];
  createdBy: Actor;
  /** When the object was deleted. */
  deletedAt?: Maybe<Scalars['DateTimeOffset']>;
  deletedBy?: Maybe<Actor>;
  id: Scalars['Int'];
  isDisabled: Scalars['Boolean'];
  name: Scalars['String'];
  /** When the object was last updated. */
  updatedAt: Scalars['DateTimeOffset'];
  updatedBy: Actor;
};

/** IcSubSectionImageContent */
export type IcSubSectionImageContent = {
  __typename?: 'IcSubSectionImageContent';
  /** The id of the image, used for downloading its content. */
  fkIcPackageExhibit: Scalars['Int'];
  /** The id of the content */
  id: Scalars['Int'];
  /** The title of this approval package sub-section. */
  title?: Maybe<Scalars['String']>;
};

/** IcSubSectionTableCell */
export type IcSubSectionTableCell = {
  __typename?: 'IcSubSectionTableCell';
  /** A list of attributes for a specific deal. */
  attributes?: Maybe<Array<ApprovalPackageSubSectionDealAttribute>>;
  /** A list of the entries for this cell. */
  entries?: Maybe<Array<IcSubSectionTableCellEntry>>;
  /** A list of the events added to this cell and their values. */
  events?: Maybe<Array<ApprovalPackageSubSectionEvent>>;
  /** The id of the cell */
  id: Scalars['Int'];
  /** A list of the metrics added to this cell and their values. */
  metrics?: Maybe<Array<ApprovalPackageSubSectionMetric>>;
  orderId?: Maybe<Scalars['Decimal']>;
  /** A list of partners for a specific deal. */
  partners?: Maybe<Array<ApprovalPackageSubSectionPartnerRole>>;
  /** A list of team members for a specific deal. */
  teamMembers?: Maybe<Array<ApprovalPackageSubSectionTeamMemberRole>>;
};

/** IcSubSectionTableCellEntry */
export type IcSubSectionTableCellEntry = {
  __typename?: 'IcSubSectionTableCellEntry';
  /** The table cell that this entry belongs to. */
  icSubSectionTableCell: Scalars['Int'];
  /** The id of the entry used for lookup */
  id: Scalars['Int'];
  /** The name of the entry. */
  name: Scalars['String'];
  /** The order id of the entry */
  orderId: Scalars['Decimal'];
  type: ApprovalPackageSubSectionTableCellEntryEnum;
};

/** IcSubSectionTableCellEntryInput */
export type IcSubSectionTableCellEntryInput = {
  /** The id of the entry used for lookup */
  id: Scalars['Int'];
  /** The name of the entry. */
  name: Scalars['String'];
  /** The order id of the entry */
  orderId: Scalars['Decimal'];
  type: ApprovalPackageSubSectionTableCellEntryEnum;
};

/** IcSubSectionTableCellEntryOption */
export type IcSubSectionTableCellEntryOption = {
  __typename?: 'IcSubSectionTableCellEntryOption';
  /** The id of the entry used for lookup */
  id: Scalars['Int'];
  /** The name of the entry. */
  name: Scalars['String'];
  type: ApprovalPackageSubSectionTableCellEntryEnum;
};

/** Interaction */
export type Interaction = {
  __typename?: 'Interaction';
  /** A list of deals that are linked to this interaction. */
  attendees: Array<InteractionAttendee>;
  /** When the object was created. */
  createdAt: Scalars['DateTimeOffset'];
  createdBy: Actor;
  /** A list of deals that are linked to this interaction. */
  deals: Array<DealInfo>;
  /** When the object was deleted. */
  deletedAt?: Maybe<Scalars['DateTimeOffset']>;
  deletedBy?: Maybe<Actor>;
  /** The firms associated with this interaction. */
  firms: Array<FirmInfo>;
  id: Scalars['Int'];
  interactionDate: Scalars['Date'];
  name: Scalars['String'];
  notes: Scalars['String'];
  /** The type of the interaction. */
  type: InteractionType;
  /** When the object was last updated. */
  updatedAt: Scalars['DateTimeOffset'];
  updatedBy: Actor;
};

/** InteractionAttendee */
export type InteractionAttendee = {
  __typename?: 'InteractionAttendee';
  id: Scalars['Int'];
  name: Scalars['String'];
  type: InteractionAttendeeEnum;
};

export enum InteractionAttendeeEnum {
  User = 'USER',
  Contact = 'CONTACT'
}

/** InteractionType */
export type InteractionType = {
  __typename?: 'InteractionType';
  /** When the object was created. */
  createdAt: Scalars['DateTimeOffset'];
  createdBy: Actor;
  /** When the object was deleted. */
  deletedAt?: Maybe<Scalars['DateTimeOffset']>;
  deletedBy?: Maybe<Actor>;
  id: Scalars['Int'];
  name: Scalars['String'];
  /** When the object was last updated. */
  updatedAt: Scalars['DateTimeOffset'];
  updatedBy: Actor;
};

/** An Interest Rate over a specified time period. */
export type InterestRate = {
  __typename?: 'InterestRate';
  /** When the object was created. */
  createdAt: Scalars['DateTimeOffset'];
  createdBy: Actor;
  /** When the object was deleted. */
  deletedAt?: Maybe<Scalars['DateTimeOffset']>;
  deletedBy?: Maybe<Actor>;
  endDate: Scalars['DateTime'];
  id: Scalars['Int'];
  startDate: Scalars['DateTime'];
  /** When the object was last updated. */
  updatedAt: Scalars['DateTimeOffset'];
  updatedBy: Actor;
  value: Scalars['Decimal'];
};

/** A result type given when an interest rate trying to be created already exists. */
export type InterestRateAlreadyExists = Error & {
  __typename?: 'InterestRateAlreadyExists';
  /** The existing interest rate with the same name. */
  interestRate?: Maybe<InterestRate>;
  message: Scalars['String'];
};

/** InvestmentType */
export type InvestmentType = {
  __typename?: 'InvestmentType';
  /** When the object was created. */
  createdAt: Scalars['DateTimeOffset'];
  createdBy: Actor;
  /** When the object was deleted. */
  deletedAt?: Maybe<Scalars['DateTimeOffset']>;
  deletedBy?: Maybe<Actor>;
  id: Scalars['Int'];
  name: Scalars['String'];
  /** When the object was last updated. */
  updatedAt: Scalars['DateTimeOffset'];
  updatedBy: Actor;
};

/** KeyRisk */
export type KeyRisk = {
  __typename?: 'KeyRisk';
  /** A list of comments updates belonging to this key risk. */
  comments?: Maybe<Array<Comment>>;
  /** When the object was created. */
  createdAt: Scalars['DateTimeOffset'];
  createdBy: Actor;
  /** When the object was deleted. */
  deletedAt?: Maybe<Scalars['DateTimeOffset']>;
  deletedBy?: Maybe<Actor>;
  id: Scalars['Int'];
  mitigantDescription?: Maybe<Scalars['String']>;
  orderId?: Maybe<Scalars['Decimal']>;
  riskDescription?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  /** When the object was last updated. */
  updatedAt: Scalars['DateTimeOffset'];
  updatedBy: Actor;
};

/** Label */
export type Label = {
  __typename?: 'Label';
  color?: Maybe<Scalars['String']>;
  /** When the object was created. */
  createdAt: Scalars['DateTimeOffset'];
  createdBy: Actor;
  /** When the object was deleted. */
  deletedAt?: Maybe<Scalars['DateTimeOffset']>;
  deletedBy?: Maybe<Actor>;
  id: Scalars['Int'];
  name: Scalars['String'];
  /** When the object was last updated. */
  updatedAt: Scalars['DateTimeOffset'];
  updatedBy: Actor;
};


/** Market */
export type Market = {
  __typename?: 'Market';
  /** When the object was created. */
  createdAt: Scalars['DateTimeOffset'];
  createdBy: Actor;
  /** When the object was deleted. */
  deletedAt?: Maybe<Scalars['DateTimeOffset']>;
  deletedBy?: Maybe<Actor>;
  id: Scalars['Int'];
  /** The mas associated with this Market. */
  msa?: Maybe<Msa>;
  name: Scalars['String'];
  /** When the object was last updated. */
  updatedAt: Scalars['DateTimeOffset'];
  updatedBy: Actor;
};

/** MarketSegment */
export type MarketSegment = {
  __typename?: 'MarketSegment';
  /** When the object was created. */
  createdAt: Scalars['DateTimeOffset'];
  createdBy: Actor;
  /** When the object was deleted. */
  deletedAt?: Maybe<Scalars['DateTimeOffset']>;
  deletedBy?: Maybe<Actor>;
  id: Scalars['Int'];
  name: Scalars['String'];
  /** When the object was last updated. */
  updatedAt: Scalars['DateTimeOffset'];
  updatedBy: Actor;
};

export enum MarketStatisticEnum {
  Average = 'AVERAGE',
  Median = 'MEDIAN',
  Min = 'MIN',
  Max = 'MAX'
}

/** MarketType */
export type MarketType = {
  __typename?: 'MarketType';
  /** When the object was created. */
  createdAt: Scalars['DateTimeOffset'];
  createdBy: Actor;
  /** When the object was deleted. */
  deletedAt?: Maybe<Scalars['DateTimeOffset']>;
  deletedBy?: Maybe<Actor>;
  id: Scalars['Int'];
  name: Scalars['String'];
  /** When the object was last updated. */
  updatedAt: Scalars['DateTimeOffset'];
  updatedBy: Actor;
};

/** A market view. */
export type MarketView = {
  __typename?: 'MarketView';
  /** A list of metric columns for this view. */
  columns?: Maybe<Array<MarketViewColumn>>;
  /** Data for the deal type chart. */
  payload?: Maybe<MarketViewPayload>;
};


/** A market view. */
export type MarketViewColumnsArgs = {
  pipelineId: Scalars['Int'];
};


/** A market view. */
export type MarketViewPayloadArgs = {
  pipelineId: Scalars['Int'];
  birthDate: Scalars['Date'];
  includeArchivedDeals: Scalars['Boolean'];
  marketStatistic: MarketStatisticEnum;
};

/** A column definition for a market view. */
export type MarketViewColumn = {
  __typename?: 'MarketViewColumn';
  format?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  lookupName: Scalars['String'];
  name: Scalars['String'];
  type: PortfolioViewColumnTypeEnum;
};

/** The data payload for the market view */
export type MarketViewPayload = {
  __typename?: 'MarketViewPayload';
  /** A list of columns for the market view. */
  columns?: Maybe<Array<Scalars['String']>>;
  /** A list of rows for the market view. */
  rows?: Maybe<Array<Maybe<Array<Maybe<Scalars['String']>>>>>;
};

/** Merit */
export type Merit = {
  __typename?: 'Merit';
  /** A list of comments updates belonging to this merit. */
  comments?: Maybe<Array<Comment>>;
  /** When the object was created. */
  createdAt: Scalars['DateTimeOffset'];
  createdBy: Actor;
  /** When the object was deleted. */
  deletedAt?: Maybe<Scalars['DateTimeOffset']>;
  deletedBy?: Maybe<Actor>;
  id: Scalars['Int'];
  meritDescription?: Maybe<Scalars['String']>;
  orderId?: Maybe<Scalars['Decimal']>;
  title: Scalars['String'];
  /** When the object was last updated. */
  updatedAt: Scalars['DateTimeOffset'];
  updatedBy: Actor;
};

/** A metric. */
export type Metric = {
  __typename?: 'Metric';
  /** When the object was created. */
  createdAt: Scalars['DateTimeOffset'];
  createdBy: Actor;
  /** When the object was deleted. */
  deletedAt?: Maybe<Scalars['DateTimeOffset']>;
  deletedBy?: Maybe<Actor>;
  /** The formatting for a given metric. */
  format?: Maybe<MetricFormatEnum>;
  id: Scalars['Int'];
  isAppDefault: Scalars['Boolean'];
  isDisabled: Scalars['Boolean'];
  name: Scalars['String'];
  precision?: Maybe<Scalars['Int']>;
  scale?: Maybe<Scalars['Decimal']>;
  unit?: Maybe<Scalars['String']>;
  /** When the object was last updated. */
  updatedAt: Scalars['DateTimeOffset'];
  updatedBy: Actor;
};

/** A result type given when a metric trying to be created or named already exists. */
export type MetricAlreadyExists = Error & {
  __typename?: 'MetricAlreadyExists';
  message: Scalars['String'];
  /** The existing metric with the same name. */
  metric?: Maybe<Metric>;
};

export enum MetricFormatEnum {
  Decimal = 'DECIMAL',
  Percentage = 'PERCENTAGE',
  Currency = 'CURRENCY'
}

export type MetricValue = {
  __typename?: 'MetricValue';
  /** The metric. */
  metric?: Maybe<Metric>;
  value: Scalars['Decimal'];
};


/** Module */
export type Module = {
  __typename?: 'Module';
  /** When the object was created. */
  createdAt: Scalars['DateTimeOffset'];
  createdBy: Actor;
  /** When the object was deleted. */
  deletedAt?: Maybe<Scalars['DateTimeOffset']>;
  deletedBy?: Maybe<Actor>;
  id: Scalars['Int'];
  name: Scalars['String'];
  /** When the object was last updated. */
  updatedAt: Scalars['DateTimeOffset'];
  updatedBy: Actor;
};

/** Msa */
export type Msa = {
  __typename?: 'Msa';
  /** When the object was created. */
  createdAt: Scalars['DateTimeOffset'];
  createdBy: Actor;
  /** When the object was deleted. */
  deletedAt?: Maybe<Scalars['DateTimeOffset']>;
  deletedBy?: Maybe<Actor>;
  id: Scalars['Int'];
  name: Scalars['String'];
  /** When the object was last updated. */
  updatedAt: Scalars['DateTimeOffset'];
  updatedBy: Actor;
};

/** Organization */
export type Organization = {
  __typename?: 'Organization';
  /** Whether or not the current user has editing rights on this organization. */
  currentUserCanEdit: Scalars['Boolean'];
  id: Scalars['Int'];
  /** The name of the organization. */
  name: Scalars['String'];
  /** The path to the organizations resources. */
  organizationPath: Scalars['String'];
  /** The owner of the organization */
  owner?: Maybe<User>;
};

/** An input object used for assigning members to an organization role. */
export type OrganizationMemberAssignmentInput = {
  /** A list of identifiers for users that should be assigned. */
  userIds: Array<Scalars['Int']>;
  /** A list of identifiers for groups that should be assigned. */
  groupIds: Array<Scalars['Int']>;
  /** The id of the role that the specified members should be assigned to. */
  fkRole: Scalars['Int'];
};

/** OrganizationMemberAssignmentResult */
export type OrganizationMemberAssignmentResult = {
  __typename?: 'OrganizationMemberAssignmentResult';
  membersUpdated: Scalars['Int'];
};

export enum OrganizationMemberEnum {
  User = 'USER',
  Group = 'GROUP'
}

/** OrganizationRoleGroup */
export type OrganizationRoleGroup = {
  __typename?: 'OrganizationRoleGroup';
  /** When the object was created. */
  createdAt: Scalars['DateTimeOffset'];
  createdBy: Actor;
  /** When the object was deleted. */
  deletedAt?: Maybe<Scalars['DateTimeOffset']>;
  deletedBy?: Maybe<Actor>;
  group: Group;
  /** The role of the group. */
  role: Role;
  /** When the object was last updated. */
  updatedAt: Scalars['DateTimeOffset'];
  updatedBy: Actor;
};

/** OrganizationRoleUser */
export type OrganizationRoleUser = {
  __typename?: 'OrganizationRoleUser';
  /** When the object was created. */
  createdAt: Scalars['DateTimeOffset'];
  createdBy: Actor;
  /** When the object was deleted. */
  deletedAt?: Maybe<Scalars['DateTimeOffset']>;
  deletedBy?: Maybe<Actor>;
  /** The role of the user. */
  role: Role;
  /** When the object was last updated. */
  updatedAt: Scalars['DateTimeOffset'];
  updatedBy: Actor;
  user: User;
};

/** PackageReviewerStatus */
export type PackageReviewerStatus = {
  __typename?: 'PackageReviewerStatus';
  /** When the object was created. */
  createdAt: Scalars['DateTimeOffset'];
  createdBy: Actor;
  /** When the object was deleted. */
  deletedAt?: Maybe<Scalars['DateTimeOffset']>;
  deletedBy?: Maybe<Actor>;
  id: Scalars['Int'];
  name: Scalars['String'];
  /** When the object was last updated. */
  updatedAt: Scalars['DateTimeOffset'];
  updatedBy: Actor;
};

/** PackageStatus */
export type PackageStatus = {
  __typename?: 'PackageStatus';
  /** When the object was created. */
  createdAt: Scalars['DateTimeOffset'];
  createdBy: Actor;
  /** When the object was deleted. */
  deletedAt?: Maybe<Scalars['DateTimeOffset']>;
  deletedBy?: Maybe<Actor>;
  id: Scalars['Int'];
  name: Scalars['String'];
  /** When the object was last updated. */
  updatedAt: Scalars['DateTimeOffset'];
  updatedBy: Actor;
};

/** A role that can be assigned to a partner for a deal. */
export type PartnerRole = {
  __typename?: 'PartnerRole';
  /** When the object was created. */
  createdAt: Scalars['DateTimeOffset'];
  createdBy: Actor;
  /** When the object was deleted. */
  deletedAt?: Maybe<Scalars['DateTimeOffset']>;
  deletedBy?: Maybe<Actor>;
  /** A description of the role. */
  description?: Maybe<Scalars['String']>;
  /** The id of the role. */
  id: Scalars['Int'];
  isAppDefault: Scalars['Boolean'];
  isDisabled: Scalars['Boolean'];
  /** The name of the role. */
  name: Scalars['String'];
  /** When the object was last updated. */
  updatedAt: Scalars['DateTimeOffset'];
  updatedBy: Actor;
};

/** Pipeline */
export type Pipeline = {
  __typename?: 'Pipeline';
  /** A list of the forecast cash offsets for a pipeline and specific accounts. */
  accountCashOffsetRules?: Maybe<Array<PipelineAccountOffset>>;
  /** A list of all checklists for this pipeline. */
  checklists?: Maybe<Array<Checklist>>;
  /** Contacts associated with this pipeline. */
  contacts: Array<Contact>;
  /** A list of contingency account categorizations for this pipeline. */
  contingencyCategorizations?: Maybe<Array<PipelineContingencyCategory>>;
  /** When the object was created. */
  createdAt: Scalars['DateTimeOffset'];
  createdBy: Actor;
  /** Whether or not the current user has editing rights on this pipeline. */
  currentUserCanEdit: Scalars['Boolean'];
  /** A list of pipeline deal stages. */
  dealStages?: Maybe<Array<DealStage>>;
  /** The debt account for this pipeline. */
  debtAccount?: Maybe<GlAccount>;
  /** The default account tree for this pipeline. */
  defaultAccountTree?: Maybe<GlAccountTree>;
  /** The default cost source for Change Events, Potential Change Orders, and Change Orders. */
  defaultCostSource?: Maybe<GlAccount>;
  /** The source of the deal. */
  defaultSource?: Maybe<FirmInfo>;
  /** When the object was deleted. */
  deletedAt?: Maybe<Scalars['DateTimeOffset']>;
  deletedBy?: Maybe<Actor>;
  description?: Maybe<Scalars['String']>;
  /** The interest account for this pipeline. */
  endInterestAccount?: Maybe<GlAccount>;
  /** The equity account for this pipeline. */
  equityAccount?: Maybe<GlAccount>;
  /** A list of all events for this pipeline. */
  events?: Maybe<Array<Event>>;
  /** The firms associated with this pipeline. */
  firms: Array<Firm>;
  /** The first residential TCO event for this pipeline. */
  firstResidentialTcoEvent?: Maybe<Event>;
  /** A list of all forecast rules for this pipeline. */
  forecastRules?: Maybe<Array<ForecastRule>>;
  /** A list of the general forecast cash offsets for a pipeline. */
  generalCashOffsetRules?: Maybe<Array<PipelineGeneralOffset>>;
  /** The General Contractor account for this pipeline. */
  generalContractorAccount?: Maybe<GlAccount>;
  /** The role of the General Contractor. */
  generalContractorRole?: Maybe<PartnerRole>;
  /** A list of groups that are assigned to this deal and their associated permissions. */
  groups?: Maybe<Array<PipelineRoleGroup>>;
  id: Scalars['Int'];
  /** The interest account for this pipeline. */
  interestAccount?: Maybe<GlAccount>;
  isDisabled: Scalars['Boolean'];
  /** The last residential TCO event for this pipeline. */
  lastResidentialTcoEvent?: Maybe<Event>;
  name: Scalars['String'];
  /** A list of all pinned events for this pipeline. */
  pinnedEvents?: Maybe<Array<Event>>;
  /** A list of all pinned metrics for this pipeline. */
  pinnedMetrics?: Maybe<Array<Metric>>;
  /** A list of all pinned partner roles for this pipeline. */
  pinnedPartnerRoles?: Maybe<Array<PartnerRole>>;
  /** A list of all pinned team member roles for this pipeline. */
  pinnedTeamMemberRoles?: Maybe<Array<TeamMemberRole>>;
  /** A list of modules for a given pipeline. */
  pipelineModules?: Maybe<Array<PipelineModule>>;
  /** When the object was last updated. */
  updatedAt: Scalars['DateTimeOffset'];
  updatedBy: Actor;
  /** A list of users that are assigned to this pipeline and their associated permissions. */
  users?: Maybe<Array<PipelineRoleUser>>;
};

/** PipelineAccountOffset */
export type PipelineAccountOffset = {
  __typename?: 'PipelineAccountOffset';
  /** The account for this offset rule. */
  account: GlAccount;
  /** The event for this offset rule. */
  event?: Maybe<Event>;
  /** The filter for this offset rule. */
  filter?: Maybe<CustomFilters>;
  id: Scalars['Int'];
  offset: Scalars['Int'];
};

/** PipelineContingencyCategory */
export type PipelineContingencyCategory = {
  __typename?: 'PipelineContingencyCategory';
  /** The account for this offset rule. */
  account: GlAccount;
  /** The contingency category for this account. */
  category: ContingencyCategory;
  id: Scalars['Int'];
};

/** A result type given when adding or removing pipeline entitites. */
export type PipelineEntityResult = {
  __typename?: 'PipelineEntityResult';
  count: Scalars['Int'];
};

/** PipelineGeneralOffset */
export type PipelineGeneralOffset = {
  __typename?: 'PipelineGeneralOffset';
  /** The event for this offset rule. */
  event?: Maybe<Event>;
  /** The filter for this offset rule. */
  filter?: Maybe<CustomFilters>;
  id: Scalars['Int'];
  offset: Scalars['Int'];
};

/** An input object used for assigning members to a pipeline. */
export type PipelineMemberAssignmentInput = {
  /** A list of identifiers for users that should be assigned to this pipeline. */
  userIds: Array<Scalars['Int']>;
  /** A list of identifiers for groups that should be assigned to this pipeline. */
  groupIds: Array<Scalars['Int']>;
  /** The id of the pipeline to assign the users/groups to. */
  fkPipeline: Scalars['Int'];
  /** The id of the role that the specified members should be assigned to. */
  fkRole: Scalars['Int'];
};

/** PipelineMemberAssignmentResult */
export type PipelineMemberAssignmentResult = {
  __typename?: 'PipelineMemberAssignmentResult';
  membersUpdated: Scalars['Int'];
};

/** PipelineModule */
export type PipelineModule = {
  __typename?: 'PipelineModule';
  /** When the object was created. */
  createdAt: Scalars['DateTimeOffset'];
  createdBy: Actor;
  /** When the object was deleted. */
  deletedAt?: Maybe<Scalars['DateTimeOffset']>;
  deletedBy?: Maybe<Actor>;
  id: Scalars['Int'];
  isDisabled: Scalars['Boolean'];
  /** The associated module for a given pipelineModule */
  module: Module;
  /** When the object was last updated. */
  updatedAt: Scalars['DateTimeOffset'];
  updatedBy: Actor;
};

/** PipelineRoleGroup */
export type PipelineRoleGroup = {
  __typename?: 'PipelineRoleGroup';
  /** When the object was created. */
  createdAt: Scalars['DateTimeOffset'];
  createdBy: Actor;
  /** When the object was deleted. */
  deletedAt?: Maybe<Scalars['DateTimeOffset']>;
  deletedBy?: Maybe<Actor>;
  group: Group;
  /** The role of the group. */
  role: Role;
  /** When the object was last updated. */
  updatedAt: Scalars['DateTimeOffset'];
  updatedBy: Actor;
};

/** PipelineRoleUser */
export type PipelineRoleUser = {
  __typename?: 'PipelineRoleUser';
  /** When the object was created. */
  createdAt: Scalars['DateTimeOffset'];
  createdBy: Actor;
  /** When the object was deleted. */
  deletedAt?: Maybe<Scalars['DateTimeOffset']>;
  deletedBy?: Maybe<Actor>;
  /** The role of the user. */
  role: Role;
  /** When the object was last updated. */
  updatedAt: Scalars['DateTimeOffset'];
  updatedBy: Actor;
  user: User;
};

/** A portfolio. */
export type Portfolio = PortfolioInfo & {
  __typename?: 'Portfolio';
  /** When the object was created. */
  createdAt: Scalars['DateTimeOffset'];
  createdBy: Actor;
  /** Whether or not the current user has editing rights on this portfolio. */
  currentUserCanEdit: Scalars['Boolean'];
  /** Whether or not the user is an owner of this portfolio. */
  currentUserIsOwner: Scalars['Boolean'];
  /** A list of deals belonging to this portfolio. */
  deals?: Maybe<Array<Deal>>;
  /** When the object was deleted. */
  deletedAt?: Maybe<Scalars['DateTimeOffset']>;
  deletedBy?: Maybe<Actor>;
  /** A description of the pipeportfolioline. */
  description?: Maybe<Scalars['String']>;
  /** A list of dynamic portfolio filters groups. */
  filterGroups?: Maybe<Array<PortfolioFilterGroup>>;
  /** A list of groups that are assigned to this portfolio and their associated permissions. */
  groups?: Maybe<Array<PortfolioRoleGroup>>;
  /** The id of the portfolio. */
  id: Scalars['Int'];
  /** Whether or not the portfolio has a specific set of deals assigned to it or a dynamic group of deals. */
  isDynamic: Scalars['Boolean'];
  /** Whether or not a portfolio is private. */
  isPrivate: Scalars['Boolean'];
  /** The name of the portfolio. */
  name: Scalars['String'];
  /** A list of views that are pinned for this portfolio. */
  pinnedView?: Maybe<PortfolioView>;
  /** A list of views that are pinned for this portfolio. */
  pinnedViews?: Maybe<Array<PortfolioView>>;
  /** Whether or not a members of an organization can edit the portfolio, if the portfolio is public. */
  publicCanEdit: Scalars['Boolean'];
  /** When the object was last updated. */
  updatedAt: Scalars['DateTimeOffset'];
  updatedBy: Actor;
  /** A list of users that are assigned to this portfolio and their associated permissions. */
  users?: Maybe<Array<PortfolioRoleUser>>;
};


/** A portfolio. */
export type PortfolioPinnedViewArgs = {
  id: Scalars['Int'];
};

/** A result type given when the portfolio that was attempted to be created already exists. */
export type PortfolioAlreadyExists = Error & {
  __typename?: 'PortfolioAlreadyExists';
  message: Scalars['String'];
  /** The existing portfolio with matching properties. */
  portfolio?: Maybe<Portfolio>;
};

/** A PortfolioFilter. */
export type PortfolioFilter = {
  __typename?: 'PortfolioFilter';
  /** The attribute that is being filtered on. */
  attribute: PortfolioFilterAttributeUnion;
  /** The filter for this deal attribute filter. */
  filter: CustomFilters;
  isAnd?: Maybe<Scalars['Boolean']>;
  type: PortfolioFilterTypeEnum;
  value?: Maybe<Scalars['String']>;
};

export type PortfolioFilterAttributeUnion = DealAttribute | PartnerRole | TeamMemberRole | Event;

/** A portfolio filter group used to destermine which deals are included in a dynamic portfolio. */
export type PortfolioFilterGroup = {
  __typename?: 'PortfolioFilterGroup';
  /** A list of filters used to determine the deals that are included in this portfolio if the isDynamic flag is set to true. */
  clauses: Array<PortfolioFilter>;
};

export enum PortfolioFilterTypeEnum {
  DealAttribute = 'DEAL_ATTRIBUTE',
  PartnerRole = 'PARTNER_ROLE',
  TeamMemberRole = 'TEAM_MEMBER_ROLE',
  ScheduleEvent = 'SCHEDULE_EVENT'
}

export type PortfolioInfo = {
  /** When the portfolio was created. */
  createdAt: Scalars['DateTimeOffset'];
  /** A description of the portfolio. */
  description?: Maybe<Scalars['String']>;
  /** The id of the portfolio. */
  id: Scalars['Int'];
  /** Whether or not a portfolio is private. */
  isPrivate: Scalars['Boolean'];
  /** The name of the portfolio. */
  name: Scalars['String'];
  /** When the portfolio was last updated. */
  updatedAt: Scalars['DateTimeOffset'];
};

/** An input object used for assigning members to a portfolio. */
export type PortfolioMemberAssignmentInput = {
  /** A list of identifiers for users that should be assigned to this portfolio. */
  userIds: Array<Scalars['Int']>;
  /** A list of identifiers for groups that should be assigned to this portfolio. */
  groupIds: Array<Scalars['Int']>;
  /** The id of the portfolio to assign the users/groups to. */
  fkPortfolio: Scalars['Int'];
  /** The id of the role that the specified members should be assigned to. */
  fkRole: Scalars['Int'];
};

/** PortfolioMemberAssignmentResult */
export type PortfolioMemberAssignmentResult = {
  __typename?: 'PortfolioMemberAssignmentResult';
  membersUpdated: Scalars['Int'];
};

export enum PortfolioMemberEnum {
  User = 'USER',
  Group = 'GROUP'
}

/** PortfolioRoleGroup */
export type PortfolioRoleGroup = {
  __typename?: 'PortfolioRoleGroup';
  /** When the object was created. */
  createdAt: Scalars['DateTimeOffset'];
  createdBy: Actor;
  /** When the object was deleted. */
  deletedAt?: Maybe<Scalars['DateTimeOffset']>;
  deletedBy?: Maybe<Actor>;
  group: Group;
  /** The role of the group. */
  role: Role;
  /** When the object was last updated. */
  updatedAt: Scalars['DateTimeOffset'];
  updatedBy: Actor;
};

/** PortfolioRoleUser */
export type PortfolioRoleUser = {
  __typename?: 'PortfolioRoleUser';
  /** When the object was created. */
  createdAt: Scalars['DateTimeOffset'];
  createdBy: Actor;
  /** When the object was deleted. */
  deletedAt?: Maybe<Scalars['DateTimeOffset']>;
  deletedBy?: Maybe<Actor>;
  /** The role of the user. */
  role: Role;
  /** When the object was last updated. */
  updatedAt: Scalars['DateTimeOffset'];
  updatedBy: Actor;
  user: User;
};

/** A view that has been pinned for a specific portfolio. */
export type PortfolioView = {
  __typename?: 'PortfolioView';
  /** The analysis type to show data for this view. */
  analysisType?: Maybe<AnalysisType>;
  /** A list of metric columns for this view. */
  columns?: Maybe<Array<PortfolioViewColumn>>;
  /** When the object was created. */
  createdAt: Scalars['DateTimeOffset'];
  createdBy: Actor;
  /** When the object was deleted. */
  deletedAt?: Maybe<Scalars['DateTimeOffset']>;
  deletedBy?: Maybe<Actor>;
  filterModel?: Maybe<Scalars['String']>;
  groupRowColumns?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  isDefault: Scalars['Boolean'];
  name: Scalars['String'];
  orderId: Scalars['Decimal'];
  /** The data for this portfolio view. */
  payload?: Maybe<PortfolioViewPayload>;
  /** The portfolio that this pinned report belongs to. */
  portfolio: PortfolioInfo;
  showAnalysisEvents: Scalars['Boolean'];
  showArchivedDeals: Scalars['Boolean'];
  sortModel?: Maybe<Scalars['String']>;
  /** When the object was last updated. */
  updatedAt: Scalars['DateTimeOffset'];
  updatedBy: Actor;
};


/** A view that has been pinned for a specific portfolio. */
export type PortfolioViewPayloadArgs = {
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  showArchivedDeals?: Maybe<Scalars['Boolean']>;
};

/** A column definition for a specific portfolio view. */
export type PortfolioViewColumn = {
  __typename?: 'PortfolioViewColumn';
  format?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  lookupName: Scalars['String'];
  name: Scalars['String'];
  orderId: Scalars['Decimal'];
  sortOrder?: Maybe<Array<Maybe<Scalars['String']>>>;
  type: PortfolioViewColumnTypeEnum;
};

/** A column definition option. */
export type PortfolioViewColumnOption = {
  __typename?: 'PortfolioViewColumnOption';
  id: Scalars['Int'];
  lookupName: Scalars['String'];
  name: Scalars['String'];
  type: PortfolioViewColumnTypeEnum;
};

export enum PortfolioViewColumnTypeEnum {
  Milestone = 'MILESTONE',
  Phase = 'PHASE',
  Metric = 'METRIC',
  DealAttribute = 'DEAL_ATTRIBUTE',
  TeamMemberRole = 'TEAM_MEMBER_ROLE',
  DealPartnerRole = 'DEAL_PARTNER_ROLE',
  BudgetMeasure = 'BUDGET_MEASURE',
  DealArtifact = 'DEAL_ARTIFACT'
}

/** PortfolioViewColumnsRemovedResult */
export type PortfolioViewColumnsRemovedResult = {
  __typename?: 'PortfolioViewColumnsRemovedResult';
  columnsRemoved: Scalars['Int'];
};

/** The data payload for a given portfolio view */
export type PortfolioViewPayload = {
  __typename?: 'PortfolioViewPayload';
  /** A list of columns for a portfolio view. */
  columns?: Maybe<Array<Scalars['String']>>;
  hasMoreDeals?: Maybe<Scalars['Boolean']>;
  /** A list of rows for a portfolio view. */
  rows?: Maybe<Array<Maybe<Array<Maybe<Scalars['String']>>>>>;
};

/** PotentialChangeOrder */
export type PotentialChangeOrder = {
  __typename?: 'PotentialChangeOrder';
  /** The change order package that this PCO belongs to. */
  changeOrder?: Maybe<ChangeOrderPackage>;
  /** The cost source for this potential change order. */
  costSource?: Maybe<GlAccount>;
  /** When the object was created. */
  createdAt: Scalars['DateTimeOffset'];
  createdBy: Actor;
  /** When the object was deleted. */
  deletedAt?: Maybe<Scalars['DateTimeOffset']>;
  deletedBy?: Maybe<Actor>;
  fkChangeOrderPackage?: Maybe<Scalars['Int']>;
  id: Scalars['Int'];
  isExecuted: Scalars['Boolean'];
  isScheduleImpactExcusable?: Maybe<Scalars['Boolean']>;
  number?: Maybe<Scalars['Int']>;
  ownerScheduleImpact?: Maybe<Scalars['Int']>;
  ownerTotal?: Maybe<Scalars['Decimal']>;
  scheduleImpact?: Maybe<Scalars['Int']>;
  /** The status of a potential change order. */
  status: ChangeOrderStatus;
  title?: Maybe<Scalars['String']>;
  total?: Maybe<Scalars['Decimal']>;
  /** The type of a change event. */
  type?: Maybe<PotentialChangeOrderType>;
  /** When the object was last updated. */
  updatedAt: Scalars['DateTimeOffset'];
  updatedBy: Actor;
};

/** PotentialChangeOrderType */
export type PotentialChangeOrderType = {
  __typename?: 'PotentialChangeOrderType';
  /** When the object was created. */
  createdAt: Scalars['DateTimeOffset'];
  createdBy: Actor;
  /** When the object was deleted. */
  deletedAt?: Maybe<Scalars['DateTimeOffset']>;
  deletedBy?: Maybe<Actor>;
  id: Scalars['Int'];
  name: Scalars['String'];
  /** When the object was last updated. */
  updatedAt: Scalars['DateTimeOffset'];
  updatedBy: Actor;
};

/** ProductType */
export type ProductType = {
  __typename?: 'ProductType';
  /** When the object was created. */
  createdAt: Scalars['DateTimeOffset'];
  createdBy: Actor;
  /** When the object was deleted. */
  deletedAt?: Maybe<Scalars['DateTimeOffset']>;
  deletedBy?: Maybe<Actor>;
  id: Scalars['Int'];
  isDisabled: Scalars['Boolean'];
  name: Scalars['String'];
  /** When the object was last updated. */
  updatedAt: Scalars['DateTimeOffset'];
  updatedBy: Actor;
};

/** ProprietaryLevel */
export type ProprietaryLevel = {
  __typename?: 'ProprietaryLevel';
  /** When the object was created. */
  createdAt: Scalars['DateTimeOffset'];
  createdBy: Actor;
  /** When the object was deleted. */
  deletedAt?: Maybe<Scalars['DateTimeOffset']>;
  deletedBy?: Maybe<Actor>;
  id: Scalars['Int'];
  name: Scalars['String'];
  /** When the object was last updated. */
  updatedAt: Scalars['DateTimeOffset'];
  updatedBy: Actor;
};

/** PublishedApprovalPackage */
export type PublishedApprovalPackage = {
  __typename?: 'PublishedApprovalPackage';
  /** The amendment number of this approval package. */
  amendmentNumber?: Maybe<Scalars['Int']>;
  /** The linked analysis instance for this approval package. */
  analysis?: Maybe<DealAnalysis>;
  /** The date and time on which this approval package was archived. */
  archiveDate?: Maybe<Scalars['DateTimeOffset']>;
  /** The contributors of the approval package. */
  contributors?: Maybe<Array<ApprovalPackageContributor>>;
  /** When the object was created. */
  createdAt: Scalars['DateTimeOffset'];
  createdBy: Actor;
  /** Whether or not the current user is a contributor on this IC package. */
  currentUserIsContributor: Scalars['Boolean'];
  /** Whether or not the current user is a contributor on this IC package. */
  currentUserIsReviewer: Scalars['Boolean'];
  /** The deal that this approval package belongs to. */
  deal: DealInfo;
  /** When the object was deleted. */
  deletedAt?: Maybe<Scalars['DateTimeOffset']>;
  deletedBy?: Maybe<Actor>;
  /** A list of exhibits for this approval package. */
  exhibits?: Maybe<Array<DocumentTreeItem>>;
  /** The id of the original approval package that this one was published from. */
  fkPackage?: Maybe<Scalars['Int']>;
  /** The id of this approval package. */
  id: Scalars['Int'];
  /** A flag indicating whether or not this approval package has been published. */
  isPublished: Scalars['Boolean'];
  /** The proposed review date for this approval package. */
  reviewDate?: Maybe<Scalars['Date']>;
  /** The reviewers of the approval package. */
  reviewers?: Maybe<Array<ApprovalPackageReviewer>>;
  /** The linked published schedule for this approval package. */
  schedule?: Maybe<PublishedScheduleInstance>;
  /** The sections of the approval package. */
  sections?: Maybe<Array<PublishedApprovalPackageSection>>;
  /** The status of this approval package. */
  status: ApprovalPackageStatusEnum;
  /** The type of the approval package. */
  type: ApprovalPackageType;
  /** When the object was last updated. */
  updatedAt: Scalars['DateTimeOffset'];
  updatedBy: Actor;
};

/** PublishedApprovalPackageSection */
export type PublishedApprovalPackageSection = {
  __typename?: 'PublishedApprovalPackageSection';
  /** The content for this section, made up of one or more sub-sections. */
  content?: Maybe<Array<PublishedApprovalPackageSubSectionUnion>>;
  /** The column width of this section based on a 12 column wide grid. */
  gridColumnWidth: Scalars['Int'];
  /** The id of the section */
  id: Scalars['Int'];
  /** The order id of the section. */
  orderId?: Maybe<Scalars['Decimal']>;
  /** The title of this approval package section. */
  title: Scalars['String'];
};

/** PublishedApprovalPackageSubSectionChecklist */
export type PublishedApprovalPackageSubSectionChecklist = {
  __typename?: 'PublishedApprovalPackageSubSectionChecklist';
  /** The column width of this sub-section based on a 12 column wide grid. */
  gridColumnWidth: Scalars['Int'];
  /** The id of the section */
  id: Scalars['Int'];
  /** A list of checklist items for a given approval package. */
  items?: Maybe<Array<ApprovalPackageChecklistItem>>;
  /** The order id of the sub-section. */
  orderId?: Maybe<Scalars['Decimal']>;
  /** The title of this approval package sub-section. */
  title: Scalars['String'];
};

/** PublishedApprovalPackageSubSectionDealBackground */
export type PublishedApprovalPackageSubSectionDealBackground = {
  __typename?: 'PublishedApprovalPackageSubSectionDealBackground';
  /** deal background text */
  dealBackgroundText?: Maybe<Scalars['String']>;
  /** The column width of this sub-section based on a 12 column wide grid. */
  gridColumnWidth: Scalars['Int'];
  /** The id of the section */
  id: Scalars['Int'];
  /** The order id of the sub-section. */
  orderId?: Maybe<Scalars['Decimal']>;
  /** The title of this approval package sub-section. */
  title: Scalars['String'];
};

/** PublishedApprovalPackageSubSectionEarnestMoneyDeposit */
export type PublishedApprovalPackageSubSectionEarnestMoneyDeposit = {
  __typename?: 'PublishedApprovalPackageSubSectionEarnestMoneyDeposit';
  /** earnest money deposit text */
  earnestMoneyDepositText?: Maybe<Scalars['String']>;
  /** The column width of this sub-section based on a 12 column wide grid. */
  gridColumnWidth: Scalars['Int'];
  /** The id of the section */
  id: Scalars['Int'];
  /** The order id of the sub-section. */
  orderId?: Maybe<Scalars['Decimal']>;
  /** The title of this approval package sub-section. */
  title: Scalars['String'];
};

/** PublishedApprovalPackageSubSectionGcEstimate */
export type PublishedApprovalPackageSubSectionGcEstimate = {
  __typename?: 'PublishedApprovalPackageSubSectionGcEstimate';
  /** A list of gcEstimate items for a given approval package. */
  gcEstimates?: Maybe<Array<ApprovalPackageGcEstimate>>;
  /** The column width of this sub-section based on a 12 column wide grid. */
  gridColumnWidth: Scalars['Int'];
  /** The id of the section */
  id: Scalars['Int'];
  /** The order id of the sub-section. */
  orderId?: Maybe<Scalars['Decimal']>;
  /** The title of this approval package sub-section. */
  title: Scalars['String'];
};

/** PublishedApprovalPackageSubSectionImages */
export type PublishedApprovalPackageSubSectionImages = {
  __typename?: 'PublishedApprovalPackageSubSectionImages';
  /** The column width of this sub-section based on a 12 column wide grid. */
  gridColumnWidth: Scalars['Int'];
  /** The id of the section */
  id: Scalars['Int'];
  /** A list of the images associated with this sub-section. */
  images?: Maybe<Array<PublishedIcSubSectionImageContent>>;
  /** The order id of the sub-section. */
  orderId?: Maybe<Scalars['Decimal']>;
  /** The title of this approval package sub-section. */
  title: Scalars['String'];
};

/** PublishedApprovalPackageSubSectionKeyRisks */
export type PublishedApprovalPackageSubSectionKeyRisks = {
  __typename?: 'PublishedApprovalPackageSubSectionKeyRisks';
  /** The column width of this sub-section based on a 12 column wide grid. */
  gridColumnWidth: Scalars['Int'];
  /** The id of the section */
  id: Scalars['Int'];
  /** A list of key risks for a given approval package. */
  keyRisks?: Maybe<Array<ApprovalPackageKeyRisk>>;
  /** The order id of the sub-section. */
  orderId?: Maybe<Scalars['Decimal']>;
  /** The title of this approval package sub-section. */
  title: Scalars['String'];
};

/** PublishedApprovalPackageSubSectionMerits */
export type PublishedApprovalPackageSubSectionMerits = {
  __typename?: 'PublishedApprovalPackageSubSectionMerits';
  /** The column width of this sub-section based on a 12 column wide grid. */
  gridColumnWidth: Scalars['Int'];
  /** The id of the section */
  id: Scalars['Int'];
  /** A list of merits for a given approval package. */
  merits?: Maybe<Array<ApprovalPackageMerit>>;
  /** The order id of the sub-section. */
  orderId?: Maybe<Scalars['Decimal']>;
  /** The title of this approval package sub-section. */
  title: Scalars['String'];
};

/** PublishedApprovalPackageSubSectionPageBreak */
export type PublishedApprovalPackageSubSectionPageBreak = {
  __typename?: 'PublishedApprovalPackageSubSectionPageBreak';
  /** The id of the section */
  id: Scalars['Int'];
  /** The order id of the sub-section. */
  orderId?: Maybe<Scalars['Decimal']>;
};

/** PublishedApprovalPackageSubSectionTable */
export type PublishedApprovalPackageSubSectionTable = {
  __typename?: 'PublishedApprovalPackageSubSectionTable';
  /** A list of the cells that make up the table of metrics. */
  cells?: Maybe<Array<PublishedIcSubSectionTableCell>>;
  /** The number of columns of metrics to show. */
  columns: Scalars['Int'];
  /** The column width of this sub-section based on a 12 column wide grid. */
  gridColumnWidth: Scalars['Int'];
  /** The id of the section */
  id: Scalars['Int'];
  /** The order id of the sub-section. */
  orderId?: Maybe<Scalars['Decimal']>;
  /** The title of this approval package sub-section. */
  title: Scalars['String'];
};

/** PublishedApprovalPackageSubSectionText */
export type PublishedApprovalPackageSubSectionText = {
  __typename?: 'PublishedApprovalPackageSubSectionText';
  /** The column width of this sub-section based on a 12 column wide grid. */
  gridColumnWidth: Scalars['Int'];
  /** The id of the section */
  id: Scalars['Int'];
  /** The order id of the sub-section. */
  orderId?: Maybe<Scalars['Decimal']>;
  /** The text of this section. */
  text?: Maybe<Scalars['String']>;
  /** The title of this approval package sub-section. */
  title: Scalars['String'];
};

export type PublishedApprovalPackageSubSectionUnion = PublishedApprovalPackageSubSectionText | PublishedApprovalPackageSubSectionChecklist | PublishedApprovalPackageSubSectionGcEstimate | PublishedApprovalPackageSubSectionKeyRisks | PublishedApprovalPackageSubSectionMerits | PublishedApprovalPackageSubSectionEarnestMoneyDeposit | PublishedApprovalPackageSubSectionDealBackground | PublishedApprovalPackageSubSectionTable | PublishedApprovalPackageSubSectionImages | PublishedApprovalPackageSubSectionPageBreak;

/** PublishedCapitalForecast */
export type PublishedCapitalForecast = {
  __typename?: 'PublishedCapitalForecast';
  /** A list of actual cost account summaries per period. */
  accountTreeActuals?: Maybe<Array<GlAccountSummary>>;
  /** A list of forecasted cost account summaries per period. */
  accountTreeForecasts?: Maybe<Array<GlAccountSummary>>;
  /** A list of approved budget totals per cost code. */
  approvedBudget?: Maybe<Array<CostAccountBudgetSummary>>;
  comment?: Maybe<Scalars['String']>;
  /** When the object was created. */
  createdAt: Scalars['DateTimeOffset'];
  createdBy: Actor;
  /** When the object was deleted. */
  deletedAt?: Maybe<Scalars['DateTimeOffset']>;
  deletedBy?: Maybe<Actor>;
  id: Scalars['Int'];
  name: Scalars['String'];
  /** A list of pending budget totals per cost code. */
  pendingBudget?: Maybe<Array<CostAccountBudgetSummary>>;
  /** When the object was last updated. */
  updatedAt: Scalars['DateTimeOffset'];
  updatedBy: Actor;
};


/** PublishedCapitalForecast */
export type PublishedCapitalForecastAccountTreeActualsArgs = {
  fkGlAccountTree: Scalars['Int'];
};


/** PublishedCapitalForecast */
export type PublishedCapitalForecastAccountTreeForecastsArgs = {
  fkGlAccountTree: Scalars['Int'];
};


/** PublishedCapitalForecast */
export type PublishedCapitalForecastApprovedBudgetArgs = {
  fkGlAccountTree: Scalars['Int'];
};


/** PublishedCapitalForecast */
export type PublishedCapitalForecastPendingBudgetArgs = {
  fkGlAccountTree: Scalars['Int'];
};

/** Deal Event */
export type PublishedDealEvent = {
  __typename?: 'PublishedDealEvent';
  /** The category that for this event. */
  category?: Maybe<EventCategory>;
  /** When the object was created. */
  createdAt: Scalars['DateTimeOffset'];
  createdBy: Actor;
  dealEventId: Scalars['Int'];
  /** When the object was deleted. */
  deletedAt?: Maybe<Scalars['DateTimeOffset']>;
  deletedBy?: Maybe<Actor>;
  /** The list of events that this event depends on. */
  dependsOn?: Maybe<Array<Event>>;
  endDate?: Maybe<Scalars['Date']>;
  id: Scalars['Int'];
  isComplete?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  orderId: Scalars['Decimal'];
  /** The state that this deal resides in. */
  parent?: Maybe<Event>;
  startDate?: Maybe<Scalars['Date']>;
  /** The type of this event. */
  type: DealEventTypeEnum;
  /** When the object was last updated. */
  updatedAt: Scalars['DateTimeOffset'];
  updatedBy: Actor;
};

/** A result type given when the deal event that was attempted to be created already exists on the published schedule. */
export type PublishedDealEventAlreadyExists = Error & {
  __typename?: 'PublishedDealEventAlreadyExists';
  /** The existing event with matching properties. */
  event?: Maybe<PublishedDealEvent>;
  message: Scalars['String'];
};

/** PublishedIcSubSectionImageContent */
export type PublishedIcSubSectionImageContent = {
  __typename?: 'PublishedIcSubSectionImageContent';
  /** The id of the image, used for downloading its content. */
  fkIcPackageExhibitPublished: Scalars['Int'];
  /** The id of the content */
  id: Scalars['Int'];
  /** The title of this approval package sub-section. */
  title?: Maybe<Scalars['String']>;
};

/** PublishedIcSubSectionTableCell */
export type PublishedIcSubSectionTableCell = {
  __typename?: 'PublishedIcSubSectionTableCell';
  /** A list of attributes for a specific deal. */
  attributes?: Maybe<Array<ApprovalPackageSubSectionDealAttribute>>;
  /** A list of the entries for this cell. */
  entries?: Maybe<Array<IcSubSectionTableCellEntry>>;
  /** A list of the events added to this cell and their values. */
  events?: Maybe<Array<ApprovalPackageSubSectionEvent>>;
  /** The id of the cell */
  id: Scalars['Int'];
  /** A list of the metrics added to this cell and their values. */
  metrics?: Maybe<Array<ApprovalPackageSubSectionMetric>>;
  orderId?: Maybe<Scalars['Decimal']>;
  /** A list of partners for a specific deal. */
  partners?: Maybe<Array<ApprovalPackageSubSectionPartnerRole>>;
  /** A list of team members for a specific deal. */
  teamMembers?: Maybe<Array<ApprovalPackageSubSectionTeamMemberRole>>;
};

/** PublishedScheduleInstance */
export type PublishedScheduleInstance = {
  __typename?: 'PublishedScheduleInstance';
  /** When the object was created. */
  createdAt: Scalars['DateTimeOffset'];
  createdBy: Actor;
  /** When the object was deleted. */
  deletedAt?: Maybe<Scalars['DateTimeOffset']>;
  deletedBy?: Maybe<Actor>;
  description?: Maybe<Scalars['String']>;
  /** A list of events associated with this . */
  events?: Maybe<Array<PublishedDealEvent>>;
  /** The Final Residential TCO event for this published schedule, if it exists. */
  finalResidentialTCO?: Maybe<PublishedDealEvent>;
  id: Scalars['Int'];
  /** When the object was last updated. */
  updatedAt: Scalars['DateTimeOffset'];
  updatedBy: Actor;
};

export type RealTraxMutation = {
  __typename?: 'RealTraxMutation';
  addAccountsToAccountTree?: Maybe<Array<GlAccountTreeBranch>>;
  addChecklist?: Maybe<AddChecklistResult>;
  addChecklistsToPipeline?: Maybe<PipelineEntityResult>;
  addDealPartner?: Maybe<AddDealPartnerResult>;
  addEventsToDeal?: Maybe<DealEventsResult>;
  addEventsToPipeline?: Maybe<PipelineEntityResult>;
  addForecastRulesToPipeline?: Maybe<PipelineEntityResult>;
  addModulesToPipeline?: Maybe<PipelineEntityResult>;
  addPinnedEventsToPipeline?: Maybe<PipelineEntityResult>;
  addPinnedMetricsToPipeline?: Maybe<PipelineEntityResult>;
  addPinnedPartnerRolesToPipeline?: Maybe<PipelineEntityResult>;
  addPinnedTeamMemberRolesToPipeline?: Maybe<PipelineEntityResult>;
  addPortfolioDeals?: Maybe<UpdatePortfolioDealsResult>;
  addPortfolioViewColumn: PortfolioViewColumn;
  addTeamMember?: Maybe<AddTeamMemberResult>;
  addUsersToGroup?: Maybe<Group>;
  archiveAnalysis: DealAnalysis;
  archiveApprovalPackage: UpdatePackageResult;
  archiveDeal?: Maybe<Deal>;
  assignChangeEventsToParent?: Maybe<Array<ChangeEvent>>;
  assignChangeOrdersToParent?: Maybe<Array<ChangeOrderPackage>>;
  assignContacts?: Maybe<Array<ContactInfo>>;
  assignMembersToDeal: DealMemberAssignmentResult;
  assignMembersToOrganization: OrganizationMemberAssignmentResult;
  assignMembersToPipeline: PipelineMemberAssignmentResult;
  assignMembersToPortfolio: PortfolioMemberAssignmentResult;
  assignPotentialChangeOrdersToParent?: Maybe<Array<PotentialChangeOrder>>;
  bulkEditVisibleDealEvents?: Maybe<Scalars['Boolean']>;
  bulkUnhideDealEvents?: Maybe<Scalars['Boolean']>;
  changeAccountTreeParent?: Maybe<Array<GlAccountTreeBranch>>;
  createAnalysis: DealAnalysis;
  createAnalysisEvent: AnalysisEvent;
  createAnalysisMetric: AnalysisMetric;
  createAnalysisTextMetric: AnalysisTextMetric;
  createAnalysisType: CreateAnalysisTypeResult;
  createAnalysisWithMetrics: Array<AnalysisMetric>;
  createApprovalPackage: CreatePackageResult;
  createApprovalPackageContributor: ApprovalPackageContributor;
  createApprovalPackageReviewer: ApprovalPackageReviewer;
  createApprovalPackageSection: ApprovalPackageSection;
  createApprovalPackageSubSection: ApprovalPackageSubSectionUnion;
  createApprovalPackageSubSectionTableCell: IcSubSectionTableCell;
  createApprovalPackageTemplate: CreatePackageTemplateResult;
  createApprovalPackageTemplateFromPackage: TemplateApprovalPackage;
  createApprovalPackageTemplateSection: TemplateApprovalPackageSection;
  createApprovalPackageTemplateSubSection: TemplateApprovalPackageSubSectionUnion;
  createApprovalPackageTemplateSubSectionTableCell: TemplateIcSubSectionTableCell;
  createApprovalPackageType: CreatePackageTypeResult;
  createBond?: Maybe<Bond>;
  createBudgetRevision?: Maybe<Revision>;
  createBudgetRevisionCostAccount?: Maybe<RevisionCostAccount>;
  createBuilding?: Maybe<Building>;
  createChangeEvent?: Maybe<ChangeEvent>;
  createChangeOrderPackage?: Maybe<ChangeOrderPackage>;
  createChecklist?: Maybe<CreateChecklistResult>;
  createChecklistCategory?: Maybe<CreateChecklistCategoryResult>;
  createChecklistItem?: Maybe<CreateChecklistItemResult>;
  createComment?: Maybe<Comment>;
  createConfigurableEntity?: Maybe<CreateConfigurableEntityResult>;
  createContact?: Maybe<CreateContactResult>;
  createContract?: Maybe<Contract>;
  createDeal?: Maybe<CreateDealResult>;
  createDealEvent?: Maybe<CreateDealEventResult>;
  createEvent?: Maybe<CreateEventResult>;
  createFirm?: Maybe<Firm>;
  createFirmRegion?: Maybe<FirmRegion>;
  createForecastRule: ForecastRule;
  createGcEstimate?: Maybe<GcEstimate>;
  createGlAccount: CreateGlAccountResult;
  createGlAccountTree: CreateGlAccountTreeResult;
  createGroup?: Maybe<Group>;
  createInteraction?: Maybe<Interaction>;
  createInterestRate: CreateInterestRateResult;
  createInterestRateTransaction?: Maybe<Transaction>;
  createKeyRisk?: Maybe<KeyRisk>;
  createLabel?: Maybe<Label>;
  createMerit?: Maybe<Merit>;
  createMetric: CreateMetricResult;
  createPartnerRole?: Maybe<UpdatePartnerRoleResult>;
  createPipeline?: Maybe<Pipeline>;
  createPipelineAccountOffset: PipelineAccountOffset;
  createPipelineContingencyCategory: PipelineContingencyCategory;
  createPipelineDealStage?: Maybe<CreateDealStageResult>;
  createPipelineGeneralOffset: PipelineGeneralOffset;
  createPortfolio?: Maybe<CreatePortfolioResult>;
  createPortfolioView?: Maybe<PortfolioView>;
  createPotentialChangeOrder?: Maybe<PotentialChangeOrder>;
  createPublishedDealEvent?: Maybe<CreatePublishedDealEventResult>;
  createScheduleInstance?: Maybe<PublishedScheduleInstance>;
  createStatusUpdate?: Maybe<DealStatusUpdate>;
  createStatusUpdateTemplate?: Maybe<DealStatusUpdateTemplate>;
  createTeamMemberRole?: Maybe<UpdateTeamMemberRoleResult>;
  createTextMetric: CreateTextMetricResult;
  createTransaction?: Maybe<Transaction>;
  deleteAnalysis: DeleteAnalysisResult;
  deleteAnalysisEvent: AnalysisEvent;
  deleteAnalysisMetric: AnalysisMetric;
  deleteAnalysisTextMetric: AnalysisTextMetric;
  deleteAnalysisType: DeleteAnalysisTypeResult;
  deleteApprovalPackage: DeletePackageResult;
  deleteApprovalPackageContributor: ApprovalPackageContributor;
  deleteApprovalPackageExhibit: DocumentTreeItem;
  deleteApprovalPackageReviewer: ApprovalPackageReviewer;
  deleteApprovalPackageSection: ApprovalPackageSection;
  deleteApprovalPackageSubsection: ApprovalPackageSubSectionUnion;
  deleteApprovalPackageSubSectionTableCell: IcSubSectionTableCell;
  deleteApprovalPackageTemplate: DeletePackageTemplateResult;
  deleteApprovalPackageTemplateSection: TemplateApprovalPackageSection;
  deleteApprovalPackageTemplateSubsection: TemplateApprovalPackageSubSectionUnion;
  deleteApprovalPackageTemplateSubSectionTableCell: TemplateIcSubSectionTableCell;
  deleteApprovalPackageType: DeletePackageTypeResult;
  deleteBond?: Maybe<Bond>;
  deleteBudgetRevision?: Maybe<Revision>;
  deleteBudgetRevisionCostAccount?: Maybe<RevisionCostAccount>;
  deleteBudgetRevisionCostAccounts?: Maybe<Array<RevisionCostAccount>>;
  deleteBuilding?: Maybe<Building>;
  deleteChangeEvent?: Maybe<ChangeEvent>;
  deleteChangeOrderPackage?: Maybe<ChangeOrderPackage>;
  deleteChecklist?: Maybe<Checklist>;
  deleteChecklistItem?: Maybe<ChecklistItem>;
  deleteComment?: Maybe<Comment>;
  deleteConfigurableEntity?: Maybe<DeleteConfigurableEntityResult>;
  deleteContact?: Maybe<DeleteContactResult>;
  deleteContactAttachment: ContactAttachment;
  deleteContract?: Maybe<Contract>;
  deleteDeal?: Maybe<Deal>;
  deleteDealArtifact: DealArtifact;
  deleteEvent?: Maybe<DeleteEventResult>;
  deleteFirm?: Maybe<DeleteFirmResult>;
  deleteFirmAttachment: FirmAttachment;
  deleteFirmRegion?: Maybe<DeleteFirmRegionResult>;
  deleteForecastRule: ForecastRule;
  deleteGcEstimate?: Maybe<GcEstimate>;
  deleteGlAccount: DeleteGlAccountResult;
  deleteGlAccountTree: DeleteGlAccountTreeResult;
  deleteGroup?: Maybe<Group>;
  deleteInteraction?: Maybe<Interaction>;
  deleteInterestRate: DeleteInterestRateResult;
  deleteKeyRisk?: Maybe<KeyRisk>;
  deleteLabel?: Maybe<Label>;
  deleteMerit?: Maybe<Merit>;
  deleteMetric: DeleteMetricResult;
  deletePartnerRole?: Maybe<DeletePartnerRoleResult>;
  deletePipeline?: Maybe<DeletePipelineResult>;
  deletePipelineAccountOffset: PipelineAccountOffset;
  deletePipelineContingencyCategory: PipelineContingencyCategory;
  deletePipelineDealStage?: Maybe<DeleteDealStageResult>;
  deletePipelineGeneralOffset: PipelineGeneralOffset;
  deletePortfolio?: Maybe<PortfolioInfo>;
  deletePortfolioView?: Maybe<PortfolioView>;
  deletePotentialChangeOrder?: Maybe<PotentialChangeOrder>;
  deletePublishedCapitalForecast: PublishedCapitalForecast;
  deleteScheduleInstance?: Maybe<PublishedScheduleInstance>;
  deleteStatusUpdate?: Maybe<DealStatusUpdate>;
  deleteStatusUpdateTemplate?: Maybe<DealStatusUpdateTemplate>;
  deleteTeamMemberRole?: Maybe<DeleteTeamMemberRoleResult>;
  deleteTextMetric: DeleteTextMetricResult;
  deleteTransaction?: Maybe<Transaction>;
  disableForecastRules: Array<Maybe<Scalars['Int']>>;
  duplicateAnalysis: DealAnalysis;
  enableForecastRules: Array<Maybe<Scalars['Int']>>;
  forecastCapitalizationAccounts?: Maybe<Scalars['Boolean']>;
  forecastInterestAccount?: Maybe<Scalars['Boolean']>;
  generateTransactionDetailPreview?: Maybe<Array<TranasctionPeriodDetailPreview>>;
  publishApprovalPackage: PublishedApprovalPackage;
  publishCapitalForecast: PublishedCapitalForecast;
  publishScheduleInstance?: Maybe<PublishedScheduleInstance>;
  refreshApprovalPackageDealMerits: Array<ApprovalPackageMerit>;
  refreshApprovalPackageKeyRisks: Array<ApprovalPackageKeyRisk>;
  removeAccountsFromAccountTree?: Maybe<Array<GlAccountTreeBranch>>;
  removeChecklist?: Maybe<RemoveChecklistResult>;
  removeChecklistsFromPipeline?: Maybe<PipelineEntityResult>;
  removeDealPartner?: Maybe<DealPartner>;
  removeEventsFromDeal?: Maybe<DealEventsResult>;
  removeEventsFromPipeline?: Maybe<PipelineEntityResult>;
  removeForecastRulesFromPipeline?: Maybe<PipelineEntityResult>;
  removeMembersFromDeal: DealMemberAssignmentResult;
  removeMembersFromOrganization: OrganizationMemberAssignmentResult;
  removeMembersFromPipeline: PipelineMemberAssignmentResult;
  removeMembersFromPortfolio: PortfolioMemberAssignmentResult;
  removeModulesFromPipeline?: Maybe<PipelineEntityResult>;
  removePinnedEventsFromPipeline?: Maybe<PipelineEntityResult>;
  removePinnedMetricsFromPipeline?: Maybe<PipelineEntityResult>;
  removePinnedPartnerRolesFromPipeline?: Maybe<PipelineEntityResult>;
  removePinnedTeamMemberRolesFromPipeline?: Maybe<PipelineEntityResult>;
  removePortfolioDeals?: Maybe<UpdatePortfolioDealsResult>;
  removePortfolioViewColumns: PortfolioViewColumnsRemovedResult;
  removeTeamMember?: Maybe<DealTeamMemberUser>;
  removeUsersFromGroup?: Maybe<Group>;
  reorderConfigurableEntity?: Maybe<UpdateConfigurableEntityResult>;
  reorderPipelineDealStage?: Maybe<UpdateDealStageResult>;
  reorderPipelinePinnedEvent?: Maybe<PipelineEntityResult>;
  reorderPipelinePinnedMetric?: Maybe<PipelineEntityResult>;
  reorderPipelinePinnedPartnerRole?: Maybe<PipelineEntityResult>;
  reorderPipelinePinnedTeamMemberRole?: Maybe<PipelineEntityResult>;
  runDefaultForecastRules?: Maybe<ForecastRuleExecutionResult>;
  setApprovalPackageSubSectionTableColumns: ApprovalPackageSubSectionTable;
  setApprovalPackageTemplateSubSectionTableColumns: TemplateApprovalPackageSubSectionTable;
  setEntriesForApprovalPackageSubSectionTableCell?: Maybe<ApprovalPackageEntityResult>;
  setEntriesForApprovalPackageTemplateSubSectionTableCell?: Maybe<ApprovalPackageEntityResult>;
  unarchiveDeal?: Maybe<Deal>;
  unassignContacts?: Maybe<Array<ContactInfo>>;
  updateAnalysis: DealAnalysis;
  updateAnalysisEvent: AnalysisEvent;
  updateAnalysisMetric: AnalysisMetric;
  updateAnalysisTextMetric: AnalysisTextMetric;
  updateAnalysisType: UpdateAnalysisTypeResult;
  updateApprovalPackage: UpdatePackageResult;
  updateApprovalPackageExhibit: DocumentTreeItem;
  updateApprovalPackageReviewer: ApprovalPackageReviewer;
  updateApprovalPackageSection: ApprovalPackageSection;
  updateApprovalPackageSubsection: ApprovalPackageSubSectionUnion;
  updateApprovalPackageSubsectionChecklist: ApprovalPackageSubSectionChecklist;
  updateApprovalPackageSubsectionText: ApprovalPackageSubSectionText;
  updateApprovalPackageTemplate: UpdatePackageTemplateResult;
  updateApprovalPackageTemplateSection: TemplateApprovalPackageSection;
  updateApprovalPackageTemplateSubsection: TemplateApprovalPackageSubSectionUnion;
  updateApprovalPackageTemplateSubsectionChecklist: ApprovalPackageSubSectionChecklist;
  updateApprovalPackageTemplateSubsectionText: TemplateApprovalPackageSubSectionText;
  updateApprovalPackageType: UpdatePackageTypeResult;
  updateBond?: Maybe<Bond>;
  updateBudgetRevision?: Maybe<Revision>;
  updateBudgetRevisionCostAccount?: Maybe<RevisionCostAccount>;
  updateBuilding?: Maybe<Building>;
  updateChangeEvent?: Maybe<ChangeEvent>;
  updateChangeOrderPackage?: Maybe<ChangeOrderPackage>;
  updateChecklist?: Maybe<UpdateChecklistResult>;
  updateChecklistItem?: Maybe<ChecklistItem>;
  updateComment?: Maybe<Comment>;
  updateConfigurableEntity?: Maybe<UpdateConfigurableEntityResult>;
  updateContact?: Maybe<UpdateContactResult>;
  updateContract?: Maybe<Contract>;
  updateDeal?: Maybe<Deal>;
  updateDealChecklistItem?: Maybe<DealChecklistItem>;
  updateDealEvent?: Maybe<UpdateDealEventResult>;
  updateDealMemberRole: UpdateDealMemberRoleResult;
  updateDealPartner?: Maybe<DealPartner>;
  updateDealPartners?: Maybe<Array<Maybe<DealPartner>>>;
  updateDynamicPortfolioFilters?: Maybe<PortfolioInfo>;
  updateEvent?: Maybe<UpdateEventResult>;
  updateFirm?: Maybe<Firm>;
  updateFirmRegion?: Maybe<UpdateFirmRegionResult>;
  updateForecastRule: ForecastRule;
  updateGcEstimate?: Maybe<GcEstimate>;
  updateGlAccount: UpdateGlAccountResult;
  updateGlAccountTree: UpdateGlAccountTreeResult;
  updateGroup?: Maybe<Group>;
  updateInteraction?: Maybe<Interaction>;
  updateInterestRate: UpdateInterestRateResult;
  updateKeyRisk?: Maybe<KeyRisk>;
  updateLabel?: Maybe<Label>;
  updateMerit?: Maybe<Merit>;
  updateMetric: UpdateMetricResult;
  updateOrganizationMemberRole: UpdateOrganizationMemberRoleResult;
  updatePartnerRole?: Maybe<UpdatePartnerRoleResult>;
  updatePipeline?: Maybe<Pipeline>;
  updatePipelineAccountOffset: PipelineAccountOffset;
  updatePipelineContingencyCategory: PipelineContingencyCategory;
  updatePipelineDealStage?: Maybe<UpdateDealStageResult>;
  updatePipelineGeneralOffset: PipelineGeneralOffset;
  updatePipelineMemberRole: UpdatePipelineMemberRoleResult;
  updatePortfolio?: Maybe<PortfolioInfo>;
  updatePortfolioMemberRole: UpdatePortfolioMemberRoleResult;
  updatePortfolioView?: Maybe<PortfolioView>;
  updatePortfolioViewColumn: PortfolioViewColumn;
  updatePotentialChangeOrder?: Maybe<PotentialChangeOrder>;
  updatePublishedDealEvent?: Maybe<UpdatePublishedDealEventResult>;
  updateScheduleInstance?: Maybe<PublishedScheduleInstance>;
  updateStatusUpdate?: Maybe<DealStatusUpdate>;
  updateStatusUpdateTemplate?: Maybe<DealStatusUpdateTemplate>;
  updateTeamMember?: Maybe<DealTeamMemberUser>;
  updateTeamMemberRole?: Maybe<UpdateTeamMemberRoleResult>;
  updateTeamMembers?: Maybe<Array<Maybe<DealTeamMemberUser>>>;
  updateTextMetric: UpdateTextMetricResult;
  updateTransaction?: Maybe<Transaction>;
  updateTransactions?: Maybe<Array<Maybe<Transaction>>>;
};


export type RealTraxMutationAddAccountsToAccountTreeArgs = {
  id: Scalars['Int'];
  parentId?: Maybe<Scalars['Int']>;
  accounts: Array<Scalars['Int']>;
};


export type RealTraxMutationAddChecklistArgs = {
  dealChecklist: DealChecklistInput;
};


export type RealTraxMutationAddChecklistsToPipelineArgs = {
  id: Scalars['Int'];
  checklistIds: Array<Scalars['Int']>;
};


export type RealTraxMutationAddDealPartnerArgs = {
  partner: AddDealPartnerInput;
};


export type RealTraxMutationAddEventsToDealArgs = {
  dealId: Scalars['Int'];
  eventIds: Array<Scalars['Int']>;
};


export type RealTraxMutationAddEventsToPipelineArgs = {
  id: Scalars['Int'];
  eventIds: Array<Scalars['Int']>;
};


export type RealTraxMutationAddForecastRulesToPipelineArgs = {
  id: Scalars['Int'];
  forecastRuleIds: Array<Scalars['Int']>;
};


export type RealTraxMutationAddModulesToPipelineArgs = {
  id: Scalars['Int'];
  moduleIds: Array<Scalars['Int']>;
};


export type RealTraxMutationAddPinnedEventsToPipelineArgs = {
  id: Scalars['Int'];
  eventIds: Array<Scalars['Int']>;
};


export type RealTraxMutationAddPinnedMetricsToPipelineArgs = {
  id: Scalars['Int'];
  metricIds: Array<Scalars['Int']>;
};


export type RealTraxMutationAddPinnedPartnerRolesToPipelineArgs = {
  id: Scalars['Int'];
  roleIds: Array<Scalars['Int']>;
};


export type RealTraxMutationAddPinnedTeamMemberRolesToPipelineArgs = {
  id: Scalars['Int'];
  roleIds: Array<Scalars['Int']>;
};


export type RealTraxMutationAddPortfolioDealsArgs = {
  portfolioDeals: UpdatePortfolioDealsInput;
};


export type RealTraxMutationAddPortfolioViewColumnArgs = {
  column: AddPortfolioViewColumnInput;
};


export type RealTraxMutationAddTeamMemberArgs = {
  teamMember: AddTeamMemberInput;
};


export type RealTraxMutationAddUsersToGroupArgs = {
  groupId: Scalars['Int'];
  userIds: Array<Scalars['Int']>;
};


export type RealTraxMutationArchiveAnalysisArgs = {
  id: Scalars['Int'];
};


export type RealTraxMutationArchiveApprovalPackageArgs = {
  id: Scalars['Int'];
  status: ApprovalPackageStatusEnum;
};


export type RealTraxMutationArchiveDealArgs = {
  id: Scalars['Int'];
  status: DealArchiveStatusEnum;
  reason?: Maybe<Scalars['String']>;
};


export type RealTraxMutationAssignChangeEventsToParentArgs = {
  dealId: Scalars['Int'];
  changeEventIds: Array<Scalars['Int']>;
  parentId?: Maybe<Scalars['Int']>;
};


export type RealTraxMutationAssignChangeOrdersToParentArgs = {
  dealId: Scalars['Int'];
  changeOrderIds: Array<Scalars['Int']>;
  parentId?: Maybe<Scalars['Int']>;
};


export type RealTraxMutationAssignContactsArgs = {
  assignment: ContactAssignmentInput;
};


export type RealTraxMutationAssignMembersToDealArgs = {
  assignment: DealMemberAssignmentInput;
};


export type RealTraxMutationAssignMembersToOrganizationArgs = {
  assignment: OrganizationMemberAssignmentInput;
};


export type RealTraxMutationAssignMembersToPipelineArgs = {
  assignment: PipelineMemberAssignmentInput;
};


export type RealTraxMutationAssignMembersToPortfolioArgs = {
  assignment: PortfolioMemberAssignmentInput;
};


export type RealTraxMutationAssignPotentialChangeOrdersToParentArgs = {
  dealId: Scalars['Int'];
  potentialChangeOrderIds: Array<Scalars['Int']>;
  parentId?: Maybe<Scalars['Int']>;
};


export type RealTraxMutationBulkEditVisibleDealEventsArgs = {
  pipelineId: Scalars['Int'];
  dealIds: Array<Scalars['Int']>;
  eventIds: Array<Scalars['Int']>;
};


export type RealTraxMutationBulkUnhideDealEventsArgs = {
  pipelineId: Scalars['Int'];
  dealIds: Array<Scalars['Int']>;
  eventIds: Array<Scalars['Int']>;
};


export type RealTraxMutationChangeAccountTreeParentArgs = {
  id: Scalars['Int'];
  parentId?: Maybe<Scalars['Int']>;
  accounts: Array<Scalars['Int']>;
};


export type RealTraxMutationCreateAnalysisArgs = {
  analysis: CreateAnalysisInput;
};


export type RealTraxMutationCreateAnalysisEventArgs = {
  event: CreateAnalysisEventInput;
};


export type RealTraxMutationCreateAnalysisMetricArgs = {
  metric: CreateAnalysisMetricInput;
};


export type RealTraxMutationCreateAnalysisTextMetricArgs = {
  textMetric: CreateAnalysisTextMetricInput;
};


export type RealTraxMutationCreateAnalysisTypeArgs = {
  analysisType: CreateAnalysisTypeInput;
};


export type RealTraxMutationCreateAnalysisWithMetricsArgs = {
  fkDeal: Scalars['Int'];
  fkScenarioType: Scalars['Int'];
  fkAnalysisType: Scalars['Int'];
  metrics: Array<CreateAnalysisWithMetricsInput>;
  events: Array<CreateAnalysisWithEventsInput>;
  texts: Array<CreateAnalysisWithTextMetricsInput>;
};


export type RealTraxMutationCreateApprovalPackageArgs = {
  approvalPackage: CreateApprovalPackageInput;
};


export type RealTraxMutationCreateApprovalPackageContributorArgs = {
  contributor: CreateApprovalPackageContributorInput;
};


export type RealTraxMutationCreateApprovalPackageReviewerArgs = {
  reviewer: CreateApprovalPackageReviewerInput;
};


export type RealTraxMutationCreateApprovalPackageSectionArgs = {
  section: CreateApprovalPackageSectionInput;
};


export type RealTraxMutationCreateApprovalPackageSubSectionArgs = {
  sectionId: Scalars['Int'];
  type: ApprovalPackageSubSectionTypeEnum;
  checklistId?: Maybe<Scalars['Int']>;
  gcEstimateTypeId?: Maybe<Scalars['Int']>;
};


export type RealTraxMutationCreateApprovalPackageSubSectionTableCellArgs = {
  id: Scalars['Int'];
};


export type RealTraxMutationCreateApprovalPackageTemplateArgs = {
  template: CreateApprovalPackageTemplateInput;
};


export type RealTraxMutationCreateApprovalPackageTemplateFromPackageArgs = {
  id: Scalars['Int'];
};


export type RealTraxMutationCreateApprovalPackageTemplateSectionArgs = {
  section: CreateApprovalPackageSectionInput;
};


export type RealTraxMutationCreateApprovalPackageTemplateSubSectionArgs = {
  sectionId: Scalars['Int'];
  type: ApprovalPackageSubSectionTypeEnum;
  checklistId?: Maybe<Scalars['Int']>;
  gcEstimateTypeId?: Maybe<Scalars['Int']>;
};


export type RealTraxMutationCreateApprovalPackageTemplateSubSectionTableCellArgs = {
  id: Scalars['Int'];
};


export type RealTraxMutationCreateApprovalPackageTypeArgs = {
  approvalPackageType: CreateApprovalPackageTypeInput;
};


export type RealTraxMutationCreateBondArgs = {
  bond: CreateBondInput;
};


export type RealTraxMutationCreateBudgetRevisionArgs = {
  revision: CreateRevisionInput;
};


export type RealTraxMutationCreateBudgetRevisionCostAccountArgs = {
  revisionCostAccount: CreateRevisionAccountInput;
};


export type RealTraxMutationCreateBuildingArgs = {
  building: CreateBuildingInput;
};


export type RealTraxMutationCreateChangeEventArgs = {
  changeEvent: CreateChangeEventInput;
};


export type RealTraxMutationCreateChangeOrderPackageArgs = {
  changeOrderPackage: CreateChangeOrderPackageInput;
};


export type RealTraxMutationCreateChecklistArgs = {
  checklist: CreateChecklistInput;
};


export type RealTraxMutationCreateChecklistCategoryArgs = {
  checklistCategory: CreateChecklistCategoryInput;
};


export type RealTraxMutationCreateChecklistItemArgs = {
  checklistItem: CreateChecklistItemInput;
};


export type RealTraxMutationCreateCommentArgs = {
  comment: CreateCommentInput;
  subjectType: CommentSubjectEnum;
};


export type RealTraxMutationCreateConfigurableEntityArgs = {
  name: Scalars['String'];
  type: ConfigurableEntityEnum;
};


export type RealTraxMutationCreateContactArgs = {
  contact: CreateContactInput;
};


export type RealTraxMutationCreateContractArgs = {
  contract: CreateContractInput;
};


export type RealTraxMutationCreateDealArgs = {
  deal: CreateDealInput;
};


export type RealTraxMutationCreateDealEventArgs = {
  event: CreateDealEventInput;
};


export type RealTraxMutationCreateEventArgs = {
  event: CreateEventInput;
};


export type RealTraxMutationCreateFirmArgs = {
  firm: CreateFirmInput;
};


export type RealTraxMutationCreateFirmRegionArgs = {
  firmRegion: CreateFirmRegionInput;
};


export type RealTraxMutationCreateForecastRuleArgs = {
  forecastRule: CreateForecastRuleInput;
};


export type RealTraxMutationCreateGcEstimateArgs = {
  gcEstimate: CreateGcEstimateInput;
};


export type RealTraxMutationCreateGlAccountArgs = {
  account: CreateGlAccountInput;
};


export type RealTraxMutationCreateGlAccountTreeArgs = {
  accountTree: CreateGlAccountTreeInput;
};


export type RealTraxMutationCreateGroupArgs = {
  group: CreateGroupInput;
};


export type RealTraxMutationCreateInteractionArgs = {
  interaction: CreateInteractionInput;
};


export type RealTraxMutationCreateInterestRateArgs = {
  interestRate: CreateInterestRateInput;
};


export type RealTraxMutationCreateInterestRateTransactionArgs = {
  transaction: CreateInterestRateTransactionInput;
};


export type RealTraxMutationCreateKeyRiskArgs = {
  keyRisk: CreateKeyRiskInput;
  dealId: Scalars['Int'];
};


export type RealTraxMutationCreateLabelArgs = {
  label: CreateLabelInput;
};


export type RealTraxMutationCreateMeritArgs = {
  merit: CreateDealMeritInput;
  dealId: Scalars['Int'];
};


export type RealTraxMutationCreateMetricArgs = {
  metric: CreateMetricInput;
};


export type RealTraxMutationCreatePartnerRoleArgs = {
  partnerRole: CreatePartnerRoleInput;
};


export type RealTraxMutationCreatePipelineArgs = {
  input: CreatePipelineInput;
};


export type RealTraxMutationCreatePipelineAccountOffsetArgs = {
  accountOffsetRule: CreatePipelineAccountOffsetInput;
};


export type RealTraxMutationCreatePipelineContingencyCategoryArgs = {
  accountCategorization: CreatePipelineContingencyCategoryInput;
};


export type RealTraxMutationCreatePipelineDealStageArgs = {
  name: Scalars['String'];
  pipelineId: Scalars['Int'];
};


export type RealTraxMutationCreatePipelineGeneralOffsetArgs = {
  generalOffsetRule: CreatePipelineGeneralOffsetInput;
};


export type RealTraxMutationCreatePortfolioArgs = {
  portfolio: CreatePortfolioInput;
};


export type RealTraxMutationCreatePortfolioViewArgs = {
  portfolioView: CreatePortfolioViewInput;
};


export type RealTraxMutationCreatePotentialChangeOrderArgs = {
  potentialChangeOrder: CreatePotentialChangeOrderInput;
};


export type RealTraxMutationCreatePublishedDealEventArgs = {
  event: CreatePublishedDealEventInput;
};


export type RealTraxMutationCreateScheduleInstanceArgs = {
  instance: CreatePublishedScheduleInstanceInput;
};


export type RealTraxMutationCreateStatusUpdateArgs = {
  statusUpdate: CreateStatusUpdateInput;
};


export type RealTraxMutationCreateStatusUpdateTemplateArgs = {
  statusUpdateTemplate: CreateStatusUpdateTemplateInput;
};


export type RealTraxMutationCreateTeamMemberRoleArgs = {
  teamMemberRole: CreateTeamMemberRoleInput;
};


export type RealTraxMutationCreateTextMetricArgs = {
  textMetric: CreateTextMetricInput;
};


export type RealTraxMutationCreateTransactionArgs = {
  transaction: CreateTransactionInput;
  details: Array<CreateTransactionDetailInput>;
};


export type RealTraxMutationDeleteAnalysisArgs = {
  id: Scalars['Int'];
};


export type RealTraxMutationDeleteAnalysisEventArgs = {
  id: Scalars['Int'];
};


export type RealTraxMutationDeleteAnalysisMetricArgs = {
  id: Scalars['Int'];
};


export type RealTraxMutationDeleteAnalysisTextMetricArgs = {
  id: Scalars['Int'];
};


export type RealTraxMutationDeleteAnalysisTypeArgs = {
  id: Scalars['Int'];
};


export type RealTraxMutationDeleteApprovalPackageArgs = {
  id: Scalars['Int'];
};


export type RealTraxMutationDeleteApprovalPackageContributorArgs = {
  id: Scalars['Int'];
};


export type RealTraxMutationDeleteApprovalPackageExhibitArgs = {
  id: Scalars['Int'];
};


export type RealTraxMutationDeleteApprovalPackageReviewerArgs = {
  id: Scalars['Int'];
};


export type RealTraxMutationDeleteApprovalPackageSectionArgs = {
  id: Scalars['Int'];
};


export type RealTraxMutationDeleteApprovalPackageSubsectionArgs = {
  id: Scalars['Int'];
};


export type RealTraxMutationDeleteApprovalPackageSubSectionTableCellArgs = {
  id: Scalars['Int'];
};


export type RealTraxMutationDeleteApprovalPackageTemplateArgs = {
  id: Scalars['Int'];
};


export type RealTraxMutationDeleteApprovalPackageTemplateSectionArgs = {
  id: Scalars['Int'];
};


export type RealTraxMutationDeleteApprovalPackageTemplateSubsectionArgs = {
  id: Scalars['Int'];
};


export type RealTraxMutationDeleteApprovalPackageTemplateSubSectionTableCellArgs = {
  id: Scalars['Int'];
};


export type RealTraxMutationDeleteApprovalPackageTypeArgs = {
  id: Scalars['Int'];
};


export type RealTraxMutationDeleteBondArgs = {
  id: Scalars['Int'];
};


export type RealTraxMutationDeleteBudgetRevisionArgs = {
  id: Scalars['Int'];
};


export type RealTraxMutationDeleteBudgetRevisionCostAccountArgs = {
  id: Scalars['Int'];
};


export type RealTraxMutationDeleteBudgetRevisionCostAccountsArgs = {
  revisionId: Scalars['Int'];
  ids: Array<Scalars['Int']>;
};


export type RealTraxMutationDeleteBuildingArgs = {
  id: Scalars['Int'];
};


export type RealTraxMutationDeleteChangeEventArgs = {
  id: Scalars['Int'];
};


export type RealTraxMutationDeleteChangeOrderPackageArgs = {
  id: Scalars['Int'];
};


export type RealTraxMutationDeleteChecklistArgs = {
  id: Scalars['Int'];
};


export type RealTraxMutationDeleteChecklistItemArgs = {
  id: Scalars['Int'];
};


export type RealTraxMutationDeleteCommentArgs = {
  id: Scalars['Int'];
  subjectType: CommentSubjectEnum;
};


export type RealTraxMutationDeleteConfigurableEntityArgs = {
  id: Scalars['Int'];
  type: ConfigurableEntityEnum;
};


export type RealTraxMutationDeleteContactArgs = {
  id: Scalars['Int'];
};


export type RealTraxMutationDeleteContactAttachmentArgs = {
  id: Scalars['Int'];
};


export type RealTraxMutationDeleteContractArgs = {
  id: Scalars['Int'];
};


export type RealTraxMutationDeleteDealArgs = {
  id: Scalars['Int'];
};


export type RealTraxMutationDeleteDealArtifactArgs = {
  id: Scalars['Int'];
};


export type RealTraxMutationDeleteEventArgs = {
  id: Scalars['Int'];
};


export type RealTraxMutationDeleteFirmArgs = {
  id: Scalars['Int'];
};


export type RealTraxMutationDeleteFirmAttachmentArgs = {
  id: Scalars['Int'];
};


export type RealTraxMutationDeleteFirmRegionArgs = {
  fkRegion: Scalars['Int'];
  fkFirm: Scalars['Int'];
};


export type RealTraxMutationDeleteForecastRuleArgs = {
  id: Scalars['Int'];
};


export type RealTraxMutationDeleteGcEstimateArgs = {
  id: Scalars['Int'];
};


export type RealTraxMutationDeleteGlAccountArgs = {
  id: Scalars['Int'];
};


export type RealTraxMutationDeleteGlAccountTreeArgs = {
  id: Scalars['Int'];
};


export type RealTraxMutationDeleteGroupArgs = {
  id: Scalars['Int'];
};


export type RealTraxMutationDeleteInteractionArgs = {
  id: Scalars['Int'];
};


export type RealTraxMutationDeleteInterestRateArgs = {
  id: Scalars['Int'];
};


export type RealTraxMutationDeleteKeyRiskArgs = {
  id: Scalars['Int'];
};


export type RealTraxMutationDeleteLabelArgs = {
  id: Scalars['Int'];
};


export type RealTraxMutationDeleteMeritArgs = {
  id: Scalars['Int'];
};


export type RealTraxMutationDeleteMetricArgs = {
  id: Scalars['Int'];
};


export type RealTraxMutationDeletePartnerRoleArgs = {
  id: Scalars['Int'];
};


export type RealTraxMutationDeletePipelineArgs = {
  id: Scalars['Int'];
};


export type RealTraxMutationDeletePipelineAccountOffsetArgs = {
  id: Scalars['Int'];
};


export type RealTraxMutationDeletePipelineContingencyCategoryArgs = {
  id: Scalars['Int'];
};


export type RealTraxMutationDeletePipelineDealStageArgs = {
  id: Scalars['Int'];
  pipelineId: Scalars['Int'];
};


export type RealTraxMutationDeletePipelineGeneralOffsetArgs = {
  id: Scalars['Int'];
};


export type RealTraxMutationDeletePortfolioArgs = {
  id: Scalars['Int'];
};


export type RealTraxMutationDeletePortfolioViewArgs = {
  id: Scalars['Int'];
};


export type RealTraxMutationDeletePotentialChangeOrderArgs = {
  id: Scalars['Int'];
};


export type RealTraxMutationDeletePublishedCapitalForecastArgs = {
  id: Scalars['Int'];
};


export type RealTraxMutationDeleteScheduleInstanceArgs = {
  id: Scalars['Int'];
};


export type RealTraxMutationDeleteStatusUpdateArgs = {
  id: Scalars['Int'];
};


export type RealTraxMutationDeleteStatusUpdateTemplateArgs = {
  id: Scalars['Int'];
};


export type RealTraxMutationDeleteTeamMemberRoleArgs = {
  id: Scalars['Int'];
};


export type RealTraxMutationDeleteTextMetricArgs = {
  id: Scalars['Int'];
};


export type RealTraxMutationDeleteTransactionArgs = {
  id: Scalars['Int'];
};


export type RealTraxMutationDisableForecastRulesArgs = {
  fkDeal: Scalars['Int'];
  forecastRules?: Maybe<Array<Scalars['Int']>>;
};


export type RealTraxMutationDuplicateAnalysisArgs = {
  id: Scalars['Int'];
};


export type RealTraxMutationEnableForecastRulesArgs = {
  fkDeal: Scalars['Int'];
  forecastRules?: Maybe<Array<Scalars['Int']>>;
};


export type RealTraxMutationForecastCapitalizationAccountsArgs = {
  fkDeal: Scalars['Int'];
};


export type RealTraxMutationForecastInterestAccountArgs = {
  fkDeal: Scalars['Int'];
};


export type RealTraxMutationGenerateTransactionDetailPreviewArgs = {
  transaction: CreateTransactionInput;
};


export type RealTraxMutationPublishApprovalPackageArgs = {
  id: Scalars['Int'];
};


export type RealTraxMutationPublishCapitalForecastArgs = {
  capitalForecast: CreatePublishedCapitalForecastInput;
};


export type RealTraxMutationPublishScheduleInstanceArgs = {
  instance: CreatePublishedScheduleInstanceInput;
};


export type RealTraxMutationRefreshApprovalPackageDealMeritsArgs = {
  id: Scalars['Int'];
  approvalPackageId: Scalars['Int'];
};


export type RealTraxMutationRefreshApprovalPackageKeyRisksArgs = {
  id: Scalars['Int'];
  approvalPackageId: Scalars['Int'];
};


export type RealTraxMutationRemoveAccountsFromAccountTreeArgs = {
  id: Scalars['Int'];
  accounts: Array<Scalars['Int']>;
};


export type RealTraxMutationRemoveChecklistArgs = {
  dealChecklist: DealChecklistInput;
};


export type RealTraxMutationRemoveChecklistsFromPipelineArgs = {
  id: Scalars['Int'];
  checklistIds: Array<Scalars['Int']>;
};


export type RealTraxMutationRemoveDealPartnerArgs = {
  partner: RemoveDealPartnerInput;
};


export type RealTraxMutationRemoveEventsFromDealArgs = {
  dealId: Scalars['Int'];
  eventIds: Array<Scalars['Int']>;
};


export type RealTraxMutationRemoveEventsFromPipelineArgs = {
  id: Scalars['Int'];
  eventIds: Array<Scalars['Int']>;
};


export type RealTraxMutationRemoveForecastRulesFromPipelineArgs = {
  id: Scalars['Int'];
  forecastRuleIds: Array<Scalars['Int']>;
};


export type RealTraxMutationRemoveMembersFromDealArgs = {
  assignment: RemoveDealMemberInput;
};


export type RealTraxMutationRemoveMembersFromOrganizationArgs = {
  assignment: RemoveOrganizationMemberInput;
};


export type RealTraxMutationRemoveMembersFromPipelineArgs = {
  assignment: RemovePipelineMemberInput;
};


export type RealTraxMutationRemoveMembersFromPortfolioArgs = {
  assignment: RemovePortfolioMemberInput;
};


export type RealTraxMutationRemoveModulesFromPipelineArgs = {
  id: Scalars['Int'];
  moduleIds: Array<Scalars['Int']>;
};


export type RealTraxMutationRemovePinnedEventsFromPipelineArgs = {
  id: Scalars['Int'];
  eventIds: Array<Scalars['Int']>;
};


export type RealTraxMutationRemovePinnedMetricsFromPipelineArgs = {
  id: Scalars['Int'];
  metricIds: Array<Scalars['Int']>;
};


export type RealTraxMutationRemovePinnedPartnerRolesFromPipelineArgs = {
  id: Scalars['Int'];
  roleIds: Array<Scalars['Int']>;
};


export type RealTraxMutationRemovePinnedTeamMemberRolesFromPipelineArgs = {
  id: Scalars['Int'];
  roleIds: Array<Scalars['Int']>;
};


export type RealTraxMutationRemovePortfolioDealsArgs = {
  portfolioDeals: UpdatePortfolioDealsInput;
};


export type RealTraxMutationRemovePortfolioViewColumnsArgs = {
  id: Scalars['Int'];
  columns?: Maybe<Array<AddPortfolioViewColumnInput>>;
};


export type RealTraxMutationRemoveTeamMemberArgs = {
  teamMember: RemoveTeamMemberInput;
};


export type RealTraxMutationRemoveUsersFromGroupArgs = {
  groupId: Scalars['Int'];
  userIds: Array<Scalars['Int']>;
};


export type RealTraxMutationReorderConfigurableEntityArgs = {
  id: Scalars['Int'];
  beforeId?: Maybe<Scalars['Int']>;
  afterId?: Maybe<Scalars['Int']>;
  type: ConfigurableEntityEnum;
};


export type RealTraxMutationReorderPipelineDealStageArgs = {
  id: Scalars['Int'];
  beforeId?: Maybe<Scalars['Int']>;
  afterId?: Maybe<Scalars['Int']>;
  pipelineId: Scalars['Int'];
};


export type RealTraxMutationReorderPipelinePinnedEventArgs = {
  pipelineId: Scalars['Int'];
  id: Scalars['Int'];
  beforeId?: Maybe<Scalars['Int']>;
  afterId?: Maybe<Scalars['Int']>;
};


export type RealTraxMutationReorderPipelinePinnedMetricArgs = {
  pipelineId: Scalars['Int'];
  id: Scalars['Int'];
  beforeId?: Maybe<Scalars['Int']>;
  afterId?: Maybe<Scalars['Int']>;
};


export type RealTraxMutationReorderPipelinePinnedPartnerRoleArgs = {
  pipelineId: Scalars['Int'];
  id: Scalars['Int'];
  beforeId?: Maybe<Scalars['Int']>;
  afterId?: Maybe<Scalars['Int']>;
};


export type RealTraxMutationReorderPipelinePinnedTeamMemberRoleArgs = {
  pipelineId: Scalars['Int'];
  id: Scalars['Int'];
  beforeId?: Maybe<Scalars['Int']>;
  afterId?: Maybe<Scalars['Int']>;
};


export type RealTraxMutationRunDefaultForecastRulesArgs = {
  fkDeal: Scalars['Int'];
  accounts?: Maybe<Array<Scalars['Int']>>;
};


export type RealTraxMutationSetApprovalPackageSubSectionTableColumnsArgs = {
  id: Scalars['Int'];
  columns: Scalars['Int'];
};


export type RealTraxMutationSetApprovalPackageTemplateSubSectionTableColumnsArgs = {
  id: Scalars['Int'];
  columns: Scalars['Int'];
};


export type RealTraxMutationSetEntriesForApprovalPackageSubSectionTableCellArgs = {
  id: Scalars['Int'];
  entries: Array<IcSubSectionTableCellEntryInput>;
};


export type RealTraxMutationSetEntriesForApprovalPackageTemplateSubSectionTableCellArgs = {
  id: Scalars['Int'];
  entries: Array<IcSubSectionTableCellEntryInput>;
};


export type RealTraxMutationUnarchiveDealArgs = {
  id: Scalars['Int'];
};


export type RealTraxMutationUnassignContactsArgs = {
  assignment: ContactAssignmentInput;
};


export type RealTraxMutationUpdateAnalysisArgs = {
  analysis: UpdateAnalysisInput;
};


export type RealTraxMutationUpdateAnalysisEventArgs = {
  event: UpdateAnalysisEventInput;
};


export type RealTraxMutationUpdateAnalysisMetricArgs = {
  metric: UpdateAnalysisMetricInput;
};


export type RealTraxMutationUpdateAnalysisTextMetricArgs = {
  textMetric: UpdateAnalysisTextMetricInput;
};


export type RealTraxMutationUpdateAnalysisTypeArgs = {
  analysisType: UpdateAnalysisTypeInput;
};


export type RealTraxMutationUpdateApprovalPackageArgs = {
  approvalPackage: UpdateApprovalPackageInput;
};


export type RealTraxMutationUpdateApprovalPackageExhibitArgs = {
  id: Scalars['Int'];
  displayName?: Maybe<Scalars['String']>;
  fileName?: Maybe<Scalars['String']>;
};


export type RealTraxMutationUpdateApprovalPackageReviewerArgs = {
  reviewer: UpdateApprovalPackageReviewerInput;
};


export type RealTraxMutationUpdateApprovalPackageSectionArgs = {
  section: UpdateApprovalPackageSectionInput;
};


export type RealTraxMutationUpdateApprovalPackageSubsectionArgs = {
  subSection: UpdateApprovalPackageSubSectionInput;
};


export type RealTraxMutationUpdateApprovalPackageSubsectionChecklistArgs = {
  subSectionChecklist: UpdateApprovalPackageSubSectionChecklistInput;
};


export type RealTraxMutationUpdateApprovalPackageSubsectionTextArgs = {
  subSectionText: UpdateApprovalPackageSubSectionTextInput;
};


export type RealTraxMutationUpdateApprovalPackageTemplateArgs = {
  template: UpdateApprovalPackageTemplateInput;
};


export type RealTraxMutationUpdateApprovalPackageTemplateSectionArgs = {
  section: UpdateApprovalPackageSectionInput;
};


export type RealTraxMutationUpdateApprovalPackageTemplateSubsectionArgs = {
  subSection: UpdateApprovalPackageSubSectionInput;
};


export type RealTraxMutationUpdateApprovalPackageTemplateSubsectionChecklistArgs = {
  subSectionChecklist: UpdateApprovalPackageSubSectionChecklistInput;
};


export type RealTraxMutationUpdateApprovalPackageTemplateSubsectionTextArgs = {
  subSectionText: UpdateApprovalPackageSubSectionTextInput;
};


export type RealTraxMutationUpdateApprovalPackageTypeArgs = {
  approvalPackageType: UpdateApprovalPackageTypeInput;
};


export type RealTraxMutationUpdateBondArgs = {
  bond: UpdateBondInput;
};


export type RealTraxMutationUpdateBudgetRevisionArgs = {
  revision: UpdateRevisionInput;
};


export type RealTraxMutationUpdateBudgetRevisionCostAccountArgs = {
  revisionCostAccount: UpdateRevisionAccountInput;
};


export type RealTraxMutationUpdateBuildingArgs = {
  building: UpdateBuildingInput;
};


export type RealTraxMutationUpdateChangeEventArgs = {
  changeEvent: UpdateChangeEventInput;
};


export type RealTraxMutationUpdateChangeOrderPackageArgs = {
  changeOrderPackage: UpdateChangeOrderPackageInput;
};


export type RealTraxMutationUpdateChecklistArgs = {
  checklist: UpdateChecklistInput;
};


export type RealTraxMutationUpdateChecklistItemArgs = {
  checklistItem: UpdateChecklistItemInput;
};


export type RealTraxMutationUpdateCommentArgs = {
  comment: UpdateCommentInput;
};


export type RealTraxMutationUpdateConfigurableEntityArgs = {
  id: Scalars['Int'];
  name: Scalars['String'];
  isDisabled: Scalars['Boolean'];
  type: ConfigurableEntityEnum;
};


export type RealTraxMutationUpdateContactArgs = {
  contact: UpdateContactInput;
};


export type RealTraxMutationUpdateContractArgs = {
  contract: UpdateContractInput;
};


export type RealTraxMutationUpdateDealArgs = {
  deal: UpdateDealInput;
};


export type RealTraxMutationUpdateDealChecklistItemArgs = {
  checklistItem: UpdateDealChecklistItemInput;
};


export type RealTraxMutationUpdateDealEventArgs = {
  event: UpdateDealEventInput;
};


export type RealTraxMutationUpdateDealMemberRoleArgs = {
  dealMemberRole: UpdateDealMemberRoleInput;
};


export type RealTraxMutationUpdateDealPartnerArgs = {
  partner: UpdateDealPartnerInput;
};


export type RealTraxMutationUpdateDealPartnersArgs = {
  roleId: Scalars['Int'];
  dealId: Scalars['Int'];
  firmIds: Array<Scalars['Int']>;
};


export type RealTraxMutationUpdateDynamicPortfolioFiltersArgs = {
  id: Scalars['Int'];
  filterGroups: Array<CreatePortfolioFilterGroupInput>;
};


export type RealTraxMutationUpdateEventArgs = {
  event: UpdateEventInput;
};


export type RealTraxMutationUpdateFirmArgs = {
  firm: UpdateFirmInput;
};


export type RealTraxMutationUpdateFirmRegionArgs = {
  firmRegion: UpdateFirmRegionInput;
};


export type RealTraxMutationUpdateForecastRuleArgs = {
  forecastRule: UpdateForecastRuleInput;
};


export type RealTraxMutationUpdateGcEstimateArgs = {
  gcEstimate: UpdateGcEstimateInput;
};


export type RealTraxMutationUpdateGlAccountArgs = {
  account: UpdateGlAccountInput;
};


export type RealTraxMutationUpdateGlAccountTreeArgs = {
  accountTree: UpdateGlAccountTreeInput;
};


export type RealTraxMutationUpdateGroupArgs = {
  group: UpdateGroupInput;
};


export type RealTraxMutationUpdateInteractionArgs = {
  interaction: UpdateInteractionInput;
};


export type RealTraxMutationUpdateInterestRateArgs = {
  interestRate: UpdateInterestRateInput;
};


export type RealTraxMutationUpdateKeyRiskArgs = {
  keyRisk: UpdateKeyRiskInput;
};


export type RealTraxMutationUpdateLabelArgs = {
  label: UpdateLabelInput;
};


export type RealTraxMutationUpdateMeritArgs = {
  merit: UpdateDealMeritInput;
};


export type RealTraxMutationUpdateMetricArgs = {
  metric: UpdateMetricInput;
};


export type RealTraxMutationUpdateOrganizationMemberRoleArgs = {
  organizationMemberRole: UpdateOrganizationMemberRoleInput;
};


export type RealTraxMutationUpdatePartnerRoleArgs = {
  partnerRole: UpdatePartnerRoleInput;
};


export type RealTraxMutationUpdatePipelineArgs = {
  input: UpdatePipelineInput;
};


export type RealTraxMutationUpdatePipelineAccountOffsetArgs = {
  accountOffsetRule: UpdatePipelineAccountOffsetInput;
};


export type RealTraxMutationUpdatePipelineContingencyCategoryArgs = {
  accountCategorization: UpdatePipelineContingencyCategoryInput;
};


export type RealTraxMutationUpdatePipelineDealStageArgs = {
  id: Scalars['Int'];
  name: Scalars['String'];
  isDisabled: Scalars['Boolean'];
  pipelineId: Scalars['Int'];
};


export type RealTraxMutationUpdatePipelineGeneralOffsetArgs = {
  generalOffsetRule: UpdatePipelineGeneralOffsetInput;
};


export type RealTraxMutationUpdatePipelineMemberRoleArgs = {
  pipelineMemberRole: UpdatePipelineMemberRoleInput;
};


export type RealTraxMutationUpdatePortfolioArgs = {
  portfolio: UpdatePortfolioInput;
};


export type RealTraxMutationUpdatePortfolioMemberRoleArgs = {
  portfolioMemberRole: UpdatePortfolioMemberRoleInput;
};


export type RealTraxMutationUpdatePortfolioViewArgs = {
  portfolioView: UpdatePortfolioViewInput;
};


export type RealTraxMutationUpdatePortfolioViewColumnArgs = {
  column: UpdatePortfolioViewColumnInput;
};


export type RealTraxMutationUpdatePotentialChangeOrderArgs = {
  potentialChangeOrder: UpdatePotentialChangeOrderInput;
};


export type RealTraxMutationUpdatePublishedDealEventArgs = {
  event: UpdatePublishedDealEventInput;
};


export type RealTraxMutationUpdateScheduleInstanceArgs = {
  instance: UpdatePublishedScheduleInstanceInput;
};


export type RealTraxMutationUpdateStatusUpdateArgs = {
  statusUpdate: UpdateStatusUpdateInput;
};


export type RealTraxMutationUpdateStatusUpdateTemplateArgs = {
  statusUpdateTemplate: UpdateStatusUpdateTemplateInput;
};


export type RealTraxMutationUpdateTeamMemberArgs = {
  teamMember: UpdateTeamMemberInput;
};


export type RealTraxMutationUpdateTeamMemberRoleArgs = {
  teamMemberRole: UpdateTeamMemberRoleInput;
};


export type RealTraxMutationUpdateTeamMembersArgs = {
  roleId: Scalars['Int'];
  dealId: Scalars['Int'];
  userIds: Array<Scalars['Int']>;
};


export type RealTraxMutationUpdateTextMetricArgs = {
  textMetric: UpdateTextMetricInput;
};


export type RealTraxMutationUpdateTransactionArgs = {
  transaction: UpdateTransactionInput;
  details: Array<CreateTransactionDetailInput>;
};


export type RealTraxMutationUpdateTransactionsArgs = {
  input: Array<UpdateTransactionWithDetailsInput>;
  fkDeal: Scalars['Int'];
};

export type RealTraxQuery = {
  __typename?: 'RealTraxQuery';
  /** A specific account. */
  account?: Maybe<GlAccount>;
  /** A list of account categories. */
  accountCategories?: Maybe<Array<GlAccountCategory>>;
  /** A list of accounts for this organization. */
  accounts?: Maybe<Array<GlAccount>>;
  /** A specific account tree. */
  accountTree?: Maybe<GlAccountTree>;
  /** A list of account trees for this organization. */
  accountTrees?: Maybe<Array<GlAccountTree>>;
  /** A list of account types. */
  accountTypes?: Maybe<Array<GlAccountType>>;
  /** A list of analysis scenarios. */
  analysisScenarios?: Maybe<Array<ScenarioType>>;
  /** A list of analysis types. */
  analysisTypes?: Maybe<Array<AnalysisType>>;
  /** Data for the analytics summary. */
  analyticsSummary: AnalyticsSummary;
  /** A specific approval package. */
  approvalPackage?: Maybe<ApprovalPackage>;
  /** A list of statuses for approval package reviewers. */
  approvalPackageReviewerStatuses?: Maybe<Array<PackageReviewerStatus>>;
  /** A list of approval packages for an organization. */
  approvalPackages?: Maybe<Array<ApprovalPackage>>;
  /** A list of statuses for approval packages. */
  approvalPackageStatuses?: Maybe<Array<PackageStatus>>;
  /** The specific approval package template. */
  approvalPackageTemplate?: Maybe<TemplateApprovalPackage>;
  /** A list of approval packages templates for an organization. */
  approvalPackageTemplates?: Maybe<Array<TemplateApprovalPackage>>;
  /** A list of types for approval packages. */
  approvalPackageType?: Maybe<ApprovalPackageType>;
  /** A list of types for approval packages. */
  approvalPackageTypes?: Maybe<Array<ApprovalPackageType>>;
  /** A dashboard displaying details on approval packages for the current user. */
  approvalsDashboard: ApprovalsDashboard;
  /** A list of interaction artifact types used for uploading deal artifacts. */
  artifactTypes?: Maybe<Array<Artifact>>;
  /** A list of asset classes */
  assetClasses?: Maybe<Array<AssetClass>>;
  /** A list of budget offset rule filters. */
  budgetOffsetRuleFilters?: Maybe<Array<CustomFilters>>;
  /** A list of building types. */
  buildingTypes?: Maybe<Array<BuildingType>>;
  /** A list of fields that can be added to an approval package table cell. */
  cellEntryOptions?: Maybe<Array<IcSubSectionTableCellEntryOption>>;
  /** A list of reasons for change events. */
  changeEventReasons?: Maybe<Array<ChangeEventReason>>;
  /** A list of types for change events. */
  changeEventTypes?: Maybe<Array<ChangeEventType>>;
  /** A list of statuses for change order packages. */
  changeOrderStatuses?: Maybe<Array<ChangeOrderStatus>>;
  /** A specific checklist */
  checklist?: Maybe<Checklist>;
  /** A list of categories for a given checklist */
  checklistCategories?: Maybe<Array<ChecklistCategory>>;
  /** A list of statuses that can checklist items can be assigned to. */
  checklistItemStatuses?: Maybe<Array<ChecklistItemStatus>>;
  /** A list of checklists */
  checklists?: Maybe<Array<Checklist>>;
  /** A list of construction types */
  constructionTypes?: Maybe<Array<ConstructionType>>;
  /** A contact and its associated information. */
  contact?: Maybe<Contact>;
  /** A list of contacts belonging to a given organization. */
  contacts?: Maybe<Array<Contact>>;
  /** A list of contingency categories. */
  contingencyCategories?: Maybe<Array<ContingencyCategory>>;
  /** Data for the core market cap chart. */
  coreMarketCapChart: Array<Maybe<DecimalChartDataItem>>;
  /** A list of statuses used as part of the CRM features. */
  crmStatuses?: Maybe<Array<CrmStatus>>;
  /** The current user. */
  currentUser: User;
  /** A deal and its associated information. */
  deal?: Maybe<Deal>;
  /** A list of deal attributes. */
  dealAttributes?: Maybe<Array<DealAttribute>>;
  /** Data for the deal type chart. */
  dealQuarterEventsChart: Array<Maybe<ChartDataItem>>;
  /** A list of deals belonging to this organization that the current user has access to. */
  deals?: Maybe<Array<Deal>>;
  /** A list of deals belonging to this organization that the current user has access to. */
  dealsPaginated?: Maybe<DealsResponse>;
  /** A list of deal stages. */
  dealStages?: Maybe<Array<DealStage>>;
  /** A list of statuses that can deal status update can be assigned to. */
  dealStatuses?: Maybe<Array<DealStatus>>;
  /** Data for the deal type chart. */
  dealTypeChart: Array<Maybe<ChartDataItem>>;
  /** A list of deal types */
  dealTypes?: Maybe<Array<DealType>>;
  /** A list of estimate types. */
  estimateTypes?: Maybe<Array<EstimateType>>;
  /** A specific event. */
  event?: Maybe<Event>;
  /** A list of events that can be added to a schedule. */
  events?: Maybe<Array<Event>>;
  /** A specific firm. */
  firm?: Maybe<Firm>;
  /** A list of firms associated with this organization. */
  firms?: Maybe<Array<Firm>>;
  /** A list of statuses used as part of the CRM features. */
  firmTypes?: Maybe<Array<FirmType>>;
  /** A list of folders belonging to a given organization. */
  folders?: Maybe<Array<Folder>>;
  /** The status of forecastInterestAccount for a deal id. */
  forecastInterestAccountStatus?: Maybe<ForecastInterestAccountStatusType>;
  /** A specific budget forecasting rule. */
  forecastRule?: Maybe<ForecastRule>;
  /** A list of budget forecasting rules. */
  forecastRules?: Maybe<Array<ForecastRule>>;
  /** Data for the fund pie chart. */
  fundChart: Array<Maybe<ChartDataItem>>;
  /** A list of fund entities. */
  fundEntities?: Maybe<Array<FundEntity>>;
  /** A list of funds */
  funds?: Maybe<Array<Fund>>;
  /** A list of GC Estimate types. */
  gcEstimateTypes?: Maybe<Array<EstimateType>>;
  /** A list of groups within this organization. */
  group?: Maybe<Group>;
  /** A list of groups within this organization. */
  groups?: Maybe<Array<Group>>;
  /** A list of hoa types */
  hoaTypes?: Maybe<Array<Hoa>>;
  /** A specific interaction w/ a firm or contact.. */
  interaction?: Maybe<Interaction>;
  /** A list of interaction types used as part of the CRM features. */
  interactionTypes?: Maybe<Array<InteractionType>>;
  /** A list of interest rates. */
  interestRates?: Maybe<Array<InterestRate>>;
  /** A list of investment types. */
  investmentTypes?: Maybe<Array<InvestmentType>>;
  /** A list of labels that can be used in the app. */
  labels?: Maybe<Array<Label>>;
  /** The status of forecastInterestAccount for a deal id. */
  latestPipelineAnalysisMetricDistribution?: Maybe<Array<Scalars['Decimal']>>;
  /** A list of markets */
  markets?: Maybe<Array<Market>>;
  /** A list of market segments */
  marketSegments?: Maybe<Array<MarketSegment>>;
  /** A list of market types */
  marketTypes?: Maybe<Array<MarketType>>;
  /** Retrieve market view data */
  marketView?: Maybe<MarketView>;
  /** A list of analysis metrics. */
  metrics?: Maybe<Array<Metric>>;
  /** A list of organization modules. */
  modules?: Maybe<Array<Module>>;
  /** A list of msas */
  msas?: Maybe<Array<Msa>>;
  /** The users currently accessed organization. */
  organization: Organization;
  /** A list of groups that are assigned an organization level permission. */
  organizationGroups?: Maybe<Array<OrganizationRoleGroup>>;
  /** A list of users that are assigned an organization level permission. */
  organizationUsers?: Maybe<Array<OrganizationRoleUser>>;
  /** A list of roles that partners of a deal can fill. */
  partnerRoles?: Maybe<Array<PartnerRole>>;
  /** A list of permission roles within this organization. */
  permissionRoles?: Maybe<Array<Role>>;
  /** A specific pipeline. */
  pipeline?: Maybe<Pipeline>;
  /** A list of pipelines. */
  pipelines?: Maybe<Array<Pipeline>>;
  /** A portfolio and its associated information. */
  portfolio?: Maybe<Portfolio>;
  /** A list of portfolios. */
  portfolios?: Maybe<Array<PortfolioInfo>>;
  /** A list of types for potential change orders. */
  potentialChangeOrderTypes?: Maybe<Array<PotentialChangeOrderType>>;
  /** A list of priority levels */
  priorityLevels?: Maybe<Array<DealPriorityLevel>>;
  /** A list of product types */
  productTypes?: Maybe<Array<ProductType>>;
  /** Data for the project lead chart. */
  projectLeadChart: Array<Maybe<ChartDataItem>>;
  /** The current published version of a specific approval package. */
  publishedApprovalPackage?: Maybe<PublishedApprovalPackage>;
  /** A list of regions */
  regions?: Maybe<Array<Region>>;
  /** A list of report types that are used with account trees. */
  reportTypes?: Maybe<Array<ReportType>>;
  /** Data for the deal stage chart. */
  stageChart: Array<Maybe<ChartDataItem>>;
  /** Data for the state pie chart. */
  stateChart: Array<Maybe<ChartDataItem>>;
  /** A list of states */
  states?: Maybe<Array<State>>;
  /** Data for the static irr chart. */
  staticIrrChart: Array<Maybe<Scalars['Decimal']>>;
  /** A specific status updates template for this organization. */
  statusUpdateTemplate?: Maybe<DealStatusUpdateTemplate>;
  /** A list of status updates templates for this organization. */
  statusUpdateTemplates?: Maybe<Array<DealStatusUpdateTemplate>>;
  /** A list of structures */
  structures?: Maybe<Array<Structure>>;
  /** A list of submarkets */
  submarkets?: Maybe<Array<SubmarketType>>;
  /** A list of roles that team members of a deal can fill. */
  teamMemberRoles?: Maybe<Array<TeamMemberRole>>;
  /** A list of analysis text metrics. */
  textMetrics?: Maybe<Array<TextMetric>>;
  /** A list of users belonging to this organization. */
  users?: Maybe<Array<User>>;
  /** A list of vendors added to an organization. */
  vendors?: Maybe<Array<Vendor>>;
  /** A list of columns that can be added to a view. */
  viewColumnOptions?: Maybe<Array<PortfolioViewColumnOption>>;
};


export type RealTraxQueryAccountArgs = {
  id: Scalars['Int'];
};


export type RealTraxQueryAccountTreeArgs = {
  id: Scalars['Int'];
};


export type RealTraxQueryAnalyticsSummaryArgs = {
  id: Scalars['Int'];
  birthDate: Scalars['Date'];
  dealTypeIds: Array<Maybe<Scalars['Int']>>;
};


export type RealTraxQueryApprovalPackageArgs = {
  id: Scalars['Int'];
};


export type RealTraxQueryApprovalPackageTemplateArgs = {
  id: Scalars['Int'];
};


export type RealTraxQueryApprovalPackageTypeArgs = {
  id: Scalars['Int'];
};


export type RealTraxQueryChecklistArgs = {
  id: Scalars['Int'];
};


export type RealTraxQueryChecklistCategoriesArgs = {
  id: Scalars['Int'];
};


export type RealTraxQueryContactArgs = {
  id: Scalars['Int'];
};


export type RealTraxQueryCoreMarketCapChartArgs = {
  id: Scalars['Int'];
  birthDate: Scalars['Date'];
  dealTypeIds: Array<Maybe<Scalars['Int']>>;
};


export type RealTraxQueryDealArgs = {
  id: Scalars['Int'];
};


export type RealTraxQueryDealQuarterEventsChartArgs = {
  pipelineId: Scalars['Int'];
  fkEvent: Scalars['Int'];
  yearsBack: Scalars['Int'];
  dealTypeIds: Array<Maybe<Scalars['Int']>>;
};


export type RealTraxQueryDealsArgs = {
  showArchivedDeals?: Maybe<Scalars['Boolean']>;
};


export type RealTraxQueryDealsPaginatedArgs = {
  showArchivedDeals?: Maybe<Scalars['Boolean']>;
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
};


export type RealTraxQueryDealTypeChartArgs = {
  id: Scalars['Int'];
  birthDate: Scalars['Date'];
  dealTypeIds: Array<Maybe<Scalars['Int']>>;
};


export type RealTraxQueryEventArgs = {
  id: Scalars['Int'];
};


export type RealTraxQueryFirmArgs = {
  id: Scalars['Int'];
};


export type RealTraxQueryFoldersArgs = {
  ids?: Maybe<Array<Scalars['Int']>>;
};


export type RealTraxQueryForecastInterestAccountStatusArgs = {
  id: Scalars['Int'];
};


export type RealTraxQueryForecastRuleArgs = {
  id: Scalars['Int'];
};


export type RealTraxQueryFundChartArgs = {
  id: Scalars['Int'];
  birthDate: Scalars['Date'];
  dealTypeIds: Array<Maybe<Scalars['Int']>>;
};


export type RealTraxQueryGroupArgs = {
  id: Scalars['Int'];
};


export type RealTraxQueryInteractionArgs = {
  id: Scalars['Int'];
};


export type RealTraxQueryLatestPipelineAnalysisMetricDistributionArgs = {
  analysisMetricId: Scalars['Int'];
};


export type RealTraxQueryPipelineArgs = {
  id: Scalars['Int'];
};


export type RealTraxQueryPortfolioArgs = {
  id: Scalars['Int'];
};


export type RealTraxQueryProjectLeadChartArgs = {
  id: Scalars['Int'];
  birthDate: Scalars['Date'];
  dealTypeIds: Array<Maybe<Scalars['Int']>>;
};


export type RealTraxQueryPublishedApprovalPackageArgs = {
  id: Scalars['Int'];
};


export type RealTraxQueryStageChartArgs = {
  id: Scalars['Int'];
  birthDate: Scalars['Date'];
  dealTypeIds: Array<Maybe<Scalars['Int']>>;
};


export type RealTraxQueryStateChartArgs = {
  id: Scalars['Int'];
  birthDate: Scalars['Date'];
  dealTypeIds: Array<Maybe<Scalars['Int']>>;
};


export type RealTraxQueryStaticIrrChartArgs = {
  id: Scalars['Int'];
  birthDate: Scalars['Date'];
  dealTypeIds: Array<Maybe<Scalars['Int']>>;
};


export type RealTraxQueryStatusUpdateTemplateArgs = {
  id: Scalars['Int'];
};

/** Region */
export type Region = {
  __typename?: 'Region';
  /** When the object was created. */
  createdAt: Scalars['DateTimeOffset'];
  createdBy: Actor;
  /** When the object was deleted. */
  deletedAt?: Maybe<Scalars['DateTimeOffset']>;
  deletedBy?: Maybe<Actor>;
  id: Scalars['Int'];
  name: Scalars['String'];
  /** When the object was last updated. */
  updatedAt: Scalars['DateTimeOffset'];
  updatedBy: Actor;
};

export type RemoveChecklistResult = DealChecklist | DealChecklistDoesNotExistType;

/** An input object used for removing members from a deal. */
export type RemoveDealMemberInput = {
  /** A list of identifiers for users that should be removed from this deal. */
  userIds: Array<Scalars['Int']>;
  /** A list of identifiers for groups that should be removed from this deal. */
  groupIds: Array<Scalars['Int']>;
  /** The id of the deal to remove the users/groups from. */
  fkDeal: Scalars['Int'];
};

/** An input object used for deleting a partner from a deal. */
export type RemoveDealPartnerInput = {
  /** The id representing the deal that the partner belongs too. */
  fkDeal: Scalars['Int'];
  /** The id of the firm on the deal. */
  fkFirmPartner: Scalars['Int'];
};

/** An input object used for removing members from an organization role. */
export type RemoveOrganizationMemberInput = {
  /** A list of identifiers for users that should be removed from an organization role. */
  userIds: Array<Scalars['Int']>;
  /** A list of identifiers for groups that should be removed from the organization role. */
  groupIds: Array<Scalars['Int']>;
};

/** An input object used for removing members from a pipeline. */
export type RemovePipelineMemberInput = {
  /** A list of identifiers for users that should be removed from this pipeline. */
  userIds: Array<Scalars['Int']>;
  /** A list of identifiers for groups that should be removed from this pipeline. */
  groupIds: Array<Scalars['Int']>;
  /** The id of the pipeline to remove the users/groups from. */
  fkPipeline: Scalars['Int'];
};

/** An input object used for removing members from a portfolio. */
export type RemovePortfolioMemberInput = {
  /** A list of identifiers for users that should be removed from this portfolio. */
  userIds: Array<Scalars['Int']>;
  /** A list of identifiers for groups that should be removed from this portfolio. */
  groupIds: Array<Scalars['Int']>;
  /** The id of the deal to remove the users/groups from. */
  fkPortfolio: Scalars['Int'];
};

/** An input object used for deleting a team member from a deal. */
export type RemoveTeamMemberInput = {
  /** The id representing the deal that the team member belongs too. */
  fkDeal: Scalars['Int'];
  /** The id of the person on the deal. */
  fkUser: Scalars['Int'];
};

/** ReportType */
export type ReportType = {
  __typename?: 'ReportType';
  /** When the object was created. */
  createdAt: Scalars['DateTimeOffset'];
  createdBy: Actor;
  /** When the object was deleted. */
  deletedAt?: Maybe<Scalars['DateTimeOffset']>;
  deletedBy?: Maybe<Actor>;
  id: Scalars['Int'];
  name: Scalars['String'];
  /** When the object was last updated. */
  updatedAt: Scalars['DateTimeOffset'];
  updatedBy: Actor;
};

/** Revision */
export type Revision = {
  __typename?: 'Revision';
  applicableDate?: Maybe<Scalars['Date']>;
  approvedDate?: Maybe<Scalars['Date']>;
  /** When the object was created. */
  createdAt: Scalars['DateTimeOffset'];
  createdBy: Actor;
  /** When the object was deleted. */
  deletedAt?: Maybe<Scalars['DateTimeOffset']>;
  deletedBy?: Maybe<Actor>;
  /** A list of views that are pinned for this portfolio. */
  details?: Maybe<Array<RevisionCostAccount>>;
  id: Scalars['Int'];
  name?: Maybe<Scalars['String']>;
  /** The amount of the budget revision. */
  total: Scalars['Decimal'];
  /** When the object was last updated. */
  updatedAt: Scalars['DateTimeOffset'];
  updatedBy: Actor;
};

/** RevisionCostAccount */
export type RevisionCostAccount = {
  __typename?: 'RevisionCostAccount';
  /** The cost account for this revision detail. */
  account: GlAccount;
  amount?: Maybe<Scalars['Decimal']>;
  /** When the object was created. */
  createdAt: Scalars['DateTimeOffset'];
  createdBy: Actor;
  /** When the object was deleted. */
  deletedAt?: Maybe<Scalars['DateTimeOffset']>;
  deletedBy?: Maybe<Actor>;
  id: Scalars['Int'];
  isPursuit: Scalars['Boolean'];
  /** When the object was last updated. */
  updatedAt: Scalars['DateTimeOffset'];
  updatedBy: Actor;
};

/** Role */
export type Role = {
  __typename?: 'Role';
  /** When the object was created. */
  createdAt: Scalars['DateTimeOffset'];
  createdBy: Actor;
  /** When the object was deleted. */
  deletedAt?: Maybe<Scalars['DateTimeOffset']>;
  deletedBy?: Maybe<Actor>;
  id: Scalars['Int'];
  name: Scalars['String'];
  /** When the object was last updated. */
  updatedAt: Scalars['DateTimeOffset'];
  updatedBy: Actor;
};


/** ScenarioType */
export type ScenarioType = {
  __typename?: 'ScenarioType';
  /** When the object was created. */
  createdAt: Scalars['DateTimeOffset'];
  createdBy: Actor;
  /** When the object was deleted. */
  deletedAt?: Maybe<Scalars['DateTimeOffset']>;
  deletedBy?: Maybe<Actor>;
  id: Scalars['Int'];
  name: Scalars['String'];
  /** When the object was last updated. */
  updatedAt: Scalars['DateTimeOffset'];
  updatedBy: Actor;
};



/** State */
export type State = {
  __typename?: 'State';
  code: Scalars['String'];
  id: Scalars['Int'];
  name: Scalars['String'];
};

/** Structure */
export type Structure = {
  __typename?: 'Structure';
  /** When the object was created. */
  createdAt: Scalars['DateTimeOffset'];
  createdBy: Actor;
  /** When the object was deleted. */
  deletedAt?: Maybe<Scalars['DateTimeOffset']>;
  deletedBy?: Maybe<Actor>;
  id: Scalars['Int'];
  isDisabled: Scalars['Boolean'];
  name: Scalars['String'];
  /** When the object was last updated. */
  updatedAt: Scalars['DateTimeOffset'];
  updatedBy: Actor;
};

/** Submarket */
export type Submarket = {
  __typename?: 'Submarket';
  /** When the object was created. */
  createdAt: Scalars['DateTimeOffset'];
  createdBy: Actor;
  /** When the object was deleted. */
  deletedAt?: Maybe<Scalars['DateTimeOffset']>;
  deletedBy?: Maybe<Actor>;
  id: Scalars['Int'];
  name: Scalars['String'];
  /** When the object was last updated. */
  updatedAt: Scalars['DateTimeOffset'];
  updatedBy: Actor;
};

/** A submarket for a specific deal. */
export type SubmarketType = {
  __typename?: 'SubmarketType';
  /** When the object was created. */
  createdAt: Scalars['DateTimeOffset'];
  createdBy: Actor;
  /** When the object was deleted. */
  deletedAt?: Maybe<Scalars['DateTimeOffset']>;
  deletedBy?: Maybe<Actor>;
  /** The name of the color variant to use when displaying this status. */
  fkMarket?: Maybe<Scalars['Int']>;
  /** The id of the role. */
  id: Scalars['Int'];
  /** The name of the role. */
  name: Scalars['String'];
  /** When the object was last updated. */
  updatedAt: Scalars['DateTimeOffset'];
  updatedBy: Actor;
};

/** SystemProcess */
export type SystemProcess = Actor & {
  __typename?: 'SystemProcess';
  /** The id of the actor. */
  displayName: Scalars['String'];
  /** The id of the actor. */
  displayNameAlt: Scalars['String'];
  /** The id of the actor. */
  id: Scalars['Int'];
};

/** Task */
export type Task = {
  __typename?: 'Task';
  /** A list of comments updates belonging to this task. */
  comments?: Maybe<Array<Comment>>;
  /** When the object was created. */
  createdAt: Scalars['DateTimeOffset'];
  createdBy: Actor;
  /** When the object was deleted. */
  deletedAt?: Maybe<Scalars['DateTimeOffset']>;
  deletedBy?: Maybe<Actor>;
  description: Scalars['String'];
  /** Projected due date */
  dueDate?: Maybe<Scalars['Date']>;
  id: Scalars['Int'];
  name: Scalars['String'];
  /** The priority of a given task. */
  priority?: Maybe<DealPriorityLevel>;
  /** The status of a given task. */
  status: TaskStatus;
  /** When the object was last updated. */
  updatedAt: Scalars['DateTimeOffset'];
  updatedBy: Actor;
};

/** TaskStatus */
export type TaskStatus = {
  __typename?: 'TaskStatus';
  /** When the object was created. */
  createdAt: Scalars['DateTimeOffset'];
  createdBy: Actor;
  /** When the object was deleted. */
  deletedAt?: Maybe<Scalars['DateTimeOffset']>;
  deletedBy?: Maybe<Actor>;
  id: Scalars['Int'];
  name: Scalars['String'];
  /** When the object was last updated. */
  updatedAt: Scalars['DateTimeOffset'];
  updatedBy: Actor;
};

/** A team member role that can be assigned to a team member of a deal. */
export type TeamMemberRole = {
  __typename?: 'TeamMemberRole';
  /** When the object was created. */
  createdAt: Scalars['DateTimeOffset'];
  createdBy: Actor;
  /** When the object was deleted. */
  deletedAt?: Maybe<Scalars['DateTimeOffset']>;
  deletedBy?: Maybe<Actor>;
  /** A description of the role. */
  description?: Maybe<Scalars['String']>;
  /** The id of the role. */
  id: Scalars['Int'];
  isAppDefault: Scalars['Boolean'];
  isDisabled: Scalars['Boolean'];
  /** The name of the role. */
  name: Scalars['String'];
  /** When the object was last updated. */
  updatedAt: Scalars['DateTimeOffset'];
  updatedBy: Actor;
};

/** A result type given when the role that was attempted to be created or edited already exists. */
export type TeamMemberRoleAlreadyExists = Error & {
  __typename?: 'TeamMemberRoleAlreadyExists';
  message: Scalars['String'];
  /** The existing role with matching properties. */
  role?: Maybe<TeamMemberRole>;
};

/** TemplateApprovalPackage */
export type TemplateApprovalPackage = {
  __typename?: 'TemplateApprovalPackage';
  /** When the object was created. */
  createdAt: Scalars['DateTimeOffset'];
  createdBy: Actor;
  /** When the object was deleted. */
  deletedAt?: Maybe<Scalars['DateTimeOffset']>;
  deletedBy?: Maybe<Actor>;
  /** The id of this approval package template */
  id: Scalars['Int'];
  /** The name of this approval package template. */
  name: Scalars['String'];
  /** The sections of the approval package template. */
  sections?: Maybe<Array<TemplateApprovalPackageSection>>;
  /** When the object was last updated. */
  updatedAt: Scalars['DateTimeOffset'];
  updatedBy: Actor;
};

/** TemplateApprovalPackageSection */
export type TemplateApprovalPackageSection = {
  __typename?: 'TemplateApprovalPackageSection';
  /** The content for this section, made up of one or more sub-sections. */
  content?: Maybe<Array<TemplateApprovalPackageSubSectionUnion>>;
  /** The column width of this section based on a 12 column wide grid. */
  gridColumnWidth: Scalars['Int'];
  /** The id of the section */
  id: Scalars['Int'];
  /** The order id of the section. */
  orderId?: Maybe<Scalars['Decimal']>;
  /** The title of this approval package section. */
  title: Scalars['String'];
};

/** TemplateApprovalPackageSubSectionChecklist */
export type TemplateApprovalPackageSubSectionChecklist = {
  __typename?: 'TemplateApprovalPackageSubSectionChecklist';
  /** The column width of this sub-section based on a 12 column wide grid. */
  gridColumnWidth: Scalars['Int'];
  /** The id of the section */
  id: Scalars['Int'];
  /** A list of checklist items for a given approval package. */
  items?: Maybe<Array<ApprovalPackageChecklistItem>>;
  /** The order id of the sub-section. */
  orderId?: Maybe<Scalars['Decimal']>;
  /** The title of this approval package sub-section. */
  title: Scalars['String'];
};

/** TemplateApprovalPackageSubSectionDealBackground */
export type TemplateApprovalPackageSubSectionDealBackground = {
  __typename?: 'TemplateApprovalPackageSubSectionDealBackground';
  /** The column width of this sub-section based on a 12 column wide grid. */
  gridColumnWidth: Scalars['Int'];
  /** The id of the section */
  id: Scalars['Int'];
  /** The order id of the sub-section. */
  orderId?: Maybe<Scalars['Decimal']>;
  /** The title of this approval package sub-section. */
  title: Scalars['String'];
};

/** TemplateApprovalPackageSubSectionEarnestMoneyDeposit */
export type TemplateApprovalPackageSubSectionEarnestMoneyDeposit = {
  __typename?: 'TemplateApprovalPackageSubSectionEarnestMoneyDeposit';
  /** The column width of this sub-section based on a 12 column wide grid. */
  gridColumnWidth: Scalars['Int'];
  /** The id of the section */
  id: Scalars['Int'];
  /** The order id of the sub-section. */
  orderId?: Maybe<Scalars['Decimal']>;
  /** The title of this approval package sub-section. */
  title: Scalars['String'];
};

/** TemplateApprovalPackageSubSectionGcEstimate */
export type TemplateApprovalPackageSubSectionGcEstimate = {
  __typename?: 'TemplateApprovalPackageSubSectionGcEstimate';
  /** A list of gcEstimate items for a given template approval package. */
  gcEstimates?: Maybe<Array<ApprovalPackageGcEstimate>>;
  /** The column width of this sub-section based on a 12 column wide grid. */
  gridColumnWidth: Scalars['Int'];
  /** The id of the section */
  id: Scalars['Int'];
  /** The order id of the sub-section. */
  orderId?: Maybe<Scalars['Decimal']>;
  /** The title of this approval package sub-section. */
  title: Scalars['String'];
};

/** TemplateApprovalPackageSubSectionImages */
export type TemplateApprovalPackageSubSectionImages = {
  __typename?: 'TemplateApprovalPackageSubSectionImages';
  /** The column width of this sub-section based on a 12 column wide grid. */
  gridColumnWidth: Scalars['Int'];
  /** The id of the section */
  id: Scalars['Int'];
  /** The order id of the sub-section. */
  orderId?: Maybe<Scalars['Decimal']>;
  /** The title of this approval package sub-section. */
  title: Scalars['String'];
};

/** TemplateApprovalPackageSubSectionKeyRisks */
export type TemplateApprovalPackageSubSectionKeyRisks = {
  __typename?: 'TemplateApprovalPackageSubSectionKeyRisks';
  /** The column width of this sub-section based on a 12 column wide grid. */
  gridColumnWidth: Scalars['Int'];
  /** The id of the section */
  id: Scalars['Int'];
  /** The order id of the sub-section. */
  orderId?: Maybe<Scalars['Decimal']>;
  /** The title of this approval package sub-section. */
  title: Scalars['String'];
};

/** TemplateApprovalPackageSubSectionMerits */
export type TemplateApprovalPackageSubSectionMerits = {
  __typename?: 'TemplateApprovalPackageSubSectionMerits';
  /** The column width of this sub-section based on a 12 column wide grid. */
  gridColumnWidth: Scalars['Int'];
  /** The id of the section */
  id: Scalars['Int'];
  /** The order id of the sub-section. */
  orderId?: Maybe<Scalars['Decimal']>;
  /** The title of this approval package sub-section. */
  title: Scalars['String'];
};

/** TemplateApprovalPackageSubSectionPageBreak */
export type TemplateApprovalPackageSubSectionPageBreak = {
  __typename?: 'TemplateApprovalPackageSubSectionPageBreak';
  /** The id of the section */
  id: Scalars['Int'];
  /** The order id of the sub-section. */
  orderId?: Maybe<Scalars['Decimal']>;
};

/** TemplateApprovalPackageSubSectionTable */
export type TemplateApprovalPackageSubSectionTable = {
  __typename?: 'TemplateApprovalPackageSubSectionTable';
  /** A list of the cells that make up the table of metrics. */
  cells?: Maybe<Array<TemplateIcSubSectionTableCell>>;
  /** The number of columns of metrics to show. */
  columns: Scalars['Int'];
  /** The column width of this sub-section based on a 12 column wide grid. */
  gridColumnWidth: Scalars['Int'];
  /** The id of the section */
  id: Scalars['Int'];
  /** The order id of the sub-section. */
  orderId?: Maybe<Scalars['Decimal']>;
  /** The title of this approval package sub-section. */
  title: Scalars['String'];
};

/** TemplateApprovalPackageSubSectionText */
export type TemplateApprovalPackageSubSectionText = {
  __typename?: 'TemplateApprovalPackageSubSectionText';
  /** The column width of this sub-section based on a 12 column wide grid. */
  gridColumnWidth: Scalars['Int'];
  /** The id of the section */
  id: Scalars['Int'];
  /** The order id of the sub-section. */
  orderId?: Maybe<Scalars['Decimal']>;
  /** The text of this section. */
  text?: Maybe<Scalars['String']>;
  /** The title of this approval package sub-section. */
  title: Scalars['String'];
};

export type TemplateApprovalPackageSubSectionUnion = TemplateApprovalPackageSubSectionText | TemplateApprovalPackageSubSectionChecklist | TemplateApprovalPackageSubSectionGcEstimate | TemplateApprovalPackageSubSectionKeyRisks | TemplateApprovalPackageSubSectionMerits | TemplateApprovalPackageSubSectionTable | TemplateApprovalPackageSubSectionImages | TemplateApprovalPackageSubSectionPageBreak | TemplateApprovalPackageSubSectionDealBackground | TemplateApprovalPackageSubSectionEarnestMoneyDeposit;

/** TemplateIcSubSectionTableCell */
export type TemplateIcSubSectionTableCell = {
  __typename?: 'TemplateIcSubSectionTableCell';
  /** A list of the entries for this cell. */
  entries?: Maybe<Array<IcSubSectionTableCellEntry>>;
  /** The id of the cell */
  id: Scalars['Int'];
  orderId?: Maybe<Scalars['Decimal']>;
};

/** A text metric. */
export type TextMetric = {
  __typename?: 'TextMetric';
  /** When the object was created. */
  createdAt: Scalars['DateTimeOffset'];
  createdBy: Actor;
  /** When the object was deleted. */
  deletedAt?: Maybe<Scalars['DateTimeOffset']>;
  deletedBy?: Maybe<Actor>;
  id: Scalars['Int'];
  isAppDefault: Scalars['Boolean'];
  isDisabled: Scalars['Boolean'];
  name: Scalars['String'];
  /** When the object was last updated. */
  updatedAt: Scalars['DateTimeOffset'];
  updatedBy: Actor;
};

/** A result type given when a text metric trying to be created or named already exists. */
export type TextMetricAlreadyExists = Error & {
  __typename?: 'TextMetricAlreadyExists';
  message: Scalars['String'];
  /** The existing text metric with the same name. */
  textMetric?: Maybe<TextMetric>;
};

/** A preview record for details of a transaction. */
export type TranasctionPeriodDetailPreview = {
  __typename?: 'TranasctionPeriodDetailPreview';
  /** The amount that is allocated in the month specified by the date. */
  amount?: Maybe<Scalars['Decimal']>;
  /** A month end date that the included amounts are be associated with. */
  date: Scalars['Date'];
  /** The type of this preview record. */
  type: TransactionPreviewTypeEnum;
};

/** Transaction */
export type Transaction = {
  __typename?: 'Transaction';
  /** The account for this tranasction. */
  account: GlAccount;
  amount?: Maybe<Scalars['Decimal']>;
  /** The contract for this transaction. */
  contract?: Maybe<Contract>;
  /** When the object was created. */
  createdAt: Scalars['DateTimeOffset'];
  createdBy: Actor;
  /** When the object was deleted. */
  deletedAt?: Maybe<Scalars['DateTimeOffset']>;
  deletedBy?: Maybe<Actor>;
  description: Scalars['String'];
  details?: Maybe<Array<TranasctionPeriodDetailPreview>>;
  /** The distribution type for this transaction. */
  distributionType: DistributionType;
  /** The end of this transaction. */
  end?: Maybe<TransactionTiming>;
  /** The end date of the transaction */
  endDate: Scalars['Date'];
  hasCashPaymentOverride: Scalars['Boolean'];
  id: Scalars['Int'];
  isOverride: Scalars['Boolean'];
  isRefundable: Scalars['Boolean'];
  /** Whether or not the transaction is stale because one or more of its associated events have changed since it was last updated. */
  isStale: Scalars['Boolean'];
  milestoneAdjustmentEnd?: Maybe<Scalars['Int']>;
  milestoneAdjustmentStart?: Maybe<Scalars['Int']>;
  /** The start of this transaction. */
  start?: Maybe<TransactionTiming>;
  /** The start date of the transaction */
  startDate: Scalars['Date'];
  /** When the object was last updated. */
  updatedAt: Scalars['DateTimeOffset'];
  updatedBy: Actor;
  /** The vendor for this transaction. */
  vendor?: Maybe<FirmInfo>;
};

/** TransactionDate */
export type TransactionDate = {
  __typename?: 'TransactionDate';
  date: Scalars['Date'];
};

/** TransactionEvent */
export type TransactionEvent = {
  __typename?: 'TransactionEvent';
  event: DealEvent;
  milestoneOffset: Scalars['Int'];
  usesStartDate: Scalars['Boolean'];
};

/** TransactionPeriodSummary */
export type TransactionPeriodSummary = {
  __typename?: 'TransactionPeriodSummary';
  /** The GL account for this summary. */
  account: GlAccount;
  amount: Scalars['Decimal'];
  description: Scalars['String'];
  id: Scalars['Int'];
  postDate: Scalars['Date'];
};

export enum TransactionPreviewTypeEnum {
  Invoice = 'INVOICE',
  Cash = 'CASH',
  RefundExpiration = 'REFUND_EXPIRATION'
}

export type TransactionTiming = TransactionDate | TransactionEvent;




/** An input object used for updating an analysis event. */
export type UpdateAnalysisEventInput = {
  /** The id of the analysis the event belongs to. */
  fkAnalysis: Scalars['Int'];
  /** The id of the event to update. */
  fkEvent: Scalars['Int'];
  /** The start date to update the analysis event to. */
  startDate?: Maybe<Scalars['DateTime']>;
};

/** An input object used for updating an analysis. */
export type UpdateAnalysisInput = {
  /** The id of the analysis. */
  id: Scalars['Int'];
  /** The description of the analysis. */
  description?: Maybe<Scalars['String']>;
  /** The id of the scenario this analysis has. */
  fkScenarioType?: Maybe<Scalars['Int']>;
};

/** An input object used for updating an analysis metric. */
export type UpdateAnalysisMetricInput = {
  /** The id of the analysis the metric belongs to. */
  fkAnalysis: Scalars['Int'];
  /** The id of the metric to update. */
  fkMetric: Scalars['Int'];
  /** The value to update the analysis metric to. */
  value?: Maybe<Scalars['Decimal']>;
};

/** An input object used for updating an analysis text metric. */
export type UpdateAnalysisTextMetricInput = {
  /** The id of the analysis the metric belongs to. */
  fkAnalysis: Scalars['Int'];
  /** The id of the text metric to update. */
  fkTextMetric: Scalars['Int'];
  /** The value to update the analysis text metric to. */
  value?: Maybe<Scalars['String']>;
};

/** An input for updating an analysis type for use with an analysis. */
export type UpdateAnalysisTypeInput = {
  id: Scalars['Int'];
  name: Scalars['String'];
  acronym: Scalars['String'];
};

export type UpdateAnalysisTypeResult = AnalysisType | AnalysisTypeAlreadyExists | ErrorResult;

/** An input for updating an approval package for a given deal. */
export type UpdateApprovalPackageInput = {
  /** The id of the approval package to update. */
  id: Scalars['Int'];
  /** The review date for the approval package. */
  reviewDate?: Maybe<Scalars['DateTime']>;
  /** The ammendment number of the the approval package. */
  amendmentNumber?: Maybe<Scalars['Int']>;
  status?: Maybe<ApprovalPackageStatusEnum>;
  /** The id of the type of this approval package. */
  fkPackageType?: Maybe<Scalars['Int']>;
  /** The id of the published schedule instance associated with this approval package. */
  fkPublishedInstanceSchedule?: Maybe<Scalars['Int']>;
  /** The id of the analysis instance associated with this approval package */
  fkAnalysis?: Maybe<Scalars['Int']>;
};

/** An input object used for updating a reviewer for an approval package. */
export type UpdateApprovalPackageReviewerInput = {
  /** The id of the reviwer record to update. */
  id: Scalars['Int'];
  /** The id of the status of the reviewer. */
  fkReviewerStatus?: Maybe<Scalars['Int']>;
  /** The id of the firm that the reviewer is associated with. */
  fkFirmRepOrg?: Maybe<Scalars['Int']>;
};

/** An input for updating an approval package section. */
export type UpdateApprovalPackageSectionInput = {
  /** The id of the section. */
  id: Scalars['Int'];
  /** The title of the section. */
  title?: Maybe<Scalars['String']>;
  /** The order id of the section. */
  orderId?: Maybe<Scalars['Decimal']>;
  /** The column width of this sub-section based on a 12 column wide grid. */
  gridColumnWidth?: Maybe<Scalars['Int']>;
};

/** An input for updating the approval package subsection. */
export type UpdateApprovalPackageSubSectionChecklistInput = {
  /** The id of the sub-section. */
  id: Scalars['Int'];
  /** The checklist of the sub-section. */
  fkChecklist?: Maybe<Scalars['Int']>;
};

/** An input for updating the approval package subsection. */
export type UpdateApprovalPackageSubSectionInput = {
  /** The id of the sub-section. */
  id: Scalars['Int'];
  /** The title of the sub-section. */
  title?: Maybe<Scalars['String']>;
  /** The order id of the sub-section. */
  orderId?: Maybe<Scalars['Decimal']>;
  /** The column width of this sub-section based on a 12 column wide grid. */
  gridColumnWidth?: Maybe<Scalars['Int']>;
};

/** An input for updating the approval package subsection. */
export type UpdateApprovalPackageSubSectionTextInput = {
  /** The id of the sub-section. */
  id: Scalars['Int'];
  /** The text of the sub-section. */
  text?: Maybe<Scalars['String']>;
};

/** An input for updating an approval package template. */
export type UpdateApprovalPackageTemplateInput = {
  /** The id of the approval package template to update. */
  id: Scalars['Int'];
  /** The name of this approval package template. */
  name?: Maybe<Scalars['String']>;
};

/** An input object used for updating an approval package type. */
export type UpdateApprovalPackageTypeInput = {
  /** The id of the approval package type. */
  id: Scalars['Int'];
  /** The name of the approval package type. */
  name?: Maybe<Scalars['String']>;
  /** The id of the default approval package template that should be used when creating an approval package of this type. */
  fkPackageTemplate?: Maybe<Scalars['Int']>;
  /** The id of the event that this approval package type should be associated with on the schedule for a given deal. */
  fkEvent?: Maybe<Scalars['Int']>;
};

/** An input object used for creating a building. */
export type UpdateBondInput = {
  id: Scalars['Int'];
  name?: Maybe<Scalars['String']>;
  isComplete?: Maybe<Scalars['Boolean']>;
  bondDescription?: Maybe<Scalars['String']>;
  principalEntity?: Maybe<Scalars['String']>;
  fkFirmBondingCompany?: Maybe<Scalars['Int']>;
  obligeeBeneficiary?: Maybe<Scalars['String']>;
  premium?: Maybe<Scalars['Decimal']>;
  bondAmount?: Maybe<Scalars['Decimal']>;
  collateralAmount?: Maybe<Scalars['Decimal']>;
  locAmount?: Maybe<Scalars['Decimal']>;
  acceptedAlternatives?: Maybe<Scalars['String']>;
  breakdownBackup?: Maybe<Scalars['String']>;
  describedWork?: Maybe<Scalars['String']>;
  originalEffectiveDate?: Maybe<Scalars['DateTime']>;
  expires?: Maybe<Scalars['DateTime']>;
  expectedRelease?: Maybe<Scalars['DateTime']>;
  conditionsForRelease?: Maybe<Scalars['String']>;
  premiumRefundDue?: Maybe<Scalars['Decimal']>;
  notes?: Maybe<Scalars['String']>;
  improvementDeadline?: Maybe<Scalars['DateTime']>;
  premiumComment?: Maybe<Scalars['String']>;
  collateralNotes?: Maybe<Scalars['String']>;
  expiresNotes?: Maybe<Scalars['String']>;
};

/** An input object used for creating a building. */
export type UpdateBuildingInput = {
  /** The id of the building. */
  id: Scalars['Int'];
  /** The name of the building. */
  name?: Maybe<Scalars['String']>;
  /** The unit count building. */
  unitCount?: Maybe<Scalars['Int']>;
  /** The address of the building. */
  address?: Maybe<Scalars['String']>;
  /** A field for providing any further details about the building. */
  notes?: Maybe<Scalars['String']>;
  /** The TCO date for the building. */
  tcoDate?: Maybe<Scalars['Date']>;
  /** The id of the building type. */
  fkBuildingType?: Maybe<Scalars['Int']>;
  /** A flag of whether or not the TCO has been recieved or not. */
  isTcoReceived?: Maybe<Scalars['Boolean']>;
};

/** An input object used for updating a change event. */
export type UpdateChangeEventInput = {
  /** The id of the change event. */
  id: Scalars['Int'];
  /** The title of the change event. */
  title?: Maybe<Scalars['String']>;
  /** The number of the change event. */
  number?: Maybe<Scalars['Int']>;
  /** The total of the change event according to the GC. */
  total?: Maybe<Scalars['Decimal']>;
  /** The schedule impact of the change event according to the GC (in # of days). */
  scheduleImpact?: Maybe<Scalars['Int']>;
  /** The total of the change event according to the owner. */
  ownerTotal?: Maybe<Scalars['Decimal']>;
  /** The schedule impact of the change event according to the owner (in # of days). */
  ownerScheduleImpact?: Maybe<Scalars['Int']>;
  /** The id of the type of the change event. */
  fkChangeEventType?: Maybe<Scalars['Int']>;
  /** The id of the status of the change event. */
  fkChangeOrderStatus?: Maybe<Scalars['Int']>;
  /** The id of the reason of the change event. */
  fkChangeEventReason?: Maybe<Scalars['Int']>;
  /** Wether or not the schedule impact is excusable. */
  isScheduleImpactExcusable?: Maybe<Scalars['Boolean']>;
  /** The id of the cost account that is the cost source for this change event. */
  fkGlAccountCostSource?: Maybe<Scalars['Int']>;
};

/** An input object used for updating a change order. */
export type UpdateChangeOrderPackageInput = {
  /** The id of the change order. */
  id: Scalars['Int'];
  /** The title of the change order. */
  title?: Maybe<Scalars['String']>;
  /** The number of the change order. */
  number?: Maybe<Scalars['Int']>;
  /** The total of the change order. */
  total?: Maybe<Scalars['Decimal']>;
  /** The schedule event of the change order. */
  scheduleImpact?: Maybe<Scalars['Int']>;
  /** The id of the status of the change order. */
  fkChangeOrderStatus?: Maybe<Scalars['Int']>;
  /** Whether or not a change order has been executed. */
  isExecuted?: Maybe<Scalars['Boolean']>;
  /** Wether or not the schedule impact is excusable. */
  isScheduleImpactExcusable?: Maybe<Scalars['Boolean']>;
  /** The id of the cost account that is the cost source for this change order package. */
  fkGlAccountCostSource?: Maybe<Scalars['Int']>;
  /** The id of the parent change order of the potential change order. */
  fkChangeOrderPackageParent?: Maybe<Scalars['Int']>;
};

/** An input object used for updating a checklist. */
export type UpdateChecklistInput = {
  /** The id of the checklist. */
  id: Scalars['Int'];
  /** The name of the checklist. */
  name?: Maybe<Scalars['String']>;
  /** A brief description of the checklist. */
  description?: Maybe<Scalars['String']>;
  /** Whether or not a checklist is disabled. */
  isDisabled?: Maybe<Scalars['Boolean']>;
};

/** An input object used for updating a checklist item. */
export type UpdateChecklistItemInput = {
  /** The id of the checklist item. */
  id: Scalars['Int'];
  /** The name of the checklist item. */
  name?: Maybe<Scalars['String']>;
  /** The default comment of the checklist item. */
  defaultComment?: Maybe<Scalars['String']>;
  /** The team member role to assign to deal instances of this item. */
  fkTeamMemberRole?: Maybe<Scalars['Int']>;
};

export type UpdateChecklistResult = Checklist | ChecklistAlreadyExists;

/** An input object used for updating a comment. */
export type UpdateCommentInput = {
  /** The id representing the subject of comment. */
  id: Scalars['Int'];
  /** The body of the comment. */
  body: Scalars['String'];
};

export type UpdateConfigurableEntityResult = ConfigurableEntity | ConfigurableEntityAlreadyExists | ErrorResult;

/** An input object used for updating a contact */
export type UpdateContactInput = {
  /** The id of the contact to update */
  id: Scalars['Int'];
  /** The first name of the contact. */
  firstName?: Maybe<Scalars['String']>;
  /** The last name of the contact. */
  lastName?: Maybe<Scalars['String']>;
  /** The title of the contact. */
  title?: Maybe<Scalars['String']>;
  /** The first line of the contact's address. */
  address1?: Maybe<Scalars['String']>;
  /** The second line of the contact's address. */
  address2?: Maybe<Scalars['String']>;
  /** The city of the contact's address. */
  city?: Maybe<Scalars['String']>;
  /** The zipcode of the contact's address. */
  zipcode?: Maybe<Scalars['String']>;
  /** The home phone number of the contact. */
  officePhone?: Maybe<Scalars['String']>;
  /** The cell phone number of the contact. */
  cellPhone?: Maybe<Scalars['String']>;
  /** The email address of the contact. */
  email?: Maybe<Scalars['String']>;
  /** The id corresponding to the state of the contact's address. */
  fkFirm?: Maybe<Scalars['Int']>;
  /** The id corresponding to the state of the contact. */
  fkState?: Maybe<Scalars['Int']>;
  /** Notes for the contact. */
  notes?: Maybe<Scalars['String']>;
  /** Whether or not the contact to be updated is considered a primary contact for its associated firm. */
  isPrimary?: Maybe<Scalars['Boolean']>;
  /** An identifier representing the pipeline that the contact is associated with. */
  fkPipeline?: Maybe<Scalars['Int']>;
};

export type UpdateContactResult = Contact | ContactAlreadyExists;

/** An input object used for updating a contract associated with the budget module. */
export type UpdateContractInput = {
  /** The id of the contract. */
  id: Scalars['Int'];
  /** The name of the contract. */
  name?: Maybe<Scalars['String']>;
  /** An optional description of the contract. */
  description?: Maybe<Scalars['String']>;
  /** The id of the vendor that the contract is associated with. */
  fkFirm?: Maybe<Scalars['Int']>;
  /** The total amount that the contract is for. This is the negotiated total for the contract, which may be different from the sum of all of the transactions associated with a given contract. */
  amount?: Maybe<Scalars['Decimal']>;
};

/** An input object used for updating the checklist item for a given deal. */
export type UpdateDealChecklistItemInput = {
  /** The id of the checklist item. */
  fkChecklistItem: Scalars['Int'];
  /** The id of the deal associated with this checklist item. */
  fkDeal: Scalars['Int'];
  /** The id of the checklist item status to assign. */
  fkChecklistItemStatus?: Maybe<Scalars['Int']>;
  /** The due date of the checklist item */
  dueDate?: Maybe<Scalars['DateTime']>;
  /** A comment for the checklist item. */
  comment?: Maybe<Scalars['String']>;
  /** The id of the user to assign to this checklist item. */
  fkUser?: Maybe<Scalars['Int']>;
};

/** An input object used for updating a deal event. */
export type UpdateDealEventInput = {
  /** The event to be added to this deal. */
  fkEvent: Scalars['Int'];
  /** The id of the deal to add this event to. */
  fkDeal: Scalars['Int'];
  /** The start date of the event */
  startDate?: Maybe<Scalars['DateTime']>;
  /** The end date of the event */
  endDate?: Maybe<Scalars['DateTime']>;
  /** If the event is has actually occurred or not. */
  isComplete?: Maybe<Scalars['Boolean']>;
  /** If the event is hidden from this deal. */
  isHidden?: Maybe<Scalars['Boolean']>;
};

export type UpdateDealEventResult = DealEvent | DealEventAlreadyExists;

/** An input object used for updating a deal */
export type UpdateDealInput = {
  /** The id of the deal. */
  id: Scalars['Int'];
  /** The name of the deal. */
  name?: Maybe<Scalars['String']>;
  /** Another name for the deal (AKA Name). */
  otherName?: Maybe<Scalars['String']>;
  /** The first line of the address of a deal. */
  address1?: Maybe<Scalars['String']>;
  /** The second line of the address of a deal. */
  address2?: Maybe<Scalars['String']>;
  /** A flag to indicate if the deals location is entered as coordinates. */
  usesCoordinates?: Maybe<Scalars['Boolean']>;
  /** The city a deal is located in. */
  city?: Maybe<Scalars['String']>;
  /** The zipcode a deal is located in. */
  zipcode?: Maybe<Scalars['String']>;
  /** The latitude of a deal location. */
  latitude?: Maybe<Scalars['Decimal']>;
  /** The longitude of a deal location. */
  longitude?: Maybe<Scalars['Decimal']>;
  /** The parcel number of a deal location. */
  parcelNumber?: Maybe<Scalars['String']>;
  /** The opportunity zone of a deal. */
  isOpportunityZone?: Maybe<Scalars['Boolean']>;
  /** The date a deal was born on. */
  birthDate?: Maybe<Scalars['DateTime']>;
  /** The date a deal died on. */
  deathDate?: Maybe<Scalars['DateTime']>;
  /** The site location of a deal. */
  siteLocation?: Maybe<Scalars['String']>;
  /** The investment vision of a deal. */
  investmentVision?: Maybe<Scalars['String']>;
  /** The earnest money deposit of a deal. */
  earnestMoneyDeposit?: Maybe<Scalars['String']>;
  /** The background of a deal. */
  dealBackground?: Maybe<Scalars['String']>;
  /** The id representing the state of a deal. */
  fkState?: Maybe<Scalars['Int']>;
  /** The id representing the structure of a deal. */
  fkStructure?: Maybe<Scalars['Int']>;
  /** The id representing the deal type of a deal. */
  fkDealType?: Maybe<Scalars['Int']>;
  /** The id of the region of a deal. */
  fkRegion?: Maybe<Scalars['Int']>;
  /** The id of the market a deal belongs to. */
  fkMarket?: Maybe<Scalars['Int']>;
  /** The id of the submarket a deal belongs to. */
  fkSubmarket?: Maybe<Scalars['Int']>;
  /** The id representing the msa of a deal. */
  fkMsa?: Maybe<Scalars['Int']>;
  /** The id representing the fund of a deal. */
  fkFund?: Maybe<Scalars['Int']>;
  /** The id representing the fund entity of a deal. */
  fkFundEntity?: Maybe<Scalars['Int']>;
  /** The id of the hoa of a deal. */
  fkHoa?: Maybe<Scalars['Int']>;
  /** The id of the priority level of a deal. */
  fkPriorityLevel?: Maybe<Scalars['Int']>;
  /** The id of the source of a deal. */
  fkSource?: Maybe<Scalars['Int']>;
  /** The id representing the market segment of a deal. */
  fkMarketSegment?: Maybe<Scalars['Int']>;
  /** The id representing the market type of a deal. */
  fkMarketType?: Maybe<Scalars['Int']>;
  /** The id representing the type of a deal. */
  fkPipeline?: Maybe<Scalars['Int']>;
  /** The id representing the proprietary level of a deal. */
  fkProprietaryLevel?: Maybe<Scalars['Int']>;
  /** The id representing the stage of a deal. */
  fkStage?: Maybe<Scalars['Int']>;
  /** The id representing the asset class of a deal. */
  fkAssetClass?: Maybe<Scalars['Int']>;
  /** The id representing the construction type of a deal. */
  fkConstructionType?: Maybe<Scalars['Int']>;
  /** The id representing the product type of a deal. */
  fkProductType?: Maybe<Scalars['Int']>;
  /** The id representing the readon why a deal was killed. */
  fkReasonKilled?: Maybe<Scalars['Int']>;
  /** The id representing the boker firm for a deal. */
  fkBrokerFirm?: Maybe<Scalars['Int']>;
  /** The id representing the broker contact for a deal. */
  fkBrokerContact?: Maybe<Scalars['Int']>;
  /** The id representing the seller firm for a deal. */
  fkSellerFirm?: Maybe<Scalars['Int']>;
  /** The id representing the seller contact for a deal. */
  fkSellerContact?: Maybe<Scalars['Int']>;
  /** The id representing the buyer firm for a deal. */
  fkBuyerFirm?: Maybe<Scalars['Int']>;
  /** The id representing the contact of the buyer for a deal. */
  fkBuyerContact?: Maybe<Scalars['Int']>;
  /** Purchase Price. */
  purchasePrice?: Maybe<Scalars['Decimal']>;
  /** Total number of units. */
  numberOfUnits?: Maybe<Scalars['Int']>;
  /** Net rentable square footage. */
  nrsf?: Maybe<Scalars['Int']>;
  /** IRR */
  irr?: Maybe<Scalars['Decimal']>;
  /** Unlevered IRR */
  unleverredIrr?: Maybe<Scalars['Decimal']>;
  /** Multiple on Invested Capital */
  moic?: Maybe<Scalars['Decimal']>;
  /** Entry Cap Rate */
  entryCapRate?: Maybe<Scalars['Decimal']>;
  /** Exit Cap Rate */
  exitCapRate?: Maybe<Scalars['Decimal']>;
  /** Required Equity */
  requiredEquity?: Maybe<Scalars['Decimal']>;
  /** Debt */
  debt?: Maybe<Scalars['Decimal']>;
  /** Year Built */
  yearBuilt?: Maybe<Scalars['Int']>;
  /** Represents if the deal is public to all members of an organizaton. */
  isPrivate?: Maybe<Scalars['Boolean']>;
  /** For a public deal, represents if members of an organization have edit rights for this deal. */
  publicCanEdit?: Maybe<Scalars['Boolean']>;
  /** Represents if the deal is public to all members of an organizaton. */
  usesLatestAnalysisAsCurrent?: Maybe<Scalars['Boolean']>;
  /** The id representing the current analysis of this deal. */
  fkAnalysisCurrent?: Maybe<Scalars['Int']>;
  /** The id representing the firm that is the General Contractor of this deal. */
  fkFirmGeneralContractor?: Maybe<Scalars['Int']>;
  /** The original GC Contract committed amount. */
  originalGcContractCommittedAmount?: Maybe<Scalars['Decimal']>;
  /** The percentage of savings from the GCs contingency account that will be shared with the owner. */
  gcContractSavingsPercentage?: Maybe<Scalars['Decimal']>;
  /** The original GC Contingency Budget */
  originalGcContingencyBudget?: Maybe<Scalars['Decimal']>;
  /** The current GC Contingency Budget */
  currentGcContingencyBudget?: Maybe<Scalars['Decimal']>;
  /** The forecasted change amounts for the GC Contingency Budget */
  forecastedGcContingencyChanges?: Maybe<Scalars['Decimal']>;
  /** The id of the revision that represents the original budget for comparison */
  fkRevisionOriginalBudget?: Maybe<Scalars['Int']>;
  /** The id of the instance of the Original GC Contract schedule */
  fkPublishedInstanceOriginalContract?: Maybe<Scalars['Int']>;
  /** If the deal uses working days vs calendar days for the schedule delay tracking. */
  isWorkingDays?: Maybe<Scalars['Boolean']>;
  /** The Legal Entity of the deal */
  legalEntity?: Maybe<Scalars['String']>;
  /** The Spread of the deal */
  spread?: Maybe<Scalars['Decimal']>;
};

/** An input object used for updating the role of a deal member. */
export type UpdateDealMemberRoleInput = {
  /** The id of a member of a deal. */
  memberId: Scalars['Int'];
  memberType: DealMemberEnum;
  /** The id of the deal to assign the users/groups to. */
  fkDeal: Scalars['Int'];
  /** The id of the role that the specified members should be assigned to. */
  fkRole: Scalars['Int'];
};

export type UpdateDealMemberRoleResult = DealRoleUser | DealRoleGroup | ErrorResult;

/** An input object used for updating a deal merit */
export type UpdateDealMeritInput = {
  /** The id of the deal merit. */
  id: Scalars['Int'];
  /** The title of the merit. */
  title?: Maybe<Scalars['String']>;
  /** A brief description of the merit. */
  meritDescription?: Maybe<Scalars['String']>;
  /** The order id of the Deal Merit */
  orderId?: Maybe<Scalars['Int']>;
};

/** An input object used for updating a deal partner. */
export type UpdateDealPartnerInput = {
  /** The id of the deal partner. */
  id: Scalars['Int'];
  /** The id representing the firm of the partner. */
  fkFirmPartner?: Maybe<Scalars['Int']>;
  /** The id representing the firm of the partner. */
  fkContact?: Maybe<Scalars['Int']>;
  /** The id representing the role of the partner. */
  fkPartnerRole?: Maybe<Scalars['Int']>;
};

export type UpdateDealStageResult = DealStage | DealStageAlreadyExists | ErrorResult;

/** An input object used for updating an event. */
export type UpdateEventInput = {
  /** The id of the event. */
  id: Scalars['Int'];
  /** The name of the event. */
  name?: Maybe<Scalars['String']>;
  /** The description of the event. */
  description?: Maybe<Scalars['String']>;
  /** The order id of the event. */
  orderId?: Maybe<Scalars['Decimal']>;
  type?: Maybe<DealEventTypeEnum>;
  /** The id of the event category */
  fkEventCategory?: Maybe<Scalars['Int']>;
  /** The id of the parent event. */
  fkParent?: Maybe<Scalars['Int']>;
  /** Whether or not an event is disabled. */
  isDisabled?: Maybe<Scalars['Boolean']>;
};

export type UpdateEventResult = Event | EventAlreadyExists | ErrorResult;

/** An input object used for updating a firm */
export type UpdateFirmInput = {
  id: Scalars['Int'];
  /** The name of the firm. */
  name?: Maybe<Scalars['String']>;
  /** The website of the firm. */
  website?: Maybe<Scalars['String']>;
  /** A brief description of the firm. */
  description?: Maybe<Scalars['String']>;
  /** An identifier representing the type of the firm. */
  fkFirmType?: Maybe<Scalars['Int']>;
  /** An identifier representing the MSA where the firm is headquartered. */
  fkMsaHq?: Maybe<Scalars['Int']>;
  /** An identifier representing the MSA where the firm is headquartered. */
  fkMarketHq?: Maybe<Scalars['Int']>;
  /** An identifier representing the investment type appetite of the firm. */
  fkInvestmentTypeAppetite?: Maybe<Scalars['Int']>;
  /** An identifier representing the status of the firm from a CRM standpooint. */
  fkCrmStatus?: Maybe<Scalars['Int']>;
  /** An identifier representing the user who is the relationship lead for this firm. */
  fkUserRelationshipLead?: Maybe<Scalars['Int']>;
  /** An identifier representing the pipeline that the firm is associated with. */
  fkPipeline?: Maybe<Scalars['Int']>;
};

/** An input object used for updating a firm region */
export type UpdateFirmRegionInput = {
  /** The id of the firm. */
  fkFirm: Scalars['Int'];
  /** The id of the region. */
  fkRegion: Scalars['Int'];
  /** Whether or not the region is the primary region. */
  isPrimaryRegion?: Maybe<Scalars['Boolean']>;
};

export type UpdateFirmRegionResult = FirmRegion | ErrorResult | AlreadyExists;

/** An input for updating a forecast rule for use in deal budgets. */
export type UpdateForecastRuleInput = {
  id: Scalars['Int'];
  description?: Maybe<Scalars['String']>;
  fkGlAccount?: Maybe<Scalars['Int']>;
  fkForecastRuleGroup?: Maybe<Scalars['Int']>;
  fkForecastRuleType?: Maybe<Scalars['Int']>;
  fkDistributionType?: Maybe<Scalars['Int']>;
  fkEventStart?: Maybe<Scalars['Int']>;
  fkEventEnd?: Maybe<Scalars['Int']>;
  milestoneAdjustmentStart?: Maybe<Scalars['Int']>;
  milestoneAdjustmentEnd?: Maybe<Scalars['Int']>;
  frequency?: Maybe<Scalars['Int']>;
  startMonth?: Maybe<Scalars['Int']>;
  dollarAmount?: Maybe<Scalars['Decimal']>;
  dollarPercent?: Maybe<Scalars['Decimal']>;
  isDisabled?: Maybe<Scalars['Boolean']>;
  usesBalance?: Maybe<Scalars['Boolean']>;
  isSliding?: Maybe<Scalars['Boolean']>;
  phaseSpendingCapPercent?: Maybe<Scalars['Decimal']>;
  fkEventEndUsesStartDate?: Maybe<Scalars['Boolean']>;
  fkEventStartUsesStartDate?: Maybe<Scalars['Boolean']>;
};

/** An input object used for updating a GC estimate. */
export type UpdateGcEstimateInput = {
  /** The id of the GC estimate. */
  id: Scalars['Int'];
  /** The id of the type of this estimate. */
  fkEstimateType?: Maybe<Scalars['Int']>;
  /** The id of the source of this estimate. */
  fkFirmSource?: Maybe<Scalars['Int']>;
  /** The status of the estimate. */
  description?: Maybe<Scalars['String']>;
  /** The total price of the estimate. */
  totalPrice?: Maybe<Scalars['Decimal']>;
  /** The price per unit of the estimate. */
  pricePerUnit?: Maybe<Scalars['Decimal']>;
  /** The price per square foot of the estimate. */
  pricePerNrsf?: Maybe<Scalars['Decimal']>;
  /** Whether or not the estimate has been approved. */
  isApproved?: Maybe<Scalars['Boolean']>;
  /** A comment for the estimate. */
  comment?: Maybe<Scalars['String']>;
};

/** An input for updating an account for use in deal budgets. */
export type UpdateGlAccountInput = {
  id: Scalars['Int'];
  name?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  displayCode?: Maybe<Scalars['String']>;
  isDisabled?: Maybe<Scalars['Boolean']>;
};

export type UpdateGlAccountResult = GlAccount | ErrorResult | AlreadyExists;

/** An input for updating an account tree for use in deal budgets. */
export type UpdateGlAccountTreeInput = {
  id: Scalars['Int'];
  name?: Maybe<Scalars['String']>;
  fkReportType?: Maybe<Scalars['Int']>;
  isDisabled?: Maybe<Scalars['Boolean']>;
};

export type UpdateGlAccountTreeResult = GlAccountTree | ErrorResult | AlreadyExists;

/** An input object used for updating a group. */
export type UpdateGroupInput = {
  /** The id of the group. */
  id: Scalars['Int'];
  /** The name of the group. */
  name: Scalars['String'];
};

/** An input object used for updating an interaction with a firm and contacts */
export type UpdateInteractionInput = {
  id: Scalars['Int'];
  name: Scalars['String'];
  interactionDate: Scalars['Date'];
  fkInteractionType: Scalars['Int'];
  interactionUserIds: Array<Scalars['Int']>;
  interactionContactIds: Array<Scalars['Int']>;
  interactionFirmIds: Array<Scalars['Int']>;
  dealIds: Array<Scalars['Int']>;
  notes: Scalars['String'];
  followUpDate?: Maybe<Scalars['Date']>;
};

/** An input for updating an interest rate. */
export type UpdateInterestRateInput = {
  id: Scalars['Int'];
  value: Scalars['Decimal'];
  startDate: Scalars['DateTime'];
};

export type UpdateInterestRateResult = InterestRate | InterestRateAlreadyExists | ErrorResult;

/** An input object used for updating a key risk */
export type UpdateKeyRiskInput = {
  /** The id the key risk. */
  id: Scalars['Int'];
  /** The title of the key risk. */
  title?: Maybe<Scalars['String']>;
  /** A brief description of the risk. */
  riskDescription?: Maybe<Scalars['String']>;
  /** A brief description of the mitigant for this risk. */
  mitigantDescription?: Maybe<Scalars['String']>;
  /** The order id of the Key Risk */
  orderId?: Maybe<Scalars['Int']>;
};

/** An input object used for updating a label. */
export type UpdateLabelInput = {
  /** The id representing of the label. */
  id: Scalars['Int'];
  /** The name of the label. */
  name: Scalars['String'];
  /** The color of the label. */
  color?: Maybe<Scalars['String']>;
};

/** An input for updating a metric for use with an analysis. */
export type UpdateMetricInput = {
  id: Scalars['Int'];
  name: Scalars['String'];
  format: MetricFormatEnum;
  unit?: Maybe<Scalars['String']>;
  precision?: Maybe<Scalars['Int']>;
  /** The amount to multiply the metric by. */
  scale?: Maybe<Scalars['Decimal']>;
  /** Whether or not a metric is disabled. */
  isDisabled: Scalars['Boolean'];
};

export type UpdateMetricResult = Metric | MetricAlreadyExists | ErrorResult;

/** An input object used for updating the role of a organization member. */
export type UpdateOrganizationMemberRoleInput = {
  /** The id of a member of an organization. */
  memberId: Scalars['Int'];
  memberType: OrganizationMemberEnum;
  /** The id of the role that the specified members should be assigned to. */
  fkRole: Scalars['Int'];
};

export type UpdateOrganizationMemberRoleResult = OrganizationRoleUser | OrganizationRoleGroup | ErrorResult;

export type UpdatePackageResult = ApprovalPackage | ErrorResult | AlreadyExists;

export type UpdatePackageTemplateResult = TemplateApprovalPackage | ErrorResult | AlreadyExists;

export type UpdatePackageTypeResult = ApprovalPackageType | ErrorResult | AlreadyExists;

/** An input object used for updating a team member role. */
export type UpdatePartnerRoleInput = {
  /** The id of the team member role. */
  id: Scalars['Int'];
  /** The name of the role. */
  name?: Maybe<Scalars['String']>;
  /** The description of the role. */
  description?: Maybe<Scalars['String']>;
  /** Whether or not a role is disabled. */
  isDisabled?: Maybe<Scalars['Boolean']>;
};

export type UpdatePartnerRoleResult = PartnerRole | AlreadyExists | ErrorResult;

/** An input for updating an account cash forecast offset rule for use in deal budgets. */
export type UpdatePipelineAccountOffsetInput = {
  id: Scalars['Int'];
  offset?: Maybe<Scalars['Int']>;
  fkGlAccount?: Maybe<Scalars['Int']>;
  fkEventOffsetMilestone?: Maybe<Scalars['Int']>;
  fkCustomFilter?: Maybe<Scalars['Int']>;
};

/** An input for updating an account contingency categorization. */
export type UpdatePipelineContingencyCategoryInput = {
  id: Scalars['Int'];
  fkGlAccount?: Maybe<Scalars['Int']>;
  fkContingencyCategory?: Maybe<Scalars['Int']>;
};

/** An input for updating a general cash forecast offset rule for use in deal budgets. */
export type UpdatePipelineGeneralOffsetInput = {
  id: Scalars['Int'];
  offset?: Maybe<Scalars['Int']>;
  fkEventOffsetMilestone?: Maybe<Scalars['Int']>;
  fkCustomFilter?: Maybe<Scalars['Int']>;
};

/** An input object used for updating a pipeline. */
export type UpdatePipelineInput = {
  /** The id of the pipeline */
  id: Scalars['Int'];
  /** The name of the pipeline */
  name?: Maybe<Scalars['String']>;
  /** The description of the pipeline. */
  description?: Maybe<Scalars['String']>;
  /** The default account tree for this pipeline. */
  fkGlAccountTreeDefault?: Maybe<Scalars['Int']>;
  /** The debt account for this pipeline. */
  fkGlAccountDebt?: Maybe<Scalars['Int']>;
  /** The interest account for this pipeline. */
  fkGlAccountInterest?: Maybe<Scalars['Int']>;
  /** The interest account for this pipeline. */
  fkGlAccountEndInterest?: Maybe<Scalars['Int']>;
  /** The debt account for this pipeline. */
  fkGlAccountEquity?: Maybe<Scalars['Int']>;
  /** The default source for this pipeline. */
  fkFirmDefaultSource?: Maybe<Scalars['Int']>;
  /** The first residential TCO event for this pipeline. */
  fkEventFirstResidentialTco?: Maybe<Scalars['Int']>;
  /** The first residential TCO event for this pipeline. */
  fkEventLastResidentialTco?: Maybe<Scalars['Int']>;
  /** The partner role for the General Contractor. */
  fkPartnerRoleGc?: Maybe<Scalars['Int']>;
  /** The account ID for the GC Contract. */
  fkGlAccountGeneralContractor?: Maybe<Scalars['Int']>;
  /** Whether or not a pipeline is disabled. */
  isDisabled?: Maybe<Scalars['Boolean']>;
  /** The default cost source for Change Events, Potential Change Orders, and Change Orders. */
  fkGlAccountDefaultCostSource?: Maybe<Scalars['Int']>;
};

/** An input object used for updating the role of a pipeline member. */
export type UpdatePipelineMemberRoleInput = {
  /** The id of a member of a pipeline. */
  memberId: Scalars['Int'];
  memberType: DealMemberEnum;
  /** The id of the pipeline to assign the users/groups to. */
  fkPipeline: Scalars['Int'];
  /** The id of the role that the specified members should be assigned to. */
  fkRole: Scalars['Int'];
};

export type UpdatePipelineMemberRoleResult = PipelineRoleUser | PipelineRoleGroup | ErrorResult;

/** An input object used for adding a deal to a portfolio. */
export type UpdatePortfolioDealsInput = {
  /** The ids of the deals to add to the specified portfolio. */
  ids: Array<Scalars['Int']>;
  /** The id of the portfolio to add the deals to. */
  fkPortfolio: Scalars['Int'];
};

export type UpdatePortfolioDealsResult = Portfolio | ErrorResult;

/** An input object used for updating a portfolio */
export type UpdatePortfolioInput = {
  /** The id of the portfolio being updated. */
  id: Scalars['Int'];
  /** The name of the portfolio being updated. */
  name?: Maybe<Scalars['String']>;
  /** The description of the update. */
  description?: Maybe<Scalars['String']>;
  /** Whether the Portfolio is private. */
  isPrivate?: Maybe<Scalars['Boolean']>;
  /** Whether members of the organization can edit the Portfolio, if the Portfolio is public. */
  publicCanEdit?: Maybe<Scalars['Boolean']>;
  /** Whether or not the portfolio has a specific set of deals assigned to it or a dynamic group of deals. */
  isDynamic?: Maybe<Scalars['Boolean']>;
};

/** An input object used for updating the role of a portfolio member. */
export type UpdatePortfolioMemberRoleInput = {
  /** The id of a member of a portfolio. */
  memberId: Scalars['Int'];
  memberType: PortfolioMemberEnum;
  /** The id of the portfolio to assign the users/groups to. */
  fkPortfolio: Scalars['Int'];
  /** The id of the role that the specified members should be assigned to. */
  fkRole: Scalars['Int'];
};

export type UpdatePortfolioMemberRoleResult = PortfolioRoleUser | PortfolioRoleGroup | ErrorResult;

/** An input object used for updating the order of a portfolio view column. */
export type UpdatePortfolioViewColumnInput = {
  /** The id of the column to update. */
  id: Scalars['Int'];
  /** The type of portfolio view column. */
  type: PortfolioViewColumnTypeEnum;
  /** The new order id for the column. */
  orderId: Scalars['Decimal'];
};

/** An input object used for creating a portfolio view */
export type UpdatePortfolioViewInput = {
  /** The id of the portfolio view. */
  id: Scalars['Int'];
  /** The name for the portfolio view */
  name?: Maybe<Scalars['String']>;
  /** The order id for the portfolio view */
  orderId?: Maybe<Scalars['Decimal']>;
  /** The analysis type for the portfolio view */
  fkAnalysisType?: Maybe<Scalars['Int']>;
  /** Whether or not to pull analysisEvents for the portfolio deal attributes */
  showAnalysisEvents?: Maybe<Scalars['Boolean']>;
  /** The sort model for the portfolio view */
  sortModel?: Maybe<Scalars['String']>;
  /** The sort model for the portfolio view */
  filterModel?: Maybe<Scalars['String']>;
  /** The group row columns for the portfolio view */
  groupRowColumns?: Maybe<Scalars['String']>;
};

/** An input object used for updating a potential change order. */
export type UpdatePotentialChangeOrderInput = {
  /** The id of the potential change order. */
  id: Scalars['Int'];
  /** The title of the potential change order. */
  title?: Maybe<Scalars['String']>;
  /** The number of the potential change order. */
  number?: Maybe<Scalars['Int']>;
  /** The total of the potential change order according to the GC. */
  total?: Maybe<Scalars['Decimal']>;
  /** The schedule impact of the potential change order according to the GC (in # of days). */
  scheduleImpact?: Maybe<Scalars['Int']>;
  /** The total of the potential change order according to the owner. */
  ownerTotal?: Maybe<Scalars['Decimal']>;
  /** The schedule impact of the potential change order according to the owner (in # of days). */
  ownerScheduleImpact?: Maybe<Scalars['Int']>;
  /** The id of the status of the potential change order. */
  fkChangeOrderStatus?: Maybe<Scalars['Int']>;
  /** The id of the type of the potential change order. */
  fkPotentialChangeOrderType?: Maybe<Scalars['Int']>;
  /** The id of the parent change order of the potential change order. */
  fkChangeOrderPackage?: Maybe<Scalars['Int']>;
  /** Whether or not a potential change order has been executed. */
  isExecuted?: Maybe<Scalars['Boolean']>;
  /** Wether or not the schedule impact is excusable. */
  isScheduleImpactExcusable?: Maybe<Scalars['Boolean']>;
  /** The id of the cost account that is the cost source for this potential change order. */
  fkGlAccountCostSource?: Maybe<Scalars['Int']>;
};

/** An input object used for updating a deal event. */
export type UpdatePublishedDealEventInput = {
  /** The event to be added to this deal. */
  fkEvent: Scalars['Int'];
  /** The id of the deal to add this event to. */
  fkDeal: Scalars['Int'];
  /** The id of the published schedule instance to add this event to. */
  fkPublishedInstanceSchedule: Scalars['Int'];
  /** The start date of the event */
  startDate?: Maybe<Scalars['DateTime']>;
  /** The end date of the event */
  endDate?: Maybe<Scalars['DateTime']>;
  /** If the event is has actually occurred or not. */
  isComplete?: Maybe<Scalars['Boolean']>;
};

export type UpdatePublishedDealEventResult = PublishedDealEvent | PublishedDealEventAlreadyExists;

/** An input object used for creating a published schedule instance */
export type UpdatePublishedScheduleInstanceInput = {
  /** The id of the published schedule instance. */
  id: Scalars['Int'];
  /** A brief description of the published instance */
  description?: Maybe<Scalars['String']>;
};

/** An input object used for updating a revision detail. */
export type UpdateRevisionAccountInput = {
  /** The identifier of this revision detail. */
  id: Scalars['Int'];
  /** The account that this revision adjustment applies to. */
  fkGlAccount: Scalars['Int'];
  /** The amount by which the specified account is being adjusted. */
  amount: Scalars['Decimal'];
  /** If the budget is pursuit or not. */
  isPursuit: Scalars['Boolean'];
};

/** An input object used for updating a budget revision. */
export type UpdateRevisionInput = {
  /** The identifier of the revision. */
  id: Scalars['Int'];
  /** The name of the revision. */
  name?: Maybe<Scalars['String']>;
  /** The date that the revision was approved. */
  approvedDate?: Maybe<Scalars['Date']>;
  /** The date that the revision was applicable. */
  applicableDate?: Maybe<Scalars['Date']>;
};

/** An input object used for updating a status update */
export type UpdateStatusUpdateInput = {
  /** The id of the status update */
  id: Scalars['Int'];
  /** The title of the status update. */
  title?: Maybe<Scalars['String']>;
  /** The summary message for this status update. */
  message: Scalars['String'];
  /** The id representing the status of the status update. */
  fkDealStatus?: Maybe<Scalars['Int']>;
  sections: Array<CreateStatusUpdateSectionInput>;
  labelIds: Array<Scalars['Int']>;
};

/** An input object used for updating a status update template */
export type UpdateStatusUpdateTemplateInput = {
  /** The id of the status update template */
  id: Scalars['Int'];
  /** The title of the status update template. */
  title?: Maybe<Scalars['String']>;
  /** The summary message for this status update template. */
  message: Scalars['String'];
  sections: Array<CreateStatusUpdateSectionInput>;
  labelIds: Array<Scalars['Int']>;
};

/** An input object used for updating a team member. */
export type UpdateTeamMemberInput = {
  /** The id representing the person the team member is. */
  fkUser: Scalars['Int'];
  /** The id representing the role of the team member. */
  fkTeamMemberRole: Scalars['Int'];
  /** The id representing the id of the deal this team member is a part of. */
  fkDeal: Scalars['Int'];
};

/** An input object used for updating a team member role. */
export type UpdateTeamMemberRoleInput = {
  /** The id of the team member role. */
  id: Scalars['Int'];
  /** The name of the role. */
  name?: Maybe<Scalars['String']>;
  /** The description of the role. */
  description?: Maybe<Scalars['String']>;
  /** Whether or not a role is disabled. */
  isDisabled?: Maybe<Scalars['Boolean']>;
};

export type UpdateTeamMemberRoleResult = TeamMemberRole | TeamMemberRoleAlreadyExists | ErrorResult;

/** An input for updating a text metric for use with an analysis. */
export type UpdateTextMetricInput = {
  id: Scalars['Int'];
  name: Scalars['String'];
  /** Whether or not a text metric is disabled. */
  isDisabled: Scalars['Boolean'];
};

export type UpdateTextMetricResult = TextMetric | TextMetricAlreadyExists | ErrorResult;

/** An input object used for updating a transaction. */
export type UpdateTransactionInput = {
  /** The id of the transaction. */
  id: Scalars['Int'];
  /** A short description of the transaction. */
  description: Scalars['String'];
  /** The id of the account that this transaction is for. */
  fkGlAccount: Scalars['Int'];
  /** The amount of the transaction. */
  amount?: Maybe<Scalars['Decimal']>;
  milestoneAdjustmentStart?: Maybe<Scalars['Int']>;
  milestoneAdjustmentEnd?: Maybe<Scalars['Int']>;
  /** The id of the event used to denote the start of this transaction. Only provide this if you are not providing a start date. */
  fkDealEventStart?: Maybe<Scalars['Int']>;
  /** The id of the event used to denote the end of this transaction. Only provide this if you are not providing an end date. */
  fkDealEventEnd?: Maybe<Scalars['Int']>;
  /** The date denoting the end of the transaction. Only provide this if you are not providing an end event. */
  periodEndDateEnd?: Maybe<Scalars['Date']>;
  /** The date denoting the start of the transaction. Only provide this if you are not providing a start event. */
  periodEndDateStart?: Maybe<Scalars['Date']>;
  /** A true value indicates that the start date of the transaction should be derived from the start event's start date. A false value indicates that the end date should be used instead. */
  fkDealEventStartUsesStartDate?: Maybe<Scalars['Boolean']>;
  /** A true value indicates that the end date of the transaction should be derived from the end event's start date. A false value indicates that the end date should be used instead. */
  fkDealEventEndUsesStartDate?: Maybe<Scalars['Boolean']>;
  /** The id of the vendor that the transaction is associated with. */
  fkFirm?: Maybe<Scalars['Int']>;
  /** The id of the contract that the transaction is associated with. */
  fkContract?: Maybe<Scalars['Int']>;
  /** The id of the distribution type of the transaction. */
  fkDistributionType: Scalars['Int'];
  /** The id of the Default Forecast Rule that created this transaction, if any. */
  fkForecastRule?: Maybe<Scalars['Int']>;
  /** A flag denoting if this tranasction should be marked as refundable. */
  isRefundable: Scalars['Boolean'];
  /** A flag denoting if this tranasction has cash payment overrides. */
  hasCashPaymentOverride: Scalars['Boolean'];
  /** A flag denoting if this tranasction is an override. */
  isOverride: Scalars['Boolean'];
};

export type UpdateTransactionWithDetailsInput = {
  /** A transaction input object. */
  transaction: UpdateTransactionInput;
  /** A list of transaction details. */
  details: Array<CreateTransactionDetailInput>;
};


/** User */
export type User = Actor & {
  __typename?: 'User';
  /** When the user was created. */
  createdAt: Scalars['DateTimeOffset'];
  /** The id of the actor. */
  displayName: Scalars['String'];
  /** The id of the actor. */
  displayNameAlt: Scalars['String'];
  email: Scalars['String'];
  firstName: Scalars['String'];
  /** The id of the actor. */
  id: Scalars['Int'];
  lastName: Scalars['String'];
  /** A list of organizations and that the user is assigned to. */
  organizations?: Maybe<Array<Organization>>;
  /** When the user was last updated. */
  updatedAt: Scalars['DateTimeOffset'];
  username?: Maybe<Scalars['String']>;
};

/** Vendor */
export type Vendor = {
  __typename?: 'Vendor';
  /** When the object was created. */
  createdAt: Scalars['DateTimeOffset'];
  createdBy: Actor;
  /** When the object was deleted. */
  deletedAt?: Maybe<Scalars['DateTimeOffset']>;
  deletedBy?: Maybe<Actor>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  name: Scalars['String'];
  /** When the object was last updated. */
  updatedAt: Scalars['DateTimeOffset'];
  updatedBy: Actor;
};

export type CreateDealVariables = Exact<{
  deal: CreateDealInput;
}>;


export type CreateDeal = (
  { __typename?: 'RealTraxMutation' }
  & { createDeal?: Maybe<(
    { __typename: 'Deal' }
    & DealFragment
  ) | (
    { __typename: 'DealAlreadyExists' }
    & ErrorFragment_DealAlreadyExists_
    & DealAlreadyExistsFragment
  )> }
);

export type DealFragment = (
  { __typename?: 'Deal' }
  & Pick<Deal, 'id' | 'name'>
);

export type DealAlreadyExistsFragment = (
  { __typename?: 'DealAlreadyExists' }
  & { deal?: Maybe<(
    { __typename?: 'Deal' }
    & Pick<Deal, 'id' | 'name'>
  )> }
);

export type CreatePortfolioVariables = Exact<{
  portfolio: CreatePortfolioInput;
}>;


export type CreatePortfolio = (
  { __typename?: 'RealTraxMutation' }
  & { createPortfolio?: Maybe<(
    { __typename: 'Portfolio' }
    & PortfolioFragment
  ) | (
    { __typename: 'PortfolioAlreadyExists' }
    & ErrorFragment_PortfolioAlreadyExists_
    & PortfolioAlreadyExistsFragment
  )> }
);

export type PortfolioFragment = (
  { __typename?: 'Portfolio' }
  & Pick<Portfolio, 'id' | 'name' | 'description'>
);

export type PortfolioAlreadyExistsFragment = (
  { __typename?: 'PortfolioAlreadyExists' }
  & { portfolio?: Maybe<(
    { __typename?: 'Portfolio' }
    & Pick<Portfolio, 'id' | 'name' | 'description'>
  )> }
);

export type UpdatePortfolioVariables = Exact<{
  portfolio: UpdatePortfolioInput;
}>;


export type UpdatePortfolio = (
  { __typename?: 'RealTraxMutation' }
  & { updatePortfolio?: Maybe<(
    { __typename?: 'Portfolio' }
    & PortfolioFragment
  )> }
);

export type UpdateDynamicPortfolioFiltersVariables = Exact<{
  id: Scalars['Int'];
  filterGroups: Array<CreatePortfolioFilterGroupInput> | CreatePortfolioFilterGroupInput;
}>;


export type UpdateDynamicPortfolioFilters = (
  { __typename?: 'RealTraxMutation' }
  & { updateDynamicPortfolioFilters?: Maybe<(
    { __typename?: 'Portfolio' }
    & Pick<Portfolio, 'id' | 'name' | 'isDynamic'>
    & { filterGroups?: Maybe<Array<(
      { __typename?: 'PortfolioFilterGroup' }
      & { clauses: Array<(
        { __typename?: 'PortfolioFilter' }
        & Pick<PortfolioFilter, 'type' | 'isAnd' | 'value'>
        & { attribute: (
          { __typename: 'DealAttribute' }
          & Pick<DealAttribute, 'id' | 'displayName' | 'columnName' | 'dataType'>
        ) | (
          { __typename: 'PartnerRole' }
          & Pick<PartnerRole, 'id' | 'name'>
        ) | (
          { __typename: 'TeamMemberRole' }
          & Pick<TeamMemberRole, 'id' | 'name'>
        ) | { __typename: 'Event' }, filter: (
          { __typename?: 'CustomFilters' }
          & Pick<CustomFilters, 'id' | 'name' | 'sign'>
        ) }
      )> }
    )>> }
  )> }
);

export type AddTeamMemberVariables = Exact<{
  teamMember: AddTeamMemberInput;
}>;


export type AddTeamMember = (
  { __typename?: 'RealTraxMutation' }
  & { addTeamMember?: Maybe<(
    { __typename: 'DealTeamMemberUser' }
    & DealTeamMemberFragment
  ) | (
    { __typename: 'AlreadyExists' }
    & ErrorFragment_AlreadyExists_
  )> }
);

export type DealTeamMemberFragment = (
  { __typename?: 'DealTeamMemberUser' }
  & { user: (
    { __typename?: 'User' }
    & Pick<User, 'id' | 'firstName' | 'lastName'>
  ), role: (
    { __typename?: 'TeamMemberRole' }
    & Pick<TeamMemberRole, 'id' | 'name' | 'description'>
  ) }
);

export type ErrorFragment_AlreadyExists_ = (
  { __typename?: 'AlreadyExists' }
  & Pick<AlreadyExists, 'message'>
);

export type ErrorFragment_AnalysisTypeAlreadyExists_ = (
  { __typename?: 'AnalysisTypeAlreadyExists' }
  & Pick<AnalysisTypeAlreadyExists, 'message'>
);

export type ErrorFragment_ChecklistAlreadyExists_ = (
  { __typename?: 'ChecklistAlreadyExists' }
  & Pick<ChecklistAlreadyExists, 'message'>
);

export type ErrorFragment_ChecklistCategoryAlreadyExists_ = (
  { __typename?: 'ChecklistCategoryAlreadyExists' }
  & Pick<ChecklistCategoryAlreadyExists, 'message'>
);

export type ErrorFragment_ChecklistItemAlreadyExists_ = (
  { __typename?: 'ChecklistItemAlreadyExists' }
  & Pick<ChecklistItemAlreadyExists, 'message'>
);

export type ErrorFragment_ConfigurableEntityAlreadyExists_ = (
  { __typename?: 'ConfigurableEntityAlreadyExists' }
  & Pick<ConfigurableEntityAlreadyExists, 'message'>
);

export type ErrorFragment_ContactAlreadyExists_ = (
  { __typename?: 'ContactAlreadyExists' }
  & Pick<ContactAlreadyExists, 'message'>
);

export type ErrorFragment_DealAlreadyExists_ = (
  { __typename?: 'DealAlreadyExists' }
  & Pick<DealAlreadyExists, 'message'>
);

export type ErrorFragment_DealChecklistAlreadyExists_ = (
  { __typename?: 'DealChecklistAlreadyExists' }
  & Pick<DealChecklistAlreadyExists, 'message'>
);

export type ErrorFragment_DealChecklistDoesNotExistType_ = (
  { __typename?: 'DealChecklistDoesNotExistType' }
  & Pick<DealChecklistDoesNotExistType, 'message'>
);

export type ErrorFragment_DealEventAlreadyExists_ = (
  { __typename?: 'DealEventAlreadyExists' }
  & Pick<DealEventAlreadyExists, 'message'>
);

export type ErrorFragment_DealStageAlreadyExists_ = (
  { __typename?: 'DealStageAlreadyExists' }
  & Pick<DealStageAlreadyExists, 'message'>
);

export type ErrorFragment_ErrorResult_ = (
  { __typename?: 'ErrorResult' }
  & Pick<ErrorResult, 'message'>
);

export type ErrorFragment_EventAlreadyExists_ = (
  { __typename?: 'EventAlreadyExists' }
  & Pick<EventAlreadyExists, 'message'>
);

export type ErrorFragment_InterestRateAlreadyExists_ = (
  { __typename?: 'InterestRateAlreadyExists' }
  & Pick<InterestRateAlreadyExists, 'message'>
);

export type ErrorFragment_MetricAlreadyExists_ = (
  { __typename?: 'MetricAlreadyExists' }
  & Pick<MetricAlreadyExists, 'message'>
);

export type ErrorFragment_PortfolioAlreadyExists_ = (
  { __typename?: 'PortfolioAlreadyExists' }
  & Pick<PortfolioAlreadyExists, 'message'>
);

export type ErrorFragment_PublishedDealEventAlreadyExists_ = (
  { __typename?: 'PublishedDealEventAlreadyExists' }
  & Pick<PublishedDealEventAlreadyExists, 'message'>
);

export type ErrorFragment_TeamMemberRoleAlreadyExists_ = (
  { __typename?: 'TeamMemberRoleAlreadyExists' }
  & Pick<TeamMemberRoleAlreadyExists, 'message'>
);

export type ErrorFragment_TextMetricAlreadyExists_ = (
  { __typename?: 'TextMetricAlreadyExists' }
  & Pick<TextMetricAlreadyExists, 'message'>
);

export type ErrorFragment = ErrorFragment_AlreadyExists_ | ErrorFragment_AnalysisTypeAlreadyExists_ | ErrorFragment_ChecklistAlreadyExists_ | ErrorFragment_ChecklistCategoryAlreadyExists_ | ErrorFragment_ChecklistItemAlreadyExists_ | ErrorFragment_ConfigurableEntityAlreadyExists_ | ErrorFragment_ContactAlreadyExists_ | ErrorFragment_DealAlreadyExists_ | ErrorFragment_DealChecklistAlreadyExists_ | ErrorFragment_DealChecklistDoesNotExistType_ | ErrorFragment_DealEventAlreadyExists_ | ErrorFragment_DealStageAlreadyExists_ | ErrorFragment_ErrorResult_ | ErrorFragment_EventAlreadyExists_ | ErrorFragment_InterestRateAlreadyExists_ | ErrorFragment_MetricAlreadyExists_ | ErrorFragment_PortfolioAlreadyExists_ | ErrorFragment_PublishedDealEventAlreadyExists_ | ErrorFragment_TeamMemberRoleAlreadyExists_ | ErrorFragment_TextMetricAlreadyExists_;

export type CreateTeamMemberRoleVariables = Exact<{
  teamMemberRole: CreateTeamMemberRoleInput;
}>;


export type CreateTeamMemberRole = (
  { __typename?: 'RealTraxMutation' }
  & { createTeamMemberRole?: Maybe<(
    { __typename: 'TeamMemberRole' }
    & Pick<TeamMemberRole, 'id' | 'name' | 'description'>
  ) | (
    { __typename: 'TeamMemberRoleAlreadyExists' }
    & Pick<TeamMemberRoleAlreadyExists, 'message'>
    & { role?: Maybe<(
      { __typename?: 'TeamMemberRole' }
      & Pick<TeamMemberRole, 'id' | 'name'>
    )> }
  ) | (
    { __typename: 'ErrorResult' }
    & Pick<ErrorResult, 'message'>
  )> }
);

export type UpdateTeamMemberRoleVariables = Exact<{
  teamMemberRole: UpdateTeamMemberRoleInput;
}>;


export type UpdateTeamMemberRole = (
  { __typename?: 'RealTraxMutation' }
  & { updateTeamMemberRole?: Maybe<(
    { __typename: 'TeamMemberRole' }
    & Pick<TeamMemberRole, 'id' | 'name' | 'description'>
  ) | (
    { __typename: 'TeamMemberRoleAlreadyExists' }
    & Pick<TeamMemberRoleAlreadyExists, 'message'>
    & { role?: Maybe<(
      { __typename?: 'TeamMemberRole' }
      & Pick<TeamMemberRole, 'id' | 'name'>
    )> }
  ) | (
    { __typename: 'ErrorResult' }
    & Pick<ErrorResult, 'message'>
  )> }
);

export type DeleteTeamMemberRoleVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeleteTeamMemberRole = (
  { __typename?: 'RealTraxMutation' }
  & { deleteTeamMemberRole?: Maybe<(
    { __typename: 'TeamMemberRole' }
    & Pick<TeamMemberRole, 'id' | 'name' | 'description'>
  ) | (
    { __typename: 'ErrorResult' }
    & Pick<ErrorResult, 'message'>
  )> }
);

export type CreatePartnerRoleVariables = Exact<{
  partnerRole: CreatePartnerRoleInput;
}>;


export type CreatePartnerRole = (
  { __typename?: 'RealTraxMutation' }
  & { createPartnerRole?: Maybe<(
    { __typename: 'PartnerRole' }
    & Pick<PartnerRole, 'id' | 'name' | 'description'>
  ) | (
    { __typename: 'AlreadyExists' }
    & Pick<AlreadyExists, 'message'>
  ) | (
    { __typename: 'ErrorResult' }
    & Pick<ErrorResult, 'message'>
  )> }
);

export type UpdatePartnerRoleVariables = Exact<{
  partnerRole: UpdatePartnerRoleInput;
}>;


export type UpdatePartnerRole = (
  { __typename?: 'RealTraxMutation' }
  & { updatePartnerRole?: Maybe<(
    { __typename: 'PartnerRole' }
    & Pick<PartnerRole, 'id' | 'name' | 'description'>
  ) | (
    { __typename: 'AlreadyExists' }
    & Pick<AlreadyExists, 'message'>
  ) | (
    { __typename: 'ErrorResult' }
    & Pick<ErrorResult, 'message'>
  )> }
);

export type DeletePartnerRoleVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeletePartnerRole = (
  { __typename?: 'RealTraxMutation' }
  & { deletePartnerRole?: Maybe<(
    { __typename: 'PartnerRole' }
    & Pick<PartnerRole, 'id' | 'name' | 'description'>
  ) | (
    { __typename: 'ErrorResult' }
    & Pick<ErrorResult, 'message'>
  )> }
);

export type CreateContactVariables = Exact<{
  contact: CreateContactInput;
}>;


export type CreateContact = (
  { __typename?: 'RealTraxMutation' }
  & { createContact?: Maybe<(
    { __typename: 'Contact' }
    & ContactFragment
  ) | (
    { __typename: 'ContactAlreadyExists' }
    & ErrorFragment_ContactAlreadyExists_
    & ContactAlreadyExistsFragment
  )> }
);

export type ContactFragment = (
  { __typename?: 'Contact' }
  & Pick<Contact, 'id' | 'firstName' | 'lastName' | 'title' | 'email' | 'cellPhone' | 'notes' | 'isPrimary'>
  & { firm?: Maybe<(
    { __typename?: 'Firm' }
    & Pick<Firm, 'id' | 'name'>
  )> }
);

export type ContactAlreadyExistsFragment = (
  { __typename?: 'ContactAlreadyExists' }
  & { contact?: Maybe<(
    { __typename?: 'Contact' }
    & Pick<Contact, 'id' | 'firstName' | 'lastName' | 'email'>
  )> }
);

export type CreateFirmVariables = Exact<{
  firm: CreateFirmInput;
}>;


export type CreateFirm = (
  { __typename?: 'RealTraxMutation' }
  & { createFirm?: Maybe<(
    { __typename: 'Firm' }
    & Pick<Firm, 'id' | 'name'>
    & { status?: Maybe<(
      { __typename?: 'CrmStatus' }
      & Pick<CrmStatus, 'id' | 'name'>
    )>, type?: Maybe<(
      { __typename?: 'FirmType' }
      & Pick<FirmType, 'id' | 'name'>
    )>, headquartersMsa?: Maybe<(
      { __typename?: 'Msa' }
      & Pick<Msa, 'id' | 'name'>
    )>, regions: Array<(
      { __typename?: 'FirmRegion' }
      & Pick<FirmRegion, 'id' | 'name' | 'isPrimaryRegion'>
    )>, productAppetite?: Maybe<(
      { __typename?: 'InvestmentType' }
      & Pick<InvestmentType, 'id' | 'name'>
    )>, keyContacts: Array<(
      { __typename?: 'Contact' }
      & ContactFragment
    )>, relationshipLead?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'firstName' | 'lastName' | 'displayName'>
    )> }
  )> }
);

export type UpdateFirmVariables = Exact<{
  firm: UpdateFirmInput;
}>;


export type UpdateFirm = (
  { __typename?: 'RealTraxMutation' }
  & { updateFirm?: Maybe<(
    { __typename: 'Firm' }
    & Pick<Firm, 'id' | 'name'>
    & { status?: Maybe<(
      { __typename?: 'CrmStatus' }
      & Pick<CrmStatus, 'id' | 'name'>
    )>, type?: Maybe<(
      { __typename?: 'FirmType' }
      & Pick<FirmType, 'id' | 'name'>
    )>, headquartersMsa?: Maybe<(
      { __typename?: 'Msa' }
      & Pick<Msa, 'id' | 'name'>
    )>, regions: Array<(
      { __typename?: 'FirmRegion' }
      & Pick<FirmRegion, 'id' | 'name' | 'isPrimaryRegion'>
    )>, productAppetite?: Maybe<(
      { __typename?: 'InvestmentType' }
      & Pick<InvestmentType, 'id' | 'name'>
    )>, keyContacts: Array<(
      { __typename?: 'Contact' }
      & ContactFragment
    )>, relationshipLead?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'firstName' | 'lastName' | 'displayName'>
    )> }
  )> }
);

export type DeleteFirmVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeleteFirm = (
  { __typename?: 'RealTraxMutation' }
  & { deleteFirm?: Maybe<(
    { __typename: 'Firm' }
    & Pick<Firm, 'id' | 'name'>
  ) | (
    { __typename: 'ErrorResult' }
    & Pick<ErrorResult, 'message'>
  )> }
);

export type CreateFirmRegionVariables = Exact<{
  firmRegion: CreateFirmRegionInput;
}>;


export type CreateFirmRegion = (
  { __typename?: 'RealTraxMutation' }
  & { createFirmRegion?: Maybe<(
    { __typename: 'FirmRegion' }
    & Pick<FirmRegion, 'id' | 'name' | 'isPrimaryRegion'>
  )> }
);

export type UpdateFirmRegionVariables = Exact<{
  firmRegion: UpdateFirmRegionInput;
}>;


export type UpdateFirmRegion = (
  { __typename?: 'RealTraxMutation' }
  & { updateFirmRegion?: Maybe<(
    { __typename: 'FirmRegion' }
    & Pick<FirmRegion, 'id' | 'name' | 'isPrimaryRegion'>
  ) | (
    { __typename: 'ErrorResult' }
    & Pick<ErrorResult, 'message'>
  ) | (
    { __typename: 'AlreadyExists' }
    & Pick<AlreadyExists, 'message'>
  )> }
);

export type DeleteFirmRegionVariables = Exact<{
  fkRegion: Scalars['Int'];
  fkFirm: Scalars['Int'];
}>;


export type DeleteFirmRegion = (
  { __typename?: 'RealTraxMutation' }
  & { deleteFirmRegion?: Maybe<(
    { __typename: 'FirmRegion' }
    & Pick<FirmRegion, 'id' | 'name' | 'isPrimaryRegion'>
  ) | (
    { __typename: 'ErrorResult' }
    & Pick<ErrorResult, 'message'>
  )> }
);

export type CreateInteractionVariables = Exact<{
  interaction: CreateInteractionInput;
}>;


export type CreateInteraction = (
  { __typename?: 'RealTraxMutation' }
  & { createInteraction?: Maybe<(
    { __typename?: 'Interaction' }
    & Pick<Interaction, 'id' | 'name' | 'interactionDate' | 'notes'>
    & { type: (
      { __typename?: 'InteractionType' }
      & Pick<InteractionType, 'id' | 'name'>
    ), attendees: Array<(
      { __typename?: 'InteractionAttendee' }
      & Pick<InteractionAttendee, 'id' | 'name' | 'type'>
    )>, deals: Array<(
      { __typename?: 'Deal' }
      & Pick<Deal, 'id' | 'name'>
    )> }
  )> }
);

export type UpdateInteractionVariables = Exact<{
  interaction: UpdateInteractionInput;
}>;


export type UpdateInteraction = (
  { __typename?: 'RealTraxMutation' }
  & { updateInteraction?: Maybe<(
    { __typename?: 'Interaction' }
    & Pick<Interaction, 'id' | 'name' | 'interactionDate' | 'notes'>
    & { type: (
      { __typename?: 'InteractionType' }
      & Pick<InteractionType, 'id' | 'name'>
    ), attendees: Array<(
      { __typename?: 'InteractionAttendee' }
      & Pick<InteractionAttendee, 'id' | 'name' | 'type'>
    )>, deals: Array<(
      { __typename?: 'Deal' }
      & Pick<Deal, 'id' | 'name'>
    )> }
  )> }
);

export type DeleteInteractionVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeleteInteraction = (
  { __typename?: 'RealTraxMutation' }
  & { deleteInteraction?: Maybe<(
    { __typename: 'Interaction' }
    & Pick<Interaction, 'id' | 'name' | 'interactionDate' | 'notes'>
    & { type: (
      { __typename?: 'InteractionType' }
      & Pick<InteractionType, 'id' | 'name'>
    ), attendees: Array<(
      { __typename?: 'InteractionAttendee' }
      & Pick<InteractionAttendee, 'id' | 'name' | 'type'>
    )>, deals: Array<(
      { __typename?: 'Deal' }
      & Pick<Deal, 'id' | 'name'>
    )> }
  )> }
);

export type AssignContactsVariables = Exact<{
  assignment: ContactAssignmentInput;
}>;


export type AssignContacts = (
  { __typename?: 'RealTraxMutation' }
  & { assignContacts?: Maybe<Array<(
    { __typename?: 'Contact' }
    & Pick<Contact, 'id' | 'firstName' | 'lastName'>
  )>> }
);

export type UnassignContactsVariables = Exact<{
  assignment: ContactAssignmentInput;
}>;


export type UnassignContacts = (
  { __typename?: 'RealTraxMutation' }
  & { unassignContacts?: Maybe<Array<(
    { __typename?: 'Contact' }
    & Pick<Contact, 'id' | 'firstName' | 'lastName'>
  )>> }
);

export type UpdateTeamMemberVariables = Exact<{
  teamMember: UpdateTeamMemberInput;
}>;


export type UpdateTeamMember = (
  { __typename?: 'RealTraxMutation' }
  & { updateTeamMember?: Maybe<(
    { __typename?: 'DealTeamMemberUser' }
    & { user: (
      { __typename?: 'User' }
      & Pick<User, 'id' | 'firstName' | 'lastName'>
    ), role: (
      { __typename?: 'TeamMemberRole' }
      & Pick<TeamMemberRole, 'id' | 'name'>
    ) }
  )> }
);

export type UpdateTeamMembersVariables = Exact<{
  dealId: Scalars['Int'];
  roleId: Scalars['Int'];
  userIds: Array<Scalars['Int']> | Scalars['Int'];
}>;


export type UpdateTeamMembers = (
  { __typename?: 'RealTraxMutation' }
  & { updateTeamMembers?: Maybe<Array<Maybe<(
    { __typename?: 'DealTeamMemberUser' }
    & { user: (
      { __typename?: 'User' }
      & Pick<User, 'id' | 'firstName' | 'lastName'>
    ), role: (
      { __typename?: 'TeamMemberRole' }
      & Pick<TeamMemberRole, 'id' | 'name'>
    ) }
  )>>> }
);

export type RemoveTeamMemberVariables = Exact<{
  teamMember: RemoveTeamMemberInput;
}>;


export type RemoveTeamMember = (
  { __typename?: 'RealTraxMutation' }
  & { removeTeamMember?: Maybe<(
    { __typename?: 'DealTeamMemberUser' }
    & { user: (
      { __typename?: 'User' }
      & Pick<User, 'id' | 'firstName' | 'lastName'>
    ), role: (
      { __typename?: 'TeamMemberRole' }
      & Pick<TeamMemberRole, 'id' | 'name'>
    ) }
  )> }
);

export type AddDealPartnerVariables = Exact<{
  partner: AddDealPartnerInput;
}>;


export type AddDealPartner = (
  { __typename?: 'RealTraxMutation' }
  & { addDealPartner?: Maybe<(
    { __typename: 'DealPartner' }
    & DealPartnerFragment
  ) | (
    { __typename: 'AlreadyExists' }
    & ErrorFragment_AlreadyExists_
  )> }
);

export type UpdateDealPartnerVariables = Exact<{
  partner: UpdateDealPartnerInput;
}>;


export type UpdateDealPartner = (
  { __typename?: 'RealTraxMutation' }
  & { updateDealPartner?: Maybe<(
    { __typename?: 'DealPartner' }
    & DealPartnerFragment
  )> }
);

export type UpdateDealPartnersVariables = Exact<{
  dealId: Scalars['Int'];
  roleId: Scalars['Int'];
  firmIds: Array<Scalars['Int']> | Scalars['Int'];
}>;


export type UpdateDealPartners = (
  { __typename?: 'RealTraxMutation' }
  & { updateDealPartners?: Maybe<Array<Maybe<(
    { __typename?: 'DealPartner' }
    & { firm?: Maybe<(
      { __typename?: 'Firm' }
      & Pick<Firm, 'id' | 'name'>
    )>, contact?: Maybe<(
      { __typename?: 'Contact' }
      & Pick<Contact, 'id' | 'firstName' | 'lastName'>
    )>, role: (
      { __typename?: 'PartnerRole' }
      & Pick<PartnerRole, 'id' | 'name' | 'description'>
    ) }
  )>>> }
);

export type RemoveDealPartnerVariables = Exact<{
  partner: RemoveDealPartnerInput;
}>;


export type RemoveDealPartner = (
  { __typename?: 'RealTraxMutation' }
  & { removeDealPartner?: Maybe<(
    { __typename?: 'DealPartner' }
    & DealPartnerFragment
  )> }
);

export type DealPartnerFragment = (
  { __typename?: 'DealPartner' }
  & Pick<DealPartner, 'id'>
  & { firm?: Maybe<(
    { __typename?: 'Firm' }
    & Pick<Firm, 'id' | 'name'>
  )>, contact?: Maybe<(
    { __typename?: 'Contact' }
    & Pick<Contact, 'id' | 'firstName' | 'lastName'>
  )>, role: (
    { __typename?: 'PartnerRole' }
    & Pick<PartnerRole, 'id' | 'name' | 'description'>
  ) }
);

export type UpdateContactVariables = Exact<{
  contact: UpdateContactInput;
}>;


export type UpdateContact = (
  { __typename?: 'RealTraxMutation' }
  & { updateContact?: Maybe<(
    { __typename: 'Contact' }
    & ContactFragment
  ) | (
    { __typename: 'ContactAlreadyExists' }
    & ErrorFragment_ContactAlreadyExists_
    & ContactAlreadyExistsFragment
  )> }
);

export type DeleteContactVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeleteContact = (
  { __typename?: 'RealTraxMutation' }
  & { deleteContact?: Maybe<(
    { __typename: 'Contact' }
    & ContactFragment
  ) | (
    { __typename: 'ErrorResult' }
    & Pick<ErrorResult, 'message'>
  )> }
);

export type UpdateDealChecklistitemVariables = Exact<{
  checklistItem: UpdateDealChecklistItemInput;
}>;


export type UpdateDealChecklistitem = (
  { __typename?: 'RealTraxMutation' }
  & { updateDealChecklistItem?: Maybe<(
    { __typename?: 'DealChecklistItem' }
    & Pick<DealChecklistItem, 'id' | 'name' | 'dueDate'>
    & { status: (
      { __typename?: 'ChecklistItemStatus' }
      & Pick<ChecklistItemStatus, 'id' | 'name'>
    ), assignee?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'firstName' | 'lastName'>
    )> }
  )> }
);

export type CreateChecklistVariables = Exact<{
  checklist: CreateChecklistInput;
}>;


export type CreateChecklist = (
  { __typename?: 'RealTraxMutation' }
  & { createChecklist?: Maybe<(
    { __typename?: 'Checklist' }
    & ChecklistFragment
  ) | (
    { __typename?: 'ChecklistAlreadyExists' }
    & ErrorFragment_ChecklistAlreadyExists_
    & ChecklistAlreadyExistsFragment
  )> }
);

export type ChecklistFragment = (
  { __typename?: 'Checklist' }
  & Pick<Checklist, 'id' | 'name'>
);

export type ChecklistAlreadyExistsFragment = (
  { __typename?: 'ChecklistAlreadyExists' }
  & { checklist?: Maybe<(
    { __typename?: 'Checklist' }
    & Pick<Checklist, 'id' | 'name'>
  )> }
);

export type UpdateChecklistVariables = Exact<{
  checklist: UpdateChecklistInput;
}>;


export type UpdateChecklist = (
  { __typename?: 'RealTraxMutation' }
  & { updateChecklist?: Maybe<(
    { __typename?: 'Checklist' }
    & ChecklistFragment
  ) | (
    { __typename?: 'ChecklistAlreadyExists' }
    & ErrorFragment_ChecklistAlreadyExists_
    & ChecklistAlreadyExistsFragment
  )> }
);

export type AddChecklistToDealVariables = Exact<{
  dealChecklist: DealChecklistInput;
}>;


export type AddChecklistToDeal = (
  { __typename?: 'RealTraxMutation' }
  & { addChecklist?: Maybe<(
    { __typename?: 'DealChecklist' }
    & DealChecklistFragment
  ) | (
    { __typename?: 'DealChecklistAlreadyExists' }
    & ErrorFragment_DealChecklistAlreadyExists_
    & DealChecklistAlreadyExistsFragment
  )> }
);

export type DealChecklistFragment = (
  { __typename?: 'DealChecklist' }
  & Pick<DealChecklist, 'id' | 'name'>
);

export type DealChecklistAlreadyExistsFragment = (
  { __typename?: 'DealChecklistAlreadyExists' }
  & { dealChecklist?: Maybe<(
    { __typename?: 'DealChecklist' }
    & Pick<DealChecklist, 'id' | 'name'>
  )> }
);

export type RemoveChecklistFromDealVariables = Exact<{
  dealChecklist: DealChecklistInput;
}>;


export type RemoveChecklistFromDeal = (
  { __typename?: 'RealTraxMutation' }
  & { removeChecklist?: Maybe<(
    { __typename?: 'DealChecklist' }
    & DealChecklistFragment
  ) | (
    { __typename?: 'DealChecklistDoesNotExistType' }
    & ErrorFragment_DealChecklistDoesNotExistType_
  )> }
);

export type CreateChecklistItemVariables = Exact<{
  checklistItem: CreateChecklistItemInput;
}>;


export type CreateChecklistItem = (
  { __typename?: 'RealTraxMutation' }
  & { createChecklistItem?: Maybe<(
    { __typename: 'ChecklistItem' }
    & ChecklistItemFragment
  ) | (
    { __typename: 'ChecklistItemAlreadyExists' }
    & ErrorFragment_ChecklistItemAlreadyExists_
    & ChecklistItemAlreadyExistsFragment
  )> }
);

export type ChecklistItemFragment = (
  { __typename?: 'ChecklistItem' }
  & Pick<ChecklistItem, 'id' | 'name'>
);

export type ChecklistItemAlreadyExistsFragment = (
  { __typename?: 'ChecklistItemAlreadyExists' }
  & { checklistItem?: Maybe<(
    { __typename?: 'ChecklistItem' }
    & Pick<ChecklistItem, 'id' | 'name'>
  )> }
);

export type UpdateChecklistItemVariables = Exact<{
  checklistItem: UpdateChecklistItemInput;
}>;


export type UpdateChecklistItem = (
  { __typename?: 'RealTraxMutation' }
  & { updateChecklistItem?: Maybe<(
    { __typename?: 'ChecklistItem' }
    & Pick<ChecklistItem, 'id' | 'name'>
  )> }
);

export type DeleteChecklistVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeleteChecklist = (
  { __typename?: 'RealTraxMutation' }
  & { deleteChecklist?: Maybe<(
    { __typename?: 'Checklist' }
    & Pick<Checklist, 'id' | 'name'>
  )> }
);

export type DeleteChecklistItemVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeleteChecklistItem = (
  { __typename?: 'RealTraxMutation' }
  & { deleteChecklistItem?: Maybe<(
    { __typename?: 'ChecklistItem' }
    & Pick<ChecklistItem, 'id' | 'name'>
  )> }
);

export type CreateStatusUpdateVariables = Exact<{
  statusUpdate: CreateStatusUpdateInput;
}>;


export type CreateStatusUpdate = (
  { __typename?: 'RealTraxMutation' }
  & { createStatusUpdate?: Maybe<(
    { __typename?: 'DealStatusUpdate' }
    & Pick<DealStatusUpdate, 'id' | 'title' | 'message' | 'fkDeal'>
    & { status: (
      { __typename?: 'DealStatus' }
      & Pick<DealStatus, 'id' | 'name' | 'color'>
    ) }
  )> }
);

export type UpdateStatusUpdateVariables = Exact<{
  statusUpdate: UpdateStatusUpdateInput;
}>;


export type UpdateStatusUpdate = (
  { __typename?: 'RealTraxMutation' }
  & { updateStatusUpdate?: Maybe<(
    { __typename?: 'DealStatusUpdate' }
    & Pick<DealStatusUpdate, 'id' | 'title' | 'message' | 'fkDeal'>
    & { status: (
      { __typename?: 'DealStatus' }
      & Pick<DealStatus, 'id' | 'name' | 'color'>
    ) }
  )> }
);

export type DeleteStatusUpdateVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeleteStatusUpdate = (
  { __typename?: 'RealTraxMutation' }
  & { deleteStatusUpdate?: Maybe<(
    { __typename?: 'DealStatusUpdate' }
    & Pick<DealStatusUpdate, 'id' | 'title' | 'message'>
    & { status: (
      { __typename?: 'DealStatus' }
      & Pick<DealStatus, 'id' | 'name' | 'color'>
    ) }
  )> }
);

export type CreateStatusUpdateTemplateVariables = Exact<{
  statusUpdateTemplate: CreateStatusUpdateTemplateInput;
}>;


export type CreateStatusUpdateTemplate = (
  { __typename?: 'RealTraxMutation' }
  & { createStatusUpdateTemplate?: Maybe<(
    { __typename?: 'DealStatusUpdateTemplate' }
    & Pick<DealStatusUpdateTemplate, 'id' | 'title' | 'message'>
  )> }
);

export type UpdateStatusUpdateTemplateVariables = Exact<{
  statusUpdateTemplate: UpdateStatusUpdateTemplateInput;
}>;


export type UpdateStatusUpdateTemplate = (
  { __typename?: 'RealTraxMutation' }
  & { updateStatusUpdateTemplate?: Maybe<(
    { __typename?: 'DealStatusUpdateTemplate' }
    & Pick<DealStatusUpdateTemplate, 'id' | 'title' | 'message'>
  )> }
);

export type DeleteStatusUpdateTemplateVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeleteStatusUpdateTemplate = (
  { __typename?: 'RealTraxMutation' }
  & { deleteStatusUpdateTemplate?: Maybe<(
    { __typename?: 'DealStatusUpdateTemplate' }
    & Pick<DealStatusUpdateTemplate, 'id' | 'title' | 'message'>
  )> }
);

export type UpdateDealVariables = Exact<{
  deal: UpdateDealInput;
}>;


export type UpdateDeal = (
  { __typename?: 'RealTraxMutation' }
  & { updateDeal?: Maybe<(
    { __typename: 'Deal' }
    & Pick<Deal, 'id' | 'name' | 'dealBackground' | 'earnestMoneyDeposit' | 'investmentVision'>
  )> }
);

export type CreateChecklistCategoryVariables = Exact<{
  checklistCategory: CreateChecklistCategoryInput;
}>;


export type CreateChecklistCategory = (
  { __typename?: 'RealTraxMutation' }
  & { createChecklistCategory?: Maybe<(
    { __typename: 'ChecklistCategory' }
    & ChecklistCategoryFragment
  ) | (
    { __typename: 'ChecklistCategoryAlreadyExists' }
    & ErrorFragment_ChecklistCategoryAlreadyExists_
    & ChecklistCategoryAlreadyExistsFragment
  )> }
);

export type ChecklistCategoryFragment = (
  { __typename?: 'ChecklistCategory' }
  & Pick<ChecklistCategory, 'id' | 'name'>
);

export type ChecklistCategoryAlreadyExistsFragment = (
  { __typename?: 'ChecklistCategoryAlreadyExists' }
  & { checklistCategory?: Maybe<(
    { __typename?: 'ChecklistCategory' }
    & Pick<ChecklistCategory, 'id' | 'name'>
  )> }
);

export type CreateKeyRiskVariables = Exact<{
  keyRisk: CreateKeyRiskInput;
  dealId: Scalars['Int'];
}>;


export type CreateKeyRisk = (
  { __typename?: 'RealTraxMutation' }
  & { createKeyRisk?: Maybe<(
    { __typename?: 'KeyRisk' }
    & Pick<KeyRisk, 'id' | 'title' | 'riskDescription' | 'mitigantDescription' | 'updatedAt'>
  )> }
);

export type UpdateKeyRiskVariables = Exact<{
  keyRisk: UpdateKeyRiskInput;
}>;


export type UpdateKeyRisk = (
  { __typename?: 'RealTraxMutation' }
  & { updateKeyRisk?: Maybe<(
    { __typename?: 'KeyRisk' }
    & Pick<KeyRisk, 'id' | 'title' | 'riskDescription' | 'mitigantDescription' | 'updatedAt' | 'orderId'>
  )> }
);

export type DeleteKeyRiskVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeleteKeyRisk = (
  { __typename?: 'RealTraxMutation' }
  & { deleteKeyRisk?: Maybe<(
    { __typename?: 'KeyRisk' }
    & Pick<KeyRisk, 'id' | 'title' | 'riskDescription' | 'mitigantDescription' | 'deletedAt'>
  )> }
);

export type CreateDealMeritVariables = Exact<{
  dealMerit: CreateDealMeritInput;
  dealId: Scalars['Int'];
}>;


export type CreateDealMerit = (
  { __typename?: 'RealTraxMutation' }
  & { createMerit?: Maybe<(
    { __typename?: 'Merit' }
    & Pick<Merit, 'id' | 'title' | 'meritDescription' | 'updatedAt'>
  )> }
);

export type UpdateDealMeritVariables = Exact<{
  dealMerit: UpdateDealMeritInput;
}>;


export type UpdateDealMerit = (
  { __typename?: 'RealTraxMutation' }
  & { updateMerit?: Maybe<(
    { __typename?: 'Merit' }
    & Pick<Merit, 'id' | 'title' | 'meritDescription' | 'updatedAt'>
  )> }
);

export type DeleteDealMeritVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeleteDealMerit = (
  { __typename?: 'RealTraxMutation' }
  & { deleteMerit?: Maybe<(
    { __typename?: 'Merit' }
    & Pick<Merit, 'id' | 'title' | 'meritDescription' | 'deletedAt'>
  )> }
);

export type ReorderPipelinePinnedMetricVariables = Exact<{
  pipelineId: Scalars['Int'];
  id: Scalars['Int'];
  beforeId?: Maybe<Scalars['Int']>;
  afterId?: Maybe<Scalars['Int']>;
}>;


export type ReorderPipelinePinnedMetric = (
  { __typename?: 'RealTraxMutation' }
  & { reorderPipelinePinnedMetric?: Maybe<{ __typename: 'PipelineEntityResult' }> }
);

export type ReorderPipelinePinnedEventVariables = Exact<{
  pipelineId: Scalars['Int'];
  id: Scalars['Int'];
  beforeId?: Maybe<Scalars['Int']>;
  afterId?: Maybe<Scalars['Int']>;
}>;


export type ReorderPipelinePinnedEvent = (
  { __typename?: 'RealTraxMutation' }
  & { reorderPipelinePinnedEvent?: Maybe<{ __typename: 'PipelineEntityResult' }> }
);

export type ReorderPipelinePinnedPartnerRoleVariables = Exact<{
  pipelineId: Scalars['Int'];
  id: Scalars['Int'];
  beforeId?: Maybe<Scalars['Int']>;
  afterId?: Maybe<Scalars['Int']>;
}>;


export type ReorderPipelinePinnedPartnerRole = (
  { __typename?: 'RealTraxMutation' }
  & { reorderPipelinePinnedPartnerRole?: Maybe<{ __typename: 'PipelineEntityResult' }> }
);

export type ReorderPipelinePinnedTeamMemberRoleVariables = Exact<{
  pipelineId: Scalars['Int'];
  id: Scalars['Int'];
  beforeId?: Maybe<Scalars['Int']>;
  afterId?: Maybe<Scalars['Int']>;
}>;


export type ReorderPipelinePinnedTeamMemberRole = (
  { __typename?: 'RealTraxMutation' }
  & { reorderPipelinePinnedTeamMemberRole?: Maybe<{ __typename: 'PipelineEntityResult' }> }
);

export type CreatePipelineDealStageVariables = Exact<{
  name: Scalars['String'];
  pipelineId: Scalars['Int'];
}>;


export type CreatePipelineDealStage = (
  { __typename?: 'RealTraxMutation' }
  & { createPipelineDealStage?: Maybe<(
    { __typename: 'DealStage' }
    & PipelineDealStageFragment
  ) | (
    { __typename: 'DealStageAlreadyExists' }
    & PipelineDealStageAlreadyExistsFragment
    & ErrorFragment_DealStageAlreadyExists_
  )> }
);

export type PipelineDealStageFragment = (
  { __typename?: 'DealStage' }
  & Pick<DealStage, 'id' | 'name' | 'createdAt' | 'fkPipeline'>
);

export type PipelineDealStageAlreadyExistsFragment = (
  { __typename?: 'DealStageAlreadyExists' }
  & { dealStage?: Maybe<(
    { __typename?: 'DealStage' }
    & PipelineDealStageFragment
  )> }
);

export type UpdatePipelineDealStageVariables = Exact<{
  id: Scalars['Int'];
  name: Scalars['String'];
  isDisabled: Scalars['Boolean'];
  pipelineId: Scalars['Int'];
}>;


export type UpdatePipelineDealStage = (
  { __typename?: 'RealTraxMutation' }
  & { updatePipelineDealStage?: Maybe<(
    { __typename: 'DealStage' }
    & PipelineDealStageFragment
  ) | (
    { __typename: 'DealStageAlreadyExists' }
    & ErrorFragment_DealStageAlreadyExists_
    & PipelineDealStageAlreadyExistsFragment
  ) | (
    { __typename: 'ErrorResult' }
    & Pick<ErrorResult, 'message'>
    & ErrorFragment_ErrorResult_
  )> }
);

export type ReorderPipelineDealStageVariables = Exact<{
  id: Scalars['Int'];
  beforeId?: Maybe<Scalars['Int']>;
  afterId?: Maybe<Scalars['Int']>;
  pipelineId: Scalars['Int'];
}>;


export type ReorderPipelineDealStage = (
  { __typename?: 'RealTraxMutation' }
  & { reorderPipelineDealStage?: Maybe<(
    { __typename: 'DealStage' }
    & PipelineDealStageFragment
  ) | (
    { __typename: 'DealStageAlreadyExists' }
    & ErrorFragment_DealStageAlreadyExists_
    & PipelineDealStageAlreadyExistsFragment
  ) | (
    { __typename: 'ErrorResult' }
    & Pick<ErrorResult, 'message'>
    & ErrorFragment_ErrorResult_
  )> }
);

export type DeletePipelineDealStageVariables = Exact<{
  id: Scalars['Int'];
  pipelineId: Scalars['Int'];
}>;


export type DeletePipelineDealStage = (
  { __typename?: 'RealTraxMutation' }
  & { deletePipelineDealStage?: Maybe<(
    { __typename: 'DealStage' }
    & Pick<DealStage, 'id' | 'name' | 'createdAt' | 'deletedAt' | 'fkPipeline'>
  ) | (
    { __typename: 'ErrorResult' }
    & Pick<ErrorResult, 'message'>
  )> }
);

export type CreateConfigurableEntityVariables = Exact<{
  name: Scalars['String'];
  type: ConfigurableEntityEnum;
}>;


export type CreateConfigurableEntity = (
  { __typename?: 'RealTraxMutation' }
  & { createConfigurableEntity?: Maybe<(
    { __typename: 'ConfigurableEntity' }
    & ConfigurableEntityFragment
  ) | (
    { __typename: 'ConfigurableEntityAlreadyExists' }
    & ErrorFragment_ConfigurableEntityAlreadyExists_
    & ConfigurableEntityAlreadyExistsFragment
  )> }
);

export type ConfigurableEntityFragment = (
  { __typename?: 'ConfigurableEntity' }
  & Pick<ConfigurableEntity, 'id' | 'name' | 'createdAt' | 'entityType'>
);

export type ConfigurableEntityAlreadyExistsFragment = (
  { __typename?: 'ConfigurableEntityAlreadyExists' }
  & { entity?: Maybe<(
    { __typename?: 'ConfigurableEntity' }
    & ConfigurableEntityFragment
  )> }
);

export type UpdateConfigurableEntityVariables = Exact<{
  id: Scalars['Int'];
  name: Scalars['String'];
  isDisabled: Scalars['Boolean'];
  type: ConfigurableEntityEnum;
}>;


export type UpdateConfigurableEntity = (
  { __typename?: 'RealTraxMutation' }
  & { updateConfigurableEntity?: Maybe<(
    { __typename: 'ConfigurableEntity' }
    & ConfigurableEntityFragment
  ) | (
    { __typename: 'ConfigurableEntityAlreadyExists' }
    & ErrorFragment_ConfigurableEntityAlreadyExists_
    & ConfigurableEntityAlreadyExistsFragment
  ) | (
    { __typename: 'ErrorResult' }
    & Pick<ErrorResult, 'message'>
    & ErrorFragment_ErrorResult_
  )> }
);

export type DeleteConfigurableEntityVariables = Exact<{
  id: Scalars['Int'];
  type: ConfigurableEntityEnum;
}>;


export type DeleteConfigurableEntity = (
  { __typename?: 'RealTraxMutation' }
  & { deleteConfigurableEntity?: Maybe<(
    { __typename: 'ConfigurableEntity' }
    & Pick<ConfigurableEntity, 'id' | 'name' | 'createdAt' | 'deletedAt' | 'entityType'>
  ) | (
    { __typename: 'ErrorResult' }
    & Pick<ErrorResult, 'message'>
  )> }
);

export type UpdateOrganizationMemberRoleVariables = Exact<{
  organizationMemberRole: UpdateOrganizationMemberRoleInput;
}>;


export type UpdateOrganizationMemberRole = (
  { __typename?: 'RealTraxMutation' }
  & { updateOrganizationMemberRole: (
    { __typename: 'OrganizationRoleUser' }
    & { user: (
      { __typename?: 'User' }
      & Pick<User, 'firstName' | 'lastName'>
    ), role: (
      { __typename?: 'Role' }
      & Pick<Role, 'id' | 'name'>
    ) }
  ) | (
    { __typename: 'OrganizationRoleGroup' }
    & { group: (
      { __typename?: 'Group' }
      & Pick<Group, 'name'>
    ), role: (
      { __typename?: 'Role' }
      & Pick<Role, 'id' | 'name'>
    ) }
  ) | (
    { __typename: 'ErrorResult' }
    & ErrorFragment_ErrorResult_
  ) }
);

export type AssignMembersToOrganizationVariables = Exact<{
  assignment: OrganizationMemberAssignmentInput;
}>;


export type AssignMembersToOrganization = (
  { __typename?: 'RealTraxMutation' }
  & { assignMembersToOrganization: (
    { __typename?: 'OrganizationMemberAssignmentResult' }
    & Pick<OrganizationMemberAssignmentResult, 'membersUpdated'>
  ) }
);

export type RemoveMembersFromOrganizationVariables = Exact<{
  assignment: RemoveOrganizationMemberInput;
}>;


export type RemoveMembersFromOrganization = (
  { __typename?: 'RealTraxMutation' }
  & { removeMembersFromOrganization: (
    { __typename?: 'OrganizationMemberAssignmentResult' }
    & Pick<OrganizationMemberAssignmentResult, 'membersUpdated'>
  ) }
);

export type UpdateDealMemberRoleVariables = Exact<{
  dealMemberRole: UpdateDealMemberRoleInput;
}>;


export type UpdateDealMemberRole = (
  { __typename?: 'RealTraxMutation' }
  & { updateDealMemberRole: (
    { __typename?: 'DealRoleUser' }
    & { user: (
      { __typename?: 'User' }
      & Pick<User, 'firstName' | 'lastName'>
    ), role: (
      { __typename?: 'Role' }
      & Pick<Role, 'id' | 'name'>
    ) }
  ) | (
    { __typename?: 'DealRoleGroup' }
    & { group: (
      { __typename?: 'Group' }
      & Pick<Group, 'name'>
    ), role: (
      { __typename?: 'Role' }
      & Pick<Role, 'id' | 'name'>
    ) }
  ) | (
    { __typename?: 'ErrorResult' }
    & ErrorFragment_ErrorResult_
  ) }
);

export type AssignMembersToDealVariables = Exact<{
  assignment: DealMemberAssignmentInput;
}>;


export type AssignMembersToDeal = (
  { __typename?: 'RealTraxMutation' }
  & { assignMembersToDeal: (
    { __typename?: 'DealMemberAssignmentResult' }
    & Pick<DealMemberAssignmentResult, 'membersUpdated'>
  ) }
);

export type RemoveMembersFromDealVariables = Exact<{
  assignment: RemoveDealMemberInput;
}>;


export type RemoveMembersFromDeal = (
  { __typename?: 'RealTraxMutation' }
  & { removeMembersFromDeal: (
    { __typename?: 'DealMemberAssignmentResult' }
    & Pick<DealMemberAssignmentResult, 'membersUpdated'>
  ) }
);

export type UpdatePipelineMemberRoleVariables = Exact<{
  pipelineMemberRole: UpdatePipelineMemberRoleInput;
}>;


export type UpdatePipelineMemberRole = (
  { __typename?: 'RealTraxMutation' }
  & { updatePipelineMemberRole: (
    { __typename?: 'PipelineRoleUser' }
    & { user: (
      { __typename?: 'User' }
      & Pick<User, 'firstName' | 'lastName'>
    ), role: (
      { __typename?: 'Role' }
      & Pick<Role, 'id' | 'name'>
    ) }
  ) | (
    { __typename?: 'PipelineRoleGroup' }
    & { group: (
      { __typename?: 'Group' }
      & Pick<Group, 'name'>
    ), role: (
      { __typename?: 'Role' }
      & Pick<Role, 'id' | 'name'>
    ) }
  ) | (
    { __typename?: 'ErrorResult' }
    & ErrorFragment_ErrorResult_
  ) }
);

export type AssignMembersToPipelineVariables = Exact<{
  assignment: PipelineMemberAssignmentInput;
}>;


export type AssignMembersToPipeline = (
  { __typename?: 'RealTraxMutation' }
  & { assignMembersToPipeline: (
    { __typename?: 'PipelineMemberAssignmentResult' }
    & Pick<PipelineMemberAssignmentResult, 'membersUpdated'>
  ) }
);

export type RemoveMembersFromPipelineVariables = Exact<{
  assignment: RemovePipelineMemberInput;
}>;


export type RemoveMembersFromPipeline = (
  { __typename?: 'RealTraxMutation' }
  & { removeMembersFromPipeline: (
    { __typename?: 'PipelineMemberAssignmentResult' }
    & Pick<PipelineMemberAssignmentResult, 'membersUpdated'>
  ) }
);

export type UpdatePortfolioMemberRoleVariables = Exact<{
  portfolioMemberRole: UpdatePortfolioMemberRoleInput;
}>;


export type UpdatePortfolioMemberRole = (
  { __typename?: 'RealTraxMutation' }
  & { updatePortfolioMemberRole: (
    { __typename?: 'PortfolioRoleUser' }
    & { user: (
      { __typename?: 'User' }
      & Pick<User, 'firstName' | 'lastName'>
    ), role: (
      { __typename?: 'Role' }
      & Pick<Role, 'id' | 'name'>
    ) }
  ) | (
    { __typename?: 'PortfolioRoleGroup' }
    & { group: (
      { __typename?: 'Group' }
      & Pick<Group, 'name'>
    ), role: (
      { __typename?: 'Role' }
      & Pick<Role, 'id' | 'name'>
    ) }
  ) | (
    { __typename?: 'ErrorResult' }
    & ErrorFragment_ErrorResult_
  ) }
);

export type AssignMembersToPortfolioVariables = Exact<{
  assignment: PortfolioMemberAssignmentInput;
}>;


export type AssignMembersToPortfolio = (
  { __typename?: 'RealTraxMutation' }
  & { assignMembersToPortfolio: (
    { __typename?: 'PortfolioMemberAssignmentResult' }
    & Pick<PortfolioMemberAssignmentResult, 'membersUpdated'>
  ) }
);

export type RemoveMembersFromPortfolioVariables = Exact<{
  assignment: RemovePortfolioMemberInput;
}>;


export type RemoveMembersFromPortfolio = (
  { __typename?: 'RealTraxMutation' }
  & { removeMembersFromPortfolio: (
    { __typename?: 'PortfolioMemberAssignmentResult' }
    & Pick<PortfolioMemberAssignmentResult, 'membersUpdated'>
  ) }
);

export type UpdatePortfolioViewColumnVariables = Exact<{
  column: UpdatePortfolioViewColumnInput;
}>;


export type UpdatePortfolioViewColumn = (
  { __typename?: 'RealTraxMutation' }
  & { updatePortfolioViewColumn: (
    { __typename?: 'PortfolioViewColumn' }
    & Pick<PortfolioViewColumn, 'id' | 'name' | 'lookupName' | 'type' | 'orderId'>
  ) }
);

export type AddPortfolioViewColumnVariables = Exact<{
  column: AddPortfolioViewColumnInput;
}>;


export type AddPortfolioViewColumn = (
  { __typename?: 'RealTraxMutation' }
  & { addPortfolioViewColumn: (
    { __typename?: 'PortfolioViewColumn' }
    & Pick<PortfolioViewColumn, 'id' | 'name' | 'lookupName' | 'type' | 'orderId'>
  ) }
);

export type RemovePortfolioViewColumnsVariables = Exact<{
  id: Scalars['Int'];
  columns?: Maybe<Array<AddPortfolioViewColumnInput> | AddPortfolioViewColumnInput>;
}>;


export type RemovePortfolioViewColumns = (
  { __typename?: 'RealTraxMutation' }
  & { removePortfolioViewColumns: (
    { __typename?: 'PortfolioViewColumnsRemovedResult' }
    & Pick<PortfolioViewColumnsRemovedResult, 'columnsRemoved'>
  ) }
);

export type AddPortfolioDealsVariables = Exact<{
  portfolioDeals: UpdatePortfolioDealsInput;
}>;


export type AddPortfolioDeals = (
  { __typename?: 'RealTraxMutation' }
  & { addPortfolioDeals?: Maybe<(
    { __typename: 'Portfolio' }
    & Pick<Portfolio, 'id' | 'name'>
  ) | (
    { __typename: 'ErrorResult' }
    & Pick<ErrorResult, 'message'>
  )> }
);

export type RemovePortfolioDealsVariables = Exact<{
  portfolioDeals: UpdatePortfolioDealsInput;
}>;


export type RemovePortfolioDeals = (
  { __typename?: 'RealTraxMutation' }
  & { removePortfolioDeals?: Maybe<(
    { __typename: 'Portfolio' }
    & Pick<Portfolio, 'id' | 'name'>
  ) | (
    { __typename: 'ErrorResult' }
    & Pick<ErrorResult, 'message'>
  )> }
);

export type CreateEventVariables = Exact<{
  event: CreateEventInput;
}>;


export type CreateEvent = (
  { __typename?: 'RealTraxMutation' }
  & { createEvent?: Maybe<(
    { __typename: 'Event' }
    & EventFragment
  ) | (
    { __typename: 'EventAlreadyExists' }
    & EventAlreadyExistsFragment
    & ErrorFragment_EventAlreadyExists_
  )> }
);

export type UpdateEventVariables = Exact<{
  event: UpdateEventInput;
}>;


export type UpdateEvent = (
  { __typename?: 'RealTraxMutation' }
  & { updateEvent?: Maybe<(
    { __typename: 'Event' }
    & EventFragment
  ) | (
    { __typename: 'EventAlreadyExists' }
    & EventAlreadyExistsFragment
    & ErrorFragment_EventAlreadyExists_
  ) | (
    { __typename: 'ErrorResult' }
    & Pick<ErrorResult, 'message'>
    & ErrorFragment_ErrorResult_
  )> }
);

export type DeleteEventVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeleteEvent = (
  { __typename?: 'RealTraxMutation' }
  & { deleteEvent?: Maybe<(
    { __typename: 'Event' }
    & EventFragment
  ) | (
    { __typename: 'ErrorResult' }
    & Pick<ErrorResult, 'message'>
  )> }
);

export type EventAlreadyExistsFragment = (
  { __typename?: 'EventAlreadyExists' }
  & Pick<EventAlreadyExists, 'message'>
  & { event?: Maybe<(
    { __typename?: 'Event' }
    & Pick<Event, 'id' | 'name' | 'type'>
  )> }
);

export type EventFragment = (
  { __typename?: 'Event' }
  & Pick<Event, 'id' | 'name' | 'type' | 'createdAt'>
);

export type CreateDealEventVariables = Exact<{
  event: CreateDealEventInput;
}>;


export type CreateDealEvent = (
  { __typename?: 'RealTraxMutation' }
  & { createDealEvent?: Maybe<(
    { __typename: 'DealEvent' }
    & DealEventFragment
  ) | (
    { __typename: 'DealEventAlreadyExists' }
    & DealEventAlreadyExistsFragment
    & ErrorFragment_DealEventAlreadyExists_
  )> }
);

export type UpdateDealEventVariables = Exact<{
  event: UpdateDealEventInput;
}>;


export type UpdateDealEvent = (
  { __typename?: 'RealTraxMutation' }
  & { updateDealEvent?: Maybe<(
    { __typename: 'DealEvent' }
    & DealEventFragment
  ) | (
    { __typename: 'DealEventAlreadyExists' }
    & DealEventAlreadyExistsFragment
    & ErrorFragment_DealEventAlreadyExists_
  )> }
);

export type DealEventAlreadyExistsFragment = (
  { __typename?: 'DealEventAlreadyExists' }
  & Pick<DealEventAlreadyExists, 'message'>
  & { event?: Maybe<(
    { __typename?: 'DealEvent' }
    & Pick<DealEvent, 'id' | 'name'>
  )> }
);

export type CreateAnalysisVariables = Exact<{
  analysis: CreateAnalysisInput;
}>;


export type CreateAnalysis = (
  { __typename?: 'RealTraxMutation' }
  & { createAnalysis: (
    { __typename: 'DealAnalysis' }
    & DealAnalysisFragment
  ) }
);

export type UpdateAnalysisVariables = Exact<{
  analysis: UpdateAnalysisInput;
}>;


export type UpdateAnalysis = (
  { __typename?: 'RealTraxMutation' }
  & { updateAnalysis: (
    { __typename: 'DealAnalysis' }
    & DealAnalysisFragment
  ) }
);

export type DeleteAnalysisVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeleteAnalysis = (
  { __typename?: 'RealTraxMutation' }
  & { deleteAnalysis: (
    { __typename: 'DealAnalysis' }
    & DealAnalysisFragment
  ) | (
    { __typename: 'ErrorResult' }
    & Pick<ErrorResult, 'message'>
  ) }
);

export type DealAnalysisFragment = (
  { __typename?: 'DealAnalysis' }
  & Pick<DealAnalysis, 'id' | 'description' | 'archiveDate' | 'fkAnalysisType'>
  & { scenario: (
    { __typename?: 'ScenarioType' }
    & Pick<ScenarioType, 'id' | 'name'>
  ) }
);

export type CreateAnalysisTypeVariables = Exact<{
  analysisType: CreateAnalysisTypeInput;
}>;


export type CreateAnalysisType = (
  { __typename?: 'RealTraxMutation' }
  & { createAnalysisType: (
    { __typename: 'AnalysisType' }
    & AnalysisTypeFragment
  ) | (
    { __typename: 'AnalysisTypeAlreadyExists' }
    & AnalysisTypeAlreadyExistsFragment
    & ErrorFragment_AnalysisTypeAlreadyExists_
  ) }
);

export type UpdateAnalysisTypeVariables = Exact<{
  analysisType: UpdateAnalysisTypeInput;
}>;


export type UpdateAnalysisType = (
  { __typename?: 'RealTraxMutation' }
  & { updateAnalysisType: (
    { __typename: 'AnalysisType' }
    & AnalysisTypeFragment
  ) | (
    { __typename: 'AnalysisTypeAlreadyExists' }
    & AnalysisTypeAlreadyExistsFragment
    & ErrorFragment_AnalysisTypeAlreadyExists_
  ) | (
    { __typename: 'ErrorResult' }
    & Pick<ErrorResult, 'message'>
    & ErrorFragment_ErrorResult_
  ) }
);

export type DeleteAnalysisTypeVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeleteAnalysisType = (
  { __typename?: 'RealTraxMutation' }
  & { deleteAnalysisType: (
    { __typename: 'AnalysisType' }
    & AnalysisTypeFragment
  ) | (
    { __typename: 'ErrorResult' }
    & Pick<ErrorResult, 'message'>
  ) }
);

export type AnalysisTypeFragment = (
  { __typename?: 'AnalysisType' }
  & Pick<AnalysisType, 'id' | 'name' | 'acronym'>
);

export type AnalysisTypeAlreadyExistsFragment = (
  { __typename?: 'AnalysisTypeAlreadyExists' }
  & Pick<AnalysisTypeAlreadyExists, 'message'>
  & { analysisType?: Maybe<(
    { __typename?: 'AnalysisType' }
    & Pick<AnalysisType, 'id' | 'name'>
  )> }
);

export type CreateAnalysisMetricVariables = Exact<{
  metric: CreateAnalysisMetricInput;
}>;


export type CreateAnalysisMetric = (
  { __typename?: 'RealTraxMutation' }
  & { createAnalysisMetric: (
    { __typename?: 'AnalysisMetric' }
    & Pick<AnalysisMetric, 'id' | 'value'>
    & { metric: (
      { __typename?: 'Metric' }
      & Pick<Metric, 'id' | 'name'>
    ) }
  ) }
);

export type UpdateAnalysisMetricVariables = Exact<{
  metric: UpdateAnalysisMetricInput;
}>;


export type UpdateAnalysisMetric = (
  { __typename?: 'RealTraxMutation' }
  & { updateAnalysisMetric: (
    { __typename?: 'AnalysisMetric' }
    & Pick<AnalysisMetric, 'id' | 'value'>
    & { metric: (
      { __typename?: 'Metric' }
      & Pick<Metric, 'id' | 'name'>
    ) }
  ) }
);

export type DeleteAnalysisMetricVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeleteAnalysisMetric = (
  { __typename?: 'RealTraxMutation' }
  & { deleteAnalysisMetric: (
    { __typename?: 'AnalysisMetric' }
    & Pick<AnalysisMetric, 'id' | 'value'>
    & { metric: (
      { __typename?: 'Metric' }
      & Pick<Metric, 'id' | 'name'>
    ) }
  ) }
);

export type CreateAnalysisTextMetricVariables = Exact<{
  textMetric: CreateAnalysisTextMetricInput;
}>;


export type CreateAnalysisTextMetric = (
  { __typename?: 'RealTraxMutation' }
  & { createAnalysisTextMetric: (
    { __typename?: 'AnalysisTextMetric' }
    & Pick<AnalysisTextMetric, 'id' | 'value'>
    & { textMetric: (
      { __typename?: 'TextMetric' }
      & Pick<TextMetric, 'id' | 'name'>
    ) }
  ) }
);

export type UpdateAnalysisTextMetricVariables = Exact<{
  textMetric: UpdateAnalysisTextMetricInput;
}>;


export type UpdateAnalysisTextMetric = (
  { __typename?: 'RealTraxMutation' }
  & { updateAnalysisTextMetric: (
    { __typename?: 'AnalysisTextMetric' }
    & Pick<AnalysisTextMetric, 'id' | 'value'>
    & { textMetric: (
      { __typename?: 'TextMetric' }
      & Pick<TextMetric, 'id' | 'name'>
    ) }
  ) }
);

export type DeleteAnalysisTextMetricVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeleteAnalysisTextMetric = (
  { __typename?: 'RealTraxMutation' }
  & { deleteAnalysisTextMetric: (
    { __typename?: 'AnalysisTextMetric' }
    & Pick<AnalysisTextMetric, 'id' | 'value'>
    & { textMetric: (
      { __typename?: 'TextMetric' }
      & Pick<TextMetric, 'id' | 'name'>
    ) }
  ) }
);

export type CreateAnalysisEventVariables = Exact<{
  event: CreateAnalysisEventInput;
}>;


export type CreateAnalysisEvent = (
  { __typename?: 'RealTraxMutation' }
  & { createAnalysisEvent: (
    { __typename?: 'AnalysisEvent' }
    & Pick<AnalysisEvent, 'id' | 'startDate'>
    & { event: (
      { __typename?: 'Event' }
      & Pick<Event, 'id' | 'name'>
    ) }
  ) }
);

export type UpdateAnalysisEventVariables = Exact<{
  event: UpdateAnalysisEventInput;
}>;


export type UpdateAnalysisEvent = (
  { __typename?: 'RealTraxMutation' }
  & { updateAnalysisEvent: (
    { __typename?: 'AnalysisEvent' }
    & Pick<AnalysisEvent, 'id' | 'startDate'>
    & { event: (
      { __typename?: 'Event' }
      & Pick<Event, 'id' | 'name'>
    ) }
  ) }
);

export type DeleteAnalysisEventVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeleteAnalysisEvent = (
  { __typename?: 'RealTraxMutation' }
  & { deleteAnalysisEvent: (
    { __typename?: 'AnalysisEvent' }
    & Pick<AnalysisEvent, 'id' | 'startDate'>
    & { event: (
      { __typename?: 'Event' }
      & Pick<Event, 'id' | 'name'>
    ) }
  ) }
);

export type DuplicateAnalysisVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DuplicateAnalysis = (
  { __typename?: 'RealTraxMutation' }
  & { duplicateAnalysis: (
    { __typename?: 'DealAnalysis' }
    & Pick<DealAnalysis, 'id' | 'description' | 'archiveDate'>
    & { scenario: (
      { __typename?: 'ScenarioType' }
      & Pick<ScenarioType, 'id' | 'name'>
    ) }
  ) }
);

export type ArchiveAnalysisVariables = Exact<{
  id: Scalars['Int'];
}>;


export type ArchiveAnalysis = (
  { __typename?: 'RealTraxMutation' }
  & { archiveAnalysis: (
    { __typename?: 'DealAnalysis' }
    & Pick<DealAnalysis, 'id' | 'description' | 'archiveDate'>
    & { scenario: (
      { __typename?: 'ScenarioType' }
      & Pick<ScenarioType, 'id' | 'name'>
    ) }
  ) }
);

export type CreateContractVariables = Exact<{
  contract: CreateContractInput;
}>;


export type CreateContract = (
  { __typename?: 'RealTraxMutation' }
  & { createContract?: Maybe<(
    { __typename?: 'Contract' }
    & Pick<Contract, 'id' | 'name' | 'description'>
    & { vendor?: Maybe<(
      { __typename?: 'Firm' }
      & Pick<Firm, 'id' | 'name'>
    )>, transactions?: Maybe<Array<(
      { __typename?: 'Transaction' }
      & Pick<Transaction, 'id' | 'description' | 'amount'>
    )>> }
  )> }
);

export type UpdateContractVariables = Exact<{
  contract: UpdateContractInput;
}>;


export type UpdateContract = (
  { __typename?: 'RealTraxMutation' }
  & { updateContract?: Maybe<(
    { __typename?: 'Contract' }
    & Pick<Contract, 'id' | 'name' | 'description'>
    & { vendor?: Maybe<(
      { __typename?: 'Firm' }
      & Pick<Firm, 'id' | 'name'>
    )>, transactions?: Maybe<Array<(
      { __typename?: 'Transaction' }
      & Pick<Transaction, 'id' | 'description' | 'amount'>
    )>> }
  )> }
);

export type DeleteContractVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeleteContract = (
  { __typename?: 'RealTraxMutation' }
  & { deleteContract?: Maybe<(
    { __typename?: 'Contract' }
    & Pick<Contract, 'id' | 'name' | 'description'>
    & { vendor?: Maybe<(
      { __typename?: 'Firm' }
      & Pick<Firm, 'id' | 'name'>
    )>, transactions?: Maybe<Array<(
      { __typename?: 'Transaction' }
      & Pick<Transaction, 'id' | 'description' | 'amount'>
    )>> }
  )> }
);

export type PublishCapitalForecastVariables = Exact<{
  capitalForecast: CreatePublishedCapitalForecastInput;
}>;


export type PublishCapitalForecast = (
  { __typename?: 'RealTraxMutation' }
  & { publishCapitalForecast: (
    { __typename?: 'PublishedCapitalForecast' }
    & Pick<PublishedCapitalForecast, 'id' | 'name' | 'comment'>
  ) }
);

export type DeletePublishedCapitalForecastVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeletePublishedCapitalForecast = (
  { __typename?: 'RealTraxMutation' }
  & { deletePublishedCapitalForecast: (
    { __typename?: 'PublishedCapitalForecast' }
    & Pick<PublishedCapitalForecast, 'id' | 'name' | 'comment'>
  ) }
);

export type CreateRevisionVariables = Exact<{
  revision: CreateRevisionInput;
}>;


export type CreateRevision = (
  { __typename?: 'RealTraxMutation' }
  & { createBudgetRevision?: Maybe<(
    { __typename?: 'Revision' }
    & Pick<Revision, 'id' | 'name'>
    & { details?: Maybe<Array<(
      { __typename?: 'RevisionCostAccount' }
      & Pick<RevisionCostAccount, 'id' | 'amount' | 'isPursuit'>
      & { account: (
        { __typename?: 'GlAccount' }
        & Pick<GlAccount, 'id' | 'name' | 'code' | 'displayCode'>
      ) }
    )>> }
  )> }
);

export type UpdateRevisionVariables = Exact<{
  revision: UpdateRevisionInput;
}>;


export type UpdateRevision = (
  { __typename?: 'RealTraxMutation' }
  & { updateBudgetRevision?: Maybe<(
    { __typename?: 'Revision' }
    & Pick<Revision, 'id' | 'name'>
    & { details?: Maybe<Array<(
      { __typename?: 'RevisionCostAccount' }
      & Pick<RevisionCostAccount, 'id' | 'amount' | 'isPursuit'>
      & { account: (
        { __typename?: 'GlAccount' }
        & Pick<GlAccount, 'id' | 'name' | 'code' | 'displayCode'>
      ) }
    )>> }
  )> }
);

export type DeleteRevisionVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeleteRevision = (
  { __typename?: 'RealTraxMutation' }
  & { deleteBudgetRevision?: Maybe<(
    { __typename?: 'Revision' }
    & Pick<Revision, 'id' | 'name'>
    & { details?: Maybe<Array<(
      { __typename?: 'RevisionCostAccount' }
      & Pick<RevisionCostAccount, 'id' | 'amount' | 'isPursuit'>
      & { account: (
        { __typename?: 'GlAccount' }
        & Pick<GlAccount, 'id' | 'name' | 'code' | 'displayCode'>
      ) }
    )>> }
  )> }
);

export type CreateRevisionCostAccountVariables = Exact<{
  revisionCostAccount: CreateRevisionAccountInput;
}>;


export type CreateRevisionCostAccount = (
  { __typename?: 'RealTraxMutation' }
  & { createBudgetRevisionCostAccount?: Maybe<(
    { __typename?: 'RevisionCostAccount' }
    & Pick<RevisionCostAccount, 'id' | 'amount' | 'isPursuit'>
    & { account: (
      { __typename?: 'GlAccount' }
      & Pick<GlAccount, 'id' | 'name' | 'code' | 'displayCode'>
    ) }
  )> }
);

export type UpdateRevisionCostAccountVariables = Exact<{
  revisionCostAccount: UpdateRevisionAccountInput;
}>;


export type UpdateRevisionCostAccount = (
  { __typename?: 'RealTraxMutation' }
  & { updateBudgetRevisionCostAccount?: Maybe<(
    { __typename?: 'RevisionCostAccount' }
    & Pick<RevisionCostAccount, 'id' | 'amount' | 'isPursuit'>
    & { account: (
      { __typename?: 'GlAccount' }
      & Pick<GlAccount, 'id' | 'name' | 'code' | 'displayCode'>
    ) }
  )> }
);

export type DeleteRevisionCostAccountsVariables = Exact<{
  revisionId: Scalars['Int'];
  ids: Array<Scalars['Int']> | Scalars['Int'];
}>;


export type DeleteRevisionCostAccounts = (
  { __typename?: 'RealTraxMutation' }
  & { deleteBudgetRevisionCostAccounts?: Maybe<Array<(
    { __typename?: 'RevisionCostAccount' }
    & Pick<RevisionCostAccount, 'id' | 'amount' | 'isPursuit'>
    & { account: (
      { __typename?: 'GlAccount' }
      & Pick<GlAccount, 'id' | 'name' | 'code' | 'displayCode'>
    ) }
  )>> }
);

export type CreatePortfolioViewVariables = Exact<{
  portfolioView: CreatePortfolioViewInput;
}>;


export type CreatePortfolioView = (
  { __typename?: 'RealTraxMutation' }
  & { createPortfolioView?: Maybe<(
    { __typename?: 'PortfolioView' }
    & PortfolioViewFragment
  )> }
);

export type UpdatePortfolioViewVariables = Exact<{
  portfolioView: UpdatePortfolioViewInput;
}>;


export type UpdatePortfolioView = (
  { __typename?: 'RealTraxMutation' }
  & { updatePortfolioView?: Maybe<(
    { __typename?: 'PortfolioView' }
    & PortfolioViewFragment
  )> }
);

export type DeletePortfolioViewVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeletePortfolioView = (
  { __typename?: 'RealTraxMutation' }
  & { deletePortfolioView?: Maybe<(
    { __typename?: 'PortfolioView' }
    & PortfolioViewFragment
  )> }
);

export type PortfolioViewFragment = (
  { __typename?: 'PortfolioView' }
  & Pick<PortfolioView, 'id' | 'name' | 'showArchivedDeals' | 'createdAt' | 'updatedAt'>
);

export type CreateCommentVariables = Exact<{
  comment: CreateCommentInput;
  subjectType: CommentSubjectEnum;
}>;


export type CreateComment = (
  { __typename?: 'RealTraxMutation' }
  & { createComment?: Maybe<(
    { __typename?: 'Comment' }
    & CommentFragment
  )> }
);

export type UpdateCommentVariables = Exact<{
  comment: UpdateCommentInput;
}>;


export type UpdateComment = (
  { __typename?: 'RealTraxMutation' }
  & { updateComment?: Maybe<(
    { __typename?: 'Comment' }
    & CommentFragment
  )> }
);

export type DeleteCommentVariables = Exact<{
  id: Scalars['Int'];
  subjectType: CommentSubjectEnum;
}>;


export type DeleteComment = (
  { __typename?: 'RealTraxMutation' }
  & { deleteComment?: Maybe<(
    { __typename?: 'Comment' }
    & CommentFragment
  )> }
);

export type CommentFragment = (
  { __typename?: 'Comment' }
  & Pick<Comment, 'id' | 'body' | 'updatedAt'>
  & { createdBy: (
    { __typename?: 'SystemProcess' }
    & Pick<SystemProcess, 'id' | 'displayName' | 'displayNameAlt'>
  ) | (
    { __typename?: 'User' }
    & Pick<User, 'id' | 'displayName' | 'displayNameAlt'>
  ) }
);

export type GenerateTransactionDetailPreviewVariables = Exact<{
  transaction: CreateTransactionInput;
}>;


export type GenerateTransactionDetailPreview = (
  { __typename?: 'RealTraxMutation' }
  & { generateTransactionDetailPreview?: Maybe<Array<(
    { __typename?: 'TranasctionPeriodDetailPreview' }
    & Pick<TranasctionPeriodDetailPreview, 'date' | 'amount' | 'type'>
  )>> }
);

export type CreateTransactionVariables = Exact<{
  transaction: CreateTransactionInput;
  details: Array<CreateTransactionDetailInput> | CreateTransactionDetailInput;
}>;


export type CreateTransaction = (
  { __typename?: 'RealTraxMutation' }
  & { createTransaction?: Maybe<(
    { __typename?: 'Transaction' }
    & Pick<Transaction, 'id' | 'amount' | 'createdAt' | 'updatedAt' | 'startDate' | 'endDate' | 'description'>
    & { createdBy: (
      { __typename?: 'SystemProcess' }
      & Pick<SystemProcess, 'id' | 'displayName'>
    ) | (
      { __typename?: 'User' }
      & Pick<User, 'id' | 'displayName'>
    ), updatedBy: (
      { __typename?: 'SystemProcess' }
      & Pick<SystemProcess, 'id' | 'displayName'>
    ) | (
      { __typename?: 'User' }
      & Pick<User, 'id' | 'displayName'>
    ), account: (
      { __typename?: 'GlAccount' }
      & Pick<GlAccount, 'id' | 'name' | 'code' | 'displayCode'>
    ), vendor?: Maybe<(
      { __typename?: 'Firm' }
      & Pick<Firm, 'id' | 'name'>
    )>, contract?: Maybe<(
      { __typename?: 'Contract' }
      & Pick<Contract, 'id' | 'name'>
    )>, distributionType: (
      { __typename?: 'DistributionType' }
      & Pick<DistributionType, 'id' | 'name'>
    ) }
  )> }
);

export type UpdateTransactionVariables = Exact<{
  transaction: UpdateTransactionInput;
  details: Array<CreateTransactionDetailInput> | CreateTransactionDetailInput;
}>;


export type UpdateTransaction = (
  { __typename?: 'RealTraxMutation' }
  & { updateTransaction?: Maybe<(
    { __typename?: 'Transaction' }
    & Pick<Transaction, 'id' | 'amount' | 'createdAt' | 'updatedAt' | 'startDate' | 'endDate' | 'description'>
    & { createdBy: (
      { __typename?: 'SystemProcess' }
      & Pick<SystemProcess, 'id' | 'displayName'>
    ) | (
      { __typename?: 'User' }
      & Pick<User, 'id' | 'displayName'>
    ), updatedBy: (
      { __typename?: 'SystemProcess' }
      & Pick<SystemProcess, 'id' | 'displayName'>
    ) | (
      { __typename?: 'User' }
      & Pick<User, 'id' | 'displayName'>
    ), account: (
      { __typename?: 'GlAccount' }
      & Pick<GlAccount, 'id' | 'name' | 'code' | 'displayCode'>
    ), vendor?: Maybe<(
      { __typename?: 'Firm' }
      & Pick<Firm, 'id' | 'name'>
    )>, contract?: Maybe<(
      { __typename?: 'Contract' }
      & Pick<Contract, 'id' | 'name'>
    )>, distributionType: (
      { __typename?: 'DistributionType' }
      & Pick<DistributionType, 'id' | 'name'>
    ) }
  )> }
);

export type UpdateTransactionsVariables = Exact<{
  input: Array<UpdateTransactionWithDetailsInput> | UpdateTransactionWithDetailsInput;
  fkDeal: Scalars['Int'];
}>;


export type UpdateTransactions = (
  { __typename?: 'RealTraxMutation' }
  & { updateTransactions?: Maybe<Array<Maybe<(
    { __typename?: 'Transaction' }
    & Pick<Transaction, 'id' | 'amount' | 'createdAt' | 'updatedAt' | 'startDate' | 'endDate' | 'description'>
    & { createdBy: (
      { __typename?: 'SystemProcess' }
      & Pick<SystemProcess, 'id' | 'displayName'>
    ) | (
      { __typename?: 'User' }
      & Pick<User, 'id' | 'displayName'>
    ), updatedBy: (
      { __typename?: 'SystemProcess' }
      & Pick<SystemProcess, 'id' | 'displayName'>
    ) | (
      { __typename?: 'User' }
      & Pick<User, 'id' | 'displayName'>
    ), account: (
      { __typename?: 'GlAccount' }
      & Pick<GlAccount, 'id' | 'name' | 'code' | 'displayCode'>
    ), vendor?: Maybe<(
      { __typename?: 'Firm' }
      & Pick<Firm, 'id' | 'name'>
    )>, contract?: Maybe<(
      { __typename?: 'Contract' }
      & Pick<Contract, 'id' | 'name'>
    )>, distributionType: (
      { __typename?: 'DistributionType' }
      & Pick<DistributionType, 'id' | 'name'>
    ) }
  )>>> }
);

export type DeleteTransactionVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeleteTransaction = (
  { __typename?: 'RealTraxMutation' }
  & { deleteTransaction?: Maybe<(
    { __typename?: 'Transaction' }
    & Pick<Transaction, 'id' | 'amount' | 'createdAt' | 'updatedAt' | 'startDate' | 'endDate' | 'description'>
    & { createdBy: (
      { __typename?: 'SystemProcess' }
      & Pick<SystemProcess, 'id' | 'displayName'>
    ) | (
      { __typename?: 'User' }
      & Pick<User, 'id' | 'displayName'>
    ), updatedBy: (
      { __typename?: 'SystemProcess' }
      & Pick<SystemProcess, 'id' | 'displayName'>
    ) | (
      { __typename?: 'User' }
      & Pick<User, 'id' | 'displayName'>
    ), account: (
      { __typename?: 'GlAccount' }
      & Pick<GlAccount, 'id' | 'name' | 'code' | 'displayCode'>
    ), vendor?: Maybe<(
      { __typename?: 'Firm' }
      & Pick<Firm, 'id' | 'name'>
    )>, contract?: Maybe<(
      { __typename?: 'Contract' }
      & Pick<Contract, 'id' | 'name'>
    )>, distributionType: (
      { __typename?: 'DistributionType' }
      & Pick<DistributionType, 'id' | 'name'>
    ) }
  )> }
);

export type DeletePortfolioVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeletePortfolio = (
  { __typename?: 'RealTraxMutation' }
  & { deletePortfolio?: Maybe<(
    { __typename?: 'Portfolio' }
    & Pick<Portfolio, 'id' | 'name'>
  )> }
);

export type DeleteDealVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeleteDeal = (
  { __typename?: 'RealTraxMutation' }
  & { deleteDeal?: Maybe<(
    { __typename?: 'Deal' }
    & Pick<Deal, 'id' | 'name'>
  )> }
);

export type ArchiveDealVariables = Exact<{
  id: Scalars['Int'];
  status: DealArchiveStatusEnum;
  reason?: Maybe<Scalars['String']>;
}>;


export type ArchiveDeal = (
  { __typename?: 'RealTraxMutation' }
  & { archiveDeal?: Maybe<(
    { __typename?: 'Deal' }
    & Pick<Deal, 'id' | 'name' | 'archiveStatus' | 'archiveReason' | 'archiveDate'>
  )> }
);

export type UnarchiveDealVariables = Exact<{
  id: Scalars['Int'];
}>;


export type UnarchiveDeal = (
  { __typename?: 'RealTraxMutation' }
  & { unarchiveDeal?: Maybe<(
    { __typename?: 'Deal' }
    & Pick<Deal, 'id' | 'name' | 'archiveStatus' | 'archiveReason' | 'archiveDate'>
  )> }
);

export type CreateGroupVariables = Exact<{
  group: CreateGroupInput;
}>;


export type CreateGroup = (
  { __typename?: 'RealTraxMutation' }
  & { createGroup?: Maybe<(
    { __typename?: 'Group' }
    & Pick<Group, 'id' | 'name' | 'createdAt' | 'updatedAt' | 'memberCount'>
  )> }
);

export type UpdateGroupVariables = Exact<{
  group: UpdateGroupInput;
}>;


export type UpdateGroup = (
  { __typename?: 'RealTraxMutation' }
  & { updateGroup?: Maybe<(
    { __typename?: 'Group' }
    & Pick<Group, 'id' | 'name' | 'createdAt' | 'updatedAt' | 'memberCount'>
  )> }
);

export type DeleteGroupVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeleteGroup = (
  { __typename?: 'RealTraxMutation' }
  & { deleteGroup?: Maybe<(
    { __typename?: 'Group' }
    & Pick<Group, 'id' | 'name' | 'createdAt' | 'updatedAt' | 'memberCount'>
  )> }
);

export type RemoveUsersfromGroupVariables = Exact<{
  groupId: Scalars['Int'];
  userIds: Array<Scalars['Int']> | Scalars['Int'];
}>;


export type RemoveUsersfromGroup = (
  { __typename?: 'RealTraxMutation' }
  & { removeUsersFromGroup?: Maybe<(
    { __typename?: 'Group' }
    & Pick<Group, 'id' | 'name' | 'createdAt' | 'updatedAt' | 'memberCount'>
  )> }
);

export type AddUsersToGroupVariables = Exact<{
  groupId: Scalars['Int'];
  userIds: Array<Scalars['Int']> | Scalars['Int'];
}>;


export type AddUsersToGroup = (
  { __typename?: 'RealTraxMutation' }
  & { addUsersToGroup?: Maybe<(
    { __typename?: 'Group' }
    & Pick<Group, 'id' | 'name' | 'createdAt' | 'updatedAt' | 'memberCount'>
  )> }
);

export type AddEventsToDealVariables = Exact<{
  dealId: Scalars['Int'];
  eventIds: Array<Scalars['Int']> | Scalars['Int'];
}>;


export type AddEventsToDeal = (
  { __typename?: 'RealTraxMutation' }
  & { addEventsToDeal?: Maybe<(
    { __typename?: 'DealEventsResult' }
    & Pick<DealEventsResult, 'count'>
  )> }
);

export type RemoveEventsFromDealVariables = Exact<{
  dealId: Scalars['Int'];
  eventIds: Array<Scalars['Int']> | Scalars['Int'];
}>;


export type RemoveEventsFromDeal = (
  { __typename?: 'RealTraxMutation' }
  & { removeEventsFromDeal?: Maybe<(
    { __typename?: 'DealEventsResult' }
    & Pick<DealEventsResult, 'count'>
  )> }
);

export type CreateMetricVariables = Exact<{
  metric: CreateMetricInput;
}>;


export type CreateMetric = (
  { __typename?: 'RealTraxMutation' }
  & { createMetric: (
    { __typename: 'Metric' }
    & MetricFragment
  ) | (
    { __typename: 'MetricAlreadyExists' }
    & MetricAlreadyExistsFragment
    & ErrorFragment_MetricAlreadyExists_
  ) }
);

export type UpdateMetricVariables = Exact<{
  metric: UpdateMetricInput;
}>;


export type UpdateMetric = (
  { __typename?: 'RealTraxMutation' }
  & { updateMetric: (
    { __typename: 'Metric' }
    & MetricFragment
  ) | (
    { __typename: 'MetricAlreadyExists' }
    & MetricAlreadyExistsFragment
    & ErrorFragment_MetricAlreadyExists_
  ) | (
    { __typename: 'ErrorResult' }
    & Pick<ErrorResult, 'message'>
    & ErrorFragment_ErrorResult_
  ) }
);

export type DeleteMetricVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeleteMetric = (
  { __typename?: 'RealTraxMutation' }
  & { deleteMetric: (
    { __typename: 'Metric' }
    & MetricFragment
  ) | (
    { __typename: 'ErrorResult' }
    & Pick<ErrorResult, 'message'>
  ) }
);

export type MetricFragment = (
  { __typename?: 'Metric' }
  & Pick<Metric, 'id' | 'name' | 'format' | 'unit' | 'precision'>
);

export type MetricAlreadyExistsFragment = (
  { __typename?: 'MetricAlreadyExists' }
  & Pick<MetricAlreadyExists, 'message'>
  & { metric?: Maybe<(
    { __typename?: 'Metric' }
    & Pick<Metric, 'id' | 'name'>
  )> }
);

export type CreateTextMetricVariables = Exact<{
  textMetric: CreateTextMetricInput;
}>;


export type CreateTextMetric = (
  { __typename?: 'RealTraxMutation' }
  & { createTextMetric: (
    { __typename: 'TextMetric' }
    & TextMetricFragment
  ) | (
    { __typename: 'TextMetricAlreadyExists' }
    & TextMetricAlreadyExistsFragment
    & ErrorFragment_TextMetricAlreadyExists_
  ) }
);

export type UpdateTextMetricVariables = Exact<{
  textMetric: UpdateTextMetricInput;
}>;


export type UpdateTextMetric = (
  { __typename?: 'RealTraxMutation' }
  & { updateTextMetric: (
    { __typename: 'TextMetric' }
    & TextMetricFragment
  ) | (
    { __typename: 'TextMetricAlreadyExists' }
    & TextMetricAlreadyExistsFragment
    & ErrorFragment_TextMetricAlreadyExists_
  ) | (
    { __typename: 'ErrorResult' }
    & Pick<ErrorResult, 'message'>
    & ErrorFragment_ErrorResult_
  ) }
);

export type DeleteTextMetricVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeleteTextMetric = (
  { __typename?: 'RealTraxMutation' }
  & { deleteTextMetric: (
    { __typename: 'TextMetric' }
    & TextMetricFragment
  ) | (
    { __typename: 'ErrorResult' }
    & Pick<ErrorResult, 'message'>
  ) }
);

export type TextMetricFragment = (
  { __typename?: 'TextMetric' }
  & Pick<TextMetric, 'id' | 'name'>
);

export type TextMetricAlreadyExistsFragment = (
  { __typename?: 'TextMetricAlreadyExists' }
  & Pick<TextMetricAlreadyExists, 'message'>
  & { textMetric?: Maybe<(
    { __typename?: 'TextMetric' }
    & Pick<TextMetric, 'id' | 'name'>
  )> }
);

export type CreatePipelineVariables = Exact<{
  input: CreatePipelineInput;
}>;


export type CreatePipeline = (
  { __typename?: 'RealTraxMutation' }
  & { createPipeline?: Maybe<(
    { __typename?: 'Pipeline' }
    & Pick<Pipeline, 'id' | 'name' | 'description'>
  )> }
);

export type UpdatePipelineVariables = Exact<{
  input: UpdatePipelineInput;
}>;


export type UpdatePipeline = (
  { __typename?: 'RealTraxMutation' }
  & { updatePipeline?: Maybe<(
    { __typename?: 'Pipeline' }
    & Pick<Pipeline, 'id' | 'name' | 'description'>
  )> }
);

export type DeletePipelineVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeletePipeline = (
  { __typename?: 'RealTraxMutation' }
  & { deletePipeline?: Maybe<(
    { __typename: 'Pipeline' }
    & Pick<Pipeline, 'id' | 'name' | 'description'>
  ) | (
    { __typename: 'ErrorResult' }
    & Pick<ErrorResult, 'message'>
  )> }
);

export type AddChecklistsToPipelineVariables = Exact<{
  id: Scalars['Int'];
  checklistIds: Array<Scalars['Int']> | Scalars['Int'];
}>;


export type AddChecklistsToPipeline = (
  { __typename?: 'RealTraxMutation' }
  & { addChecklistsToPipeline?: Maybe<(
    { __typename?: 'PipelineEntityResult' }
    & Pick<PipelineEntityResult, 'count'>
  )> }
);

export type RemoveChecklistsFromPipelineVariables = Exact<{
  id: Scalars['Int'];
  checklistIds: Array<Scalars['Int']> | Scalars['Int'];
}>;


export type RemoveChecklistsFromPipeline = (
  { __typename?: 'RealTraxMutation' }
  & { removeChecklistsFromPipeline?: Maybe<(
    { __typename?: 'PipelineEntityResult' }
    & Pick<PipelineEntityResult, 'count'>
  )> }
);

export type AddPinnedMetricsToPipelineVariables = Exact<{
  id: Scalars['Int'];
  metricIds: Array<Scalars['Int']> | Scalars['Int'];
}>;


export type AddPinnedMetricsToPipeline = (
  { __typename?: 'RealTraxMutation' }
  & { addPinnedMetricsToPipeline?: Maybe<(
    { __typename?: 'PipelineEntityResult' }
    & Pick<PipelineEntityResult, 'count'>
  )> }
);

export type RemovePinnedMetricsFromPipelineVariables = Exact<{
  id: Scalars['Int'];
  metricIds: Array<Scalars['Int']> | Scalars['Int'];
}>;


export type RemovePinnedMetricsFromPipeline = (
  { __typename?: 'RealTraxMutation' }
  & { removePinnedMetricsFromPipeline?: Maybe<(
    { __typename?: 'PipelineEntityResult' }
    & Pick<PipelineEntityResult, 'count'>
  )> }
);

export type AddPinnedEventsToPipelineVariables = Exact<{
  id: Scalars['Int'];
  eventIds: Array<Scalars['Int']> | Scalars['Int'];
}>;


export type AddPinnedEventsToPipeline = (
  { __typename?: 'RealTraxMutation' }
  & { addPinnedEventsToPipeline?: Maybe<(
    { __typename?: 'PipelineEntityResult' }
    & Pick<PipelineEntityResult, 'count'>
  )> }
);

export type RemovePinnedEventsFromPipelineVariables = Exact<{
  id: Scalars['Int'];
  eventIds: Array<Scalars['Int']> | Scalars['Int'];
}>;


export type RemovePinnedEventsFromPipeline = (
  { __typename?: 'RealTraxMutation' }
  & { removePinnedEventsFromPipeline?: Maybe<(
    { __typename?: 'PipelineEntityResult' }
    & Pick<PipelineEntityResult, 'count'>
  )> }
);

export type AddPinnedPartnerRolesToPipelineVariables = Exact<{
  id: Scalars['Int'];
  roleIds: Array<Scalars['Int']> | Scalars['Int'];
}>;


export type AddPinnedPartnerRolesToPipeline = (
  { __typename?: 'RealTraxMutation' }
  & { addPinnedPartnerRolesToPipeline?: Maybe<(
    { __typename?: 'PipelineEntityResult' }
    & Pick<PipelineEntityResult, 'count'>
  )> }
);

export type RemovePinnedPartnerRolesFromPipelineVariables = Exact<{
  id: Scalars['Int'];
  roleIds: Array<Scalars['Int']> | Scalars['Int'];
}>;


export type RemovePinnedPartnerRolesFromPipeline = (
  { __typename?: 'RealTraxMutation' }
  & { removePinnedPartnerRolesFromPipeline?: Maybe<(
    { __typename?: 'PipelineEntityResult' }
    & Pick<PipelineEntityResult, 'count'>
  )> }
);

export type AddPinnedTeamMemberRolesToPipelineVariables = Exact<{
  id: Scalars['Int'];
  roleIds: Array<Scalars['Int']> | Scalars['Int'];
}>;


export type AddPinnedTeamMemberRolesToPipeline = (
  { __typename?: 'RealTraxMutation' }
  & { addPinnedTeamMemberRolesToPipeline?: Maybe<(
    { __typename?: 'PipelineEntityResult' }
    & Pick<PipelineEntityResult, 'count'>
  )> }
);

export type RemovePinnedTeamMemberRolesFromPipelineVariables = Exact<{
  id: Scalars['Int'];
  roleIds: Array<Scalars['Int']> | Scalars['Int'];
}>;


export type RemovePinnedTeamMemberRolesFromPipeline = (
  { __typename?: 'RealTraxMutation' }
  & { removePinnedTeamMemberRolesFromPipeline?: Maybe<(
    { __typename?: 'PipelineEntityResult' }
    & Pick<PipelineEntityResult, 'count'>
  )> }
);

export type AddEventsToPipelineVariables = Exact<{
  id: Scalars['Int'];
  eventIds: Array<Scalars['Int']> | Scalars['Int'];
}>;


export type AddEventsToPipeline = (
  { __typename?: 'RealTraxMutation' }
  & { addEventsToPipeline?: Maybe<(
    { __typename?: 'PipelineEntityResult' }
    & Pick<PipelineEntityResult, 'count'>
  )> }
);

export type RemoveEventsFromPipelineVariables = Exact<{
  id: Scalars['Int'];
  eventIds: Array<Scalars['Int']> | Scalars['Int'];
}>;


export type RemoveEventsFromPipeline = (
  { __typename?: 'RealTraxMutation' }
  & { removeEventsFromPipeline?: Maybe<(
    { __typename?: 'PipelineEntityResult' }
    & Pick<PipelineEntityResult, 'count'>
  )> }
);

export type AddModulesToPipelineVariables = Exact<{
  id: Scalars['Int'];
  moduleIds: Array<Scalars['Int']> | Scalars['Int'];
}>;


export type AddModulesToPipeline = (
  { __typename?: 'RealTraxMutation' }
  & { addModulesToPipeline?: Maybe<(
    { __typename?: 'PipelineEntityResult' }
    & Pick<PipelineEntityResult, 'count'>
  )> }
);

export type RemoveModulesFromPipelineVariables = Exact<{
  id: Scalars['Int'];
  moduleIds: Array<Scalars['Int']> | Scalars['Int'];
}>;


export type RemoveModulesFromPipeline = (
  { __typename?: 'RealTraxMutation' }
  & { removeModulesFromPipeline?: Maybe<(
    { __typename?: 'PipelineEntityResult' }
    & Pick<PipelineEntityResult, 'count'>
  )> }
);

export type AddForecastRulesToPipelineVariables = Exact<{
  id: Scalars['Int'];
  forecastRuleIds: Array<Scalars['Int']> | Scalars['Int'];
}>;


export type AddForecastRulesToPipeline = (
  { __typename?: 'RealTraxMutation' }
  & { addForecastRulesToPipeline?: Maybe<(
    { __typename?: 'PipelineEntityResult' }
    & Pick<PipelineEntityResult, 'count'>
  )> }
);

export type RemoveForecastRulesFromPipelineVariables = Exact<{
  id: Scalars['Int'];
  forecastRuleIds: Array<Scalars['Int']> | Scalars['Int'];
}>;


export type RemoveForecastRulesFromPipeline = (
  { __typename?: 'RealTraxMutation' }
  & { removeForecastRulesFromPipeline?: Maybe<(
    { __typename?: 'PipelineEntityResult' }
    & Pick<PipelineEntityResult, 'count'>
  )> }
);

export type EnableForecastRulesVariables = Exact<{
  fkDeal: Scalars['Int'];
  forecastRules: Array<Scalars['Int']> | Scalars['Int'];
}>;


export type EnableForecastRules = (
  { __typename?: 'RealTraxMutation' }
  & Pick<RealTraxMutation, 'enableForecastRules'>
);

export type DisableForecastRulesVariables = Exact<{
  fkDeal: Scalars['Int'];
  forecastRules: Array<Scalars['Int']> | Scalars['Int'];
}>;


export type DisableForecastRules = (
  { __typename?: 'RealTraxMutation' }
  & Pick<RealTraxMutation, 'disableForecastRules'>
);

export type PublishScheduleInstanceVariables = Exact<{
  instance: CreatePublishedScheduleInstanceInput;
}>;


export type PublishScheduleInstance = (
  { __typename?: 'RealTraxMutation' }
  & { publishScheduleInstance?: Maybe<(
    { __typename?: 'PublishedScheduleInstance' }
    & PublishedScheduleIntanceFragment
  )> }
);

export type CreateScheduleInstanceVariables = Exact<{
  instance: CreatePublishedScheduleInstanceInput;
}>;


export type CreateScheduleInstance = (
  { __typename?: 'RealTraxMutation' }
  & { createScheduleInstance?: Maybe<(
    { __typename?: 'PublishedScheduleInstance' }
    & PublishedScheduleIntanceFragment
  )> }
);

export type UpdateScheduleInstanceVariables = Exact<{
  instance: UpdatePublishedScheduleInstanceInput;
}>;


export type UpdateScheduleInstance = (
  { __typename?: 'RealTraxMutation' }
  & { updateScheduleInstance?: Maybe<(
    { __typename?: 'PublishedScheduleInstance' }
    & PublishedScheduleIntanceFragment
  )> }
);

export type DeleteScheduleInstanceVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeleteScheduleInstance = (
  { __typename?: 'RealTraxMutation' }
  & { deleteScheduleInstance?: Maybe<(
    { __typename?: 'PublishedScheduleInstance' }
    & PublishedScheduleIntanceFragment
  )> }
);

export type PublishedScheduleIntanceFragment = (
  { __typename?: 'PublishedScheduleInstance' }
  & Pick<PublishedScheduleInstance, 'id' | 'description' | 'createdAt' | 'updatedAt'>
);

export type CreatePublishedDealEventVariables = Exact<{
  event: CreatePublishedDealEventInput;
}>;


export type CreatePublishedDealEvent = (
  { __typename?: 'RealTraxMutation' }
  & { createPublishedDealEvent?: Maybe<(
    { __typename: 'PublishedDealEvent' }
    & PublishedDealEventFragment
  ) | (
    { __typename: 'PublishedDealEventAlreadyExists' }
    & PublishedDealEventAlreadyExistsFragment
    & ErrorFragment_PublishedDealEventAlreadyExists_
  )> }
);

export type UpdatePublishedDealEventVariables = Exact<{
  event: UpdatePublishedDealEventInput;
}>;


export type UpdatePublishedDealEvent = (
  { __typename?: 'RealTraxMutation' }
  & { updatePublishedDealEvent?: Maybe<(
    { __typename: 'PublishedDealEvent' }
    & PublishedDealEventFragment
  ) | (
    { __typename: 'PublishedDealEventAlreadyExists' }
    & PublishedDealEventAlreadyExistsFragment
    & ErrorFragment_PublishedDealEventAlreadyExists_
  )> }
);

export type PublishedDealEventAlreadyExistsFragment = (
  { __typename?: 'PublishedDealEventAlreadyExists' }
  & Pick<PublishedDealEventAlreadyExists, 'message'>
  & { event?: Maybe<(
    { __typename?: 'PublishedDealEvent' }
    & Pick<PublishedDealEvent, 'id' | 'name'>
  )> }
);

export type CreateBuildingVariables = Exact<{
  building: CreateBuildingInput;
}>;


export type CreateBuilding = (
  { __typename?: 'RealTraxMutation' }
  & { createBuilding?: Maybe<(
    { __typename?: 'Building' }
    & BuildingFragment
  )> }
);

export type UpdateBuildingVariables = Exact<{
  building: UpdateBuildingInput;
}>;


export type UpdateBuilding = (
  { __typename?: 'RealTraxMutation' }
  & { updateBuilding?: Maybe<(
    { __typename?: 'Building' }
    & BuildingFragment
  )> }
);

export type DeleteBuildingVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeleteBuilding = (
  { __typename?: 'RealTraxMutation' }
  & { deleteBuilding?: Maybe<(
    { __typename?: 'Building' }
    & BuildingFragment
  )> }
);

export type CreateBondVariables = Exact<{
  bond: CreateBondInput;
}>;


export type CreateBond = (
  { __typename?: 'RealTraxMutation' }
  & { createBond?: Maybe<(
    { __typename?: 'Bond' }
    & BondFragment
  )> }
);

export type UpdateBondVariables = Exact<{
  bond: UpdateBondInput;
}>;


export type UpdateBond = (
  { __typename?: 'RealTraxMutation' }
  & { updateBond?: Maybe<(
    { __typename?: 'Bond' }
    & BondFragment
  )> }
);

export type DeleteBondVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeleteBond = (
  { __typename?: 'RealTraxMutation' }
  & { deleteBond?: Maybe<(
    { __typename?: 'Bond' }
    & BondFragment
  )> }
);

export type BondFragment = (
  { __typename?: 'Bond' }
  & Pick<Bond, 'id' | 'name' | 'createdAt' | 'updatedAt' | 'isComplete' | 'bondDescription' | 'principalEntity' | 'obligeeBeneficiary' | 'premium' | 'bondAmount' | 'collateralAmount' | 'locAmount' | 'acceptedAlternatives' | 'breakdownBackup' | 'describedWork' | 'originalEffectiveDate' | 'expires' | 'expectedRelease' | 'conditionsForRelease' | 'premiumRefundDue' | 'notes' | 'improvementDeadline' | 'premiumComment' | 'collateralNotes' | 'expiresNotes'>
  & { createdBy: (
    { __typename?: 'SystemProcess' }
    & Pick<SystemProcess, 'id' | 'displayName'>
  ) | (
    { __typename?: 'User' }
    & Pick<User, 'id' | 'displayName'>
  ), updatedBy: (
    { __typename?: 'SystemProcess' }
    & Pick<SystemProcess, 'id' | 'displayName'>
  ) | (
    { __typename?: 'User' }
    & Pick<User, 'id' | 'displayName'>
  ), bondingCompany?: Maybe<(
    { __typename?: 'Firm' }
    & Pick<Firm, 'id' | 'name'>
  )> }
);

export type CreateGcEstimateVariables = Exact<{
  gcEstimate: CreateGcEstimateInput;
}>;


export type CreateGcEstimate = (
  { __typename?: 'RealTraxMutation' }
  & { createGcEstimate?: Maybe<(
    { __typename?: 'GcEstimate' }
    & GcEstimateFragment
  )> }
);

export type UpdateGcEstimateVariables = Exact<{
  gcEstimate: UpdateGcEstimateInput;
}>;


export type UpdateGcEstimate = (
  { __typename?: 'RealTraxMutation' }
  & { updateGcEstimate?: Maybe<(
    { __typename?: 'GcEstimate' }
    & GcEstimateFragment
  )> }
);

export type DeleteGcEstimateVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeleteGcEstimate = (
  { __typename?: 'RealTraxMutation' }
  & { deleteGcEstimate?: Maybe<(
    { __typename?: 'GcEstimate' }
    & GcEstimateFragment
  )> }
);

export type DealPinnedMetricFragment = (
  { __typename?: 'DealPinnedMetric' }
  & { metric: (
    { __typename?: 'Metric' }
    & MetricFragment
  ) }
);

export type CreateGlAccountVariables = Exact<{
  account: CreateGlAccountInput;
}>;


export type CreateGlAccount = (
  { __typename?: 'RealTraxMutation' }
  & { createGlAccount: (
    { __typename: 'GlAccount' }
    & GlAccountFragment
  ) | (
    { __typename: 'ErrorResult' }
    & ErrorFragment_ErrorResult_
  ) }
);

export type UpdateGlAccountVariables = Exact<{
  account: UpdateGlAccountInput;
}>;


export type UpdateGlAccount = (
  { __typename?: 'RealTraxMutation' }
  & { updateGlAccount: (
    { __typename: 'GlAccount' }
    & GlAccountFragment
  ) | (
    { __typename: 'ErrorResult' }
    & Pick<ErrorResult, 'message'>
    & ErrorFragment_ErrorResult_
  ) | (
    { __typename: 'AlreadyExists' }
    & ErrorFragment_AlreadyExists_
  ) }
);

export type DeleteGlAccountVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeleteGlAccount = (
  { __typename?: 'RealTraxMutation' }
  & { deleteGlAccount: (
    { __typename: 'GlAccount' }
    & GlAccountFragment
  ) | (
    { __typename: 'ErrorResult' }
    & Pick<ErrorResult, 'message'>
  ) }
);

export type GlAccountFragment = (
  { __typename?: 'GlAccount' }
  & Pick<GlAccount, 'id' | 'name' | 'code' | 'displayCode' | 'isDisabled' | 'isAppDefault'>
  & { type?: Maybe<(
    { __typename?: 'GlAccountType' }
    & Pick<GlAccountType, 'id' | 'name'>
  )>, category?: Maybe<(
    { __typename?: 'GlAccountCategory' }
    & Pick<GlAccountCategory, 'id' | 'name'>
  )> }
);

export type CreateGlAccountTreeVariables = Exact<{
  accountTree: CreateGlAccountTreeInput;
}>;


export type CreateGlAccountTree = (
  { __typename?: 'RealTraxMutation' }
  & { createGlAccountTree: (
    { __typename: 'GlAccountTree' }
    & GlAccountTreeFragment
  ) | (
    { __typename: 'ErrorResult' }
    & ErrorFragment_ErrorResult_
  ) }
);

export type UpdateGlAccountTreeVariables = Exact<{
  accountTree: UpdateGlAccountTreeInput;
}>;


export type UpdateGlAccountTree = (
  { __typename?: 'RealTraxMutation' }
  & { updateGlAccountTree: (
    { __typename: 'GlAccountTree' }
    & GlAccountTreeFragment
  ) | (
    { __typename: 'ErrorResult' }
    & Pick<ErrorResult, 'message'>
    & ErrorFragment_ErrorResult_
  ) | (
    { __typename: 'AlreadyExists' }
    & ErrorFragment_AlreadyExists_
  ) }
);

export type DeleteGlAccountTreeVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeleteGlAccountTree = (
  { __typename?: 'RealTraxMutation' }
  & { deleteGlAccountTree: (
    { __typename: 'GlAccountTree' }
    & GlAccountTreeFragment
  ) | (
    { __typename: 'ErrorResult' }
    & Pick<ErrorResult, 'message'>
  ) }
);

export type GlAccountTreeFragment = (
  { __typename?: 'GlAccountTree' }
  & Pick<GlAccountTree, 'id' | 'name' | 'isDisabled' | 'isAppDefault'>
  & { reportType?: Maybe<(
    { __typename?: 'ReportType' }
    & Pick<ReportType, 'id' | 'name'>
  )> }
);

export type CreatePipelineContingencyCategoryVariables = Exact<{
  accountCategorization: CreatePipelineContingencyCategoryInput;
}>;


export type CreatePipelineContingencyCategory = (
  { __typename?: 'RealTraxMutation' }
  & { createPipelineContingencyCategory: (
    { __typename: 'PipelineContingencyCategory' }
    & PipelineContingencyCategoryFragment
  ) }
);

export type UpdatePipelineContingencyCategoryVariables = Exact<{
  accountCategorization: UpdatePipelineContingencyCategoryInput;
}>;


export type UpdatePipelineContingencyCategory = (
  { __typename?: 'RealTraxMutation' }
  & { updatePipelineContingencyCategory: (
    { __typename: 'PipelineContingencyCategory' }
    & PipelineContingencyCategoryFragment
  ) }
);

export type DeletePipelineContingencyCategoryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeletePipelineContingencyCategory = (
  { __typename?: 'RealTraxMutation' }
  & { deletePipelineContingencyCategory: (
    { __typename: 'PipelineContingencyCategory' }
    & PipelineContingencyCategoryFragment
  ) }
);

export type PipelineContingencyCategoryFragment = (
  { __typename?: 'PipelineContingencyCategory' }
  & Pick<PipelineContingencyCategory, 'id'>
  & { account: (
    { __typename?: 'GlAccount' }
    & Pick<GlAccount, 'id' | 'name' | 'code' | 'displayCode'>
  ), category: (
    { __typename?: 'ContingencyCategory' }
    & Pick<ContingencyCategory, 'id' | 'name'>
  ) }
);

export type CreatePipelineGeneralOffsetVariables = Exact<{
  generalOffsetRule: CreatePipelineGeneralOffsetInput;
}>;


export type CreatePipelineGeneralOffset = (
  { __typename?: 'RealTraxMutation' }
  & { createPipelineGeneralOffset: (
    { __typename: 'PipelineGeneralOffset' }
    & PipelineGeneralOffsetFragment
  ) }
);

export type UpdatePipelineGeneralOffsetVariables = Exact<{
  generalOffsetRule: UpdatePipelineGeneralOffsetInput;
}>;


export type UpdatePipelineGeneralOffset = (
  { __typename?: 'RealTraxMutation' }
  & { updatePipelineGeneralOffset: (
    { __typename: 'PipelineGeneralOffset' }
    & PipelineGeneralOffsetFragment
  ) }
);

export type DeletePipelineGeneralOffsetVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeletePipelineGeneralOffset = (
  { __typename?: 'RealTraxMutation' }
  & { deletePipelineGeneralOffset: (
    { __typename: 'PipelineGeneralOffset' }
    & PipelineGeneralOffsetFragment
  ) }
);

export type PipelineGeneralOffsetFragment = (
  { __typename?: 'PipelineGeneralOffset' }
  & Pick<PipelineGeneralOffset, 'id' | 'offset'>
  & { event?: Maybe<(
    { __typename?: 'Event' }
    & Pick<Event, 'id' | 'name' | 'type'>
  )>, filter?: Maybe<(
    { __typename?: 'CustomFilters' }
    & Pick<CustomFilters, 'id' | 'name'>
  )> }
);

export type CreatePipelineAccountOffsetVariables = Exact<{
  accountOffsetRule: CreatePipelineAccountOffsetInput;
}>;


export type CreatePipelineAccountOffset = (
  { __typename?: 'RealTraxMutation' }
  & { createPipelineAccountOffset: (
    { __typename: 'PipelineAccountOffset' }
    & PipelineAccountOffsetFragment
  ) }
);

export type UpdatePipelineAccountOffsetVariables = Exact<{
  accountOffsetRule: UpdatePipelineAccountOffsetInput;
}>;


export type UpdatePipelineAccountOffset = (
  { __typename?: 'RealTraxMutation' }
  & { updatePipelineAccountOffset: (
    { __typename: 'PipelineAccountOffset' }
    & PipelineAccountOffsetFragment
  ) }
);

export type DeletePipelineAccountOffsetVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeletePipelineAccountOffset = (
  { __typename?: 'RealTraxMutation' }
  & { deletePipelineAccountOffset: (
    { __typename: 'PipelineAccountOffset' }
    & PipelineAccountOffsetFragment
  ) }
);

export type PipelineAccountOffsetFragment = (
  { __typename?: 'PipelineAccountOffset' }
  & Pick<PipelineAccountOffset, 'id' | 'offset'>
  & { account: (
    { __typename?: 'GlAccount' }
    & Pick<GlAccount, 'id' | 'name' | 'code' | 'displayCode'>
  ), event?: Maybe<(
    { __typename?: 'Event' }
    & Pick<Event, 'id' | 'name' | 'type'>
  )>, filter?: Maybe<(
    { __typename?: 'CustomFilters' }
    & Pick<CustomFilters, 'id' | 'name'>
  )> }
);

export type CreateForecastRuleVariables = Exact<{
  forecastRule: CreateForecastRuleInput;
}>;


export type CreateForecastRule = (
  { __typename?: 'RealTraxMutation' }
  & { createForecastRule: (
    { __typename: 'ForecastRule' }
    & ForecastRuleFragment
  ) }
);

export type UpdateForecastRuleVariables = Exact<{
  forecastRule: UpdateForecastRuleInput;
}>;


export type UpdateForecastRule = (
  { __typename?: 'RealTraxMutation' }
  & { updateForecastRule: (
    { __typename: 'ForecastRule' }
    & ForecastRuleFragment
  ) }
);

export type DeleteForecastRuleVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeleteForecastRule = (
  { __typename?: 'RealTraxMutation' }
  & { deleteForecastRule: (
    { __typename: 'ForecastRule' }
    & ForecastRuleFragment
  ) }
);

export type ForecastRuleFragment = (
  { __typename?: 'ForecastRule' }
  & Pick<ForecastRule, 'id' | 'description' | 'fkForecastRuleGroup' | 'fkForecastRuleType' | 'milestoneAdjustmentStart' | 'milestoneAdjustmentEnd' | 'frequency' | 'startMonth' | 'dollarAmount' | 'dollarPercent' | 'isDisabled' | 'usesBalance' | 'isSliding' | 'phaseSpendingCapPercent' | 'fkEventEndUsesStartDate' | 'fkEventStartUsesStartDate'>
  & { account: (
    { __typename?: 'GlAccount' }
    & Pick<GlAccount, 'id' | 'name' | 'code' | 'displayCode'>
  ), distributionType: (
    { __typename?: 'DistributionType' }
    & Pick<DistributionType, 'id' | 'name'>
  ), startEvent: (
    { __typename?: 'Event' }
    & Pick<Event, 'id' | 'name' | 'type'>
  ), endEvent: (
    { __typename?: 'Event' }
    & Pick<Event, 'id' | 'name' | 'type'>
  ) }
);

export type AddAccountsToAccountTreeVariables = Exact<{
  id: Scalars['Int'];
  parentId?: Maybe<Scalars['Int']>;
  accounts: Array<Scalars['Int']> | Scalars['Int'];
}>;


export type AddAccountsToAccountTree = (
  { __typename?: 'RealTraxMutation' }
  & { addAccountsToAccountTree?: Maybe<Array<(
    { __typename?: 'GlAccountTreeBranch' }
    & Pick<GlAccountTreeBranch, 'id' | 'level' | 'fkParentBranch' | 'reverseFromDrCr'>
    & { account: (
      { __typename?: 'GlAccount' }
      & Pick<GlAccount, 'id' | 'name' | 'code' | 'displayCode'>
    ) }
  )>> }
);

export type RemoveAccountsFromAccountTreeVariables = Exact<{
  id: Scalars['Int'];
  accounts: Array<Scalars['Int']> | Scalars['Int'];
}>;


export type RemoveAccountsFromAccountTree = (
  { __typename?: 'RealTraxMutation' }
  & { removeAccountsFromAccountTree?: Maybe<Array<(
    { __typename?: 'GlAccountTreeBranch' }
    & Pick<GlAccountTreeBranch, 'id' | 'level' | 'fkParentBranch' | 'reverseFromDrCr'>
    & { account: (
      { __typename?: 'GlAccount' }
      & Pick<GlAccount, 'id' | 'name' | 'code' | 'displayCode'>
    ) }
  )>> }
);

export type ChangeAccountTreeParentVariables = Exact<{
  id: Scalars['Int'];
  parentId?: Maybe<Scalars['Int']>;
  accounts: Array<Scalars['Int']> | Scalars['Int'];
}>;


export type ChangeAccountTreeParent = (
  { __typename?: 'RealTraxMutation' }
  & { changeAccountTreeParent?: Maybe<Array<(
    { __typename?: 'GlAccountTreeBranch' }
    & Pick<GlAccountTreeBranch, 'id' | 'level' | 'fkParentBranch' | 'reverseFromDrCr'>
    & { account: (
      { __typename?: 'GlAccount' }
      & Pick<GlAccount, 'id' | 'name' | 'code' | 'displayCode'>
    ) }
  )>> }
);

export type CreateChangeEventVariables = Exact<{
  changeEvent: CreateChangeEventInput;
}>;


export type CreateChangeEvent = (
  { __typename?: 'RealTraxMutation' }
  & { createChangeEvent?: Maybe<(
    { __typename?: 'ChangeEvent' }
    & ChangeEventFragment
  )> }
);

export type UpdateChangeEventVariables = Exact<{
  changeEvent: UpdateChangeEventInput;
}>;


export type UpdateChangeEvent = (
  { __typename?: 'RealTraxMutation' }
  & { updateChangeEvent?: Maybe<(
    { __typename?: 'ChangeEvent' }
    & ChangeEventFragment
  )> }
);

export type DeleteChangeEventVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeleteChangeEvent = (
  { __typename?: 'RealTraxMutation' }
  & { deleteChangeEvent?: Maybe<(
    { __typename?: 'ChangeEvent' }
    & ChangeEventFragment
  )> }
);

export type CreatePotentialChangeOrderVariables = Exact<{
  potentialChangeOrder: CreatePotentialChangeOrderInput;
}>;


export type CreatePotentialChangeOrder = (
  { __typename?: 'RealTraxMutation' }
  & { createPotentialChangeOrder?: Maybe<(
    { __typename?: 'PotentialChangeOrder' }
    & PotentialChangeOrderFragment
  )> }
);

export type UpdatePotentialChangeOrderVariables = Exact<{
  potentialChangeOrder: UpdatePotentialChangeOrderInput;
}>;


export type UpdatePotentialChangeOrder = (
  { __typename?: 'RealTraxMutation' }
  & { updatePotentialChangeOrder?: Maybe<(
    { __typename?: 'PotentialChangeOrder' }
    & PotentialChangeOrderFragment
  )> }
);

export type DeletePotentialChangeOrderVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeletePotentialChangeOrder = (
  { __typename?: 'RealTraxMutation' }
  & { deletePotentialChangeOrder?: Maybe<(
    { __typename?: 'PotentialChangeOrder' }
    & PotentialChangeOrderFragment
  )> }
);

export type CreateChangeOrderPackageVariables = Exact<{
  changeOrderPackage: CreateChangeOrderPackageInput;
}>;


export type CreateChangeOrderPackage = (
  { __typename?: 'RealTraxMutation' }
  & { createChangeOrderPackage?: Maybe<(
    { __typename?: 'ChangeOrderPackage' }
    & ChangeOrderPackageFragment
  )> }
);

export type UpdateChangeOrderPackageVariables = Exact<{
  changeOrderPackage: UpdateChangeOrderPackageInput;
}>;


export type UpdateChangeOrderPackage = (
  { __typename?: 'RealTraxMutation' }
  & { updateChangeOrderPackage?: Maybe<(
    { __typename?: 'ChangeOrderPackage' }
    & ChangeOrderPackageFragment
  )> }
);

export type DeleteChangeOrderPackageVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeleteChangeOrderPackage = (
  { __typename?: 'RealTraxMutation' }
  & { deleteChangeOrderPackage?: Maybe<(
    { __typename?: 'ChangeOrderPackage' }
    & ChangeOrderPackageFragment
  )> }
);

export type CreateLabelVariables = Exact<{
  label: CreateLabelInput;
}>;


export type CreateLabel = (
  { __typename?: 'RealTraxMutation' }
  & { createLabel?: Maybe<(
    { __typename?: 'Label' }
    & Pick<Label, 'id' | 'name' | 'color'>
  )> }
);

export type UpdateLabelVariables = Exact<{
  label: UpdateLabelInput;
}>;


export type UpdateLabel = (
  { __typename?: 'RealTraxMutation' }
  & { updateLabel?: Maybe<(
    { __typename?: 'Label' }
    & Pick<Label, 'id' | 'name' | 'color'>
  )> }
);

export type DeleteLabelVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeleteLabel = (
  { __typename?: 'RealTraxMutation' }
  & { deleteLabel?: Maybe<(
    { __typename?: 'Label' }
    & Pick<Label, 'id' | 'name' | 'color'>
  )> }
);

export type CreateApprovalPackageVariables = Exact<{
  approvalPackage: CreateApprovalPackageInput;
}>;


export type CreateApprovalPackage = (
  { __typename?: 'RealTraxMutation' }
  & { createApprovalPackage: (
    { __typename: 'ApprovalPackage' }
    & ApprovalPackageFragment
  ) | (
    { __typename: 'ErrorResult' }
    & Pick<ErrorResult, 'message'>
  ) }
);

export type UpdateApprovalPackageVariables = Exact<{
  approvalPackage: UpdateApprovalPackageInput;
}>;


export type UpdateApprovalPackage = (
  { __typename?: 'RealTraxMutation' }
  & { updateApprovalPackage: (
    { __typename: 'ApprovalPackage' }
    & ApprovalPackageFragment
  ) | (
    { __typename: 'ErrorResult' }
    & Pick<ErrorResult, 'message'>
  ) | { __typename: 'AlreadyExists' } }
);

export type ArchiveApprovalPackageVariables = Exact<{
  id: Scalars['Int'];
  status: ApprovalPackageStatusEnum;
}>;


export type ArchiveApprovalPackage = (
  { __typename?: 'RealTraxMutation' }
  & { archiveApprovalPackage: (
    { __typename: 'ApprovalPackage' }
    & ApprovalPackageFragment
  ) | (
    { __typename: 'ErrorResult' }
    & Pick<ErrorResult, 'message'>
  ) | { __typename: 'AlreadyExists' } }
);

export type DeleteApprovalPackageVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeleteApprovalPackage = (
  { __typename?: 'RealTraxMutation' }
  & { deleteApprovalPackage: (
    { __typename: 'ApprovalPackage' }
    & ApprovalPackageFragment
  ) | (
    { __typename: 'ErrorResult' }
    & Pick<ErrorResult, 'message'>
  ) }
);

export type ApprovalPackageFragment = (
  { __typename?: 'ApprovalPackage' }
  & Pick<ApprovalPackage, 'id' | 'updatedAt' | 'createdAt' | 'reviewDate' | 'isPublished' | 'status' | 'amendmentNumber'>
  & { type: (
    { __typename?: 'ApprovalPackageType' }
    & Pick<ApprovalPackageType, 'id' | 'name'>
  ), deal: (
    { __typename?: 'Deal' }
    & Pick<Deal, 'id' | 'name'>
  ) }
);

export type UpdateApprovalPackageSubSectionTextVariables = Exact<{
  subSectionText: UpdateApprovalPackageSubSectionTextInput;
}>;


export type UpdateApprovalPackageSubSectionText = (
  { __typename?: 'RealTraxMutation' }
  & { updateApprovalPackageSubsectionText: (
    { __typename?: 'ApprovalPackageSubSectionText' }
    & Pick<ApprovalPackageSubSectionText, 'id' | 'title' | 'text'>
  ) }
);

export type UpdateApprovalPackageSubSectionChecklistVariables = Exact<{
  subSectionChecklist: UpdateApprovalPackageSubSectionChecklistInput;
}>;


export type UpdateApprovalPackageSubSectionChecklist = (
  { __typename?: 'RealTraxMutation' }
  & { updateApprovalPackageSubsectionChecklist: (
    { __typename?: 'ApprovalPackageSubSectionChecklist' }
    & Pick<ApprovalPackageSubSectionChecklist, 'id' | 'title'>
  ) }
);

export type CreateApprovalPackageSubSectionVariables = Exact<{
  sectionId: Scalars['Int'];
  type: ApprovalPackageSubSectionTypeEnum;
  checklistId?: Maybe<Scalars['Int']>;
  gcEstimateTypeId?: Maybe<Scalars['Int']>;
}>;


export type CreateApprovalPackageSubSection = (
  { __typename?: 'RealTraxMutation' }
  & { createApprovalPackageSubSection: (
    { __typename?: 'ApprovalPackageSubSectionText' }
    & ApprovalPackageSubSectionFragment_ApprovalPackageSubSectionText_
  ) | (
    { __typename?: 'ApprovalPackageSubSectionDealBackground' }
    & ApprovalPackageSubSectionFragment_ApprovalPackageSubSectionDealBackground_
  ) | (
    { __typename?: 'ApprovalPackageSubSectionEarnestMoneyDeposit' }
    & ApprovalPackageSubSectionFragment_ApprovalPackageSubSectionEarnestMoneyDeposit_
  ) | (
    { __typename?: 'ApprovalPackageSubSectionChecklist' }
    & ApprovalPackageSubSectionFragment_ApprovalPackageSubSectionChecklist_
  ) | (
    { __typename?: 'ApprovalPackageSubSectionGcEstimate' }
    & ApprovalPackageSubSectionFragment_ApprovalPackageSubSectionGcEstimate_
  ) | (
    { __typename?: 'ApprovalPackageSubSectionKeyRisks' }
    & ApprovalPackageSubSectionFragment_ApprovalPackageSubSectionKeyRisks_
  ) | (
    { __typename?: 'ApprovalPackageSubSectionMerits' }
    & ApprovalPackageSubSectionFragment_ApprovalPackageSubSectionMerits_
  ) | (
    { __typename?: 'ApprovalPackageSubSectionTable' }
    & ApprovalPackageSubSectionFragment_ApprovalPackageSubSectionTable_
  ) | (
    { __typename?: 'ApprovalPackageSubSectionImages' }
    & ApprovalPackageSubSectionFragment_ApprovalPackageSubSectionImages_
  ) | (
    { __typename?: 'ApprovalPackageSubSectionPageBreak' }
    & ApprovalPackageSubSectionFragment_ApprovalPackageSubSectionPageBreak_
  ) }
);

export type UpdateApprovalPackageSubSectionVariables = Exact<{
  subSection: UpdateApprovalPackageSubSectionInput;
}>;


export type UpdateApprovalPackageSubSection = (
  { __typename?: 'RealTraxMutation' }
  & { updateApprovalPackageSubsection: (
    { __typename?: 'ApprovalPackageSubSectionText' }
    & ApprovalPackageSubSectionFragment_ApprovalPackageSubSectionText_
  ) | (
    { __typename?: 'ApprovalPackageSubSectionDealBackground' }
    & ApprovalPackageSubSectionFragment_ApprovalPackageSubSectionDealBackground_
  ) | (
    { __typename?: 'ApprovalPackageSubSectionEarnestMoneyDeposit' }
    & ApprovalPackageSubSectionFragment_ApprovalPackageSubSectionEarnestMoneyDeposit_
  ) | (
    { __typename?: 'ApprovalPackageSubSectionChecklist' }
    & ApprovalPackageSubSectionFragment_ApprovalPackageSubSectionChecklist_
  ) | (
    { __typename?: 'ApprovalPackageSubSectionGcEstimate' }
    & ApprovalPackageSubSectionFragment_ApprovalPackageSubSectionGcEstimate_
  ) | (
    { __typename?: 'ApprovalPackageSubSectionKeyRisks' }
    & ApprovalPackageSubSectionFragment_ApprovalPackageSubSectionKeyRisks_
  ) | (
    { __typename?: 'ApprovalPackageSubSectionMerits' }
    & ApprovalPackageSubSectionFragment_ApprovalPackageSubSectionMerits_
  ) | (
    { __typename?: 'ApprovalPackageSubSectionTable' }
    & ApprovalPackageSubSectionFragment_ApprovalPackageSubSectionTable_
  ) | (
    { __typename?: 'ApprovalPackageSubSectionImages' }
    & ApprovalPackageSubSectionFragment_ApprovalPackageSubSectionImages_
  ) | (
    { __typename?: 'ApprovalPackageSubSectionPageBreak' }
    & ApprovalPackageSubSectionFragment_ApprovalPackageSubSectionPageBreak_
  ) }
);

export type DeleteApprovalPackageSubSectionVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeleteApprovalPackageSubSection = (
  { __typename?: 'RealTraxMutation' }
  & { deleteApprovalPackageSubsection: (
    { __typename?: 'ApprovalPackageSubSectionText' }
    & ApprovalPackageSubSectionFragment_ApprovalPackageSubSectionText_
  ) | (
    { __typename?: 'ApprovalPackageSubSectionDealBackground' }
    & ApprovalPackageSubSectionFragment_ApprovalPackageSubSectionDealBackground_
  ) | (
    { __typename?: 'ApprovalPackageSubSectionEarnestMoneyDeposit' }
    & ApprovalPackageSubSectionFragment_ApprovalPackageSubSectionEarnestMoneyDeposit_
  ) | (
    { __typename?: 'ApprovalPackageSubSectionChecklist' }
    & ApprovalPackageSubSectionFragment_ApprovalPackageSubSectionChecklist_
  ) | (
    { __typename?: 'ApprovalPackageSubSectionGcEstimate' }
    & ApprovalPackageSubSectionFragment_ApprovalPackageSubSectionGcEstimate_
  ) | (
    { __typename?: 'ApprovalPackageSubSectionKeyRisks' }
    & ApprovalPackageSubSectionFragment_ApprovalPackageSubSectionKeyRisks_
  ) | (
    { __typename?: 'ApprovalPackageSubSectionMerits' }
    & ApprovalPackageSubSectionFragment_ApprovalPackageSubSectionMerits_
  ) | (
    { __typename?: 'ApprovalPackageSubSectionTable' }
    & ApprovalPackageSubSectionFragment_ApprovalPackageSubSectionTable_
  ) | (
    { __typename?: 'ApprovalPackageSubSectionImages' }
    & ApprovalPackageSubSectionFragment_ApprovalPackageSubSectionImages_
  ) | (
    { __typename?: 'ApprovalPackageSubSectionPageBreak' }
    & ApprovalPackageSubSectionFragment_ApprovalPackageSubSectionPageBreak_
  ) }
);

export type CreateApprovalPackageSectionVariables = Exact<{
  section: CreateApprovalPackageSectionInput;
}>;


export type CreateApprovalPackageSection = (
  { __typename?: 'RealTraxMutation' }
  & { createApprovalPackageSection: (
    { __typename?: 'ApprovalPackageSection' }
    & Pick<ApprovalPackageSection, 'id' | 'title' | 'orderId'>
  ) }
);

export type UpdateApprovalPackageSectionVariables = Exact<{
  section: UpdateApprovalPackageSectionInput;
}>;


export type UpdateApprovalPackageSection = (
  { __typename?: 'RealTraxMutation' }
  & { updateApprovalPackageSection: (
    { __typename?: 'ApprovalPackageSection' }
    & Pick<ApprovalPackageSection, 'id' | 'title' | 'orderId'>
  ) }
);

export type DeleteApprovalPackageSectionVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeleteApprovalPackageSection = (
  { __typename?: 'RealTraxMutation' }
  & { deleteApprovalPackageSection: (
    { __typename?: 'ApprovalPackageSection' }
    & Pick<ApprovalPackageSection, 'id' | 'title' | 'orderId'>
  ) }
);

export type SetApprovalPackageSubSectionTableColumnsVariables = Exact<{
  id: Scalars['Int'];
  columns: Scalars['Int'];
}>;


export type SetApprovalPackageSubSectionTableColumns = (
  { __typename?: 'RealTraxMutation' }
  & { setApprovalPackageSubSectionTableColumns: (
    { __typename?: 'ApprovalPackageSubSectionTable' }
    & Pick<ApprovalPackageSubSectionTable, 'id' | 'title' | 'orderId' | 'gridColumnWidth' | 'columns'>
    & { cells?: Maybe<Array<(
      { __typename?: 'IcSubSectionTableCell' }
      & Pick<IcSubSectionTableCell, 'id' | 'orderId'>
      & { entries?: Maybe<Array<(
        { __typename?: 'IcSubSectionTableCellEntry' }
        & Pick<IcSubSectionTableCellEntry, 'id' | 'name' | 'orderId' | 'type'>
      )>>, attributes?: Maybe<Array<(
        { __typename?: 'ApprovalPackageSubSectionDealAttribute' }
        & Pick<ApprovalPackageSubSectionDealAttribute, 'value'>
        & { attribute: (
          { __typename?: 'DealAttribute' }
          & Pick<DealAttribute, 'id' | 'displayName' | 'columnName' | 'dataType'>
        ) }
      )>>, teamMembers?: Maybe<Array<(
        { __typename?: 'ApprovalPackageSubSectionTeamMemberRole' }
        & Pick<ApprovalPackageSubSectionTeamMemberRole, 'names'>
        & { role: (
          { __typename?: 'TeamMemberRole' }
          & Pick<TeamMemberRole, 'id' | 'name'>
        ) }
      )>>, partners?: Maybe<Array<(
        { __typename?: 'ApprovalPackageSubSectionPartnerRole' }
        & Pick<ApprovalPackageSubSectionPartnerRole, 'names'>
        & { role: (
          { __typename?: 'PartnerRole' }
          & Pick<PartnerRole, 'id' | 'name'>
        ) }
      )>>, events?: Maybe<Array<(
        { __typename?: 'ApprovalPackageSubSectionEvent' }
        & Pick<ApprovalPackageSubSectionEvent, 'startDate' | 'endDate'>
        & { event: (
          { __typename?: 'Event' }
          & Pick<Event, 'id' | 'name' | 'type'>
        ) }
      )>>, metrics?: Maybe<Array<(
        { __typename?: 'ApprovalPackageSubSectionMetric' }
        & Pick<ApprovalPackageSubSectionMetric, 'value'>
        & { metric: (
          { __typename?: 'Metric' }
          & MetricFragment
        ) }
      )>> }
    )>> }
  ) }
);

export type CreateApprovalPackageSubSectionTableCellVariables = Exact<{
  id: Scalars['Int'];
}>;


export type CreateApprovalPackageSubSectionTableCell = (
  { __typename?: 'RealTraxMutation' }
  & { createApprovalPackageSubSectionTableCell: (
    { __typename?: 'IcSubSectionTableCell' }
    & Pick<IcSubSectionTableCell, 'id'>
  ) }
);

export type SetEntriesForApprovalPackageSubSectionTableCellVariables = Exact<{
  id: Scalars['Int'];
  entries: Array<IcSubSectionTableCellEntryInput> | IcSubSectionTableCellEntryInput;
}>;


export type SetEntriesForApprovalPackageSubSectionTableCell = (
  { __typename?: 'RealTraxMutation' }
  & { setEntriesForApprovalPackageSubSectionTableCell?: Maybe<(
    { __typename?: 'ApprovalPackageEntityResult' }
    & Pick<ApprovalPackageEntityResult, 'count'>
  )> }
);

export type DeleteApprovalPackageSubSectionTableCellVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeleteApprovalPackageSubSectionTableCell = (
  { __typename?: 'RealTraxMutation' }
  & { deleteApprovalPackageSubSectionTableCell: (
    { __typename?: 'IcSubSectionTableCell' }
    & Pick<IcSubSectionTableCell, 'id'>
  ) }
);

export type UpdateApprovalPackageExhibitVariables = Exact<{
  id: Scalars['Int'];
  displayName?: Maybe<Scalars['String']>;
  fileName?: Maybe<Scalars['String']>;
}>;


export type UpdateApprovalPackageExhibit = (
  { __typename?: 'RealTraxMutation' }
  & { updateApprovalPackageExhibit: (
    { __typename?: 'DocumentTreeItem' }
    & Pick<DocumentTreeItem, 'id' | 'type' | 'name' | 'displayName' | 'fileExtension' | 'createdAt' | 'updatedAt' | 'deletedAt'>
  ) }
);

export type DeleteApprovalPackageExhibitVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeleteApprovalPackageExhibit = (
  { __typename?: 'RealTraxMutation' }
  & { deleteApprovalPackageExhibit: (
    { __typename?: 'DocumentTreeItem' }
    & Pick<DocumentTreeItem, 'id' | 'type' | 'name' | 'displayName' | 'fileExtension' | 'createdAt' | 'updatedAt' | 'deletedAt'>
  ) }
);

export type DeleteDealArtifactVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeleteDealArtifact = (
  { __typename?: 'RealTraxMutation' }
  & { deleteDealArtifact: (
    { __typename?: 'DealArtifact' }
    & Pick<DealArtifact, 'id' | 'name' | 'fileExtension' | 'createdAt' | 'updatedAt' | 'deletedAt'>
  ) }
);

export type DeleteContactAttachmentVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeleteContactAttachment = (
  { __typename?: 'RealTraxMutation' }
  & { deleteContactAttachment: (
    { __typename?: 'ContactAttachment' }
    & Pick<ContactAttachment, 'id' | 'createdAt' | 'updatedAt' | 'deletedAt'>
  ) }
);

export type DeleteFirmAttachmentVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeleteFirmAttachment = (
  { __typename?: 'RealTraxMutation' }
  & { deleteFirmAttachment: (
    { __typename?: 'FirmAttachment' }
    & Pick<FirmAttachment, 'id' | 'createdAt' | 'updatedAt' | 'deletedAt'>
  ) }
);

export type CreateApprovalPackageReviewerVariables = Exact<{
  reviewer: CreateApprovalPackageReviewerInput;
}>;


export type CreateApprovalPackageReviewer = (
  { __typename?: 'RealTraxMutation' }
  & { createApprovalPackageReviewer: (
    { __typename: 'ApprovalPackageReviewer' }
    & ApprovalPackageReviewerFragment
  ) }
);

export type UpdateApprovalPackageReviewerVariables = Exact<{
  reviewer: UpdateApprovalPackageReviewerInput;
}>;


export type UpdateApprovalPackageReviewer = (
  { __typename?: 'RealTraxMutation' }
  & { updateApprovalPackageReviewer: (
    { __typename: 'ApprovalPackageReviewer' }
    & ApprovalPackageReviewerFragment
  ) }
);

export type DeleteApprovalPackageReviewerVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeleteApprovalPackageReviewer = (
  { __typename?: 'RealTraxMutation' }
  & { deleteApprovalPackageReviewer: (
    { __typename: 'ApprovalPackageReviewer' }
    & ApprovalPackageReviewerFragment
  ) }
);

export type CreateApprovalPackageContributorVariables = Exact<{
  contributor: CreateApprovalPackageContributorInput;
}>;


export type CreateApprovalPackageContributor = (
  { __typename?: 'RealTraxMutation' }
  & { createApprovalPackageContributor: (
    { __typename: 'ApprovalPackageContributor' }
    & ApprovalPackageContributorFragment
  ) }
);

export type DeleteApprovalPackageContributorVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeleteApprovalPackageContributor = (
  { __typename?: 'RealTraxMutation' }
  & { deleteApprovalPackageContributor: (
    { __typename: 'ApprovalPackageContributor' }
    & ApprovalPackageContributorFragment
  ) }
);

export type PublishApprovalPackageVariables = Exact<{
  id: Scalars['Int'];
}>;


export type PublishApprovalPackage = (
  { __typename?: 'RealTraxMutation' }
  & { publishApprovalPackage: (
    { __typename?: 'PublishedApprovalPackage' }
    & Pick<PublishedApprovalPackage, 'id' | 'updatedAt' | 'createdAt' | 'reviewDate' | 'isPublished' | 'status' | 'amendmentNumber'>
    & { type: (
      { __typename?: 'ApprovalPackageType' }
      & Pick<ApprovalPackageType, 'id' | 'name'>
    ), deal: (
      { __typename?: 'Deal' }
      & Pick<Deal, 'id' | 'name'>
    ) }
  ) }
);

export type CreateApprovalPackageTemplateVariables = Exact<{
  template: CreateApprovalPackageTemplateInput;
}>;


export type CreateApprovalPackageTemplate = (
  { __typename?: 'RealTraxMutation' }
  & { createApprovalPackageTemplate: (
    { __typename: 'TemplateApprovalPackage' }
    & ApprovalPackageTemplateFragment
  ) | (
    { __typename: 'ErrorResult' }
    & Pick<ErrorResult, 'message'>
  ) }
);

export type UpdateApprovalPackageTemplateVariables = Exact<{
  template: UpdateApprovalPackageTemplateInput;
}>;


export type UpdateApprovalPackageTemplate = (
  { __typename?: 'RealTraxMutation' }
  & { updateApprovalPackageTemplate: (
    { __typename: 'TemplateApprovalPackage' }
    & ApprovalPackageTemplateFragment
  ) | (
    { __typename: 'ErrorResult' }
    & Pick<ErrorResult, 'message'>
  ) | { __typename: 'AlreadyExists' } }
);

export type DeleteApprovalPackageTemplateVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeleteApprovalPackageTemplate = (
  { __typename?: 'RealTraxMutation' }
  & { deleteApprovalPackageTemplate: (
    { __typename: 'TemplateApprovalPackage' }
    & ApprovalPackageTemplateFragment
  ) | (
    { __typename: 'ErrorResult' }
    & Pick<ErrorResult, 'message'>
  ) }
);

export type ApprovalPackageTemplateFragment = (
  { __typename?: 'TemplateApprovalPackage' }
  & Pick<TemplateApprovalPackage, 'id' | 'name' | 'updatedAt' | 'createdAt'>
);

export type UpdateApprovalPackageTemplateSubSectionTextVariables = Exact<{
  subSectionText: UpdateApprovalPackageSubSectionTextInput;
}>;


export type UpdateApprovalPackageTemplateSubSectionText = (
  { __typename?: 'RealTraxMutation' }
  & { updateApprovalPackageTemplateSubsectionText: (
    { __typename?: 'TemplateApprovalPackageSubSectionText' }
    & Pick<TemplateApprovalPackageSubSectionText, 'id' | 'title' | 'text'>
  ) }
);

export type UpdateApprovalPackageTemplateSubSectionChecklistVariables = Exact<{
  subSectionChecklist: UpdateApprovalPackageSubSectionChecklistInput;
}>;


export type UpdateApprovalPackageTemplateSubSectionChecklist = (
  { __typename?: 'RealTraxMutation' }
  & { updateApprovalPackageTemplateSubsectionChecklist: (
    { __typename?: 'ApprovalPackageSubSectionChecklist' }
    & Pick<ApprovalPackageSubSectionChecklist, 'id' | 'title'>
  ) }
);

export type CreateApprovalPackageTemplateSubSectionVariables = Exact<{
  sectionId: Scalars['Int'];
  type: ApprovalPackageSubSectionTypeEnum;
  checklistId?: Maybe<Scalars['Int']>;
  gcEstimateTypeId?: Maybe<Scalars['Int']>;
}>;


export type CreateApprovalPackageTemplateSubSection = (
  { __typename?: 'RealTraxMutation' }
  & { createApprovalPackageTemplateSubSection: (
    { __typename?: 'TemplateApprovalPackageSubSectionText' }
    & TemplateApprovalPackageSubSectionFragment_TemplateApprovalPackageSubSectionText_
  ) | (
    { __typename?: 'TemplateApprovalPackageSubSectionChecklist' }
    & TemplateApprovalPackageSubSectionFragment_TemplateApprovalPackageSubSectionChecklist_
  ) | (
    { __typename?: 'TemplateApprovalPackageSubSectionGcEstimate' }
    & TemplateApprovalPackageSubSectionFragment_TemplateApprovalPackageSubSectionGcEstimate_
  ) | (
    { __typename?: 'TemplateApprovalPackageSubSectionKeyRisks' }
    & TemplateApprovalPackageSubSectionFragment_TemplateApprovalPackageSubSectionKeyRisks_
  ) | (
    { __typename?: 'TemplateApprovalPackageSubSectionMerits' }
    & TemplateApprovalPackageSubSectionFragment_TemplateApprovalPackageSubSectionMerits_
  ) | (
    { __typename?: 'TemplateApprovalPackageSubSectionTable' }
    & TemplateApprovalPackageSubSectionFragment_TemplateApprovalPackageSubSectionTable_
  ) | (
    { __typename?: 'TemplateApprovalPackageSubSectionImages' }
    & TemplateApprovalPackageSubSectionFragment_TemplateApprovalPackageSubSectionImages_
  ) | (
    { __typename?: 'TemplateApprovalPackageSubSectionPageBreak' }
    & TemplateApprovalPackageSubSectionFragment_TemplateApprovalPackageSubSectionPageBreak_
  ) | (
    { __typename?: 'TemplateApprovalPackageSubSectionDealBackground' }
    & TemplateApprovalPackageSubSectionFragment_TemplateApprovalPackageSubSectionDealBackground_
  ) | (
    { __typename?: 'TemplateApprovalPackageSubSectionEarnestMoneyDeposit' }
    & TemplateApprovalPackageSubSectionFragment_TemplateApprovalPackageSubSectionEarnestMoneyDeposit_
  ) }
);

export type UpdateApprovalPackageTemplateSubSectionVariables = Exact<{
  subSection: UpdateApprovalPackageSubSectionInput;
}>;


export type UpdateApprovalPackageTemplateSubSection = (
  { __typename?: 'RealTraxMutation' }
  & { updateApprovalPackageTemplateSubsection: (
    { __typename?: 'TemplateApprovalPackageSubSectionText' }
    & TemplateApprovalPackageSubSectionFragment_TemplateApprovalPackageSubSectionText_
  ) | (
    { __typename?: 'TemplateApprovalPackageSubSectionChecklist' }
    & TemplateApprovalPackageSubSectionFragment_TemplateApprovalPackageSubSectionChecklist_
  ) | (
    { __typename?: 'TemplateApprovalPackageSubSectionGcEstimate' }
    & TemplateApprovalPackageSubSectionFragment_TemplateApprovalPackageSubSectionGcEstimate_
  ) | (
    { __typename?: 'TemplateApprovalPackageSubSectionKeyRisks' }
    & TemplateApprovalPackageSubSectionFragment_TemplateApprovalPackageSubSectionKeyRisks_
  ) | (
    { __typename?: 'TemplateApprovalPackageSubSectionMerits' }
    & TemplateApprovalPackageSubSectionFragment_TemplateApprovalPackageSubSectionMerits_
  ) | (
    { __typename?: 'TemplateApprovalPackageSubSectionTable' }
    & TemplateApprovalPackageSubSectionFragment_TemplateApprovalPackageSubSectionTable_
  ) | (
    { __typename?: 'TemplateApprovalPackageSubSectionImages' }
    & TemplateApprovalPackageSubSectionFragment_TemplateApprovalPackageSubSectionImages_
  ) | (
    { __typename?: 'TemplateApprovalPackageSubSectionPageBreak' }
    & TemplateApprovalPackageSubSectionFragment_TemplateApprovalPackageSubSectionPageBreak_
  ) | (
    { __typename?: 'TemplateApprovalPackageSubSectionDealBackground' }
    & TemplateApprovalPackageSubSectionFragment_TemplateApprovalPackageSubSectionDealBackground_
  ) | (
    { __typename?: 'TemplateApprovalPackageSubSectionEarnestMoneyDeposit' }
    & TemplateApprovalPackageSubSectionFragment_TemplateApprovalPackageSubSectionEarnestMoneyDeposit_
  ) }
);

export type DeleteApprovalPackageTemplateSubSectionVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeleteApprovalPackageTemplateSubSection = (
  { __typename?: 'RealTraxMutation' }
  & { deleteApprovalPackageTemplateSubsection: (
    { __typename?: 'TemplateApprovalPackageSubSectionText' }
    & TemplateApprovalPackageSubSectionFragment_TemplateApprovalPackageSubSectionText_
  ) | (
    { __typename?: 'TemplateApprovalPackageSubSectionChecklist' }
    & TemplateApprovalPackageSubSectionFragment_TemplateApprovalPackageSubSectionChecklist_
  ) | (
    { __typename?: 'TemplateApprovalPackageSubSectionGcEstimate' }
    & TemplateApprovalPackageSubSectionFragment_TemplateApprovalPackageSubSectionGcEstimate_
  ) | (
    { __typename?: 'TemplateApprovalPackageSubSectionKeyRisks' }
    & TemplateApprovalPackageSubSectionFragment_TemplateApprovalPackageSubSectionKeyRisks_
  ) | (
    { __typename?: 'TemplateApprovalPackageSubSectionMerits' }
    & TemplateApprovalPackageSubSectionFragment_TemplateApprovalPackageSubSectionMerits_
  ) | (
    { __typename?: 'TemplateApprovalPackageSubSectionTable' }
    & TemplateApprovalPackageSubSectionFragment_TemplateApprovalPackageSubSectionTable_
  ) | (
    { __typename?: 'TemplateApprovalPackageSubSectionImages' }
    & TemplateApprovalPackageSubSectionFragment_TemplateApprovalPackageSubSectionImages_
  ) | (
    { __typename?: 'TemplateApprovalPackageSubSectionPageBreak' }
    & TemplateApprovalPackageSubSectionFragment_TemplateApprovalPackageSubSectionPageBreak_
  ) | (
    { __typename?: 'TemplateApprovalPackageSubSectionDealBackground' }
    & TemplateApprovalPackageSubSectionFragment_TemplateApprovalPackageSubSectionDealBackground_
  ) | (
    { __typename?: 'TemplateApprovalPackageSubSectionEarnestMoneyDeposit' }
    & TemplateApprovalPackageSubSectionFragment_TemplateApprovalPackageSubSectionEarnestMoneyDeposit_
  ) }
);

export type CreateApprovalPackageTemplateSectionVariables = Exact<{
  section: CreateApprovalPackageSectionInput;
}>;


export type CreateApprovalPackageTemplateSection = (
  { __typename?: 'RealTraxMutation' }
  & { createApprovalPackageTemplateSection: (
    { __typename?: 'TemplateApprovalPackageSection' }
    & Pick<TemplateApprovalPackageSection, 'id' | 'title' | 'orderId'>
  ) }
);

export type UpdateApprovalPackageTemplateSectionVariables = Exact<{
  section: UpdateApprovalPackageSectionInput;
}>;


export type UpdateApprovalPackageTemplateSection = (
  { __typename?: 'RealTraxMutation' }
  & { updateApprovalPackageTemplateSection: (
    { __typename?: 'TemplateApprovalPackageSection' }
    & Pick<TemplateApprovalPackageSection, 'id' | 'title' | 'orderId'>
  ) }
);

export type DeleteApprovalPackageTemplateSectionVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeleteApprovalPackageTemplateSection = (
  { __typename?: 'RealTraxMutation' }
  & { deleteApprovalPackageTemplateSection: (
    { __typename?: 'TemplateApprovalPackageSection' }
    & Pick<TemplateApprovalPackageSection, 'id' | 'title' | 'orderId'>
  ) }
);

export type SetApprovalPackageTemplateSubSectionTableColumnsVariables = Exact<{
  id: Scalars['Int'];
  columns: Scalars['Int'];
}>;


export type SetApprovalPackageTemplateSubSectionTableColumns = (
  { __typename?: 'RealTraxMutation' }
  & { setApprovalPackageTemplateSubSectionTableColumns: (
    { __typename?: 'TemplateApprovalPackageSubSectionTable' }
    & Pick<TemplateApprovalPackageSubSectionTable, 'id' | 'title' | 'orderId' | 'gridColumnWidth' | 'columns'>
    & { cells?: Maybe<Array<(
      { __typename?: 'TemplateIcSubSectionTableCell' }
      & Pick<TemplateIcSubSectionTableCell, 'id' | 'orderId'>
      & { entries?: Maybe<Array<(
        { __typename?: 'IcSubSectionTableCellEntry' }
        & Pick<IcSubSectionTableCellEntry, 'id' | 'name' | 'orderId' | 'type'>
      )>> }
    )>> }
  ) }
);

export type CreateApprovalPackageTemplateSubSectionTableCellVariables = Exact<{
  id: Scalars['Int'];
}>;


export type CreateApprovalPackageTemplateSubSectionTableCell = (
  { __typename?: 'RealTraxMutation' }
  & { createApprovalPackageTemplateSubSectionTableCell: (
    { __typename?: 'TemplateIcSubSectionTableCell' }
    & Pick<TemplateIcSubSectionTableCell, 'id'>
  ) }
);

export type SetEntriesForApprovalPackageTemplateSubSectionTableCellVariables = Exact<{
  id: Scalars['Int'];
  entries: Array<IcSubSectionTableCellEntryInput> | IcSubSectionTableCellEntryInput;
}>;


export type SetEntriesForApprovalPackageTemplateSubSectionTableCell = (
  { __typename?: 'RealTraxMutation' }
  & { setEntriesForApprovalPackageTemplateSubSectionTableCell?: Maybe<(
    { __typename?: 'ApprovalPackageEntityResult' }
    & Pick<ApprovalPackageEntityResult, 'count'>
  )> }
);

export type DeleteApprovalPackageTemplateSubSectionTableCellVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeleteApprovalPackageTemplateSubSectionTableCell = (
  { __typename?: 'RealTraxMutation' }
  & { deleteApprovalPackageTemplateSubSectionTableCell: (
    { __typename?: 'TemplateIcSubSectionTableCell' }
    & Pick<TemplateIcSubSectionTableCell, 'id'>
  ) }
);

export type CreateApprovalPackageTemplateFromPackageVariables = Exact<{
  id: Scalars['Int'];
}>;


export type CreateApprovalPackageTemplateFromPackage = (
  { __typename?: 'RealTraxMutation' }
  & { createApprovalPackageTemplateFromPackage: (
    { __typename?: 'TemplateApprovalPackage' }
    & Pick<TemplateApprovalPackage, 'id' | 'name' | 'updatedAt' | 'createdAt'>
  ) }
);

export type AssignChangeEventsToParentVariables = Exact<{
  dealId: Scalars['Int'];
  changeEventIds: Array<Scalars['Int']> | Scalars['Int'];
  parentId?: Maybe<Scalars['Int']>;
}>;


export type AssignChangeEventsToParent = (
  { __typename?: 'RealTraxMutation' }
  & { assignChangeEventsToParent?: Maybe<Array<(
    { __typename?: 'ChangeEvent' }
    & ChangeEventFragment
  )>> }
);

export type AssignPotentialChangeOrdersToParentVariables = Exact<{
  dealId: Scalars['Int'];
  potentialChangeOrderIds: Array<Scalars['Int']> | Scalars['Int'];
  parentId?: Maybe<Scalars['Int']>;
}>;


export type AssignPotentialChangeOrdersToParent = (
  { __typename?: 'RealTraxMutation' }
  & { assignPotentialChangeOrdersToParent?: Maybe<Array<(
    { __typename?: 'PotentialChangeOrder' }
    & PotentialChangeOrderFragment
  )>> }
);

export type AssignChangeOrdersToParentVariables = Exact<{
  dealId: Scalars['Int'];
  changeOrderIds: Array<Scalars['Int']> | Scalars['Int'];
  parentId?: Maybe<Scalars['Int']>;
}>;


export type AssignChangeOrdersToParent = (
  { __typename?: 'RealTraxMutation' }
  & { assignChangeOrdersToParent?: Maybe<Array<(
    { __typename?: 'ChangeOrderPackage' }
    & ChangeOrderPackageFragment
  )>> }
);

export type RunDefaultForecastRulesVariables = Exact<{
  fkDeal: Scalars['Int'];
  accounts?: Maybe<Array<Scalars['Int']> | Scalars['Int']>;
}>;


export type RunDefaultForecastRules = (
  { __typename?: 'RealTraxMutation' }
  & { runDefaultForecastRules?: Maybe<(
    { __typename?: 'ForecastRuleExecutionResult' }
    & Pick<ForecastRuleExecutionResult, 'transactionsCreated'>
  )> }
);

export type ForecastCapitalizationAccountsVariables = Exact<{
  fkDeal: Scalars['Int'];
}>;


export type ForecastCapitalizationAccounts = (
  { __typename?: 'RealTraxMutation' }
  & Pick<RealTraxMutation, 'forecastCapitalizationAccounts'>
);

export type ForecastInterestAccountVariables = Exact<{
  fkDeal: Scalars['Int'];
}>;


export type ForecastInterestAccount = (
  { __typename?: 'RealTraxMutation' }
  & Pick<RealTraxMutation, 'forecastInterestAccount'>
);

export type CreateApprovalPackageTypeVariables = Exact<{
  approvalPackageType: CreateApprovalPackageTypeInput;
}>;


export type CreateApprovalPackageType = (
  { __typename?: 'RealTraxMutation' }
  & { createApprovalPackageType: (
    { __typename?: 'ApprovalPackageType' }
    & ApprovalPackageTypeFragment
  ) | (
    { __typename?: 'ErrorResult' }
    & Pick<ErrorResult, 'message'>
  ) }
);

export type UpdateApprovalPackageTypeVariables = Exact<{
  approvalPackageType: UpdateApprovalPackageTypeInput;
}>;


export type UpdateApprovalPackageType = (
  { __typename?: 'RealTraxMutation' }
  & { updateApprovalPackageType: (
    { __typename?: 'ApprovalPackageType' }
    & ApprovalPackageTypeFragment
  ) | (
    { __typename?: 'ErrorResult' }
    & Pick<ErrorResult, 'message'>
  ) | { __typename?: 'AlreadyExists' } }
);

export type DeleteApprovalPackageTypeVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeleteApprovalPackageType = (
  { __typename?: 'RealTraxMutation' }
  & { deleteApprovalPackageType: (
    { __typename: 'ApprovalPackageType' }
    & ApprovalPackageTypeFragment
  ) | (
    { __typename: 'ErrorResult' }
    & Pick<ErrorResult, 'message'>
  ) }
);

export type ApprovalPackageTypeFragment = (
  { __typename?: 'ApprovalPackageType' }
  & Pick<ApprovalPackageType, 'id' | 'name'>
  & { event?: Maybe<(
    { __typename?: 'Event' }
    & Pick<Event, 'id' | 'name' | 'type'>
  )>, defaultTemplate?: Maybe<(
    { __typename?: 'TemplateApprovalPackage' }
    & Pick<TemplateApprovalPackage, 'id' | 'name'>
  )> }
);

export type BulkEditVisibleDealEventsVariables = Exact<{
  pipelineId: Scalars['Int'];
  dealIds: Array<Scalars['Int']> | Scalars['Int'];
  eventIds: Array<Scalars['Int']> | Scalars['Int'];
}>;


export type BulkEditVisibleDealEvents = (
  { __typename?: 'RealTraxMutation' }
  & Pick<RealTraxMutation, 'bulkEditVisibleDealEvents'>
);

export type BulkUnhideDealEventsVariables = Exact<{
  pipelineId: Scalars['Int'];
  dealIds: Array<Scalars['Int']> | Scalars['Int'];
  eventIds: Array<Scalars['Int']> | Scalars['Int'];
}>;


export type BulkUnhideDealEvents = (
  { __typename?: 'RealTraxMutation' }
  & Pick<RealTraxMutation, 'bulkUnhideDealEvents'>
);

export type RefreshApprovalPackageKeyRisksVariables = Exact<{
  id: Scalars['Int'];
  approvalPackageId: Scalars['Int'];
}>;


export type RefreshApprovalPackageKeyRisks = (
  { __typename?: 'RealTraxMutation' }
  & { refreshApprovalPackageKeyRisks: Array<(
    { __typename?: 'ApprovalPackageKeyRisk' }
    & Pick<ApprovalPackageKeyRisk, 'id' | 'title' | 'riskDescription' | 'mitigantDescription' | 'orderId'>
  )> }
);

export type RefreshApprovalPackageDealMeritsVariables = Exact<{
  id: Scalars['Int'];
  approvalPackageId: Scalars['Int'];
}>;


export type RefreshApprovalPackageDealMerits = (
  { __typename?: 'RealTraxMutation' }
  & { refreshApprovalPackageDealMerits: Array<(
    { __typename?: 'ApprovalPackageMerit' }
    & Pick<ApprovalPackageMerit, 'id' | 'title' | 'meritDescription' | 'orderId'>
  )> }
);

export type CreateInterestRateVariables = Exact<{
  interestRate: CreateInterestRateInput;
}>;


export type CreateInterestRate = (
  { __typename?: 'RealTraxMutation' }
  & { createInterestRate: (
    { __typename: 'InterestRate' }
    & InterestRateFragment
  ) | (
    { __typename: 'InterestRateAlreadyExists' }
    & InterestRateAlreadyExistsFragment
    & ErrorFragment_InterestRateAlreadyExists_
  ) }
);

export type UpdateInterestRateVariables = Exact<{
  interestRate: UpdateInterestRateInput;
}>;


export type UpdateInterestRate = (
  { __typename?: 'RealTraxMutation' }
  & { updateInterestRate: (
    { __typename: 'InterestRate' }
    & InterestRateFragment
  ) | (
    { __typename: 'InterestRateAlreadyExists' }
    & InterestRateAlreadyExistsFragment
    & ErrorFragment_InterestRateAlreadyExists_
  ) | (
    { __typename: 'ErrorResult' }
    & ErrorFragment_ErrorResult_
  ) }
);

export type DeleteInterestRateVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeleteInterestRate = (
  { __typename?: 'RealTraxMutation' }
  & { deleteInterestRate: (
    { __typename: 'InterestRate' }
    & InterestRateFragment
  ) | (
    { __typename: 'ErrorResult' }
    & Pick<ErrorResult, 'message'>
  ) }
);

export type InterestRateFragment = (
  { __typename?: 'InterestRate' }
  & Pick<InterestRate, 'id' | 'value' | 'startDate'>
);

export type InterestRateAlreadyExistsFragment = (
  { __typename?: 'InterestRateAlreadyExists' }
  & Pick<InterestRateAlreadyExists, 'message'>
  & { interestRate?: Maybe<(
    { __typename?: 'InterestRate' }
    & Pick<InterestRate, 'id' | 'value' | 'startDate'>
  )> }
);

export type GetPortfoliosVariables = Exact<{ [key: string]: never; }>;


export type GetPortfolios = (
  { __typename?: 'RealTraxQuery' }
  & { portfolios?: Maybe<Array<(
    { __typename?: 'Portfolio' }
    & Pick<Portfolio, 'id' | 'name' | 'description' | 'isPrivate'>
  )>> }
);

export type GetPortfolioVariables = Exact<{
  id: Scalars['Int'];
}>;


export type GetPortfolio = (
  { __typename?: 'RealTraxQuery' }
  & { portfolio?: Maybe<(
    { __typename?: 'Portfolio' }
    & Pick<Portfolio, 'id' | 'name' | 'description' | 'isPrivate' | 'publicCanEdit' | 'isDynamic' | 'currentUserCanEdit' | 'currentUserIsOwner'>
    & { filterGroups?: Maybe<Array<(
      { __typename?: 'PortfolioFilterGroup' }
      & { clauses: Array<(
        { __typename?: 'PortfolioFilter' }
        & Pick<PortfolioFilter, 'type' | 'isAnd' | 'value'>
        & { attribute: (
          { __typename: 'DealAttribute' }
          & Pick<DealAttribute, 'id' | 'displayName' | 'columnName' | 'dataType'>
        ) | (
          { __typename: 'PartnerRole' }
          & Pick<PartnerRole, 'id' | 'name'>
        ) | (
          { __typename: 'TeamMemberRole' }
          & Pick<TeamMemberRole, 'id' | 'name'>
        ) | (
          { __typename: 'Event' }
          & Pick<Event, 'id' | 'name'>
        ), filter: (
          { __typename?: 'CustomFilters' }
          & Pick<CustomFilters, 'id' | 'name' | 'sign'>
        ) }
      )> }
    )>>, users?: Maybe<Array<(
      { __typename?: 'PortfolioRoleUser' }
      & { user: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'firstName' | 'lastName'>
      ), role: (
        { __typename?: 'Role' }
        & Pick<Role, 'id' | 'name'>
      ) }
    )>> }
  )> }
);

export type GetDealsVariables = Exact<{
  showArchivedDeals: Scalars['Boolean'];
}>;


export type GetDeals = (
  { __typename?: 'RealTraxQuery' }
  & { deals?: Maybe<Array<(
    { __typename?: 'Deal' }
    & Pick<Deal, 'id' | 'name' | 'archiveDate' | 'archiveStatus' | 'archiveReason' | 'city' | 'birthDate'>
    & { currentStatus?: Maybe<(
      { __typename?: 'DealStatusUpdate' }
      & Pick<DealStatusUpdate, 'id' | 'title' | 'message'>
      & { status: (
        { __typename?: 'DealStatus' }
        & Pick<DealStatus, 'id' | 'name' | 'color'>
      ) }
    )>, region?: Maybe<(
      { __typename?: 'Region' }
      & Pick<Region, 'id' | 'name'>
    )>, state?: Maybe<(
      { __typename?: 'State' }
      & Pick<State, 'id' | 'name' | 'code'>
    )>, stage?: Maybe<(
      { __typename?: 'DealStage' }
      & Pick<DealStage, 'id' | 'name'>
    )>, marketSegment?: Maybe<(
      { __typename?: 'MarketSegment' }
      & Pick<MarketSegment, 'id' | 'name'>
    )>, pipeline: (
      { __typename?: 'Pipeline' }
      & Pick<Pipeline, 'id' | 'name' | 'isDisabled'>
    ), fund?: Maybe<(
      { __typename?: 'Fund' }
      & Pick<Fund, 'id' | 'name'>
    )> }
  )>> }
);

export type GetDealsPaginatedVariables = Exact<{
  showArchivedDeals: Scalars['Boolean'];
  limit: Scalars['Int'];
  offset: Scalars['Int'];
}>;


export type GetDealsPaginated = (
  { __typename?: 'RealTraxQuery' }
  & { dealsPaginated?: Maybe<(
    { __typename?: 'DealsResponse' }
    & Pick<DealsResponse, 'hasMoreDeals'>
    & { deals?: Maybe<Array<(
      { __typename?: 'Deal' }
      & Pick<Deal, 'id' | 'name' | 'archiveDate' | 'archiveStatus' | 'archiveReason' | 'city' | 'birthDate'>
      & { currentStatus?: Maybe<(
        { __typename?: 'DealStatusUpdate' }
        & Pick<DealStatusUpdate, 'id' | 'title' | 'message'>
        & { status: (
          { __typename?: 'DealStatus' }
          & Pick<DealStatus, 'id' | 'name' | 'color'>
        ) }
      )>, region?: Maybe<(
        { __typename?: 'Region' }
        & Pick<Region, 'id' | 'name'>
      )>, state?: Maybe<(
        { __typename?: 'State' }
        & Pick<State, 'id' | 'name' | 'code'>
      )>, stage?: Maybe<(
        { __typename?: 'DealStage' }
        & Pick<DealStage, 'id' | 'name'>
      )>, marketSegment?: Maybe<(
        { __typename?: 'MarketSegment' }
        & Pick<MarketSegment, 'id' | 'name'>
      )>, pipeline: (
        { __typename?: 'Pipeline' }
        & Pick<Pipeline, 'id' | 'name' | 'isDisabled'>
      ), fund?: Maybe<(
        { __typename?: 'Fund' }
        & Pick<Fund, 'id' | 'name'>
      )> }
    )>> }
  )> }
);

export type GetPortfolioDealsVariables = Exact<{
  id: Scalars['Int'];
}>;


export type GetPortfolioDeals = (
  { __typename?: 'RealTraxQuery' }
  & { portfolio?: Maybe<(
    { __typename?: 'Portfolio' }
    & Pick<Portfolio, 'id' | 'name' | 'description'>
    & { deals?: Maybe<Array<(
      { __typename?: 'Deal' }
      & Pick<Deal, 'id' | 'name' | 'city' | 'birthDate'>
      & { currentStatus?: Maybe<(
        { __typename?: 'DealStatusUpdate' }
        & Pick<DealStatusUpdate, 'id' | 'title' | 'message'>
        & { status: (
          { __typename?: 'DealStatus' }
          & Pick<DealStatus, 'id' | 'name' | 'color'>
        ) }
      )>, region?: Maybe<(
        { __typename?: 'Region' }
        & Pick<Region, 'id' | 'name'>
      )>, state?: Maybe<(
        { __typename?: 'State' }
        & Pick<State, 'id' | 'name' | 'code'>
      )>, stage?: Maybe<(
        { __typename?: 'DealStage' }
        & Pick<DealStage, 'id' | 'name'>
      )>, marketSegment?: Maybe<(
        { __typename?: 'MarketSegment' }
        & Pick<MarketSegment, 'id' | 'name'>
      )> }
    )>> }
  )> }
);

export type GetPortfolioUserAccessVariables = Exact<{
  id: Scalars['Int'];
}>;


export type GetPortfolioUserAccess = (
  { __typename?: 'RealTraxQuery' }
  & { portfolio?: Maybe<(
    { __typename?: 'Portfolio' }
    & Pick<Portfolio, 'id' | 'name'>
    & { users?: Maybe<Array<(
      { __typename?: 'PortfolioRoleUser' }
      & { user: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'firstName' | 'lastName'>
      ), role: (
        { __typename?: 'Role' }
        & Pick<Role, 'id' | 'name'>
      ) }
    )>>, groups?: Maybe<Array<(
      { __typename?: 'PortfolioRoleGroup' }
      & { group: (
        { __typename?: 'Group' }
        & Pick<Group, 'id' | 'name'>
      ), role: (
        { __typename?: 'Role' }
        & Pick<Role, 'id' | 'name'>
      ) }
    )>> }
  )> }
);

export type GetDealVariables = Exact<{
  id: Scalars['Int'];
}>;


export type GetDeal = (
  { __typename?: 'RealTraxQuery' }
  & { deal?: Maybe<(
    { __typename?: 'Deal' }
    & Pick<Deal, 'id' | 'name' | 'usesLatestAnalysisAsCurrent' | 'isWorkingDays' | 'otherName' | 'isPrivate' | 'publicCanEdit' | 'birthDate' | 'dealBackground' | 'earnestMoneyDeposit' | 'investmentVision' | 'zipcode' | 'city' | 'address1' | 'address2' | 'usesCoordinates' | 'longitude' | 'latitude' | 'currentUserCanEdit' | 'currentUserIsOwner' | 'archiveDate' | 'archiveStatus' | 'archiveReason' | 'legalEntity' | 'spread' | 'parcelNumber' | 'yearBuilt' | 'purchasePrice' | 'numberOfUnits' | 'nrsf' | 'irr' | 'unleverredIrr' | 'moic' | 'entryCapRate' | 'exitCapRate' | 'requiredEquity' | 'debt'>
    & { structure?: Maybe<(
      { __typename?: 'Structure' }
      & { value: Structure['id'], label: Structure['name'] }
    )>, dealType?: Maybe<(
      { __typename?: 'DealType' }
      & { value: DealType['id'], label: DealType['name'] }
    )>, priorityLevel?: Maybe<(
      { __typename?: 'DealPriorityLevel' }
      & { value: DealPriorityLevel['id'], label: DealPriorityLevel['name'] }
    )>, stage?: Maybe<(
      { __typename?: 'DealStage' }
      & { value: DealStage['id'], label: DealStage['name'] }
    )>, fund?: Maybe<(
      { __typename?: 'Fund' }
      & { value: Fund['id'], label: Fund['name'] }
    )>, fundEntity?: Maybe<(
      { __typename?: 'FundEntity' }
      & { value: FundEntity['id'], label: FundEntity['name'] }
    )>, pipeline: (
      { __typename?: 'Pipeline' }
      & { value: Pipeline['id'], label: Pipeline['name'] }
      & { pipelineModules?: Maybe<Array<(
        { __typename?: 'PipelineModule' }
        & Pick<PipelineModule, 'id' | 'isDisabled'>
        & { module: (
          { __typename?: 'Module' }
          & Pick<Module, 'id' | 'name'>
        ) }
      )>>, dealStages?: Maybe<Array<(
        { __typename?: 'DealStage' }
        & Pick<DealStage, 'isDisabled' | 'orderId'>
        & { value: DealStage['id'], label: DealStage['name'] }
      )>> }
    ), source?: Maybe<(
      { __typename?: 'Firm' }
      & { value: Firm['id'], label: Firm['name'] }
    )>, state?: Maybe<(
      { __typename?: 'State' }
      & { value: State['id'], label: State['name'], abbreviation: State['code'] }
    )>, region?: Maybe<(
      { __typename?: 'Region' }
      & { value: Region['id'], label: Region['name'] }
    )>, market?: Maybe<(
      { __typename?: 'Market' }
      & { value: Market['id'], label: Market['name'] }
      & { msa?: Maybe<(
        { __typename?: 'Msa' }
        & Pick<Msa, 'id' | 'name'>
      )> }
    )>, marketType?: Maybe<(
      { __typename?: 'MarketType' }
      & { value: MarketType['id'], label: MarketType['name'] }
    )>, marketSegment?: Maybe<(
      { __typename?: 'MarketSegment' }
      & { value: MarketSegment['id'], label: MarketSegment['name'] }
    )>, submarket?: Maybe<(
      { __typename?: 'Submarket' }
      & { value: Submarket['id'], label: Submarket['name'] }
    )>, brokerFirm?: Maybe<(
      { __typename?: 'Firm' }
      & { value: Firm['id'], label: Firm['name'] }
    )>, brokerContact?: Maybe<(
      { __typename?: 'Contact' }
      & Pick<Contact, 'id' | 'firstName' | 'lastName'>
    )>, buyerFirm?: Maybe<(
      { __typename?: 'Firm' }
      & { value: Firm['id'], label: Firm['name'] }
    )>, buyerContact?: Maybe<(
      { __typename?: 'Contact' }
      & Pick<Contact, 'id' | 'firstName' | 'lastName'>
    )>, sellerFirm?: Maybe<(
      { __typename?: 'Firm' }
      & { value: Firm['id'], label: Firm['name'] }
    )>, sellerContact?: Maybe<(
      { __typename?: 'Contact' }
      & Pick<Contact, 'id' | 'firstName' | 'lastName'>
    )>, productType?: Maybe<(
      { __typename?: 'ProductType' }
      & { value: ProductType['id'], label: ProductType['name'] }
    )>, constructionType?: Maybe<(
      { __typename?: 'ConstructionType' }
      & { value: ConstructionType['id'], label: ConstructionType['name'] }
    )>, assetClass?: Maybe<(
      { __typename?: 'AssetClass' }
      & { value: AssetClass['id'], label: AssetClass['name'] }
    )>, hoa?: Maybe<(
      { __typename?: 'Hoa' }
      & { value: Hoa['id'], label: Hoa['name'] }
    )>, currentStatus?: Maybe<(
      { __typename?: 'DealStatusUpdate' }
      & Pick<DealStatusUpdate, 'id' | 'title' | 'message' | 'createdAt' | 'updatedAt' | 'commentCount'>
      & { sections?: Maybe<Array<(
        { __typename?: 'DealStatusUpdateSection' }
        & Pick<DealStatusUpdateSection, 'id' | 'title' | 'content'>
      )>>, labels?: Maybe<Array<(
        { __typename?: 'Label' }
        & Pick<Label, 'id' | 'name' | 'color'>
      )>>, createdBy: (
        { __typename?: 'SystemProcess' }
        & Pick<SystemProcess, 'id' | 'displayName' | 'displayNameAlt'>
      ) | (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'displayName' | 'displayNameAlt'>
      ), status: (
        { __typename?: 'DealStatus' }
        & Pick<DealStatus, 'id' | 'name' | 'color'>
      ) }
    )>, keyRisks?: Maybe<Array<(
      { __typename?: 'KeyRisk' }
      & Pick<KeyRisk, 'id' | 'title' | 'riskDescription' | 'mitigantDescription' | 'updatedAt' | 'orderId'>
      & { createdBy: (
        { __typename?: 'SystemProcess' }
        & Pick<SystemProcess, 'id' | 'displayName' | 'displayNameAlt'>
      ) | (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'displayName' | 'displayNameAlt'>
      ), updatedBy: (
        { __typename?: 'SystemProcess' }
        & Pick<SystemProcess, 'id' | 'displayName' | 'displayNameAlt'>
      ) | (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'displayName' | 'displayNameAlt'>
      ) }
    )>>, dealMerits?: Maybe<Array<(
      { __typename?: 'Merit' }
      & Pick<Merit, 'id' | 'title' | 'meritDescription' | 'updatedAt' | 'orderId'>
      & { createdBy: (
        { __typename?: 'SystemProcess' }
        & Pick<SystemProcess, 'id' | 'displayName' | 'displayNameAlt'>
      ) | (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'displayName' | 'displayNameAlt'>
      ), updatedBy: (
        { __typename?: 'SystemProcess' }
        & Pick<SystemProcess, 'id' | 'displayName' | 'displayNameAlt'>
      ) | (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'displayName' | 'displayNameAlt'>
      ) }
    )>>, currentAnalysis?: Maybe<(
      { __typename?: 'DealAnalysis' }
      & Pick<DealAnalysis, 'id' | 'description'>
    )>, pinnedMetrics?: Maybe<Array<(
      { __typename?: 'DealPinnedMetric' }
      & { metric: (
        { __typename?: 'Metric' }
        & Pick<Metric, 'scale'>
        & MetricFragment
      ), currentValue?: Maybe<(
        { __typename?: 'AnalysisMetric' }
        & Pick<AnalysisMetric, 'value'>
      )> }
    )>>, pinnedEvents?: Maybe<Array<(
      { __typename?: 'DealPinnedEvent' }
      & Pick<DealPinnedEvent, 'endDate'>
      & { event: (
        { __typename?: 'Event' }
        & Pick<Event, 'name'>
      ) }
    )>>, pinnedPartnerRoles?: Maybe<Array<(
      { __typename?: 'DealPinnedPartnerRole' }
      & { role: (
        { __typename?: 'PartnerRole' }
        & Pick<PartnerRole, 'id' | 'name'>
      ), currentValue?: Maybe<Array<Maybe<(
        { __typename?: 'DealPartner' }
        & { role: (
          { __typename?: 'PartnerRole' }
          & Pick<PartnerRole, 'id' | 'name'>
        ), firm?: Maybe<(
          { __typename?: 'Firm' }
          & Pick<Firm, 'id' | 'name'>
        )> }
      )>>> }
    )>>, pinnedTeamMemberRoles?: Maybe<Array<(
      { __typename?: 'DealPinnedTeamMemberRole' }
      & { role: (
        { __typename?: 'TeamMemberRole' }
        & Pick<TeamMemberRole, 'id' | 'name'>
      ), currentValue?: Maybe<Array<Maybe<(
        { __typename?: 'DealTeamMemberUser' }
        & { role: (
          { __typename?: 'TeamMemberRole' }
          & Pick<TeamMemberRole, 'id' | 'name'>
        ), user: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'displayName'>
        ) }
      )>>> }
    )>> }
  )> }
);

export type GetDealInteractionsVariables = Exact<{
  id: Scalars['Int'];
}>;


export type GetDealInteractions = (
  { __typename?: 'RealTraxQuery' }
  & { deal?: Maybe<(
    { __typename?: 'Deal' }
    & Pick<Deal, 'id' | 'name'>
    & { pipeline: (
      { __typename?: 'Pipeline' }
      & Pick<Pipeline, 'id'>
    ), interactions: Array<(
      { __typename?: 'Interaction' }
      & Pick<Interaction, 'id' | 'name' | 'createdAt' | 'interactionDate' | 'notes'>
      & { createdBy: (
        { __typename?: 'SystemProcess' }
        & Pick<SystemProcess, 'id' | 'displayName' | 'displayNameAlt'>
      ) | (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'displayName' | 'displayNameAlt'>
      ), type: (
        { __typename?: 'InteractionType' }
        & Pick<InteractionType, 'id' | 'name'>
      ), attendees: Array<(
        { __typename?: 'InteractionAttendee' }
        & Pick<InteractionAttendee, 'id' | 'name' | 'type'>
      )>, deals: Array<(
        { __typename?: 'Deal' }
        & Pick<Deal, 'id' | 'name'>
      )> }
    )> }
  )> }
);

export type GetDealUserAccessVariables = Exact<{
  id: Scalars['Int'];
}>;


export type GetDealUserAccess = (
  { __typename?: 'RealTraxQuery' }
  & { deal?: Maybe<(
    { __typename?: 'Deal' }
    & Pick<Deal, 'id' | 'name'>
    & { users?: Maybe<Array<(
      { __typename?: 'DealRoleUser' }
      & { user: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'firstName' | 'lastName'>
      ), role: (
        { __typename?: 'Role' }
        & Pick<Role, 'id' | 'name'>
      ) }
    )>>, groups?: Maybe<Array<(
      { __typename?: 'DealRoleGroup' }
      & { group: (
        { __typename?: 'Group' }
        & Pick<Group, 'id' | 'name'>
      ), role: (
        { __typename?: 'Role' }
        & Pick<Role, 'id' | 'name'>
      ) }
    )>> }
  )> }
);

export type GetPipelineUserAccessVariables = Exact<{
  id: Scalars['Int'];
}>;


export type GetPipelineUserAccess = (
  { __typename?: 'RealTraxQuery' }
  & { pipeline?: Maybe<(
    { __typename?: 'Pipeline' }
    & Pick<Pipeline, 'id' | 'name'>
    & { users?: Maybe<Array<(
      { __typename?: 'PipelineRoleUser' }
      & { user: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'firstName' | 'lastName'>
      ), role: (
        { __typename?: 'Role' }
        & Pick<Role, 'id' | 'name'>
      ) }
    )>>, groups?: Maybe<Array<(
      { __typename?: 'PipelineRoleGroup' }
      & { group: (
        { __typename?: 'Group' }
        & Pick<Group, 'id' | 'name'>
      ), role: (
        { __typename?: 'Role' }
        & Pick<Role, 'id' | 'name'>
      ) }
    )>> }
  )> }
);

export type GetDealTeamVariables = Exact<{
  id: Scalars['Int'];
}>;


export type GetDealTeam = (
  { __typename?: 'RealTraxQuery' }
  & { deal?: Maybe<(
    { __typename?: 'Deal' }
    & { team?: Maybe<Array<(
      { __typename?: 'DealTeamMemberUser' }
      & { user: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'firstName' | 'lastName'>
      ), role: (
        { __typename?: 'TeamMemberRole' }
        & Pick<TeamMemberRole, 'id' | 'name' | 'description'>
      ) }
    )>> }
  )> }
);

export type GetDealPartnersVariables = Exact<{
  id: Scalars['Int'];
}>;


export type GetDealPartners = (
  { __typename?: 'RealTraxQuery' }
  & { deal?: Maybe<(
    { __typename?: 'Deal' }
    & { partners?: Maybe<Array<(
      { __typename?: 'DealPartner' }
      & Pick<DealPartner, 'id'>
      & { contact?: Maybe<(
        { __typename?: 'Contact' }
        & Pick<Contact, 'id' | 'firstName' | 'lastName'>
      )>, firm?: Maybe<(
        { __typename?: 'Firm' }
        & Pick<Firm, 'id' | 'name'>
      )>, role: (
        { __typename?: 'PartnerRole' }
        & Pick<PartnerRole, 'id' | 'name' | 'description'>
      ) }
    )>> }
  )> }
);

export type GetTeamMemberRolesVariables = Exact<{ [key: string]: never; }>;


export type GetTeamMemberRoles = (
  { __typename?: 'RealTraxQuery' }
  & { teamMemberRoles?: Maybe<Array<(
    { __typename?: 'TeamMemberRole' }
    & Pick<TeamMemberRole, 'id' | 'name' | 'description' | 'isDisabled' | 'isAppDefault'>
  )>> }
);

export type GetPartnerRolesVariables = Exact<{ [key: string]: never; }>;


export type GetPartnerRoles = (
  { __typename?: 'RealTraxQuery' }
  & { partnerRoles?: Maybe<Array<(
    { __typename?: 'PartnerRole' }
    & Pick<PartnerRole, 'id' | 'name' | 'description' | 'isDisabled' | 'isAppDefault'>
  )>> }
);

export type GetPortfolioUsersVariables = Exact<{
  id: Scalars['Int'];
}>;


export type GetPortfolioUsers = (
  { __typename?: 'RealTraxQuery' }
  & { portfolio?: Maybe<(
    { __typename?: 'Portfolio' }
    & { users?: Maybe<Array<(
      { __typename?: 'PortfolioRoleUser' }
      & { user: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'firstName' | 'lastName'>
      ), role: (
        { __typename?: 'Role' }
        & Pick<Role, 'id' | 'name'>
      ) }
    )>> }
  )> }
);

export type GetCurrentOrganizationInfoVariables = Exact<{ [key: string]: never; }>;


export type GetCurrentOrganizationInfo = (
  { __typename?: 'RealTraxQuery' }
  & { organization: (
    { __typename?: 'Organization' }
    & Pick<Organization, 'name' | 'organizationPath' | 'currentUserCanEdit'>
    & { owner?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'firstName' | 'lastName' | 'displayName' | 'displayNameAlt' | 'email' | 'username'>
    )> }
  ) }
);

export type GetOrganizationUserAccessVariables = Exact<{ [key: string]: never; }>;


export type GetOrganizationUserAccess = (
  { __typename?: 'RealTraxQuery' }
  & { organizationUsers?: Maybe<Array<(
    { __typename?: 'OrganizationRoleUser' }
    & { user: (
      { __typename?: 'User' }
      & Pick<User, 'id' | 'firstName' | 'lastName'>
    ), role: (
      { __typename?: 'Role' }
      & Pick<Role, 'id' | 'name'>
    ) }
  )>>, organizationGroups?: Maybe<Array<(
    { __typename?: 'OrganizationRoleGroup' }
    & { group: (
      { __typename?: 'Group' }
      & Pick<Group, 'id' | 'name'>
    ), role: (
      { __typename?: 'Role' }
      & Pick<Role, 'id' | 'name'>
    ) }
  )>> }
);

export type GetFirmVariables = Exact<{
  id: Scalars['Int'];
}>;


export type GetFirm = (
  { __typename?: 'RealTraxQuery' }
  & { firm?: Maybe<(
    { __typename?: 'Firm' }
    & Pick<Firm, 'id' | 'name' | 'website'>
    & { status?: Maybe<(
      { __typename?: 'CrmStatus' }
      & Pick<CrmStatus, 'id' | 'name'>
    )>, type?: Maybe<(
      { __typename?: 'FirmType' }
      & Pick<FirmType, 'id' | 'name'>
    )>, headquartersMsa?: Maybe<(
      { __typename?: 'Msa' }
      & Pick<Msa, 'id' | 'name'>
    )>, headquartersMarket?: Maybe<(
      { __typename?: 'Market' }
      & Pick<Market, 'id' | 'name'>
    )>, regions: Array<(
      { __typename?: 'FirmRegion' }
      & Pick<FirmRegion, 'id' | 'name' | 'isPrimaryRegion'>
    )>, productAppetite?: Maybe<(
      { __typename?: 'InvestmentType' }
      & Pick<InvestmentType, 'id' | 'name'>
    )>, contacts: Array<(
      { __typename?: 'Contact' }
      & ContactFragment
    )>, relationshipLead?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'firstName' | 'lastName' | 'displayName'>
    )>, associatedDeals: Array<(
      { __typename?: 'Deal' }
      & Pick<Deal, 'id' | 'name'>
    )>, interactions: Array<(
      { __typename?: 'Interaction' }
      & Pick<Interaction, 'id' | 'name' | 'createdAt' | 'interactionDate' | 'notes'>
      & { createdBy: (
        { __typename?: 'SystemProcess' }
        & Pick<SystemProcess, 'id' | 'displayName' | 'displayNameAlt'>
      ) | (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'displayName' | 'displayNameAlt'>
      ), type: (
        { __typename?: 'InteractionType' }
        & Pick<InteractionType, 'id' | 'name'>
      ), attendees: Array<(
        { __typename?: 'InteractionAttendee' }
        & Pick<InteractionAttendee, 'id' | 'name' | 'type'>
      )>, deals: Array<(
        { __typename?: 'Deal' }
        & Pick<Deal, 'id' | 'name'>
      )> }
    )> }
  )> }
);

export type GetDealPipelineFirmsVariables = Exact<{
  id: Scalars['Int'];
}>;


export type GetDealPipelineFirms = (
  { __typename?: 'RealTraxQuery' }
  & { deal?: Maybe<(
    { __typename?: 'Deal' }
    & Pick<Deal, 'id' | 'name'>
    & { pipeline: (
      { __typename?: 'Pipeline' }
      & { firms: Array<(
        { __typename?: 'Firm' }
        & Pick<Firm, 'id' | 'name'>
      )> }
    ) }
  )> }
);

export type GetDealPipelineContactsVariables = Exact<{
  id: Scalars['Int'];
}>;


export type GetDealPipelineContacts = (
  { __typename?: 'RealTraxQuery' }
  & { deal?: Maybe<(
    { __typename?: 'Deal' }
    & Pick<Deal, 'id' | 'name'>
    & { pipeline: (
      { __typename?: 'Pipeline' }
      & { contacts: Array<(
        { __typename?: 'Contact' }
        & Pick<Contact, 'id' | 'firstName' | 'lastName'>
      )> }
    ) }
  )> }
);

export type GetInteractionVariables = Exact<{
  id: Scalars['Int'];
}>;


export type GetInteraction = (
  { __typename?: 'RealTraxQuery' }
  & { interaction?: Maybe<(
    { __typename?: 'Interaction' }
    & Pick<Interaction, 'id' | 'name' | 'createdAt' | 'interactionDate' | 'notes'>
    & { createdBy: (
      { __typename?: 'SystemProcess' }
      & Pick<SystemProcess, 'id' | 'displayName' | 'displayNameAlt'>
    ) | (
      { __typename?: 'User' }
      & Pick<User, 'id' | 'displayName' | 'displayNameAlt'>
    ), type: (
      { __typename?: 'InteractionType' }
      & Pick<InteractionType, 'id' | 'name'>
    ), firms: Array<(
      { __typename?: 'Firm' }
      & Pick<Firm, 'id' | 'name'>
    )>, attendees: Array<(
      { __typename?: 'InteractionAttendee' }
      & Pick<InteractionAttendee, 'id' | 'name' | 'type'>
    )>, deals: Array<(
      { __typename?: 'Deal' }
      & Pick<Deal, 'id' | 'name'>
    )> }
  )> }
);

export type GetContactVariables = Exact<{
  id: Scalars['Int'];
}>;


export type GetContact = (
  { __typename?: 'RealTraxQuery' }
  & { contact?: Maybe<(
    { __typename?: 'Contact' }
    & Pick<Contact, 'id' | 'firstName' | 'lastName' | 'title' | 'email' | 'cellPhone' | 'officePhone' | 'notes' | 'isPrimary' | 'inaccessibleAssociatedDealsCount'>
    & { firm?: Maybe<(
      { __typename?: 'Firm' }
      & Pick<Firm, 'id' | 'name'>
    )>, associatedDeals: Array<(
      { __typename?: 'Deal' }
      & Pick<Deal, 'id' | 'name'>
    )>, interactions: Array<(
      { __typename?: 'Interaction' }
      & Pick<Interaction, 'id' | 'name' | 'createdAt' | 'interactionDate' | 'notes'>
      & { createdBy: (
        { __typename?: 'SystemProcess' }
        & Pick<SystemProcess, 'id' | 'displayName' | 'displayNameAlt'>
      ) | (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'displayName' | 'displayNameAlt'>
      ), type: (
        { __typename?: 'InteractionType' }
        & Pick<InteractionType, 'id' | 'name'>
      ), attendees: Array<(
        { __typename?: 'InteractionAttendee' }
        & Pick<InteractionAttendee, 'id' | 'name' | 'type'>
      )>, deals: Array<(
        { __typename?: 'Deal' }
        & Pick<Deal, 'id' | 'name'>
      )> }
    )> }
  )> }
);

export type GetDealChecklistsVariables = Exact<{
  id: Scalars['Int'];
}>;


export type GetDealChecklists = (
  { __typename?: 'RealTraxQuery' }
  & { deal?: Maybe<(
    { __typename?: 'Deal' }
    & { checklists?: Maybe<Array<(
      { __typename?: 'DealChecklist' }
      & Pick<DealChecklist, 'id' | 'name' | 'description'>
      & { checklistItems?: Maybe<Array<(
        { __typename?: 'DealChecklistItem' }
        & Pick<DealChecklistItem, 'id' | 'name' | 'comment' | 'dueDate'>
        & { category?: Maybe<(
          { __typename?: 'ChecklistCategory' }
          & Pick<ChecklistCategory, 'id' | 'name'>
        )>, status: (
          { __typename?: 'ChecklistItemStatus' }
          & Pick<ChecklistItemStatus, 'id' | 'name'>
        ), assignee?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'id' | 'firstName' | 'lastName'>
        )> }
      )>> }
    )>> }
  )> }
);

export type GetChecklistItemStatusesVariables = Exact<{ [key: string]: never; }>;


export type GetChecklistItemStatuses = (
  { __typename?: 'RealTraxQuery' }
  & { checklistItemStatuses?: Maybe<Array<(
    { __typename?: 'ChecklistItemStatus' }
    & Pick<ChecklistItemStatus, 'id' | 'name'>
  )>> }
);

export type GetChecklistsVariables = Exact<{ [key: string]: never; }>;


export type GetChecklists = (
  { __typename?: 'RealTraxQuery' }
  & { checklists?: Maybe<Array<(
    { __typename?: 'Checklist' }
    & Pick<Checklist, 'id' | 'name' | 'description' | 'isDisabled'>
  )>> }
);

export type GetChecklistVariables = Exact<{
  id: Scalars['Int'];
}>;


export type GetChecklist = (
  { __typename?: 'RealTraxQuery' }
  & { checklist?: Maybe<(
    { __typename?: 'Checklist' }
    & Pick<Checklist, 'id' | 'name' | 'description' | 'isDisabled'>
    & { checklistItems?: Maybe<Array<(
      { __typename?: 'ChecklistItem' }
      & Pick<ChecklistItem, 'id' | 'name' | 'defaultComment'>
      & { category?: Maybe<(
        { __typename?: 'ChecklistCategory' }
        & Pick<ChecklistCategory, 'id' | 'name'>
      )>, teamMemberRole?: Maybe<(
        { __typename?: 'TeamMemberRole' }
        & Pick<TeamMemberRole, 'id' | 'name'>
      )> }
    )>> }
  )> }
);

export type GetChecklistCategoriesVariables = Exact<{
  id: Scalars['Int'];
}>;


export type GetChecklistCategories = (
  { __typename?: 'RealTraxQuery' }
  & { checklistCategories?: Maybe<Array<(
    { __typename?: 'ChecklistCategory' }
    & Pick<ChecklistCategory, 'id' | 'name' | 'fkChecklist'>
  )>> }
);

export type GetDealStatusUpdatesVariables = Exact<{
  id: Scalars['Int'];
}>;


export type GetDealStatusUpdates = (
  { __typename?: 'RealTraxQuery' }
  & { deal?: Maybe<(
    { __typename?: 'Deal' }
    & Pick<Deal, 'id' | 'name'>
    & { statusUpdates?: Maybe<Array<(
      { __typename?: 'DealStatusUpdate' }
      & Pick<DealStatusUpdate, 'id' | 'title' | 'message' | 'createdAt' | 'updatedAt' | 'commentCount'>
      & { sections?: Maybe<Array<(
        { __typename?: 'DealStatusUpdateSection' }
        & Pick<DealStatusUpdateSection, 'id' | 'title' | 'content'>
      )>>, labels?: Maybe<Array<(
        { __typename?: 'Label' }
        & Pick<Label, 'id' | 'name' | 'color'>
      )>>, createdBy: (
        { __typename?: 'SystemProcess' }
        & Pick<SystemProcess, 'id' | 'displayName' | 'displayNameAlt'>
      ) | (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'displayName' | 'displayNameAlt'>
      ), status: (
        { __typename?: 'DealStatus' }
        & Pick<DealStatus, 'id' | 'name' | 'color'>
      ) }
    )>> }
  )> }
);

export type GetDealStatusUpdateVariables = Exact<{
  id: Scalars['Int'];
  statusUpdateId: Scalars['Int'];
}>;


export type GetDealStatusUpdate = (
  { __typename?: 'RealTraxQuery' }
  & { deal?: Maybe<(
    { __typename?: 'Deal' }
    & Pick<Deal, 'id' | 'name'>
    & { statusUpdate?: Maybe<(
      { __typename?: 'DealStatusUpdate' }
      & Pick<DealStatusUpdate, 'id' | 'title' | 'message' | 'createdAt' | 'updatedAt' | 'commentCount'>
      & { sections?: Maybe<Array<(
        { __typename?: 'DealStatusUpdateSection' }
        & Pick<DealStatusUpdateSection, 'id' | 'title' | 'content'>
      )>>, labels?: Maybe<Array<(
        { __typename?: 'Label' }
        & Pick<Label, 'id' | 'name' | 'color'>
      )>>, comments?: Maybe<Array<(
        { __typename?: 'Comment' }
        & Pick<Comment, 'id' | 'body' | 'createdAt' | 'updatedAt'>
        & { createdBy: (
          { __typename?: 'SystemProcess' }
          & Pick<SystemProcess, 'id' | 'displayName' | 'displayNameAlt'>
        ) | (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'displayName' | 'displayNameAlt'>
        ) }
      )>>, createdBy: (
        { __typename?: 'SystemProcess' }
        & Pick<SystemProcess, 'id' | 'displayName' | 'displayNameAlt'>
      ) | (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'displayName' | 'displayNameAlt'>
      ), status: (
        { __typename?: 'DealStatus' }
        & Pick<DealStatus, 'id' | 'name' | 'color'>
      ) }
    )> }
  )> }
);

export type GetDealStatusUpdateTemplatesVariables = Exact<{ [key: string]: never; }>;


export type GetDealStatusUpdateTemplates = (
  { __typename?: 'RealTraxQuery' }
  & { statusUpdateTemplates?: Maybe<Array<(
    { __typename?: 'DealStatusUpdateTemplate' }
    & Pick<DealStatusUpdateTemplate, 'id' | 'title' | 'message' | 'createdAt' | 'updatedAt'>
    & { sections?: Maybe<Array<(
      { __typename?: 'DealStatusUpdateTemplateSection' }
      & Pick<DealStatusUpdateTemplateSection, 'id' | 'title' | 'content'>
    )>>, labels?: Maybe<Array<(
      { __typename?: 'Label' }
      & Pick<Label, 'id' | 'name' | 'color'>
    )>>, createdBy: (
      { __typename?: 'SystemProcess' }
      & Pick<SystemProcess, 'id' | 'displayName' | 'displayNameAlt'>
    ) | (
      { __typename?: 'User' }
      & Pick<User, 'id' | 'displayName' | 'displayNameAlt'>
    ) }
  )>> }
);

export type GetDealStatusUpdateTemplateVariables = Exact<{
  id: Scalars['Int'];
}>;


export type GetDealStatusUpdateTemplate = (
  { __typename?: 'RealTraxQuery' }
  & { statusUpdateTemplate?: Maybe<(
    { __typename?: 'DealStatusUpdateTemplate' }
    & Pick<DealStatusUpdateTemplate, 'id' | 'title' | 'message' | 'createdAt' | 'updatedAt'>
    & { sections?: Maybe<Array<(
      { __typename?: 'DealStatusUpdateTemplateSection' }
      & Pick<DealStatusUpdateTemplateSection, 'id' | 'title' | 'content'>
    )>>, labels?: Maybe<Array<(
      { __typename?: 'Label' }
      & Pick<Label, 'id' | 'name' | 'color'>
    )>>, createdBy: (
      { __typename?: 'SystemProcess' }
      & Pick<SystemProcess, 'id' | 'displayName' | 'displayNameAlt'>
    ) | (
      { __typename?: 'User' }
      & Pick<User, 'id' | 'displayName' | 'displayNameAlt'>
    ) }
  )> }
);

export type GetDealStatusesVariables = Exact<{ [key: string]: never; }>;


export type GetDealStatuses = (
  { __typename?: 'RealTraxQuery' }
  & { dealStatuses?: Maybe<Array<(
    { __typename?: 'DealStatus' }
    & Pick<DealStatus, 'id' | 'name' | 'color'>
  )>> }
);

export type GetDealScheduleVariables = Exact<{
  id: Scalars['Int'];
}>;


export type GetDealSchedule = (
  { __typename?: 'RealTraxQuery' }
  & { deal?: Maybe<(
    { __typename?: 'Deal' }
    & Pick<Deal, 'id' | 'birthDate'>
    & { events?: Maybe<Array<(
      { __typename?: 'DealEvent' }
      & { latestApprovedEvent?: Maybe<(
        { __typename?: 'PublishedDealEvent' }
        & Pick<PublishedDealEvent, 'id' | 'startDate' | 'endDate' | 'type'>
      )> }
      & DealEventFragment
    )>>, hiddenEvents?: Maybe<Array<(
      { __typename?: 'DealEvent' }
      & DealEventFragment
    )>>, buildings?: Maybe<Array<(
      { __typename?: 'Building' }
      & Pick<Building, 'id' | 'name' | 'buildingNumber' | 'isTcoReceived' | 'tcoDate' | 'commentCount'>
      & { type: (
        { __typename?: 'BuildingType' }
        & Pick<BuildingType, 'id' | 'name'>
      ) }
    )>>, pipeline: (
      { __typename?: 'Pipeline' }
      & Pick<Pipeline, 'id'>
    ) }
  )> }
);

export type GetDealKeyMilestonesVariables = Exact<{
  id: Scalars['Int'];
}>;


export type GetDealKeyMilestones = (
  { __typename?: 'RealTraxQuery' }
  & { deal?: Maybe<(
    { __typename?: 'Deal' }
    & Pick<Deal, 'id' | 'birthDate'>
    & { milestones?: Maybe<Array<(
      { __typename?: 'DealEvent' }
      & Pick<DealEvent, 'id' | 'name' | 'startDate'>
      & { parent?: Maybe<(
        { __typename?: 'Event' }
        & Pick<Event, 'id' | 'name'>
      )> }
    )>> }
  )> }
);

export type GetDealEventVariables = Exact<{
  id: Scalars['Int'];
  dealEventId: Scalars['Int'];
}>;


export type GetDealEvent = (
  { __typename?: 'RealTraxQuery' }
  & { deal?: Maybe<(
    { __typename?: 'Deal' }
    & Pick<Deal, 'id'>
    & { event?: Maybe<(
      { __typename?: 'DealEvent' }
      & Pick<DealEvent, 'id' | 'dealEventId' | 'name' | 'orderId' | 'startDate' | 'endDate' | 'isComplete' | 'type' | 'commentCount'>
      & { category?: Maybe<(
        { __typename?: 'EventCategory' }
        & Pick<EventCategory, 'id' | 'name'>
      )>, parent?: Maybe<(
        { __typename?: 'Event' }
        & Pick<Event, 'id' | 'name'>
      )>, dependsOn?: Maybe<Array<(
        { __typename?: 'Event' }
        & Pick<Event, 'id' | 'name'>
      )>>, comments?: Maybe<Array<(
        { __typename?: 'Comment' }
        & Pick<Comment, 'id' | 'body' | 'createdAt' | 'updatedAt'>
        & { createdBy: (
          { __typename?: 'SystemProcess' }
          & Pick<SystemProcess, 'id' | 'displayName' | 'displayNameAlt'>
        ) | (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'displayName' | 'displayNameAlt'>
        ) }
      )>> }
    )> }
  )> }
);

export type GetCurrentUserVariables = Exact<{ [key: string]: never; }>;


export type GetCurrentUser = (
  { __typename?: 'RealTraxQuery' }
  & { currentUser: (
    { __typename?: 'User' }
    & Pick<User, 'id' | 'firstName' | 'lastName' | 'email'>
    & { organizations?: Maybe<Array<(
      { __typename?: 'Organization' }
      & Pick<Organization, 'id' | 'name' | 'organizationPath'>
    )>> }
  ) }
);

export type GetDealDocumentsVariables = Exact<{
  id: Scalars['Int'];
  fkFolder?: Maybe<Scalars['Int']>;
}>;


export type GetDealDocuments = (
  { __typename?: 'RealTraxQuery' }
  & { deal?: Maybe<(
    { __typename?: 'Deal' }
    & { documents?: Maybe<Array<(
      { __typename?: 'DocumentTreeItem' }
      & Pick<DocumentTreeItem, 'id' | 'type' | 'name' | 'displayName' | 'createdAt' | 'updatedAt' | 'fileExtension'>
    )>> }
  )> }
);

export type GetFoldersVariables = Exact<{
  ids?: Maybe<Array<Scalars['Int']> | Scalars['Int']>;
}>;


export type GetFolders = (
  { __typename?: 'RealTraxQuery' }
  & { folders?: Maybe<Array<(
    { __typename?: 'Folder' }
    & Pick<Folder, 'id' | 'name'>
  )>> }
);

export type GetStatesVariables = Exact<{ [key: string]: never; }>;


export type GetStates = (
  { __typename?: 'RealTraxQuery' }
  & { states?: Maybe<Array<(
    { __typename?: 'State' }
    & { value: State['id'], label: State['name'], abbreviation: State['code'] }
  )>> }
);

export type GetRegionsVariables = Exact<{ [key: string]: never; }>;


export type GetRegions = (
  { __typename?: 'RealTraxQuery' }
  & { regions?: Maybe<Array<(
    { __typename?: 'Region' }
    & { value: Region['id'], label: Region['name'] }
  )>> }
);

export type GetMarketsVariables = Exact<{ [key: string]: never; }>;


export type GetMarkets = (
  { __typename?: 'RealTraxQuery' }
  & { markets?: Maybe<Array<(
    { __typename?: 'Market' }
    & { value: Market['id'], label: Market['name'] }
  )>> }
);

export type GetMarketTypesVariables = Exact<{ [key: string]: never; }>;


export type GetMarketTypes = (
  { __typename?: 'RealTraxQuery' }
  & { marketTypes?: Maybe<Array<(
    { __typename?: 'MarketType' }
    & { value: MarketType['id'], label: MarketType['name'] }
  )>> }
);

export type GetMarketSegmentsVariables = Exact<{ [key: string]: never; }>;


export type GetMarketSegments = (
  { __typename?: 'RealTraxQuery' }
  & { marketSegments?: Maybe<Array<(
    { __typename?: 'MarketSegment' }
    & { value: MarketSegment['id'], label: MarketSegment['name'] }
  )>> }
);

export type GetSubmarketsVariables = Exact<{ [key: string]: never; }>;


export type GetSubmarkets = (
  { __typename?: 'RealTraxQuery' }
  & { submarkets?: Maybe<Array<(
    { __typename?: 'SubmarketType' }
    & Pick<SubmarketType, 'fkMarket'>
    & { value: SubmarketType['id'], label: SubmarketType['name'] }
  )>> }
);

export type GetMsasVariables = Exact<{ [key: string]: never; }>;


export type GetMsas = (
  { __typename?: 'RealTraxQuery' }
  & { msas?: Maybe<Array<(
    { __typename?: 'Msa' }
    & { value: Msa['id'], label: Msa['name'] }
  )>> }
);

export type GetProductTypesVariables = Exact<{ [key: string]: never; }>;


export type GetProductTypes = (
  { __typename?: 'RealTraxQuery' }
  & { productTypes?: Maybe<Array<(
    { __typename?: 'ProductType' }
    & Pick<ProductType, 'isDisabled'>
    & { value: ProductType['id'], label: ProductType['name'] }
  )>> }
);

export type GetBuildingTypesVariables = Exact<{ [key: string]: never; }>;


export type GetBuildingTypes = (
  { __typename?: 'RealTraxQuery' }
  & { buildingTypes?: Maybe<Array<(
    { __typename?: 'BuildingType' }
    & Pick<BuildingType, 'isDisabled'>
    & { value: BuildingType['id'], label: BuildingType['name'] }
  )>> }
);

export type GetEstimateTypesVariables = Exact<{ [key: string]: never; }>;


export type GetEstimateTypes = (
  { __typename?: 'RealTraxQuery' }
  & { estimateTypes?: Maybe<Array<(
    { __typename?: 'EstimateType' }
    & Pick<EstimateType, 'isDisabled'>
    & { value: EstimateType['id'], label: EstimateType['name'] }
  )>> }
);

export type GetConstructionTypesVariables = Exact<{ [key: string]: never; }>;


export type GetConstructionTypes = (
  { __typename?: 'RealTraxQuery' }
  & { constructionTypes?: Maybe<Array<(
    { __typename?: 'ConstructionType' }
    & Pick<ConstructionType, 'isDisabled'>
    & { value: ConstructionType['id'], label: ConstructionType['name'] }
  )>> }
);

export type GetStructuresVariables = Exact<{ [key: string]: never; }>;


export type GetStructures = (
  { __typename?: 'RealTraxQuery' }
  & { structures?: Maybe<Array<(
    { __typename?: 'Structure' }
    & Pick<Structure, 'isDisabled'>
    & { value: Structure['id'], label: Structure['name'] }
  )>> }
);

export type GetDealTypesVariables = Exact<{ [key: string]: never; }>;


export type GetDealTypes = (
  { __typename?: 'RealTraxQuery' }
  & { dealTypes?: Maybe<Array<(
    { __typename?: 'DealType' }
    & { value: DealType['id'], label: DealType['name'] }
  )>> }
);

export type GetAssetClassesVariables = Exact<{ [key: string]: never; }>;


export type GetAssetClasses = (
  { __typename?: 'RealTraxQuery' }
  & { assetClasses?: Maybe<Array<(
    { __typename?: 'AssetClass' }
    & Pick<AssetClass, 'isDisabled'>
    & { value: AssetClass['id'], label: AssetClass['name'] }
  )>> }
);

export type GetHoaTypesVariables = Exact<{ [key: string]: never; }>;


export type GetHoaTypes = (
  { __typename?: 'RealTraxQuery' }
  & { hoaTypes?: Maybe<Array<(
    { __typename?: 'Hoa' }
    & Pick<Hoa, 'isDisabled'>
    & { value: Hoa['id'], label: Hoa['name'] }
  )>> }
);

export type GetFundsVariables = Exact<{ [key: string]: never; }>;


export type GetFunds = (
  { __typename?: 'RealTraxQuery' }
  & { funds?: Maybe<Array<(
    { __typename?: 'Fund' }
    & Pick<Fund, 'isDisabled'>
    & { value: Fund['id'], label: Fund['name'] }
  )>> }
);

export type GetFundEntitiesVariables = Exact<{ [key: string]: never; }>;


export type GetFundEntities = (
  { __typename?: 'RealTraxQuery' }
  & { fundEntities?: Maybe<Array<(
    { __typename?: 'FundEntity' }
    & Pick<FundEntity, 'isDisabled'>
    & { value: FundEntity['id'], label: FundEntity['name'] }
  )>> }
);

export type GetPriorityLevelsVariables = Exact<{ [key: string]: never; }>;


export type GetPriorityLevels = (
  { __typename?: 'RealTraxQuery' }
  & { priorityLevels?: Maybe<Array<(
    { __typename?: 'DealPriorityLevel' }
    & { value: DealPriorityLevel['id'], label: DealPriorityLevel['name'] }
  )>> }
);

export type GetPipelinesVariables = Exact<{ [key: string]: never; }>;


export type GetPipelines = (
  { __typename?: 'RealTraxQuery' }
  & { pipelines?: Maybe<Array<(
    { __typename?: 'Pipeline' }
    & Pick<Pipeline, 'id' | 'name' | 'description' | 'isDisabled'>
  )>> }
);

export type GetPipelineVariables = Exact<{
  id: Scalars['Int'];
}>;


export type GetPipeline = (
  { __typename?: 'RealTraxQuery' }
  & { pipeline?: Maybe<(
    { __typename?: 'Pipeline' }
    & Pick<Pipeline, 'id' | 'name' | 'description' | 'isDisabled'>
    & { generalContractorRole?: Maybe<(
      { __typename?: 'PartnerRole' }
      & Pick<PartnerRole, 'id' | 'name'>
    )>, generalContractorAccount?: Maybe<(
      { __typename?: 'GlAccount' }
      & Pick<GlAccount, 'id' | 'name' | 'code' | 'displayCode'>
    )>, defaultCostSource?: Maybe<(
      { __typename?: 'GlAccount' }
      & Pick<GlAccount, 'id' | 'name' | 'code' | 'displayCode'>
    )>, equityAccount?: Maybe<(
      { __typename?: 'GlAccount' }
      & Pick<GlAccount, 'id' | 'name' | 'code' | 'displayCode'>
    )>, debtAccount?: Maybe<(
      { __typename?: 'GlAccount' }
      & Pick<GlAccount, 'id' | 'name' | 'code' | 'displayCode'>
    )>, interestAccount?: Maybe<(
      { __typename?: 'GlAccount' }
      & Pick<GlAccount, 'id' | 'name' | 'code' | 'displayCode'>
    )>, endInterestAccount?: Maybe<(
      { __typename?: 'GlAccount' }
      & Pick<GlAccount, 'id' | 'name' | 'code' | 'displayCode'>
    )>, firstResidentialTcoEvent?: Maybe<(
      { __typename?: 'Event' }
      & Pick<Event, 'id' | 'name' | 'type'>
    )>, lastResidentialTcoEvent?: Maybe<(
      { __typename?: 'Event' }
      & Pick<Event, 'id' | 'name' | 'type'>
    )>, events?: Maybe<Array<(
      { __typename?: 'Event' }
      & Pick<Event, 'id' | 'name' | 'type'>
    )>>, forecastRules?: Maybe<Array<(
      { __typename?: 'ForecastRule' }
      & ForecastRuleFragment
    )>>, contingencyCategorizations?: Maybe<Array<(
      { __typename?: 'PipelineContingencyCategory' }
      & PipelineContingencyCategoryFragment
    )>>, pinnedMetrics?: Maybe<Array<(
      { __typename?: 'Metric' }
      & Pick<Metric, 'id' | 'name'>
    )>>, pinnedPartnerRoles?: Maybe<Array<(
      { __typename?: 'PartnerRole' }
      & Pick<PartnerRole, 'id' | 'name'>
    )>>, pinnedTeamMemberRoles?: Maybe<Array<(
      { __typename?: 'TeamMemberRole' }
      & Pick<TeamMemberRole, 'id' | 'name'>
    )>>, checklists?: Maybe<Array<(
      { __typename?: 'Checklist' }
      & Pick<Checklist, 'id' | 'name' | 'description' | 'isDisabled'>
    )>>, defaultAccountTree?: Maybe<(
      { __typename?: 'GlAccountTree' }
      & Pick<GlAccountTree, 'id' | 'name'>
    )>, defaultSource?: Maybe<(
      { __typename?: 'Firm' }
      & Pick<Firm, 'id' | 'name'>
    )>, accountCashOffsetRules?: Maybe<Array<(
      { __typename?: 'PipelineAccountOffset' }
      & Pick<PipelineAccountOffset, 'id' | 'offset'>
      & { account: (
        { __typename?: 'GlAccount' }
        & Pick<GlAccount, 'id' | 'name' | 'code' | 'displayCode'>
      ), event?: Maybe<(
        { __typename?: 'Event' }
        & Pick<Event, 'id' | 'name' | 'type'>
      )>, filter?: Maybe<(
        { __typename?: 'CustomFilters' }
        & Pick<CustomFilters, 'id' | 'name'>
      )> }
    )>>, generalCashOffsetRules?: Maybe<Array<(
      { __typename?: 'PipelineGeneralOffset' }
      & Pick<PipelineGeneralOffset, 'id' | 'offset'>
      & { event?: Maybe<(
        { __typename?: 'Event' }
        & Pick<Event, 'id' | 'name' | 'type'>
      )>, filter?: Maybe<(
        { __typename?: 'CustomFilters' }
        & Pick<CustomFilters, 'id' | 'name'>
      )> }
    )>> }
  )> }
);

export type GetPipelineFirmsVariables = Exact<{
  id: Scalars['Int'];
}>;


export type GetPipelineFirms = (
  { __typename?: 'RealTraxQuery' }
  & { pipeline?: Maybe<(
    { __typename?: 'Pipeline' }
    & Pick<Pipeline, 'id' | 'name' | 'description' | 'isDisabled'>
    & { firms: Array<(
      { __typename?: 'Firm' }
      & Pick<Firm, 'id' | 'name' | 'website'>
      & { status?: Maybe<(
        { __typename?: 'CrmStatus' }
        & Pick<CrmStatus, 'id' | 'name'>
      )>, type?: Maybe<(
        { __typename?: 'FirmType' }
        & Pick<FirmType, 'id' | 'name'>
      )>, headquartersMsa?: Maybe<(
        { __typename?: 'Msa' }
        & Pick<Msa, 'id' | 'name'>
      )>, headquartersMarket?: Maybe<(
        { __typename?: 'Market' }
        & Pick<Market, 'id' | 'name'>
      )>, regions: Array<(
        { __typename?: 'FirmRegion' }
        & Pick<FirmRegion, 'id' | 'name' | 'isPrimaryRegion'>
      )>, productAppetite?: Maybe<(
        { __typename?: 'InvestmentType' }
        & Pick<InvestmentType, 'id' | 'name'>
      )>, keyContacts: Array<(
        { __typename?: 'Contact' }
        & ContactFragment
      )>, relationshipLead?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'firstName' | 'lastName' | 'displayName'>
      )> }
    )> }
  )> }
);

export type GetPipelineContactsVariables = Exact<{
  id: Scalars['Int'];
}>;


export type GetPipelineContacts = (
  { __typename?: 'RealTraxQuery' }
  & { pipeline?: Maybe<(
    { __typename?: 'Pipeline' }
    & Pick<Pipeline, 'id' | 'name' | 'description' | 'isDisabled'>
    & { contacts: Array<(
      { __typename?: 'Contact' }
      & ContactFragment
    )> }
  )> }
);

export type GetPipelineModulesVariables = Exact<{
  id: Scalars['Int'];
}>;


export type GetPipelineModules = (
  { __typename?: 'RealTraxQuery' }
  & { pipeline?: Maybe<(
    { __typename?: 'Pipeline' }
    & Pick<Pipeline, 'id' | 'name' | 'description' | 'isDisabled'>
    & { generalContractorRole?: Maybe<(
      { __typename?: 'PartnerRole' }
      & Pick<PartnerRole, 'id' | 'name'>
    )>, generalContractorAccount?: Maybe<(
      { __typename?: 'GlAccount' }
      & Pick<GlAccount, 'id' | 'name' | 'code' | 'displayCode'>
    )>, defaultCostSource?: Maybe<(
      { __typename?: 'GlAccount' }
      & Pick<GlAccount, 'id' | 'name' | 'code' | 'displayCode'>
    )>, equityAccount?: Maybe<(
      { __typename?: 'GlAccount' }
      & Pick<GlAccount, 'id' | 'name' | 'code' | 'displayCode'>
    )>, debtAccount?: Maybe<(
      { __typename?: 'GlAccount' }
      & Pick<GlAccount, 'id' | 'name' | 'code' | 'displayCode'>
    )>, firstResidentialTcoEvent?: Maybe<(
      { __typename?: 'Event' }
      & Pick<Event, 'id' | 'name' | 'type'>
    )>, lastResidentialTcoEvent?: Maybe<(
      { __typename?: 'Event' }
      & Pick<Event, 'id' | 'name' | 'type'>
    )>, events?: Maybe<Array<(
      { __typename?: 'Event' }
      & Pick<Event, 'id' | 'name' | 'type'>
    )>>, forecastRules?: Maybe<Array<(
      { __typename?: 'ForecastRule' }
      & ForecastRuleFragment
    )>>, contingencyCategorizations?: Maybe<Array<(
      { __typename?: 'PipelineContingencyCategory' }
      & PipelineContingencyCategoryFragment
    )>>, pinnedMetrics?: Maybe<Array<(
      { __typename?: 'Metric' }
      & Pick<Metric, 'id' | 'name'>
    )>>, pinnedEvents?: Maybe<Array<(
      { __typename?: 'Event' }
      & Pick<Event, 'id' | 'name'>
    )>>, pinnedPartnerRoles?: Maybe<Array<(
      { __typename?: 'PartnerRole' }
      & Pick<PartnerRole, 'id' | 'name'>
    )>>, pinnedTeamMemberRoles?: Maybe<Array<(
      { __typename?: 'TeamMemberRole' }
      & Pick<TeamMemberRole, 'id' | 'name'>
    )>>, checklists?: Maybe<Array<(
      { __typename?: 'Checklist' }
      & Pick<Checklist, 'id' | 'name' | 'description' | 'isDisabled'>
    )>>, defaultAccountTree?: Maybe<(
      { __typename?: 'GlAccountTree' }
      & Pick<GlAccountTree, 'id' | 'name'>
    )>, defaultSource?: Maybe<(
      { __typename?: 'Firm' }
      & Pick<Firm, 'id' | 'name'>
    )>, accountCashOffsetRules?: Maybe<Array<(
      { __typename?: 'PipelineAccountOffset' }
      & Pick<PipelineAccountOffset, 'id' | 'offset'>
      & { account: (
        { __typename?: 'GlAccount' }
        & Pick<GlAccount, 'id' | 'name' | 'code' | 'displayCode'>
      ), event?: Maybe<(
        { __typename?: 'Event' }
        & Pick<Event, 'id' | 'name' | 'type'>
      )>, filter?: Maybe<(
        { __typename?: 'CustomFilters' }
        & Pick<CustomFilters, 'id' | 'name'>
      )> }
    )>>, generalCashOffsetRules?: Maybe<Array<(
      { __typename?: 'PipelineGeneralOffset' }
      & Pick<PipelineGeneralOffset, 'id' | 'offset'>
      & { event?: Maybe<(
        { __typename?: 'Event' }
        & Pick<Event, 'id' | 'name' | 'type'>
      )>, filter?: Maybe<(
        { __typename?: 'CustomFilters' }
        & Pick<CustomFilters, 'id' | 'name'>
      )> }
    )>>, pipelineModules?: Maybe<Array<(
      { __typename?: 'PipelineModule' }
      & Pick<PipelineModule, 'id' | 'isDisabled'>
      & { module: (
        { __typename?: 'Module' }
        & Pick<Module, 'id' | 'name'>
      ) }
    )>> }
  )> }
);

export type GetPipelineDealStagesVariables = Exact<{
  id: Scalars['Int'];
}>;


export type GetPipelineDealStages = (
  { __typename?: 'RealTraxQuery' }
  & { pipeline?: Maybe<(
    { __typename?: 'Pipeline' }
    & Pick<Pipeline, 'id' | 'name'>
    & { dealStages?: Maybe<Array<(
      { __typename?: 'DealStage' }
      & Pick<DealStage, 'isDisabled' | 'orderId'>
      & { value: DealStage['id'], label: DealStage['name'] }
    )>> }
  )> }
);

export type GetModulesVariables = Exact<{ [key: string]: never; }>;


export type GetModules = (
  { __typename?: 'RealTraxQuery' }
  & { modules?: Maybe<Array<(
    { __typename?: 'Module' }
    & Pick<Module, 'id' | 'name'>
  )>> }
);

export type GetBudgetOffsetRuleFiltersVariables = Exact<{ [key: string]: never; }>;


export type GetBudgetOffsetRuleFilters = (
  { __typename?: 'RealTraxQuery' }
  & { budgetOffsetRuleFilters?: Maybe<Array<(
    { __typename?: 'CustomFilters' }
    & Pick<CustomFilters, 'id' | 'name' | 'sign'>
  )>> }
);

export type GetForecastRulesVariables = Exact<{ [key: string]: never; }>;


export type GetForecastRules = (
  { __typename?: 'RealTraxQuery' }
  & { forecastRules?: Maybe<Array<(
    { __typename?: 'ForecastRule' }
    & ForecastRuleFragment
  )>> }
);

export type GetForecastRuleVariables = Exact<{
  id: Scalars['Int'];
}>;


export type GetForecastRule = (
  { __typename?: 'RealTraxQuery' }
  & { forecastRule?: Maybe<(
    { __typename?: 'ForecastRule' }
    & ForecastRuleFragment
  )> }
);

export type GetAnalysisScenariosVariables = Exact<{ [key: string]: never; }>;


export type GetAnalysisScenarios = (
  { __typename?: 'RealTraxQuery' }
  & { analysisScenarios?: Maybe<Array<(
    { __typename?: 'ScenarioType' }
    & { value: ScenarioType['id'], label: ScenarioType['name'] }
  )>> }
);

export type GetAnalysisTypesVariables = Exact<{ [key: string]: never; }>;


export type GetAnalysisTypes = (
  { __typename?: 'RealTraxQuery' }
  & { analysisTypes?: Maybe<Array<(
    { __typename?: 'AnalysisType' }
    & Pick<AnalysisType, 'id' | 'acronym' | 'name'>
  )>> }
);

export type GetMetricsVariables = Exact<{ [key: string]: never; }>;


export type GetMetrics = (
  { __typename?: 'RealTraxQuery' }
  & { metrics?: Maybe<Array<(
    { __typename?: 'Metric' }
    & Pick<Metric, 'precision' | 'scale' | 'isDisabled' | 'isAppDefault'>
    & MetricFragment
  )>> }
);

export type GetTextMetricsVariables = Exact<{ [key: string]: never; }>;


export type GetTextMetrics = (
  { __typename?: 'RealTraxQuery' }
  & { textMetrics?: Maybe<Array<(
    { __typename?: 'TextMetric' }
    & Pick<TextMetric, 'isDisabled' | 'isAppDefault'>
    & TextMetricFragment
  )>> }
);

export type GetOrganizationUsersVariables = Exact<{ [key: string]: never; }>;


export type GetOrganizationUsers = (
  { __typename?: 'RealTraxQuery' }
  & { users?: Maybe<Array<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'firstName' | 'lastName' | 'username' | 'email' | 'createdAt' | 'updatedAt'>
  )>> }
);

export type GetOrganizationGroupsVariables = Exact<{ [key: string]: never; }>;


export type GetOrganizationGroups = (
  { __typename?: 'RealTraxQuery' }
  & { groups?: Maybe<Array<(
    { __typename?: 'Group' }
    & Pick<Group, 'id' | 'name' | 'memberCount' | 'createdAt' | 'updatedAt'>
  )>> }
);

export type GetOrganizationGroupVariables = Exact<{
  id: Scalars['Int'];
}>;


export type GetOrganizationGroup = (
  { __typename?: 'RealTraxQuery' }
  & { group?: Maybe<(
    { __typename?: 'Group' }
    & Pick<Group, 'id' | 'name' | 'createdAt' | 'updatedAt'>
    & { users?: Maybe<Array<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'firstName' | 'lastName' | 'username' | 'email' | 'createdAt' | 'updatedAt'>
    )>> }
  )> }
);

export type GetPermissionRolesVariables = Exact<{ [key: string]: never; }>;


export type GetPermissionRoles = (
  { __typename?: 'RealTraxQuery' }
  & { permissionRoles?: Maybe<Array<(
    { __typename?: 'Role' }
    & Pick<Role, 'id' | 'name' | 'createdAt' | 'updatedAt'>
  )>> }
);

export type GetPortfolioViewPaginatedVariables = Exact<{
  id: Scalars['Int'];
  viewId: Scalars['Int'];
  limit: Scalars['Int'];
  offset: Scalars['Int'];
  showArchivedDeals: Scalars['Boolean'];
}>;


export type GetPortfolioViewPaginated = (
  { __typename?: 'RealTraxQuery' }
  & { portfolio?: Maybe<(
    { __typename?: 'Portfolio' }
    & Pick<Portfolio, 'id' | 'name'>
    & { pinnedView?: Maybe<(
      { __typename?: 'PortfolioView' }
      & Pick<PortfolioView, 'id' | 'name' | 'isDefault' | 'orderId' | 'sortModel' | 'filterModel' | 'groupRowColumns' | 'showArchivedDeals'>
      & { columns?: Maybe<Array<(
        { __typename?: 'PortfolioViewColumn' }
        & Pick<PortfolioViewColumn, 'id' | 'name' | 'lookupName' | 'type' | 'orderId' | 'sortOrder'>
      )>>, payload?: Maybe<(
        { __typename?: 'PortfolioViewPayload' }
        & Pick<PortfolioViewPayload, 'columns' | 'rows' | 'hasMoreDeals'>
      )> }
    )> }
  )> }
);

export type GetPortfolioViewVariables = Exact<{
  id: Scalars['Int'];
  viewId: Scalars['Int'];
  showArchivedDeals: Scalars['Boolean'];
}>;


export type GetPortfolioView = (
  { __typename?: 'RealTraxQuery' }
  & { portfolio?: Maybe<(
    { __typename?: 'Portfolio' }
    & Pick<Portfolio, 'id' | 'name'>
    & { pinnedView?: Maybe<(
      { __typename?: 'PortfolioView' }
      & Pick<PortfolioView, 'id' | 'name' | 'isDefault' | 'orderId' | 'sortModel' | 'filterModel' | 'groupRowColumns' | 'showArchivedDeals'>
      & { columns?: Maybe<Array<(
        { __typename?: 'PortfolioViewColumn' }
        & Pick<PortfolioViewColumn, 'id' | 'name' | 'lookupName' | 'type' | 'orderId' | 'sortOrder'>
      )>>, payload?: Maybe<(
        { __typename?: 'PortfolioViewPayload' }
        & Pick<PortfolioViewPayload, 'columns' | 'rows' | 'hasMoreDeals'>
      )> }
    )> }
  )> }
);

export type GetPortfolioViewColumnsVariables = Exact<{
  id: Scalars['Int'];
  viewId: Scalars['Int'];
}>;


export type GetPortfolioViewColumns = (
  { __typename?: 'RealTraxQuery' }
  & { portfolio?: Maybe<(
    { __typename?: 'Portfolio' }
    & Pick<Portfolio, 'id' | 'name'>
    & { pinnedView?: Maybe<(
      { __typename?: 'PortfolioView' }
      & Pick<PortfolioView, 'id' | 'name' | 'isDefault' | 'orderId' | 'sortModel' | 'filterModel' | 'groupRowColumns' | 'showArchivedDeals'>
      & { columns?: Maybe<Array<(
        { __typename?: 'PortfolioViewColumn' }
        & Pick<PortfolioViewColumn, 'id' | 'name' | 'lookupName' | 'type' | 'orderId' | 'sortOrder'>
      )>> }
    )> }
  )> }
);

export type GetPortfolioViewsVariables = Exact<{
  id: Scalars['Int'];
}>;


export type GetPortfolioViews = (
  { __typename?: 'RealTraxQuery' }
  & { portfolio?: Maybe<(
    { __typename?: 'Portfolio' }
    & Pick<Portfolio, 'id' | 'name'>
    & { pinnedViews?: Maybe<Array<(
      { __typename?: 'PortfolioView' }
      & Pick<PortfolioView, 'id' | 'name' | 'showAnalysisEvents' | 'isDefault' | 'orderId'>
      & { analysisType?: Maybe<(
        { __typename?: 'AnalysisType' }
        & Pick<AnalysisType, 'id' | 'name'>
      )> }
    )>> }
  )> }
);

export type AllViewColumnOptionsVariables = Exact<{ [key: string]: never; }>;


export type AllViewColumnOptions = (
  { __typename?: 'RealTraxQuery' }
  & { viewColumnOptions?: Maybe<Array<(
    { __typename?: 'PortfolioViewColumnOption' }
    & Pick<PortfolioViewColumnOption, 'id' | 'name' | 'lookupName' | 'type'>
  )>> }
);

export type GetEventsVariables = Exact<{ [key: string]: never; }>;


export type GetEvents = (
  { __typename?: 'RealTraxQuery' }
  & { events?: Maybe<Array<(
    { __typename?: 'Event' }
    & Pick<Event, 'id' | 'name' | 'description' | 'type' | 'orderId' | 'isDisabled' | 'isAppDefault'>
    & { parent?: Maybe<(
      { __typename?: 'Event' }
      & Pick<Event, 'id' | 'name'>
    )> }
  )>> }
);

export type GetEventVariables = Exact<{
  id: Scalars['Int'];
}>;


export type GetEvent = (
  { __typename?: 'RealTraxQuery' }
  & { event?: Maybe<(
    { __typename?: 'Event' }
    & Pick<Event, 'id' | 'name' | 'description' | 'isDisabled' | 'isAppDefault' | 'type' | 'orderId'>
    & { parent?: Maybe<(
      { __typename?: 'Event' }
      & Pick<Event, 'id' | 'name'>
    )> }
  )> }
);

export type GetDealAttributesVariables = Exact<{ [key: string]: never; }>;


export type GetDealAttributes = (
  { __typename?: 'RealTraxQuery' }
  & { dealAttributes?: Maybe<Array<(
    { __typename?: 'DealAttribute' }
    & Pick<DealAttribute, 'id' | 'displayName' | 'columnName' | 'dataType'>
  )>> }
);

export type GetDealAnalysesVariables = Exact<{
  id: Scalars['Int'];
}>;


export type GetDealAnalyses = (
  { __typename?: 'RealTraxQuery' }
  & { deal?: Maybe<(
    { __typename?: 'Deal' }
    & Pick<Deal, 'id'>
    & { pipeline: (
      { __typename?: 'Pipeline' }
      & { pinnedMetrics?: Maybe<Array<(
        { __typename?: 'Metric' }
        & Pick<Metric, 'id' | 'name'>
      )>> }
    ), currentAnalysis?: Maybe<(
      { __typename?: 'DealAnalysis' }
      & Pick<DealAnalysis, 'id' | 'description'>
    )>, analyses?: Maybe<Array<(
      { __typename?: 'DealAnalysis' }
      & Pick<DealAnalysis, 'id' | 'description' | 'createdAt' | 'updatedAt' | 'archiveDate'>
      & { scenario: (
        { __typename?: 'ScenarioType' }
        & Pick<ScenarioType, 'id' | 'name'>
      ), type: (
        { __typename?: 'AnalysisType' }
        & Pick<AnalysisType, 'id' | 'name'>
      ), archivedApprovalPackages?: Maybe<Array<(
        { __typename?: 'ApprovalPackage' }
        & Pick<ApprovalPackage, 'id' | 'status'>
        & { type: (
          { __typename?: 'ApprovalPackageType' }
          & Pick<ApprovalPackageType, 'id' | 'name'>
        ) }
      )>>, pinnedMetrics?: Maybe<Array<(
        { __typename?: 'AnalysisMetric' }
        & Pick<AnalysisMetric, 'id' | 'value'>
        & { metric: (
          { __typename?: 'Metric' }
          & Pick<Metric, 'scale'>
          & MetricFragment
        ) }
      )>> }
    )>> }
  )> }
);

export type GetDealCurrentAnalysisVariables = Exact<{
  id: Scalars['Int'];
}>;


export type GetDealCurrentAnalysis = (
  { __typename?: 'RealTraxQuery' }
  & { deal?: Maybe<(
    { __typename?: 'Deal' }
    & Pick<Deal, 'id'>
    & { currentAnalysis?: Maybe<(
      { __typename?: 'DealAnalysis' }
      & Pick<DealAnalysis, 'id' | 'description' | 'createdAt' | 'updatedAt' | 'archiveDate'>
      & { scenario: (
        { __typename?: 'ScenarioType' }
        & Pick<ScenarioType, 'id' | 'name'>
      ), metrics?: Maybe<Array<(
        { __typename?: 'AnalysisMetric' }
        & Pick<AnalysisMetric, 'id' | 'value'>
        & { metric: (
          { __typename?: 'Metric' }
          & Pick<Metric, 'scale'>
          & MetricFragment
        ) }
      )>> }
    )> }
  )> }
);

export type GetDealMasterBudgetDataVariables = Exact<{
  id: Scalars['Int'];
}>;


export type GetDealMasterBudgetData = (
  { __typename?: 'RealTraxQuery' }
  & { deal?: Maybe<(
    { __typename?: 'Deal' }
    & Pick<Deal, 'id' | 'name' | 'currentUserCanEdit'>
    & { pipeline: (
      { __typename?: 'Pipeline' }
      & { defaultAccountTree?: Maybe<(
        { __typename?: 'GlAccountTree' }
        & Pick<GlAccountTree, 'id' | 'name'>
      )> }
    ), forecastRules?: Maybe<Array<(
      { __typename?: 'ForecastRuleDeal' }
      & Pick<ForecastRuleDeal, 'id' | 'updatedAt' | 'isEnabled'>
      & { updatedBy: (
        { __typename?: 'SystemProcess' }
        & Pick<SystemProcess, 'id' | 'displayName'>
      ) | (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'displayName'>
      ), forecastRule: (
        { __typename?: 'ForecastRule' }
        & ForecastRuleFragment
      ) }
    )>> }
  )>, accountTrees?: Maybe<Array<(
    { __typename?: 'GlAccountTree' }
    & Pick<GlAccountTree, 'id' | 'name' | 'isDisabled' | 'isAppDefault'>
  )>> }
);

export type GetDealAnalysisVariables = Exact<{
  id: Scalars['Int'];
  fkAnalysis: Scalars['Int'];
}>;


export type GetDealAnalysis = (
  { __typename?: 'RealTraxQuery' }
  & { deal?: Maybe<(
    { __typename?: 'Deal' }
    & Pick<Deal, 'id' | 'usesLatestAnalysisAsCurrent'>
    & { currentAnalysis?: Maybe<(
      { __typename?: 'DealAnalysis' }
      & Pick<DealAnalysis, 'id'>
    )>, analysis?: Maybe<(
      { __typename?: 'DealAnalysis' }
      & Pick<DealAnalysis, 'id' | 'description' | 'createdAt' | 'updatedAt' | 'archiveDate'>
      & { type: (
        { __typename?: 'AnalysisType' }
        & Pick<AnalysisType, 'name'>
      ), scenario: (
        { __typename?: 'ScenarioType' }
        & Pick<ScenarioType, 'id' | 'name'>
      ), metrics?: Maybe<Array<(
        { __typename?: 'AnalysisMetric' }
        & Pick<AnalysisMetric, 'id' | 'value'>
        & { metric: (
          { __typename?: 'Metric' }
          & Pick<Metric, 'scale'>
          & MetricFragment
        ) }
      )>>, textMetrics?: Maybe<Array<(
        { __typename?: 'AnalysisTextMetric' }
        & Pick<AnalysisTextMetric, 'id' | 'value'>
        & { textMetric: (
          { __typename?: 'TextMetric' }
          & TextMetricFragment
        ) }
      )>>, events?: Maybe<Array<(
        { __typename?: 'AnalysisEvent' }
        & Pick<AnalysisEvent, 'id' | 'startDate'>
        & { event: (
          { __typename?: 'Event' }
          & EventFragment
        ) }
      )>> }
    )> }
  )> }
);

export type GetDealMasterBudgetOverviewVariables = Exact<{
  id: Scalars['Int'];
  fkGlAccountTree: Scalars['Int'];
}>;


export type GetDealMasterBudgetOverview = (
  { __typename?: 'RealTraxQuery' }
  & { accountTree?: Maybe<(
    { __typename?: 'GlAccountTree' }
    & Pick<GlAccountTree, 'id' | 'name'>
    & { reportType?: Maybe<(
      { __typename?: 'ReportType' }
      & Pick<ReportType, 'id' | 'name'>
    )>, branches?: Maybe<Array<(
      { __typename?: 'GlAccountTreeBranch' }
      & Pick<GlAccountTreeBranch, 'id' | 'level' | 'reverseFromDrCr' | 'fkParentBranch'>
      & { account: (
        { __typename?: 'GlAccount' }
        & Pick<GlAccount, 'id' | 'name' | 'code' | 'displayCode'>
      ) }
    )>> }
  )>, deal?: Maybe<(
    { __typename?: 'Deal' }
    & Pick<Deal, 'id' | 'name'>
    & { pipeline: (
      { __typename?: 'Pipeline' }
      & { defaultAccountTree?: Maybe<(
        { __typename?: 'GlAccountTree' }
        & Pick<GlAccountTree, 'id' | 'name'>
      )> }
    ), accountTreeForecasts?: Maybe<Array<(
      { __typename?: 'GlAccountSummary' }
      & Pick<GlAccountSummary, 'postDate' | 'amount'>
      & { account: (
        { __typename?: 'GlAccount' }
        & Pick<GlAccount, 'id' | 'name' | 'code' | 'displayCode'>
      ) }
    )>>, accountTreeActuals?: Maybe<Array<(
      { __typename?: 'GlAccountSummary' }
      & Pick<GlAccountSummary, 'postDate' | 'amount'>
      & { account: (
        { __typename?: 'GlAccount' }
        & Pick<GlAccount, 'id' | 'name' | 'code' | 'displayCode'>
      ) }
    )>>, approvedBudget?: Maybe<Array<(
      { __typename?: 'CostAccountBudgetSummary' }
      & Pick<CostAccountBudgetSummary, 'amount'>
      & { account: (
        { __typename?: 'GlAccount' }
        & Pick<GlAccount, 'id' | 'name' | 'code' | 'displayCode'>
      ) }
    )>>, pendingBudget?: Maybe<Array<(
      { __typename?: 'CostAccountBudgetSummary' }
      & Pick<CostAccountBudgetSummary, 'amount'>
      & { account: (
        { __typename?: 'GlAccount' }
        & Pick<GlAccount, 'id' | 'name' | 'code' | 'displayCode'>
      ) }
    )>> }
  )> }
);

export type GetDealTransactionsVariables = Exact<{
  id: Scalars['Int'];
}>;


export type GetDealTransactions = (
  { __typename?: 'RealTraxQuery' }
  & { deal?: Maybe<(
    { __typename?: 'Deal' }
    & { transactions?: Maybe<Array<(
      { __typename?: 'Transaction' }
      & Pick<Transaction, 'id' | 'amount' | 'createdAt' | 'updatedAt' | 'startDate' | 'endDate' | 'isOverride' | 'isStale' | 'isRefundable' | 'hasCashPaymentOverride' | 'milestoneAdjustmentStart' | 'milestoneAdjustmentEnd' | 'description'>
      & { start?: Maybe<(
        { __typename: 'TransactionDate' }
        & TransactionDateFragment
      ) | (
        { __typename: 'TransactionEvent' }
        & TransactionEventFragment
      )>, end?: Maybe<(
        { __typename: 'TransactionDate' }
        & TransactionDateFragment
      ) | (
        { __typename: 'TransactionEvent' }
        & TransactionEventFragment
      )>, createdBy: (
        { __typename?: 'SystemProcess' }
        & Pick<SystemProcess, 'id' | 'displayName'>
      ) | (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'displayName'>
      ), updatedBy: (
        { __typename?: 'SystemProcess' }
        & Pick<SystemProcess, 'id' | 'displayName'>
      ) | (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'displayName'>
      ), account: (
        { __typename?: 'GlAccount' }
        & Pick<GlAccount, 'id' | 'name' | 'code' | 'displayCode'>
      ), vendor?: Maybe<(
        { __typename?: 'Firm' }
        & Pick<Firm, 'id' | 'name'>
      )>, contract?: Maybe<(
        { __typename?: 'Contract' }
        & Pick<Contract, 'id' | 'name'>
      )>, distributionType: (
        { __typename?: 'DistributionType' }
        & Pick<DistributionType, 'id' | 'name'>
      ) }
    )>> }
  )> }
);

export type GetDealTransactionVariables = Exact<{
  id: Scalars['Int'];
  transactionId: Scalars['Int'];
}>;


export type GetDealTransaction = (
  { __typename?: 'RealTraxQuery' }
  & { deal?: Maybe<(
    { __typename?: 'Deal' }
    & { transaction?: Maybe<(
      { __typename?: 'Transaction' }
      & Pick<Transaction, 'id' | 'amount' | 'isRefundable' | 'hasCashPaymentOverride' | 'isOverride' | 'createdAt' | 'updatedAt' | 'milestoneAdjustmentStart' | 'milestoneAdjustmentEnd' | 'isStale' | 'description'>
      & { start?: Maybe<(
        { __typename: 'TransactionDate' }
        & TransactionDateFragment
      ) | (
        { __typename: 'TransactionEvent' }
        & TransactionEventFragment
      )>, end?: Maybe<(
        { __typename: 'TransactionDate' }
        & TransactionDateFragment
      ) | (
        { __typename: 'TransactionEvent' }
        & TransactionEventFragment
      )>, createdBy: (
        { __typename?: 'SystemProcess' }
        & Pick<SystemProcess, 'id' | 'displayName'>
      ) | (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'displayName'>
      ), updatedBy: (
        { __typename?: 'SystemProcess' }
        & Pick<SystemProcess, 'id' | 'displayName'>
      ) | (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'displayName'>
      ), account: (
        { __typename?: 'GlAccount' }
        & Pick<GlAccount, 'id' | 'name' | 'code' | 'displayCode'>
      ), vendor?: Maybe<(
        { __typename?: 'Firm' }
        & Pick<Firm, 'id' | 'name'>
      )>, contract?: Maybe<(
        { __typename?: 'Contract' }
        & Pick<Contract, 'id' | 'name'>
      )>, distributionType: (
        { __typename?: 'DistributionType' }
        & Pick<DistributionType, 'id' | 'name'>
      ), details?: Maybe<Array<(
        { __typename?: 'TranasctionPeriodDetailPreview' }
        & Pick<TranasctionPeriodDetailPreview, 'date' | 'amount' | 'type'>
      )>> }
    )> }
  )> }
);

export type TransactionDateFragment = (
  { __typename?: 'TransactionDate' }
  & Pick<TransactionDate, 'date'>
);

export type TransactionEventFragment = (
  { __typename?: 'TransactionEvent' }
  & Pick<TransactionEvent, 'usesStartDate' | 'milestoneOffset'>
  & { event: (
    { __typename?: 'DealEvent' }
    & Pick<DealEvent, 'id' | 'name' | 'startDate' | 'endDate' | 'type'>
  ) }
);

export type GetDealRevisionsVariables = Exact<{
  id: Scalars['Int'];
}>;


export type GetDealRevisions = (
  { __typename?: 'RealTraxQuery' }
  & { deal?: Maybe<(
    { __typename?: 'Deal' }
    & { revisions?: Maybe<Array<(
      { __typename?: 'Revision' }
      & Pick<Revision, 'id' | 'name' | 'createdAt' | 'updatedAt' | 'total' | 'approvedDate' | 'applicableDate'>
      & { createdBy: (
        { __typename?: 'SystemProcess' }
        & Pick<SystemProcess, 'id' | 'displayName'>
      ) | (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'displayName'>
      ), updatedBy: (
        { __typename?: 'SystemProcess' }
        & Pick<SystemProcess, 'id' | 'displayName'>
      ) | (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'displayName'>
      ) }
    )>> }
  )> }
);

export type GetDealContractsVariables = Exact<{
  id: Scalars['Int'];
}>;


export type GetDealContracts = (
  { __typename?: 'RealTraxQuery' }
  & { deal?: Maybe<(
    { __typename?: 'Deal' }
    & { contracts?: Maybe<Array<(
      { __typename?: 'Contract' }
      & Pick<Contract, 'id' | 'name' | 'createdAt' | 'updatedAt' | 'amount'>
      & { vendor?: Maybe<(
        { __typename?: 'Firm' }
        & Pick<Firm, 'id' | 'name'>
      )>, createdBy: (
        { __typename?: 'SystemProcess' }
        & Pick<SystemProcess, 'id' | 'displayName'>
      ) | (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'displayName'>
      ), updatedBy: (
        { __typename?: 'SystemProcess' }
        & Pick<SystemProcess, 'id' | 'displayName'>
      ) | (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'displayName'>
      ) }
    )>> }
  )> }
);

export type GetDealContractVariables = Exact<{
  id: Scalars['Int'];
  contractId: Scalars['Int'];
}>;


export type GetDealContract = (
  { __typename?: 'RealTraxQuery' }
  & { deal?: Maybe<(
    { __typename?: 'Deal' }
    & { contract?: Maybe<(
      { __typename?: 'Contract' }
      & Pick<Contract, 'id' | 'name' | 'createdAt' | 'updatedAt' | 'amount'>
      & { vendor?: Maybe<(
        { __typename?: 'Firm' }
        & Pick<Firm, 'id' | 'name'>
      )>, transactions?: Maybe<Array<(
        { __typename?: 'Transaction' }
        & Pick<Transaction, 'id' | 'amount' | 'createdAt' | 'updatedAt' | 'startDate' | 'endDate' | 'isStale' | 'description'>
        & { createdBy: (
          { __typename?: 'SystemProcess' }
          & Pick<SystemProcess, 'id' | 'displayName'>
        ) | (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'displayName'>
        ), updatedBy: (
          { __typename?: 'SystemProcess' }
          & Pick<SystemProcess, 'id' | 'displayName'>
        ) | (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'displayName'>
        ), account: (
          { __typename?: 'GlAccount' }
          & Pick<GlAccount, 'id' | 'name' | 'code' | 'displayCode'>
        ), vendor?: Maybe<(
          { __typename?: 'Firm' }
          & Pick<Firm, 'id' | 'name'>
        )>, distributionType: (
          { __typename?: 'DistributionType' }
          & Pick<DistributionType, 'id' | 'name'>
        ) }
      )>>, createdBy: (
        { __typename?: 'SystemProcess' }
        & Pick<SystemProcess, 'id' | 'displayName'>
      ) | (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'displayName'>
      ), updatedBy: (
        { __typename?: 'SystemProcess' }
        & Pick<SystemProcess, 'id' | 'displayName'>
      ) | (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'displayName'>
      ) }
    )> }
  )> }
);

export type GetDealPublishedCapitalForecastsVariables = Exact<{
  id: Scalars['Int'];
}>;


export type GetDealPublishedCapitalForecasts = (
  { __typename?: 'RealTraxQuery' }
  & { deal?: Maybe<(
    { __typename?: 'Deal' }
    & Pick<Deal, 'id' | 'name'>
    & { publishedCapitalForecasts?: Maybe<Array<(
      { __typename?: 'PublishedCapitalForecast' }
      & Pick<PublishedCapitalForecast, 'id' | 'name' | 'comment' | 'createdAt' | 'updatedAt'>
      & { createdBy: (
        { __typename?: 'SystemProcess' }
        & Pick<SystemProcess, 'id' | 'displayName'>
      ) | (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'displayName'>
      ) }
    )>> }
  )> }
);

export type GetDealPublishedMasterBudgetOverviewVariables = Exact<{
  id: Scalars['Int'];
  publishedCapitalForecastId: Scalars['Int'];
  fkGlAccountTree: Scalars['Int'];
}>;


export type GetDealPublishedMasterBudgetOverview = (
  { __typename?: 'RealTraxQuery' }
  & { accountTree?: Maybe<(
    { __typename?: 'GlAccountTree' }
    & Pick<GlAccountTree, 'id' | 'name'>
    & { reportType?: Maybe<(
      { __typename?: 'ReportType' }
      & Pick<ReportType, 'id' | 'name'>
    )>, branches?: Maybe<Array<(
      { __typename?: 'GlAccountTreeBranch' }
      & Pick<GlAccountTreeBranch, 'id' | 'level' | 'reverseFromDrCr' | 'fkParentBranch'>
      & { account: (
        { __typename?: 'GlAccount' }
        & Pick<GlAccount, 'id' | 'name' | 'code' | 'displayCode'>
      ) }
    )>> }
  )>, deal?: Maybe<(
    { __typename?: 'Deal' }
    & Pick<Deal, 'id' | 'name'>
    & { pipeline: (
      { __typename?: 'Pipeline' }
      & { defaultAccountTree?: Maybe<(
        { __typename?: 'GlAccountTree' }
        & Pick<GlAccountTree, 'id' | 'name'>
      )> }
    ), publishedCapitalForecast?: Maybe<(
      { __typename?: 'PublishedCapitalForecast' }
      & Pick<PublishedCapitalForecast, 'id' | 'name' | 'comment' | 'createdAt' | 'updatedAt'>
      & { createdBy: (
        { __typename?: 'SystemProcess' }
        & Pick<SystemProcess, 'id' | 'displayName'>
      ) | (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'displayName'>
      ), accountTreeForecasts?: Maybe<Array<(
        { __typename?: 'GlAccountSummary' }
        & Pick<GlAccountSummary, 'postDate' | 'amount'>
        & { account: (
          { __typename?: 'GlAccount' }
          & Pick<GlAccount, 'id' | 'name' | 'code' | 'displayCode'>
        ) }
      )>>, accountTreeActuals?: Maybe<Array<(
        { __typename?: 'GlAccountSummary' }
        & Pick<GlAccountSummary, 'postDate' | 'amount'>
        & { account: (
          { __typename?: 'GlAccount' }
          & Pick<GlAccount, 'id' | 'name' | 'code' | 'displayCode'>
        ) }
      )>>, approvedBudget?: Maybe<Array<(
        { __typename?: 'CostAccountBudgetSummary' }
        & Pick<CostAccountBudgetSummary, 'amount'>
        & { account: (
          { __typename?: 'GlAccount' }
          & Pick<GlAccount, 'id' | 'name' | 'code' | 'displayCode'>
        ) }
      )>>, pendingBudget?: Maybe<Array<(
        { __typename?: 'CostAccountBudgetSummary' }
        & Pick<CostAccountBudgetSummary, 'amount'>
        & { account: (
          { __typename?: 'GlAccount' }
          & Pick<GlAccount, 'id' | 'name' | 'code' | 'displayCode'>
        ) }
      )>> }
    )> }
  )> }
);

export type GetDealTransactionFormOptionsVariables = Exact<{
  id: Scalars['Int'];
  accountTreeId: Scalars['Int'];
}>;


export type GetDealTransactionFormOptions = (
  { __typename?: 'RealTraxQuery' }
  & { accountTree?: Maybe<(
    { __typename?: 'GlAccountTree' }
    & Pick<GlAccountTree, 'id'>
    & { reportType?: Maybe<(
      { __typename?: 'ReportType' }
      & Pick<ReportType, 'id' | 'name'>
    )>, branches?: Maybe<Array<(
      { __typename?: 'GlAccountTreeBranch' }
      & Pick<GlAccountTreeBranch, 'id' | 'level'>
      & { account: (
        { __typename?: 'GlAccount' }
        & Pick<GlAccount, 'id' | 'name' | 'code' | 'displayCode'>
        & { type?: Maybe<(
          { __typename?: 'GlAccountType' }
          & Pick<GlAccountType, 'id' | 'name'>
        )> }
      ) }
    )>> }
  )>, deal?: Maybe<(
    { __typename?: 'Deal' }
    & { contracts?: Maybe<Array<(
      { __typename?: 'Contract' }
      & { value: Contract['id'], label: Contract['name'] }
    )>>, events?: Maybe<Array<(
      { __typename?: 'DealEvent' }
      & Pick<DealEvent, 'id' | 'name' | 'startDate' | 'endDate' | 'type'>
    )>>, pipeline: (
      { __typename?: 'Pipeline' }
      & { firms: Array<(
        { __typename?: 'Firm' }
        & Pick<Firm, 'id' | 'name'>
      )> }
    ) }
  )> }
);

export type GetBudgetRevisionVariables = Exact<{
  id: Scalars['Int'];
  revisionId: Scalars['Int'];
  accountTreeId: Scalars['Int'];
}>;


export type GetBudgetRevision = (
  { __typename?: 'RealTraxQuery' }
  & { accountTree?: Maybe<(
    { __typename?: 'GlAccountTree' }
    & Pick<GlAccountTree, 'id'>
    & { reportType?: Maybe<(
      { __typename?: 'ReportType' }
      & Pick<ReportType, 'id' | 'name'>
    )>, branches?: Maybe<Array<(
      { __typename?: 'GlAccountTreeBranch' }
      & Pick<GlAccountTreeBranch, 'id' | 'level'>
      & { account: (
        { __typename?: 'GlAccount' }
        & Pick<GlAccount, 'id' | 'name' | 'code' | 'displayCode'>
        & { type?: Maybe<(
          { __typename?: 'GlAccountType' }
          & Pick<GlAccountType, 'id' | 'name'>
        )> }
      ) }
    )>> }
  )>, deal?: Maybe<(
    { __typename?: 'Deal' }
    & Pick<Deal, 'id' | 'name'>
    & { revision?: Maybe<(
      { __typename?: 'Revision' }
      & Pick<Revision, 'id' | 'name' | 'approvedDate' | 'applicableDate' | 'total'>
      & { details?: Maybe<Array<(
        { __typename?: 'RevisionCostAccount' }
        & Pick<RevisionCostAccount, 'id' | 'amount' | 'isPursuit'>
        & { account: (
          { __typename?: 'GlAccount' }
          & Pick<GlAccount, 'id' | 'name' | 'code' | 'displayCode'>
        ) }
      )>> }
    )> }
  )> }
);

export type GetDealAccountForecastsVariables = Exact<{
  id: Scalars['Int'];
  accountIds: Array<Scalars['Int']> | Scalars['Int'];
  start?: Maybe<Scalars['Date']>;
  end?: Maybe<Scalars['Date']>;
  excludeTransactionId?: Maybe<Scalars['Int']>;
}>;


export type GetDealAccountForecasts = (
  { __typename?: 'RealTraxQuery' }
  & { deal?: Maybe<(
    { __typename?: 'Deal' }
    & { accountForecasts?: Maybe<Array<(
      { __typename?: 'GlAccountSummary' }
      & Pick<GlAccountSummary, 'postDate' | 'amount'>
      & { account: (
        { __typename?: 'GlAccount' }
        & Pick<GlAccount, 'id' | 'name' | 'code' | 'displayCode'>
      ) }
    )>> }
  )> }
);

export type DealEventFragment = (
  { __typename?: 'DealEvent' }
  & Pick<DealEvent, 'id' | 'dealEventId' | 'name' | 'orderId' | 'isEditable' | 'startDate' | 'endDate' | 'isComplete' | 'type' | 'commentCount'>
  & { category?: Maybe<(
    { __typename?: 'EventCategory' }
    & Pick<EventCategory, 'id' | 'name'>
  )>, parent?: Maybe<(
    { __typename?: 'Event' }
    & Pick<Event, 'id' | 'name'>
  )>, dependsOn?: Maybe<Array<(
    { __typename?: 'Event' }
    & Pick<Event, 'id' | 'name'>
  )>> }
);

export type PublishedDealEventFragment = (
  { __typename?: 'PublishedDealEvent' }
  & Pick<PublishedDealEvent, 'id' | 'dealEventId' | 'name' | 'orderId' | 'startDate' | 'endDate' | 'isComplete' | 'type'>
  & { category?: Maybe<(
    { __typename?: 'EventCategory' }
    & Pick<EventCategory, 'id' | 'name'>
  )>, parent?: Maybe<(
    { __typename?: 'Event' }
    & Pick<Event, 'id' | 'name'>
  )>, dependsOn?: Maybe<Array<(
    { __typename?: 'Event' }
    & Pick<Event, 'id' | 'name'>
  )>> }
);

export type GetPublishedScheduleInstancesVariables = Exact<{
  id: Scalars['Int'];
}>;


export type GetPublishedScheduleInstances = (
  { __typename?: 'RealTraxQuery' }
  & { deal?: Maybe<(
    { __typename?: 'Deal' }
    & { publishedScheduleInstances?: Maybe<Array<(
      { __typename?: 'PublishedScheduleInstance' }
      & Pick<PublishedScheduleInstance, 'id' | 'description' | 'createdAt' | 'updatedAt'>
      & { createdBy: (
        { __typename?: 'SystemProcess' }
        & Pick<SystemProcess, 'id' | 'displayName'>
      ) | (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'displayName'>
      ), updatedBy: (
        { __typename?: 'SystemProcess' }
        & Pick<SystemProcess, 'id' | 'displayName'>
      ) | (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'displayName'>
      ) }
    )>> }
  )> }
);

export type GetPublishedScheduleInstanceVariables = Exact<{
  id: Scalars['Int'];
  fkPublishedScheduleInstance: Scalars['Int'];
}>;


export type GetPublishedScheduleInstance = (
  { __typename?: 'RealTraxQuery' }
  & { deal?: Maybe<(
    { __typename?: 'Deal' }
    & { publishedScheduleInstance?: Maybe<(
      { __typename?: 'PublishedScheduleInstance' }
      & Pick<PublishedScheduleInstance, 'id' | 'description' | 'createdAt' | 'updatedAt'>
      & { createdBy: (
        { __typename?: 'SystemProcess' }
        & Pick<SystemProcess, 'id' | 'displayName'>
      ) | (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'displayName'>
      ), updatedBy: (
        { __typename?: 'SystemProcess' }
        & Pick<SystemProcess, 'id' | 'displayName'>
      ) | (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'displayName'>
      ), events?: Maybe<Array<(
        { __typename?: 'PublishedDealEvent' }
        & PublishedDealEventFragment
      )>> }
    )> }
  )> }
);

export type GetDealBuildingsVariables = Exact<{
  id: Scalars['Int'];
}>;


export type GetDealBuildings = (
  { __typename?: 'RealTraxQuery' }
  & { deal?: Maybe<(
    { __typename?: 'Deal' }
    & { buildings?: Maybe<Array<(
      { __typename?: 'Building' }
      & BuildingFragment
    )>> }
  )> }
);

export type GetDealBuildingVariables = Exact<{
  id: Scalars['Int'];
  fkBuilding: Scalars['Int'];
}>;


export type GetDealBuilding = (
  { __typename?: 'RealTraxQuery' }
  & { deal?: Maybe<(
    { __typename?: 'Deal' }
    & { building?: Maybe<(
      { __typename?: 'Building' }
      & { comments?: Maybe<Array<(
        { __typename?: 'Comment' }
        & Pick<Comment, 'id' | 'body' | 'createdAt' | 'updatedAt'>
        & { createdBy: (
          { __typename?: 'SystemProcess' }
          & Pick<SystemProcess, 'id' | 'displayName' | 'displayNameAlt'>
        ) | (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'displayName' | 'displayNameAlt'>
        ) }
      )>> }
      & BuildingFragment
    )> }
  )> }
);

export type BuildingFragment = (
  { __typename?: 'Building' }
  & Pick<Building, 'id' | 'name' | 'address' | 'buildingNumber' | 'notes' | 'isTcoReceived' | 'createdAt' | 'updatedAt' | 'tcoDate' | 'unitCount' | 'commentCount'>
  & { createdBy: (
    { __typename?: 'SystemProcess' }
    & Pick<SystemProcess, 'id' | 'displayName'>
  ) | (
    { __typename?: 'User' }
    & Pick<User, 'id' | 'displayName'>
  ), updatedBy: (
    { __typename?: 'SystemProcess' }
    & Pick<SystemProcess, 'id' | 'displayName'>
  ) | (
    { __typename?: 'User' }
    & Pick<User, 'id' | 'displayName'>
  ), type: (
    { __typename?: 'BuildingType' }
    & Pick<BuildingType, 'id' | 'name'>
  ) }
);

export type GetDealBondsVariables = Exact<{
  id: Scalars['Int'];
}>;


export type GetDealBonds = (
  { __typename?: 'RealTraxQuery' }
  & { deal?: Maybe<(
    { __typename?: 'Deal' }
    & { bonds?: Maybe<Array<(
      { __typename?: 'Bond' }
      & BondFragment
    )>> }
  )> }
);

export type GetDealBondVariables = Exact<{
  id: Scalars['Int'];
  fkBond: Scalars['Int'];
}>;


export type GetDealBond = (
  { __typename?: 'RealTraxQuery' }
  & { deal?: Maybe<(
    { __typename?: 'Deal' }
    & { bond?: Maybe<(
      { __typename?: 'Bond' }
      & BondFragment
    )> }
  )> }
);

export type GetDealGcEstimatesVariables = Exact<{
  id: Scalars['Int'];
}>;


export type GetDealGcEstimates = (
  { __typename?: 'RealTraxQuery' }
  & { deal?: Maybe<(
    { __typename?: 'Deal' }
    & { gcEstimates?: Maybe<Array<(
      { __typename?: 'GcEstimate' }
      & GcEstimateFragment
    )>> }
  )> }
);

export type GetGcEstimateTypesVariables = Exact<{ [key: string]: never; }>;


export type GetGcEstimateTypes = (
  { __typename?: 'RealTraxQuery' }
  & { gcEstimateTypes?: Maybe<Array<(
    { __typename?: 'EstimateType' }
    & { value: EstimateType['id'], label: EstimateType['name'] }
  )>> }
);

export type GetDealGcEstimateVariables = Exact<{
  id: Scalars['Int'];
  fkGcEstimate: Scalars['Int'];
}>;


export type GetDealGcEstimate = (
  { __typename?: 'RealTraxQuery' }
  & { deal?: Maybe<(
    { __typename?: 'Deal' }
    & { gcEstimate?: Maybe<(
      { __typename?: 'GcEstimate' }
      & { comments?: Maybe<Array<(
        { __typename?: 'Comment' }
        & Pick<Comment, 'id' | 'body' | 'createdAt' | 'updatedAt'>
        & { createdBy: (
          { __typename?: 'SystemProcess' }
          & Pick<SystemProcess, 'id' | 'displayName' | 'displayNameAlt'>
        ) | (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'displayName' | 'displayNameAlt'>
        ) }
      )>> }
      & GcEstimateFragment
    )> }
  )> }
);

export type GcEstimateFragment = (
  { __typename?: 'GcEstimate' }
  & Pick<GcEstimate, 'id' | 'description' | 'totalPrice' | 'pricePerUnit' | 'pricePerNrsf' | 'isApproved' | 'comment' | 'createdAt' | 'updatedAt' | 'commentCount'>
  & { type: (
    { __typename?: 'EstimateType' }
    & Pick<EstimateType, 'id' | 'name'>
  ), source: (
    { __typename?: 'Firm' }
    & Pick<Firm, 'id' | 'name'>
  ), createdBy: (
    { __typename?: 'SystemProcess' }
    & Pick<SystemProcess, 'id' | 'displayName'>
  ) | (
    { __typename?: 'User' }
    & Pick<User, 'id' | 'displayName'>
  ), updatedBy: (
    { __typename?: 'SystemProcess' }
    & Pick<SystemProcess, 'id' | 'displayName'>
  ) | (
    { __typename?: 'User' }
    & Pick<User, 'id' | 'displayName'>
  ) }
);

export type GetDealPinnedMetricsVariables = Exact<{
  id: Scalars['Int'];
}>;


export type GetDealPinnedMetrics = (
  { __typename?: 'RealTraxQuery' }
  & { deal?: Maybe<(
    { __typename?: 'Deal' }
    & { pinnedMetrics?: Maybe<Array<(
      { __typename?: 'DealPinnedMetric' }
      & DealPinnedMetricFragment
    )>> }
  )> }
);

export type GetContingencyCategoriesVariables = Exact<{ [key: string]: never; }>;


export type GetContingencyCategories = (
  { __typename?: 'RealTraxQuery' }
  & { contingencyCategories?: Maybe<Array<(
    { __typename?: 'ContingencyCategory' }
    & Pick<ContingencyCategory, 'id' | 'name'>
  )>> }
);

export type GetAccountCategoriesVariables = Exact<{ [key: string]: never; }>;


export type GetAccountCategories = (
  { __typename?: 'RealTraxQuery' }
  & { accountCategories?: Maybe<Array<(
    { __typename?: 'GlAccountCategory' }
    & Pick<GlAccountCategory, 'id' | 'name'>
  )>> }
);

export type GetAccountTypesVariables = Exact<{ [key: string]: never; }>;


export type GetAccountTypes = (
  { __typename?: 'RealTraxQuery' }
  & { accountTypes?: Maybe<Array<(
    { __typename?: 'GlAccountType' }
    & Pick<GlAccountType, 'id' | 'name'>
  )>> }
);

export type GetAccountsVariables = Exact<{ [key: string]: never; }>;


export type GetAccounts = (
  { __typename?: 'RealTraxQuery' }
  & { accounts?: Maybe<Array<(
    { __typename?: 'GlAccount' }
    & Pick<GlAccount, 'id' | 'name' | 'code' | 'displayCode' | 'isDisabled' | 'isAppDefault'>
    & { type?: Maybe<(
      { __typename?: 'GlAccountType' }
      & Pick<GlAccountType, 'id' | 'name'>
    )>, category?: Maybe<(
      { __typename?: 'GlAccountCategory' }
      & Pick<GlAccountCategory, 'id' | 'name'>
    )> }
  )>> }
);

export type GetAccountVariables = Exact<{
  id: Scalars['Int'];
}>;


export type GetAccount = (
  { __typename?: 'RealTraxQuery' }
  & { account?: Maybe<(
    { __typename?: 'GlAccount' }
    & Pick<GlAccount, 'id' | 'name' | 'code' | 'displayCode' | 'isDisabled' | 'isAppDefault'>
    & { type?: Maybe<(
      { __typename?: 'GlAccountType' }
      & Pick<GlAccountType, 'id' | 'name'>
    )>, category?: Maybe<(
      { __typename?: 'GlAccountCategory' }
      & Pick<GlAccountCategory, 'id' | 'name'>
    )> }
  )> }
);

export type GetAccountTreesVariables = Exact<{ [key: string]: never; }>;


export type GetAccountTrees = (
  { __typename?: 'RealTraxQuery' }
  & { accountTrees?: Maybe<Array<(
    { __typename?: 'GlAccountTree' }
    & Pick<GlAccountTree, 'id' | 'name' | 'isDisabled' | 'isAppDefault'>
  )>> }
);

export type GetAccountTreeVariables = Exact<{
  id: Scalars['Int'];
}>;


export type GetAccountTree = (
  { __typename?: 'RealTraxQuery' }
  & { accountTree?: Maybe<(
    { __typename?: 'GlAccountTree' }
    & Pick<GlAccountTree, 'id' | 'name' | 'isDisabled' | 'isAppDefault'>
    & { reportType?: Maybe<(
      { __typename?: 'ReportType' }
      & Pick<ReportType, 'id' | 'name'>
    )>, branches?: Maybe<Array<(
      { __typename?: 'GlAccountTreeBranch' }
      & Pick<GlAccountTreeBranch, 'id' | 'level' | 'reverseFromDrCr' | 'fkParentBranch'>
      & { account: (
        { __typename?: 'GlAccount' }
        & Pick<GlAccount, 'id' | 'name' | 'code' | 'displayCode'>
        & { type?: Maybe<(
          { __typename?: 'GlAccountType' }
          & Pick<GlAccountType, 'id' | 'name'>
        )> }
      ) }
    )>> }
  )> }
);

export type GetDealChangeItemsVariables = Exact<{
  id: Scalars['Int'];
}>;


export type GetDealChangeItems = (
  { __typename?: 'RealTraxQuery' }
  & { deal?: Maybe<(
    { __typename?: 'Deal' }
    & Pick<Deal, 'name' | 'isWorkingDays' | 'originalGcContingencyBudget' | 'currentGcContingencyBudget' | 'forecastedGcContingencyChanges' | 'originalGcContractCommittedAmount' | 'gcContractSavingsPercentage'>
    & { finalResidentialTCO?: Maybe<(
      { __typename?: 'DealEvent' }
      & Pick<DealEvent, 'id' | 'startDate' | 'endDate' | 'type'>
    )>, originalBudgetRevision?: Maybe<(
      { __typename?: 'Revision' }
      & Pick<Revision, 'id' | 'name' | 'approvedDate' | 'total'>
    )>, originalGcContractSchedule?: Maybe<(
      { __typename?: 'PublishedScheduleInstance' }
      & Pick<PublishedScheduleInstance, 'id' | 'description' | 'createdAt' | 'updatedAt'>
      & { finalResidentialTCO?: Maybe<(
        { __typename?: 'PublishedDealEvent' }
        & Pick<PublishedDealEvent, 'id' | 'startDate' | 'endDate' | 'type'>
      )> }
    )>, generalContractor?: Maybe<(
      { __typename?: 'Firm' }
      & Pick<Firm, 'id' | 'name'>
    )>, contingencyReconciliationSummary?: Maybe<(
      { __typename?: 'ContingencyReconciliationSummary' }
      & Pick<ContingencyReconciliationSummary, 'approvedOwnerContingencies' | 'forecastedUses' | 'balanceAfterForecast' | 'ownerShareOfGcContingencySavings' | 'totalForeacstedBalanceIncludingSavings'>
    )>, generalContractorSummary?: Maybe<(
      { __typename?: 'GeneralContractorSummary' }
      & Pick<GeneralContractorSummary, 'originalContractAmount' | 'issuedChangeOrdersAmount' | 'gcPercentComplete'>
    )>, changeItemReconciliation?: Maybe<Array<(
      { __typename?: 'ChangeItemReconciliationDetails' }
      & Pick<ChangeItemReconciliationDetails, 'title' | 'forecastedChanges' | 'adjustmentsToDate' | 'balanceAfterForecast' | 'comparisonBudgetAmount' | 'currentBudgetAmount' | 'percentUsedMinusGcPercentComplete'>
      & { costSource?: Maybe<(
        { __typename?: 'GlAccount' }
        & Pick<GlAccount, 'id' | 'name' | 'code' | 'displayCode'>
      )> }
    )>>, pipeline: (
      { __typename?: 'Pipeline' }
      & { generalContractorAccount?: Maybe<(
        { __typename?: 'GlAccount' }
        & Pick<GlAccount, 'id' | 'name' | 'displayCode' | 'code'>
      )>, contingencyCategorizations?: Maybe<Array<(
        { __typename?: 'PipelineContingencyCategory' }
        & Pick<PipelineContingencyCategory, 'id'>
        & { account: (
          { __typename?: 'GlAccount' }
          & Pick<GlAccount, 'id' | 'name' | 'code' | 'displayCode'>
        ), category: (
          { __typename?: 'ContingencyCategory' }
          & Pick<ContingencyCategory, 'id' | 'name'>
        ) }
      )>> }
    ), changeEvents?: Maybe<Array<(
      { __typename?: 'ChangeEvent' }
      & ChangeEventFragment
    )>>, potentialChangeOrders?: Maybe<Array<(
      { __typename?: 'PotentialChangeOrder' }
      & PotentialChangeOrderFragment
    )>>, changeOrderPackages?: Maybe<Array<(
      { __typename?: 'ChangeOrderPackage' }
      & ChangeOrderPackageFragment
    )>> }
  )> }
);

export type GetDealChangeEventVariables = Exact<{
  id: Scalars['Int'];
  fkChangeEvent: Scalars['Int'];
}>;


export type GetDealChangeEvent = (
  { __typename?: 'RealTraxQuery' }
  & { deal?: Maybe<(
    { __typename?: 'Deal' }
    & { changeEvent?: Maybe<(
      { __typename?: 'ChangeEvent' }
      & ChangeEventFragment
    )> }
  )> }
);

export type ChangeEventFragment = (
  { __typename?: 'ChangeEvent' }
  & Pick<ChangeEvent, 'id' | 'title' | 'number' | 'total' | 'scheduleImpact' | 'ownerTotal' | 'ownerScheduleImpact' | 'isScheduleImpactExcusable' | 'fkPotentialChangeOrder' | 'createdAt' | 'updatedAt'>
  & { createdBy: (
    { __typename?: 'SystemProcess' }
    & Pick<SystemProcess, 'id' | 'displayName'>
  ) | (
    { __typename?: 'User' }
    & Pick<User, 'id' | 'displayName'>
  ), updatedBy: (
    { __typename?: 'SystemProcess' }
    & Pick<SystemProcess, 'id' | 'displayName'>
  ) | (
    { __typename?: 'User' }
    & Pick<User, 'id' | 'displayName'>
  ), type: (
    { __typename?: 'ChangeEventType' }
    & Pick<ChangeEventType, 'id' | 'name'>
  ), status: (
    { __typename?: 'ChangeOrderStatus' }
    & Pick<ChangeOrderStatus, 'id' | 'name'>
  ), reason?: Maybe<(
    { __typename?: 'ChangeEventReason' }
    & Pick<ChangeEventReason, 'id' | 'name'>
  )>, costSource?: Maybe<(
    { __typename?: 'GlAccount' }
    & Pick<GlAccount, 'id' | 'name'>
  )> }
);

export type GetDealPotentialChangeOrdersVariables = Exact<{
  id: Scalars['Int'];
}>;


export type GetDealPotentialChangeOrders = (
  { __typename?: 'RealTraxQuery' }
  & { deal?: Maybe<(
    { __typename?: 'Deal' }
    & { potentialChangeOrders?: Maybe<Array<(
      { __typename?: 'PotentialChangeOrder' }
      & PotentialChangeOrderFragment
    )>> }
  )> }
);

export type GetDealPotentialChangeOrderVariables = Exact<{
  id: Scalars['Int'];
  fkPotentialChangeOrder: Scalars['Int'];
}>;


export type GetDealPotentialChangeOrder = (
  { __typename?: 'RealTraxQuery' }
  & { deal?: Maybe<(
    { __typename?: 'Deal' }
    & { potentialChangeOrder?: Maybe<(
      { __typename?: 'PotentialChangeOrder' }
      & PotentialChangeOrderFragment
    )> }
  )> }
);

export type PotentialChangeOrderFragment = (
  { __typename?: 'PotentialChangeOrder' }
  & Pick<PotentialChangeOrder, 'id' | 'title' | 'number' | 'total' | 'scheduleImpact' | 'ownerTotal' | 'ownerScheduleImpact' | 'isScheduleImpactExcusable' | 'fkChangeOrderPackage' | 'createdAt' | 'updatedAt'>
  & { createdBy: (
    { __typename?: 'SystemProcess' }
    & Pick<SystemProcess, 'id' | 'displayName'>
  ) | (
    { __typename?: 'User' }
    & Pick<User, 'id' | 'displayName'>
  ), updatedBy: (
    { __typename?: 'SystemProcess' }
    & Pick<SystemProcess, 'id' | 'displayName'>
  ) | (
    { __typename?: 'User' }
    & Pick<User, 'id' | 'displayName'>
  ), status: (
    { __typename?: 'ChangeOrderStatus' }
    & Pick<ChangeOrderStatus, 'id' | 'name'>
  ), type?: Maybe<(
    { __typename?: 'PotentialChangeOrderType' }
    & Pick<PotentialChangeOrderType, 'id' | 'name'>
  )>, costSource?: Maybe<(
    { __typename?: 'GlAccount' }
    & Pick<GlAccount, 'id' | 'name'>
  )> }
);

export type GetDealChangeOrderPackagesVariables = Exact<{
  id: Scalars['Int'];
}>;


export type GetDealChangeOrderPackages = (
  { __typename?: 'RealTraxQuery' }
  & { deal?: Maybe<(
    { __typename?: 'Deal' }
    & { changeOrderPackages?: Maybe<Array<(
      { __typename?: 'ChangeOrderPackage' }
      & ChangeOrderPackageFragment
    )>> }
  )> }
);

export type GetDealChangeOrderPackageVariables = Exact<{
  id: Scalars['Int'];
  fkChangeOrderPackage: Scalars['Int'];
}>;


export type GetDealChangeOrderPackage = (
  { __typename?: 'RealTraxQuery' }
  & { deal?: Maybe<(
    { __typename?: 'Deal' }
    & { changeOrderPackage?: Maybe<(
      { __typename?: 'ChangeOrderPackage' }
      & ChangeOrderPackageFragment
    )> }
  )> }
);

export type ChangeOrderPackageFragment = (
  { __typename?: 'ChangeOrderPackage' }
  & Pick<ChangeOrderPackage, 'id' | 'title' | 'number' | 'total' | 'scheduleImpact' | 'isScheduleImpactExcusable' | 'fkChangeOrderPackageParent' | 'createdAt' | 'updatedAt'>
  & { createdBy: (
    { __typename?: 'SystemProcess' }
    & Pick<SystemProcess, 'id' | 'displayName'>
  ) | (
    { __typename?: 'User' }
    & Pick<User, 'id' | 'displayName'>
  ), updatedBy: (
    { __typename?: 'SystemProcess' }
    & Pick<SystemProcess, 'id' | 'displayName'>
  ) | (
    { __typename?: 'User' }
    & Pick<User, 'id' | 'displayName'>
  ), status: (
    { __typename?: 'ChangeOrderStatus' }
    & Pick<ChangeOrderStatus, 'id' | 'name'>
  ), costSource?: Maybe<(
    { __typename?: 'GlAccount' }
    & Pick<GlAccount, 'id' | 'name'>
  )> }
);

export type GetChangeOrderStatusesVariables = Exact<{ [key: string]: never; }>;


export type GetChangeOrderStatuses = (
  { __typename?: 'RealTraxQuery' }
  & { changeOrderStatuses?: Maybe<Array<(
    { __typename?: 'ChangeOrderStatus' }
    & Pick<ChangeOrderStatus, 'id' | 'name'>
  )>> }
);

export type GetPotentialChangeOrderTypesVariables = Exact<{ [key: string]: never; }>;


export type GetPotentialChangeOrderTypes = (
  { __typename?: 'RealTraxQuery' }
  & { potentialChangeOrderTypes?: Maybe<Array<(
    { __typename?: 'PotentialChangeOrderType' }
    & Pick<PotentialChangeOrderType, 'id' | 'name'>
  )>> }
);

export type GetChangeEventTypesVariables = Exact<{ [key: string]: never; }>;


export type GetChangeEventTypes = (
  { __typename?: 'RealTraxQuery' }
  & { changeEventTypes?: Maybe<Array<(
    { __typename?: 'ChangeEventType' }
    & Pick<ChangeEventType, 'id' | 'name'>
  )>> }
);

export type GetChangeEventReasonsVariables = Exact<{ [key: string]: never; }>;


export type GetChangeEventReasons = (
  { __typename?: 'RealTraxQuery' }
  & { changeEventReasons?: Maybe<Array<(
    { __typename?: 'ChangeEventReason' }
    & Pick<ChangeEventReason, 'id' | 'name'>
  )>> }
);

export type GetApprovalPackagesVariables = Exact<{ [key: string]: never; }>;


export type GetApprovalPackages = (
  { __typename?: 'RealTraxQuery' }
  & { approvalsDashboard: (
    { __typename?: 'ApprovalsDashboard' }
    & Pick<ApprovalsDashboard, 'needsApproval' | 'waitingForOthers' | 'completed' | 'all' | 'pending'>
  ), approvalPackages?: Maybe<Array<(
    { __typename?: 'ApprovalPackage' }
    & Pick<ApprovalPackage, 'id' | 'reviewDate' | 'isPublished' | 'status' | 'amendmentNumber' | 'needsCurrentUserReview' | 'waitingForOthers' | 'archiveDate'>
    & { type: (
      { __typename?: 'ApprovalPackageType' }
      & Pick<ApprovalPackageType, 'id' | 'name'>
    ), deal: (
      { __typename?: 'Deal' }
      & Pick<Deal, 'id' | 'name'>
    ), dealType?: Maybe<(
      { __typename?: 'DealType' }
      & Pick<DealType, 'name'>
    )>, reviewers?: Maybe<Array<(
      { __typename?: 'ApprovalPackageReviewer' }
      & ApprovalPackageReviewerFragment
    )>> }
  )>> }
);

export type GetDealApprovalPackagesVariables = Exact<{
  id: Scalars['Int'];
}>;


export type GetDealApprovalPackages = (
  { __typename?: 'RealTraxQuery' }
  & { deal?: Maybe<(
    { __typename?: 'Deal' }
    & Pick<Deal, 'id' | 'name'>
    & { approvalPackages?: Maybe<Array<(
      { __typename?: 'ApprovalPackage' }
      & Pick<ApprovalPackage, 'id' | 'reviewDate' | 'isPublished' | 'status' | 'amendmentNumber' | 'needsCurrentUserReview' | 'waitingForOthers' | 'archiveDate'>
      & { type: (
        { __typename?: 'ApprovalPackageType' }
        & Pick<ApprovalPackageType, 'id' | 'name'>
      ), deal: (
        { __typename?: 'Deal' }
        & Pick<Deal, 'id' | 'name'>
      ) }
    )>> }
  )> }
);

export type GetApprovalPackageVariables = Exact<{
  id: Scalars['Int'];
}>;


export type GetApprovalPackage = (
  { __typename?: 'RealTraxQuery' }
  & { approvalPackage?: Maybe<(
    { __typename?: 'ApprovalPackage' }
    & Pick<ApprovalPackage, 'id' | 'updatedAt' | 'createdAt' | 'reviewDate' | 'isPublished' | 'archiveDate' | 'currentUserIsContributor' | 'currentUserIsReviewer' | 'status' | 'amendmentNumber'>
    & { type: (
      { __typename?: 'ApprovalPackageType' }
      & Pick<ApprovalPackageType, 'id' | 'name'>
    ), deal: (
      { __typename?: 'Deal' }
      & Pick<Deal, 'id' | 'name'>
      & { pipeline: (
        { __typename?: 'Pipeline' }
        & Pick<Pipeline, 'id' | 'name'>
      ), fund?: Maybe<(
        { __typename?: 'Fund' }
        & Pick<Fund, 'id' | 'name'>
      )> }
    ), analysis?: Maybe<(
      { __typename?: 'DealAnalysis' }
      & Pick<DealAnalysis, 'id' | 'description' | 'createdAt'>
      & { scenario: (
        { __typename?: 'ScenarioType' }
        & Pick<ScenarioType, 'id' | 'name'>
      ) }
    )>, schedule?: Maybe<(
      { __typename?: 'PublishedScheduleInstance' }
      & Pick<PublishedScheduleInstance, 'id' | 'description' | 'createdAt'>
    )>, sections?: Maybe<Array<(
      { __typename?: 'ApprovalPackageSection' }
      & Pick<ApprovalPackageSection, 'id' | 'title' | 'orderId' | 'gridColumnWidth'>
      & { content?: Maybe<Array<(
        { __typename?: 'ApprovalPackageSubSectionText' }
        & ApprovalPackageSubSectionFragment_ApprovalPackageSubSectionText_
      ) | (
        { __typename?: 'ApprovalPackageSubSectionDealBackground' }
        & ApprovalPackageSubSectionFragment_ApprovalPackageSubSectionDealBackground_
      ) | (
        { __typename?: 'ApprovalPackageSubSectionEarnestMoneyDeposit' }
        & ApprovalPackageSubSectionFragment_ApprovalPackageSubSectionEarnestMoneyDeposit_
      ) | (
        { __typename?: 'ApprovalPackageSubSectionChecklist' }
        & ApprovalPackageSubSectionFragment_ApprovalPackageSubSectionChecklist_
      ) | (
        { __typename?: 'ApprovalPackageSubSectionGcEstimate' }
        & ApprovalPackageSubSectionFragment_ApprovalPackageSubSectionGcEstimate_
      ) | (
        { __typename?: 'ApprovalPackageSubSectionKeyRisks' }
        & ApprovalPackageSubSectionFragment_ApprovalPackageSubSectionKeyRisks_
      ) | (
        { __typename?: 'ApprovalPackageSubSectionMerits' }
        & ApprovalPackageSubSectionFragment_ApprovalPackageSubSectionMerits_
      ) | (
        { __typename?: 'ApprovalPackageSubSectionTable' }
        & ApprovalPackageSubSectionFragment_ApprovalPackageSubSectionTable_
      ) | (
        { __typename?: 'ApprovalPackageSubSectionImages' }
        & ApprovalPackageSubSectionFragment_ApprovalPackageSubSectionImages_
      ) | (
        { __typename?: 'ApprovalPackageSubSectionPageBreak' }
        & ApprovalPackageSubSectionFragment_ApprovalPackageSubSectionPageBreak_
      )>> }
    )>>, reviewers?: Maybe<Array<(
      { __typename?: 'ApprovalPackageReviewer' }
      & ApprovalPackageReviewerFragment
    )>>, contributors?: Maybe<Array<(
      { __typename?: 'ApprovalPackageContributor' }
      & ApprovalPackageContributorFragment
    )>>, exhibits?: Maybe<Array<(
      { __typename?: 'DocumentTreeItem' }
      & Pick<DocumentTreeItem, 'id' | 'type' | 'name' | 'displayName' | 'fileExtension' | 'createdAt' | 'updatedAt'>
      & { createdBy: (
        { __typename?: 'SystemProcess' }
        & Pick<SystemProcess, 'id' | 'displayName' | 'displayNameAlt'>
      ) | (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'displayName' | 'displayNameAlt'>
      ), updatedBy: (
        { __typename?: 'SystemProcess' }
        & Pick<SystemProcess, 'id' | 'displayName' | 'displayNameAlt'>
      ) | (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'displayName' | 'displayNameAlt'>
      ) }
    )>> }
  )> }
);

export type ApprovalPackageReviewerFragment = (
  { __typename?: 'ApprovalPackageReviewer' }
  & Pick<ApprovalPackageReviewer, 'id' | 'createdAt' | 'updatedAt' | 'reviewedDate' | 'status'>
  & { user: (
    { __typename?: 'User' }
    & Pick<User, 'id' | 'firstName' | 'lastName' | 'email'>
  ), firm?: Maybe<(
    { __typename?: 'Firm' }
    & Pick<Firm, 'id' | 'name'>
  )> }
);

export type ApprovalPackageContributorFragment = (
  { __typename?: 'ApprovalPackageContributor' }
  & Pick<ApprovalPackageContributor, 'id' | 'createdAt' | 'updatedAt'>
  & { user: (
    { __typename?: 'User' }
    & Pick<User, 'id' | 'firstName' | 'lastName'>
  ) }
);

export type ApprovalPackageSubSectionFragment_ApprovalPackageSubSectionText_ = (
  { __typename: 'ApprovalPackageSubSectionText' }
  & Pick<ApprovalPackageSubSectionText, 'id' | 'title' | 'orderId' | 'gridColumnWidth' | 'text'>
);

export type ApprovalPackageSubSectionFragment_ApprovalPackageSubSectionDealBackground_ = (
  { __typename: 'ApprovalPackageSubSectionDealBackground' }
  & Pick<ApprovalPackageSubSectionDealBackground, 'id' | 'title' | 'orderId' | 'gridColumnWidth' | 'dealBackgroundText'>
);

export type ApprovalPackageSubSectionFragment_ApprovalPackageSubSectionEarnestMoneyDeposit_ = (
  { __typename: 'ApprovalPackageSubSectionEarnestMoneyDeposit' }
  & Pick<ApprovalPackageSubSectionEarnestMoneyDeposit, 'id' | 'title' | 'orderId' | 'gridColumnWidth' | 'earnestMoneyDepositText'>
);

export type ApprovalPackageSubSectionFragment_ApprovalPackageSubSectionChecklist_ = (
  { __typename: 'ApprovalPackageSubSectionChecklist' }
  & Pick<ApprovalPackageSubSectionChecklist, 'id' | 'title' | 'orderId' | 'gridColumnWidth'>
  & { items?: Maybe<Array<(
    { __typename?: 'ApprovalPackageChecklistItem' }
    & Pick<ApprovalPackageChecklistItem, 'name' | 'date'>
    & { category?: Maybe<(
      { __typename?: 'ChecklistCategory' }
      & Pick<ChecklistCategory, 'id' | 'name'>
    )>, assignee?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'firstName' | 'lastName'>
    )>, status: (
      { __typename?: 'ChecklistItemStatus' }
      & Pick<ChecklistItemStatus, 'id' | 'name'>
    ) }
  )>> }
);

export type ApprovalPackageSubSectionFragment_ApprovalPackageSubSectionGcEstimate_ = (
  { __typename: 'ApprovalPackageSubSectionGcEstimate' }
  & Pick<ApprovalPackageSubSectionGcEstimate, 'id' | 'title' | 'orderId' | 'gridColumnWidth'>
  & { gcEstimates?: Maybe<Array<(
    { __typename?: 'ApprovalPackageGcEstimate' }
    & Pick<ApprovalPackageGcEstimate, 'id' | 'updatedAt' | 'description' | 'totalPrice' | 'pricePerUnit' | 'pricePerNrsf' | 'comment' | 'isApproved' | 'commentCount'>
    & { type: (
      { __typename?: 'EstimateType' }
      & Pick<EstimateType, 'id' | 'name'>
    ), source: (
      { __typename?: 'Firm' }
      & Pick<Firm, 'id' | 'name'>
    ), comments?: Maybe<Array<(
      { __typename?: 'Comment' }
      & Pick<Comment, 'id' | 'body'>
    )>> }
  )>> }
);

export type ApprovalPackageSubSectionFragment_ApprovalPackageSubSectionKeyRisks_ = (
  { __typename: 'ApprovalPackageSubSectionKeyRisks' }
  & Pick<ApprovalPackageSubSectionKeyRisks, 'id' | 'title' | 'orderId' | 'gridColumnWidth'>
  & { keyRisks?: Maybe<Array<(
    { __typename?: 'ApprovalPackageKeyRisk' }
    & Pick<ApprovalPackageKeyRisk, 'id' | 'title' | 'riskDescription' | 'mitigantDescription' | 'orderId'>
  )>> }
);

export type ApprovalPackageSubSectionFragment_ApprovalPackageSubSectionMerits_ = (
  { __typename: 'ApprovalPackageSubSectionMerits' }
  & Pick<ApprovalPackageSubSectionMerits, 'id' | 'title' | 'orderId' | 'gridColumnWidth'>
  & { merits?: Maybe<Array<(
    { __typename?: 'ApprovalPackageMerit' }
    & Pick<ApprovalPackageMerit, 'id' | 'title' | 'meritDescription' | 'orderId'>
  )>> }
);

export type ApprovalPackageSubSectionFragment_ApprovalPackageSubSectionTable_ = (
  { __typename: 'ApprovalPackageSubSectionTable' }
  & Pick<ApprovalPackageSubSectionTable, 'id' | 'title' | 'orderId' | 'gridColumnWidth' | 'columns'>
  & { cells?: Maybe<Array<(
    { __typename?: 'IcSubSectionTableCell' }
    & Pick<IcSubSectionTableCell, 'id' | 'orderId'>
    & { entries?: Maybe<Array<(
      { __typename?: 'IcSubSectionTableCellEntry' }
      & Pick<IcSubSectionTableCellEntry, 'id' | 'name' | 'orderId' | 'type'>
    )>>, attributes?: Maybe<Array<(
      { __typename?: 'ApprovalPackageSubSectionDealAttribute' }
      & Pick<ApprovalPackageSubSectionDealAttribute, 'value'>
      & { attribute: (
        { __typename?: 'DealAttribute' }
        & Pick<DealAttribute, 'id' | 'displayName' | 'columnName' | 'dataType'>
      ) }
    )>>, teamMembers?: Maybe<Array<(
      { __typename?: 'ApprovalPackageSubSectionTeamMemberRole' }
      & Pick<ApprovalPackageSubSectionTeamMemberRole, 'names'>
      & { role: (
        { __typename?: 'TeamMemberRole' }
        & Pick<TeamMemberRole, 'id' | 'name'>
      ) }
    )>>, partners?: Maybe<Array<(
      { __typename?: 'ApprovalPackageSubSectionPartnerRole' }
      & Pick<ApprovalPackageSubSectionPartnerRole, 'names'>
      & { role: (
        { __typename?: 'PartnerRole' }
        & Pick<PartnerRole, 'id' | 'name'>
      ) }
    )>>, events?: Maybe<Array<(
      { __typename?: 'ApprovalPackageSubSectionEvent' }
      & Pick<ApprovalPackageSubSectionEvent, 'startDate' | 'endDate'>
      & { event: (
        { __typename?: 'Event' }
        & Pick<Event, 'id' | 'name' | 'type'>
      ) }
    )>>, metrics?: Maybe<Array<(
      { __typename?: 'ApprovalPackageSubSectionMetric' }
      & Pick<ApprovalPackageSubSectionMetric, 'value'>
      & { metric: (
        { __typename?: 'Metric' }
        & Pick<Metric, 'scale'>
        & MetricFragment
      ) }
    )>> }
  )>> }
);

export type ApprovalPackageSubSectionFragment_ApprovalPackageSubSectionImages_ = (
  { __typename: 'ApprovalPackageSubSectionImages' }
  & Pick<ApprovalPackageSubSectionImages, 'id' | 'title' | 'orderId' | 'gridColumnWidth'>
  & { images?: Maybe<Array<(
    { __typename?: 'IcSubSectionImageContent' }
    & Pick<IcSubSectionImageContent, 'id' | 'title' | 'fkIcPackageExhibit'>
  )>> }
);

export type ApprovalPackageSubSectionFragment_ApprovalPackageSubSectionPageBreak_ = (
  { __typename: 'ApprovalPackageSubSectionPageBreak' }
  & Pick<ApprovalPackageSubSectionPageBreak, 'id' | 'orderId'>
);

export type ApprovalPackageSubSectionFragment = ApprovalPackageSubSectionFragment_ApprovalPackageSubSectionText_ | ApprovalPackageSubSectionFragment_ApprovalPackageSubSectionDealBackground_ | ApprovalPackageSubSectionFragment_ApprovalPackageSubSectionEarnestMoneyDeposit_ | ApprovalPackageSubSectionFragment_ApprovalPackageSubSectionChecklist_ | ApprovalPackageSubSectionFragment_ApprovalPackageSubSectionGcEstimate_ | ApprovalPackageSubSectionFragment_ApprovalPackageSubSectionKeyRisks_ | ApprovalPackageSubSectionFragment_ApprovalPackageSubSectionMerits_ | ApprovalPackageSubSectionFragment_ApprovalPackageSubSectionTable_ | ApprovalPackageSubSectionFragment_ApprovalPackageSubSectionImages_ | ApprovalPackageSubSectionFragment_ApprovalPackageSubSectionPageBreak_;

export type GetApprovalPackageStatusesVariables = Exact<{ [key: string]: never; }>;


export type GetApprovalPackageStatuses = (
  { __typename?: 'RealTraxQuery' }
  & { approvalPackageStatuses?: Maybe<Array<(
    { __typename?: 'PackageStatus' }
    & Pick<PackageStatus, 'id' | 'name'>
  )>> }
);

export type GetApprovalPackageReviewerStatusesVariables = Exact<{ [key: string]: never; }>;


export type GetApprovalPackageReviewerStatuses = (
  { __typename?: 'RealTraxQuery' }
  & { approvalPackageReviewerStatuses?: Maybe<Array<(
    { __typename?: 'PackageReviewerStatus' }
    & Pick<PackageReviewerStatus, 'id' | 'name'>
  )>> }
);

export type GetApprovalPackageTypesVariables = Exact<{ [key: string]: never; }>;


export type GetApprovalPackageTypes = (
  { __typename?: 'RealTraxQuery' }
  & { approvalPackageTypes?: Maybe<Array<(
    { __typename?: 'ApprovalPackageType' }
    & ApprovalPackageTypeFragment
  )>> }
);

export type GetApprovalPackageTypeVariables = Exact<{
  id: Scalars['Int'];
}>;


export type GetApprovalPackageType = (
  { __typename?: 'RealTraxQuery' }
  & { approvalPackageType?: Maybe<(
    { __typename?: 'ApprovalPackageType' }
    & Pick<ApprovalPackageType, 'id' | 'name'>
    & { defaultTemplate?: Maybe<(
      { __typename?: 'TemplateApprovalPackage' }
      & Pick<TemplateApprovalPackage, 'id' | 'name'>
    )>, event?: Maybe<(
      { __typename?: 'Event' }
      & Pick<Event, 'id' | 'name' | 'type'>
    )> }
  )> }
);

export type GetPublishedApprovalPackageVariables = Exact<{
  id: Scalars['Int'];
}>;


export type GetPublishedApprovalPackage = (
  { __typename?: 'RealTraxQuery' }
  & { publishedApprovalPackage?: Maybe<(
    { __typename?: 'PublishedApprovalPackage' }
    & Pick<PublishedApprovalPackage, 'id' | 'fkPackage' | 'updatedAt' | 'createdAt' | 'reviewDate' | 'isPublished' | 'currentUserIsContributor' | 'currentUserIsReviewer' | 'archiveDate' | 'status' | 'amendmentNumber'>
    & { type: (
      { __typename?: 'ApprovalPackageType' }
      & Pick<ApprovalPackageType, 'id' | 'name'>
    ), deal: (
      { __typename?: 'Deal' }
      & Pick<Deal, 'id' | 'name'>
      & { pipeline: (
        { __typename?: 'Pipeline' }
        & Pick<Pipeline, 'id' | 'name'>
      ), fund?: Maybe<(
        { __typename?: 'Fund' }
        & Pick<Fund, 'id' | 'name'>
      )> }
    ), analysis?: Maybe<(
      { __typename?: 'DealAnalysis' }
      & Pick<DealAnalysis, 'id' | 'description' | 'createdAt'>
      & { scenario: (
        { __typename?: 'ScenarioType' }
        & Pick<ScenarioType, 'id' | 'name'>
      ) }
    )>, schedule?: Maybe<(
      { __typename?: 'PublishedScheduleInstance' }
      & Pick<PublishedScheduleInstance, 'id' | 'description' | 'createdAt'>
    )>, sections?: Maybe<Array<(
      { __typename?: 'PublishedApprovalPackageSection' }
      & Pick<PublishedApprovalPackageSection, 'id' | 'title' | 'orderId' | 'gridColumnWidth'>
      & { content?: Maybe<Array<(
        { __typename?: 'PublishedApprovalPackageSubSectionText' }
        & PublishedApprovalPackageSubSectionFragment_PublishedApprovalPackageSubSectionText_
      ) | (
        { __typename?: 'PublishedApprovalPackageSubSectionChecklist' }
        & PublishedApprovalPackageSubSectionFragment_PublishedApprovalPackageSubSectionChecklist_
      ) | (
        { __typename?: 'PublishedApprovalPackageSubSectionGcEstimate' }
        & PublishedApprovalPackageSubSectionFragment_PublishedApprovalPackageSubSectionGcEstimate_
      ) | (
        { __typename?: 'PublishedApprovalPackageSubSectionKeyRisks' }
        & PublishedApprovalPackageSubSectionFragment_PublishedApprovalPackageSubSectionKeyRisks_
      ) | (
        { __typename?: 'PublishedApprovalPackageSubSectionMerits' }
        & PublishedApprovalPackageSubSectionFragment_PublishedApprovalPackageSubSectionMerits_
      ) | (
        { __typename?: 'PublishedApprovalPackageSubSectionEarnestMoneyDeposit' }
        & PublishedApprovalPackageSubSectionFragment_PublishedApprovalPackageSubSectionEarnestMoneyDeposit_
      ) | (
        { __typename?: 'PublishedApprovalPackageSubSectionDealBackground' }
        & PublishedApprovalPackageSubSectionFragment_PublishedApprovalPackageSubSectionDealBackground_
      ) | (
        { __typename?: 'PublishedApprovalPackageSubSectionTable' }
        & PublishedApprovalPackageSubSectionFragment_PublishedApprovalPackageSubSectionTable_
      ) | (
        { __typename?: 'PublishedApprovalPackageSubSectionImages' }
        & PublishedApprovalPackageSubSectionFragment_PublishedApprovalPackageSubSectionImages_
      ) | (
        { __typename?: 'PublishedApprovalPackageSubSectionPageBreak' }
        & PublishedApprovalPackageSubSectionFragment_PublishedApprovalPackageSubSectionPageBreak_
      )>> }
    )>>, reviewers?: Maybe<Array<(
      { __typename?: 'ApprovalPackageReviewer' }
      & ApprovalPackageReviewerFragment
    )>>, contributors?: Maybe<Array<(
      { __typename?: 'ApprovalPackageContributor' }
      & ApprovalPackageContributorFragment
    )>>, exhibits?: Maybe<Array<(
      { __typename?: 'DocumentTreeItem' }
      & Pick<DocumentTreeItem, 'id' | 'type' | 'name' | 'displayName' | 'fileExtension' | 'createdAt' | 'updatedAt'>
      & { createdBy: (
        { __typename?: 'SystemProcess' }
        & Pick<SystemProcess, 'id' | 'displayName' | 'displayNameAlt'>
      ) | (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'displayName' | 'displayNameAlt'>
      ), updatedBy: (
        { __typename?: 'SystemProcess' }
        & Pick<SystemProcess, 'id' | 'displayName' | 'displayNameAlt'>
      ) | (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'displayName' | 'displayNameAlt'>
      ) }
    )>> }
  )> }
);

export type PublishedApprovalPackageSubSectionFragment_PublishedApprovalPackageSubSectionText_ = (
  { __typename: 'PublishedApprovalPackageSubSectionText' }
  & Pick<PublishedApprovalPackageSubSectionText, 'id' | 'title' | 'orderId' | 'gridColumnWidth' | 'text'>
);

export type PublishedApprovalPackageSubSectionFragment_PublishedApprovalPackageSubSectionChecklist_ = (
  { __typename: 'PublishedApprovalPackageSubSectionChecklist' }
  & Pick<PublishedApprovalPackageSubSectionChecklist, 'id' | 'title' | 'orderId' | 'gridColumnWidth'>
  & { items?: Maybe<Array<(
    { __typename?: 'ApprovalPackageChecklistItem' }
    & Pick<ApprovalPackageChecklistItem, 'name' | 'date'>
    & { category?: Maybe<(
      { __typename?: 'ChecklistCategory' }
      & Pick<ChecklistCategory, 'id' | 'name'>
    )>, assignee?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'firstName' | 'lastName'>
    )>, status: (
      { __typename?: 'ChecklistItemStatus' }
      & Pick<ChecklistItemStatus, 'id' | 'name'>
    ) }
  )>> }
);

export type PublishedApprovalPackageSubSectionFragment_PublishedApprovalPackageSubSectionGcEstimate_ = (
  { __typename: 'PublishedApprovalPackageSubSectionGcEstimate' }
  & Pick<PublishedApprovalPackageSubSectionGcEstimate, 'id' | 'title' | 'orderId' | 'gridColumnWidth'>
  & { gcEstimates?: Maybe<Array<(
    { __typename?: 'ApprovalPackageGcEstimate' }
    & Pick<ApprovalPackageGcEstimate, 'id' | 'updatedAt' | 'description' | 'totalPrice' | 'pricePerUnit' | 'pricePerNrsf' | 'comment' | 'isApproved' | 'commentCount'>
    & { type: (
      { __typename?: 'EstimateType' }
      & Pick<EstimateType, 'id' | 'name'>
    ), source: (
      { __typename?: 'Firm' }
      & Pick<Firm, 'id' | 'name'>
    ), comments?: Maybe<Array<(
      { __typename?: 'Comment' }
      & Pick<Comment, 'id' | 'body'>
    )>> }
  )>> }
);

export type PublishedApprovalPackageSubSectionFragment_PublishedApprovalPackageSubSectionKeyRisks_ = (
  { __typename: 'PublishedApprovalPackageSubSectionKeyRisks' }
  & Pick<PublishedApprovalPackageSubSectionKeyRisks, 'id' | 'title' | 'orderId' | 'gridColumnWidth'>
  & { keyRisks?: Maybe<Array<(
    { __typename?: 'ApprovalPackageKeyRisk' }
    & Pick<ApprovalPackageKeyRisk, 'id' | 'orderId' | 'title' | 'riskDescription' | 'mitigantDescription'>
  )>> }
);

export type PublishedApprovalPackageSubSectionFragment_PublishedApprovalPackageSubSectionMerits_ = (
  { __typename: 'PublishedApprovalPackageSubSectionMerits' }
  & Pick<PublishedApprovalPackageSubSectionMerits, 'id' | 'title' | 'orderId' | 'gridColumnWidth'>
  & { merits?: Maybe<Array<(
    { __typename?: 'ApprovalPackageMerit' }
    & Pick<ApprovalPackageMerit, 'id' | 'orderId' | 'title' | 'meritDescription'>
  )>> }
);

export type PublishedApprovalPackageSubSectionFragment_PublishedApprovalPackageSubSectionEarnestMoneyDeposit_ = (
  { __typename: 'PublishedApprovalPackageSubSectionEarnestMoneyDeposit' }
  & Pick<PublishedApprovalPackageSubSectionEarnestMoneyDeposit, 'id' | 'title' | 'orderId' | 'gridColumnWidth' | 'earnestMoneyDepositText'>
);

export type PublishedApprovalPackageSubSectionFragment_PublishedApprovalPackageSubSectionDealBackground_ = (
  { __typename: 'PublishedApprovalPackageSubSectionDealBackground' }
  & Pick<PublishedApprovalPackageSubSectionDealBackground, 'id' | 'title' | 'orderId' | 'gridColumnWidth' | 'dealBackgroundText'>
);

export type PublishedApprovalPackageSubSectionFragment_PublishedApprovalPackageSubSectionTable_ = (
  { __typename: 'PublishedApprovalPackageSubSectionTable' }
  & Pick<PublishedApprovalPackageSubSectionTable, 'id' | 'title' | 'orderId' | 'gridColumnWidth' | 'columns'>
  & { cells?: Maybe<Array<(
    { __typename?: 'PublishedIcSubSectionTableCell' }
    & Pick<PublishedIcSubSectionTableCell, 'id' | 'orderId'>
    & { entries?: Maybe<Array<(
      { __typename?: 'IcSubSectionTableCellEntry' }
      & Pick<IcSubSectionTableCellEntry, 'id' | 'name' | 'orderId' | 'type'>
    )>>, attributes?: Maybe<Array<(
      { __typename?: 'ApprovalPackageSubSectionDealAttribute' }
      & Pick<ApprovalPackageSubSectionDealAttribute, 'value'>
      & { attribute: (
        { __typename?: 'DealAttribute' }
        & Pick<DealAttribute, 'id' | 'displayName' | 'columnName' | 'dataType'>
      ) }
    )>>, teamMembers?: Maybe<Array<(
      { __typename?: 'ApprovalPackageSubSectionTeamMemberRole' }
      & Pick<ApprovalPackageSubSectionTeamMemberRole, 'names'>
      & { role: (
        { __typename?: 'TeamMemberRole' }
        & Pick<TeamMemberRole, 'id' | 'name'>
      ) }
    )>>, partners?: Maybe<Array<(
      { __typename?: 'ApprovalPackageSubSectionPartnerRole' }
      & Pick<ApprovalPackageSubSectionPartnerRole, 'names'>
      & { role: (
        { __typename?: 'PartnerRole' }
        & Pick<PartnerRole, 'id' | 'name'>
      ) }
    )>>, events?: Maybe<Array<(
      { __typename?: 'ApprovalPackageSubSectionEvent' }
      & Pick<ApprovalPackageSubSectionEvent, 'startDate' | 'endDate'>
      & { event: (
        { __typename?: 'Event' }
        & Pick<Event, 'id' | 'name' | 'type'>
      ) }
    )>>, metrics?: Maybe<Array<(
      { __typename?: 'ApprovalPackageSubSectionMetric' }
      & Pick<ApprovalPackageSubSectionMetric, 'value'>
      & { metric: (
        { __typename?: 'Metric' }
        & Pick<Metric, 'scale'>
        & MetricFragment
      ) }
    )>> }
  )>> }
);

export type PublishedApprovalPackageSubSectionFragment_PublishedApprovalPackageSubSectionImages_ = (
  { __typename: 'PublishedApprovalPackageSubSectionImages' }
  & Pick<PublishedApprovalPackageSubSectionImages, 'id' | 'title' | 'orderId' | 'gridColumnWidth'>
  & { images?: Maybe<Array<(
    { __typename?: 'PublishedIcSubSectionImageContent' }
    & Pick<PublishedIcSubSectionImageContent, 'id' | 'title' | 'fkIcPackageExhibitPublished'>
  )>> }
);

export type PublishedApprovalPackageSubSectionFragment_PublishedApprovalPackageSubSectionPageBreak_ = (
  { __typename: 'PublishedApprovalPackageSubSectionPageBreak' }
  & Pick<PublishedApprovalPackageSubSectionPageBreak, 'id' | 'orderId'>
);

export type PublishedApprovalPackageSubSectionFragment = PublishedApprovalPackageSubSectionFragment_PublishedApprovalPackageSubSectionText_ | PublishedApprovalPackageSubSectionFragment_PublishedApprovalPackageSubSectionChecklist_ | PublishedApprovalPackageSubSectionFragment_PublishedApprovalPackageSubSectionGcEstimate_ | PublishedApprovalPackageSubSectionFragment_PublishedApprovalPackageSubSectionKeyRisks_ | PublishedApprovalPackageSubSectionFragment_PublishedApprovalPackageSubSectionMerits_ | PublishedApprovalPackageSubSectionFragment_PublishedApprovalPackageSubSectionEarnestMoneyDeposit_ | PublishedApprovalPackageSubSectionFragment_PublishedApprovalPackageSubSectionDealBackground_ | PublishedApprovalPackageSubSectionFragment_PublishedApprovalPackageSubSectionTable_ | PublishedApprovalPackageSubSectionFragment_PublishedApprovalPackageSubSectionImages_ | PublishedApprovalPackageSubSectionFragment_PublishedApprovalPackageSubSectionPageBreak_;

export type GetApprovalPackageTableCellEntryOptionsVariables = Exact<{ [key: string]: never; }>;


export type GetApprovalPackageTableCellEntryOptions = (
  { __typename?: 'RealTraxQuery' }
  & { cellEntryOptions?: Maybe<Array<(
    { __typename?: 'IcSubSectionTableCellEntryOption' }
    & Pick<IcSubSectionTableCellEntryOption, 'id' | 'name' | 'type'>
  )>> }
);

export type GetApprovalPackageTemplatesVariables = Exact<{ [key: string]: never; }>;


export type GetApprovalPackageTemplates = (
  { __typename?: 'RealTraxQuery' }
  & { approvalPackageTemplates?: Maybe<Array<(
    { __typename?: 'TemplateApprovalPackage' }
    & Pick<TemplateApprovalPackage, 'id' | 'name'>
  )>> }
);

export type GetApprovalPackageTemplateVariables = Exact<{
  id: Scalars['Int'];
}>;


export type GetApprovalPackageTemplate = (
  { __typename?: 'RealTraxQuery' }
  & { approvalPackageTemplate?: Maybe<(
    { __typename?: 'TemplateApprovalPackage' }
    & Pick<TemplateApprovalPackage, 'id' | 'name' | 'updatedAt' | 'createdAt'>
    & { sections?: Maybe<Array<(
      { __typename?: 'TemplateApprovalPackageSection' }
      & Pick<TemplateApprovalPackageSection, 'id' | 'title' | 'orderId' | 'gridColumnWidth'>
      & { content?: Maybe<Array<(
        { __typename?: 'TemplateApprovalPackageSubSectionText' }
        & TemplateApprovalPackageSubSectionFragment_TemplateApprovalPackageSubSectionText_
      ) | (
        { __typename?: 'TemplateApprovalPackageSubSectionChecklist' }
        & TemplateApprovalPackageSubSectionFragment_TemplateApprovalPackageSubSectionChecklist_
      ) | (
        { __typename?: 'TemplateApprovalPackageSubSectionGcEstimate' }
        & TemplateApprovalPackageSubSectionFragment_TemplateApprovalPackageSubSectionGcEstimate_
      ) | (
        { __typename?: 'TemplateApprovalPackageSubSectionKeyRisks' }
        & TemplateApprovalPackageSubSectionFragment_TemplateApprovalPackageSubSectionKeyRisks_
      ) | (
        { __typename?: 'TemplateApprovalPackageSubSectionMerits' }
        & TemplateApprovalPackageSubSectionFragment_TemplateApprovalPackageSubSectionMerits_
      ) | (
        { __typename?: 'TemplateApprovalPackageSubSectionTable' }
        & TemplateApprovalPackageSubSectionFragment_TemplateApprovalPackageSubSectionTable_
      ) | (
        { __typename?: 'TemplateApprovalPackageSubSectionImages' }
        & TemplateApprovalPackageSubSectionFragment_TemplateApprovalPackageSubSectionImages_
      ) | (
        { __typename?: 'TemplateApprovalPackageSubSectionPageBreak' }
        & TemplateApprovalPackageSubSectionFragment_TemplateApprovalPackageSubSectionPageBreak_
      ) | (
        { __typename?: 'TemplateApprovalPackageSubSectionDealBackground' }
        & TemplateApprovalPackageSubSectionFragment_TemplateApprovalPackageSubSectionDealBackground_
      ) | (
        { __typename?: 'TemplateApprovalPackageSubSectionEarnestMoneyDeposit' }
        & TemplateApprovalPackageSubSectionFragment_TemplateApprovalPackageSubSectionEarnestMoneyDeposit_
      )>> }
    )>> }
  )> }
);

export type TemplateApprovalPackageSubSectionFragment_TemplateApprovalPackageSubSectionText_ = (
  { __typename: 'TemplateApprovalPackageSubSectionText' }
  & Pick<TemplateApprovalPackageSubSectionText, 'id' | 'title' | 'orderId' | 'gridColumnWidth' | 'text'>
);

export type TemplateApprovalPackageSubSectionFragment_TemplateApprovalPackageSubSectionChecklist_ = (
  { __typename: 'TemplateApprovalPackageSubSectionChecklist' }
  & Pick<TemplateApprovalPackageSubSectionChecklist, 'id' | 'title' | 'orderId' | 'gridColumnWidth'>
  & { items?: Maybe<Array<(
    { __typename?: 'ApprovalPackageChecklistItem' }
    & Pick<ApprovalPackageChecklistItem, 'name' | 'date'>
    & { category?: Maybe<(
      { __typename?: 'ChecklistCategory' }
      & Pick<ChecklistCategory, 'id' | 'name'>
    )>, assignee?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'firstName' | 'lastName'>
    )>, status: (
      { __typename?: 'ChecklistItemStatus' }
      & Pick<ChecklistItemStatus, 'id' | 'name'>
    ) }
  )>> }
);

export type TemplateApprovalPackageSubSectionFragment_TemplateApprovalPackageSubSectionGcEstimate_ = (
  { __typename: 'TemplateApprovalPackageSubSectionGcEstimate' }
  & Pick<TemplateApprovalPackageSubSectionGcEstimate, 'id' | 'title' | 'orderId' | 'gridColumnWidth'>
  & { gcEstimates?: Maybe<Array<(
    { __typename?: 'ApprovalPackageGcEstimate' }
    & Pick<ApprovalPackageGcEstimate, 'id' | 'updatedAt' | 'description' | 'totalPrice' | 'pricePerUnit' | 'pricePerNrsf' | 'comment' | 'isApproved' | 'commentCount'>
    & { type: (
      { __typename?: 'EstimateType' }
      & Pick<EstimateType, 'id' | 'name'>
    ), source: (
      { __typename?: 'Firm' }
      & Pick<Firm, 'id' | 'name'>
    ), comments?: Maybe<Array<(
      { __typename?: 'Comment' }
      & Pick<Comment, 'id' | 'body'>
    )>> }
  )>> }
);

export type TemplateApprovalPackageSubSectionFragment_TemplateApprovalPackageSubSectionKeyRisks_ = (
  { __typename: 'TemplateApprovalPackageSubSectionKeyRisks' }
  & Pick<TemplateApprovalPackageSubSectionKeyRisks, 'id' | 'title' | 'orderId' | 'gridColumnWidth'>
);

export type TemplateApprovalPackageSubSectionFragment_TemplateApprovalPackageSubSectionMerits_ = (
  { __typename: 'TemplateApprovalPackageSubSectionMerits' }
  & Pick<TemplateApprovalPackageSubSectionMerits, 'id' | 'title' | 'orderId' | 'gridColumnWidth'>
);

export type TemplateApprovalPackageSubSectionFragment_TemplateApprovalPackageSubSectionTable_ = (
  { __typename: 'TemplateApprovalPackageSubSectionTable' }
  & Pick<TemplateApprovalPackageSubSectionTable, 'id' | 'title' | 'orderId' | 'gridColumnWidth' | 'columns'>
  & { cells?: Maybe<Array<(
    { __typename?: 'TemplateIcSubSectionTableCell' }
    & Pick<TemplateIcSubSectionTableCell, 'id' | 'orderId'>
    & { entries?: Maybe<Array<(
      { __typename?: 'IcSubSectionTableCellEntry' }
      & Pick<IcSubSectionTableCellEntry, 'id' | 'name' | 'orderId' | 'type'>
    )>> }
  )>> }
);

export type TemplateApprovalPackageSubSectionFragment_TemplateApprovalPackageSubSectionImages_ = (
  { __typename: 'TemplateApprovalPackageSubSectionImages' }
  & Pick<TemplateApprovalPackageSubSectionImages, 'id' | 'title' | 'orderId' | 'gridColumnWidth'>
);

export type TemplateApprovalPackageSubSectionFragment_TemplateApprovalPackageSubSectionPageBreak_ = (
  { __typename: 'TemplateApprovalPackageSubSectionPageBreak' }
  & Pick<TemplateApprovalPackageSubSectionPageBreak, 'id' | 'orderId'>
);

export type TemplateApprovalPackageSubSectionFragment_TemplateApprovalPackageSubSectionDealBackground_ = (
  { __typename: 'TemplateApprovalPackageSubSectionDealBackground' }
  & Pick<TemplateApprovalPackageSubSectionDealBackground, 'id' | 'title' | 'orderId' | 'gridColumnWidth'>
);

export type TemplateApprovalPackageSubSectionFragment_TemplateApprovalPackageSubSectionEarnestMoneyDeposit_ = (
  { __typename: 'TemplateApprovalPackageSubSectionEarnestMoneyDeposit' }
  & Pick<TemplateApprovalPackageSubSectionEarnestMoneyDeposit, 'id' | 'title' | 'orderId' | 'gridColumnWidth'>
);

export type TemplateApprovalPackageSubSectionFragment = TemplateApprovalPackageSubSectionFragment_TemplateApprovalPackageSubSectionText_ | TemplateApprovalPackageSubSectionFragment_TemplateApprovalPackageSubSectionChecklist_ | TemplateApprovalPackageSubSectionFragment_TemplateApprovalPackageSubSectionGcEstimate_ | TemplateApprovalPackageSubSectionFragment_TemplateApprovalPackageSubSectionKeyRisks_ | TemplateApprovalPackageSubSectionFragment_TemplateApprovalPackageSubSectionMerits_ | TemplateApprovalPackageSubSectionFragment_TemplateApprovalPackageSubSectionTable_ | TemplateApprovalPackageSubSectionFragment_TemplateApprovalPackageSubSectionImages_ | TemplateApprovalPackageSubSectionFragment_TemplateApprovalPackageSubSectionPageBreak_ | TemplateApprovalPackageSubSectionFragment_TemplateApprovalPackageSubSectionDealBackground_ | TemplateApprovalPackageSubSectionFragment_TemplateApprovalPackageSubSectionEarnestMoneyDeposit_;

export type GetLabelsVariables = Exact<{ [key: string]: never; }>;


export type GetLabels = (
  { __typename?: 'RealTraxQuery' }
  & { labels?: Maybe<Array<(
    { __typename?: 'Label' }
    & Pick<Label, 'id' | 'name' | 'color'>
  )>> }
);

export type GetReportTypesVariables = Exact<{ [key: string]: never; }>;


export type GetReportTypes = (
  { __typename?: 'RealTraxQuery' }
  & { reportTypes?: Maybe<Array<(
    { __typename?: 'ReportType' }
    & Pick<ReportType, 'id' | 'name'>
  )>> }
);

export type GetInvestmentTypesVariables = Exact<{ [key: string]: never; }>;


export type GetInvestmentTypes = (
  { __typename?: 'RealTraxQuery' }
  & { investmentTypes?: Maybe<Array<(
    { __typename?: 'InvestmentType' }
    & Pick<InvestmentType, 'id' | 'name'>
  )>> }
);

export type GetCrmStatusesVariables = Exact<{ [key: string]: never; }>;


export type GetCrmStatuses = (
  { __typename?: 'RealTraxQuery' }
  & { crmStatuses?: Maybe<Array<(
    { __typename?: 'CrmStatus' }
    & Pick<CrmStatus, 'id' | 'name'>
  )>> }
);

export type GetFirmTypesVariables = Exact<{ [key: string]: never; }>;


export type GetFirmTypes = (
  { __typename?: 'RealTraxQuery' }
  & { firmTypes?: Maybe<Array<(
    { __typename?: 'FirmType' }
    & Pick<FirmType, 'id' | 'name'>
  )>> }
);

export type GetInteractionTypesVariables = Exact<{ [key: string]: never; }>;


export type GetInteractionTypes = (
  { __typename?: 'RealTraxQuery' }
  & { interactionTypes?: Maybe<Array<(
    { __typename?: 'InteractionType' }
    & Pick<InteractionType, 'id' | 'name'>
  )>> }
);

export type GetArtifactTypesVariables = Exact<{ [key: string]: never; }>;


export type GetArtifactTypes = (
  { __typename?: 'RealTraxQuery' }
  & { artifactTypes?: Maybe<Array<(
    { __typename?: 'Artifact' }
    & Pick<Artifact, 'id' | 'name'>
  )>> }
);

export type GetTransactionPeriodSummariesVariables = Exact<{
  id: Scalars['Int'];
  fkGlAccount: Scalars['Int'];
  period: Scalars['Date'];
}>;


export type GetTransactionPeriodSummaries = (
  { __typename?: 'RealTraxQuery' }
  & { deal?: Maybe<(
    { __typename?: 'Deal' }
    & { transactionPeriodSummaries?: Maybe<Array<(
      { __typename?: 'TransactionPeriodSummary' }
      & Pick<TransactionPeriodSummary, 'id' | 'description' | 'amount' | 'postDate'>
      & { account: (
        { __typename?: 'GlAccount' }
        & Pick<GlAccount, 'id' | 'name' | 'code' | 'displayCode'>
      ) }
    )>> }
  )> }
);

export type GetDealArtifactsVariables = Exact<{
  id: Scalars['Int'];
}>;


export type GetDealArtifacts = (
  { __typename?: 'RealTraxQuery' }
  & { deal?: Maybe<(
    { __typename?: 'Deal' }
    & { artifacts?: Maybe<Array<(
      { __typename?: 'DealArtifact' }
      & Pick<DealArtifact, 'id' | 'name' | 'fileExtension' | 'fkArtifact' | 'createdAt' | 'updatedAt'>
      & { createdBy: (
        { __typename?: 'SystemProcess' }
        & Pick<SystemProcess, 'id' | 'displayName' | 'displayNameAlt'>
      ) | (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'displayName' | 'displayNameAlt'>
      ), updatedBy: (
        { __typename?: 'SystemProcess' }
        & Pick<SystemProcess, 'id' | 'displayName' | 'displayNameAlt'>
      ) | (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'displayName' | 'displayNameAlt'>
      ) }
    )>> }
  )> }
);

export type GetContactAttachmentsVariables = Exact<{
  id: Scalars['Int'];
}>;


export type GetContactAttachments = (
  { __typename?: 'RealTraxQuery' }
  & { contact?: Maybe<(
    { __typename?: 'Contact' }
    & { attachments?: Maybe<Array<(
      { __typename?: 'ContactAttachment' }
      & Pick<ContactAttachment, 'id' | 'displayName' | 'fileExtension' | 'fkDocument' | 'createdAt' | 'updatedAt'>
      & { createdBy: (
        { __typename?: 'SystemProcess' }
        & Pick<SystemProcess, 'id' | 'displayName' | 'displayNameAlt'>
      ) | (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'displayName' | 'displayNameAlt'>
      ), updatedBy: (
        { __typename?: 'SystemProcess' }
        & Pick<SystemProcess, 'id' | 'displayName' | 'displayNameAlt'>
      ) | (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'displayName' | 'displayNameAlt'>
      ) }
    )>> }
  )> }
);

export type GetFirmAttachmentsVariables = Exact<{
  id: Scalars['Int'];
}>;


export type GetFirmAttachments = (
  { __typename?: 'RealTraxQuery' }
  & { firm?: Maybe<(
    { __typename?: 'Firm' }
    & { attachments?: Maybe<Array<(
      { __typename?: 'FirmAttachment' }
      & Pick<FirmAttachment, 'id' | 'displayName' | 'fileExtension' | 'fkDocument' | 'createdAt' | 'updatedAt'>
      & { createdBy: (
        { __typename?: 'SystemProcess' }
        & Pick<SystemProcess, 'id' | 'displayName' | 'displayNameAlt'>
      ) | (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'displayName' | 'displayNameAlt'>
      ), updatedBy: (
        { __typename?: 'SystemProcess' }
        & Pick<SystemProcess, 'id' | 'displayName' | 'displayNameAlt'>
      ) | (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'displayName' | 'displayNameAlt'>
      ) }
    )>> }
  )> }
);

export type GetInterestRatesVariables = Exact<{ [key: string]: never; }>;


export type GetInterestRates = (
  { __typename?: 'RealTraxQuery' }
  & { interestRates?: Maybe<Array<(
    { __typename?: 'InterestRate' }
    & Pick<InterestRate, 'id' | 'value' | 'startDate' | 'endDate'>
  )>> }
);

export type GetForecastInterestAccountStatusVariables = Exact<{
  id: Scalars['Int'];
}>;


export type GetForecastInterestAccountStatus = (
  { __typename?: 'RealTraxQuery' }
  & { forecastInterestAccountStatus?: Maybe<(
    { __typename?: 'ForecastInterestAccountStatusType' }
    & Pick<ForecastInterestAccountStatusType, 'sessionId' | 'transactionsTotal' | 'transactionsLeft'>
  )> }
);

export type GetLatestPipelineAnalysisMetricDistributionVariables = Exact<{
  analysisMetricId: Scalars['Int'];
}>;


export type GetLatestPipelineAnalysisMetricDistribution = (
  { __typename?: 'RealTraxQuery' }
  & Pick<RealTraxQuery, 'latestPipelineAnalysisMetricDistribution'>
);

export type GetCategoricalDealChartsVariables = Exact<{
  id: Scalars['Int'];
  birthDate: Scalars['Date'];
  dealTypeIds: Array<Scalars['Int']> | Scalars['Int'];
}>;


export type GetCategoricalDealCharts = (
  { __typename?: 'RealTraxQuery' }
  & { fundChart: Array<Maybe<(
    { __typename?: 'ChartDataItem' }
    & Pick<ChartDataItem, 'name' | 'value'>
  )>>, stateChart: Array<Maybe<(
    { __typename?: 'ChartDataItem' }
    & Pick<ChartDataItem, 'name' | 'value'>
  )>>, stageChart: Array<Maybe<(
    { __typename?: 'ChartDataItem' }
    & Pick<ChartDataItem, 'name' | 'value'>
  )>>, projectLeadChart: Array<Maybe<(
    { __typename?: 'ChartDataItem' }
    & Pick<ChartDataItem, 'name' | 'value'>
  )>> }
);

export type GetNumericalDealChartsVariables = Exact<{
  id: Scalars['Int'];
  birthDate: Scalars['Date'];
  dealTypeIds: Array<Scalars['Int']> | Scalars['Int'];
}>;


export type GetNumericalDealCharts = (
  { __typename?: 'RealTraxQuery' }
  & Pick<RealTraxQuery, 'staticIrrChart'>
);

export type GetAnalyticsSummaryVariables = Exact<{
  id: Scalars['Int'];
  birthDate: Scalars['Date'];
  dealTypeIds: Array<Scalars['Int']> | Scalars['Int'];
}>;


export type GetAnalyticsSummary = (
  { __typename?: 'RealTraxQuery' }
  & { analyticsSummary: (
    { __typename?: 'AnalyticsSummary' }
    & Pick<AnalyticsSummary, 'dealCount'>
    & { totalUnits?: Maybe<(
      { __typename?: 'MetricValue' }
      & Pick<MetricValue, 'value'>
      & { metric?: Maybe<(
        { __typename?: 'Metric' }
        & Pick<Metric, 'name' | 'precision' | 'unit' | 'scale' | 'format'>
      )> }
    )>, totalSquareFootage?: Maybe<(
      { __typename?: 'MetricValue' }
      & Pick<MetricValue, 'value'>
      & { metric?: Maybe<(
        { __typename?: 'Metric' }
        & Pick<Metric, 'name' | 'precision' | 'unit' | 'scale' | 'format'>
      )> }
    )>, developmentCosts?: Maybe<(
      { __typename?: 'MetricValue' }
      & Pick<MetricValue, 'value'>
      & { metric?: Maybe<(
        { __typename?: 'Metric' }
        & Pick<Metric, 'name' | 'precision' | 'unit' | 'scale' | 'format'>
      )> }
    )> }
  ) }
);

export type GetFundChartVariables = Exact<{
  id: Scalars['Int'];
  birthDate: Scalars['Date'];
  dealTypeIds: Array<Scalars['Int']> | Scalars['Int'];
}>;


export type GetFundChart = (
  { __typename?: 'RealTraxQuery' }
  & { coreMarketCapChart: Array<Maybe<(
    { __typename?: 'DecimalChartDataItem' }
    & Pick<DecimalChartDataItem, 'name' | 'value'>
  )>>, dealTypeChart: Array<Maybe<(
    { __typename?: 'ChartDataItem' }
    & Pick<ChartDataItem, 'name' | 'value'>
  )>> }
);

export type GetDealQuarterEventsChartVariables = Exact<{
  pipelineId: Scalars['Int'];
  fkEvent: Scalars['Int'];
  yearsBack: Scalars['Int'];
  dealTypeIds: Array<Scalars['Int']> | Scalars['Int'];
}>;


export type GetDealQuarterEventsChart = (
  { __typename?: 'RealTraxQuery' }
  & { dealQuarterEventsChart: Array<Maybe<(
    { __typename?: 'ChartDataItem' }
    & Pick<ChartDataItem, 'name' | 'value'>
  )>> }
);

export type GetMarketViewVariables = Exact<{
  pipelineId: Scalars['Int'];
  birthDate: Scalars['Date'];
  includeArchivedDeals: Scalars['Boolean'];
  marketStatistic: MarketStatisticEnum;
}>;


export type GetMarketView = (
  { __typename?: 'RealTraxQuery' }
  & { marketView?: Maybe<(
    { __typename?: 'MarketView' }
    & { columns?: Maybe<Array<(
      { __typename?: 'MarketViewColumn' }
      & Pick<MarketViewColumn, 'id' | 'name' | 'format' | 'type' | 'lookupName'>
    )>>, payload?: Maybe<(
      { __typename?: 'MarketViewPayload' }
      & Pick<MarketViewPayload, 'columns' | 'rows'>
    )> }
  )> }
);

export const DealFragment = gql`
    fragment DealFragment on Deal {
  id
  name
}
    `;
export const DealAlreadyExistsFragment = gql`
    fragment DealAlreadyExistsFragment on DealAlreadyExists {
  deal {
    id
    name
  }
}
    `;
export const PortfolioFragment = gql`
    fragment PortfolioFragment on Portfolio {
  id
  name
  description
}
    `;
export const PortfolioAlreadyExistsFragment = gql`
    fragment PortfolioAlreadyExistsFragment on PortfolioAlreadyExists {
  portfolio {
    id
    name
    description
  }
}
    `;
export const DealTeamMemberFragment = gql`
    fragment DealTeamMemberFragment on DealTeamMemberUser {
  user {
    id
    firstName
    lastName
  }
  role {
    id
    name
    description
  }
}
    `;
export const ErrorFragment = gql`
    fragment ErrorFragment on Error {
  message
}
    `;
export const ContactFragment = gql`
    fragment ContactFragment on Contact {
  id
  firstName
  lastName
  title
  firm {
    id
    name
  }
  email
  cellPhone
  notes
  isPrimary
}
    `;
export const ContactAlreadyExistsFragment = gql`
    fragment ContactAlreadyExistsFragment on ContactAlreadyExists {
  contact {
    id
    firstName
    lastName
    email
  }
}
    `;
export const DealPartnerFragment = gql`
    fragment DealPartnerFragment on DealPartner {
  id
  firm {
    id
    name
  }
  contact {
    id
    firstName
    lastName
  }
  role {
    id
    name
    description
  }
}
    `;
export const ChecklistFragment = gql`
    fragment ChecklistFragment on Checklist {
  id
  name
}
    `;
export const ChecklistAlreadyExistsFragment = gql`
    fragment ChecklistAlreadyExistsFragment on ChecklistAlreadyExists {
  checklist {
    id
    name
  }
}
    `;
export const DealChecklistFragment = gql`
    fragment DealChecklistFragment on DealChecklist {
  id
  name
}
    `;
export const DealChecklistAlreadyExistsFragment = gql`
    fragment DealChecklistAlreadyExistsFragment on DealChecklistAlreadyExists {
  dealChecklist {
    id
    name
  }
}
    `;
export const ChecklistItemFragment = gql`
    fragment ChecklistItemFragment on ChecklistItem {
  id
  name
}
    `;
export const ChecklistItemAlreadyExistsFragment = gql`
    fragment ChecklistItemAlreadyExistsFragment on ChecklistItemAlreadyExists {
  checklistItem {
    id
    name
  }
}
    `;
export const ChecklistCategoryFragment = gql`
    fragment ChecklistCategoryFragment on ChecklistCategory {
  id
  name
}
    `;
export const ChecklistCategoryAlreadyExistsFragment = gql`
    fragment ChecklistCategoryAlreadyExistsFragment on ChecklistCategoryAlreadyExists {
  checklistCategory {
    id
    name
  }
}
    `;
export const PipelineDealStageFragment = gql`
    fragment PipelineDealStageFragment on DealStage {
  id
  name
  createdAt
  fkPipeline
}
    `;
export const PipelineDealStageAlreadyExistsFragment = gql`
    fragment PipelineDealStageAlreadyExistsFragment on DealStageAlreadyExists {
  dealStage {
    ...PipelineDealStageFragment
  }
}
    ${PipelineDealStageFragment}`;
export const ConfigurableEntityFragment = gql`
    fragment ConfigurableEntityFragment on ConfigurableEntity {
  id
  name
  createdAt
  entityType
}
    `;
export const ConfigurableEntityAlreadyExistsFragment = gql`
    fragment ConfigurableEntityAlreadyExistsFragment on ConfigurableEntityAlreadyExists {
  entity {
    ...ConfigurableEntityFragment
  }
}
    ${ConfigurableEntityFragment}`;
export const EventAlreadyExistsFragment = gql`
    fragment EventAlreadyExistsFragment on EventAlreadyExists {
  message
  event {
    id
    name
    type
  }
}
    `;
export const EventFragment = gql`
    fragment EventFragment on Event {
  id
  name
  type
  createdAt
}
    `;
export const DealEventAlreadyExistsFragment = gql`
    fragment DealEventAlreadyExistsFragment on DealEventAlreadyExists {
  message
  event {
    id
    name
  }
}
    `;
export const DealAnalysisFragment = gql`
    fragment DealAnalysisFragment on DealAnalysis {
  id
  description
  scenario {
    id
    name
  }
  archiveDate
  fkAnalysisType
}
    `;
export const AnalysisTypeFragment = gql`
    fragment AnalysisTypeFragment on AnalysisType {
  id
  name
  acronym
}
    `;
export const AnalysisTypeAlreadyExistsFragment = gql`
    fragment AnalysisTypeAlreadyExistsFragment on AnalysisTypeAlreadyExists {
  message
  analysisType {
    id
    name
  }
}
    `;
export const PortfolioViewFragment = gql`
    fragment PortfolioViewFragment on PortfolioView {
  id
  name
  showArchivedDeals
  createdAt
  updatedAt
}
    `;
export const CommentFragment = gql`
    fragment CommentFragment on Comment {
  id
  body
  createdBy {
    id
    displayName
    displayNameAlt
  }
  updatedAt
}
    `;
export const MetricAlreadyExistsFragment = gql`
    fragment MetricAlreadyExistsFragment on MetricAlreadyExists {
  message
  metric {
    id
    name
  }
}
    `;
export const TextMetricFragment = gql`
    fragment TextMetricFragment on TextMetric {
  id
  name
}
    `;
export const TextMetricAlreadyExistsFragment = gql`
    fragment TextMetricAlreadyExistsFragment on TextMetricAlreadyExists {
  message
  textMetric {
    id
    name
  }
}
    `;
export const PublishedScheduleIntanceFragment = gql`
    fragment PublishedScheduleIntanceFragment on PublishedScheduleInstance {
  id
  description
  createdAt
  updatedAt
}
    `;
export const PublishedDealEventAlreadyExistsFragment = gql`
    fragment PublishedDealEventAlreadyExistsFragment on PublishedDealEventAlreadyExists {
  message
  event {
    id
    name
  }
}
    `;
export const BondFragment = gql`
    fragment BondFragment on Bond {
  id
  name
  createdAt
  updatedAt
  createdBy {
    id
    displayName
  }
  updatedBy {
    id
    displayName
  }
  isComplete
  bondDescription
  principalEntity
  bondingCompany {
    id
    name
  }
  obligeeBeneficiary
  premium
  bondAmount
  collateralAmount
  locAmount
  acceptedAlternatives
  breakdownBackup
  describedWork
  originalEffectiveDate
  expires
  expectedRelease
  conditionsForRelease
  premiumRefundDue
  notes
  improvementDeadline
  premiumComment
  collateralNotes
  expiresNotes
}
    `;
export const MetricFragment = gql`
    fragment MetricFragment on Metric {
  id
  name
  format
  unit
  precision
}
    `;
export const DealPinnedMetricFragment = gql`
    fragment DealPinnedMetricFragment on DealPinnedMetric {
  metric {
    ...MetricFragment
  }
}
    ${MetricFragment}`;
export const GlAccountFragment = gql`
    fragment GlAccountFragment on GlAccount {
  id
  name
  code
  displayCode
  type {
    id
    name
  }
  category {
    id
    name
  }
  isDisabled
  isAppDefault
}
    `;
export const GlAccountTreeFragment = gql`
    fragment GlAccountTreeFragment on GlAccountTree {
  id
  name
  reportType {
    id
    name
  }
  isDisabled
  isAppDefault
}
    `;
export const PipelineContingencyCategoryFragment = gql`
    fragment PipelineContingencyCategoryFragment on PipelineContingencyCategory {
  id
  account {
    id
    name
    code
    displayCode
  }
  category {
    id
    name
  }
}
    `;
export const PipelineGeneralOffsetFragment = gql`
    fragment PipelineGeneralOffsetFragment on PipelineGeneralOffset {
  id
  offset
  event {
    id
    name
    type
  }
  filter {
    id
    name
  }
}
    `;
export const PipelineAccountOffsetFragment = gql`
    fragment PipelineAccountOffsetFragment on PipelineAccountOffset {
  id
  offset
  account {
    id
    name
    code
    displayCode
  }
  event {
    id
    name
    type
  }
  filter {
    id
    name
  }
}
    `;
export const ForecastRuleFragment = gql`
    fragment ForecastRuleFragment on ForecastRule {
  id
  description
  account {
    id
    name
    code
    displayCode
  }
  fkForecastRuleGroup
  fkForecastRuleType
  distributionType {
    id
    name
  }
  startEvent {
    id
    name
    type
  }
  endEvent {
    id
    name
    type
  }
  milestoneAdjustmentStart
  milestoneAdjustmentEnd
  frequency
  startMonth
  dollarAmount
  dollarPercent
  isDisabled
  usesBalance
  isSliding
  phaseSpendingCapPercent
  fkEventEndUsesStartDate
  fkEventStartUsesStartDate
}
    `;
export const ApprovalPackageFragment = gql`
    fragment ApprovalPackageFragment on ApprovalPackage {
  id
  updatedAt
  createdAt
  reviewDate
  isPublished
  type {
    id
    name
  }
  status
  amendmentNumber
  deal {
    ... on Deal {
      id
      name
    }
  }
}
    `;
export const ApprovalPackageTemplateFragment = gql`
    fragment ApprovalPackageTemplateFragment on TemplateApprovalPackage {
  id
  name
  updatedAt
  createdAt
}
    `;
export const ApprovalPackageTypeFragment = gql`
    fragment ApprovalPackageTypeFragment on ApprovalPackageType {
  id
  name
  event {
    id
    name
    type
  }
  defaultTemplate {
    id
    name
  }
}
    `;
export const InterestRateFragment = gql`
    fragment InterestRateFragment on InterestRate {
  id
  value
  startDate
}
    `;
export const InterestRateAlreadyExistsFragment = gql`
    fragment InterestRateAlreadyExistsFragment on InterestRateAlreadyExists {
  message
  interestRate {
    id
    value
    startDate
  }
}
    `;
export const TransactionDateFragment = gql`
    fragment TransactionDateFragment on TransactionDate {
  date
}
    `;
export const TransactionEventFragment = gql`
    fragment TransactionEventFragment on TransactionEvent {
  event {
    id
    name
    startDate
    endDate
    type
  }
  usesStartDate
  milestoneOffset
}
    `;
export const DealEventFragment = gql`
    fragment DealEventFragment on DealEvent {
  id
  dealEventId
  name
  orderId
  isEditable
  startDate
  endDate
  isComplete
  type
  category {
    id
    name
  }
  parent {
    id
    name
  }
  dependsOn {
    id
    name
  }
  commentCount
}
    `;
export const PublishedDealEventFragment = gql`
    fragment PublishedDealEventFragment on PublishedDealEvent {
  id
  dealEventId
  name
  orderId
  startDate
  endDate
  isComplete
  type
  category {
    id
    name
  }
  parent {
    id
    name
  }
  dependsOn {
    id
    name
  }
}
    `;
export const BuildingFragment = gql`
    fragment BuildingFragment on Building {
  id
  name
  address
  buildingNumber
  notes
  isTcoReceived
  createdAt
  updatedAt
  createdBy {
    id
    displayName
  }
  updatedBy {
    id
    displayName
  }
  tcoDate
  type {
    id
    name
  }
  unitCount
  commentCount
}
    `;
export const GcEstimateFragment = gql`
    fragment GcEstimateFragment on GcEstimate {
  id
  description
  totalPrice
  pricePerUnit
  pricePerNrsf
  isApproved
  comment
  type {
    id
    name
  }
  source {
    id
    name
  }
  createdAt
  updatedAt
  createdBy {
    id
    displayName
  }
  updatedBy {
    id
    displayName
  }
  commentCount
}
    `;
export const ChangeEventFragment = gql`
    fragment ChangeEventFragment on ChangeEvent {
  id
  title
  number
  total
  scheduleImpact
  ownerTotal
  ownerScheduleImpact
  isScheduleImpactExcusable
  fkPotentialChangeOrder
  createdAt
  updatedAt
  createdBy {
    id
    displayName
  }
  updatedBy {
    id
    displayName
  }
  type {
    id
    name
  }
  status {
    id
    name
  }
  reason {
    id
    name
  }
  costSource {
    id
    name
  }
}
    `;
export const PotentialChangeOrderFragment = gql`
    fragment PotentialChangeOrderFragment on PotentialChangeOrder {
  id
  title
  number
  total
  scheduleImpact
  ownerTotal
  ownerScheduleImpact
  isScheduleImpactExcusable
  fkChangeOrderPackage
  createdAt
  updatedAt
  createdBy {
    id
    displayName
  }
  updatedBy {
    id
    displayName
  }
  status {
    id
    name
  }
  type {
    id
    name
  }
  costSource {
    id
    name
  }
}
    `;
export const ChangeOrderPackageFragment = gql`
    fragment ChangeOrderPackageFragment on ChangeOrderPackage {
  id
  title
  number
  total
  scheduleImpact
  isScheduleImpactExcusable
  fkChangeOrderPackageParent
  createdAt
  updatedAt
  createdBy {
    id
    displayName
  }
  updatedBy {
    id
    displayName
  }
  status {
    id
    name
  }
  costSource {
    id
    name
  }
}
    `;
export const ApprovalPackageReviewerFragment = gql`
    fragment ApprovalPackageReviewerFragment on ApprovalPackageReviewer {
  id
  createdAt
  updatedAt
  reviewedDate
  user {
    id
    firstName
    lastName
    email
  }
  status
  firm {
    id
    name
  }
}
    `;
export const ApprovalPackageContributorFragment = gql`
    fragment ApprovalPackageContributorFragment on ApprovalPackageContributor {
  id
  createdAt
  updatedAt
  user {
    id
    firstName
    lastName
  }
}
    `;
export const ApprovalPackageSubSectionFragment = gql`
    fragment ApprovalPackageSubSectionFragment on ApprovalPackageSubSectionUnion {
  __typename
  ... on ApprovalPackageSubSectionText {
    id
    title
    orderId
    gridColumnWidth
    text
  }
  ... on ApprovalPackageSubSectionDealBackground {
    id
    title
    orderId
    gridColumnWidth
    dealBackgroundText
  }
  ... on ApprovalPackageSubSectionEarnestMoneyDeposit {
    id
    title
    orderId
    gridColumnWidth
    earnestMoneyDepositText
  }
  ... on ApprovalPackageSubSectionGcEstimate {
    id
    title
    orderId
    gridColumnWidth
    gcEstimates {
      id
      updatedAt
      description
      totalPrice
      pricePerUnit
      pricePerNrsf
      comment
      isApproved
      type {
        id
        name
      }
      source {
        id
        name
      }
      comments {
        id
        body
      }
      commentCount
    }
  }
  ... on ApprovalPackageSubSectionChecklist {
    id
    title
    orderId
    gridColumnWidth
    items {
      name
      date
      category {
        id
        name
      }
      assignee {
        id
        firstName
        lastName
      }
      status {
        id
        name
      }
    }
  }
  ... on ApprovalPackageSubSectionImages {
    id
    title
    orderId
    gridColumnWidth
    images {
      id
      title
      fkIcPackageExhibit
    }
  }
  ... on ApprovalPackageSubSectionTable {
    id
    title
    orderId
    gridColumnWidth
    columns
    cells {
      id
      orderId
      entries {
        id
        name
        orderId
        type
      }
      attributes {
        value
        attribute {
          id
          displayName
          columnName
          dataType
        }
      }
      teamMembers {
        names
        role {
          id
          name
        }
      }
      partners {
        names
        role {
          id
          name
        }
      }
      events {
        event {
          id
          name
          type
        }
        startDate
        endDate
      }
      metrics {
        metric {
          scale
          ...MetricFragment
        }
        value
      }
    }
  }
  ... on ApprovalPackageSubSectionKeyRisks {
    id
    title
    orderId
    gridColumnWidth
    keyRisks {
      id
      title
      riskDescription
      mitigantDescription
      orderId
    }
  }
  ... on ApprovalPackageSubSectionMerits {
    id
    title
    orderId
    gridColumnWidth
    merits {
      id
      title
      meritDescription
      orderId
    }
  }
  ... on ApprovalPackageSubSectionPageBreak {
    id
    orderId
  }
}
    ${MetricFragment}`;
export const PublishedApprovalPackageSubSectionFragment = gql`
    fragment PublishedApprovalPackageSubSectionFragment on PublishedApprovalPackageSubSectionUnion {
  __typename
  ... on PublishedApprovalPackageSubSectionText {
    id
    title
    orderId
    gridColumnWidth
    text
  }
  ... on PublishedApprovalPackageSubSectionDealBackground {
    id
    title
    orderId
    gridColumnWidth
    dealBackgroundText
  }
  ... on PublishedApprovalPackageSubSectionEarnestMoneyDeposit {
    id
    title
    orderId
    gridColumnWidth
    earnestMoneyDepositText
  }
  ... on PublishedApprovalPackageSubSectionChecklist {
    id
    title
    orderId
    gridColumnWidth
    items {
      name
      date
      category {
        id
        name
      }
      assignee {
        id
        firstName
        lastName
      }
      status {
        id
        name
      }
    }
  }
  ... on PublishedApprovalPackageSubSectionGcEstimate {
    id
    title
    orderId
    gridColumnWidth
    gcEstimates {
      id
      updatedAt
      description
      totalPrice
      pricePerUnit
      pricePerNrsf
      comment
      isApproved
      type {
        id
        name
      }
      source {
        id
        name
      }
      comments {
        id
        body
      }
      commentCount
    }
  }
  ... on PublishedApprovalPackageSubSectionImages {
    id
    title
    orderId
    gridColumnWidth
    images {
      id
      title
      fkIcPackageExhibitPublished
    }
  }
  ... on PublishedApprovalPackageSubSectionTable {
    id
    title
    orderId
    gridColumnWidth
    columns
    cells {
      id
      orderId
      entries {
        id
        name
        orderId
        type
      }
      attributes {
        value
        attribute {
          id
          displayName
          columnName
          dataType
        }
      }
      teamMembers {
        names
        role {
          id
          name
        }
      }
      partners {
        names
        role {
          id
          name
        }
      }
      events {
        event {
          id
          name
          type
        }
        startDate
        endDate
      }
      metrics {
        metric {
          scale
          ...MetricFragment
        }
        value
      }
    }
  }
  ... on PublishedApprovalPackageSubSectionKeyRisks {
    id
    title
    orderId
    gridColumnWidth
    keyRisks {
      id
      orderId
      title
      riskDescription
      mitigantDescription
    }
  }
  ... on PublishedApprovalPackageSubSectionMerits {
    id
    title
    orderId
    gridColumnWidth
    merits {
      id
      orderId
      title
      meritDescription
    }
  }
  ... on PublishedApprovalPackageSubSectionPageBreak {
    id
    orderId
  }
}
    ${MetricFragment}`;
export const TemplateApprovalPackageSubSectionFragment = gql`
    fragment TemplateApprovalPackageSubSectionFragment on TemplateApprovalPackageSubSectionUnion {
  __typename
  ... on TemplateApprovalPackageSubSectionText {
    id
    title
    orderId
    gridColumnWidth
    text
  }
  ... on TemplateApprovalPackageSubSectionDealBackground {
    id
    title
    orderId
    gridColumnWidth
  }
  ... on TemplateApprovalPackageSubSectionEarnestMoneyDeposit {
    id
    title
    orderId
    gridColumnWidth
  }
  ... on TemplateApprovalPackageSubSectionChecklist {
    id
    title
    orderId
    gridColumnWidth
    items {
      name
      date
      category {
        id
        name
      }
      assignee {
        id
        firstName
        lastName
      }
      status {
        id
        name
      }
    }
  }
  ... on TemplateApprovalPackageSubSectionGcEstimate {
    id
    title
    orderId
    gridColumnWidth
    gcEstimates {
      id
      updatedAt
      description
      totalPrice
      pricePerUnit
      pricePerNrsf
      comment
      isApproved
      type {
        id
        name
      }
      source {
        id
        name
      }
      comments {
        id
        body
      }
      commentCount
    }
  }
  ... on TemplateApprovalPackageSubSectionImages {
    id
    title
    orderId
    gridColumnWidth
  }
  ... on TemplateApprovalPackageSubSectionTable {
    id
    title
    orderId
    gridColumnWidth
    columns
    cells {
      id
      orderId
      entries {
        id
        name
        orderId
        type
      }
    }
  }
  ... on TemplateApprovalPackageSubSectionKeyRisks {
    id
    title
    orderId
    gridColumnWidth
  }
  ... on TemplateApprovalPackageSubSectionMerits {
    id
    title
    orderId
    gridColumnWidth
  }
  ... on TemplateApprovalPackageSubSectionPageBreak {
    id
    orderId
  }
}
    `;
export const CreateDealDocument = gql`
    mutation CreateDeal($deal: CreateDealInput!) {
  createDeal(deal: $deal) {
    __typename
    ...DealFragment
    ...ErrorFragment
    ...DealAlreadyExistsFragment
  }
}
    ${DealFragment}
${ErrorFragment}
${DealAlreadyExistsFragment}`;
export const CreatePortfolioDocument = gql`
    mutation CreatePortfolio($portfolio: CreatePortfolioInput!) {
  createPortfolio(portfolio: $portfolio) {
    __typename
    ...PortfolioFragment
    ...ErrorFragment
    ...PortfolioAlreadyExistsFragment
  }
}
    ${PortfolioFragment}
${ErrorFragment}
${PortfolioAlreadyExistsFragment}`;
export const UpdatePortfolioDocument = gql`
    mutation UpdatePortfolio($portfolio: UpdatePortfolioInput!) {
  updatePortfolio(portfolio: $portfolio) {
    ...PortfolioFragment
  }
}
    ${PortfolioFragment}`;
export const UpdateDynamicPortfolioFiltersDocument = gql`
    mutation UpdateDynamicPortfolioFilters($id: Int!, $filterGroups: [CreatePortfolioFilterGroupInput!]!) {
  updateDynamicPortfolioFilters(id: $id, filterGroups: $filterGroups) {
    ... on Portfolio {
      id
      name
      isDynamic
      filterGroups {
        clauses {
          type
          isAnd
          value
          attribute {
            __typename
            ... on DealAttribute {
              id
              displayName
              columnName
              dataType
            }
            ... on PartnerRole {
              id
              name
            }
            ... on TeamMemberRole {
              id
              name
            }
          }
          filter {
            id
            name
            sign
          }
        }
      }
    }
  }
}
    `;
export const AddTeamMemberDocument = gql`
    mutation AddTeamMember($teamMember: AddTeamMemberInput!) {
  addTeamMember(teamMember: $teamMember) {
    __typename
    ...DealTeamMemberFragment
    ...ErrorFragment
  }
}
    ${DealTeamMemberFragment}
${ErrorFragment}`;
export const CreateTeamMemberRoleDocument = gql`
    mutation CreateTeamMemberRole($teamMemberRole: CreateTeamMemberRoleInput!) {
  createTeamMemberRole(teamMemberRole: $teamMemberRole) {
    __typename
    ... on TeamMemberRole {
      id
      name
      description
    }
    ... on TeamMemberRoleAlreadyExists {
      message
      role {
        id
        name
      }
    }
    ... on ErrorResult {
      message
    }
  }
}
    `;
export const UpdateTeamMemberRoleDocument = gql`
    mutation UpdateTeamMemberRole($teamMemberRole: UpdateTeamMemberRoleInput!) {
  updateTeamMemberRole(teamMemberRole: $teamMemberRole) {
    __typename
    ... on TeamMemberRole {
      id
      name
      description
    }
    ... on TeamMemberRoleAlreadyExists {
      message
      role {
        id
        name
      }
    }
    ... on ErrorResult {
      message
    }
  }
}
    `;
export const DeleteTeamMemberRoleDocument = gql`
    mutation DeleteTeamMemberRole($id: Int!) {
  deleteTeamMemberRole(id: $id) {
    __typename
    ... on TeamMemberRole {
      id
      name
      description
    }
    ... on ErrorResult {
      message
    }
  }
}
    `;
export const CreatePartnerRoleDocument = gql`
    mutation CreatePartnerRole($partnerRole: CreatePartnerRoleInput!) {
  createPartnerRole(partnerRole: $partnerRole) {
    __typename
    ... on PartnerRole {
      id
      name
      description
    }
    ... on AlreadyExists {
      message
    }
    ... on ErrorResult {
      message
    }
  }
}
    `;
export const UpdatePartnerRoleDocument = gql`
    mutation UpdatePartnerRole($partnerRole: UpdatePartnerRoleInput!) {
  updatePartnerRole(partnerRole: $partnerRole) {
    __typename
    ... on PartnerRole {
      id
      name
      description
    }
    ... on AlreadyExists {
      message
    }
    ... on ErrorResult {
      message
    }
  }
}
    `;
export const DeletePartnerRoleDocument = gql`
    mutation DeletePartnerRole($id: Int!) {
  deletePartnerRole(id: $id) {
    __typename
    ... on PartnerRole {
      id
      name
      description
    }
    ... on ErrorResult {
      message
    }
  }
}
    `;
export const CreateContactDocument = gql`
    mutation CreateContact($contact: CreateContactInput!) {
  createContact(contact: $contact) {
    __typename
    ...ContactFragment
    ...ErrorFragment
    ...ContactAlreadyExistsFragment
  }
}
    ${ContactFragment}
${ErrorFragment}
${ContactAlreadyExistsFragment}`;
export const CreateFirmDocument = gql`
    mutation CreateFirm($firm: CreateFirmInput!) {
  createFirm(firm: $firm) {
    __typename
    id
    name
    status {
      id
      name
    }
    type {
      id
      name
    }
    headquartersMsa {
      id
      name
    }
    regions {
      id
      name
      isPrimaryRegion
    }
    productAppetite {
      id
      name
    }
    keyContacts {
      ...ContactFragment
    }
    relationshipLead {
      id
      firstName
      lastName
      displayName
    }
  }
}
    ${ContactFragment}`;
export const UpdateFirmDocument = gql`
    mutation UpdateFirm($firm: UpdateFirmInput!) {
  updateFirm(firm: $firm) {
    __typename
    id
    name
    status {
      id
      name
    }
    type {
      id
      name
    }
    headquartersMsa {
      id
      name
    }
    regions {
      id
      name
      isPrimaryRegion
    }
    productAppetite {
      id
      name
    }
    keyContacts {
      ...ContactFragment
    }
    relationshipLead {
      id
      firstName
      lastName
      displayName
    }
  }
}
    ${ContactFragment}`;
export const DeleteFirmDocument = gql`
    mutation DeleteFirm($id: Int!) {
  deleteFirm(id: $id) {
    __typename
    ... on Firm {
      id
      name
    }
    ... on ErrorResult {
      message
    }
  }
}
    `;
export const CreateFirmRegionDocument = gql`
    mutation CreateFirmRegion($firmRegion: CreateFirmRegionInput!) {
  createFirmRegion(firmRegion: $firmRegion) {
    __typename
    id
    name
    isPrimaryRegion
  }
}
    `;
export const UpdateFirmRegionDocument = gql`
    mutation UpdateFirmRegion($firmRegion: UpdateFirmRegionInput!) {
  updateFirmRegion(firmRegion: $firmRegion) {
    __typename
    ... on FirmRegion {
      id
      name
      isPrimaryRegion
    }
    ... on Error {
      message
    }
  }
}
    `;
export const DeleteFirmRegionDocument = gql`
    mutation DeleteFirmRegion($fkRegion: Int!, $fkFirm: Int!) {
  deleteFirmRegion(fkRegion: $fkRegion, fkFirm: $fkFirm) {
    __typename
    ... on FirmRegion {
      id
      name
      isPrimaryRegion
    }
    ... on Error {
      message
    }
  }
}
    `;
export const CreateInteractionDocument = gql`
    mutation CreateInteraction($interaction: CreateInteractionInput!) {
  createInteraction(interaction: $interaction) {
    id
    name
    interactionDate
    notes
    type {
      id
      name
    }
    attendees {
      id
      name
      type
    }
    deals {
      id
      name
    }
  }
}
    `;
export const UpdateInteractionDocument = gql`
    mutation UpdateInteraction($interaction: UpdateInteractionInput!) {
  updateInteraction(interaction: $interaction) {
    id
    name
    interactionDate
    notes
    type {
      id
      name
    }
    attendees {
      id
      name
      type
    }
    deals {
      id
      name
    }
  }
}
    `;
export const DeleteInteractionDocument = gql`
    mutation DeleteInteraction($id: Int!) {
  deleteInteraction(id: $id) {
    __typename
    id
    name
    interactionDate
    notes
    type {
      id
      name
    }
    attendees {
      id
      name
      type
    }
    deals {
      id
      name
    }
  }
}
    `;
export const AssignContactsDocument = gql`
    mutation AssignContacts($assignment: ContactAssignmentInput!) {
  assignContacts(assignment: $assignment) {
    id
    firstName
    lastName
  }
}
    `;
export const UnassignContactsDocument = gql`
    mutation UnassignContacts($assignment: ContactAssignmentInput!) {
  unassignContacts(assignment: $assignment) {
    id
    firstName
    lastName
  }
}
    `;
export const UpdateTeamMemberDocument = gql`
    mutation UpdateTeamMember($teamMember: UpdateTeamMemberInput!) {
  updateTeamMember(teamMember: $teamMember) {
    user {
      id
      firstName
      lastName
    }
    role {
      id
      name
    }
  }
}
    `;
export const UpdateTeamMembersDocument = gql`
    mutation UpdateTeamMembers($dealId: Int!, $roleId: Int!, $userIds: [Int!]!) {
  updateTeamMembers(dealId: $dealId, roleId: $roleId, userIds: $userIds) {
    user {
      id
      firstName
      lastName
    }
    role {
      id
      name
    }
  }
}
    `;
export const RemoveTeamMemberDocument = gql`
    mutation RemoveTeamMember($teamMember: RemoveTeamMemberInput!) {
  removeTeamMember(teamMember: $teamMember) {
    user {
      id
      firstName
      lastName
    }
    role {
      id
      name
    }
  }
}
    `;
export const AddDealPartnerDocument = gql`
    mutation AddDealPartner($partner: AddDealPartnerInput!) {
  addDealPartner(partner: $partner) {
    __typename
    ...DealPartnerFragment
    ...ErrorFragment
  }
}
    ${DealPartnerFragment}
${ErrorFragment}`;
export const UpdateDealPartnerDocument = gql`
    mutation UpdateDealPartner($partner: UpdateDealPartnerInput!) {
  updateDealPartner(partner: $partner) {
    ...DealPartnerFragment
  }
}
    ${DealPartnerFragment}`;
export const UpdateDealPartnersDocument = gql`
    mutation UpdateDealPartners($dealId: Int!, $roleId: Int!, $firmIds: [Int!]!) {
  updateDealPartners(dealId: $dealId, roleId: $roleId, firmIds: $firmIds) {
    firm {
      id
      name
    }
    contact {
      id
      firstName
      lastName
    }
    role {
      id
      name
      description
    }
  }
}
    `;
export const RemoveDealPartnerDocument = gql`
    mutation RemoveDealPartner($partner: RemoveDealPartnerInput!) {
  removeDealPartner(partner: $partner) {
    ...DealPartnerFragment
  }
}
    ${DealPartnerFragment}`;
export const UpdateContactDocument = gql`
    mutation UpdateContact($contact: UpdateContactInput!) {
  updateContact(contact: $contact) {
    __typename
    ...ContactFragment
    ...ErrorFragment
    ...ContactAlreadyExistsFragment
  }
}
    ${ContactFragment}
${ErrorFragment}
${ContactAlreadyExistsFragment}`;
export const DeleteContactDocument = gql`
    mutation DeleteContact($id: Int!) {
  deleteContact(id: $id) {
    __typename
    ...ContactFragment
    ... on ErrorResult {
      message
    }
  }
}
    ${ContactFragment}`;
export const UpdateDealChecklistitemDocument = gql`
    mutation UpdateDealChecklistitem($checklistItem: UpdateDealChecklistItemInput!) {
  updateDealChecklistItem(checklistItem: $checklistItem) {
    id
    name
    status {
      id
      name
    }
    dueDate
    assignee {
      id
      firstName
      lastName
    }
  }
}
    `;
export const CreateChecklistDocument = gql`
    mutation CreateChecklist($checklist: CreateChecklistInput!) {
  createChecklist(checklist: $checklist) {
    ...ChecklistFragment
    ...ErrorFragment
    ...ChecklistAlreadyExistsFragment
  }
}
    ${ChecklistFragment}
${ErrorFragment}
${ChecklistAlreadyExistsFragment}`;
export const UpdateChecklistDocument = gql`
    mutation UpdateChecklist($checklist: UpdateChecklistInput!) {
  updateChecklist(checklist: $checklist) {
    ...ChecklistFragment
    ...ErrorFragment
    ...ChecklistAlreadyExistsFragment
  }
}
    ${ChecklistFragment}
${ErrorFragment}
${ChecklistAlreadyExistsFragment}`;
export const AddChecklistToDealDocument = gql`
    mutation AddChecklistToDeal($dealChecklist: DealChecklistInput!) {
  addChecklist(dealChecklist: $dealChecklist) {
    ...DealChecklistFragment
    ...ErrorFragment
    ...DealChecklistAlreadyExistsFragment
  }
}
    ${DealChecklistFragment}
${ErrorFragment}
${DealChecklistAlreadyExistsFragment}`;
export const RemoveChecklistFromDealDocument = gql`
    mutation RemoveChecklistFromDeal($dealChecklist: DealChecklistInput!) {
  removeChecklist(dealChecklist: $dealChecklist) {
    ...DealChecklistFragment
    ...ErrorFragment
  }
}
    ${DealChecklistFragment}
${ErrorFragment}`;
export const CreateChecklistItemDocument = gql`
    mutation CreateChecklistItem($checklistItem: CreateChecklistItemInput!) {
  createChecklistItem(checklistItem: $checklistItem) {
    __typename
    ...ChecklistItemFragment
    ...ErrorFragment
    ...ChecklistItemAlreadyExistsFragment
  }
}
    ${ChecklistItemFragment}
${ErrorFragment}
${ChecklistItemAlreadyExistsFragment}`;
export const UpdateChecklistItemDocument = gql`
    mutation UpdateChecklistItem($checklistItem: UpdateChecklistItemInput!) {
  updateChecklistItem(checklistItem: $checklistItem) {
    id
    name
  }
}
    `;
export const DeleteChecklistDocument = gql`
    mutation DeleteChecklist($id: Int!) {
  deleteChecklist(id: $id) {
    id
    name
  }
}
    `;
export const DeleteChecklistItemDocument = gql`
    mutation DeleteChecklistItem($id: Int!) {
  deleteChecklistItem(id: $id) {
    id
    name
  }
}
    `;
export const CreateStatusUpdateDocument = gql`
    mutation CreateStatusUpdate($statusUpdate: CreateStatusUpdateInput!) {
  createStatusUpdate(statusUpdate: $statusUpdate) {
    id
    title
    message
    fkDeal
    status {
      id
      name
      color
    }
  }
}
    `;
export const UpdateStatusUpdateDocument = gql`
    mutation UpdateStatusUpdate($statusUpdate: UpdateStatusUpdateInput!) {
  updateStatusUpdate(statusUpdate: $statusUpdate) {
    id
    title
    message
    fkDeal
    status {
      id
      name
      color
    }
  }
}
    `;
export const DeleteStatusUpdateDocument = gql`
    mutation DeleteStatusUpdate($id: Int!) {
  deleteStatusUpdate(id: $id) {
    id
    title
    message
    status {
      id
      name
      color
    }
  }
}
    `;
export const CreateStatusUpdateTemplateDocument = gql`
    mutation CreateStatusUpdateTemplate($statusUpdateTemplate: CreateStatusUpdateTemplateInput!) {
  createStatusUpdateTemplate(statusUpdateTemplate: $statusUpdateTemplate) {
    id
    title
    message
  }
}
    `;
export const UpdateStatusUpdateTemplateDocument = gql`
    mutation UpdateStatusUpdateTemplate($statusUpdateTemplate: UpdateStatusUpdateTemplateInput!) {
  updateStatusUpdateTemplate(statusUpdateTemplate: $statusUpdateTemplate) {
    id
    title
    message
  }
}
    `;
export const DeleteStatusUpdateTemplateDocument = gql`
    mutation DeleteStatusUpdateTemplate($id: Int!) {
  deleteStatusUpdateTemplate(id: $id) {
    id
    title
    message
  }
}
    `;
export const UpdateDealDocument = gql`
    mutation UpdateDeal($deal: UpdateDealInput!) {
  updateDeal(deal: $deal) {
    __typename
    id
    name
    dealBackground
    earnestMoneyDeposit
    investmentVision
  }
}
    `;
export const CreateChecklistCategoryDocument = gql`
    mutation CreateChecklistCategory($checklistCategory: CreateChecklistCategoryInput!) {
  createChecklistCategory(checklistCategory: $checklistCategory) {
    __typename
    ...ChecklistCategoryFragment
    ...ErrorFragment
    ...ChecklistCategoryAlreadyExistsFragment
  }
}
    ${ChecklistCategoryFragment}
${ErrorFragment}
${ChecklistCategoryAlreadyExistsFragment}`;
export const CreateKeyRiskDocument = gql`
    mutation CreateKeyRisk($keyRisk: CreateKeyRiskInput!, $dealId: Int!) {
  createKeyRisk(keyRisk: $keyRisk, dealId: $dealId) {
    id
    title
    riskDescription
    mitigantDescription
    updatedAt
  }
}
    `;
export const UpdateKeyRiskDocument = gql`
    mutation UpdateKeyRisk($keyRisk: UpdateKeyRiskInput!) {
  updateKeyRisk(keyRisk: $keyRisk) {
    id
    title
    riskDescription
    mitigantDescription
    updatedAt
    orderId
  }
}
    `;
export const DeleteKeyRiskDocument = gql`
    mutation DeleteKeyRisk($id: Int!) {
  deleteKeyRisk(id: $id) {
    id
    title
    riskDescription
    mitigantDescription
    deletedAt
  }
}
    `;
export const CreateDealMeritDocument = gql`
    mutation CreateDealMerit($dealMerit: CreateDealMeritInput!, $dealId: Int!) {
  createMerit(merit: $dealMerit, dealId: $dealId) {
    id
    title
    meritDescription
    updatedAt
  }
}
    `;
export const UpdateDealMeritDocument = gql`
    mutation UpdateDealMerit($dealMerit: UpdateDealMeritInput!) {
  updateMerit(merit: $dealMerit) {
    id
    title
    meritDescription
    updatedAt
  }
}
    `;
export const DeleteDealMeritDocument = gql`
    mutation DeleteDealMerit($id: Int!) {
  deleteMerit(id: $id) {
    id
    title
    meritDescription
    deletedAt
  }
}
    `;
export const ReorderPipelinePinnedMetricDocument = gql`
    mutation ReorderPipelinePinnedMetric($pipelineId: Int!, $id: Int!, $beforeId: Int, $afterId: Int) {
  reorderPipelinePinnedMetric(
    pipelineId: $pipelineId
    id: $id
    beforeId: $beforeId
    afterId: $afterId
  ) {
    __typename
  }
}
    `;
export const ReorderPipelinePinnedEventDocument = gql`
    mutation ReorderPipelinePinnedEvent($pipelineId: Int!, $id: Int!, $beforeId: Int, $afterId: Int) {
  reorderPipelinePinnedEvent(
    pipelineId: $pipelineId
    id: $id
    beforeId: $beforeId
    afterId: $afterId
  ) {
    __typename
  }
}
    `;
export const ReorderPipelinePinnedPartnerRoleDocument = gql`
    mutation ReorderPipelinePinnedPartnerRole($pipelineId: Int!, $id: Int!, $beforeId: Int, $afterId: Int) {
  reorderPipelinePinnedPartnerRole(
    pipelineId: $pipelineId
    id: $id
    beforeId: $beforeId
    afterId: $afterId
  ) {
    __typename
  }
}
    `;
export const ReorderPipelinePinnedTeamMemberRoleDocument = gql`
    mutation ReorderPipelinePinnedTeamMemberRole($pipelineId: Int!, $id: Int!, $beforeId: Int, $afterId: Int) {
  reorderPipelinePinnedTeamMemberRole(
    pipelineId: $pipelineId
    id: $id
    beforeId: $beforeId
    afterId: $afterId
  ) {
    __typename
  }
}
    `;
export const CreatePipelineDealStageDocument = gql`
    mutation CreatePipelineDealStage($name: String!, $pipelineId: Int!) {
  createPipelineDealStage(name: $name, pipelineId: $pipelineId) {
    __typename
    ...PipelineDealStageFragment
    ...PipelineDealStageAlreadyExistsFragment
    ...ErrorFragment
  }
}
    ${PipelineDealStageFragment}
${PipelineDealStageAlreadyExistsFragment}
${ErrorFragment}`;
export const UpdatePipelineDealStageDocument = gql`
    mutation UpdatePipelineDealStage($id: Int!, $name: String!, $isDisabled: Boolean!, $pipelineId: Int!) {
  updatePipelineDealStage(
    id: $id
    name: $name
    isDisabled: $isDisabled
    pipelineId: $pipelineId
  ) {
    __typename
    ...PipelineDealStageFragment
    ...ErrorFragment
    ...PipelineDealStageAlreadyExistsFragment
    ... on ErrorResult {
      message
    }
  }
}
    ${PipelineDealStageFragment}
${ErrorFragment}
${PipelineDealStageAlreadyExistsFragment}`;
export const ReorderPipelineDealStageDocument = gql`
    mutation ReorderPipelineDealStage($id: Int!, $beforeId: Int, $afterId: Int, $pipelineId: Int!) {
  reorderPipelineDealStage(
    id: $id
    beforeId: $beforeId
    afterId: $afterId
    pipelineId: $pipelineId
  ) {
    __typename
    ...PipelineDealStageFragment
    ...ErrorFragment
    ...PipelineDealStageAlreadyExistsFragment
    ... on ErrorResult {
      message
    }
  }
}
    ${PipelineDealStageFragment}
${ErrorFragment}
${PipelineDealStageAlreadyExistsFragment}`;
export const DeletePipelineDealStageDocument = gql`
    mutation DeletePipelineDealStage($id: Int!, $pipelineId: Int!) {
  deletePipelineDealStage(id: $id, pipelineId: $pipelineId) {
    __typename
    ... on DealStage {
      id
      name
      createdAt
      deletedAt
      fkPipeline
    }
    ... on ErrorResult {
      message
    }
  }
}
    `;
export const CreateConfigurableEntityDocument = gql`
    mutation CreateConfigurableEntity($name: String!, $type: ConfigurableEntityEnum!) {
  createConfigurableEntity(name: $name, type: $type) {
    __typename
    ...ConfigurableEntityFragment
    ...ErrorFragment
    ...ConfigurableEntityAlreadyExistsFragment
  }
}
    ${ConfigurableEntityFragment}
${ErrorFragment}
${ConfigurableEntityAlreadyExistsFragment}`;
export const UpdateConfigurableEntityDocument = gql`
    mutation UpdateConfigurableEntity($id: Int!, $name: String!, $isDisabled: Boolean!, $type: ConfigurableEntityEnum!) {
  updateConfigurableEntity(
    id: $id
    name: $name
    isDisabled: $isDisabled
    type: $type
  ) {
    __typename
    ...ConfigurableEntityFragment
    ...ErrorFragment
    ...ConfigurableEntityAlreadyExistsFragment
    ... on ErrorResult {
      message
    }
  }
}
    ${ConfigurableEntityFragment}
${ErrorFragment}
${ConfigurableEntityAlreadyExistsFragment}`;
export const DeleteConfigurableEntityDocument = gql`
    mutation DeleteConfigurableEntity($id: Int!, $type: ConfigurableEntityEnum!) {
  deleteConfigurableEntity(id: $id, type: $type) {
    __typename
    ... on ConfigurableEntity {
      id
      name
      createdAt
      deletedAt
      entityType
    }
    ... on ErrorResult {
      message
    }
  }
}
    `;
export const UpdateOrganizationMemberRoleDocument = gql`
    mutation UpdateOrganizationMemberRole($organizationMemberRole: UpdateOrganizationMemberRoleInput!) {
  updateOrganizationMemberRole(organizationMemberRole: $organizationMemberRole) {
    __typename
    ... on OrganizationRoleUser {
      user {
        firstName
        lastName
      }
      role {
        id
        name
      }
    }
    ... on OrganizationRoleGroup {
      group {
        name
      }
      role {
        id
        name
      }
    }
    ...ErrorFragment
  }
}
    ${ErrorFragment}`;
export const AssignMembersToOrganizationDocument = gql`
    mutation AssignMembersToOrganization($assignment: OrganizationMemberAssignmentInput!) {
  assignMembersToOrganization(assignment: $assignment) {
    membersUpdated
  }
}
    `;
export const RemoveMembersFromOrganizationDocument = gql`
    mutation RemoveMembersFromOrganization($assignment: RemoveOrganizationMemberInput!) {
  removeMembersFromOrganization(assignment: $assignment) {
    membersUpdated
  }
}
    `;
export const UpdateDealMemberRoleDocument = gql`
    mutation UpdateDealMemberRole($dealMemberRole: UpdateDealMemberRoleInput!) {
  updateDealMemberRole(dealMemberRole: $dealMemberRole) {
    ... on DealRoleUser {
      user {
        firstName
        lastName
      }
      role {
        id
        name
      }
    }
    ... on DealRoleGroup {
      group {
        name
      }
      role {
        id
        name
      }
    }
    ...ErrorFragment
  }
}
    ${ErrorFragment}`;
export const AssignMembersToDealDocument = gql`
    mutation AssignMembersToDeal($assignment: DealMemberAssignmentInput!) {
  assignMembersToDeal(assignment: $assignment) {
    membersUpdated
  }
}
    `;
export const RemoveMembersFromDealDocument = gql`
    mutation RemoveMembersFromDeal($assignment: RemoveDealMemberInput!) {
  removeMembersFromDeal(assignment: $assignment) {
    membersUpdated
  }
}
    `;
export const UpdatePipelineMemberRoleDocument = gql`
    mutation UpdatePipelineMemberRole($pipelineMemberRole: UpdatePipelineMemberRoleInput!) {
  updatePipelineMemberRole(pipelineMemberRole: $pipelineMemberRole) {
    ... on PipelineRoleUser {
      user {
        firstName
        lastName
      }
      role {
        id
        name
      }
    }
    ... on PipelineRoleGroup {
      group {
        name
      }
      role {
        id
        name
      }
    }
    ...ErrorFragment
  }
}
    ${ErrorFragment}`;
export const AssignMembersToPipelineDocument = gql`
    mutation AssignMembersToPipeline($assignment: PipelineMemberAssignmentInput!) {
  assignMembersToPipeline(assignment: $assignment) {
    membersUpdated
  }
}
    `;
export const RemoveMembersFromPipelineDocument = gql`
    mutation RemoveMembersFromPipeline($assignment: RemovePipelineMemberInput!) {
  removeMembersFromPipeline(assignment: $assignment) {
    membersUpdated
  }
}
    `;
export const UpdatePortfolioMemberRoleDocument = gql`
    mutation UpdatePortfolioMemberRole($portfolioMemberRole: UpdatePortfolioMemberRoleInput!) {
  updatePortfolioMemberRole(portfolioMemberRole: $portfolioMemberRole) {
    ... on PortfolioRoleUser {
      user {
        firstName
        lastName
      }
      role {
        id
        name
      }
    }
    ... on PortfolioRoleGroup {
      group {
        name
      }
      role {
        id
        name
      }
    }
    ...ErrorFragment
  }
}
    ${ErrorFragment}`;
export const AssignMembersToPortfolioDocument = gql`
    mutation AssignMembersToPortfolio($assignment: PortfolioMemberAssignmentInput!) {
  assignMembersToPortfolio(assignment: $assignment) {
    membersUpdated
  }
}
    `;
export const RemoveMembersFromPortfolioDocument = gql`
    mutation RemoveMembersFromPortfolio($assignment: RemovePortfolioMemberInput!) {
  removeMembersFromPortfolio(assignment: $assignment) {
    membersUpdated
  }
}
    `;
export const UpdatePortfolioViewColumnDocument = gql`
    mutation UpdatePortfolioViewColumn($column: UpdatePortfolioViewColumnInput!) {
  updatePortfolioViewColumn(column: $column) {
    id
    name
    lookupName
    type
    orderId
  }
}
    `;
export const AddPortfolioViewColumnDocument = gql`
    mutation AddPortfolioViewColumn($column: AddPortfolioViewColumnInput!) {
  addPortfolioViewColumn(column: $column) {
    id
    name
    lookupName
    type
    orderId
  }
}
    `;
export const RemovePortfolioViewColumnsDocument = gql`
    mutation RemovePortfolioViewColumns($id: Int!, $columns: [AddPortfolioViewColumnInput!]) {
  removePortfolioViewColumns(id: $id, columns: $columns) {
    columnsRemoved
  }
}
    `;
export const AddPortfolioDealsDocument = gql`
    mutation AddPortfolioDeals($portfolioDeals: UpdatePortfolioDealsInput!) {
  addPortfolioDeals(portfolioDeals: $portfolioDeals) {
    __typename
    ... on Portfolio {
      id
      name
    }
    ... on ErrorResult {
      message
    }
    ... on Error {
      message
    }
  }
}
    `;
export const RemovePortfolioDealsDocument = gql`
    mutation RemovePortfolioDeals($portfolioDeals: UpdatePortfolioDealsInput!) {
  removePortfolioDeals(portfolioDeals: $portfolioDeals) {
    __typename
    ... on Portfolio {
      id
      name
    }
    ... on ErrorResult {
      message
    }
    ... on Error {
      message
    }
  }
}
    `;
export const CreateEventDocument = gql`
    mutation CreateEvent($event: CreateEventInput!) {
  createEvent(event: $event) {
    __typename
    ...EventFragment
    ...EventAlreadyExistsFragment
    ...ErrorFragment
  }
}
    ${EventFragment}
${EventAlreadyExistsFragment}
${ErrorFragment}`;
export const UpdateEventDocument = gql`
    mutation UpdateEvent($event: UpdateEventInput!) {
  updateEvent(event: $event) {
    __typename
    ...EventFragment
    ...EventAlreadyExistsFragment
    ...ErrorFragment
    ... on ErrorResult {
      message
    }
  }
}
    ${EventFragment}
${EventAlreadyExistsFragment}
${ErrorFragment}`;
export const DeleteEventDocument = gql`
    mutation DeleteEvent($id: Int!) {
  deleteEvent(id: $id) {
    __typename
    ...EventFragment
    ... on ErrorResult {
      message
    }
  }
}
    ${EventFragment}`;
export const CreateDealEventDocument = gql`
    mutation CreateDealEvent($event: CreateDealEventInput!) {
  createDealEvent(event: $event) {
    __typename
    ...DealEventFragment
    ...DealEventAlreadyExistsFragment
    ...ErrorFragment
  }
}
    ${DealEventFragment}
${DealEventAlreadyExistsFragment}
${ErrorFragment}`;
export const UpdateDealEventDocument = gql`
    mutation UpdateDealEvent($event: UpdateDealEventInput!) {
  updateDealEvent(event: $event) {
    __typename
    ...DealEventFragment
    ...DealEventAlreadyExistsFragment
    ...ErrorFragment
  }
}
    ${DealEventFragment}
${DealEventAlreadyExistsFragment}
${ErrorFragment}`;
export const CreateAnalysisDocument = gql`
    mutation CreateAnalysis($analysis: CreateAnalysisInput!) {
  createAnalysis(analysis: $analysis) {
    __typename
    ...DealAnalysisFragment
  }
}
    ${DealAnalysisFragment}`;
export const UpdateAnalysisDocument = gql`
    mutation UpdateAnalysis($analysis: UpdateAnalysisInput!) {
  updateAnalysis(analysis: $analysis) {
    __typename
    ...DealAnalysisFragment
  }
}
    ${DealAnalysisFragment}`;
export const DeleteAnalysisDocument = gql`
    mutation DeleteAnalysis($id: Int!) {
  deleteAnalysis(id: $id) {
    __typename
    ...DealAnalysisFragment
    ... on ErrorResult {
      message
    }
  }
}
    ${DealAnalysisFragment}`;
export const CreateAnalysisTypeDocument = gql`
    mutation CreateAnalysisType($analysisType: CreateAnalysisTypeInput!) {
  createAnalysisType(analysisType: $analysisType) {
    __typename
    ...AnalysisTypeFragment
    ...AnalysisTypeAlreadyExistsFragment
    ...ErrorFragment
  }
}
    ${AnalysisTypeFragment}
${AnalysisTypeAlreadyExistsFragment}
${ErrorFragment}`;
export const UpdateAnalysisTypeDocument = gql`
    mutation UpdateAnalysisType($analysisType: UpdateAnalysisTypeInput!) {
  updateAnalysisType(analysisType: $analysisType) {
    __typename
    ...AnalysisTypeFragment
    ...AnalysisTypeAlreadyExistsFragment
    ...ErrorFragment
    ... on ErrorResult {
      message
    }
  }
}
    ${AnalysisTypeFragment}
${AnalysisTypeAlreadyExistsFragment}
${ErrorFragment}`;
export const DeleteAnalysisTypeDocument = gql`
    mutation DeleteAnalysisType($id: Int!) {
  deleteAnalysisType(id: $id) {
    __typename
    ...AnalysisTypeFragment
    ... on ErrorResult {
      message
    }
  }
}
    ${AnalysisTypeFragment}`;
export const CreateAnalysisMetricDocument = gql`
    mutation CreateAnalysisMetric($metric: CreateAnalysisMetricInput!) {
  createAnalysisMetric(metric: $metric) {
    id
    value
    metric {
      id
      name
    }
  }
}
    `;
export const UpdateAnalysisMetricDocument = gql`
    mutation UpdateAnalysisMetric($metric: UpdateAnalysisMetricInput!) {
  updateAnalysisMetric(metric: $metric) {
    id
    value
    metric {
      id
      name
    }
  }
}
    `;
export const DeleteAnalysisMetricDocument = gql`
    mutation DeleteAnalysisMetric($id: Int!) {
  deleteAnalysisMetric(id: $id) {
    id
    value
    metric {
      id
      name
    }
  }
}
    `;
export const CreateAnalysisTextMetricDocument = gql`
    mutation CreateAnalysisTextMetric($textMetric: CreateAnalysisTextMetricInput!) {
  createAnalysisTextMetric(textMetric: $textMetric) {
    id
    value
    textMetric {
      id
      name
    }
  }
}
    `;
export const UpdateAnalysisTextMetricDocument = gql`
    mutation UpdateAnalysisTextMetric($textMetric: UpdateAnalysisTextMetricInput!) {
  updateAnalysisTextMetric(textMetric: $textMetric) {
    id
    value
    textMetric {
      id
      name
    }
  }
}
    `;
export const DeleteAnalysisTextMetricDocument = gql`
    mutation DeleteAnalysisTextMetric($id: Int!) {
  deleteAnalysisTextMetric(id: $id) {
    id
    value
    textMetric {
      id
      name
    }
  }
}
    `;
export const CreateAnalysisEventDocument = gql`
    mutation CreateAnalysisEvent($event: CreateAnalysisEventInput!) {
  createAnalysisEvent(event: $event) {
    id
    startDate
    event {
      id
      name
    }
  }
}
    `;
export const UpdateAnalysisEventDocument = gql`
    mutation UpdateAnalysisEvent($event: UpdateAnalysisEventInput!) {
  updateAnalysisEvent(event: $event) {
    id
    startDate
    event {
      id
      name
    }
  }
}
    `;
export const DeleteAnalysisEventDocument = gql`
    mutation DeleteAnalysisEvent($id: Int!) {
  deleteAnalysisEvent(id: $id) {
    id
    startDate
    event {
      id
      name
    }
  }
}
    `;
export const DuplicateAnalysisDocument = gql`
    mutation DuplicateAnalysis($id: Int!) {
  duplicateAnalysis(id: $id) {
    id
    description
    scenario {
      id
      name
    }
    archiveDate
  }
}
    `;
export const ArchiveAnalysisDocument = gql`
    mutation ArchiveAnalysis($id: Int!) {
  archiveAnalysis(id: $id) {
    id
    description
    archiveDate
    scenario {
      id
      name
    }
  }
}
    `;
export const CreateContractDocument = gql`
    mutation CreateContract($contract: CreateContractInput!) {
  createContract(contract: $contract) {
    id
    name
    description
    vendor {
      id
      name
    }
    transactions {
      id
      description
      amount
    }
  }
}
    `;
export const UpdateContractDocument = gql`
    mutation UpdateContract($contract: UpdateContractInput!) {
  updateContract(contract: $contract) {
    id
    name
    description
    vendor {
      id
      name
    }
    transactions {
      id
      description
      amount
    }
  }
}
    `;
export const DeleteContractDocument = gql`
    mutation DeleteContract($id: Int!) {
  deleteContract(id: $id) {
    id
    name
    description
    vendor {
      id
      name
    }
    transactions {
      id
      description
      amount
    }
  }
}
    `;
export const PublishCapitalForecastDocument = gql`
    mutation PublishCapitalForecast($capitalForecast: CreatePublishedCapitalForecastInput!) {
  publishCapitalForecast(capitalForecast: $capitalForecast) {
    id
    name
    comment
  }
}
    `;
export const DeletePublishedCapitalForecastDocument = gql`
    mutation DeletePublishedCapitalForecast($id: Int!) {
  deletePublishedCapitalForecast(id: $id) {
    id
    name
    comment
  }
}
    `;
export const CreateRevisionDocument = gql`
    mutation CreateRevision($revision: CreateRevisionInput!) {
  createBudgetRevision(revision: $revision) {
    id
    name
    details {
      id
      amount
      isPursuit
      account {
        id
        name
        code
        displayCode
      }
    }
  }
}
    `;
export const UpdateRevisionDocument = gql`
    mutation UpdateRevision($revision: UpdateRevisionInput!) {
  updateBudgetRevision(revision: $revision) {
    id
    name
    details {
      id
      amount
      isPursuit
      account {
        id
        name
        code
        displayCode
      }
    }
  }
}
    `;
export const DeleteRevisionDocument = gql`
    mutation DeleteRevision($id: Int!) {
  deleteBudgetRevision(id: $id) {
    id
    name
    details {
      id
      amount
      isPursuit
      account {
        id
        name
        code
        displayCode
      }
    }
  }
}
    `;
export const CreateRevisionCostAccountDocument = gql`
    mutation CreateRevisionCostAccount($revisionCostAccount: CreateRevisionAccountInput!) {
  createBudgetRevisionCostAccount(revisionCostAccount: $revisionCostAccount) {
    id
    amount
    isPursuit
    account {
      id
      name
      code
      displayCode
    }
  }
}
    `;
export const UpdateRevisionCostAccountDocument = gql`
    mutation UpdateRevisionCostAccount($revisionCostAccount: UpdateRevisionAccountInput!) {
  updateBudgetRevisionCostAccount(revisionCostAccount: $revisionCostAccount) {
    id
    amount
    isPursuit
    account {
      id
      name
      code
      displayCode
    }
  }
}
    `;
export const DeleteRevisionCostAccountsDocument = gql`
    mutation DeleteRevisionCostAccounts($revisionId: Int!, $ids: [Int!]!) {
  deleteBudgetRevisionCostAccounts(revisionId: $revisionId, ids: $ids) {
    id
    amount
    isPursuit
    account {
      id
      name
      code
      displayCode
    }
  }
}
    `;
export const CreatePortfolioViewDocument = gql`
    mutation CreatePortfolioView($portfolioView: CreatePortfolioViewInput!) {
  createPortfolioView(portfolioView: $portfolioView) {
    ...PortfolioViewFragment
  }
}
    ${PortfolioViewFragment}`;
export const UpdatePortfolioViewDocument = gql`
    mutation UpdatePortfolioView($portfolioView: UpdatePortfolioViewInput!) {
  updatePortfolioView(portfolioView: $portfolioView) {
    ...PortfolioViewFragment
  }
}
    ${PortfolioViewFragment}`;
export const DeletePortfolioViewDocument = gql`
    mutation DeletePortfolioView($id: Int!) {
  deletePortfolioView(id: $id) {
    ...PortfolioViewFragment
  }
}
    ${PortfolioViewFragment}`;
export const CreateCommentDocument = gql`
    mutation CreateComment($comment: CreateCommentInput!, $subjectType: CommentSubjectEnum!) {
  createComment(comment: $comment, subjectType: $subjectType) {
    ...CommentFragment
  }
}
    ${CommentFragment}`;
export const UpdateCommentDocument = gql`
    mutation UpdateComment($comment: UpdateCommentInput!) {
  updateComment(comment: $comment) {
    ...CommentFragment
  }
}
    ${CommentFragment}`;
export const DeleteCommentDocument = gql`
    mutation DeleteComment($id: Int!, $subjectType: CommentSubjectEnum!) {
  deleteComment(id: $id, subjectType: $subjectType) {
    ...CommentFragment
  }
}
    ${CommentFragment}`;
export const GenerateTransactionDetailPreviewDocument = gql`
    mutation GenerateTransactionDetailPreview($transaction: CreateTransactionInput!) {
  generateTransactionDetailPreview(transaction: $transaction) {
    date
    amount
    type
  }
}
    `;
export const CreateTransactionDocument = gql`
    mutation CreateTransaction($transaction: CreateTransactionInput!, $details: [CreateTransactionDetailInput!]!) {
  createTransaction(transaction: $transaction, details: $details) {
    id
    amount
    createdAt
    updatedAt
    startDate
    endDate
    createdBy {
      id
      displayName
    }
    updatedBy {
      id
      displayName
    }
    account {
      id
      name
      code
      displayCode
    }
    description
    vendor {
      id
      name
    }
    contract {
      id
      name
    }
    distributionType {
      id
      name
    }
  }
}
    `;
export const UpdateTransactionDocument = gql`
    mutation UpdateTransaction($transaction: UpdateTransactionInput!, $details: [CreateTransactionDetailInput!]!) {
  updateTransaction(transaction: $transaction, details: $details) {
    id
    amount
    createdAt
    updatedAt
    startDate
    endDate
    createdBy {
      id
      displayName
    }
    updatedBy {
      id
      displayName
    }
    account {
      id
      name
      code
      displayCode
    }
    description
    vendor {
      id
      name
    }
    contract {
      id
      name
    }
    distributionType {
      id
      name
    }
  }
}
    `;
export const UpdateTransactionsDocument = gql`
    mutation UpdateTransactions($input: [UpdateTransactionWithDetailsInput!]!, $fkDeal: Int!) {
  updateTransactions(input: $input, fkDeal: $fkDeal) {
    id
    amount
    createdAt
    updatedAt
    startDate
    endDate
    createdBy {
      id
      displayName
    }
    updatedBy {
      id
      displayName
    }
    account {
      id
      name
      code
      displayCode
    }
    description
    vendor {
      id
      name
    }
    contract {
      id
      name
    }
    distributionType {
      id
      name
    }
  }
}
    `;
export const DeleteTransactionDocument = gql`
    mutation DeleteTransaction($id: Int!) {
  deleteTransaction(id: $id) {
    id
    amount
    createdAt
    updatedAt
    startDate
    endDate
    createdBy {
      id
      displayName
    }
    updatedBy {
      id
      displayName
    }
    account {
      id
      name
      code
      displayCode
    }
    description
    vendor {
      id
      name
    }
    contract {
      id
      name
    }
    distributionType {
      id
      name
    }
  }
}
    `;
export const DeletePortfolioDocument = gql`
    mutation DeletePortfolio($id: Int!) {
  deletePortfolio(id: $id) {
    ... on Portfolio {
      id
      name
    }
  }
}
    `;
export const DeleteDealDocument = gql`
    mutation DeleteDeal($id: Int!) {
  deleteDeal(id: $id) {
    id
    name
  }
}
    `;
export const ArchiveDealDocument = gql`
    mutation ArchiveDeal($id: Int!, $status: DealArchiveStatusEnum!, $reason: String) {
  archiveDeal(id: $id, status: $status, reason: $reason) {
    id
    name
    archiveStatus
    archiveReason
    archiveDate
  }
}
    `;
export const UnarchiveDealDocument = gql`
    mutation UnarchiveDeal($id: Int!) {
  unarchiveDeal(id: $id) {
    id
    name
    archiveStatus
    archiveReason
    archiveDate
  }
}
    `;
export const CreateGroupDocument = gql`
    mutation CreateGroup($group: CreateGroupInput!) {
  createGroup(group: $group) {
    id
    name
    createdAt
    updatedAt
    memberCount
  }
}
    `;
export const UpdateGroupDocument = gql`
    mutation UpdateGroup($group: UpdateGroupInput!) {
  updateGroup(group: $group) {
    id
    name
    createdAt
    updatedAt
    memberCount
  }
}
    `;
export const DeleteGroupDocument = gql`
    mutation DeleteGroup($id: Int!) {
  deleteGroup(id: $id) {
    id
    name
    createdAt
    updatedAt
    memberCount
  }
}
    `;
export const RemoveUsersfromGroupDocument = gql`
    mutation RemoveUsersfromGroup($groupId: Int!, $userIds: [Int!]!) {
  removeUsersFromGroup(groupId: $groupId, userIds: $userIds) {
    id
    name
    createdAt
    updatedAt
    memberCount
  }
}
    `;
export const AddUsersToGroupDocument = gql`
    mutation AddUsersToGroup($groupId: Int!, $userIds: [Int!]!) {
  addUsersToGroup(groupId: $groupId, userIds: $userIds) {
    id
    name
    createdAt
    updatedAt
    memberCount
  }
}
    `;
export const AddEventsToDealDocument = gql`
    mutation AddEventsToDeal($dealId: Int!, $eventIds: [Int!]!) {
  addEventsToDeal(dealId: $dealId, eventIds: $eventIds) {
    count
  }
}
    `;
export const RemoveEventsFromDealDocument = gql`
    mutation RemoveEventsFromDeal($dealId: Int!, $eventIds: [Int!]!) {
  removeEventsFromDeal(dealId: $dealId, eventIds: $eventIds) {
    count
  }
}
    `;
export const CreateMetricDocument = gql`
    mutation CreateMetric($metric: CreateMetricInput!) {
  createMetric(metric: $metric) {
    __typename
    ...MetricFragment
    ...MetricAlreadyExistsFragment
    ...ErrorFragment
  }
}
    ${MetricFragment}
${MetricAlreadyExistsFragment}
${ErrorFragment}`;
export const UpdateMetricDocument = gql`
    mutation UpdateMetric($metric: UpdateMetricInput!) {
  updateMetric(metric: $metric) {
    __typename
    ...MetricFragment
    ...MetricAlreadyExistsFragment
    ...ErrorFragment
    ... on ErrorResult {
      message
    }
  }
}
    ${MetricFragment}
${MetricAlreadyExistsFragment}
${ErrorFragment}`;
export const DeleteMetricDocument = gql`
    mutation DeleteMetric($id: Int!) {
  deleteMetric(id: $id) {
    __typename
    ...MetricFragment
    ... on ErrorResult {
      message
    }
  }
}
    ${MetricFragment}`;
export const CreateTextMetricDocument = gql`
    mutation CreateTextMetric($textMetric: CreateTextMetricInput!) {
  createTextMetric(textMetric: $textMetric) {
    __typename
    ...TextMetricFragment
    ...TextMetricAlreadyExistsFragment
    ...ErrorFragment
  }
}
    ${TextMetricFragment}
${TextMetricAlreadyExistsFragment}
${ErrorFragment}`;
export const UpdateTextMetricDocument = gql`
    mutation UpdateTextMetric($textMetric: UpdateTextMetricInput!) {
  updateTextMetric(textMetric: $textMetric) {
    __typename
    ...TextMetricFragment
    ...TextMetricAlreadyExistsFragment
    ...ErrorFragment
    ... on ErrorResult {
      message
    }
  }
}
    ${TextMetricFragment}
${TextMetricAlreadyExistsFragment}
${ErrorFragment}`;
export const DeleteTextMetricDocument = gql`
    mutation DeleteTextMetric($id: Int!) {
  deleteTextMetric(id: $id) {
    __typename
    ...TextMetricFragment
    ... on ErrorResult {
      message
    }
  }
}
    ${TextMetricFragment}`;
export const CreatePipelineDocument = gql`
    mutation CreatePipeline($input: CreatePipelineInput!) {
  createPipeline(input: $input) {
    id
    name
    description
  }
}
    `;
export const UpdatePipelineDocument = gql`
    mutation UpdatePipeline($input: UpdatePipelineInput!) {
  updatePipeline(input: $input) {
    id
    name
    description
  }
}
    `;
export const DeletePipelineDocument = gql`
    mutation DeletePipeline($id: Int!) {
  deletePipeline(id: $id) {
    __typename
    ... on Pipeline {
      id
      name
      description
    }
    ... on ErrorResult {
      message
    }
  }
}
    `;
export const AddChecklistsToPipelineDocument = gql`
    mutation AddChecklistsToPipeline($id: Int!, $checklistIds: [Int!]!) {
  addChecklistsToPipeline(id: $id, checklistIds: $checklistIds) {
    count
  }
}
    `;
export const RemoveChecklistsFromPipelineDocument = gql`
    mutation RemoveChecklistsFromPipeline($id: Int!, $checklistIds: [Int!]!) {
  removeChecklistsFromPipeline(id: $id, checklistIds: $checklistIds) {
    count
  }
}
    `;
export const AddPinnedMetricsToPipelineDocument = gql`
    mutation AddPinnedMetricsToPipeline($id: Int!, $metricIds: [Int!]!) {
  addPinnedMetricsToPipeline(id: $id, metricIds: $metricIds) {
    count
  }
}
    `;
export const RemovePinnedMetricsFromPipelineDocument = gql`
    mutation RemovePinnedMetricsFromPipeline($id: Int!, $metricIds: [Int!]!) {
  removePinnedMetricsFromPipeline(id: $id, metricIds: $metricIds) {
    count
  }
}
    `;
export const AddPinnedEventsToPipelineDocument = gql`
    mutation AddPinnedEventsToPipeline($id: Int!, $eventIds: [Int!]!) {
  addPinnedEventsToPipeline(id: $id, eventIds: $eventIds) {
    count
  }
}
    `;
export const RemovePinnedEventsFromPipelineDocument = gql`
    mutation RemovePinnedEventsFromPipeline($id: Int!, $eventIds: [Int!]!) {
  removePinnedEventsFromPipeline(id: $id, eventIds: $eventIds) {
    count
  }
}
    `;
export const AddPinnedPartnerRolesToPipelineDocument = gql`
    mutation AddPinnedPartnerRolesToPipeline($id: Int!, $roleIds: [Int!]!) {
  addPinnedPartnerRolesToPipeline(id: $id, roleIds: $roleIds) {
    count
  }
}
    `;
export const RemovePinnedPartnerRolesFromPipelineDocument = gql`
    mutation RemovePinnedPartnerRolesFromPipeline($id: Int!, $roleIds: [Int!]!) {
  removePinnedPartnerRolesFromPipeline(id: $id, roleIds: $roleIds) {
    count
  }
}
    `;
export const AddPinnedTeamMemberRolesToPipelineDocument = gql`
    mutation AddPinnedTeamMemberRolesToPipeline($id: Int!, $roleIds: [Int!]!) {
  addPinnedTeamMemberRolesToPipeline(id: $id, roleIds: $roleIds) {
    count
  }
}
    `;
export const RemovePinnedTeamMemberRolesFromPipelineDocument = gql`
    mutation RemovePinnedTeamMemberRolesFromPipeline($id: Int!, $roleIds: [Int!]!) {
  removePinnedTeamMemberRolesFromPipeline(id: $id, roleIds: $roleIds) {
    count
  }
}
    `;
export const AddEventsToPipelineDocument = gql`
    mutation AddEventsToPipeline($id: Int!, $eventIds: [Int!]!) {
  addEventsToPipeline(id: $id, eventIds: $eventIds) {
    count
  }
}
    `;
export const RemoveEventsFromPipelineDocument = gql`
    mutation RemoveEventsFromPipeline($id: Int!, $eventIds: [Int!]!) {
  removeEventsFromPipeline(id: $id, eventIds: $eventIds) {
    count
  }
}
    `;
export const AddModulesToPipelineDocument = gql`
    mutation AddModulesToPipeline($id: Int!, $moduleIds: [Int!]!) {
  addModulesToPipeline(id: $id, moduleIds: $moduleIds) {
    count
  }
}
    `;
export const RemoveModulesFromPipelineDocument = gql`
    mutation RemoveModulesFromPipeline($id: Int!, $moduleIds: [Int!]!) {
  removeModulesFromPipeline(id: $id, moduleIds: $moduleIds) {
    count
  }
}
    `;
export const AddForecastRulesToPipelineDocument = gql`
    mutation AddForecastRulesToPipeline($id: Int!, $forecastRuleIds: [Int!]!) {
  addForecastRulesToPipeline(id: $id, forecastRuleIds: $forecastRuleIds) {
    count
  }
}
    `;
export const RemoveForecastRulesFromPipelineDocument = gql`
    mutation RemoveForecastRulesFromPipeline($id: Int!, $forecastRuleIds: [Int!]!) {
  removeForecastRulesFromPipeline(id: $id, forecastRuleIds: $forecastRuleIds) {
    count
  }
}
    `;
export const EnableForecastRulesDocument = gql`
    mutation EnableForecastRules($fkDeal: Int!, $forecastRules: [Int!]!) {
  enableForecastRules(fkDeal: $fkDeal, forecastRules: $forecastRules)
}
    `;
export const DisableForecastRulesDocument = gql`
    mutation DisableForecastRules($fkDeal: Int!, $forecastRules: [Int!]!) {
  disableForecastRules(fkDeal: $fkDeal, forecastRules: $forecastRules)
}
    `;
export const PublishScheduleInstanceDocument = gql`
    mutation PublishScheduleInstance($instance: CreatePublishedScheduleInstanceInput!) {
  publishScheduleInstance(instance: $instance) {
    ...PublishedScheduleIntanceFragment
  }
}
    ${PublishedScheduleIntanceFragment}`;
export const CreateScheduleInstanceDocument = gql`
    mutation CreateScheduleInstance($instance: CreatePublishedScheduleInstanceInput!) {
  createScheduleInstance(instance: $instance) {
    ...PublishedScheduleIntanceFragment
  }
}
    ${PublishedScheduleIntanceFragment}`;
export const UpdateScheduleInstanceDocument = gql`
    mutation UpdateScheduleInstance($instance: UpdatePublishedScheduleInstanceInput!) {
  updateScheduleInstance(instance: $instance) {
    ...PublishedScheduleIntanceFragment
  }
}
    ${PublishedScheduleIntanceFragment}`;
export const DeleteScheduleInstanceDocument = gql`
    mutation DeleteScheduleInstance($id: Int!) {
  deleteScheduleInstance(id: $id) {
    ...PublishedScheduleIntanceFragment
  }
}
    ${PublishedScheduleIntanceFragment}`;
export const CreatePublishedDealEventDocument = gql`
    mutation CreatePublishedDealEvent($event: CreatePublishedDealEventInput!) {
  createPublishedDealEvent(event: $event) {
    __typename
    ...PublishedDealEventFragment
    ...PublishedDealEventAlreadyExistsFragment
    ...ErrorFragment
  }
}
    ${PublishedDealEventFragment}
${PublishedDealEventAlreadyExistsFragment}
${ErrorFragment}`;
export const UpdatePublishedDealEventDocument = gql`
    mutation UpdatePublishedDealEvent($event: UpdatePublishedDealEventInput!) {
  updatePublishedDealEvent(event: $event) {
    __typename
    ...PublishedDealEventFragment
    ...PublishedDealEventAlreadyExistsFragment
    ...ErrorFragment
  }
}
    ${PublishedDealEventFragment}
${PublishedDealEventAlreadyExistsFragment}
${ErrorFragment}`;
export const CreateBuildingDocument = gql`
    mutation CreateBuilding($building: CreateBuildingInput!) {
  createBuilding(building: $building) {
    ...BuildingFragment
  }
}
    ${BuildingFragment}`;
export const UpdateBuildingDocument = gql`
    mutation UpdateBuilding($building: UpdateBuildingInput!) {
  updateBuilding(building: $building) {
    ...BuildingFragment
  }
}
    ${BuildingFragment}`;
export const DeleteBuildingDocument = gql`
    mutation DeleteBuilding($id: Int!) {
  deleteBuilding(id: $id) {
    ...BuildingFragment
  }
}
    ${BuildingFragment}`;
export const CreateBondDocument = gql`
    mutation CreateBond($bond: CreateBondInput!) {
  createBond(bond: $bond) {
    ...BondFragment
  }
}
    ${BondFragment}`;
export const UpdateBondDocument = gql`
    mutation UpdateBond($bond: UpdateBondInput!) {
  updateBond(bond: $bond) {
    ...BondFragment
  }
}
    ${BondFragment}`;
export const DeleteBondDocument = gql`
    mutation DeleteBond($id: Int!) {
  deleteBond(id: $id) {
    ...BondFragment
  }
}
    ${BondFragment}`;
export const CreateGcEstimateDocument = gql`
    mutation CreateGcEstimate($gcEstimate: CreateGcEstimateInput!) {
  createGcEstimate(gcEstimate: $gcEstimate) {
    ...GcEstimateFragment
  }
}
    ${GcEstimateFragment}`;
export const UpdateGcEstimateDocument = gql`
    mutation UpdateGcEstimate($gcEstimate: UpdateGcEstimateInput!) {
  updateGcEstimate(gcEstimate: $gcEstimate) {
    ...GcEstimateFragment
  }
}
    ${GcEstimateFragment}`;
export const DeleteGcEstimateDocument = gql`
    mutation DeleteGcEstimate($id: Int!) {
  deleteGcEstimate(id: $id) {
    ...GcEstimateFragment
  }
}
    ${GcEstimateFragment}`;
export const CreateGlAccountDocument = gql`
    mutation CreateGlAccount($account: CreateGlAccountInput!) {
  createGlAccount(account: $account) {
    __typename
    ...GlAccountFragment
    ...ErrorFragment
  }
}
    ${GlAccountFragment}
${ErrorFragment}`;
export const UpdateGlAccountDocument = gql`
    mutation UpdateGlAccount($account: UpdateGlAccountInput!) {
  updateGlAccount(account: $account) {
    __typename
    ...GlAccountFragment
    ...ErrorFragment
    ... on ErrorResult {
      message
    }
  }
}
    ${GlAccountFragment}
${ErrorFragment}`;
export const DeleteGlAccountDocument = gql`
    mutation DeleteGlAccount($id: Int!) {
  deleteGlAccount(id: $id) {
    __typename
    ...GlAccountFragment
    ... on ErrorResult {
      message
    }
  }
}
    ${GlAccountFragment}`;
export const CreateGlAccountTreeDocument = gql`
    mutation CreateGlAccountTree($accountTree: CreateGlAccountTreeInput!) {
  createGlAccountTree(accountTree: $accountTree) {
    __typename
    ...GlAccountTreeFragment
    ...ErrorFragment
  }
}
    ${GlAccountTreeFragment}
${ErrorFragment}`;
export const UpdateGlAccountTreeDocument = gql`
    mutation UpdateGlAccountTree($accountTree: UpdateGlAccountTreeInput!) {
  updateGlAccountTree(accountTree: $accountTree) {
    __typename
    ...GlAccountTreeFragment
    ...ErrorFragment
    ... on ErrorResult {
      message
    }
  }
}
    ${GlAccountTreeFragment}
${ErrorFragment}`;
export const DeleteGlAccountTreeDocument = gql`
    mutation DeleteGlAccountTree($id: Int!) {
  deleteGlAccountTree(id: $id) {
    __typename
    ...GlAccountTreeFragment
    ... on ErrorResult {
      message
    }
  }
}
    ${GlAccountTreeFragment}`;
export const CreatePipelineContingencyCategoryDocument = gql`
    mutation CreatePipelineContingencyCategory($accountCategorization: CreatePipelineContingencyCategoryInput!) {
  createPipelineContingencyCategory(accountCategorization: $accountCategorization) {
    __typename
    ...PipelineContingencyCategoryFragment
  }
}
    ${PipelineContingencyCategoryFragment}`;
export const UpdatePipelineContingencyCategoryDocument = gql`
    mutation UpdatePipelineContingencyCategory($accountCategorization: UpdatePipelineContingencyCategoryInput!) {
  updatePipelineContingencyCategory(accountCategorization: $accountCategorization) {
    __typename
    ...PipelineContingencyCategoryFragment
  }
}
    ${PipelineContingencyCategoryFragment}`;
export const DeletePipelineContingencyCategoryDocument = gql`
    mutation DeletePipelineContingencyCategory($id: Int!) {
  deletePipelineContingencyCategory(id: $id) {
    __typename
    ...PipelineContingencyCategoryFragment
  }
}
    ${PipelineContingencyCategoryFragment}`;
export const CreatePipelineGeneralOffsetDocument = gql`
    mutation CreatePipelineGeneralOffset($generalOffsetRule: CreatePipelineGeneralOffsetInput!) {
  createPipelineGeneralOffset(generalOffsetRule: $generalOffsetRule) {
    __typename
    ...PipelineGeneralOffsetFragment
  }
}
    ${PipelineGeneralOffsetFragment}`;
export const UpdatePipelineGeneralOffsetDocument = gql`
    mutation UpdatePipelineGeneralOffset($generalOffsetRule: UpdatePipelineGeneralOffsetInput!) {
  updatePipelineGeneralOffset(generalOffsetRule: $generalOffsetRule) {
    __typename
    ...PipelineGeneralOffsetFragment
  }
}
    ${PipelineGeneralOffsetFragment}`;
export const DeletePipelineGeneralOffsetDocument = gql`
    mutation DeletePipelineGeneralOffset($id: Int!) {
  deletePipelineGeneralOffset(id: $id) {
    __typename
    ...PipelineGeneralOffsetFragment
  }
}
    ${PipelineGeneralOffsetFragment}`;
export const CreatePipelineAccountOffsetDocument = gql`
    mutation CreatePipelineAccountOffset($accountOffsetRule: CreatePipelineAccountOffsetInput!) {
  createPipelineAccountOffset(accountOffsetRule: $accountOffsetRule) {
    __typename
    ...PipelineAccountOffsetFragment
  }
}
    ${PipelineAccountOffsetFragment}`;
export const UpdatePipelineAccountOffsetDocument = gql`
    mutation UpdatePipelineAccountOffset($accountOffsetRule: UpdatePipelineAccountOffsetInput!) {
  updatePipelineAccountOffset(accountOffsetRule: $accountOffsetRule) {
    __typename
    ...PipelineAccountOffsetFragment
  }
}
    ${PipelineAccountOffsetFragment}`;
export const DeletePipelineAccountOffsetDocument = gql`
    mutation DeletePipelineAccountOffset($id: Int!) {
  deletePipelineAccountOffset(id: $id) {
    __typename
    ...PipelineAccountOffsetFragment
  }
}
    ${PipelineAccountOffsetFragment}`;
export const CreateForecastRuleDocument = gql`
    mutation CreateForecastRule($forecastRule: CreateForecastRuleInput!) {
  createForecastRule(forecastRule: $forecastRule) {
    __typename
    ...ForecastRuleFragment
  }
}
    ${ForecastRuleFragment}`;
export const UpdateForecastRuleDocument = gql`
    mutation UpdateForecastRule($forecastRule: UpdateForecastRuleInput!) {
  updateForecastRule(forecastRule: $forecastRule) {
    __typename
    ...ForecastRuleFragment
  }
}
    ${ForecastRuleFragment}`;
export const DeleteForecastRuleDocument = gql`
    mutation DeleteForecastRule($id: Int!) {
  deleteForecastRule(id: $id) {
    __typename
    ...ForecastRuleFragment
  }
}
    ${ForecastRuleFragment}`;
export const AddAccountsToAccountTreeDocument = gql`
    mutation AddAccountsToAccountTree($id: Int!, $parentId: Int, $accounts: [Int!]!) {
  addAccountsToAccountTree(id: $id, parentId: $parentId, accounts: $accounts) {
    id
    level
    fkParentBranch
    account {
      id
      name
      code
      displayCode
    }
    reverseFromDrCr
  }
}
    `;
export const RemoveAccountsFromAccountTreeDocument = gql`
    mutation RemoveAccountsFromAccountTree($id: Int!, $accounts: [Int!]!) {
  removeAccountsFromAccountTree(id: $id, accounts: $accounts) {
    id
    level
    fkParentBranch
    account {
      id
      name
      code
      displayCode
    }
    reverseFromDrCr
  }
}
    `;
export const ChangeAccountTreeParentDocument = gql`
    mutation ChangeAccountTreeParent($id: Int!, $parentId: Int, $accounts: [Int!]!) {
  changeAccountTreeParent(id: $id, parentId: $parentId, accounts: $accounts) {
    id
    level
    fkParentBranch
    account {
      id
      name
      code
      displayCode
    }
    reverseFromDrCr
  }
}
    `;
export const CreateChangeEventDocument = gql`
    mutation CreateChangeEvent($changeEvent: CreateChangeEventInput!) {
  createChangeEvent(changeEvent: $changeEvent) {
    ...ChangeEventFragment
  }
}
    ${ChangeEventFragment}`;
export const UpdateChangeEventDocument = gql`
    mutation UpdateChangeEvent($changeEvent: UpdateChangeEventInput!) {
  updateChangeEvent(changeEvent: $changeEvent) {
    ...ChangeEventFragment
  }
}
    ${ChangeEventFragment}`;
export const DeleteChangeEventDocument = gql`
    mutation DeleteChangeEvent($id: Int!) {
  deleteChangeEvent(id: $id) {
    ...ChangeEventFragment
  }
}
    ${ChangeEventFragment}`;
export const CreatePotentialChangeOrderDocument = gql`
    mutation CreatePotentialChangeOrder($potentialChangeOrder: CreatePotentialChangeOrderInput!) {
  createPotentialChangeOrder(potentialChangeOrder: $potentialChangeOrder) {
    ...PotentialChangeOrderFragment
  }
}
    ${PotentialChangeOrderFragment}`;
export const UpdatePotentialChangeOrderDocument = gql`
    mutation UpdatePotentialChangeOrder($potentialChangeOrder: UpdatePotentialChangeOrderInput!) {
  updatePotentialChangeOrder(potentialChangeOrder: $potentialChangeOrder) {
    ...PotentialChangeOrderFragment
  }
}
    ${PotentialChangeOrderFragment}`;
export const DeletePotentialChangeOrderDocument = gql`
    mutation DeletePotentialChangeOrder($id: Int!) {
  deletePotentialChangeOrder(id: $id) {
    ...PotentialChangeOrderFragment
  }
}
    ${PotentialChangeOrderFragment}`;
export const CreateChangeOrderPackageDocument = gql`
    mutation CreateChangeOrderPackage($changeOrderPackage: CreateChangeOrderPackageInput!) {
  createChangeOrderPackage(changeOrderPackage: $changeOrderPackage) {
    ...ChangeOrderPackageFragment
  }
}
    ${ChangeOrderPackageFragment}`;
export const UpdateChangeOrderPackageDocument = gql`
    mutation UpdateChangeOrderPackage($changeOrderPackage: UpdateChangeOrderPackageInput!) {
  updateChangeOrderPackage(changeOrderPackage: $changeOrderPackage) {
    ...ChangeOrderPackageFragment
  }
}
    ${ChangeOrderPackageFragment}`;
export const DeleteChangeOrderPackageDocument = gql`
    mutation DeleteChangeOrderPackage($id: Int!) {
  deleteChangeOrderPackage(id: $id) {
    ...ChangeOrderPackageFragment
  }
}
    ${ChangeOrderPackageFragment}`;
export const CreateLabelDocument = gql`
    mutation CreateLabel($label: CreateLabelInput!) {
  createLabel(label: $label) {
    id
    name
    color
  }
}
    `;
export const UpdateLabelDocument = gql`
    mutation UpdateLabel($label: UpdateLabelInput!) {
  updateLabel(label: $label) {
    id
    name
    color
  }
}
    `;
export const DeleteLabelDocument = gql`
    mutation DeleteLabel($id: Int!) {
  deleteLabel(id: $id) {
    id
    name
    color
  }
}
    `;
export const CreateApprovalPackageDocument = gql`
    mutation CreateApprovalPackage($approvalPackage: CreateApprovalPackageInput!) {
  createApprovalPackage(approvalPackage: $approvalPackage) {
    __typename
    ...ApprovalPackageFragment
    ... on ErrorResult {
      message
    }
  }
}
    ${ApprovalPackageFragment}`;
export const UpdateApprovalPackageDocument = gql`
    mutation UpdateApprovalPackage($approvalPackage: UpdateApprovalPackageInput!) {
  updateApprovalPackage(approvalPackage: $approvalPackage) {
    __typename
    ...ApprovalPackageFragment
    ... on ErrorResult {
      message
    }
  }
}
    ${ApprovalPackageFragment}`;
export const ArchiveApprovalPackageDocument = gql`
    mutation ArchiveApprovalPackage($id: Int!, $status: ApprovalPackageStatusEnum!) {
  archiveApprovalPackage(id: $id, status: $status) {
    __typename
    ...ApprovalPackageFragment
    ... on ErrorResult {
      message
    }
  }
}
    ${ApprovalPackageFragment}`;
export const DeleteApprovalPackageDocument = gql`
    mutation DeleteApprovalPackage($id: Int!) {
  deleteApprovalPackage(id: $id) {
    __typename
    ...ApprovalPackageFragment
    ... on ErrorResult {
      message
    }
  }
}
    ${ApprovalPackageFragment}`;
export const UpdateApprovalPackageSubSectionTextDocument = gql`
    mutation UpdateApprovalPackageSubSectionText($subSectionText: UpdateApprovalPackageSubSectionTextInput!) {
  updateApprovalPackageSubsectionText(subSectionText: $subSectionText) {
    id
    title
    text
  }
}
    `;
export const UpdateApprovalPackageSubSectionChecklistDocument = gql`
    mutation UpdateApprovalPackageSubSectionChecklist($subSectionChecklist: UpdateApprovalPackageSubSectionChecklistInput!) {
  updateApprovalPackageSubsectionChecklist(
    subSectionChecklist: $subSectionChecklist
  ) {
    id
    title
  }
}
    `;
export const CreateApprovalPackageSubSectionDocument = gql`
    mutation CreateApprovalPackageSubSection($sectionId: Int!, $type: ApprovalPackageSubSectionTypeEnum!, $checklistId: Int, $gcEstimateTypeId: Int) {
  createApprovalPackageSubSection(
    sectionId: $sectionId
    type: $type
    checklistId: $checklistId
    gcEstimateTypeId: $gcEstimateTypeId
  ) {
    ...ApprovalPackageSubSectionFragment
  }
}
    ${ApprovalPackageSubSectionFragment}`;
export const UpdateApprovalPackageSubSectionDocument = gql`
    mutation UpdateApprovalPackageSubSection($subSection: UpdateApprovalPackageSubSectionInput!) {
  updateApprovalPackageSubsection(subSection: $subSection) {
    ...ApprovalPackageSubSectionFragment
  }
}
    ${ApprovalPackageSubSectionFragment}`;
export const DeleteApprovalPackageSubSectionDocument = gql`
    mutation DeleteApprovalPackageSubSection($id: Int!) {
  deleteApprovalPackageSubsection(id: $id) {
    ...ApprovalPackageSubSectionFragment
  }
}
    ${ApprovalPackageSubSectionFragment}`;
export const CreateApprovalPackageSectionDocument = gql`
    mutation CreateApprovalPackageSection($section: CreateApprovalPackageSectionInput!) {
  createApprovalPackageSection(section: $section) {
    id
    title
    orderId
  }
}
    `;
export const UpdateApprovalPackageSectionDocument = gql`
    mutation UpdateApprovalPackageSection($section: UpdateApprovalPackageSectionInput!) {
  updateApprovalPackageSection(section: $section) {
    id
    title
    orderId
  }
}
    `;
export const DeleteApprovalPackageSectionDocument = gql`
    mutation DeleteApprovalPackageSection($id: Int!) {
  deleteApprovalPackageSection(id: $id) {
    id
    title
    orderId
  }
}
    `;
export const SetApprovalPackageSubSectionTableColumnsDocument = gql`
    mutation SetApprovalPackageSubSectionTableColumns($id: Int!, $columns: Int!) {
  setApprovalPackageSubSectionTableColumns(id: $id, columns: $columns) {
    id
    title
    orderId
    gridColumnWidth
    columns
    cells {
      id
      orderId
      entries {
        id
        name
        orderId
        type
      }
      attributes {
        value
        attribute {
          id
          displayName
          columnName
          dataType
        }
      }
      teamMembers {
        names
        role {
          id
          name
        }
      }
      partners {
        names
        role {
          id
          name
        }
      }
      events {
        event {
          id
          name
          type
        }
        startDate
        endDate
      }
      metrics {
        metric {
          ...MetricFragment
        }
        value
      }
    }
  }
}
    ${MetricFragment}`;
export const CreateApprovalPackageSubSectionTableCellDocument = gql`
    mutation CreateApprovalPackageSubSectionTableCell($id: Int!) {
  createApprovalPackageSubSectionTableCell(id: $id) {
    id
  }
}
    `;
export const SetEntriesForApprovalPackageSubSectionTableCellDocument = gql`
    mutation SetEntriesForApprovalPackageSubSectionTableCell($id: Int!, $entries: [IcSubSectionTableCellEntryInput!]!) {
  setEntriesForApprovalPackageSubSectionTableCell(id: $id, entries: $entries) {
    count
  }
}
    `;
export const DeleteApprovalPackageSubSectionTableCellDocument = gql`
    mutation DeleteApprovalPackageSubSectionTableCell($id: Int!) {
  deleteApprovalPackageSubSectionTableCell(id: $id) {
    id
  }
}
    `;
export const UpdateApprovalPackageExhibitDocument = gql`
    mutation UpdateApprovalPackageExhibit($id: Int!, $displayName: String, $fileName: String) {
  updateApprovalPackageExhibit(
    id: $id
    displayName: $displayName
    fileName: $fileName
  ) {
    id
    type
    name
    displayName
    fileExtension
    createdAt
    updatedAt
    deletedAt
  }
}
    `;
export const DeleteApprovalPackageExhibitDocument = gql`
    mutation DeleteApprovalPackageExhibit($id: Int!) {
  deleteApprovalPackageExhibit(id: $id) {
    id
    type
    name
    displayName
    fileExtension
    createdAt
    updatedAt
    deletedAt
  }
}
    `;
export const DeleteDealArtifactDocument = gql`
    mutation DeleteDealArtifact($id: Int!) {
  deleteDealArtifact(id: $id) {
    id
    name
    fileExtension
    createdAt
    updatedAt
    deletedAt
  }
}
    `;
export const DeleteContactAttachmentDocument = gql`
    mutation DeleteContactAttachment($id: Int!) {
  deleteContactAttachment(id: $id) {
    id
    createdAt
    updatedAt
    deletedAt
  }
}
    `;
export const DeleteFirmAttachmentDocument = gql`
    mutation DeleteFirmAttachment($id: Int!) {
  deleteFirmAttachment(id: $id) {
    id
    createdAt
    updatedAt
    deletedAt
  }
}
    `;
export const CreateApprovalPackageReviewerDocument = gql`
    mutation CreateApprovalPackageReviewer($reviewer: CreateApprovalPackageReviewerInput!) {
  createApprovalPackageReviewer(reviewer: $reviewer) {
    __typename
    ...ApprovalPackageReviewerFragment
  }
}
    ${ApprovalPackageReviewerFragment}`;
export const UpdateApprovalPackageReviewerDocument = gql`
    mutation UpdateApprovalPackageReviewer($reviewer: UpdateApprovalPackageReviewerInput!) {
  updateApprovalPackageReviewer(reviewer: $reviewer) {
    __typename
    ...ApprovalPackageReviewerFragment
  }
}
    ${ApprovalPackageReviewerFragment}`;
export const DeleteApprovalPackageReviewerDocument = gql`
    mutation DeleteApprovalPackageReviewer($id: Int!) {
  deleteApprovalPackageReviewer(id: $id) {
    __typename
    ...ApprovalPackageReviewerFragment
  }
}
    ${ApprovalPackageReviewerFragment}`;
export const CreateApprovalPackageContributorDocument = gql`
    mutation CreateApprovalPackageContributor($contributor: CreateApprovalPackageContributorInput!) {
  createApprovalPackageContributor(contributor: $contributor) {
    __typename
    ...ApprovalPackageContributorFragment
  }
}
    ${ApprovalPackageContributorFragment}`;
export const DeleteApprovalPackageContributorDocument = gql`
    mutation DeleteApprovalPackageContributor($id: Int!) {
  deleteApprovalPackageContributor(id: $id) {
    __typename
    ...ApprovalPackageContributorFragment
  }
}
    ${ApprovalPackageContributorFragment}`;
export const PublishApprovalPackageDocument = gql`
    mutation PublishApprovalPackage($id: Int!) {
  publishApprovalPackage(id: $id) {
    id
    updatedAt
    createdAt
    reviewDate
    isPublished
    type {
      id
      name
    }
    status
    amendmentNumber
    deal {
      ... on Deal {
        id
        name
      }
    }
  }
}
    `;
export const CreateApprovalPackageTemplateDocument = gql`
    mutation CreateApprovalPackageTemplate($template: CreateApprovalPackageTemplateInput!) {
  createApprovalPackageTemplate(template: $template) {
    __typename
    ...ApprovalPackageTemplateFragment
    ... on ErrorResult {
      message
    }
  }
}
    ${ApprovalPackageTemplateFragment}`;
export const UpdateApprovalPackageTemplateDocument = gql`
    mutation UpdateApprovalPackageTemplate($template: UpdateApprovalPackageTemplateInput!) {
  updateApprovalPackageTemplate(template: $template) {
    __typename
    ...ApprovalPackageTemplateFragment
    ... on ErrorResult {
      message
    }
  }
}
    ${ApprovalPackageTemplateFragment}`;
export const DeleteApprovalPackageTemplateDocument = gql`
    mutation DeleteApprovalPackageTemplate($id: Int!) {
  deleteApprovalPackageTemplate(id: $id) {
    __typename
    ...ApprovalPackageTemplateFragment
    ... on ErrorResult {
      message
    }
  }
}
    ${ApprovalPackageTemplateFragment}`;
export const UpdateApprovalPackageTemplateSubSectionTextDocument = gql`
    mutation UpdateApprovalPackageTemplateSubSectionText($subSectionText: UpdateApprovalPackageSubSectionTextInput!) {
  updateApprovalPackageTemplateSubsectionText(subSectionText: $subSectionText) {
    id
    title
    text
  }
}
    `;
export const UpdateApprovalPackageTemplateSubSectionChecklistDocument = gql`
    mutation UpdateApprovalPackageTemplateSubSectionChecklist($subSectionChecklist: UpdateApprovalPackageSubSectionChecklistInput!) {
  updateApprovalPackageTemplateSubsectionChecklist(
    subSectionChecklist: $subSectionChecklist
  ) {
    id
    title
  }
}
    `;
export const CreateApprovalPackageTemplateSubSectionDocument = gql`
    mutation CreateApprovalPackageTemplateSubSection($sectionId: Int!, $type: ApprovalPackageSubSectionTypeEnum!, $checklistId: Int, $gcEstimateTypeId: Int) {
  createApprovalPackageTemplateSubSection(
    sectionId: $sectionId
    type: $type
    checklistId: $checklistId
    gcEstimateTypeId: $gcEstimateTypeId
  ) {
    ...TemplateApprovalPackageSubSectionFragment
  }
}
    ${TemplateApprovalPackageSubSectionFragment}`;
export const UpdateApprovalPackageTemplateSubSectionDocument = gql`
    mutation UpdateApprovalPackageTemplateSubSection($subSection: UpdateApprovalPackageSubSectionInput!) {
  updateApprovalPackageTemplateSubsection(subSection: $subSection) {
    ...TemplateApprovalPackageSubSectionFragment
  }
}
    ${TemplateApprovalPackageSubSectionFragment}`;
export const DeleteApprovalPackageTemplateSubSectionDocument = gql`
    mutation DeleteApprovalPackageTemplateSubSection($id: Int!) {
  deleteApprovalPackageTemplateSubsection(id: $id) {
    ...TemplateApprovalPackageSubSectionFragment
  }
}
    ${TemplateApprovalPackageSubSectionFragment}`;
export const CreateApprovalPackageTemplateSectionDocument = gql`
    mutation CreateApprovalPackageTemplateSection($section: CreateApprovalPackageSectionInput!) {
  createApprovalPackageTemplateSection(section: $section) {
    id
    title
    orderId
  }
}
    `;
export const UpdateApprovalPackageTemplateSectionDocument = gql`
    mutation UpdateApprovalPackageTemplateSection($section: UpdateApprovalPackageSectionInput!) {
  updateApprovalPackageTemplateSection(section: $section) {
    id
    title
    orderId
  }
}
    `;
export const DeleteApprovalPackageTemplateSectionDocument = gql`
    mutation DeleteApprovalPackageTemplateSection($id: Int!) {
  deleteApprovalPackageTemplateSection(id: $id) {
    id
    title
    orderId
  }
}
    `;
export const SetApprovalPackageTemplateSubSectionTableColumnsDocument = gql`
    mutation SetApprovalPackageTemplateSubSectionTableColumns($id: Int!, $columns: Int!) {
  setApprovalPackageTemplateSubSectionTableColumns(id: $id, columns: $columns) {
    id
    title
    orderId
    gridColumnWidth
    columns
    cells {
      id
      orderId
      entries {
        id
        name
        orderId
        type
      }
    }
  }
}
    `;
export const CreateApprovalPackageTemplateSubSectionTableCellDocument = gql`
    mutation CreateApprovalPackageTemplateSubSectionTableCell($id: Int!) {
  createApprovalPackageTemplateSubSectionTableCell(id: $id) {
    id
  }
}
    `;
export const SetEntriesForApprovalPackageTemplateSubSectionTableCellDocument = gql`
    mutation SetEntriesForApprovalPackageTemplateSubSectionTableCell($id: Int!, $entries: [IcSubSectionTableCellEntryInput!]!) {
  setEntriesForApprovalPackageTemplateSubSectionTableCell(
    id: $id
    entries: $entries
  ) {
    count
  }
}
    `;
export const DeleteApprovalPackageTemplateSubSectionTableCellDocument = gql`
    mutation DeleteApprovalPackageTemplateSubSectionTableCell($id: Int!) {
  deleteApprovalPackageTemplateSubSectionTableCell(id: $id) {
    id
  }
}
    `;
export const CreateApprovalPackageTemplateFromPackageDocument = gql`
    mutation CreateApprovalPackageTemplateFromPackage($id: Int!) {
  createApprovalPackageTemplateFromPackage(id: $id) {
    id
    name
    updatedAt
    createdAt
  }
}
    `;
export const AssignChangeEventsToParentDocument = gql`
    mutation AssignChangeEventsToParent($dealId: Int!, $changeEventIds: [Int!]!, $parentId: Int) {
  assignChangeEventsToParent(
    dealId: $dealId
    changeEventIds: $changeEventIds
    parentId: $parentId
  ) {
    ...ChangeEventFragment
  }
}
    ${ChangeEventFragment}`;
export const AssignPotentialChangeOrdersToParentDocument = gql`
    mutation AssignPotentialChangeOrdersToParent($dealId: Int!, $potentialChangeOrderIds: [Int!]!, $parentId: Int) {
  assignPotentialChangeOrdersToParent(
    dealId: $dealId
    potentialChangeOrderIds: $potentialChangeOrderIds
    parentId: $parentId
  ) {
    ...PotentialChangeOrderFragment
  }
}
    ${PotentialChangeOrderFragment}`;
export const AssignChangeOrdersToParentDocument = gql`
    mutation AssignChangeOrdersToParent($dealId: Int!, $changeOrderIds: [Int!]!, $parentId: Int) {
  assignChangeOrdersToParent(
    dealId: $dealId
    changeOrderIds: $changeOrderIds
    parentId: $parentId
  ) {
    ...ChangeOrderPackageFragment
  }
}
    ${ChangeOrderPackageFragment}`;
export const RunDefaultForecastRulesDocument = gql`
    mutation RunDefaultForecastRules($fkDeal: Int!, $accounts: [Int!]) {
  runDefaultForecastRules(fkDeal: $fkDeal, accounts: $accounts) {
    transactionsCreated
  }
}
    `;
export const ForecastCapitalizationAccountsDocument = gql`
    mutation ForecastCapitalizationAccounts($fkDeal: Int!) {
  forecastCapitalizationAccounts(fkDeal: $fkDeal)
}
    `;
export const ForecastInterestAccountDocument = gql`
    mutation ForecastInterestAccount($fkDeal: Int!) {
  forecastInterestAccount(fkDeal: $fkDeal)
}
    `;
export const CreateApprovalPackageTypeDocument = gql`
    mutation CreateApprovalPackageType($approvalPackageType: CreateApprovalPackageTypeInput!) {
  createApprovalPackageType(approvalPackageType: $approvalPackageType) {
    ...ApprovalPackageTypeFragment
    ... on ErrorResult {
      message
    }
  }
}
    ${ApprovalPackageTypeFragment}`;
export const UpdateApprovalPackageTypeDocument = gql`
    mutation UpdateApprovalPackageType($approvalPackageType: UpdateApprovalPackageTypeInput!) {
  updateApprovalPackageType(approvalPackageType: $approvalPackageType) {
    ...ApprovalPackageTypeFragment
    ... on ErrorResult {
      message
    }
  }
}
    ${ApprovalPackageTypeFragment}`;
export const DeleteApprovalPackageTypeDocument = gql`
    mutation DeleteApprovalPackageType($id: Int!) {
  deleteApprovalPackageType(id: $id) {
    __typename
    ...ApprovalPackageTypeFragment
    ... on ErrorResult {
      message
    }
  }
}
    ${ApprovalPackageTypeFragment}`;
export const BulkEditVisibleDealEventsDocument = gql`
    mutation BulkEditVisibleDealEvents($pipelineId: Int!, $dealIds: [Int!]!, $eventIds: [Int!]!) {
  bulkEditVisibleDealEvents(
    pipelineId: $pipelineId
    dealIds: $dealIds
    eventIds: $eventIds
  )
}
    `;
export const BulkUnhideDealEventsDocument = gql`
    mutation BulkUnhideDealEvents($pipelineId: Int!, $dealIds: [Int!]!, $eventIds: [Int!]!) {
  bulkUnhideDealEvents(
    pipelineId: $pipelineId
    dealIds: $dealIds
    eventIds: $eventIds
  )
}
    `;
export const RefreshApprovalPackageKeyRisksDocument = gql`
    mutation RefreshApprovalPackageKeyRisks($id: Int!, $approvalPackageId: Int!) {
  refreshApprovalPackageKeyRisks(id: $id, approvalPackageId: $approvalPackageId) {
    id
    title
    riskDescription
    mitigantDescription
    orderId
  }
}
    `;
export const RefreshApprovalPackageDealMeritsDocument = gql`
    mutation RefreshApprovalPackageDealMerits($id: Int!, $approvalPackageId: Int!) {
  refreshApprovalPackageDealMerits(id: $id, approvalPackageId: $approvalPackageId) {
    id
    title
    meritDescription
    orderId
  }
}
    `;
export const CreateInterestRateDocument = gql`
    mutation CreateInterestRate($interestRate: CreateInterestRateInput!) {
  createInterestRate(interestRate: $interestRate) {
    __typename
    ...InterestRateFragment
    ...InterestRateAlreadyExistsFragment
    ...ErrorFragment
  }
}
    ${InterestRateFragment}
${InterestRateAlreadyExistsFragment}
${ErrorFragment}`;
export const UpdateInterestRateDocument = gql`
    mutation UpdateInterestRate($interestRate: UpdateInterestRateInput!) {
  updateInterestRate(interestRate: $interestRate) {
    __typename
    ...InterestRateFragment
    ...InterestRateAlreadyExistsFragment
    ...ErrorFragment
  }
}
    ${InterestRateFragment}
${InterestRateAlreadyExistsFragment}
${ErrorFragment}`;
export const DeleteInterestRateDocument = gql`
    mutation DeleteInterestRate($id: Int!) {
  deleteInterestRate(id: $id) {
    __typename
    ...InterestRateFragment
    ... on ErrorResult {
      message
    }
  }
}
    ${InterestRateFragment}`;
export const GetPortfoliosDocument = gql`
    query GetPortfolios {
  portfolios {
    id
    name
    description
    isPrivate
  }
}
    `;
export const GetPortfolioDocument = gql`
    query GetPortfolio($id: Int!) {
  portfolio(id: $id) {
    id
    name
    description
    isPrivate
    publicCanEdit
    isDynamic
    filterGroups {
      clauses {
        type
        isAnd
        value
        attribute {
          __typename
          ... on DealAttribute {
            id
            displayName
            columnName
            dataType
          }
          ... on PartnerRole {
            id
            name
          }
          ... on TeamMemberRole {
            id
            name
          }
          ... on Event {
            id
            name
          }
        }
        filter {
          id
          name
          sign
        }
      }
    }
    currentUserCanEdit
    currentUserIsOwner
    users {
      user {
        id
        firstName
        lastName
      }
      role {
        id
        name
      }
    }
  }
}
    `;
export const GetDealsDocument = gql`
    query GetDeals($showArchivedDeals: Boolean!) {
  deals(showArchivedDeals: $showArchivedDeals) {
    id
    name
    archiveDate
    archiveStatus
    archiveReason
    currentStatus {
      id
      title
      message
      status {
        id
        name
        color
      }
    }
    region {
      id
      name
    }
    city
    state {
      id
      name
      code
    }
    birthDate
    stage {
      id
      name
    }
    marketSegment {
      id
      name
    }
    pipeline {
      id
      name
      isDisabled
    }
    fund {
      id
      name
    }
  }
}
    `;
export const GetDealsPaginatedDocument = gql`
    query GetDealsPaginated($showArchivedDeals: Boolean!, $limit: Int!, $offset: Int!) {
  dealsPaginated(
    showArchivedDeals: $showArchivedDeals
    limit: $limit
    offset: $offset
  ) {
    deals {
      id
      name
      archiveDate
      archiveStatus
      archiveReason
      currentStatus {
        id
        title
        message
        status {
          id
          name
          color
        }
      }
      region {
        id
        name
      }
      city
      state {
        id
        name
        code
      }
      birthDate
      stage {
        id
        name
      }
      marketSegment {
        id
        name
      }
      pipeline {
        id
        name
        isDisabled
      }
      fund {
        id
        name
      }
    }
    hasMoreDeals
  }
}
    `;
export const GetPortfolioDealsDocument = gql`
    query GetPortfolioDeals($id: Int!) {
  portfolio(id: $id) {
    id
    name
    description
    deals {
      id
      name
      currentStatus {
        id
        title
        message
        status {
          id
          name
          color
        }
      }
      region {
        id
        name
      }
      city
      state {
        id
        name
        code
      }
      birthDate
      stage {
        id
        name
      }
      marketSegment {
        id
        name
      }
    }
  }
}
    `;
export const GetPortfolioUserAccessDocument = gql`
    query GetPortfolioUserAccess($id: Int!) {
  portfolio(id: $id) {
    id
    name
    users {
      user {
        id
        firstName
        lastName
      }
      role {
        id
        name
      }
    }
    groups {
      group {
        id
        name
      }
      role {
        id
        name
      }
    }
  }
}
    `;
export const GetDealDocument = gql`
    query GetDeal($id: Int!) {
  deal(id: $id) {
    id
    name
    usesLatestAnalysisAsCurrent
    isWorkingDays
    otherName
    isPrivate
    publicCanEdit
    birthDate
    dealBackground
    earnestMoneyDeposit
    investmentVision
    zipcode
    city
    address1
    address2
    usesCoordinates
    longitude
    latitude
    currentUserCanEdit
    currentUserIsOwner
    archiveDate
    archiveStatus
    archiveReason
    legalEntity
    spread
    structure {
      value: id
      label: name
    }
    dealType {
      value: id
      label: name
    }
    priorityLevel {
      value: id
      label: name
    }
    stage {
      value: id
      label: name
    }
    fund {
      value: id
      label: name
    }
    fundEntity {
      value: id
      label: name
    }
    pipeline {
      value: id
      label: name
      pipelineModules {
        id
        isDisabled
        module {
          id
          name
        }
      }
      dealStages {
        value: id
        label: name
        isDisabled
        orderId
      }
    }
    source {
      value: id
      label: name
    }
    state {
      value: id
      label: name
      abbreviation: code
    }
    region {
      value: id
      label: name
    }
    parcelNumber
    yearBuilt
    market {
      value: id
      label: name
      msa {
        id
        name
      }
    }
    marketType {
      value: id
      label: name
    }
    marketSegment {
      value: id
      label: name
    }
    submarket {
      value: id
      label: name
    }
    brokerFirm {
      value: id
      label: name
    }
    brokerContact {
      id
      firstName
      lastName
    }
    buyerFirm {
      value: id
      label: name
    }
    buyerContact {
      id
      firstName
      lastName
    }
    sellerFirm {
      value: id
      label: name
    }
    sellerContact {
      id
      firstName
      lastName
    }
    purchasePrice
    productType {
      value: id
      label: name
    }
    constructionType {
      value: id
      label: name
    }
    assetClass {
      value: id
      label: name
    }
    hoa {
      value: id
      label: name
    }
    numberOfUnits
    nrsf
    irr
    unleverredIrr
    moic
    entryCapRate
    exitCapRate
    requiredEquity
    debt
    currentStatus {
      id
      title
      message
      sections {
        id
        title
        content
      }
      labels {
        id
        name
        color
      }
      createdAt
      updatedAt
      createdBy {
        id
        displayName
        displayNameAlt
      }
      commentCount
      status {
        id
        name
        color
      }
    }
    keyRisks {
      id
      title
      riskDescription
      mitigantDescription
      updatedAt
      orderId
      createdBy {
        id
        displayName
        displayNameAlt
      }
      updatedBy {
        id
        displayName
        displayNameAlt
      }
    }
    dealMerits {
      id
      title
      meritDescription
      updatedAt
      orderId
      createdBy {
        id
        displayName
        displayNameAlt
      }
      updatedBy {
        id
        displayName
        displayNameAlt
      }
    }
    currentAnalysis {
      id
      description
    }
    pinnedMetrics {
      metric {
        scale
        ...MetricFragment
      }
      currentValue {
        value
      }
    }
    pinnedEvents {
      event {
        name
      }
      endDate
    }
    pinnedPartnerRoles {
      role {
        id
        name
      }
      currentValue {
        role {
          id
          name
        }
        firm {
          id
          name
        }
      }
    }
    pinnedTeamMemberRoles {
      role {
        id
        name
      }
      currentValue {
        role {
          id
          name
        }
        user {
          id
          displayName
        }
      }
    }
  }
}
    ${MetricFragment}`;
export const GetDealInteractionsDocument = gql`
    query GetDealInteractions($id: Int!) {
  deal(id: $id) {
    id
    name
    pipeline {
      id
    }
    interactions {
      id
      name
      createdAt
      createdBy {
        id
        displayName
        displayNameAlt
      }
      interactionDate
      notes
      type {
        id
        name
      }
      attendees {
        id
        name
        type
      }
      deals {
        id
        name
      }
    }
  }
}
    `;
export const GetDealUserAccessDocument = gql`
    query GetDealUserAccess($id: Int!) {
  deal(id: $id) {
    id
    name
    users {
      user {
        id
        firstName
        lastName
      }
      role {
        id
        name
      }
    }
    groups {
      group {
        id
        name
      }
      role {
        id
        name
      }
    }
  }
}
    `;
export const GetPipelineUserAccessDocument = gql`
    query GetPipelineUserAccess($id: Int!) {
  pipeline(id: $id) {
    id
    name
    users {
      user {
        id
        firstName
        lastName
      }
      role {
        id
        name
      }
    }
    groups {
      group {
        id
        name
      }
      role {
        id
        name
      }
    }
  }
}
    `;
export const GetDealTeamDocument = gql`
    query GetDealTeam($id: Int!) {
  deal(id: $id) {
    team {
      user {
        id
        firstName
        lastName
      }
      role {
        id
        name
        description
      }
    }
  }
}
    `;
export const GetDealPartnersDocument = gql`
    query GetDealPartners($id: Int!) {
  deal(id: $id) {
    partners {
      id
      contact {
        id
        firstName
        lastName
      }
      firm {
        id
        name
      }
      role {
        id
        name
        description
      }
    }
  }
}
    `;
export const GetTeamMemberRolesDocument = gql`
    query GetTeamMemberRoles {
  teamMemberRoles {
    id
    name
    description
    isDisabled
    isAppDefault
  }
}
    `;
export const GetPartnerRolesDocument = gql`
    query GetPartnerRoles {
  partnerRoles {
    id
    name
    description
    isDisabled
    isAppDefault
  }
}
    `;
export const GetPortfolioUsersDocument = gql`
    query GetPortfolioUsers($id: Int!) {
  portfolio(id: $id) {
    users {
      user {
        id
        firstName
        lastName
      }
      role {
        id
        name
      }
    }
  }
}
    `;
export const GetCurrentOrganizationInfoDocument = gql`
    query GetCurrentOrganizationInfo {
  organization {
    name
    organizationPath
    owner {
      id
      firstName
      lastName
      displayName
      displayNameAlt
      email
      username
    }
    currentUserCanEdit
  }
}
    `;
export const GetOrganizationUserAccessDocument = gql`
    query GetOrganizationUserAccess {
  organizationUsers {
    user {
      id
      firstName
      lastName
    }
    role {
      id
      name
    }
  }
  organizationGroups {
    group {
      id
      name
    }
    role {
      id
      name
    }
  }
}
    `;
export const GetFirmDocument = gql`
    query GetFirm($id: Int!) {
  firm(id: $id) {
    id
    name
    website
    status {
      id
      name
    }
    type {
      id
      name
    }
    headquartersMsa {
      id
      name
    }
    headquartersMarket {
      id
      name
    }
    regions {
      id
      name
      isPrimaryRegion
    }
    productAppetite {
      id
      name
    }
    contacts {
      ...ContactFragment
    }
    relationshipLead {
      id
      firstName
      lastName
      displayName
    }
    associatedDeals {
      id
      name
    }
    interactions {
      id
      name
      createdAt
      createdBy {
        id
        displayName
        displayNameAlt
      }
      interactionDate
      notes
      type {
        id
        name
      }
      attendees {
        id
        name
        type
      }
      deals {
        id
        name
      }
    }
  }
}
    ${ContactFragment}`;
export const GetDealPipelineFirmsDocument = gql`
    query GetDealPipelineFirms($id: Int!) {
  deal(id: $id) {
    id
    name
    pipeline {
      firms {
        id
        name
      }
    }
  }
}
    `;
export const GetDealPipelineContactsDocument = gql`
    query GetDealPipelineContacts($id: Int!) {
  deal(id: $id) {
    id
    name
    pipeline {
      contacts {
        id
        firstName
        lastName
      }
    }
  }
}
    `;
export const GetInteractionDocument = gql`
    query GetInteraction($id: Int!) {
  interaction(id: $id) {
    id
    name
    createdAt
    createdBy {
      id
      displayName
      displayNameAlt
    }
    interactionDate
    notes
    type {
      id
      name
    }
    firms {
      id
      name
    }
    attendees {
      id
      name
      type
    }
    deals {
      id
      name
    }
  }
}
    `;
export const GetContactDocument = gql`
    query GetContact($id: Int!) {
  contact(id: $id) {
    id
    firstName
    lastName
    title
    email
    cellPhone
    officePhone
    notes
    isPrimary
    firm {
      id
      name
    }
    associatedDeals {
      id
      name
    }
    inaccessibleAssociatedDealsCount
    interactions {
      id
      name
      createdAt
      createdBy {
        id
        displayName
        displayNameAlt
      }
      interactionDate
      notes
      type {
        id
        name
      }
      attendees {
        id
        name
        type
      }
      deals {
        id
        name
      }
    }
  }
}
    `;
export const GetDealChecklistsDocument = gql`
    query GetDealChecklists($id: Int!) {
  deal(id: $id) {
    checklists {
      id
      name
      description
      checklistItems {
        id
        name
        comment
        category {
          id
          name
        }
        status {
          id
          name
        }
        dueDate
        assignee {
          id
          firstName
          lastName
        }
      }
    }
  }
}
    `;
export const GetChecklistItemStatusesDocument = gql`
    query GetChecklistItemStatuses {
  checklistItemStatuses {
    id
    name
  }
}
    `;
export const GetChecklistsDocument = gql`
    query GetChecklists {
  checklists {
    id
    name
    description
    isDisabled
  }
}
    `;
export const GetChecklistDocument = gql`
    query GetChecklist($id: Int!) {
  checklist(id: $id) {
    id
    name
    description
    isDisabled
    checklistItems {
      id
      name
      category {
        id
        name
      }
      teamMemberRole {
        id
        name
      }
      defaultComment
    }
  }
}
    `;
export const GetChecklistCategoriesDocument = gql`
    query GetChecklistCategories($id: Int!) {
  checklistCategories(id: $id) {
    id
    name
    fkChecklist
  }
}
    `;
export const GetDealStatusUpdatesDocument = gql`
    query GetDealStatusUpdates($id: Int!) {
  deal(id: $id) {
    id
    name
    statusUpdates {
      id
      title
      message
      sections {
        id
        title
        content
      }
      labels {
        id
        name
        color
      }
      labels {
        id
        name
        color
      }
      createdAt
      updatedAt
      commentCount
      createdBy {
        id
        displayName
        displayNameAlt
      }
      status {
        id
        name
        color
      }
    }
  }
}
    `;
export const GetDealStatusUpdateDocument = gql`
    query GetDealStatusUpdate($id: Int!, $statusUpdateId: Int!) {
  deal(id: $id) {
    id
    name
    statusUpdate(id: $statusUpdateId) {
      id
      title
      message
      sections {
        id
        title
        content
      }
      labels {
        id
        name
        color
      }
      createdAt
      updatedAt
      commentCount
      comments {
        id
        body
        createdAt
        updatedAt
        createdBy {
          id
          displayName
          displayNameAlt
        }
      }
      createdBy {
        id
        displayName
        displayNameAlt
      }
      status {
        id
        name
        color
      }
    }
  }
}
    `;
export const GetDealStatusUpdateTemplatesDocument = gql`
    query GetDealStatusUpdateTemplates {
  statusUpdateTemplates {
    id
    title
    message
    sections {
      id
      title
      content
    }
    labels {
      id
      name
      color
    }
    createdAt
    updatedAt
    createdBy {
      id
      displayName
      displayNameAlt
    }
  }
}
    `;
export const GetDealStatusUpdateTemplateDocument = gql`
    query GetDealStatusUpdateTemplate($id: Int!) {
  statusUpdateTemplate(id: $id) {
    id
    title
    message
    sections {
      id
      title
      content
    }
    labels {
      id
      name
      color
    }
    createdAt
    updatedAt
    createdBy {
      id
      displayName
      displayNameAlt
    }
  }
}
    `;
export const GetDealStatusesDocument = gql`
    query GetDealStatuses {
  dealStatuses {
    id
    name
    color
  }
}
    `;
export const GetDealScheduleDocument = gql`
    query GetDealSchedule($id: Int!) {
  deal(id: $id) {
    id
    birthDate
    events {
      ...DealEventFragment
      latestApprovedEvent {
        id
        startDate
        endDate
        type
      }
    }
    hiddenEvents {
      ...DealEventFragment
    }
    buildings {
      id
      name
      buildingNumber
      isTcoReceived
      tcoDate
      type {
        id
        name
      }
      commentCount
    }
    pipeline {
      id
    }
  }
}
    ${DealEventFragment}`;
export const GetDealKeyMilestonesDocument = gql`
    query GetDealKeyMilestones($id: Int!) {
  deal(id: $id) {
    id
    birthDate
    milestones {
      id
      name
      startDate
      parent {
        id
        name
      }
    }
  }
}
    `;
export const GetDealEventDocument = gql`
    query GetDealEvent($id: Int!, $dealEventId: Int!) {
  deal(id: $id) {
    id
    event(id: $dealEventId) {
      id
      dealEventId
      name
      orderId
      startDate
      endDate
      isComplete
      type
      category {
        id
        name
      }
      parent {
        id
        name
      }
      dependsOn {
        id
        name
      }
      commentCount
      comments {
        id
        body
        createdAt
        updatedAt
        createdBy {
          id
          displayName
          displayNameAlt
        }
      }
    }
  }
}
    `;
export const GetCurrentUserDocument = gql`
    query GetCurrentUser {
  currentUser {
    id
    firstName
    lastName
    email
    organizations {
      id
      name
      organizationPath
    }
  }
}
    `;
export const GetDealDocumentsDocument = gql`
    query GetDealDocuments($id: Int!, $fkFolder: Int) {
  deal(id: $id) {
    documents(fkFolder: $fkFolder) {
      id
      type
      name
      displayName
      createdAt
      updatedAt
      fileExtension
    }
  }
}
    `;
export const GetFoldersDocument = gql`
    query GetFolders($ids: [Int!]) {
  folders(ids: $ids) {
    id
    name
  }
}
    `;
export const GetStatesDocument = gql`
    query GetStates {
  states {
    value: id
    label: name
    abbreviation: code
  }
}
    `;
export const GetRegionsDocument = gql`
    query GetRegions {
  regions {
    value: id
    label: name
  }
}
    `;
export const GetMarketsDocument = gql`
    query GetMarkets {
  markets {
    value: id
    label: name
  }
}
    `;
export const GetMarketTypesDocument = gql`
    query GetMarketTypes {
  marketTypes {
    value: id
    label: name
  }
}
    `;
export const GetMarketSegmentsDocument = gql`
    query GetMarketSegments {
  marketSegments {
    value: id
    label: name
  }
}
    `;
export const GetSubmarketsDocument = gql`
    query GetSubmarkets {
  submarkets {
    fkMarket
    value: id
    label: name
  }
}
    `;
export const GetMsasDocument = gql`
    query GetMsas {
  msas {
    value: id
    label: name
  }
}
    `;
export const GetProductTypesDocument = gql`
    query GetProductTypes {
  productTypes {
    value: id
    label: name
    isDisabled
  }
}
    `;
export const GetBuildingTypesDocument = gql`
    query GetBuildingTypes {
  buildingTypes {
    value: id
    label: name
    isDisabled
  }
}
    `;
export const GetEstimateTypesDocument = gql`
    query GetEstimateTypes {
  estimateTypes {
    value: id
    label: name
    isDisabled
  }
}
    `;
export const GetConstructionTypesDocument = gql`
    query GetConstructionTypes {
  constructionTypes {
    value: id
    label: name
    isDisabled
  }
}
    `;
export const GetStructuresDocument = gql`
    query GetStructures {
  structures {
    value: id
    label: name
    isDisabled
  }
}
    `;
export const GetDealTypesDocument = gql`
    query GetDealTypes {
  dealTypes {
    value: id
    label: name
  }
}
    `;
export const GetAssetClassesDocument = gql`
    query GetAssetClasses {
  assetClasses {
    value: id
    label: name
    isDisabled
  }
}
    `;
export const GetHoaTypesDocument = gql`
    query GetHoaTypes {
  hoaTypes {
    value: id
    label: name
    isDisabled
  }
}
    `;
export const GetFundsDocument = gql`
    query GetFunds {
  funds {
    value: id
    label: name
    isDisabled
  }
}
    `;
export const GetFundEntitiesDocument = gql`
    query GetFundEntities {
  fundEntities {
    value: id
    label: name
    isDisabled
  }
}
    `;
export const GetPriorityLevelsDocument = gql`
    query GetPriorityLevels {
  priorityLevels {
    value: id
    label: name
  }
}
    `;
export const GetPipelinesDocument = gql`
    query GetPipelines {
  pipelines {
    id
    name
    description
    isDisabled
  }
}
    `;
export const GetPipelineDocument = gql`
    query GetPipeline($id: Int!) {
  pipeline(id: $id) {
    id
    name
    description
    isDisabled
    generalContractorRole {
      id
      name
    }
    generalContractorAccount {
      id
      name
      code
      displayCode
    }
    defaultCostSource {
      id
      name
      code
      displayCode
    }
    equityAccount {
      id
      name
      code
      displayCode
    }
    debtAccount {
      id
      name
      code
      displayCode
    }
    debtAccount {
      id
      name
      code
      displayCode
    }
    interestAccount {
      id
      name
      code
      displayCode
    }
    endInterestAccount {
      id
      name
      code
      displayCode
    }
    firstResidentialTcoEvent {
      id
      name
      type
    }
    lastResidentialTcoEvent {
      id
      name
      type
    }
    events {
      id
      name
      type
    }
    events {
      id
      name
      type
    }
    forecastRules {
      ...ForecastRuleFragment
    }
    contingencyCategorizations {
      ...PipelineContingencyCategoryFragment
    }
    pinnedMetrics {
      id
      name
    }
    pinnedPartnerRoles {
      id
      name
    }
    pinnedTeamMemberRoles {
      id
      name
    }
    checklists {
      id
      name
      description
      isDisabled
    }
    defaultAccountTree {
      id
      name
    }
    defaultSource {
      id
      name
    }
    accountCashOffsetRules {
      id
      offset
      account {
        id
        name
        code
        displayCode
      }
      event {
        id
        name
        type
      }
      filter {
        id
        name
      }
    }
    generalCashOffsetRules {
      id
      offset
      event {
        id
        name
        type
      }
      filter {
        id
        name
      }
    }
  }
}
    ${ForecastRuleFragment}
${PipelineContingencyCategoryFragment}`;
export const GetPipelineFirmsDocument = gql`
    query GetPipelineFirms($id: Int!) {
  pipeline(id: $id) {
    id
    name
    description
    isDisabled
    firms {
      id
      name
      website
      status {
        id
        name
      }
      type {
        id
        name
      }
      headquartersMsa {
        id
        name
      }
      headquartersMarket {
        id
        name
      }
      regions {
        id
        name
        isPrimaryRegion
      }
      productAppetite {
        id
        name
      }
      keyContacts {
        ...ContactFragment
      }
      relationshipLead {
        id
        firstName
        lastName
        displayName
      }
    }
  }
}
    ${ContactFragment}`;
export const GetPipelineContactsDocument = gql`
    query GetPipelineContacts($id: Int!) {
  pipeline(id: $id) {
    id
    name
    description
    isDisabled
    contacts {
      ...ContactFragment
    }
  }
}
    ${ContactFragment}`;
export const GetPipelineModulesDocument = gql`
    query GetPipelineModules($id: Int!) {
  pipeline(id: $id) {
    id
    name
    description
    isDisabled
    generalContractorRole {
      id
      name
    }
    generalContractorAccount {
      id
      name
      code
      displayCode
    }
    defaultCostSource {
      id
      name
      code
      displayCode
    }
    equityAccount {
      id
      name
      code
      displayCode
    }
    debtAccount {
      id
      name
      code
      displayCode
    }
    debtAccount {
      id
      name
      code
      displayCode
    }
    firstResidentialTcoEvent {
      id
      name
      type
    }
    lastResidentialTcoEvent {
      id
      name
      type
    }
    events {
      id
      name
      type
    }
    forecastRules {
      ...ForecastRuleFragment
    }
    contingencyCategorizations {
      ...PipelineContingencyCategoryFragment
    }
    pinnedMetrics {
      id
      name
    }
    pinnedEvents {
      id
      name
    }
    pinnedPartnerRoles {
      id
      name
    }
    pinnedTeamMemberRoles {
      id
      name
    }
    checklists {
      id
      name
      description
      isDisabled
    }
    defaultAccountTree {
      id
      name
    }
    defaultSource {
      id
      name
    }
    accountCashOffsetRules {
      id
      offset
      account {
        id
        name
        code
        displayCode
      }
      event {
        id
        name
        type
      }
      filter {
        id
        name
      }
    }
    generalCashOffsetRules {
      id
      offset
      event {
        id
        name
        type
      }
      filter {
        id
        name
      }
    }
    pipelineModules {
      id
      isDisabled
      module {
        id
        name
      }
    }
  }
}
    ${ForecastRuleFragment}
${PipelineContingencyCategoryFragment}`;
export const GetPipelineDealStagesDocument = gql`
    query GetPipelineDealStages($id: Int!) {
  pipeline(id: $id) {
    id
    name
    dealStages {
      value: id
      label: name
      isDisabled
      orderId
    }
  }
}
    `;
export const GetModulesDocument = gql`
    query GetModules {
  modules {
    id
    name
  }
}
    `;
export const GetBudgetOffsetRuleFiltersDocument = gql`
    query GetBudgetOffsetRuleFilters {
  budgetOffsetRuleFilters {
    id
    name
    sign
  }
}
    `;
export const GetForecastRulesDocument = gql`
    query GetForecastRules {
  forecastRules {
    ...ForecastRuleFragment
  }
}
    ${ForecastRuleFragment}`;
export const GetForecastRuleDocument = gql`
    query GetForecastRule($id: Int!) {
  forecastRule(id: $id) {
    ...ForecastRuleFragment
  }
}
    ${ForecastRuleFragment}`;
export const GetAnalysisScenariosDocument = gql`
    query GetAnalysisScenarios {
  analysisScenarios {
    value: id
    label: name
  }
}
    `;
export const GetAnalysisTypesDocument = gql`
    query GetAnalysisTypes {
  analysisTypes {
    id
    acronym
    name
  }
}
    `;
export const GetMetricsDocument = gql`
    query GetMetrics {
  metrics {
    ...MetricFragment
    precision
    scale
    isDisabled
    isAppDefault
  }
}
    ${MetricFragment}`;
export const GetTextMetricsDocument = gql`
    query GetTextMetrics {
  textMetrics {
    ...TextMetricFragment
    isDisabled
    isAppDefault
  }
}
    ${TextMetricFragment}`;
export const GetOrganizationUsersDocument = gql`
    query GetOrganizationUsers {
  users {
    id
    firstName
    lastName
    username
    email
    createdAt
    updatedAt
  }
}
    `;
export const GetOrganizationGroupsDocument = gql`
    query GetOrganizationGroups {
  groups {
    id
    name
    memberCount
    createdAt
    updatedAt
  }
}
    `;
export const GetOrganizationGroupDocument = gql`
    query GetOrganizationGroup($id: Int!) {
  group(id: $id) {
    id
    name
    createdAt
    updatedAt
    users {
      id
      firstName
      lastName
      username
      email
      createdAt
      updatedAt
    }
  }
}
    `;
export const GetPermissionRolesDocument = gql`
    query GetPermissionRoles {
  permissionRoles {
    id
    name
    createdAt
    updatedAt
  }
}
    `;
export const GetPortfolioViewPaginatedDocument = gql`
    query GetPortfolioViewPaginated($id: Int!, $viewId: Int!, $limit: Int!, $offset: Int!, $showArchivedDeals: Boolean!) {
  portfolio(id: $id) {
    id
    name
    pinnedView(id: $viewId) {
      id
      name
      isDefault
      orderId
      sortModel
      filterModel
      groupRowColumns
      showArchivedDeals
      columns {
        id
        name
        lookupName
        type
        orderId
        sortOrder
      }
      payload(limit: $limit, offset: $offset, showArchivedDeals: $showArchivedDeals) {
        columns
        rows
        hasMoreDeals
      }
    }
  }
}
    `;
export const GetPortfolioViewDocument = gql`
    query GetPortfolioView($id: Int!, $viewId: Int!, $showArchivedDeals: Boolean!) {
  portfolio(id: $id) {
    id
    name
    pinnedView(id: $viewId) {
      id
      name
      isDefault
      orderId
      sortModel
      filterModel
      groupRowColumns
      showArchivedDeals
      columns {
        id
        name
        lookupName
        type
        orderId
        sortOrder
      }
      payload(showArchivedDeals: $showArchivedDeals) {
        columns
        rows
        hasMoreDeals
      }
    }
  }
}
    `;
export const GetPortfolioViewColumnsDocument = gql`
    query GetPortfolioViewColumns($id: Int!, $viewId: Int!) {
  portfolio(id: $id) {
    id
    name
    pinnedView(id: $viewId) {
      id
      name
      isDefault
      orderId
      sortModel
      filterModel
      groupRowColumns
      showArchivedDeals
      columns {
        id
        name
        lookupName
        type
        orderId
        sortOrder
      }
    }
  }
}
    `;
export const GetPortfolioViewsDocument = gql`
    query GetPortfolioViews($id: Int!) {
  portfolio(id: $id) {
    id
    name
    pinnedViews {
      id
      name
      analysisType {
        id
        name
      }
      showAnalysisEvents
      isDefault
      orderId
    }
  }
}
    `;
export const AllViewColumnOptionsDocument = gql`
    query AllViewColumnOptions {
  viewColumnOptions {
    id
    name
    lookupName
    type
  }
}
    `;
export const GetEventsDocument = gql`
    query GetEvents {
  events {
    id
    name
    description
    type
    parent {
      id
      name
    }
    orderId
    isDisabled
    isAppDefault
  }
}
    `;
export const GetEventDocument = gql`
    query GetEvent($id: Int!) {
  event(id: $id) {
    id
    name
    description
    isDisabled
    isAppDefault
    type
    parent {
      id
      name
    }
    orderId
  }
}
    `;
export const GetDealAttributesDocument = gql`
    query GetDealAttributes {
  dealAttributes {
    id
    displayName
    columnName
    dataType
  }
}
    `;
export const GetDealAnalysesDocument = gql`
    query GetDealAnalyses($id: Int!) {
  deal(id: $id) {
    id
    pipeline {
      pinnedMetrics {
        id
        name
      }
    }
    currentAnalysis {
      id
      description
    }
    analyses {
      id
      description
      createdAt
      updatedAt
      archiveDate
      scenario {
        id
        name
      }
      type {
        id
        name
      }
      archivedApprovalPackages {
        id
        status
        type {
          id
          name
        }
      }
      pinnedMetrics {
        id
        value
        metric {
          scale
          ...MetricFragment
        }
      }
    }
  }
}
    ${MetricFragment}`;
export const GetDealCurrentAnalysisDocument = gql`
    query GetDealCurrentAnalysis($id: Int!) {
  deal(id: $id) {
    id
    currentAnalysis {
      id
      description
      createdAt
      updatedAt
      archiveDate
      scenario {
        id
        name
      }
      metrics {
        id
        value
        metric {
          scale
          ...MetricFragment
        }
      }
    }
  }
}
    ${MetricFragment}`;
export const GetDealMasterBudgetDataDocument = gql`
    query GetDealMasterBudgetData($id: Int!) {
  deal(id: $id) {
    id
    name
    pipeline {
      defaultAccountTree {
        id
        name
      }
    }
    currentUserCanEdit
    forecastRules {
      id
      updatedAt
      updatedBy {
        id
        displayName
      }
      forecastRule {
        ...ForecastRuleFragment
      }
      isEnabled
    }
  }
  accountTrees {
    id
    name
    isDisabled
    isAppDefault
  }
}
    ${ForecastRuleFragment}`;
export const GetDealAnalysisDocument = gql`
    query GetDealAnalysis($id: Int!, $fkAnalysis: Int!) {
  deal(id: $id) {
    id
    usesLatestAnalysisAsCurrent
    currentAnalysis {
      id
    }
    analysis(id: $fkAnalysis) {
      id
      description
      createdAt
      updatedAt
      archiveDate
      type {
        name
      }
      scenario {
        id
        name
      }
      metrics {
        id
        value
        metric {
          scale
          ...MetricFragment
        }
      }
      textMetrics {
        id
        value
        textMetric {
          ...TextMetricFragment
        }
      }
      events {
        id
        startDate
        event {
          ...EventFragment
        }
      }
    }
  }
}
    ${MetricFragment}
${TextMetricFragment}
${EventFragment}`;
export const GetDealMasterBudgetOverviewDocument = gql`
    query GetDealMasterBudgetOverview($id: Int!, $fkGlAccountTree: Int!) {
  accountTree(id: $fkGlAccountTree) {
    id
    name
    reportType {
      id
      name
    }
    branches {
      id
      level
      account {
        id
        name
        code
        displayCode
      }
      reverseFromDrCr
      fkParentBranch
    }
  }
  deal(id: $id) {
    id
    name
    pipeline {
      defaultAccountTree {
        id
        name
      }
    }
    accountTreeForecasts(fkGlAccountTree: $fkGlAccountTree) {
      account {
        id
        name
        code
        displayCode
      }
      postDate
      amount
    }
    accountTreeActuals(fkGlAccountTree: $fkGlAccountTree) {
      account {
        id
        name
        code
        displayCode
      }
      postDate
      amount
    }
    approvedBudget(fkGlAccountTree: $fkGlAccountTree) {
      account {
        id
        name
        code
        displayCode
      }
      amount
    }
    pendingBudget(fkGlAccountTree: $fkGlAccountTree) {
      account {
        id
        name
        code
        displayCode
      }
      amount
    }
  }
}
    `;
export const GetDealTransactionsDocument = gql`
    query GetDealTransactions($id: Int!) {
  deal(id: $id) {
    transactions {
      id
      amount
      createdAt
      updatedAt
      startDate
      endDate
      isOverride
      isStale
      isRefundable
      hasCashPaymentOverride
      milestoneAdjustmentStart
      milestoneAdjustmentEnd
      start {
        __typename
        ...TransactionDateFragment
        ...TransactionEventFragment
      }
      end {
        __typename
        ...TransactionDateFragment
        ...TransactionEventFragment
      }
      createdBy {
        id
        displayName
      }
      updatedBy {
        id
        displayName
      }
      account {
        id
        name
        code
        displayCode
      }
      description
      vendor {
        id
        name
      }
      contract {
        id
        name
      }
      distributionType {
        id
        name
      }
    }
  }
}
    ${TransactionDateFragment}
${TransactionEventFragment}`;
export const GetDealTransactionDocument = gql`
    query GetDealTransaction($id: Int!, $transactionId: Int!) {
  deal(id: $id) {
    transaction(id: $transactionId) {
      id
      amount
      isRefundable
      hasCashPaymentOverride
      isOverride
      createdAt
      updatedAt
      milestoneAdjustmentStart
      milestoneAdjustmentEnd
      isStale
      start {
        __typename
        ...TransactionDateFragment
        ...TransactionEventFragment
      }
      end {
        __typename
        ...TransactionDateFragment
        ...TransactionEventFragment
      }
      createdBy {
        id
        displayName
      }
      updatedBy {
        id
        displayName
      }
      account {
        id
        name
        code
        displayCode
      }
      description
      vendor {
        id
        name
      }
      contract {
        id
        name
      }
      distributionType {
        id
        name
      }
      details {
        date
        amount
        type
      }
    }
  }
}
    ${TransactionDateFragment}
${TransactionEventFragment}`;
export const GetDealRevisionsDocument = gql`
    query GetDealRevisions($id: Int!) {
  deal(id: $id) {
    revisions {
      id
      name
      createdAt
      updatedAt
      total
      approvedDate
      applicableDate
      createdBy {
        id
        displayName
      }
      updatedBy {
        id
        displayName
      }
    }
  }
}
    `;
export const GetDealContractsDocument = gql`
    query GetDealContracts($id: Int!) {
  deal(id: $id) {
    contracts {
      id
      name
      createdAt
      updatedAt
      amount
      vendor {
        id
        name
      }
      createdBy {
        id
        displayName
      }
      updatedBy {
        id
        displayName
      }
    }
  }
}
    `;
export const GetDealContractDocument = gql`
    query GetDealContract($id: Int!, $contractId: Int!) {
  deal(id: $id) {
    contract(id: $contractId) {
      id
      name
      createdAt
      updatedAt
      amount
      vendor {
        id
        name
      }
      transactions {
        id
        amount
        createdAt
        updatedAt
        startDate
        endDate
        isStale
        createdBy {
          id
          displayName
        }
        updatedBy {
          id
          displayName
        }
        account {
          id
          name
          code
          displayCode
        }
        description
        vendor {
          id
          name
        }
        distributionType {
          id
          name
        }
      }
      createdBy {
        id
        displayName
      }
      updatedBy {
        id
        displayName
      }
    }
  }
}
    `;
export const GetDealPublishedCapitalForecastsDocument = gql`
    query GetDealPublishedCapitalForecasts($id: Int!) {
  deal(id: $id) {
    id
    name
    publishedCapitalForecasts {
      id
      name
      comment
      createdAt
      updatedAt
      createdBy {
        id
        displayName
      }
    }
  }
}
    `;
export const GetDealPublishedMasterBudgetOverviewDocument = gql`
    query GetDealPublishedMasterBudgetOverview($id: Int!, $publishedCapitalForecastId: Int!, $fkGlAccountTree: Int!) {
  accountTree(id: $fkGlAccountTree) {
    id
    name
    reportType {
      id
      name
    }
    branches {
      id
      level
      account {
        id
        name
        code
        displayCode
      }
      reverseFromDrCr
      fkParentBranch
    }
  }
  deal(id: $id) {
    id
    name
    pipeline {
      defaultAccountTree {
        id
        name
      }
    }
    publishedCapitalForecast(id: $publishedCapitalForecastId) {
      id
      name
      comment
      createdAt
      updatedAt
      createdBy {
        id
        displayName
      }
      accountTreeForecasts(fkGlAccountTree: $fkGlAccountTree) {
        account {
          id
          name
          code
          displayCode
        }
        postDate
        amount
      }
      accountTreeActuals(fkGlAccountTree: $fkGlAccountTree) {
        account {
          id
          name
          code
          displayCode
        }
        postDate
        amount
      }
      approvedBudget(fkGlAccountTree: $fkGlAccountTree) {
        account {
          id
          name
          code
          displayCode
        }
        amount
      }
      pendingBudget(fkGlAccountTree: $fkGlAccountTree) {
        account {
          id
          name
          code
          displayCode
        }
        amount
      }
    }
  }
}
    `;
export const GetDealTransactionFormOptionsDocument = gql`
    query GetDealTransactionFormOptions($id: Int!, $accountTreeId: Int!) {
  accountTree(id: $accountTreeId) {
    id
    reportType {
      id
      name
    }
    branches {
      id
      level
      account {
        id
        name
        code
        displayCode
        type {
          id
          name
        }
      }
    }
  }
  deal(id: $id) {
    contracts {
      value: id
      label: name
    }
    events {
      id
      name
      startDate
      endDate
      type
    }
    pipeline {
      firms {
        id
        name
      }
    }
  }
}
    `;
export const GetBudgetRevisionDocument = gql`
    query GetBudgetRevision($id: Int!, $revisionId: Int!, $accountTreeId: Int!) {
  accountTree(id: $accountTreeId) {
    id
    reportType {
      id
      name
    }
    branches {
      id
      level
      account {
        id
        name
        code
        displayCode
        type {
          id
          name
        }
      }
    }
  }
  deal(id: $id) {
    id
    name
    revision(id: $revisionId) {
      id
      name
      approvedDate
      applicableDate
      total
      details {
        id
        amount
        isPursuit
        account {
          id
          name
          code
          displayCode
        }
      }
    }
  }
}
    `;
export const GetDealAccountForecastsDocument = gql`
    query GetDealAccountForecasts($id: Int!, $accountIds: [Int!]!, $start: Date, $end: Date, $excludeTransactionId: Int) {
  deal(id: $id) {
    accountForecasts(
      accountIds: $accountIds
      start: $start
      end: $end
      excludeTransactionId: $excludeTransactionId
    ) {
      account {
        id
        name
        code
        displayCode
      }
      postDate
      amount
    }
  }
}
    `;
export const GetPublishedScheduleInstancesDocument = gql`
    query GetPublishedScheduleInstances($id: Int!) {
  deal(id: $id) {
    publishedScheduleInstances {
      id
      description
      createdAt
      updatedAt
      createdBy {
        id
        displayName
      }
      updatedBy {
        id
        displayName
      }
    }
  }
}
    `;
export const GetPublishedScheduleInstanceDocument = gql`
    query GetPublishedScheduleInstance($id: Int!, $fkPublishedScheduleInstance: Int!) {
  deal(id: $id) {
    publishedScheduleInstance(id: $fkPublishedScheduleInstance) {
      id
      description
      createdAt
      updatedAt
      createdBy {
        id
        displayName
      }
      updatedBy {
        id
        displayName
      }
      events {
        ...PublishedDealEventFragment
      }
    }
  }
}
    ${PublishedDealEventFragment}`;
export const GetDealBuildingsDocument = gql`
    query GetDealBuildings($id: Int!) {
  deal(id: $id) {
    buildings {
      ...BuildingFragment
    }
  }
}
    ${BuildingFragment}`;
export const GetDealBuildingDocument = gql`
    query GetDealBuilding($id: Int!, $fkBuilding: Int!) {
  deal(id: $id) {
    building(id: $fkBuilding) {
      ...BuildingFragment
      comments {
        id
        body
        createdAt
        updatedAt
        createdBy {
          id
          displayName
          displayNameAlt
        }
      }
    }
  }
}
    ${BuildingFragment}`;
export const GetDealBondsDocument = gql`
    query GetDealBonds($id: Int!) {
  deal(id: $id) {
    bonds {
      ...BondFragment
    }
  }
}
    ${BondFragment}`;
export const GetDealBondDocument = gql`
    query GetDealBond($id: Int!, $fkBond: Int!) {
  deal(id: $id) {
    bond(id: $fkBond) {
      ...BondFragment
    }
  }
}
    ${BondFragment}`;
export const GetDealGcEstimatesDocument = gql`
    query GetDealGcEstimates($id: Int!) {
  deal(id: $id) {
    gcEstimates {
      ...GcEstimateFragment
    }
  }
}
    ${GcEstimateFragment}`;
export const GetGcEstimateTypesDocument = gql`
    query GetGcEstimateTypes {
  gcEstimateTypes {
    value: id
    label: name
  }
}
    `;
export const GetDealGcEstimateDocument = gql`
    query GetDealGcEstimate($id: Int!, $fkGcEstimate: Int!) {
  deal(id: $id) {
    gcEstimate(id: $fkGcEstimate) {
      ...GcEstimateFragment
      comments {
        id
        body
        createdAt
        updatedAt
        createdBy {
          id
          displayName
          displayNameAlt
        }
      }
    }
  }
}
    ${GcEstimateFragment}`;
export const GetDealPinnedMetricsDocument = gql`
    query GetDealPinnedMetrics($id: Int!) {
  deal(id: $id) {
    pinnedMetrics {
      ...DealPinnedMetricFragment
    }
  }
}
    ${DealPinnedMetricFragment}`;
export const GetContingencyCategoriesDocument = gql`
    query GetContingencyCategories {
  contingencyCategories {
    id
    name
  }
}
    `;
export const GetAccountCategoriesDocument = gql`
    query GetAccountCategories {
  accountCategories {
    id
    name
  }
}
    `;
export const GetAccountTypesDocument = gql`
    query GetAccountTypes {
  accountTypes {
    id
    name
  }
}
    `;
export const GetAccountsDocument = gql`
    query GetAccounts {
  accounts {
    id
    name
    code
    displayCode
    isDisabled
    isAppDefault
    type {
      id
      name
    }
    category {
      id
      name
    }
  }
}
    `;
export const GetAccountDocument = gql`
    query GetAccount($id: Int!) {
  account(id: $id) {
    id
    name
    code
    displayCode
    isDisabled
    isAppDefault
    type {
      id
      name
    }
    category {
      id
      name
    }
  }
}
    `;
export const GetAccountTreesDocument = gql`
    query GetAccountTrees {
  accountTrees {
    id
    name
    isDisabled
    isAppDefault
  }
}
    `;
export const GetAccountTreeDocument = gql`
    query GetAccountTree($id: Int!) {
  accountTree(id: $id) {
    id
    name
    reportType {
      id
      name
    }
    branches {
      id
      level
      account {
        id
        name
        code
        displayCode
        type {
          id
          name
        }
      }
      reverseFromDrCr
      fkParentBranch
    }
    isDisabled
    isAppDefault
  }
}
    `;
export const GetDealChangeItemsDocument = gql`
    query GetDealChangeItems($id: Int!) {
  deal(id: $id) {
    name
    isWorkingDays
    finalResidentialTCO {
      id
      startDate
      endDate
      type
    }
    originalGcContingencyBudget
    currentGcContingencyBudget
    forecastedGcContingencyChanges
    originalGcContractCommittedAmount
    gcContractSavingsPercentage
    originalBudgetRevision {
      id
      name
      approvedDate
      total
    }
    originalGcContractSchedule {
      id
      description
      createdAt
      updatedAt
      finalResidentialTCO {
        id
        startDate
        endDate
        type
      }
    }
    generalContractor {
      id
      name
    }
    contingencyReconciliationSummary {
      approvedOwnerContingencies
      forecastedUses
      balanceAfterForecast
      ownerShareOfGcContingencySavings
      totalForeacstedBalanceIncludingSavings
    }
    generalContractorSummary {
      originalContractAmount
      issuedChangeOrdersAmount
      gcPercentComplete
    }
    changeItemReconciliation {
      costSource {
        id
        name
        code
        displayCode
      }
      title
      forecastedChanges
      adjustmentsToDate
      balanceAfterForecast
      comparisonBudgetAmount
      currentBudgetAmount
      percentUsedMinusGcPercentComplete
    }
    pipeline {
      generalContractorAccount {
        id
        name
        displayCode
        code
      }
      contingencyCategorizations {
        id
        account {
          id
          name
          code
          displayCode
        }
        category {
          id
          name
        }
      }
    }
    changeEvents {
      ...ChangeEventFragment
    }
    potentialChangeOrders {
      ...PotentialChangeOrderFragment
    }
    changeOrderPackages {
      ...ChangeOrderPackageFragment
    }
  }
}
    ${ChangeEventFragment}
${PotentialChangeOrderFragment}
${ChangeOrderPackageFragment}`;
export const GetDealChangeEventDocument = gql`
    query GetDealChangeEvent($id: Int!, $fkChangeEvent: Int!) {
  deal(id: $id) {
    changeEvent(id: $fkChangeEvent) {
      ...ChangeEventFragment
    }
  }
}
    ${ChangeEventFragment}`;
export const GetDealPotentialChangeOrdersDocument = gql`
    query GetDealPotentialChangeOrders($id: Int!) {
  deal(id: $id) {
    potentialChangeOrders {
      ...PotentialChangeOrderFragment
    }
  }
}
    ${PotentialChangeOrderFragment}`;
export const GetDealPotentialChangeOrderDocument = gql`
    query GetDealPotentialChangeOrder($id: Int!, $fkPotentialChangeOrder: Int!) {
  deal(id: $id) {
    potentialChangeOrder(id: $fkPotentialChangeOrder) {
      ...PotentialChangeOrderFragment
    }
  }
}
    ${PotentialChangeOrderFragment}`;
export const GetDealChangeOrderPackagesDocument = gql`
    query GetDealChangeOrderPackages($id: Int!) {
  deal(id: $id) {
    changeOrderPackages {
      ...ChangeOrderPackageFragment
    }
  }
}
    ${ChangeOrderPackageFragment}`;
export const GetDealChangeOrderPackageDocument = gql`
    query GetDealChangeOrderPackage($id: Int!, $fkChangeOrderPackage: Int!) {
  deal(id: $id) {
    changeOrderPackage(id: $fkChangeOrderPackage) {
      ...ChangeOrderPackageFragment
    }
  }
}
    ${ChangeOrderPackageFragment}`;
export const GetChangeOrderStatusesDocument = gql`
    query GetChangeOrderStatuses {
  changeOrderStatuses {
    id
    name
  }
}
    `;
export const GetPotentialChangeOrderTypesDocument = gql`
    query GetPotentialChangeOrderTypes {
  potentialChangeOrderTypes {
    id
    name
  }
}
    `;
export const GetChangeEventTypesDocument = gql`
    query GetChangeEventTypes {
  changeEventTypes {
    id
    name
  }
}
    `;
export const GetChangeEventReasonsDocument = gql`
    query GetChangeEventReasons {
  changeEventReasons {
    id
    name
  }
}
    `;
export const GetApprovalPackagesDocument = gql`
    query GetApprovalPackages {
  approvalsDashboard {
    needsApproval
    waitingForOthers
    completed
    all
    pending
  }
  approvalPackages {
    id
    reviewDate
    isPublished
    type {
      id
      name
    }
    status
    amendmentNumber
    deal {
      id
      name
    }
    dealType {
      name
    }
    reviewers {
      ...ApprovalPackageReviewerFragment
    }
    needsCurrentUserReview
    waitingForOthers
    archiveDate
  }
}
    ${ApprovalPackageReviewerFragment}`;
export const GetDealApprovalPackagesDocument = gql`
    query GetDealApprovalPackages($id: Int!) {
  deal(id: $id) {
    id
    name
    approvalPackages {
      id
      reviewDate
      isPublished
      type {
        id
        name
      }
      status
      amendmentNumber
      deal {
        id
        name
      }
      needsCurrentUserReview
      waitingForOthers
      archiveDate
    }
  }
}
    `;
export const GetApprovalPackageDocument = gql`
    query GetApprovalPackage($id: Int!) {
  approvalPackage(id: $id) {
    id
    updatedAt
    createdAt
    reviewDate
    isPublished
    archiveDate
    currentUserIsContributor
    currentUserIsReviewer
    type {
      id
      name
    }
    status
    amendmentNumber
    deal {
      ... on Deal {
        id
        name
        pipeline {
          id
          name
        }
        fund {
          id
          name
        }
      }
    }
    analysis {
      id
      description
      createdAt
      scenario {
        id
        name
      }
    }
    schedule {
      id
      description
      createdAt
    }
    sections {
      id
      title
      orderId
      gridColumnWidth
      content {
        ...ApprovalPackageSubSectionFragment
      }
    }
    reviewers {
      ...ApprovalPackageReviewerFragment
    }
    contributors {
      ...ApprovalPackageContributorFragment
    }
    exhibits {
      id
      type
      name
      displayName
      fileExtension
      createdAt
      createdBy {
        id
        displayName
        displayNameAlt
      }
      updatedAt
      updatedBy {
        id
        displayName
        displayNameAlt
      }
    }
  }
}
    ${ApprovalPackageSubSectionFragment}
${ApprovalPackageReviewerFragment}
${ApprovalPackageContributorFragment}`;
export const GetApprovalPackageStatusesDocument = gql`
    query GetApprovalPackageStatuses {
  approvalPackageStatuses {
    id
    name
  }
}
    `;
export const GetApprovalPackageReviewerStatusesDocument = gql`
    query GetApprovalPackageReviewerStatuses {
  approvalPackageReviewerStatuses {
    id
    name
  }
}
    `;
export const GetApprovalPackageTypesDocument = gql`
    query GetApprovalPackageTypes {
  approvalPackageTypes {
    ...ApprovalPackageTypeFragment
  }
}
    ${ApprovalPackageTypeFragment}`;
export const GetApprovalPackageTypeDocument = gql`
    query GetApprovalPackageType($id: Int!) {
  approvalPackageType(id: $id) {
    id
    name
    defaultTemplate {
      id
      name
    }
    event {
      id
      name
      type
    }
  }
}
    `;
export const GetPublishedApprovalPackageDocument = gql`
    query GetPublishedApprovalPackage($id: Int!) {
  publishedApprovalPackage(id: $id) {
    id
    fkPackage
    updatedAt
    createdAt
    reviewDate
    isPublished
    currentUserIsContributor
    currentUserIsReviewer
    archiveDate
    type {
      id
      name
    }
    status
    amendmentNumber
    deal {
      ... on Deal {
        id
        name
        pipeline {
          id
          name
        }
        fund {
          id
          name
        }
      }
    }
    analysis {
      id
      description
      createdAt
      scenario {
        id
        name
      }
    }
    schedule {
      id
      description
      createdAt
    }
    sections {
      id
      title
      orderId
      gridColumnWidth
      content {
        ...PublishedApprovalPackageSubSectionFragment
      }
    }
    reviewers {
      ...ApprovalPackageReviewerFragment
    }
    contributors {
      ...ApprovalPackageContributorFragment
    }
    exhibits {
      id
      type
      name
      displayName
      fileExtension
      createdAt
      createdBy {
        id
        displayName
        displayNameAlt
      }
      updatedAt
      updatedBy {
        id
        displayName
        displayNameAlt
      }
    }
  }
}
    ${PublishedApprovalPackageSubSectionFragment}
${ApprovalPackageReviewerFragment}
${ApprovalPackageContributorFragment}`;
export const GetApprovalPackageTableCellEntryOptionsDocument = gql`
    query GetApprovalPackageTableCellEntryOptions {
  cellEntryOptions {
    id
    name
    type
  }
}
    `;
export const GetApprovalPackageTemplatesDocument = gql`
    query GetApprovalPackageTemplates {
  approvalPackageTemplates {
    id
    name
  }
}
    `;
export const GetApprovalPackageTemplateDocument = gql`
    query GetApprovalPackageTemplate($id: Int!) {
  approvalPackageTemplate(id: $id) {
    id
    name
    updatedAt
    createdAt
    sections {
      id
      title
      orderId
      gridColumnWidth
      content {
        ...TemplateApprovalPackageSubSectionFragment
      }
    }
  }
}
    ${TemplateApprovalPackageSubSectionFragment}`;
export const GetLabelsDocument = gql`
    query GetLabels {
  labels {
    id
    name
    color
  }
}
    `;
export const GetReportTypesDocument = gql`
    query GetReportTypes {
  reportTypes {
    id
    name
  }
}
    `;
export const GetInvestmentTypesDocument = gql`
    query GetInvestmentTypes {
  investmentTypes {
    id
    name
  }
}
    `;
export const GetCrmStatusesDocument = gql`
    query GetCrmStatuses {
  crmStatuses {
    id
    name
  }
}
    `;
export const GetFirmTypesDocument = gql`
    query GetFirmTypes {
  firmTypes {
    id
    name
  }
}
    `;
export const GetInteractionTypesDocument = gql`
    query GetInteractionTypes {
  interactionTypes {
    id
    name
  }
}
    `;
export const GetArtifactTypesDocument = gql`
    query GetArtifactTypes {
  artifactTypes {
    id
    name
  }
}
    `;
export const GetTransactionPeriodSummariesDocument = gql`
    query GetTransactionPeriodSummaries($id: Int!, $fkGlAccount: Int!, $period: Date!) {
  deal(id: $id) {
    transactionPeriodSummaries(fkGlAccount: $fkGlAccount, period: $period) {
      id
      description
      amount
      postDate
      account {
        id
        name
        code
        displayCode
      }
    }
  }
}
    `;
export const GetDealArtifactsDocument = gql`
    query GetDealArtifacts($id: Int!) {
  deal(id: $id) {
    artifacts {
      id
      name
      fileExtension
      fkArtifact
      createdAt
      createdBy {
        id
        displayName
        displayNameAlt
      }
      updatedAt
      updatedBy {
        id
        displayName
        displayNameAlt
      }
    }
  }
}
    `;
export const GetContactAttachmentsDocument = gql`
    query GetContactAttachments($id: Int!) {
  contact(id: $id) {
    attachments {
      id
      displayName
      fileExtension
      fkDocument
      createdAt
      createdBy {
        id
        displayName
        displayNameAlt
      }
      updatedAt
      updatedBy {
        id
        displayName
        displayNameAlt
      }
    }
  }
}
    `;
export const GetFirmAttachmentsDocument = gql`
    query GetFirmAttachments($id: Int!) {
  firm(id: $id) {
    attachments {
      id
      displayName
      fileExtension
      fkDocument
      createdAt
      createdBy {
        id
        displayName
        displayNameAlt
      }
      updatedAt
      updatedBy {
        id
        displayName
        displayNameAlt
      }
    }
  }
}
    `;
export const GetInterestRatesDocument = gql`
    query GetInterestRates {
  interestRates {
    id
    value
    startDate
    endDate
  }
}
    `;
export const GetForecastInterestAccountStatusDocument = gql`
    query GetForecastInterestAccountStatus($id: Int!) {
  forecastInterestAccountStatus(id: $id) {
    sessionId
    transactionsTotal
    transactionsLeft
  }
}
    `;
export const GetLatestPipelineAnalysisMetricDistributionDocument = gql`
    query GetLatestPipelineAnalysisMetricDistribution($analysisMetricId: Int!) {
  latestPipelineAnalysisMetricDistribution(analysisMetricId: $analysisMetricId)
}
    `;
export const GetCategoricalDealChartsDocument = gql`
    query GetCategoricalDealCharts($id: Int!, $birthDate: Date!, $dealTypeIds: [Int!]!) {
  fundChart(id: $id, birthDate: $birthDate, dealTypeIds: $dealTypeIds) {
    name
    value
  }
  stateChart(id: $id, birthDate: $birthDate, dealTypeIds: $dealTypeIds) {
    name
    value
  }
  stageChart(id: $id, birthDate: $birthDate, dealTypeIds: $dealTypeIds) {
    name
    value
  }
  projectLeadChart(id: $id, birthDate: $birthDate, dealTypeIds: $dealTypeIds) {
    name
    value
  }
}
    `;
export const GetNumericalDealChartsDocument = gql`
    query GetNumericalDealCharts($id: Int!, $birthDate: Date!, $dealTypeIds: [Int!]!) {
  staticIrrChart(id: $id, birthDate: $birthDate, dealTypeIds: $dealTypeIds)
}
    `;
export const GetAnalyticsSummaryDocument = gql`
    query GetAnalyticsSummary($id: Int!, $birthDate: Date!, $dealTypeIds: [Int!]!) {
  analyticsSummary(id: $id, birthDate: $birthDate, dealTypeIds: $dealTypeIds) {
    totalUnits {
      value
      metric {
        name
        precision
        unit
        scale
        format
      }
    }
    totalSquareFootage {
      value
      metric {
        name
        precision
        unit
        scale
        format
      }
    }
    developmentCosts {
      value
      metric {
        name
        precision
        unit
        scale
        format
      }
    }
    dealCount
  }
}
    `;
export const GetFundChartDocument = gql`
    query GetFundChart($id: Int!, $birthDate: Date!, $dealTypeIds: [Int!]!) {
  coreMarketCapChart(id: $id, birthDate: $birthDate, dealTypeIds: $dealTypeIds) {
    name
    value
  }
  dealTypeChart(id: $id, birthDate: $birthDate, dealTypeIds: $dealTypeIds) {
    name
    value
  }
}
    `;
export const GetDealQuarterEventsChartDocument = gql`
    query GetDealQuarterEventsChart($pipelineId: Int!, $fkEvent: Int!, $yearsBack: Int!, $dealTypeIds: [Int!]!) {
  dealQuarterEventsChart(
    pipelineId: $pipelineId
    fkEvent: $fkEvent
    yearsBack: $yearsBack
    dealTypeIds: $dealTypeIds
  ) {
    name
    value
  }
}
    `;
export const GetMarketViewDocument = gql`
    query GetMarketView($pipelineId: Int!, $birthDate: Date!, $includeArchivedDeals: Boolean!, $marketStatistic: MarketStatisticEnum!) {
  marketView {
    columns(pipelineId: $pipelineId) {
      id
      name
      format
      type
      lookupName
    }
    payload(
      pipelineId: $pipelineId
      birthDate: $birthDate
      includeArchivedDeals: $includeArchivedDeals
      marketStatistic: $marketStatistic
    ) {
      columns
      rows
    }
  }
}
    `;

export type SdkFunctionWrapper = <T>(action: () => Promise<T>) => Promise<T>;


const defaultWrapper: SdkFunctionWrapper = sdkFunction => sdkFunction();
export function getSdk(client: GraphQLClient, withWrapper: SdkFunctionWrapper = defaultWrapper) {
  return {
    CreateDeal(variables: CreateDealVariables): Promise<{ data?: CreateDeal | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<CreateDeal>(print(CreateDealDocument), variables));
    },
    CreatePortfolio(variables: CreatePortfolioVariables): Promise<{ data?: CreatePortfolio | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<CreatePortfolio>(print(CreatePortfolioDocument), variables));
    },
    UpdatePortfolio(variables: UpdatePortfolioVariables): Promise<{ data?: UpdatePortfolio | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<UpdatePortfolio>(print(UpdatePortfolioDocument), variables));
    },
    UpdateDynamicPortfolioFilters(variables: UpdateDynamicPortfolioFiltersVariables): Promise<{ data?: UpdateDynamicPortfolioFilters | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<UpdateDynamicPortfolioFilters>(print(UpdateDynamicPortfolioFiltersDocument), variables));
    },
    AddTeamMember(variables: AddTeamMemberVariables): Promise<{ data?: AddTeamMember | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<AddTeamMember>(print(AddTeamMemberDocument), variables));
    },
    CreateTeamMemberRole(variables: CreateTeamMemberRoleVariables): Promise<{ data?: CreateTeamMemberRole | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<CreateTeamMemberRole>(print(CreateTeamMemberRoleDocument), variables));
    },
    UpdateTeamMemberRole(variables: UpdateTeamMemberRoleVariables): Promise<{ data?: UpdateTeamMemberRole | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<UpdateTeamMemberRole>(print(UpdateTeamMemberRoleDocument), variables));
    },
    DeleteTeamMemberRole(variables: DeleteTeamMemberRoleVariables): Promise<{ data?: DeleteTeamMemberRole | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<DeleteTeamMemberRole>(print(DeleteTeamMemberRoleDocument), variables));
    },
    CreatePartnerRole(variables: CreatePartnerRoleVariables): Promise<{ data?: CreatePartnerRole | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<CreatePartnerRole>(print(CreatePartnerRoleDocument), variables));
    },
    UpdatePartnerRole(variables: UpdatePartnerRoleVariables): Promise<{ data?: UpdatePartnerRole | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<UpdatePartnerRole>(print(UpdatePartnerRoleDocument), variables));
    },
    DeletePartnerRole(variables: DeletePartnerRoleVariables): Promise<{ data?: DeletePartnerRole | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<DeletePartnerRole>(print(DeletePartnerRoleDocument), variables));
    },
    CreateContact(variables: CreateContactVariables): Promise<{ data?: CreateContact | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<CreateContact>(print(CreateContactDocument), variables));
    },
    CreateFirm(variables: CreateFirmVariables): Promise<{ data?: CreateFirm | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<CreateFirm>(print(CreateFirmDocument), variables));
    },
    UpdateFirm(variables: UpdateFirmVariables): Promise<{ data?: UpdateFirm | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<UpdateFirm>(print(UpdateFirmDocument), variables));
    },
    DeleteFirm(variables: DeleteFirmVariables): Promise<{ data?: DeleteFirm | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<DeleteFirm>(print(DeleteFirmDocument), variables));
    },
    CreateFirmRegion(variables: CreateFirmRegionVariables): Promise<{ data?: CreateFirmRegion | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<CreateFirmRegion>(print(CreateFirmRegionDocument), variables));
    },
    UpdateFirmRegion(variables: UpdateFirmRegionVariables): Promise<{ data?: UpdateFirmRegion | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<UpdateFirmRegion>(print(UpdateFirmRegionDocument), variables));
    },
    DeleteFirmRegion(variables: DeleteFirmRegionVariables): Promise<{ data?: DeleteFirmRegion | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<DeleteFirmRegion>(print(DeleteFirmRegionDocument), variables));
    },
    CreateInteraction(variables: CreateInteractionVariables): Promise<{ data?: CreateInteraction | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<CreateInteraction>(print(CreateInteractionDocument), variables));
    },
    UpdateInteraction(variables: UpdateInteractionVariables): Promise<{ data?: UpdateInteraction | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<UpdateInteraction>(print(UpdateInteractionDocument), variables));
    },
    DeleteInteraction(variables: DeleteInteractionVariables): Promise<{ data?: DeleteInteraction | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<DeleteInteraction>(print(DeleteInteractionDocument), variables));
    },
    AssignContacts(variables: AssignContactsVariables): Promise<{ data?: AssignContacts | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<AssignContacts>(print(AssignContactsDocument), variables));
    },
    UnassignContacts(variables: UnassignContactsVariables): Promise<{ data?: UnassignContacts | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<UnassignContacts>(print(UnassignContactsDocument), variables));
    },
    UpdateTeamMember(variables: UpdateTeamMemberVariables): Promise<{ data?: UpdateTeamMember | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<UpdateTeamMember>(print(UpdateTeamMemberDocument), variables));
    },
    UpdateTeamMembers(variables: UpdateTeamMembersVariables): Promise<{ data?: UpdateTeamMembers | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<UpdateTeamMembers>(print(UpdateTeamMembersDocument), variables));
    },
    RemoveTeamMember(variables: RemoveTeamMemberVariables): Promise<{ data?: RemoveTeamMember | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<RemoveTeamMember>(print(RemoveTeamMemberDocument), variables));
    },
    AddDealPartner(variables: AddDealPartnerVariables): Promise<{ data?: AddDealPartner | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<AddDealPartner>(print(AddDealPartnerDocument), variables));
    },
    UpdateDealPartner(variables: UpdateDealPartnerVariables): Promise<{ data?: UpdateDealPartner | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<UpdateDealPartner>(print(UpdateDealPartnerDocument), variables));
    },
    UpdateDealPartners(variables: UpdateDealPartnersVariables): Promise<{ data?: UpdateDealPartners | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<UpdateDealPartners>(print(UpdateDealPartnersDocument), variables));
    },
    RemoveDealPartner(variables: RemoveDealPartnerVariables): Promise<{ data?: RemoveDealPartner | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<RemoveDealPartner>(print(RemoveDealPartnerDocument), variables));
    },
    UpdateContact(variables: UpdateContactVariables): Promise<{ data?: UpdateContact | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<UpdateContact>(print(UpdateContactDocument), variables));
    },
    DeleteContact(variables: DeleteContactVariables): Promise<{ data?: DeleteContact | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<DeleteContact>(print(DeleteContactDocument), variables));
    },
    UpdateDealChecklistitem(variables: UpdateDealChecklistitemVariables): Promise<{ data?: UpdateDealChecklistitem | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<UpdateDealChecklistitem>(print(UpdateDealChecklistitemDocument), variables));
    },
    CreateChecklist(variables: CreateChecklistVariables): Promise<{ data?: CreateChecklist | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<CreateChecklist>(print(CreateChecklistDocument), variables));
    },
    UpdateChecklist(variables: UpdateChecklistVariables): Promise<{ data?: UpdateChecklist | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<UpdateChecklist>(print(UpdateChecklistDocument), variables));
    },
    AddChecklistToDeal(variables: AddChecklistToDealVariables): Promise<{ data?: AddChecklistToDeal | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<AddChecklistToDeal>(print(AddChecklistToDealDocument), variables));
    },
    RemoveChecklistFromDeal(variables: RemoveChecklistFromDealVariables): Promise<{ data?: RemoveChecklistFromDeal | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<RemoveChecklistFromDeal>(print(RemoveChecklistFromDealDocument), variables));
    },
    CreateChecklistItem(variables: CreateChecklistItemVariables): Promise<{ data?: CreateChecklistItem | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<CreateChecklistItem>(print(CreateChecklistItemDocument), variables));
    },
    UpdateChecklistItem(variables: UpdateChecklistItemVariables): Promise<{ data?: UpdateChecklistItem | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<UpdateChecklistItem>(print(UpdateChecklistItemDocument), variables));
    },
    DeleteChecklist(variables: DeleteChecklistVariables): Promise<{ data?: DeleteChecklist | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<DeleteChecklist>(print(DeleteChecklistDocument), variables));
    },
    DeleteChecklistItem(variables: DeleteChecklistItemVariables): Promise<{ data?: DeleteChecklistItem | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<DeleteChecklistItem>(print(DeleteChecklistItemDocument), variables));
    },
    CreateStatusUpdate(variables: CreateStatusUpdateVariables): Promise<{ data?: CreateStatusUpdate | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<CreateStatusUpdate>(print(CreateStatusUpdateDocument), variables));
    },
    UpdateStatusUpdate(variables: UpdateStatusUpdateVariables): Promise<{ data?: UpdateStatusUpdate | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<UpdateStatusUpdate>(print(UpdateStatusUpdateDocument), variables));
    },
    DeleteStatusUpdate(variables: DeleteStatusUpdateVariables): Promise<{ data?: DeleteStatusUpdate | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<DeleteStatusUpdate>(print(DeleteStatusUpdateDocument), variables));
    },
    CreateStatusUpdateTemplate(variables: CreateStatusUpdateTemplateVariables): Promise<{ data?: CreateStatusUpdateTemplate | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<CreateStatusUpdateTemplate>(print(CreateStatusUpdateTemplateDocument), variables));
    },
    UpdateStatusUpdateTemplate(variables: UpdateStatusUpdateTemplateVariables): Promise<{ data?: UpdateStatusUpdateTemplate | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<UpdateStatusUpdateTemplate>(print(UpdateStatusUpdateTemplateDocument), variables));
    },
    DeleteStatusUpdateTemplate(variables: DeleteStatusUpdateTemplateVariables): Promise<{ data?: DeleteStatusUpdateTemplate | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<DeleteStatusUpdateTemplate>(print(DeleteStatusUpdateTemplateDocument), variables));
    },
    UpdateDeal(variables: UpdateDealVariables): Promise<{ data?: UpdateDeal | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<UpdateDeal>(print(UpdateDealDocument), variables));
    },
    CreateChecklistCategory(variables: CreateChecklistCategoryVariables): Promise<{ data?: CreateChecklistCategory | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<CreateChecklistCategory>(print(CreateChecklistCategoryDocument), variables));
    },
    CreateKeyRisk(variables: CreateKeyRiskVariables): Promise<{ data?: CreateKeyRisk | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<CreateKeyRisk>(print(CreateKeyRiskDocument), variables));
    },
    UpdateKeyRisk(variables: UpdateKeyRiskVariables): Promise<{ data?: UpdateKeyRisk | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<UpdateKeyRisk>(print(UpdateKeyRiskDocument), variables));
    },
    DeleteKeyRisk(variables: DeleteKeyRiskVariables): Promise<{ data?: DeleteKeyRisk | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<DeleteKeyRisk>(print(DeleteKeyRiskDocument), variables));
    },
    CreateDealMerit(variables: CreateDealMeritVariables): Promise<{ data?: CreateDealMerit | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<CreateDealMerit>(print(CreateDealMeritDocument), variables));
    },
    UpdateDealMerit(variables: UpdateDealMeritVariables): Promise<{ data?: UpdateDealMerit | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<UpdateDealMerit>(print(UpdateDealMeritDocument), variables));
    },
    DeleteDealMerit(variables: DeleteDealMeritVariables): Promise<{ data?: DeleteDealMerit | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<DeleteDealMerit>(print(DeleteDealMeritDocument), variables));
    },
    ReorderPipelinePinnedMetric(variables: ReorderPipelinePinnedMetricVariables): Promise<{ data?: ReorderPipelinePinnedMetric | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<ReorderPipelinePinnedMetric>(print(ReorderPipelinePinnedMetricDocument), variables));
    },
    ReorderPipelinePinnedEvent(variables: ReorderPipelinePinnedEventVariables): Promise<{ data?: ReorderPipelinePinnedEvent | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<ReorderPipelinePinnedEvent>(print(ReorderPipelinePinnedEventDocument), variables));
    },
    ReorderPipelinePinnedPartnerRole(variables: ReorderPipelinePinnedPartnerRoleVariables): Promise<{ data?: ReorderPipelinePinnedPartnerRole | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<ReorderPipelinePinnedPartnerRole>(print(ReorderPipelinePinnedPartnerRoleDocument), variables));
    },
    ReorderPipelinePinnedTeamMemberRole(variables: ReorderPipelinePinnedTeamMemberRoleVariables): Promise<{ data?: ReorderPipelinePinnedTeamMemberRole | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<ReorderPipelinePinnedTeamMemberRole>(print(ReorderPipelinePinnedTeamMemberRoleDocument), variables));
    },
    CreatePipelineDealStage(variables: CreatePipelineDealStageVariables): Promise<{ data?: CreatePipelineDealStage | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<CreatePipelineDealStage>(print(CreatePipelineDealStageDocument), variables));
    },
    UpdatePipelineDealStage(variables: UpdatePipelineDealStageVariables): Promise<{ data?: UpdatePipelineDealStage | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<UpdatePipelineDealStage>(print(UpdatePipelineDealStageDocument), variables));
    },
    ReorderPipelineDealStage(variables: ReorderPipelineDealStageVariables): Promise<{ data?: ReorderPipelineDealStage | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<ReorderPipelineDealStage>(print(ReorderPipelineDealStageDocument), variables));
    },
    DeletePipelineDealStage(variables: DeletePipelineDealStageVariables): Promise<{ data?: DeletePipelineDealStage | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<DeletePipelineDealStage>(print(DeletePipelineDealStageDocument), variables));
    },
    CreateConfigurableEntity(variables: CreateConfigurableEntityVariables): Promise<{ data?: CreateConfigurableEntity | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<CreateConfigurableEntity>(print(CreateConfigurableEntityDocument), variables));
    },
    UpdateConfigurableEntity(variables: UpdateConfigurableEntityVariables): Promise<{ data?: UpdateConfigurableEntity | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<UpdateConfigurableEntity>(print(UpdateConfigurableEntityDocument), variables));
    },
    DeleteConfigurableEntity(variables: DeleteConfigurableEntityVariables): Promise<{ data?: DeleteConfigurableEntity | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<DeleteConfigurableEntity>(print(DeleteConfigurableEntityDocument), variables));
    },
    UpdateOrganizationMemberRole(variables: UpdateOrganizationMemberRoleVariables): Promise<{ data?: UpdateOrganizationMemberRole | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<UpdateOrganizationMemberRole>(print(UpdateOrganizationMemberRoleDocument), variables));
    },
    AssignMembersToOrganization(variables: AssignMembersToOrganizationVariables): Promise<{ data?: AssignMembersToOrganization | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<AssignMembersToOrganization>(print(AssignMembersToOrganizationDocument), variables));
    },
    RemoveMembersFromOrganization(variables: RemoveMembersFromOrganizationVariables): Promise<{ data?: RemoveMembersFromOrganization | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<RemoveMembersFromOrganization>(print(RemoveMembersFromOrganizationDocument), variables));
    },
    UpdateDealMemberRole(variables: UpdateDealMemberRoleVariables): Promise<{ data?: UpdateDealMemberRole | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<UpdateDealMemberRole>(print(UpdateDealMemberRoleDocument), variables));
    },
    AssignMembersToDeal(variables: AssignMembersToDealVariables): Promise<{ data?: AssignMembersToDeal | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<AssignMembersToDeal>(print(AssignMembersToDealDocument), variables));
    },
    RemoveMembersFromDeal(variables: RemoveMembersFromDealVariables): Promise<{ data?: RemoveMembersFromDeal | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<RemoveMembersFromDeal>(print(RemoveMembersFromDealDocument), variables));
    },
    UpdatePipelineMemberRole(variables: UpdatePipelineMemberRoleVariables): Promise<{ data?: UpdatePipelineMemberRole | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<UpdatePipelineMemberRole>(print(UpdatePipelineMemberRoleDocument), variables));
    },
    AssignMembersToPipeline(variables: AssignMembersToPipelineVariables): Promise<{ data?: AssignMembersToPipeline | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<AssignMembersToPipeline>(print(AssignMembersToPipelineDocument), variables));
    },
    RemoveMembersFromPipeline(variables: RemoveMembersFromPipelineVariables): Promise<{ data?: RemoveMembersFromPipeline | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<RemoveMembersFromPipeline>(print(RemoveMembersFromPipelineDocument), variables));
    },
    UpdatePortfolioMemberRole(variables: UpdatePortfolioMemberRoleVariables): Promise<{ data?: UpdatePortfolioMemberRole | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<UpdatePortfolioMemberRole>(print(UpdatePortfolioMemberRoleDocument), variables));
    },
    AssignMembersToPortfolio(variables: AssignMembersToPortfolioVariables): Promise<{ data?: AssignMembersToPortfolio | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<AssignMembersToPortfolio>(print(AssignMembersToPortfolioDocument), variables));
    },
    RemoveMembersFromPortfolio(variables: RemoveMembersFromPortfolioVariables): Promise<{ data?: RemoveMembersFromPortfolio | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<RemoveMembersFromPortfolio>(print(RemoveMembersFromPortfolioDocument), variables));
    },
    UpdatePortfolioViewColumn(variables: UpdatePortfolioViewColumnVariables): Promise<{ data?: UpdatePortfolioViewColumn | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<UpdatePortfolioViewColumn>(print(UpdatePortfolioViewColumnDocument), variables));
    },
    AddPortfolioViewColumn(variables: AddPortfolioViewColumnVariables): Promise<{ data?: AddPortfolioViewColumn | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<AddPortfolioViewColumn>(print(AddPortfolioViewColumnDocument), variables));
    },
    RemovePortfolioViewColumns(variables: RemovePortfolioViewColumnsVariables): Promise<{ data?: RemovePortfolioViewColumns | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<RemovePortfolioViewColumns>(print(RemovePortfolioViewColumnsDocument), variables));
    },
    AddPortfolioDeals(variables: AddPortfolioDealsVariables): Promise<{ data?: AddPortfolioDeals | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<AddPortfolioDeals>(print(AddPortfolioDealsDocument), variables));
    },
    RemovePortfolioDeals(variables: RemovePortfolioDealsVariables): Promise<{ data?: RemovePortfolioDeals | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<RemovePortfolioDeals>(print(RemovePortfolioDealsDocument), variables));
    },
    CreateEvent(variables: CreateEventVariables): Promise<{ data?: CreateEvent | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<CreateEvent>(print(CreateEventDocument), variables));
    },
    UpdateEvent(variables: UpdateEventVariables): Promise<{ data?: UpdateEvent | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<UpdateEvent>(print(UpdateEventDocument), variables));
    },
    DeleteEvent(variables: DeleteEventVariables): Promise<{ data?: DeleteEvent | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<DeleteEvent>(print(DeleteEventDocument), variables));
    },
    CreateDealEvent(variables: CreateDealEventVariables): Promise<{ data?: CreateDealEvent | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<CreateDealEvent>(print(CreateDealEventDocument), variables));
    },
    UpdateDealEvent(variables: UpdateDealEventVariables): Promise<{ data?: UpdateDealEvent | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<UpdateDealEvent>(print(UpdateDealEventDocument), variables));
    },
    CreateAnalysis(variables: CreateAnalysisVariables): Promise<{ data?: CreateAnalysis | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<CreateAnalysis>(print(CreateAnalysisDocument), variables));
    },
    UpdateAnalysis(variables: UpdateAnalysisVariables): Promise<{ data?: UpdateAnalysis | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<UpdateAnalysis>(print(UpdateAnalysisDocument), variables));
    },
    DeleteAnalysis(variables: DeleteAnalysisVariables): Promise<{ data?: DeleteAnalysis | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<DeleteAnalysis>(print(DeleteAnalysisDocument), variables));
    },
    CreateAnalysisType(variables: CreateAnalysisTypeVariables): Promise<{ data?: CreateAnalysisType | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<CreateAnalysisType>(print(CreateAnalysisTypeDocument), variables));
    },
    UpdateAnalysisType(variables: UpdateAnalysisTypeVariables): Promise<{ data?: UpdateAnalysisType | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<UpdateAnalysisType>(print(UpdateAnalysisTypeDocument), variables));
    },
    DeleteAnalysisType(variables: DeleteAnalysisTypeVariables): Promise<{ data?: DeleteAnalysisType | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<DeleteAnalysisType>(print(DeleteAnalysisTypeDocument), variables));
    },
    CreateAnalysisMetric(variables: CreateAnalysisMetricVariables): Promise<{ data?: CreateAnalysisMetric | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<CreateAnalysisMetric>(print(CreateAnalysisMetricDocument), variables));
    },
    UpdateAnalysisMetric(variables: UpdateAnalysisMetricVariables): Promise<{ data?: UpdateAnalysisMetric | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<UpdateAnalysisMetric>(print(UpdateAnalysisMetricDocument), variables));
    },
    DeleteAnalysisMetric(variables: DeleteAnalysisMetricVariables): Promise<{ data?: DeleteAnalysisMetric | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<DeleteAnalysisMetric>(print(DeleteAnalysisMetricDocument), variables));
    },
    CreateAnalysisTextMetric(variables: CreateAnalysisTextMetricVariables): Promise<{ data?: CreateAnalysisTextMetric | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<CreateAnalysisTextMetric>(print(CreateAnalysisTextMetricDocument), variables));
    },
    UpdateAnalysisTextMetric(variables: UpdateAnalysisTextMetricVariables): Promise<{ data?: UpdateAnalysisTextMetric | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<UpdateAnalysisTextMetric>(print(UpdateAnalysisTextMetricDocument), variables));
    },
    DeleteAnalysisTextMetric(variables: DeleteAnalysisTextMetricVariables): Promise<{ data?: DeleteAnalysisTextMetric | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<DeleteAnalysisTextMetric>(print(DeleteAnalysisTextMetricDocument), variables));
    },
    CreateAnalysisEvent(variables: CreateAnalysisEventVariables): Promise<{ data?: CreateAnalysisEvent | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<CreateAnalysisEvent>(print(CreateAnalysisEventDocument), variables));
    },
    UpdateAnalysisEvent(variables: UpdateAnalysisEventVariables): Promise<{ data?: UpdateAnalysisEvent | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<UpdateAnalysisEvent>(print(UpdateAnalysisEventDocument), variables));
    },
    DeleteAnalysisEvent(variables: DeleteAnalysisEventVariables): Promise<{ data?: DeleteAnalysisEvent | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<DeleteAnalysisEvent>(print(DeleteAnalysisEventDocument), variables));
    },
    DuplicateAnalysis(variables: DuplicateAnalysisVariables): Promise<{ data?: DuplicateAnalysis | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<DuplicateAnalysis>(print(DuplicateAnalysisDocument), variables));
    },
    ArchiveAnalysis(variables: ArchiveAnalysisVariables): Promise<{ data?: ArchiveAnalysis | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<ArchiveAnalysis>(print(ArchiveAnalysisDocument), variables));
    },
    CreateContract(variables: CreateContractVariables): Promise<{ data?: CreateContract | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<CreateContract>(print(CreateContractDocument), variables));
    },
    UpdateContract(variables: UpdateContractVariables): Promise<{ data?: UpdateContract | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<UpdateContract>(print(UpdateContractDocument), variables));
    },
    DeleteContract(variables: DeleteContractVariables): Promise<{ data?: DeleteContract | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<DeleteContract>(print(DeleteContractDocument), variables));
    },
    PublishCapitalForecast(variables: PublishCapitalForecastVariables): Promise<{ data?: PublishCapitalForecast | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<PublishCapitalForecast>(print(PublishCapitalForecastDocument), variables));
    },
    DeletePublishedCapitalForecast(variables: DeletePublishedCapitalForecastVariables): Promise<{ data?: DeletePublishedCapitalForecast | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<DeletePublishedCapitalForecast>(print(DeletePublishedCapitalForecastDocument), variables));
    },
    CreateRevision(variables: CreateRevisionVariables): Promise<{ data?: CreateRevision | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<CreateRevision>(print(CreateRevisionDocument), variables));
    },
    UpdateRevision(variables: UpdateRevisionVariables): Promise<{ data?: UpdateRevision | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<UpdateRevision>(print(UpdateRevisionDocument), variables));
    },
    DeleteRevision(variables: DeleteRevisionVariables): Promise<{ data?: DeleteRevision | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<DeleteRevision>(print(DeleteRevisionDocument), variables));
    },
    CreateRevisionCostAccount(variables: CreateRevisionCostAccountVariables): Promise<{ data?: CreateRevisionCostAccount | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<CreateRevisionCostAccount>(print(CreateRevisionCostAccountDocument), variables));
    },
    UpdateRevisionCostAccount(variables: UpdateRevisionCostAccountVariables): Promise<{ data?: UpdateRevisionCostAccount | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<UpdateRevisionCostAccount>(print(UpdateRevisionCostAccountDocument), variables));
    },
    DeleteRevisionCostAccounts(variables: DeleteRevisionCostAccountsVariables): Promise<{ data?: DeleteRevisionCostAccounts | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<DeleteRevisionCostAccounts>(print(DeleteRevisionCostAccountsDocument), variables));
    },
    CreatePortfolioView(variables: CreatePortfolioViewVariables): Promise<{ data?: CreatePortfolioView | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<CreatePortfolioView>(print(CreatePortfolioViewDocument), variables));
    },
    UpdatePortfolioView(variables: UpdatePortfolioViewVariables): Promise<{ data?: UpdatePortfolioView | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<UpdatePortfolioView>(print(UpdatePortfolioViewDocument), variables));
    },
    DeletePortfolioView(variables: DeletePortfolioViewVariables): Promise<{ data?: DeletePortfolioView | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<DeletePortfolioView>(print(DeletePortfolioViewDocument), variables));
    },
    CreateComment(variables: CreateCommentVariables): Promise<{ data?: CreateComment | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<CreateComment>(print(CreateCommentDocument), variables));
    },
    UpdateComment(variables: UpdateCommentVariables): Promise<{ data?: UpdateComment | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<UpdateComment>(print(UpdateCommentDocument), variables));
    },
    DeleteComment(variables: DeleteCommentVariables): Promise<{ data?: DeleteComment | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<DeleteComment>(print(DeleteCommentDocument), variables));
    },
    GenerateTransactionDetailPreview(variables: GenerateTransactionDetailPreviewVariables): Promise<{ data?: GenerateTransactionDetailPreview | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<GenerateTransactionDetailPreview>(print(GenerateTransactionDetailPreviewDocument), variables));
    },
    CreateTransaction(variables: CreateTransactionVariables): Promise<{ data?: CreateTransaction | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<CreateTransaction>(print(CreateTransactionDocument), variables));
    },
    UpdateTransaction(variables: UpdateTransactionVariables): Promise<{ data?: UpdateTransaction | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<UpdateTransaction>(print(UpdateTransactionDocument), variables));
    },
    UpdateTransactions(variables: UpdateTransactionsVariables): Promise<{ data?: UpdateTransactions | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<UpdateTransactions>(print(UpdateTransactionsDocument), variables));
    },
    DeleteTransaction(variables: DeleteTransactionVariables): Promise<{ data?: DeleteTransaction | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<DeleteTransaction>(print(DeleteTransactionDocument), variables));
    },
    DeletePortfolio(variables: DeletePortfolioVariables): Promise<{ data?: DeletePortfolio | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<DeletePortfolio>(print(DeletePortfolioDocument), variables));
    },
    DeleteDeal(variables: DeleteDealVariables): Promise<{ data?: DeleteDeal | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<DeleteDeal>(print(DeleteDealDocument), variables));
    },
    ArchiveDeal(variables: ArchiveDealVariables): Promise<{ data?: ArchiveDeal | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<ArchiveDeal>(print(ArchiveDealDocument), variables));
    },
    UnarchiveDeal(variables: UnarchiveDealVariables): Promise<{ data?: UnarchiveDeal | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<UnarchiveDeal>(print(UnarchiveDealDocument), variables));
    },
    CreateGroup(variables: CreateGroupVariables): Promise<{ data?: CreateGroup | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<CreateGroup>(print(CreateGroupDocument), variables));
    },
    UpdateGroup(variables: UpdateGroupVariables): Promise<{ data?: UpdateGroup | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<UpdateGroup>(print(UpdateGroupDocument), variables));
    },
    DeleteGroup(variables: DeleteGroupVariables): Promise<{ data?: DeleteGroup | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<DeleteGroup>(print(DeleteGroupDocument), variables));
    },
    RemoveUsersfromGroup(variables: RemoveUsersfromGroupVariables): Promise<{ data?: RemoveUsersfromGroup | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<RemoveUsersfromGroup>(print(RemoveUsersfromGroupDocument), variables));
    },
    AddUsersToGroup(variables: AddUsersToGroupVariables): Promise<{ data?: AddUsersToGroup | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<AddUsersToGroup>(print(AddUsersToGroupDocument), variables));
    },
    AddEventsToDeal(variables: AddEventsToDealVariables): Promise<{ data?: AddEventsToDeal | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<AddEventsToDeal>(print(AddEventsToDealDocument), variables));
    },
    RemoveEventsFromDeal(variables: RemoveEventsFromDealVariables): Promise<{ data?: RemoveEventsFromDeal | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<RemoveEventsFromDeal>(print(RemoveEventsFromDealDocument), variables));
    },
    CreateMetric(variables: CreateMetricVariables): Promise<{ data?: CreateMetric | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<CreateMetric>(print(CreateMetricDocument), variables));
    },
    UpdateMetric(variables: UpdateMetricVariables): Promise<{ data?: UpdateMetric | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<UpdateMetric>(print(UpdateMetricDocument), variables));
    },
    DeleteMetric(variables: DeleteMetricVariables): Promise<{ data?: DeleteMetric | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<DeleteMetric>(print(DeleteMetricDocument), variables));
    },
    CreateTextMetric(variables: CreateTextMetricVariables): Promise<{ data?: CreateTextMetric | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<CreateTextMetric>(print(CreateTextMetricDocument), variables));
    },
    UpdateTextMetric(variables: UpdateTextMetricVariables): Promise<{ data?: UpdateTextMetric | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<UpdateTextMetric>(print(UpdateTextMetricDocument), variables));
    },
    DeleteTextMetric(variables: DeleteTextMetricVariables): Promise<{ data?: DeleteTextMetric | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<DeleteTextMetric>(print(DeleteTextMetricDocument), variables));
    },
    CreatePipeline(variables: CreatePipelineVariables): Promise<{ data?: CreatePipeline | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<CreatePipeline>(print(CreatePipelineDocument), variables));
    },
    UpdatePipeline(variables: UpdatePipelineVariables): Promise<{ data?: UpdatePipeline | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<UpdatePipeline>(print(UpdatePipelineDocument), variables));
    },
    DeletePipeline(variables: DeletePipelineVariables): Promise<{ data?: DeletePipeline | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<DeletePipeline>(print(DeletePipelineDocument), variables));
    },
    AddChecklistsToPipeline(variables: AddChecklistsToPipelineVariables): Promise<{ data?: AddChecklistsToPipeline | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<AddChecklistsToPipeline>(print(AddChecklistsToPipelineDocument), variables));
    },
    RemoveChecklistsFromPipeline(variables: RemoveChecklistsFromPipelineVariables): Promise<{ data?: RemoveChecklistsFromPipeline | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<RemoveChecklistsFromPipeline>(print(RemoveChecklistsFromPipelineDocument), variables));
    },
    AddPinnedMetricsToPipeline(variables: AddPinnedMetricsToPipelineVariables): Promise<{ data?: AddPinnedMetricsToPipeline | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<AddPinnedMetricsToPipeline>(print(AddPinnedMetricsToPipelineDocument), variables));
    },
    RemovePinnedMetricsFromPipeline(variables: RemovePinnedMetricsFromPipelineVariables): Promise<{ data?: RemovePinnedMetricsFromPipeline | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<RemovePinnedMetricsFromPipeline>(print(RemovePinnedMetricsFromPipelineDocument), variables));
    },
    AddPinnedEventsToPipeline(variables: AddPinnedEventsToPipelineVariables): Promise<{ data?: AddPinnedEventsToPipeline | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<AddPinnedEventsToPipeline>(print(AddPinnedEventsToPipelineDocument), variables));
    },
    RemovePinnedEventsFromPipeline(variables: RemovePinnedEventsFromPipelineVariables): Promise<{ data?: RemovePinnedEventsFromPipeline | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<RemovePinnedEventsFromPipeline>(print(RemovePinnedEventsFromPipelineDocument), variables));
    },
    AddPinnedPartnerRolesToPipeline(variables: AddPinnedPartnerRolesToPipelineVariables): Promise<{ data?: AddPinnedPartnerRolesToPipeline | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<AddPinnedPartnerRolesToPipeline>(print(AddPinnedPartnerRolesToPipelineDocument), variables));
    },
    RemovePinnedPartnerRolesFromPipeline(variables: RemovePinnedPartnerRolesFromPipelineVariables): Promise<{ data?: RemovePinnedPartnerRolesFromPipeline | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<RemovePinnedPartnerRolesFromPipeline>(print(RemovePinnedPartnerRolesFromPipelineDocument), variables));
    },
    AddPinnedTeamMemberRolesToPipeline(variables: AddPinnedTeamMemberRolesToPipelineVariables): Promise<{ data?: AddPinnedTeamMemberRolesToPipeline | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<AddPinnedTeamMemberRolesToPipeline>(print(AddPinnedTeamMemberRolesToPipelineDocument), variables));
    },
    RemovePinnedTeamMemberRolesFromPipeline(variables: RemovePinnedTeamMemberRolesFromPipelineVariables): Promise<{ data?: RemovePinnedTeamMemberRolesFromPipeline | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<RemovePinnedTeamMemberRolesFromPipeline>(print(RemovePinnedTeamMemberRolesFromPipelineDocument), variables));
    },
    AddEventsToPipeline(variables: AddEventsToPipelineVariables): Promise<{ data?: AddEventsToPipeline | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<AddEventsToPipeline>(print(AddEventsToPipelineDocument), variables));
    },
    RemoveEventsFromPipeline(variables: RemoveEventsFromPipelineVariables): Promise<{ data?: RemoveEventsFromPipeline | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<RemoveEventsFromPipeline>(print(RemoveEventsFromPipelineDocument), variables));
    },
    AddModulesToPipeline(variables: AddModulesToPipelineVariables): Promise<{ data?: AddModulesToPipeline | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<AddModulesToPipeline>(print(AddModulesToPipelineDocument), variables));
    },
    RemoveModulesFromPipeline(variables: RemoveModulesFromPipelineVariables): Promise<{ data?: RemoveModulesFromPipeline | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<RemoveModulesFromPipeline>(print(RemoveModulesFromPipelineDocument), variables));
    },
    AddForecastRulesToPipeline(variables: AddForecastRulesToPipelineVariables): Promise<{ data?: AddForecastRulesToPipeline | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<AddForecastRulesToPipeline>(print(AddForecastRulesToPipelineDocument), variables));
    },
    RemoveForecastRulesFromPipeline(variables: RemoveForecastRulesFromPipelineVariables): Promise<{ data?: RemoveForecastRulesFromPipeline | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<RemoveForecastRulesFromPipeline>(print(RemoveForecastRulesFromPipelineDocument), variables));
    },
    EnableForecastRules(variables: EnableForecastRulesVariables): Promise<{ data?: EnableForecastRules | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<EnableForecastRules>(print(EnableForecastRulesDocument), variables));
    },
    DisableForecastRules(variables: DisableForecastRulesVariables): Promise<{ data?: DisableForecastRules | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<DisableForecastRules>(print(DisableForecastRulesDocument), variables));
    },
    PublishScheduleInstance(variables: PublishScheduleInstanceVariables): Promise<{ data?: PublishScheduleInstance | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<PublishScheduleInstance>(print(PublishScheduleInstanceDocument), variables));
    },
    CreateScheduleInstance(variables: CreateScheduleInstanceVariables): Promise<{ data?: CreateScheduleInstance | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<CreateScheduleInstance>(print(CreateScheduleInstanceDocument), variables));
    },
    UpdateScheduleInstance(variables: UpdateScheduleInstanceVariables): Promise<{ data?: UpdateScheduleInstance | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<UpdateScheduleInstance>(print(UpdateScheduleInstanceDocument), variables));
    },
    DeleteScheduleInstance(variables: DeleteScheduleInstanceVariables): Promise<{ data?: DeleteScheduleInstance | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<DeleteScheduleInstance>(print(DeleteScheduleInstanceDocument), variables));
    },
    CreatePublishedDealEvent(variables: CreatePublishedDealEventVariables): Promise<{ data?: CreatePublishedDealEvent | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<CreatePublishedDealEvent>(print(CreatePublishedDealEventDocument), variables));
    },
    UpdatePublishedDealEvent(variables: UpdatePublishedDealEventVariables): Promise<{ data?: UpdatePublishedDealEvent | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<UpdatePublishedDealEvent>(print(UpdatePublishedDealEventDocument), variables));
    },
    CreateBuilding(variables: CreateBuildingVariables): Promise<{ data?: CreateBuilding | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<CreateBuilding>(print(CreateBuildingDocument), variables));
    },
    UpdateBuilding(variables: UpdateBuildingVariables): Promise<{ data?: UpdateBuilding | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<UpdateBuilding>(print(UpdateBuildingDocument), variables));
    },
    DeleteBuilding(variables: DeleteBuildingVariables): Promise<{ data?: DeleteBuilding | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<DeleteBuilding>(print(DeleteBuildingDocument), variables));
    },
    CreateBond(variables: CreateBondVariables): Promise<{ data?: CreateBond | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<CreateBond>(print(CreateBondDocument), variables));
    },
    UpdateBond(variables: UpdateBondVariables): Promise<{ data?: UpdateBond | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<UpdateBond>(print(UpdateBondDocument), variables));
    },
    DeleteBond(variables: DeleteBondVariables): Promise<{ data?: DeleteBond | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<DeleteBond>(print(DeleteBondDocument), variables));
    },
    CreateGcEstimate(variables: CreateGcEstimateVariables): Promise<{ data?: CreateGcEstimate | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<CreateGcEstimate>(print(CreateGcEstimateDocument), variables));
    },
    UpdateGcEstimate(variables: UpdateGcEstimateVariables): Promise<{ data?: UpdateGcEstimate | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<UpdateGcEstimate>(print(UpdateGcEstimateDocument), variables));
    },
    DeleteGcEstimate(variables: DeleteGcEstimateVariables): Promise<{ data?: DeleteGcEstimate | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<DeleteGcEstimate>(print(DeleteGcEstimateDocument), variables));
    },
    CreateGlAccount(variables: CreateGlAccountVariables): Promise<{ data?: CreateGlAccount | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<CreateGlAccount>(print(CreateGlAccountDocument), variables));
    },
    UpdateGlAccount(variables: UpdateGlAccountVariables): Promise<{ data?: UpdateGlAccount | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<UpdateGlAccount>(print(UpdateGlAccountDocument), variables));
    },
    DeleteGlAccount(variables: DeleteGlAccountVariables): Promise<{ data?: DeleteGlAccount | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<DeleteGlAccount>(print(DeleteGlAccountDocument), variables));
    },
    CreateGlAccountTree(variables: CreateGlAccountTreeVariables): Promise<{ data?: CreateGlAccountTree | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<CreateGlAccountTree>(print(CreateGlAccountTreeDocument), variables));
    },
    UpdateGlAccountTree(variables: UpdateGlAccountTreeVariables): Promise<{ data?: UpdateGlAccountTree | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<UpdateGlAccountTree>(print(UpdateGlAccountTreeDocument), variables));
    },
    DeleteGlAccountTree(variables: DeleteGlAccountTreeVariables): Promise<{ data?: DeleteGlAccountTree | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<DeleteGlAccountTree>(print(DeleteGlAccountTreeDocument), variables));
    },
    CreatePipelineContingencyCategory(variables: CreatePipelineContingencyCategoryVariables): Promise<{ data?: CreatePipelineContingencyCategory | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<CreatePipelineContingencyCategory>(print(CreatePipelineContingencyCategoryDocument), variables));
    },
    UpdatePipelineContingencyCategory(variables: UpdatePipelineContingencyCategoryVariables): Promise<{ data?: UpdatePipelineContingencyCategory | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<UpdatePipelineContingencyCategory>(print(UpdatePipelineContingencyCategoryDocument), variables));
    },
    DeletePipelineContingencyCategory(variables: DeletePipelineContingencyCategoryVariables): Promise<{ data?: DeletePipelineContingencyCategory | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<DeletePipelineContingencyCategory>(print(DeletePipelineContingencyCategoryDocument), variables));
    },
    CreatePipelineGeneralOffset(variables: CreatePipelineGeneralOffsetVariables): Promise<{ data?: CreatePipelineGeneralOffset | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<CreatePipelineGeneralOffset>(print(CreatePipelineGeneralOffsetDocument), variables));
    },
    UpdatePipelineGeneralOffset(variables: UpdatePipelineGeneralOffsetVariables): Promise<{ data?: UpdatePipelineGeneralOffset | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<UpdatePipelineGeneralOffset>(print(UpdatePipelineGeneralOffsetDocument), variables));
    },
    DeletePipelineGeneralOffset(variables: DeletePipelineGeneralOffsetVariables): Promise<{ data?: DeletePipelineGeneralOffset | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<DeletePipelineGeneralOffset>(print(DeletePipelineGeneralOffsetDocument), variables));
    },
    CreatePipelineAccountOffset(variables: CreatePipelineAccountOffsetVariables): Promise<{ data?: CreatePipelineAccountOffset | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<CreatePipelineAccountOffset>(print(CreatePipelineAccountOffsetDocument), variables));
    },
    UpdatePipelineAccountOffset(variables: UpdatePipelineAccountOffsetVariables): Promise<{ data?: UpdatePipelineAccountOffset | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<UpdatePipelineAccountOffset>(print(UpdatePipelineAccountOffsetDocument), variables));
    },
    DeletePipelineAccountOffset(variables: DeletePipelineAccountOffsetVariables): Promise<{ data?: DeletePipelineAccountOffset | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<DeletePipelineAccountOffset>(print(DeletePipelineAccountOffsetDocument), variables));
    },
    CreateForecastRule(variables: CreateForecastRuleVariables): Promise<{ data?: CreateForecastRule | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<CreateForecastRule>(print(CreateForecastRuleDocument), variables));
    },
    UpdateForecastRule(variables: UpdateForecastRuleVariables): Promise<{ data?: UpdateForecastRule | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<UpdateForecastRule>(print(UpdateForecastRuleDocument), variables));
    },
    DeleteForecastRule(variables: DeleteForecastRuleVariables): Promise<{ data?: DeleteForecastRule | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<DeleteForecastRule>(print(DeleteForecastRuleDocument), variables));
    },
    AddAccountsToAccountTree(variables: AddAccountsToAccountTreeVariables): Promise<{ data?: AddAccountsToAccountTree | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<AddAccountsToAccountTree>(print(AddAccountsToAccountTreeDocument), variables));
    },
    RemoveAccountsFromAccountTree(variables: RemoveAccountsFromAccountTreeVariables): Promise<{ data?: RemoveAccountsFromAccountTree | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<RemoveAccountsFromAccountTree>(print(RemoveAccountsFromAccountTreeDocument), variables));
    },
    ChangeAccountTreeParent(variables: ChangeAccountTreeParentVariables): Promise<{ data?: ChangeAccountTreeParent | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<ChangeAccountTreeParent>(print(ChangeAccountTreeParentDocument), variables));
    },
    CreateChangeEvent(variables: CreateChangeEventVariables): Promise<{ data?: CreateChangeEvent | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<CreateChangeEvent>(print(CreateChangeEventDocument), variables));
    },
    UpdateChangeEvent(variables: UpdateChangeEventVariables): Promise<{ data?: UpdateChangeEvent | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<UpdateChangeEvent>(print(UpdateChangeEventDocument), variables));
    },
    DeleteChangeEvent(variables: DeleteChangeEventVariables): Promise<{ data?: DeleteChangeEvent | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<DeleteChangeEvent>(print(DeleteChangeEventDocument), variables));
    },
    CreatePotentialChangeOrder(variables: CreatePotentialChangeOrderVariables): Promise<{ data?: CreatePotentialChangeOrder | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<CreatePotentialChangeOrder>(print(CreatePotentialChangeOrderDocument), variables));
    },
    UpdatePotentialChangeOrder(variables: UpdatePotentialChangeOrderVariables): Promise<{ data?: UpdatePotentialChangeOrder | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<UpdatePotentialChangeOrder>(print(UpdatePotentialChangeOrderDocument), variables));
    },
    DeletePotentialChangeOrder(variables: DeletePotentialChangeOrderVariables): Promise<{ data?: DeletePotentialChangeOrder | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<DeletePotentialChangeOrder>(print(DeletePotentialChangeOrderDocument), variables));
    },
    CreateChangeOrderPackage(variables: CreateChangeOrderPackageVariables): Promise<{ data?: CreateChangeOrderPackage | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<CreateChangeOrderPackage>(print(CreateChangeOrderPackageDocument), variables));
    },
    UpdateChangeOrderPackage(variables: UpdateChangeOrderPackageVariables): Promise<{ data?: UpdateChangeOrderPackage | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<UpdateChangeOrderPackage>(print(UpdateChangeOrderPackageDocument), variables));
    },
    DeleteChangeOrderPackage(variables: DeleteChangeOrderPackageVariables): Promise<{ data?: DeleteChangeOrderPackage | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<DeleteChangeOrderPackage>(print(DeleteChangeOrderPackageDocument), variables));
    },
    CreateLabel(variables: CreateLabelVariables): Promise<{ data?: CreateLabel | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<CreateLabel>(print(CreateLabelDocument), variables));
    },
    UpdateLabel(variables: UpdateLabelVariables): Promise<{ data?: UpdateLabel | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<UpdateLabel>(print(UpdateLabelDocument), variables));
    },
    DeleteLabel(variables: DeleteLabelVariables): Promise<{ data?: DeleteLabel | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<DeleteLabel>(print(DeleteLabelDocument), variables));
    },
    CreateApprovalPackage(variables: CreateApprovalPackageVariables): Promise<{ data?: CreateApprovalPackage | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<CreateApprovalPackage>(print(CreateApprovalPackageDocument), variables));
    },
    UpdateApprovalPackage(variables: UpdateApprovalPackageVariables): Promise<{ data?: UpdateApprovalPackage | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<UpdateApprovalPackage>(print(UpdateApprovalPackageDocument), variables));
    },
    ArchiveApprovalPackage(variables: ArchiveApprovalPackageVariables): Promise<{ data?: ArchiveApprovalPackage | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<ArchiveApprovalPackage>(print(ArchiveApprovalPackageDocument), variables));
    },
    DeleteApprovalPackage(variables: DeleteApprovalPackageVariables): Promise<{ data?: DeleteApprovalPackage | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<DeleteApprovalPackage>(print(DeleteApprovalPackageDocument), variables));
    },
    UpdateApprovalPackageSubSectionText(variables: UpdateApprovalPackageSubSectionTextVariables): Promise<{ data?: UpdateApprovalPackageSubSectionText | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<UpdateApprovalPackageSubSectionText>(print(UpdateApprovalPackageSubSectionTextDocument), variables));
    },
    UpdateApprovalPackageSubSectionChecklist(variables: UpdateApprovalPackageSubSectionChecklistVariables): Promise<{ data?: UpdateApprovalPackageSubSectionChecklist | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<UpdateApprovalPackageSubSectionChecklist>(print(UpdateApprovalPackageSubSectionChecklistDocument), variables));
    },
    CreateApprovalPackageSubSection(variables: CreateApprovalPackageSubSectionVariables): Promise<{ data?: CreateApprovalPackageSubSection | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<CreateApprovalPackageSubSection>(print(CreateApprovalPackageSubSectionDocument), variables));
    },
    UpdateApprovalPackageSubSection(variables: UpdateApprovalPackageSubSectionVariables): Promise<{ data?: UpdateApprovalPackageSubSection | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<UpdateApprovalPackageSubSection>(print(UpdateApprovalPackageSubSectionDocument), variables));
    },
    DeleteApprovalPackageSubSection(variables: DeleteApprovalPackageSubSectionVariables): Promise<{ data?: DeleteApprovalPackageSubSection | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<DeleteApprovalPackageSubSection>(print(DeleteApprovalPackageSubSectionDocument), variables));
    },
    CreateApprovalPackageSection(variables: CreateApprovalPackageSectionVariables): Promise<{ data?: CreateApprovalPackageSection | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<CreateApprovalPackageSection>(print(CreateApprovalPackageSectionDocument), variables));
    },
    UpdateApprovalPackageSection(variables: UpdateApprovalPackageSectionVariables): Promise<{ data?: UpdateApprovalPackageSection | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<UpdateApprovalPackageSection>(print(UpdateApprovalPackageSectionDocument), variables));
    },
    DeleteApprovalPackageSection(variables: DeleteApprovalPackageSectionVariables): Promise<{ data?: DeleteApprovalPackageSection | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<DeleteApprovalPackageSection>(print(DeleteApprovalPackageSectionDocument), variables));
    },
    SetApprovalPackageSubSectionTableColumns(variables: SetApprovalPackageSubSectionTableColumnsVariables): Promise<{ data?: SetApprovalPackageSubSectionTableColumns | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<SetApprovalPackageSubSectionTableColumns>(print(SetApprovalPackageSubSectionTableColumnsDocument), variables));
    },
    CreateApprovalPackageSubSectionTableCell(variables: CreateApprovalPackageSubSectionTableCellVariables): Promise<{ data?: CreateApprovalPackageSubSectionTableCell | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<CreateApprovalPackageSubSectionTableCell>(print(CreateApprovalPackageSubSectionTableCellDocument), variables));
    },
    SetEntriesForApprovalPackageSubSectionTableCell(variables: SetEntriesForApprovalPackageSubSectionTableCellVariables): Promise<{ data?: SetEntriesForApprovalPackageSubSectionTableCell | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<SetEntriesForApprovalPackageSubSectionTableCell>(print(SetEntriesForApprovalPackageSubSectionTableCellDocument), variables));
    },
    DeleteApprovalPackageSubSectionTableCell(variables: DeleteApprovalPackageSubSectionTableCellVariables): Promise<{ data?: DeleteApprovalPackageSubSectionTableCell | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<DeleteApprovalPackageSubSectionTableCell>(print(DeleteApprovalPackageSubSectionTableCellDocument), variables));
    },
    UpdateApprovalPackageExhibit(variables: UpdateApprovalPackageExhibitVariables): Promise<{ data?: UpdateApprovalPackageExhibit | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<UpdateApprovalPackageExhibit>(print(UpdateApprovalPackageExhibitDocument), variables));
    },
    DeleteApprovalPackageExhibit(variables: DeleteApprovalPackageExhibitVariables): Promise<{ data?: DeleteApprovalPackageExhibit | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<DeleteApprovalPackageExhibit>(print(DeleteApprovalPackageExhibitDocument), variables));
    },
    DeleteDealArtifact(variables: DeleteDealArtifactVariables): Promise<{ data?: DeleteDealArtifact | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<DeleteDealArtifact>(print(DeleteDealArtifactDocument), variables));
    },
    DeleteContactAttachment(variables: DeleteContactAttachmentVariables): Promise<{ data?: DeleteContactAttachment | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<DeleteContactAttachment>(print(DeleteContactAttachmentDocument), variables));
    },
    DeleteFirmAttachment(variables: DeleteFirmAttachmentVariables): Promise<{ data?: DeleteFirmAttachment | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<DeleteFirmAttachment>(print(DeleteFirmAttachmentDocument), variables));
    },
    CreateApprovalPackageReviewer(variables: CreateApprovalPackageReviewerVariables): Promise<{ data?: CreateApprovalPackageReviewer | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<CreateApprovalPackageReviewer>(print(CreateApprovalPackageReviewerDocument), variables));
    },
    UpdateApprovalPackageReviewer(variables: UpdateApprovalPackageReviewerVariables): Promise<{ data?: UpdateApprovalPackageReviewer | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<UpdateApprovalPackageReviewer>(print(UpdateApprovalPackageReviewerDocument), variables));
    },
    DeleteApprovalPackageReviewer(variables: DeleteApprovalPackageReviewerVariables): Promise<{ data?: DeleteApprovalPackageReviewer | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<DeleteApprovalPackageReviewer>(print(DeleteApprovalPackageReviewerDocument), variables));
    },
    CreateApprovalPackageContributor(variables: CreateApprovalPackageContributorVariables): Promise<{ data?: CreateApprovalPackageContributor | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<CreateApprovalPackageContributor>(print(CreateApprovalPackageContributorDocument), variables));
    },
    DeleteApprovalPackageContributor(variables: DeleteApprovalPackageContributorVariables): Promise<{ data?: DeleteApprovalPackageContributor | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<DeleteApprovalPackageContributor>(print(DeleteApprovalPackageContributorDocument), variables));
    },
    PublishApprovalPackage(variables: PublishApprovalPackageVariables): Promise<{ data?: PublishApprovalPackage | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<PublishApprovalPackage>(print(PublishApprovalPackageDocument), variables));
    },
    CreateApprovalPackageTemplate(variables: CreateApprovalPackageTemplateVariables): Promise<{ data?: CreateApprovalPackageTemplate | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<CreateApprovalPackageTemplate>(print(CreateApprovalPackageTemplateDocument), variables));
    },
    UpdateApprovalPackageTemplate(variables: UpdateApprovalPackageTemplateVariables): Promise<{ data?: UpdateApprovalPackageTemplate | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<UpdateApprovalPackageTemplate>(print(UpdateApprovalPackageTemplateDocument), variables));
    },
    DeleteApprovalPackageTemplate(variables: DeleteApprovalPackageTemplateVariables): Promise<{ data?: DeleteApprovalPackageTemplate | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<DeleteApprovalPackageTemplate>(print(DeleteApprovalPackageTemplateDocument), variables));
    },
    UpdateApprovalPackageTemplateSubSectionText(variables: UpdateApprovalPackageTemplateSubSectionTextVariables): Promise<{ data?: UpdateApprovalPackageTemplateSubSectionText | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<UpdateApprovalPackageTemplateSubSectionText>(print(UpdateApprovalPackageTemplateSubSectionTextDocument), variables));
    },
    UpdateApprovalPackageTemplateSubSectionChecklist(variables: UpdateApprovalPackageTemplateSubSectionChecklistVariables): Promise<{ data?: UpdateApprovalPackageTemplateSubSectionChecklist | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<UpdateApprovalPackageTemplateSubSectionChecklist>(print(UpdateApprovalPackageTemplateSubSectionChecklistDocument), variables));
    },
    CreateApprovalPackageTemplateSubSection(variables: CreateApprovalPackageTemplateSubSectionVariables): Promise<{ data?: CreateApprovalPackageTemplateSubSection | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<CreateApprovalPackageTemplateSubSection>(print(CreateApprovalPackageTemplateSubSectionDocument), variables));
    },
    UpdateApprovalPackageTemplateSubSection(variables: UpdateApprovalPackageTemplateSubSectionVariables): Promise<{ data?: UpdateApprovalPackageTemplateSubSection | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<UpdateApprovalPackageTemplateSubSection>(print(UpdateApprovalPackageTemplateSubSectionDocument), variables));
    },
    DeleteApprovalPackageTemplateSubSection(variables: DeleteApprovalPackageTemplateSubSectionVariables): Promise<{ data?: DeleteApprovalPackageTemplateSubSection | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<DeleteApprovalPackageTemplateSubSection>(print(DeleteApprovalPackageTemplateSubSectionDocument), variables));
    },
    CreateApprovalPackageTemplateSection(variables: CreateApprovalPackageTemplateSectionVariables): Promise<{ data?: CreateApprovalPackageTemplateSection | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<CreateApprovalPackageTemplateSection>(print(CreateApprovalPackageTemplateSectionDocument), variables));
    },
    UpdateApprovalPackageTemplateSection(variables: UpdateApprovalPackageTemplateSectionVariables): Promise<{ data?: UpdateApprovalPackageTemplateSection | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<UpdateApprovalPackageTemplateSection>(print(UpdateApprovalPackageTemplateSectionDocument), variables));
    },
    DeleteApprovalPackageTemplateSection(variables: DeleteApprovalPackageTemplateSectionVariables): Promise<{ data?: DeleteApprovalPackageTemplateSection | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<DeleteApprovalPackageTemplateSection>(print(DeleteApprovalPackageTemplateSectionDocument), variables));
    },
    SetApprovalPackageTemplateSubSectionTableColumns(variables: SetApprovalPackageTemplateSubSectionTableColumnsVariables): Promise<{ data?: SetApprovalPackageTemplateSubSectionTableColumns | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<SetApprovalPackageTemplateSubSectionTableColumns>(print(SetApprovalPackageTemplateSubSectionTableColumnsDocument), variables));
    },
    CreateApprovalPackageTemplateSubSectionTableCell(variables: CreateApprovalPackageTemplateSubSectionTableCellVariables): Promise<{ data?: CreateApprovalPackageTemplateSubSectionTableCell | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<CreateApprovalPackageTemplateSubSectionTableCell>(print(CreateApprovalPackageTemplateSubSectionTableCellDocument), variables));
    },
    SetEntriesForApprovalPackageTemplateSubSectionTableCell(variables: SetEntriesForApprovalPackageTemplateSubSectionTableCellVariables): Promise<{ data?: SetEntriesForApprovalPackageTemplateSubSectionTableCell | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<SetEntriesForApprovalPackageTemplateSubSectionTableCell>(print(SetEntriesForApprovalPackageTemplateSubSectionTableCellDocument), variables));
    },
    DeleteApprovalPackageTemplateSubSectionTableCell(variables: DeleteApprovalPackageTemplateSubSectionTableCellVariables): Promise<{ data?: DeleteApprovalPackageTemplateSubSectionTableCell | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<DeleteApprovalPackageTemplateSubSectionTableCell>(print(DeleteApprovalPackageTemplateSubSectionTableCellDocument), variables));
    },
    CreateApprovalPackageTemplateFromPackage(variables: CreateApprovalPackageTemplateFromPackageVariables): Promise<{ data?: CreateApprovalPackageTemplateFromPackage | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<CreateApprovalPackageTemplateFromPackage>(print(CreateApprovalPackageTemplateFromPackageDocument), variables));
    },
    AssignChangeEventsToParent(variables: AssignChangeEventsToParentVariables): Promise<{ data?: AssignChangeEventsToParent | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<AssignChangeEventsToParent>(print(AssignChangeEventsToParentDocument), variables));
    },
    AssignPotentialChangeOrdersToParent(variables: AssignPotentialChangeOrdersToParentVariables): Promise<{ data?: AssignPotentialChangeOrdersToParent | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<AssignPotentialChangeOrdersToParent>(print(AssignPotentialChangeOrdersToParentDocument), variables));
    },
    AssignChangeOrdersToParent(variables: AssignChangeOrdersToParentVariables): Promise<{ data?: AssignChangeOrdersToParent | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<AssignChangeOrdersToParent>(print(AssignChangeOrdersToParentDocument), variables));
    },
    RunDefaultForecastRules(variables: RunDefaultForecastRulesVariables): Promise<{ data?: RunDefaultForecastRules | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<RunDefaultForecastRules>(print(RunDefaultForecastRulesDocument), variables));
    },
    ForecastCapitalizationAccounts(variables: ForecastCapitalizationAccountsVariables): Promise<{ data?: ForecastCapitalizationAccounts | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<ForecastCapitalizationAccounts>(print(ForecastCapitalizationAccountsDocument), variables));
    },
    ForecastInterestAccount(variables: ForecastInterestAccountVariables): Promise<{ data?: ForecastInterestAccount | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<ForecastInterestAccount>(print(ForecastInterestAccountDocument), variables));
    },
    CreateApprovalPackageType(variables: CreateApprovalPackageTypeVariables): Promise<{ data?: CreateApprovalPackageType | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<CreateApprovalPackageType>(print(CreateApprovalPackageTypeDocument), variables));
    },
    UpdateApprovalPackageType(variables: UpdateApprovalPackageTypeVariables): Promise<{ data?: UpdateApprovalPackageType | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<UpdateApprovalPackageType>(print(UpdateApprovalPackageTypeDocument), variables));
    },
    DeleteApprovalPackageType(variables: DeleteApprovalPackageTypeVariables): Promise<{ data?: DeleteApprovalPackageType | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<DeleteApprovalPackageType>(print(DeleteApprovalPackageTypeDocument), variables));
    },
    BulkEditVisibleDealEvents(variables: BulkEditVisibleDealEventsVariables): Promise<{ data?: BulkEditVisibleDealEvents | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<BulkEditVisibleDealEvents>(print(BulkEditVisibleDealEventsDocument), variables));
    },
    BulkUnhideDealEvents(variables: BulkUnhideDealEventsVariables): Promise<{ data?: BulkUnhideDealEvents | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<BulkUnhideDealEvents>(print(BulkUnhideDealEventsDocument), variables));
    },
    RefreshApprovalPackageKeyRisks(variables: RefreshApprovalPackageKeyRisksVariables): Promise<{ data?: RefreshApprovalPackageKeyRisks | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<RefreshApprovalPackageKeyRisks>(print(RefreshApprovalPackageKeyRisksDocument), variables));
    },
    RefreshApprovalPackageDealMerits(variables: RefreshApprovalPackageDealMeritsVariables): Promise<{ data?: RefreshApprovalPackageDealMerits | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<RefreshApprovalPackageDealMerits>(print(RefreshApprovalPackageDealMeritsDocument), variables));
    },
    CreateInterestRate(variables: CreateInterestRateVariables): Promise<{ data?: CreateInterestRate | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<CreateInterestRate>(print(CreateInterestRateDocument), variables));
    },
    UpdateInterestRate(variables: UpdateInterestRateVariables): Promise<{ data?: UpdateInterestRate | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<UpdateInterestRate>(print(UpdateInterestRateDocument), variables));
    },
    DeleteInterestRate(variables: DeleteInterestRateVariables): Promise<{ data?: DeleteInterestRate | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<DeleteInterestRate>(print(DeleteInterestRateDocument), variables));
    },
    GetPortfolios(variables?: GetPortfoliosVariables): Promise<{ data?: GetPortfolios | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<GetPortfolios>(print(GetPortfoliosDocument), variables));
    },
    GetPortfolio(variables: GetPortfolioVariables): Promise<{ data?: GetPortfolio | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<GetPortfolio>(print(GetPortfolioDocument), variables));
    },
    GetDeals(variables: GetDealsVariables): Promise<{ data?: GetDeals | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<GetDeals>(print(GetDealsDocument), variables));
    },
    GetDealsPaginated(variables: GetDealsPaginatedVariables): Promise<{ data?: GetDealsPaginated | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<GetDealsPaginated>(print(GetDealsPaginatedDocument), variables));
    },
    GetPortfolioDeals(variables: GetPortfolioDealsVariables): Promise<{ data?: GetPortfolioDeals | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<GetPortfolioDeals>(print(GetPortfolioDealsDocument), variables));
    },
    GetPortfolioUserAccess(variables: GetPortfolioUserAccessVariables): Promise<{ data?: GetPortfolioUserAccess | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<GetPortfolioUserAccess>(print(GetPortfolioUserAccessDocument), variables));
    },
    GetDeal(variables: GetDealVariables): Promise<{ data?: GetDeal | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<GetDeal>(print(GetDealDocument), variables));
    },
    GetDealInteractions(variables: GetDealInteractionsVariables): Promise<{ data?: GetDealInteractions | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<GetDealInteractions>(print(GetDealInteractionsDocument), variables));
    },
    GetDealUserAccess(variables: GetDealUserAccessVariables): Promise<{ data?: GetDealUserAccess | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<GetDealUserAccess>(print(GetDealUserAccessDocument), variables));
    },
    GetPipelineUserAccess(variables: GetPipelineUserAccessVariables): Promise<{ data?: GetPipelineUserAccess | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<GetPipelineUserAccess>(print(GetPipelineUserAccessDocument), variables));
    },
    GetDealTeam(variables: GetDealTeamVariables): Promise<{ data?: GetDealTeam | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<GetDealTeam>(print(GetDealTeamDocument), variables));
    },
    GetDealPartners(variables: GetDealPartnersVariables): Promise<{ data?: GetDealPartners | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<GetDealPartners>(print(GetDealPartnersDocument), variables));
    },
    GetTeamMemberRoles(variables?: GetTeamMemberRolesVariables): Promise<{ data?: GetTeamMemberRoles | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<GetTeamMemberRoles>(print(GetTeamMemberRolesDocument), variables));
    },
    GetPartnerRoles(variables?: GetPartnerRolesVariables): Promise<{ data?: GetPartnerRoles | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<GetPartnerRoles>(print(GetPartnerRolesDocument), variables));
    },
    GetPortfolioUsers(variables: GetPortfolioUsersVariables): Promise<{ data?: GetPortfolioUsers | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<GetPortfolioUsers>(print(GetPortfolioUsersDocument), variables));
    },
    GetCurrentOrganizationInfo(variables?: GetCurrentOrganizationInfoVariables): Promise<{ data?: GetCurrentOrganizationInfo | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<GetCurrentOrganizationInfo>(print(GetCurrentOrganizationInfoDocument), variables));
    },
    GetOrganizationUserAccess(variables?: GetOrganizationUserAccessVariables): Promise<{ data?: GetOrganizationUserAccess | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<GetOrganizationUserAccess>(print(GetOrganizationUserAccessDocument), variables));
    },
    GetFirm(variables: GetFirmVariables): Promise<{ data?: GetFirm | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<GetFirm>(print(GetFirmDocument), variables));
    },
    GetDealPipelineFirms(variables: GetDealPipelineFirmsVariables): Promise<{ data?: GetDealPipelineFirms | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<GetDealPipelineFirms>(print(GetDealPipelineFirmsDocument), variables));
    },
    GetDealPipelineContacts(variables: GetDealPipelineContactsVariables): Promise<{ data?: GetDealPipelineContacts | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<GetDealPipelineContacts>(print(GetDealPipelineContactsDocument), variables));
    },
    GetInteraction(variables: GetInteractionVariables): Promise<{ data?: GetInteraction | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<GetInteraction>(print(GetInteractionDocument), variables));
    },
    GetContact(variables: GetContactVariables): Promise<{ data?: GetContact | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<GetContact>(print(GetContactDocument), variables));
    },
    GetDealChecklists(variables: GetDealChecklistsVariables): Promise<{ data?: GetDealChecklists | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<GetDealChecklists>(print(GetDealChecklistsDocument), variables));
    },
    GetChecklistItemStatuses(variables?: GetChecklistItemStatusesVariables): Promise<{ data?: GetChecklistItemStatuses | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<GetChecklistItemStatuses>(print(GetChecklistItemStatusesDocument), variables));
    },
    GetChecklists(variables?: GetChecklistsVariables): Promise<{ data?: GetChecklists | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<GetChecklists>(print(GetChecklistsDocument), variables));
    },
    GetChecklist(variables: GetChecklistVariables): Promise<{ data?: GetChecklist | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<GetChecklist>(print(GetChecklistDocument), variables));
    },
    GetChecklistCategories(variables: GetChecklistCategoriesVariables): Promise<{ data?: GetChecklistCategories | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<GetChecklistCategories>(print(GetChecklistCategoriesDocument), variables));
    },
    GetDealStatusUpdates(variables: GetDealStatusUpdatesVariables): Promise<{ data?: GetDealStatusUpdates | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<GetDealStatusUpdates>(print(GetDealStatusUpdatesDocument), variables));
    },
    GetDealStatusUpdate(variables: GetDealStatusUpdateVariables): Promise<{ data?: GetDealStatusUpdate | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<GetDealStatusUpdate>(print(GetDealStatusUpdateDocument), variables));
    },
    GetDealStatusUpdateTemplates(variables?: GetDealStatusUpdateTemplatesVariables): Promise<{ data?: GetDealStatusUpdateTemplates | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<GetDealStatusUpdateTemplates>(print(GetDealStatusUpdateTemplatesDocument), variables));
    },
    GetDealStatusUpdateTemplate(variables: GetDealStatusUpdateTemplateVariables): Promise<{ data?: GetDealStatusUpdateTemplate | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<GetDealStatusUpdateTemplate>(print(GetDealStatusUpdateTemplateDocument), variables));
    },
    GetDealStatuses(variables?: GetDealStatusesVariables): Promise<{ data?: GetDealStatuses | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<GetDealStatuses>(print(GetDealStatusesDocument), variables));
    },
    GetDealSchedule(variables: GetDealScheduleVariables): Promise<{ data?: GetDealSchedule | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<GetDealSchedule>(print(GetDealScheduleDocument), variables));
    },
    GetDealKeyMilestones(variables: GetDealKeyMilestonesVariables): Promise<{ data?: GetDealKeyMilestones | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<GetDealKeyMilestones>(print(GetDealKeyMilestonesDocument), variables));
    },
    GetDealEvent(variables: GetDealEventVariables): Promise<{ data?: GetDealEvent | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<GetDealEvent>(print(GetDealEventDocument), variables));
    },
    GetCurrentUser(variables?: GetCurrentUserVariables): Promise<{ data?: GetCurrentUser | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<GetCurrentUser>(print(GetCurrentUserDocument), variables));
    },
    GetDealDocuments(variables: GetDealDocumentsVariables): Promise<{ data?: GetDealDocuments | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<GetDealDocuments>(print(GetDealDocumentsDocument), variables));
    },
    GetFolders(variables?: GetFoldersVariables): Promise<{ data?: GetFolders | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<GetFolders>(print(GetFoldersDocument), variables));
    },
    GetStates(variables?: GetStatesVariables): Promise<{ data?: GetStates | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<GetStates>(print(GetStatesDocument), variables));
    },
    GetRegions(variables?: GetRegionsVariables): Promise<{ data?: GetRegions | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<GetRegions>(print(GetRegionsDocument), variables));
    },
    GetMarkets(variables?: GetMarketsVariables): Promise<{ data?: GetMarkets | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<GetMarkets>(print(GetMarketsDocument), variables));
    },
    GetMarketTypes(variables?: GetMarketTypesVariables): Promise<{ data?: GetMarketTypes | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<GetMarketTypes>(print(GetMarketTypesDocument), variables));
    },
    GetMarketSegments(variables?: GetMarketSegmentsVariables): Promise<{ data?: GetMarketSegments | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<GetMarketSegments>(print(GetMarketSegmentsDocument), variables));
    },
    GetSubmarkets(variables?: GetSubmarketsVariables): Promise<{ data?: GetSubmarkets | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<GetSubmarkets>(print(GetSubmarketsDocument), variables));
    },
    GetMsas(variables?: GetMsasVariables): Promise<{ data?: GetMsas | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<GetMsas>(print(GetMsasDocument), variables));
    },
    GetProductTypes(variables?: GetProductTypesVariables): Promise<{ data?: GetProductTypes | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<GetProductTypes>(print(GetProductTypesDocument), variables));
    },
    GetBuildingTypes(variables?: GetBuildingTypesVariables): Promise<{ data?: GetBuildingTypes | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<GetBuildingTypes>(print(GetBuildingTypesDocument), variables));
    },
    GetEstimateTypes(variables?: GetEstimateTypesVariables): Promise<{ data?: GetEstimateTypes | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<GetEstimateTypes>(print(GetEstimateTypesDocument), variables));
    },
    GetConstructionTypes(variables?: GetConstructionTypesVariables): Promise<{ data?: GetConstructionTypes | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<GetConstructionTypes>(print(GetConstructionTypesDocument), variables));
    },
    GetStructures(variables?: GetStructuresVariables): Promise<{ data?: GetStructures | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<GetStructures>(print(GetStructuresDocument), variables));
    },
    GetDealTypes(variables?: GetDealTypesVariables): Promise<{ data?: GetDealTypes | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<GetDealTypes>(print(GetDealTypesDocument), variables));
    },
    GetAssetClasses(variables?: GetAssetClassesVariables): Promise<{ data?: GetAssetClasses | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<GetAssetClasses>(print(GetAssetClassesDocument), variables));
    },
    GetHoaTypes(variables?: GetHoaTypesVariables): Promise<{ data?: GetHoaTypes | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<GetHoaTypes>(print(GetHoaTypesDocument), variables));
    },
    GetFunds(variables?: GetFundsVariables): Promise<{ data?: GetFunds | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<GetFunds>(print(GetFundsDocument), variables));
    },
    GetFundEntities(variables?: GetFundEntitiesVariables): Promise<{ data?: GetFundEntities | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<GetFundEntities>(print(GetFundEntitiesDocument), variables));
    },
    GetPriorityLevels(variables?: GetPriorityLevelsVariables): Promise<{ data?: GetPriorityLevels | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<GetPriorityLevels>(print(GetPriorityLevelsDocument), variables));
    },
    GetPipelines(variables?: GetPipelinesVariables): Promise<{ data?: GetPipelines | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<GetPipelines>(print(GetPipelinesDocument), variables));
    },
    GetPipeline(variables: GetPipelineVariables): Promise<{ data?: GetPipeline | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<GetPipeline>(print(GetPipelineDocument), variables));
    },
    GetPipelineFirms(variables: GetPipelineFirmsVariables): Promise<{ data?: GetPipelineFirms | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<GetPipelineFirms>(print(GetPipelineFirmsDocument), variables));
    },
    GetPipelineContacts(variables: GetPipelineContactsVariables): Promise<{ data?: GetPipelineContacts | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<GetPipelineContacts>(print(GetPipelineContactsDocument), variables));
    },
    GetPipelineModules(variables: GetPipelineModulesVariables): Promise<{ data?: GetPipelineModules | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<GetPipelineModules>(print(GetPipelineModulesDocument), variables));
    },
    GetPipelineDealStages(variables: GetPipelineDealStagesVariables): Promise<{ data?: GetPipelineDealStages | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<GetPipelineDealStages>(print(GetPipelineDealStagesDocument), variables));
    },
    GetModules(variables?: GetModulesVariables): Promise<{ data?: GetModules | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<GetModules>(print(GetModulesDocument), variables));
    },
    GetBudgetOffsetRuleFilters(variables?: GetBudgetOffsetRuleFiltersVariables): Promise<{ data?: GetBudgetOffsetRuleFilters | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<GetBudgetOffsetRuleFilters>(print(GetBudgetOffsetRuleFiltersDocument), variables));
    },
    GetForecastRules(variables?: GetForecastRulesVariables): Promise<{ data?: GetForecastRules | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<GetForecastRules>(print(GetForecastRulesDocument), variables));
    },
    GetForecastRule(variables: GetForecastRuleVariables): Promise<{ data?: GetForecastRule | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<GetForecastRule>(print(GetForecastRuleDocument), variables));
    },
    GetAnalysisScenarios(variables?: GetAnalysisScenariosVariables): Promise<{ data?: GetAnalysisScenarios | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<GetAnalysisScenarios>(print(GetAnalysisScenariosDocument), variables));
    },
    GetAnalysisTypes(variables?: GetAnalysisTypesVariables): Promise<{ data?: GetAnalysisTypes | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<GetAnalysisTypes>(print(GetAnalysisTypesDocument), variables));
    },
    GetMetrics(variables?: GetMetricsVariables): Promise<{ data?: GetMetrics | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<GetMetrics>(print(GetMetricsDocument), variables));
    },
    GetTextMetrics(variables?: GetTextMetricsVariables): Promise<{ data?: GetTextMetrics | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<GetTextMetrics>(print(GetTextMetricsDocument), variables));
    },
    GetOrganizationUsers(variables?: GetOrganizationUsersVariables): Promise<{ data?: GetOrganizationUsers | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<GetOrganizationUsers>(print(GetOrganizationUsersDocument), variables));
    },
    GetOrganizationGroups(variables?: GetOrganizationGroupsVariables): Promise<{ data?: GetOrganizationGroups | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<GetOrganizationGroups>(print(GetOrganizationGroupsDocument), variables));
    },
    GetOrganizationGroup(variables: GetOrganizationGroupVariables): Promise<{ data?: GetOrganizationGroup | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<GetOrganizationGroup>(print(GetOrganizationGroupDocument), variables));
    },
    GetPermissionRoles(variables?: GetPermissionRolesVariables): Promise<{ data?: GetPermissionRoles | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<GetPermissionRoles>(print(GetPermissionRolesDocument), variables));
    },
    GetPortfolioViewPaginated(variables: GetPortfolioViewPaginatedVariables): Promise<{ data?: GetPortfolioViewPaginated | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<GetPortfolioViewPaginated>(print(GetPortfolioViewPaginatedDocument), variables));
    },
    GetPortfolioView(variables: GetPortfolioViewVariables): Promise<{ data?: GetPortfolioView | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<GetPortfolioView>(print(GetPortfolioViewDocument), variables));
    },
    GetPortfolioViewColumns(variables: GetPortfolioViewColumnsVariables): Promise<{ data?: GetPortfolioViewColumns | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<GetPortfolioViewColumns>(print(GetPortfolioViewColumnsDocument), variables));
    },
    GetPortfolioViews(variables: GetPortfolioViewsVariables): Promise<{ data?: GetPortfolioViews | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<GetPortfolioViews>(print(GetPortfolioViewsDocument), variables));
    },
    AllViewColumnOptions(variables?: AllViewColumnOptionsVariables): Promise<{ data?: AllViewColumnOptions | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<AllViewColumnOptions>(print(AllViewColumnOptionsDocument), variables));
    },
    GetEvents(variables?: GetEventsVariables): Promise<{ data?: GetEvents | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<GetEvents>(print(GetEventsDocument), variables));
    },
    GetEvent(variables: GetEventVariables): Promise<{ data?: GetEvent | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<GetEvent>(print(GetEventDocument), variables));
    },
    GetDealAttributes(variables?: GetDealAttributesVariables): Promise<{ data?: GetDealAttributes | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<GetDealAttributes>(print(GetDealAttributesDocument), variables));
    },
    GetDealAnalyses(variables: GetDealAnalysesVariables): Promise<{ data?: GetDealAnalyses | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<GetDealAnalyses>(print(GetDealAnalysesDocument), variables));
    },
    GetDealCurrentAnalysis(variables: GetDealCurrentAnalysisVariables): Promise<{ data?: GetDealCurrentAnalysis | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<GetDealCurrentAnalysis>(print(GetDealCurrentAnalysisDocument), variables));
    },
    GetDealMasterBudgetData(variables: GetDealMasterBudgetDataVariables): Promise<{ data?: GetDealMasterBudgetData | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<GetDealMasterBudgetData>(print(GetDealMasterBudgetDataDocument), variables));
    },
    GetDealAnalysis(variables: GetDealAnalysisVariables): Promise<{ data?: GetDealAnalysis | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<GetDealAnalysis>(print(GetDealAnalysisDocument), variables));
    },
    GetDealMasterBudgetOverview(variables: GetDealMasterBudgetOverviewVariables): Promise<{ data?: GetDealMasterBudgetOverview | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<GetDealMasterBudgetOverview>(print(GetDealMasterBudgetOverviewDocument), variables));
    },
    GetDealTransactions(variables: GetDealTransactionsVariables): Promise<{ data?: GetDealTransactions | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<GetDealTransactions>(print(GetDealTransactionsDocument), variables));
    },
    GetDealTransaction(variables: GetDealTransactionVariables): Promise<{ data?: GetDealTransaction | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<GetDealTransaction>(print(GetDealTransactionDocument), variables));
    },
    GetDealRevisions(variables: GetDealRevisionsVariables): Promise<{ data?: GetDealRevisions | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<GetDealRevisions>(print(GetDealRevisionsDocument), variables));
    },
    GetDealContracts(variables: GetDealContractsVariables): Promise<{ data?: GetDealContracts | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<GetDealContracts>(print(GetDealContractsDocument), variables));
    },
    GetDealContract(variables: GetDealContractVariables): Promise<{ data?: GetDealContract | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<GetDealContract>(print(GetDealContractDocument), variables));
    },
    GetDealPublishedCapitalForecasts(variables: GetDealPublishedCapitalForecastsVariables): Promise<{ data?: GetDealPublishedCapitalForecasts | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<GetDealPublishedCapitalForecasts>(print(GetDealPublishedCapitalForecastsDocument), variables));
    },
    GetDealPublishedMasterBudgetOverview(variables: GetDealPublishedMasterBudgetOverviewVariables): Promise<{ data?: GetDealPublishedMasterBudgetOverview | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<GetDealPublishedMasterBudgetOverview>(print(GetDealPublishedMasterBudgetOverviewDocument), variables));
    },
    GetDealTransactionFormOptions(variables: GetDealTransactionFormOptionsVariables): Promise<{ data?: GetDealTransactionFormOptions | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<GetDealTransactionFormOptions>(print(GetDealTransactionFormOptionsDocument), variables));
    },
    GetBudgetRevision(variables: GetBudgetRevisionVariables): Promise<{ data?: GetBudgetRevision | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<GetBudgetRevision>(print(GetBudgetRevisionDocument), variables));
    },
    GetDealAccountForecasts(variables: GetDealAccountForecastsVariables): Promise<{ data?: GetDealAccountForecasts | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<GetDealAccountForecasts>(print(GetDealAccountForecastsDocument), variables));
    },
    GetPublishedScheduleInstances(variables: GetPublishedScheduleInstancesVariables): Promise<{ data?: GetPublishedScheduleInstances | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<GetPublishedScheduleInstances>(print(GetPublishedScheduleInstancesDocument), variables));
    },
    GetPublishedScheduleInstance(variables: GetPublishedScheduleInstanceVariables): Promise<{ data?: GetPublishedScheduleInstance | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<GetPublishedScheduleInstance>(print(GetPublishedScheduleInstanceDocument), variables));
    },
    GetDealBuildings(variables: GetDealBuildingsVariables): Promise<{ data?: GetDealBuildings | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<GetDealBuildings>(print(GetDealBuildingsDocument), variables));
    },
    GetDealBuilding(variables: GetDealBuildingVariables): Promise<{ data?: GetDealBuilding | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<GetDealBuilding>(print(GetDealBuildingDocument), variables));
    },
    GetDealBonds(variables: GetDealBondsVariables): Promise<{ data?: GetDealBonds | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<GetDealBonds>(print(GetDealBondsDocument), variables));
    },
    GetDealBond(variables: GetDealBondVariables): Promise<{ data?: GetDealBond | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<GetDealBond>(print(GetDealBondDocument), variables));
    },
    GetDealGcEstimates(variables: GetDealGcEstimatesVariables): Promise<{ data?: GetDealGcEstimates | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<GetDealGcEstimates>(print(GetDealGcEstimatesDocument), variables));
    },
    GetGcEstimateTypes(variables?: GetGcEstimateTypesVariables): Promise<{ data?: GetGcEstimateTypes | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<GetGcEstimateTypes>(print(GetGcEstimateTypesDocument), variables));
    },
    GetDealGcEstimate(variables: GetDealGcEstimateVariables): Promise<{ data?: GetDealGcEstimate | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<GetDealGcEstimate>(print(GetDealGcEstimateDocument), variables));
    },
    GetDealPinnedMetrics(variables: GetDealPinnedMetricsVariables): Promise<{ data?: GetDealPinnedMetrics | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<GetDealPinnedMetrics>(print(GetDealPinnedMetricsDocument), variables));
    },
    GetContingencyCategories(variables?: GetContingencyCategoriesVariables): Promise<{ data?: GetContingencyCategories | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<GetContingencyCategories>(print(GetContingencyCategoriesDocument), variables));
    },
    GetAccountCategories(variables?: GetAccountCategoriesVariables): Promise<{ data?: GetAccountCategories | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<GetAccountCategories>(print(GetAccountCategoriesDocument), variables));
    },
    GetAccountTypes(variables?: GetAccountTypesVariables): Promise<{ data?: GetAccountTypes | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<GetAccountTypes>(print(GetAccountTypesDocument), variables));
    },
    GetAccounts(variables?: GetAccountsVariables): Promise<{ data?: GetAccounts | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<GetAccounts>(print(GetAccountsDocument), variables));
    },
    GetAccount(variables: GetAccountVariables): Promise<{ data?: GetAccount | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<GetAccount>(print(GetAccountDocument), variables));
    },
    GetAccountTrees(variables?: GetAccountTreesVariables): Promise<{ data?: GetAccountTrees | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<GetAccountTrees>(print(GetAccountTreesDocument), variables));
    },
    GetAccountTree(variables: GetAccountTreeVariables): Promise<{ data?: GetAccountTree | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<GetAccountTree>(print(GetAccountTreeDocument), variables));
    },
    GetDealChangeItems(variables: GetDealChangeItemsVariables): Promise<{ data?: GetDealChangeItems | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<GetDealChangeItems>(print(GetDealChangeItemsDocument), variables));
    },
    GetDealChangeEvent(variables: GetDealChangeEventVariables): Promise<{ data?: GetDealChangeEvent | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<GetDealChangeEvent>(print(GetDealChangeEventDocument), variables));
    },
    GetDealPotentialChangeOrders(variables: GetDealPotentialChangeOrdersVariables): Promise<{ data?: GetDealPotentialChangeOrders | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<GetDealPotentialChangeOrders>(print(GetDealPotentialChangeOrdersDocument), variables));
    },
    GetDealPotentialChangeOrder(variables: GetDealPotentialChangeOrderVariables): Promise<{ data?: GetDealPotentialChangeOrder | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<GetDealPotentialChangeOrder>(print(GetDealPotentialChangeOrderDocument), variables));
    },
    GetDealChangeOrderPackages(variables: GetDealChangeOrderPackagesVariables): Promise<{ data?: GetDealChangeOrderPackages | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<GetDealChangeOrderPackages>(print(GetDealChangeOrderPackagesDocument), variables));
    },
    GetDealChangeOrderPackage(variables: GetDealChangeOrderPackageVariables): Promise<{ data?: GetDealChangeOrderPackage | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<GetDealChangeOrderPackage>(print(GetDealChangeOrderPackageDocument), variables));
    },
    GetChangeOrderStatuses(variables?: GetChangeOrderStatusesVariables): Promise<{ data?: GetChangeOrderStatuses | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<GetChangeOrderStatuses>(print(GetChangeOrderStatusesDocument), variables));
    },
    GetPotentialChangeOrderTypes(variables?: GetPotentialChangeOrderTypesVariables): Promise<{ data?: GetPotentialChangeOrderTypes | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<GetPotentialChangeOrderTypes>(print(GetPotentialChangeOrderTypesDocument), variables));
    },
    GetChangeEventTypes(variables?: GetChangeEventTypesVariables): Promise<{ data?: GetChangeEventTypes | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<GetChangeEventTypes>(print(GetChangeEventTypesDocument), variables));
    },
    GetChangeEventReasons(variables?: GetChangeEventReasonsVariables): Promise<{ data?: GetChangeEventReasons | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<GetChangeEventReasons>(print(GetChangeEventReasonsDocument), variables));
    },
    GetApprovalPackages(variables?: GetApprovalPackagesVariables): Promise<{ data?: GetApprovalPackages | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<GetApprovalPackages>(print(GetApprovalPackagesDocument), variables));
    },
    GetDealApprovalPackages(variables: GetDealApprovalPackagesVariables): Promise<{ data?: GetDealApprovalPackages | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<GetDealApprovalPackages>(print(GetDealApprovalPackagesDocument), variables));
    },
    GetApprovalPackage(variables: GetApprovalPackageVariables): Promise<{ data?: GetApprovalPackage | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<GetApprovalPackage>(print(GetApprovalPackageDocument), variables));
    },
    GetApprovalPackageStatuses(variables?: GetApprovalPackageStatusesVariables): Promise<{ data?: GetApprovalPackageStatuses | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<GetApprovalPackageStatuses>(print(GetApprovalPackageStatusesDocument), variables));
    },
    GetApprovalPackageReviewerStatuses(variables?: GetApprovalPackageReviewerStatusesVariables): Promise<{ data?: GetApprovalPackageReviewerStatuses | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<GetApprovalPackageReviewerStatuses>(print(GetApprovalPackageReviewerStatusesDocument), variables));
    },
    GetApprovalPackageTypes(variables?: GetApprovalPackageTypesVariables): Promise<{ data?: GetApprovalPackageTypes | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<GetApprovalPackageTypes>(print(GetApprovalPackageTypesDocument), variables));
    },
    GetApprovalPackageType(variables: GetApprovalPackageTypeVariables): Promise<{ data?: GetApprovalPackageType | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<GetApprovalPackageType>(print(GetApprovalPackageTypeDocument), variables));
    },
    GetPublishedApprovalPackage(variables: GetPublishedApprovalPackageVariables): Promise<{ data?: GetPublishedApprovalPackage | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<GetPublishedApprovalPackage>(print(GetPublishedApprovalPackageDocument), variables));
    },
    GetApprovalPackageTableCellEntryOptions(variables?: GetApprovalPackageTableCellEntryOptionsVariables): Promise<{ data?: GetApprovalPackageTableCellEntryOptions | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<GetApprovalPackageTableCellEntryOptions>(print(GetApprovalPackageTableCellEntryOptionsDocument), variables));
    },
    GetApprovalPackageTemplates(variables?: GetApprovalPackageTemplatesVariables): Promise<{ data?: GetApprovalPackageTemplates | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<GetApprovalPackageTemplates>(print(GetApprovalPackageTemplatesDocument), variables));
    },
    GetApprovalPackageTemplate(variables: GetApprovalPackageTemplateVariables): Promise<{ data?: GetApprovalPackageTemplate | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<GetApprovalPackageTemplate>(print(GetApprovalPackageTemplateDocument), variables));
    },
    GetLabels(variables?: GetLabelsVariables): Promise<{ data?: GetLabels | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<GetLabels>(print(GetLabelsDocument), variables));
    },
    GetReportTypes(variables?: GetReportTypesVariables): Promise<{ data?: GetReportTypes | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<GetReportTypes>(print(GetReportTypesDocument), variables));
    },
    GetInvestmentTypes(variables?: GetInvestmentTypesVariables): Promise<{ data?: GetInvestmentTypes | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<GetInvestmentTypes>(print(GetInvestmentTypesDocument), variables));
    },
    GetCrmStatuses(variables?: GetCrmStatusesVariables): Promise<{ data?: GetCrmStatuses | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<GetCrmStatuses>(print(GetCrmStatusesDocument), variables));
    },
    GetFirmTypes(variables?: GetFirmTypesVariables): Promise<{ data?: GetFirmTypes | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<GetFirmTypes>(print(GetFirmTypesDocument), variables));
    },
    GetInteractionTypes(variables?: GetInteractionTypesVariables): Promise<{ data?: GetInteractionTypes | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<GetInteractionTypes>(print(GetInteractionTypesDocument), variables));
    },
    GetArtifactTypes(variables?: GetArtifactTypesVariables): Promise<{ data?: GetArtifactTypes | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<GetArtifactTypes>(print(GetArtifactTypesDocument), variables));
    },
    GetTransactionPeriodSummaries(variables: GetTransactionPeriodSummariesVariables): Promise<{ data?: GetTransactionPeriodSummaries | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<GetTransactionPeriodSummaries>(print(GetTransactionPeriodSummariesDocument), variables));
    },
    GetDealArtifacts(variables: GetDealArtifactsVariables): Promise<{ data?: GetDealArtifacts | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<GetDealArtifacts>(print(GetDealArtifactsDocument), variables));
    },
    GetContactAttachments(variables: GetContactAttachmentsVariables): Promise<{ data?: GetContactAttachments | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<GetContactAttachments>(print(GetContactAttachmentsDocument), variables));
    },
    GetFirmAttachments(variables: GetFirmAttachmentsVariables): Promise<{ data?: GetFirmAttachments | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<GetFirmAttachments>(print(GetFirmAttachmentsDocument), variables));
    },
    GetInterestRates(variables?: GetInterestRatesVariables): Promise<{ data?: GetInterestRates | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<GetInterestRates>(print(GetInterestRatesDocument), variables));
    },
    GetForecastInterestAccountStatus(variables: GetForecastInterestAccountStatusVariables): Promise<{ data?: GetForecastInterestAccountStatus | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<GetForecastInterestAccountStatus>(print(GetForecastInterestAccountStatusDocument), variables));
    },
    GetLatestPipelineAnalysisMetricDistribution(variables: GetLatestPipelineAnalysisMetricDistributionVariables): Promise<{ data?: GetLatestPipelineAnalysisMetricDistribution | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<GetLatestPipelineAnalysisMetricDistribution>(print(GetLatestPipelineAnalysisMetricDistributionDocument), variables));
    },
    GetCategoricalDealCharts(variables: GetCategoricalDealChartsVariables): Promise<{ data?: GetCategoricalDealCharts | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<GetCategoricalDealCharts>(print(GetCategoricalDealChartsDocument), variables));
    },
    GetNumericalDealCharts(variables: GetNumericalDealChartsVariables): Promise<{ data?: GetNumericalDealCharts | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<GetNumericalDealCharts>(print(GetNumericalDealChartsDocument), variables));
    },
    GetAnalyticsSummary(variables: GetAnalyticsSummaryVariables): Promise<{ data?: GetAnalyticsSummary | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<GetAnalyticsSummary>(print(GetAnalyticsSummaryDocument), variables));
    },
    GetFundChart(variables: GetFundChartVariables): Promise<{ data?: GetFundChart | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<GetFundChart>(print(GetFundChartDocument), variables));
    },
    GetDealQuarterEventsChart(variables: GetDealQuarterEventsChartVariables): Promise<{ data?: GetDealQuarterEventsChart | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<GetDealQuarterEventsChart>(print(GetDealQuarterEventsChartDocument), variables));
    },
    GetMarketView(variables: GetMarketViewVariables): Promise<{ data?: GetMarketView | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<GetMarketView>(print(GetMarketViewDocument), variables));
    }
  };
}
export type Sdk = ReturnType<typeof getSdk>;