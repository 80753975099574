import React, { FunctionComponent, useCallback, useMemo, useState } from 'react';
import { Column } from 'ag-grid-community';
import { ToolbarMenu } from '../pageContent';
import { MenuSeparator, DropdownItem } from '../menu/DropdownButton';
import styled from 'styled-components';
import { mixin, font } from 'shared/utils/styles';
import Tooltip from '../Tooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SortModel } from 'shared/hooks';

interface SortMenuButtonProps {
  sortModel: SortModel[];
  applyColumnState: (sortModel: SortModel[]) => void;
  gridColumns: Column[];
}

const SortMenuButton: FunctionComponent<SortMenuButtonProps> = ({
  sortModel,
  applyColumnState,
  gridColumns,
}) => {
  const sortableColumns = React.useMemo(() => {
    const sortable: Column[] = [];
    gridColumns.forEach((col) => {
      const colDef = col.getColDef();
      if (colDef.sortable) {
        sortable.push(col);
      }
    });
    return sortable;
  }, [gridColumns]);

  const [isOpen, setIsOpen] = useState(false);

  const onSortMenuItemClicked = useCallback(
    (col: Column, sort: 'asc' | 'desc' | undefined) => {
      if (sort === undefined) {
        const index = sortModel.findIndex((x) => x.colId == col.getColId());
        sortModel.splice(index, 1);
        sortModel = [...sortModel];
      } else {
        sortModel = [...sortModel, { colId: col.getColId(), sort: sort }];
      }
      applyColumnState(sortModel);
    },
    [applyColumnState, sortModel]
  );

  const onClearSortClicked = useCallback(
    (setIsOpen) => {
      applyColumnState([]);
      setIsOpen(false);
    },
    [applyColumnState]
  );

  const sortButtonTitle = useMemo(() => {
    const sortedColumns: string[] = [];
    sortModel.forEach((sorted) => {
      const col = gridColumns
        .find((x) => x.getColId() === sorted.colId)
        ?.getColDef();
      if (col && col.headerName) sortedColumns.push(col.headerName);
    });
    return sortedColumns.length > 0
      ? `Sorted by: ${sortedColumns.join(', ')}`
      : 'Sort';
  }, [sortModel, gridColumns]);

  return (
    <ToolbarMenu
      icon={'sort-amount-up'}
      title={sortButtonTitle}
      anchor={'right'}
      closeOnClick={false}
    >
      {sortableColumns.length > 0 ? sortableColumns.map((col) => {
        return (
          <SortMenuItem
            key={col.getColId()}
            col={col}
            gridSort={sortModel}
            onSortMenuItemClicked={onSortMenuItemClicked}
          />
        );
      }) : (
        <DropdownItem>No Sortable Columns</DropdownItem>
      )}
      <MenuSeparator />
      <DropdownItem onClick={() => onClearSortClicked(setIsOpen)}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            width: '100%',
            padding: '5px 0',
          }}
        >
          Clear All
        </div>
      </DropdownItem>
    </ToolbarMenu>
  );
};

interface SortMenuItemProps extends React.HTMLAttributes<HTMLDivElement> {
  col: Column;
  gridSort: SortModel[];
  onSortMenuItemClicked: (
    col: Column,
    sort: 'asc' | 'desc' | undefined
  ) => void;
}

const SortArrow = styled.div<{ isActive: boolean }>`
  ${mixin.clickable};
  padding: 2px;
  ${font.size(12)};
  color: ${({ theme }) => theme.textLight};
  ${(props) => props.isActive && `color: ${props.theme.primary}`};
  &: hover {
    color: ${({ theme }) => theme.textDarkest};
  }
`;

const SortMenuItem: FunctionComponent<SortMenuItemProps> = ({
  col,
  gridSort,
  onSortMenuItemClicked,
  ...props
}) => {
  const sort = gridSort.find((x) => x.colId == col.getColId())?.sort;
  const title = col.getColDef().headerName || '(No Header)';
  return (
    <DropdownItem {...props}>
      <Tooltip
        content={sort === 'asc' ? 'Clear Ascending Sort' : 'Sort Ascending'}
      >
        <SortArrow
          isActive={sort === 'asc'}
          onClick={() =>
            onSortMenuItemClicked(col, sort === 'asc' ? undefined : 'asc')
          }
        >
          <FontAwesomeIcon
            icon={[sort === 'asc' ? 'fas' : 'fal', 'arrow-up']}
            fixedWidth={true}
          />
        </SortArrow>
      </Tooltip>
      <Tooltip
        content={sort === 'desc' ? 'Clear Descending Sort' : 'Sort Descending'}
      >
        <SortArrow
          isActive={sort === 'desc'}
          onClick={() =>
            onSortMenuItemClicked(col, sort === 'desc' ? undefined : 'desc')
          }
        >
          <FontAwesomeIcon
            icon={[sort === 'desc' ? 'fas' : 'fal', 'arrow-down']}
            fixedWidth={true}
          />
        </SortArrow>
      </Tooltip>
      <div style={{ marginLeft: '10px' }}>{title}</div>
    </DropdownItem>
  );
};

export default SortMenuButton;
