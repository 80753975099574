import React, { FunctionComponent, useMemo } from 'react';
import { Debug, FormModal, PageDetails } from 'shared/components';
import * as Yup from 'yup';
import InteractionFormDetails from './InteractionFormDetails';
import { useCreateInteraction } from 'shared/hooks/api';
import { CreateInteractionInput, InteractionAttendeeEnum } from 'shared/hooks/api/graphql/generated';
import { useNotification } from 'shared/components/notifications';
import { RequiredTextEditorSchema } from 'shared/components/form/textEditor/TextEditor';
import { oneOfEnum } from 'App/Organization/Approvals/ApprovalPackage/modals/ArchiveApprovalPackageModal';
import { formatDate } from 'shared/utils/formatting/formatters';
import { yearMonthDayFormat } from 'shared/utils/dateFormats';

interface CreateInteractionModalProps {
  onClose: () => void;
  firmId?: number;
  contactId?: number;
  firm?: Option;
  contact?: Option;
  deal?: Option;
}

type Option = {
  value: number;
  label: string;
};

type Attendee = {
  value: number;
  label: string;
  type: InteractionAttendeeEnum;
};

export interface InteractionFormValues {
  name: string;
  interactionDate: Date;
  type: Option;
  attendees: Attendee[];
  deals: Option[];
  notes: any;
  firms: Option[];
}

export const OptionSchema = () =>
  Yup.object().shape({
    value: Yup.number().required(),
    label: Yup.string().required(),
  });

const AttendeeSchema = () =>
  Yup.object().shape({
    value: Yup.number().required(),
    label: Yup.string().required(),
    type: oneOfEnum(InteractionAttendeeEnum).required(),
  });

export const interactionFormSchema: Yup.ObjectSchema<InteractionFormValues> = Yup.object()
  .shape({
    name: Yup.string().required().label('Name'),
    interactionDate: Yup.date().required().label('Interaction Date'),
    type: OptionSchema()
      .required()
      .label('Type'),
    attendees: Yup.array().min(0).of(AttendeeSchema().required()).defined().label('Attendees'),
    deals: Yup.array().min(0).of(OptionSchema().required()).defined().label('Deals'),
    firms: Yup.array().min(0).of(OptionSchema().required()).defined().label('Firms'),
    notes: RequiredTextEditorSchema().label('Notes'),
  })
  .required();

const CreateInteractionModal: FunctionComponent<CreateInteractionModalProps> = ({
  onClose,
  firmId,
  contactId,
  firm,
  contact,
  deal
}) => {
  const [
    createInteraction,
    { status: createInteractionStatus },
  ] = useCreateInteraction(firmId, contactId);
  const notify = useNotification();

  const initialValues = useMemo(() => {
    const deals = deal ? [deal] : [];
    const attendees = contact ? [{ value: contact.value, label: contact.label, type: InteractionAttendeeEnum.Contact }] : [];
    const firms = firm ? [firm] : [];
    return {
      firms: firms,
      attendees: attendees,
      deals: deals,
    }
  }, [contact, firm, deal]);


  return (
    <FormModal<InteractionFormValues>
      title={'Log New Interaction'}
      onClose={onClose}
      isWorking={createInteractionStatus == 'loading'}
      submitTitle={'Create'}
      initialValues={initialValues}
      showInvalidFormTooltip={true}
      handleSubmit={async (values) => {
        const deals = values.deals !== null ? values.deals : [];
        const firms = values.firms !== null ? values.firms : [];
        const attendees = values.attendees !== null ? values.attendees : [];
        const contactIds = attendees.filter(x => x.type === InteractionAttendeeEnum.Contact).map((attendee) => attendee.value);
        const userIds = attendees.filter(x => x.type === InteractionAttendeeEnum.User).map((attendee) => attendee.value);
        const input: CreateInteractionInput = {
          name: values.name,
          interactionDate: formatDate(values.interactionDate, yearMonthDayFormat)!,
          fkInteractionType: values.type.value,
          interactionContactIds: contactIds,
          interactionUserIds: userIds,
          dealIds: deals.map((deal) => deal.value),
          notes: JSON.stringify(values.notes),
          interactionFirmIds: firms.map((firm) => firm.value),
        };
        try {
          const result = await createInteraction(input);
          if (result.errors) {
            notify({
              duration: 5000,
              title: 'Uh-oh!',
              variant: 'danger',
              message:
                'An unknown error occurred while creating the interaction!',
            });
          } else {
            notify({
              title: 'Success!',
              message: 'Interaction Logged!',
              variant: 'success',
            });
            onClose();
          }
        } catch {
          notify({
            duration: 5000,
            title: 'Uh-oh!',
            variant: 'danger',
            message:
              'An unknown error occurred while creating the interaction!',
          });
        }
      }}
      validationSchema={interactionFormSchema}
      size={'lg'}
    >
      {(props) => (
        <PageDetails>
          <InteractionFormDetails form={props} />
          {/* <Debug state={props}/> */}
        </PageDetails>
      )}
    </FormModal>
  );
};

export default CreateInteractionModal;
