import React from 'react';
import NormalizedStyles from './Styles/NormalizedStyles';
import BaseStyles from './Styles/BaseStyles';
import Routes from './Routes';
import initializeIconLibrary from '../shared/utils/icons/IconsInit';
import ModalStyles from './Styles/ModalStyles';
import 'shared/css/ag-grid-custom.scss';
import { LicenseManager } from 'ag-grid-enterprise';
import { useGetCurrentUser } from 'shared/hooks/api';
import { createGlobalStyle } from 'styled-components';

LicenseManager.setLicenseKey(
  'CompanyName=The Wolff Company,LicensedGroup=Aleks Vlahovic,LicenseType=MultipleApplications,LicensedConcurrentDeveloperCount=1,LicensedProductionInstancesCount=0,AssetReference=AG-014383,ExpiryDate=15_April_2022_[v2]_MTY0OTk3NzIwMDAwMA==5681344c6c106e611b475c86a17b36bb'
);

initializeIconLibrary();

const App = () => {
  const { data, status } = useGetCurrentUser();

  return (
    <React.Fragment>
      <NormalizedStyles />
      <BaseStyles />
      <ModalStyles />
      {status == 'success' && data && <Routes />}
    </React.Fragment>
  );
};

export default App;
