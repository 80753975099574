import React, { HTMLAttributes, FunctionComponent, useState, InputHTMLAttributes } from 'react';
import styled from 'styled-components';
import FieldWrapper, { FieldWrapperProps } from 'shared/components/form/index';
import { useField } from 'formik';

const CheckboxInput = styled.input`
  appearance: checkbox;
`;

interface CheckboxProps extends InputHTMLAttributes<HTMLInputElement> {
  checked?: boolean;
}

const Checkbox: FunctionComponent<CheckboxProps> = ({
  checked,
  onChange,
  ...props
}) => {
  return (
    <CheckboxInput
      type={'checkbox'}
      checked={checked}
      onChange={onChange}
      {...props}
    />
  );
};

export interface CheckboxFieldProps
  extends CheckboxProps,
    FieldWrapperProps {
  name: string;
}

export const CheckboxField: FunctionComponent<CheckboxFieldProps> = ({
  label,
  error,
  ...props
}) => {
  return (
    <FieldWrapper label={label} error={error} {...props}>
      <Checkbox {...props} />
    </FieldWrapper>
  );
};

export const CheckboxFormikField: FunctionComponent<CheckboxFieldProps> = ({
  name,
  ...props
}) => {
  const [field, meta, helpers] = useField(name);
  const setValue = helpers.setValue;
  return (
    <CheckboxField
      error={meta.error}
      {...props}
      {...field}
      checked={field.value}
      onChange={() => setValue(!field.value)}
    />
  );
};

export default Checkbox;
