import { useContext, useCallback, useEffect } from 'react';
import React from 'react';
import { usePrevious } from './usePrevious';
import { IconName } from '@fortawesome/pro-light-svg-icons';
import { getQueryParamAsObject } from 'shared/utils/queryParamDocumentPath';

export interface Breadcrumb {
  title: string;
  icon?: IconName;
  path: string;
  key: string;
  index: number;
}

export interface BreadcrumbsContextValue {
  breadcrumbs: Breadcrumb[];
  setBreadcrumbs?: React.Dispatch<React.SetStateAction<Breadcrumb[]>>;
}

export const BreadcrumbsContext = React.createContext<BreadcrumbsContextValue>({
  breadcrumbs: new Array<Breadcrumb>(),
});

export const useBreadcrumbs = () => {
  const { breadcrumbs, setBreadcrumbs } = useContext(BreadcrumbsContext);
  const prevBreadcrumbs = usePrevious(breadcrumbs);

  const addBreadcrumb = useCallback(
    (breadcrumb: Breadcrumb) => {
      if (setBreadcrumbs) {
        setBreadcrumbs((crumbs) => {
          const existing = crumbs.findIndex((x) => x.key == breadcrumb.key);
          if (existing == -1) {
            return [...crumbs, breadcrumb].sort((x, y) => x.index - y.index);
          } else {
            return crumbs.sort((x, y) => x.index - y.index);
          }
        });
      }
    },
    [setBreadcrumbs]
  );

  const removeBreadcrumb = useCallback(
    (key: string) => {
      if (setBreadcrumbs) {
        setBreadcrumbs((crumbs) => {
          const copy = [...crumbs];
          const index = crumbs.findIndex((x) => x.key == key);
          if (index != -1) {
            copy.splice(index, 1);
          }
          return copy.sort((x, y) => x.index - y.index);
        });
      }
    },
    [setBreadcrumbs]
  );

  return {
    breadcrumbs,
    addBreadcrumb,
    removeBreadcrumb,
  };
};

export const useProvidedBreadcrumbs = (index: number) => {
  const originName = getQueryParamAsObject('originName');
  const originSource = getQueryParamAsObject('originSource');
  const crumb =
    originName != null && originSource != null
      ? {
          title: originName as string,
          path: originSource as string,
          key: 'provided',
          index: index,
        }
      : undefined;
  useBreadcrumb(crumb, [originName, originSource, index]);
};

export const useBreadcrumb = (breadcrumb?: Breadcrumb, deps: any[] = []) => {
  const { addBreadcrumb, removeBreadcrumb } = useBreadcrumbs();

  useEffect(() => {
    if (breadcrumb) {
      addBreadcrumb(breadcrumb);
    }
    return () => {
      if (breadcrumb) {
        removeBreadcrumb(breadcrumb.key);
      }
    };
  }, [...deps, addBreadcrumb, removeBreadcrumb]);
};
