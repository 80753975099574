import React, { FunctionComponent, useContext, useMemo, useState } from 'react';
import {
  Modal,
  Section,
  PageDetails,
  Button,
  DropdownButton,
  DropdownItem,
  FormModal,
  Row,
  Column,
  InputFormikField,
  TextareaFormikField,
  Dialog,
} from 'shared/components';
import styled, { ThemeContext } from 'styled-components';
import { font } from 'shared/utils/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as Yup from 'yup';
import {
  UpdateDealMeritInput,
  CreateDealMeritInput,
} from 'shared/hooks/api/graphql/generated';
import { diff } from 'shared/utils/deepDiff';
import {
  useUpdateDealMerit,
  useDeleteDealMerit,
  useCreateDealMerit,
} from 'shared/hooks/api';
import { useNotification } from 'shared/components/notifications';
import { AuthorAvatar } from './KeyRisksModal';
import NoAccess from 'shared/components/noAccess';
import { sortBy } from 'shared/utils/sorting';
import ReorderModal from './ReorderModal'

export type DealMerit = {
  id: number;
  title: string;
  meritDescription?: string;
  updatedAt: Date;
  updatedBy: string;
  orderId?: number;
};

interface DealMeritsModalProps {
  onClose: () => void;
  onSuccess: () => void;
  oppId: number;
  dealMerits: DealMerit[];
  currentUserCanEdit: boolean;
}

const DealMeritsModal: FunctionComponent<DealMeritsModalProps> = ({
  onClose,
  onSuccess,
  dealMerits,
  oppId,
  currentUserCanEdit,
}) => {
  const themeContext = useContext(ThemeContext);
  const meritsContent = useMemo(() => {
    return dealMerits.sort(sortBy((x) => x.orderId)).map((merit) => (
      <DealMerit key={merit.id} merit={merit} onSuccess={onSuccess} />
    ));
  }, [dealMerits]);

  const [
    isShowingCreateDealMeritModal,
    setIsShowingCreateDealMeritModal,
  ] = useState(false);

  const [
    isShowingReorderModal,
    setIsShowingReorderModal,
  ] = useState(false)

  return (
    <Modal
      title="Deal Merits"
      isOpen={true}
      onClose={onClose}
      size={'lg'}
      tools={[
        <Button
          key={'new-key-merit'}
          variant={'outline'}
          icon={'plus'}
          onClick={() => setIsShowingCreateDealMeritModal(true)}
          disabled={!currentUserCanEdit}
        >
          New Deal Merit
        </Button>,
        <Button
          key={'reorder-deal-merits'}
          variant={'outline'}
          icon={'grip-vertical'}
          onClick={() => setIsShowingReorderModal(true)}
          disabled={!currentUserCanEdit}
        >
          Reorder Deal Merits
        </Button>
      ]}
    >
      <PageDetails>
        {meritsContent.length != 0 ? (
          meritsContent
        ) : (
          <NoAccess
            title={'No Deal Merits'}
            subtitle={'Any deal merits added will be viewable here'}
            icon={'comment-alt-check'}
            iconColor={themeContext.success}
          />
        )}
      </PageDetails>
      {isShowingCreateDealMeritModal && (
        <CreateDealMeritModal
          onClose={() => setIsShowingCreateDealMeritModal(false)}
          onSuccess={onSuccess}
          oppId={oppId}
        />
      )}
      {isShowingReorderModal && (
        <ReorderModal
          onClose={() => setIsShowingReorderModal(false)}
          content={dealMerits}
          title={"Reorder Deal Merits"}
          contentType={'deal-merits'}
          oppId={oppId}
        />
      )}
    </Modal>
  );
};

export default DealMeritsModal;

interface DealMeritProps {
  merit: DealMerit;
  onSuccess: () => void;
}

const DealMerit: FunctionComponent<DealMeritProps> = ({ merit, onSuccess }) => {
  const themeContext = useContext(ThemeContext);
  const [isShowingEditModal, setIsShowingEditModal] = useState(false);
  const [isShowingDeleteDialog, setIsShowingDeleteDialog] = useState(false);
  const [
    deleteDealMerit,
    { status: deleteDealMeritStatus },
  ] = useDeleteDealMerit();
  const notify = useNotification();
  return (
    <React.Fragment>
      <Section
        icon={
          <FontAwesomeIcon
            icon={['fal', 'comment-alt-check']}
            color={themeContext.success}
          />
        }
        title={merit.title || 'No Title'}
        right={[
          <AuthorAvatar
            key={'merit-author'}
            name={merit.updatedBy}
            size={25}
            lastUpdated={merit.updatedAt}
          />,
          <DropdownButton
            icon={'chevron-down'}
            key={'edit-menu'}
            slim={true}
            variant={'simple'}
            anchor={'right'}
          >
            <DropdownItem
              icon={<FontAwesomeIcon icon={['fal', 'pencil']} />}
              onClick={() => setIsShowingEditModal(true)}
            >
              Edit
            </DropdownItem>
            <DropdownItem
              icon={
                <FontAwesomeIcon icon={['fal', 'trash']} color={themeContext.danger} />
              }
              onClick={() => setIsShowingDeleteDialog(true)}
            >
              Delete
            </DropdownItem>
          </DropdownButton>,
        ]}
      >
        <DealMeritBodyWrapper>
          <DealMeritBody>
            {merit.meritDescription}
          </DealMeritBody>
        </DealMeritBodyWrapper>
      </Section>
      {isShowingEditModal && (
        <EditDealMeritModal
          onClose={() => setIsShowingEditModal(false)}
          onSuccess={onSuccess}
          merit={merit}
        />
      )}
      {isShowingDeleteDialog && (
        <Dialog
          variant={'danger'}
          isOpen={true}
          title={'Delete Deal Merit'}
          confirmTitle={'Yes, Delete'}
          message={`This will delete the deal merit titled "${merit.title}". Are you sure you want to do this?`}
          isWorking={deleteDealMeritStatus == 'loading'}
          onConfirm={async () => {
            try {
              await deleteDealMerit(merit.id);
              setIsShowingDeleteDialog(false);
              notify({
                message: 'Deal merit successfully deleted!',
              });
              onSuccess();
            } catch {
              notify({
                duration: 5000,
                variant: 'danger',
                message: 'An error occcurred while deleting this deal merit.',
              });
            }
          }}
          onClose={() => setIsShowingDeleteDialog(false)}
          onCancel={() => setIsShowingDeleteDialog(false)}
        />
      )}
    </React.Fragment>
  );
};

interface EditDealMeritModalProps {
  onClose: () => void;
  onSuccess: () => void;
  merit: DealMerit;
}

const validationSchema = Yup.object()
  .shape({
    title: Yup.string()
      .min(3, 'Too Short!')
      .max(50, 'Too Long!')
      .required('Required')
      .label("Title"),
    meritDescription: Yup.string().required('Required').label("Description"),
  })
  .required();

type Option = {
  value: number;
  label: string;
};

export type DealMeritFormValues = {
  title: string;
  meritDescription: string;
};

const EditDealMeritModal: FunctionComponent<EditDealMeritModalProps> = ({
  onClose,
  onSuccess,
  merit,
}) => {
  const initialValues = useMemo(() => {
    return {
      title: merit.title,
      meritDescription: merit.meritDescription,
    };
  }, [merit]);

  const [updateDealMerit, { status }] = useUpdateDealMerit();
  const notify = useNotification();

  return (
    <FormModal<DealMeritFormValues>
      initialValues={initialValues}
      title={'Edit Deal Merit'}
      submitTitle={'Update'}
      onClose={onClose}
      isWorking={status == 'loading'}
      validationSchema={validationSchema}
      size={'lg'}
      handleSubmit={async (form, resetForm) => {
        const changes = diff(initialValues, form);
        const input: UpdateDealMeritInput = {
          id: merit.id,
          title: changes.title,
          meritDescription: changes.meritDescription,
        };
        try {
          await updateDealMerit(input);
          notify({
            duration: 3000,
            title: 'Success!',
            message: 'Deal Merit Updated!',
            variant: 'success',
          });
          onClose();
          onSuccess();
          resetForm();
        } catch (error) {
          notify({
            duration: 5000,
            title: 'Uh-oh!',
            message: `An unknown error occurred while updating this key merit! ${error}`,
            variant: 'danger',
          });
        }
      }}
    >
      {(props) => (
        <PageDetails>
          <DealMeritForm />
        </PageDetails>
      )}
    </FormModal>
  );
};

interface CreateDealMeritModalProps {
  onClose: () => void;
  onSuccess: () => void;
  oppId: number;
}

const CreateDealMeritModal: FunctionComponent<CreateDealMeritModalProps> = ({
  onClose,
  onSuccess,
  oppId,
}) => {
  const [createDealMerit, { status }] = useCreateDealMerit(oppId);
  const notify = useNotification();

  return (
    <FormModal<DealMeritFormValues>
      initialValues={{}}
      title={'New Deal Merit'}
      submitTitle={'Create Deal Merit'}
      onClose={onClose}
      isWorking={status == 'loading'}
      validationSchema={validationSchema}
      size={'lg'}
      handleSubmit={async (form, resetForm) => {
        const input: CreateDealMeritInput = {
          fkDeal: oppId,
          title: form.title,
          meritDescription: form.meritDescription,
        };
        try {
          await createDealMerit(input);
          notify({
            duration: 3000,
            title: 'Success!',
            message: 'Deal Merit Created!',
            variant: 'success',
          });
          onClose();
          onSuccess();
          resetForm();
        } catch (error) {
          notify({
            duration: 5000,
            title: 'Uh-oh!',
            message: `An unknown error occurred while creating this deal merit! ${error}`,
            variant: 'danger',
          });
        }
      }}
    >
      {(props) => (
        <PageDetails>
          <DealMeritForm />
        </PageDetails>
      )}
    </FormModal>
  );
};

const DealMeritForm = () => {
  return (
    <React.Fragment>
      <Row>
        <Column>
          <InputFormikField
            name={'title'}
            label={'Title'}
            placeholder={`Enter a title...`}
          />
        </Column>
        <Column span={12}>
          <TextareaFormikField
            name={'meritDescription'}
            label={'Description'}
            placeholder={`Enter a description here...`}
            minRows={10}
          />
        </Column>
      </Row>
    </React.Fragment>
  );
};

const DealMeritBodyWrapper = styled.div`
  display: flex;
  flex-direction: row;
  white-space: pre-wrap;
`;

const DealMeritBody = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  ${font.size(12)};
  padding: 0 10px;
`;

const DealMeritBodyTitle = styled.div`
  ${font.medium};
  color: ${({ theme }) => theme.textDarkest};
  margin-bottom: 10px;
`;
