/* eslint-disable @typescript-eslint/no-empty-function */
import React, { useEffect, useContext } from 'react';
import { useIsMobileWidth } from 'shared/hooks';

interface SidebarContextValue {
  isMainSidebarOpen: boolean;
  isNestedSidebarOpen?: boolean;
  setIsMainSidebarOpen: (value?: boolean) => void;
  setIsNestedSidebarOpen: (value?: boolean) => void;
}

export const SidebarContext = React.createContext<SidebarContextValue>({ 
  isMainSidebarOpen: false,
  isNestedSidebarOpen: undefined,
  setIsMainSidebarOpen: () => {},
  setIsNestedSidebarOpen: () => {},
})

export const useSidebarContext = () => {
  const value = useContext(SidebarContext);
  return value;
}

type SetSidebarIsOpenFunc = (value?: boolean) => void

export const useSidebarStorage = (id: string, setIsSidebarOpen: SetSidebarIsOpenFunc, isSidebarOpen?: boolean) => {
  const isMobileWidth = useIsMobileWidth();
  useEffect(() => {
    const value = localStorage.getItem(`sidebar:${id}`);
    if (typeof value == 'undefined' || value == null) {
      setIsSidebarOpen(isMobileWidth ? false : true);
    } else {
      const val = value === 'true';
      setIsSidebarOpen(val);
    }
    return () => {
      setIsSidebarOpen(undefined);
    }
  }, [id, isMobileWidth])

  useEffect(() => {
    if (isSidebarOpen != undefined) {
      localStorage.setItem(`sidebar:${id}`, isSidebarOpen.toString());
    }
  }, [isSidebarOpen, id])
}
