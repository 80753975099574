import React, {
  FunctionComponent,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import FieldWrapper, { FieldWrapperProps } from '..';
import { useField } from 'formik';
import ReactSelect, {
  components,
  Props as ReactSelectProps,
  Theme,
} from 'react-select';
import ReactSelectAsync, {
  Props as ReactSelectAsyncProps,
} from 'react-select/async';
import ReactSelectCreatable, {
  Props as ReactSelectCreatableProps,
} from 'react-select/creatable';
import ReactSelectCreatableAsync, {
  Props as ReactSelectCreatableAsyncProps,
} from 'react-select/async-creatable';
import { lightTheme, mixin } from 'shared/utils/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SelectOption from './SelectOption';
import { DefaultTheme, ThemeContext } from 'styled-components';

const IconOption = (props: any) => {
  return (
    <components.Option {...props}>
      <div style={{ display: 'inline-flex', alignItems: 'center' }}>
        {props.data.icon ? (
          <React.Fragment>
            <FontAwesomeIcon
              icon={['fal', props.data.icon]}
              fixedWidth={true}
              color={lightTheme.textLight}
            />
            <div style={{ marginLeft: '10px' }}>{props.data.label}</div>
          </React.Fragment>
        ) : (
          props.data.label
        )}
      </div>
    </components.Option>
  );
};

const IconSingleValue = (props: any) => (
  <components.SingleValue {...props}>
    <div style={{ display: 'inline-flex', alignItems: 'center' }}>
      {props.data.icon ? (
        <React.Fragment>
          <FontAwesomeIcon
            icon={['fal', props.data.icon]}
            fixedWidth={true}
            color={props.data.color}
          />
          <div style={{ marginLeft: '10px' }}>{props.data.label}</div>
        </React.Fragment>
      ) : (
        props.data.label
      )}
    </div>
  </components.SingleValue>
);

export const customSelectStyles = (theme: DefaultTheme, height = '30px') => {
  return {
    menuPortal: (base: any) => ({ ...base, zIndex: 100000 }),
    container: (provided: any) => ({
      ...provided,
      backgroundColor: theme.backgroundPrimary,
      height: height,
      minHeight: height,
    }),
    indicatorsContainer: (provided: any) => ({
      ...provided,
      height: height,
      minHeight: height,
      fontSize: '10px',
    }),
    control: (provided: any, state: { isFocused: boolean }) => {
      return {
        ...provided,
        backgroundColor: theme.backgroundPrimary,
        height: height,
        minHeight: height,
        border: state.isFocused
          ? `1px solid ${theme.border}`
          : `1px solid ${theme.border}`,
        borderColor: state.isFocused ? theme.primary : theme.border,        
      };
    },
    valueContainer: (provided: any) => {
      return {
        ...provided,
        display: 'flex',
        alignItems: 'center',
        color: theme.textMedium,
      };
    },
    singleValue: (provided: any) => {
      return {
        ...provided,
        color: theme.textMedium,
      };
    },
    multiValue: (provided: any) => {
      return {
        ...provided,
        backgroundColor: theme.backgroundPrimary,
        border: `1px solid ${theme.border}`,
        borderRadius: '3px',
        display: 'flex',
        alignItems: 'center',
        marginBottom: '2px',
      };
    },
    multiValueLabel: (provided: any) => {
      return {
        ...provided,
        backgroundColor: theme.backgroundPrimary,
        color: theme.info,
        fontSize: '100%',
      };
    },
    multiValueRemove: (provided: any) => {
      return {
        ...provided,
        backgroundColor: theme.backgroundPrimary,
        height: '100%',
      };
    },
    placeholder: (provided: any) => {
      return {
        ...provided,
        color: theme.textMedium,
      };
    },
    option: (provided: any, state: { isFocused: boolean, isSelected: boolean }) => ({
      ...provided,
      backgroundColor: state.isFocused
        ? theme.secondary
        : state.isSelected
          ? theme.primary
          : provided.backgroundColor,
      color: state.isSelected
        ? 'white'
        : state.isFocused
          ? theme.textDarkest
          : provided.color,
    }),
    menuList: (provided: any) => ({
      ...provided,
      '::-webkit-scrollbar': {
        width: '8px',
        height: '8px',
      },
      '::-webkit-scrollbar-track': {
        background: theme.backgroundLight,
      },
      '::-webkit-scrollbar-thumb': {
        background: theme.textLight,
        borderRadius: '4px',
      },
    }),
    input: (provided: any) => ({
      ...provided,
      color: theme.textDark,
    }),
    menu: (provided: any) => ({
      ...provided,
      borderColor: theme.border,
      borderWidth: '1px',
      borderStyle: 'solid',
    }),
    
  };
};

export const getDefaultSelectProps = (theme: DefaultTheme) => ({
  components: { Option: IconOption, SingleValue: IconSingleValue },
  styles: customSelectStyles(theme, '30px'),
  isClearable: false,
  options: [],
  placeholder: 'Select a value...',
  noOptionsMessage: () => 'No Options',
  theme: (selectTheme: Theme) => ({
    ...selectTheme,
    borderRadius: 3,
    colors: {
      ...selectTheme.colors,
      primary: theme.primary,
      neutral0: theme.backgroundPrimary,
      primary75: mixin.lighten(theme.primary, 0.25),
      primary50: mixin.lighten(theme.primary, 0.5),
      primary25: mixin.lighten(theme.primary, 0.75),
      danger: theme.danger,
    },
  }),
  menuPortalTarget: document.body,
  isMulti: false,
});

interface SelectProps<IsMulti extends boolean = false>
  extends ReactSelectProps<SelectOption, IsMulti> {
  loadOptions?: () => void;
}

function Select<IsMulti extends boolean = false>({
  loadOptions,
  ...props
}: SelectProps<IsMulti>) {
  const themeContext = useContext(ThemeContext);
  const defaultSelectProps = getDefaultSelectProps(themeContext);
  const [shouldLoadOptions, setShouldLoadOptions] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const timeout = useRef<number>();

  useEffect(() => {
    setIsLoading(props.isLoading || false);
  }, [props.isLoading]);

  const onFocus = useCallback(
    async (event: React.FocusEvent<HTMLElement>) => {
      if (props.onFocus) props.onFocus(event);
      if (loadOptions && shouldLoadOptions) {
        setIsLoading(true);
        try {
          await loadOptions();
        } finally {
          setIsLoading(false);
          setShouldLoadOptions(false);
          timeout.current = (setTimeout(
            () => setShouldLoadOptions(true),
            10000
          ) as unknown) as number;
        }
      }
    },
    [
      loadOptions,
      shouldLoadOptions,
      setShouldLoadOptions,
      timeout,
      props.onFocus,
    ]
  );

  return (
    <ReactSelect
      {...defaultSelectProps}
      {...props}
      isMulti={props.isMulti}
      isLoading={isLoading}
      onFocus={onFocus}
    />
  );
}

export default Select;

export function SelectAsync<IsMulti extends boolean>({
  ...props
}: ReactSelectAsyncProps<SelectOption, IsMulti>) {
  const themeContext = useContext(ThemeContext);
  const defaultSelectProps = getDefaultSelectProps(themeContext);
  return (
    <ReactSelectAsync
      {...defaultSelectProps}
      {...props}
      isMulti={props.isMulti}
    />
  );
}

export function SelectCreatable<IsMulti extends boolean>({
  ...props
}: ReactSelectCreatableProps<SelectOption, IsMulti>) {
  const themeContext = useContext(ThemeContext);
  const defaultSelectProps = getDefaultSelectProps(themeContext);
  return (
    <ReactSelectCreatable
      {...defaultSelectProps}
      {...props}
      isMulti={props.isMulti}
    />
  );
}

export function SelectCreatableAsync<IsMulti extends boolean>({
  ...props
}: ReactSelectCreatableAsyncProps<SelectOption, IsMulti>) {
  const themeContext = useContext(ThemeContext);
  const defaultSelectProps = getDefaultSelectProps(themeContext);
  return (
    <ReactSelectCreatableAsync
      {...defaultSelectProps}
      {...props}
      isMulti={props.isMulti}
    />
  );
}

interface SelectFieldProps<IsMulti extends boolean = false>
  extends SelectProps<IsMulti>,
    FieldWrapperProps {
  name: string;
}

export function SelectField<IsMulti extends boolean = false>({
  label,
  error,
  ...props
}: SelectFieldProps<IsMulti>) {
  return (
    <FieldWrapper label={label} error={error} {...props}>
      <Select {...props} />
    </FieldWrapper>
  );
}

export function SelectFormikField<IsMulti extends boolean = false>({
  name,
  ...props
}: SelectFieldProps<IsMulti>) {
  const [field, meta, helpers] = useField(name);
  const { setValue } = helpers;
  return (
    <SelectField
      error={meta.error}
      {...props}
      {...field}
      onChange={(opt) => {
        if (props.onChange) {
          props.onChange(opt);
        }
        setValue(opt);
      }}
      value={field.value}
    />
  );
}

interface SelectAsyncFieldProps<IsMulti extends boolean = false>
  extends ReactSelectAsyncProps<SelectOption, IsMulti>,
    FieldWrapperProps {
  name: string;
}

export const SelectAsyncField: FunctionComponent<SelectAsyncFieldProps> = ({
  label,
  error,
  ...props
}) => {
  return (
    <FieldWrapper label={label} error={error} {...props}>
      <SelectAsync {...props} />
    </FieldWrapper>
  );
};

export const SelectAsyncFormikField: FunctionComponent<SelectAsyncFieldProps> = ({
  name,
  ...props
}) => {
  const [field, meta, helpers] = useField(name);
  const { setValue } = helpers;
  return (
    <SelectAsyncField
      error={meta.error}
      {...props}
      {...field}
      onChange={(opt) => setValue(opt)}
      value={field.value}
    />
  );
};

export interface SelectCreatableFieldProps<IsMulti extends boolean = false>
  extends ReactSelectCreatableProps<SelectOption, IsMulti>,
    FieldWrapperProps {
  name: string;
}

export function SelectCreatableField<IsMulti extends boolean = false>({
  label,
  error,
  ...props
}: SelectCreatableFieldProps<IsMulti>) {
  return (
    <FieldWrapper label={label} error={error} {...props}>
      <SelectCreatable {...props} />
    </FieldWrapper>
  );
}

export function SelectCreatableFormikField<IsMulti extends boolean = false>({
  name,
  ...props
}: SelectCreatableFieldProps<IsMulti>){
  const [field, meta, helpers] = useField(name);
  const { setValue } = helpers;
  return (
    <SelectCreatableField
      error={meta.error}
      {...props}
      {...field}
      onChange={(opt) => setValue(opt)}
      value={field.value}
    />
  );
}

interface SelectCreatableAsyncFieldProps<IsMulti extends boolean = false>
  extends ReactSelectCreatableAsyncProps<SelectOption, IsMulti>,
    FieldWrapperProps {
  name: string;
}

export const SelectCreatableAsyncField: FunctionComponent<SelectCreatableAsyncFieldProps> = ({
  label,
  error,
  ...props
}) => {
  return (
    <FieldWrapper label={label} error={error} {...props}>
      <SelectCreatableAsync {...props} />
    </FieldWrapper>
  );
};

export const SelectCreatableAsyncFormikField: FunctionComponent<SelectCreatableAsyncFieldProps> = ({
  name,
  ...props
}) => {
  const [field, meta, helpers] = useField(name);
  const { setValue } = helpers;
  return (
    <SelectCreatableAsyncField
      error={meta.error}
      {...props}
      {...field}
      onChange={(opt) => setValue(opt)}
      value={field.value}
    />
  );
};
