import { css } from 'styled-components';
import Color from 'color';
import 'styled-components';
import { differenceInCalendarDays } from 'date-fns';
import parseISO from 'date-fns/parseISO';
import { Milestone } from 'App/Organization/Deal/Summary/SummaryDashboard';

declare module 'styled-components' {
  export interface DefaultTheme {
    info: string;
    success: string;
    danger: string;
    warning: string;
    secondary: string;
    primary: string;
    lightBlue: string;
    purple: string;
    pink: string;
    mint: string;
    charcoal: string;
    highlight: string;

    textDarkest: string;
    textDark: string;
    textMedium: string;
    textLight: string;
    textLightest: string;
    textLink: string;

    tableHeaderFont: string;
    tableHeaderBackground: string;
    tableBorder: string;
    tableOddRowBackground: string;
    tableEvenRowBackground: string;

    backgroundPrimary: string;
    backgroundDarkPrimary: string;
    backgroundMedium: string;
    backgroundLight: string;
    backgroundLightest: string;
    backgroundLightPrimary: string;
    backgroundLightSuccess: string;

    border: string;
    borderLightest: string;
    borderLight: string;
    borderInputFocus: string;

    disabledInputBackground: string;
    disabledInputText: string;

    nestedSidebar: {
      background: string;
      border: string;
      separator: string;
      link: string;
      activeBackground: string;
      activeIcon: string;
    };

    sidebar: {
      background: string;
      border: string;
      separator: string;
      link: string;
      activeBackground: string;
      activeIcon: string;
    };
    isDark: boolean;
  }
}

export const lightTheme = {
  info: '#359bdf', // blue
  success: '#30cea6', // green
  danger: '#f4516c', // red
  warning: '#ffb822', // yellow
  secondary: '#ebecf0', // light grey
  primary: '#359bdf', // light grey
  lightBlue: '#22d3f7',
  purple: '#ba23f7',
  pink: '#f588d6',
  mint: '#0ae69a',
  charcoal: '#363636',
  highlight: 'yellow',

  textDarkest: '#172b4d',
  textDark: '#42526E',
  textMedium: '#5E6C84',
  textLight: '#8993a4',
  textLightest: '#a9b0bc',
  textLink: '#0052cc',

  tableHeaderFont: 'rgba(23, 43, 77, 0.54)',
  tableHeaderBackground: '#ebedf2',
  tableBorder: '#d1d3d8',
  // tableOddRowBackground: '#fcfdfe',
  tableOddRowBackground: '#f9f9f9',
  tableEvenRowBackground: '#fff',

  backgroundPrimary: '#FFFFFF',
  backgroundDarkPrimary: '#0747A6',
  backgroundMedium: '#dfe1e6',
  backgroundLight: '#ebecf0',
  backgroundLightest: '#F4F5F7',
  backgroundLightPrimary: '#D2E5FE',
  backgroundLightSuccess: '#E4FCEF',

  border: '#D6D8E0',
  borderLightest: '#dfe1e6',
  borderLight: '#C1C7D0',
  borderInputFocus: '#4c9aff',

  disabledInputBackground: '#f2f2f2',
  disabledInputText: '#999999',

  nestedSidebar: {
    background: '#fafafa',
    border: '#ebedf2',
    separator: '#ebedf2',
    link: '#42526E',
    activeBackground: '#e6e6e6',
    activeIcon: '#359bdf',
  },

  sidebar: {
    background: '#111323',
    border: '#ebedf2',
    separator: '#35363e',
    link: '#fff',
    activeBackground: '#363844',
    activeIcon: '#359bdf',
  },
  isDark: false,
};

export const darkTheme = {
  info: '#0A84FF', // blue
  success: '#34C759', // green
  danger: '#FF375F', // red
  warning: '#FFD60A', // yellow
  secondary: '#2C2C2E', // dark grey
  primary: '#0A84FF', // blue
  lightBlue: '#64D2FF',
  purple: '#BF5AF2',
  pink: '#FF2D55',
  mint: '#30D158',
  charcoal: '#8E8E93',
  highlight: '#cba528',

  textDarkest: '#FFFFFF',
  textDark: '#E5E5EA',
  textMedium: '#AEAEB2',
  textLight: '#636366',
  textLightest: '#48484A',
  textLink: '#0A84FF',

  tableHeaderFont: 'rgba(235, 235, 245, 0.6)',
  tableHeaderBackground: '#1C1C1E',
  tableBorder: '#252526',
  tableOddRowBackground: '#1C1C1E',
  tableEvenRowBackground: '#3A3A3C',

  backgroundPrimary: '#121212',
  backgroundDarkPrimary: '#0A84FF',
  backgroundMedium: '#2C2C2E',
  backgroundLight: '#3A3A3C',
  backgroundLightest: '#48484A',
  backgroundLightPrimary: '#5AC8FA',
  backgroundLightSuccess: '#32D74B',

  border: '#3A3A3C',
  borderLightest: '#2C2C2E',
  borderLight: '#48484A',
  borderInputFocus: '#0A84FF',

  disabledInputBackground: '#3A3A3C',
  disabledInputText: '#636366',

  nestedSidebar: {
    background: '#1C1C1E',
    border: '#3A3A3C',
    separator: '#3A3A3C',
    link: '#E5E5EA',
    activeBackground: '#3A3A3C',
    activeIcon: '#0A84FF',
  },

  sidebar: {
    background: '#111323',
    border: '#3A3A3C',
    separator: '#48484A',
    link: '#E5E5EA',
    activeBackground: '#2C2C2E',
    activeIcon: '#0A84FF',
  },
  isDark: true,
};

export const sizes = {
  appSidebarWidth: 240,
  collapsedAppSidebarWidth: 62.5,
  navbarHeight: 50,
  defaultFontSize: 12,
};

export const zIndexValues = {
  modal: 1000,
  dropdown: 101,
  navLeft: 100,
};

export const font = {
  regular:
    'font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Helvetica,Arial, sans-serif; font-weight: normal;',
  medium:
    'font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Helvetica,Arial, sans-serif; font-weight: 500;',
  bold:
    'font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Helvetica,Arial, sans-serif; font-weight: 600;',
  black:
    'font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Helvetica,Arial, sans-serif; font-weight: 700;',
  size: (size: number) => `font-size: ${size}px;`,
  defaultSize: `font-size: ${sizes.defaultFontSize}px;`,
};

// export const font = {
//   regular: 'font-family: SourceSansProRegular; font-weight: normal;',
//   medium: 'font-family: SourceSansProSemiBold; font-weight: normal;',
//   bold: 'font-family: SourceSansProBold; font-weight: normal;',
//   black: 'font-family: SourceSansProBlack; font-weight: normal;',
//   size: (size: number) => `font-size: ${size}px;`
// };

// export const font = {
//   regular: 'font-family: RubikRegular; font-weight: normal;',
//   medium: 'font-family: RubikMedium; font-weight: normal;',
//   bold: 'font-family: RubikBold; font-weight: normal;',
//   black: 'font-family: RubikBlack; font-weight: normal;',
//   size: (size: number) => `font-size: ${size}px;`
// };

// export const font = {
//   regular: 'font-family: NunitoRegular; font-weight: normal;',
//   medium: 'font-family: NunitoSemiBold; font-weight: normal;',
//   bold: 'font-family: NunitoBold; font-weight: normal;',
//   black: 'font-family: NunitoBlack; font-weight: normal;',
//   size: (size: number) => `font-size: ${size}px;`
// };

export const mixin = {
  darken: (colorValue: string, amount: number) =>
    Color(colorValue).darken(amount).string(),
  lighten: (colorValue: string, amount: number) =>
    Color(colorValue).lighten(amount).string(),
  rgba: (colorValue: string, opacity: number) =>
    Color(colorValue).alpha(opacity).string(),
  boxShadowMedium: css`
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.1);
  `,
  boxShadowDropdown: css`
    box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px,
      rgba(9, 30, 66, 0.31) 0px 0px 1px;
  `,
  truncateText: css`
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  `,
  clickable: css`
    cursor: pointer;
    user-select: none;
  `,
  hardwareAccelerate: css`
    transform: translateZ(0);
  `,
  cover: css`
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  `,
  placeholderColor: (colorValue: string) => css`
    ::-webkit-input-placeholder {
      color: ${colorValue} !important;
      opacity: 1 !important;
    }
    :-moz-placeholder {
      color: ${colorValue} !important;
      opacity: 1 !important;
    }
    ::-moz-placeholder {
      color: ${colorValue} !important;
      opacity: 1 !important;
    }
    :-ms-input-placeholder {
      color: ${colorValue} !important;
      opacity: 1 !important;
    }
  `,
  scrollableY: css`
    overflow-x: hidden;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
  `,
  customScrollbar: ({ width = 8 } = {}) => css`
    &::-webkit-scrollbar {
      width: ${width}px;
    }
    &::-webkit-scrollbar-track {
      background: none;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 99px;
      background: ${({ theme }) => theme.backgroundMedium};
    }
  `,
  backgroundImage: (imageURL: string) => css`
    background-image: url("${imageURL}");
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: cover;
    background-color: ${({ theme }) => theme.backgroundLight};
  `,
  link: () => css`
    cursor: pointer;
    color: ${({ theme }) => theme.textLink};
    ${font.medium}
    &:hover, &:visited, &:active {
      color: ${({ theme }) => theme.textLink};
    }
    &:hover {
      text-decoration: underline;
    }
  `,
  tag: () => css`
    display: inline-flex;
    align-items: center;
    height: 24px;
    padding: 0 8px;
    border-radius: 4px;
    cursor: pointer;
    user-select: none;
    color: ${({ theme }) => theme.textDarkest};
    background: ${({ theme }) => theme.backgroundMedium};
    ${font.bold}
    ${font.size(12)}
    i {
      margin-left: 4px;
    }
  `,
};

export const getMilestoneColor = (milestone: Milestone, isDark: boolean) => {
  if (milestone.startDate) {
    const lightColors = {
      red: { text: '#9b2c2c', background: '#ffe5e5' },
      green: { text: '#276749', background: '#d7f2e1' },
      yellow: { text: '#8a6d3b', background: '#fff9db' },
    };
    const darkColors = {
      red: { text: '#FF6188', background: '#422C38' },
      green: { text: '#A7ECCF', background: '#234E52' },
      yellow: { text: '#FFD866', background: '#4B3F2F' },
    };

    let colors = isDark ? darkColors : lightColors;
    if (milestone.id.toString().includes('pinned')) {
      return colors.green;
    }

    const daysUntilMilestone = differenceInCalendarDays(
      parseISO(milestone.startDate),
      new Date()
    );

    if (daysUntilMilestone <= 3) {
      return colors.red;
    } else {
      return colors.yellow;
    }
  }
  return null;
};
