/* eslint-disable react/display-name */
import React, {
  FunctionComponent,
  InputHTMLAttributes,
  forwardRef,
  useState,
  useEffect,
  useMemo,
} from 'react';
import styled from 'styled-components';
import FieldWrapper, { FieldWrapperProps } from '..';
import { useField } from 'formik';
import {
  FontAwesomeIcon,
  FontAwesomeIconProps,
} from '@fortawesome/react-fontawesome';
import { IconName } from '@fortawesome/pro-light-svg-icons';
import { formatDecimal } from 'shared/utils/formatting/formatters';

const StyledInputWrapper = styled.div<InputWrapperProps>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  outline: none;
  background-color: ${({ theme }) => theme.backgroundPrimary};
  border: 1px solid
    ${(props) =>
      props.borderless || props.hideBorder ? 'transparent' : props.theme.border};
  border-radius: 3px;
  text-overflow: ellipsis;
  color: ${({ theme }) => theme.textDarkest};

  &::placeholder {
    color: ${({ theme }) => theme.textMedium};
  }

  &:disabled {
    background-color: ${({ theme }) => theme.disabledInputBackground};
    color: ${({ theme }) => theme.disabledInputText};
  }

  ${(props) => props.isFocused && `border 1px solid ${props.theme.info};`}

  ${(props) =>
    props.borderless &&
    !props.isFocused &&
    `
    &:hover {
      border: 1px solid ${props.theme.border};
    }
  `}
  max-height: 30px;
  cursor: text;
`;

const StyledInput = styled.input<InputProps>`
  width: 100%;
  border: none;
  padding: ${(props) => (props.borderless || props.hideBorder ? '2px' : '7px')};
  background-color: ${({ theme }) => theme.backgroundPrimary};
  color: ${({ theme }) => theme.textDark};
`;

const StyledInputIconWrapper = styled.div<InputWrapperProps>`
  color: ${({ theme }) => theme.textLight};
  padding: ${(props) => (props.borderless || props.hideBorder ? '2px' : '7px')};
  margin-right: 2px;
`;

// TODO handle type="search".
// It currently truncates placeholder text.
export const Input = forwardRef<HTMLInputElement, InputProps>(
  (
    {
      type = 'text',
      autoComplete = 'off',
      icon,
      accessoryOnRight = false,
      className,
      style,
      value,
      mask = 'none',
      accessory,
      ...props
    },
    ref
  ) => {
    const [isFocused, setIsFocused] = useState(false);
    
    const maskedValue = useMemo(() => {
      if (mask === 'none' || value === null || value === undefined || value === '') {
        return value;
      } else if (mask === 'number') {
        if (typeof value === 'string') {
          const decimalMatch = value.match(/^(\-?\d+)(\.\d{0,2})?$/);
          if (decimalMatch) {
            const numPart = decimalMatch[1];
            const decimalPart = decimalMatch[2] || '';
            let formatted = formatDecimal(Number(numPart), null);
            
            if (decimalPart) {
              if (decimalPart === '.') {
                formatted += '.';
              } else {
                const formattedDecimal = decimalPart.length > 1 ? decimalPart : decimalPart + '0';
                formatted += formattedDecimal;
              }
            }
            return formatted;
          } else {
            return value === '-' ? '-' : '';
          }
        } else {
          return value;
        }
      }
    }, [value, mask]);

    return (
      <StyledInputWrapper
        borderless={props.borderless}
        hideBorder={props.hideBorder}
        isFocused={isFocused}
        className={className}
        style={style}
      >
        {icon && !accessoryOnRight ? (
          <StyledInputIconWrapper
            borderless={props.borderless}
            hideBorder={props.hideBorder}
          >
            <FontAwesomeIcon icon={['fal', icon]} />
          </StyledInputIconWrapper>
        ) : 
          accessory &&
          !accessoryOnRight && (
            <StyledInputIconWrapper
              borderless={props.borderless}
              hideBorder={props.hideBorder}
            >
              {accessory}
            </StyledInputIconWrapper>
          
        )}
        <StyledInput
          ref={ref}
          type={type}
          autoComplete={autoComplete}
          value={maskedValue}
          {...props}
          onChange={(event) => {
            if (props.onChange) {
              const val = event.target.value;
              if (mask == 'number') {
                const onlyDigitsDecimalsOrNegativeSign = val.replace(/[^-\\.\d]/g, '');
                if (onlyDigitsDecimalsOrNegativeSign == '-') {
                  event.target.value = onlyDigitsDecimalsOrNegativeSign;
                } else {
                  const withoutNegativeSignAtEnd = onlyDigitsDecimalsOrNegativeSign.replace(/-$/g, '');
                  event.target.value = withoutNegativeSignAtEnd;
                }
              }
              props.onChange(event);
            }
          }}
          onFocus={(event) => {
            if (props.onFocus) props.onFocus(event);
            setIsFocused(true);
          }}
          onBlur={(event) => {
            if (props.onBlur) props.onBlur(event);
            setIsFocused(false);
          }}
        />
        {icon && accessoryOnRight ? (
          <StyledInputIconWrapper
            borderless={props.borderless}
            hideBorder={props.hideBorder}
          >
            <FontAwesomeIcon icon={['fal', icon]} />
          </StyledInputIconWrapper>
        ) : (
          accessory &&
          accessoryOnRight && (
            <StyledInputIconWrapper
              borderless={props.borderless}
              hideBorder={props.hideBorder}
            >
              {accessory}
            </StyledInputIconWrapper>
          )
        )}
      </StyledInputWrapper>
    );
  }
);

interface InputWrapperProps {
  borderless?: boolean;
  hideBorder?: boolean;
  isFocused?: boolean;
  icon?: IconName;
  accessoryOnRight?: boolean;
  accessory?: React.ReactNode;
}

export interface InputProps
  extends InputHTMLAttributes<HTMLInputElement>,
    InputWrapperProps {
  mask?: 'number' | 'none';
}

export interface InputFieldProps extends InputProps, FieldWrapperProps {
  name: string;
}

export const InputField = forwardRef<HTMLInputElement, InputFieldProps>(
  (
    {
      label,
      error,
      borderless = false,
      placeholder = 'Enter a value',
      ...props
    },
    ref
  ) => {
    return (
      <FieldWrapper label={label} error={error} {...props}>
        <Input
          placeholder={placeholder}
          borderless={borderless}
          {...props}
          ref={ref}
        />
      </FieldWrapper>
    );
  }
);

export const InputFormikField: FunctionComponent<InputFieldProps> = ({
  name,
  ...props
}) => {
  const [field, meta] = useField(name);
  return (
    <InputField
      error={meta.error}
      {...props}
      {...field}
      onBlur={(e) => {
        if (props.onBlur) {
          props.onBlur(e);
        }
        field.onBlur(e);
      }}
    />
  );
};
