import styled, { css } from "styled-components";
import { font, mixin } from "shared/utils/styles";

export const Text = styled.div<{padLeft?: boolean}>`
  ${font.bold};
  padding-left: ${props => props.padLeft ? 5 : 0}px;
`

interface RequiredLabelProps {
  color: string;
  onRemove?: () => void;
}

export const StyledLabel = styled.div<RequiredLabelProps>`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  vertical-align: middle;
  // height: 32px;
  line-height: 1;
  padding: 5px 10px;
  white-space: nowrap;
  ${font.defaultSize};
  appearance: none;
  color: #fff;
  background: ${props => props.color};
  border: 1px solid ${props => props.color};
  ${font.medium}
  user-select: none;

  ${props => !props.onRemove && props.onClick ? `cursor: pointer;` : ''}

  &:hover {
    background: ${props => mixin.darken(props.color, 0.1)};
  }

  & > span {
    cursor: pointer;
  }

  & > *:not(:last-child) {
    margin-right: 5px;
  }

`