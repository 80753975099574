import React, {
  forwardRef,
  FunctionComponent,
  ReactElement,
  useContext,
  useMemo,
  useState,
} from 'react';
import {
  MainNavbarLeft,
  MainNavbarRight,
  NavbarMenuIcon,
  MainNavbarWrapper,
} from 'shared/components/navbar/Styles';
import {
  NavbarButton,
  DropdownButton,
  DropdownItem,
  Avatar,
  Button,
} from 'shared/components';
import { useHistory } from 'react-router-dom';
import { useBreadcrumbs } from 'shared/hooks';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  InteractiveDropdownItem,
  MenuSeparator,
} from 'shared/components/menu/DropdownButton';
import { useAuthModule } from 'shared/auth/msal';
import { useGetCurrentUser } from 'shared/hooks/api';
import { Input, InputProps } from 'shared/components/form/input/Input';
import styled from 'styled-components';
import { darkTheme, font, lightTheme, mixin } from 'shared/utils/styles';
import { ThemePreferenceContext } from 'index';

interface MainNavbarProps {
  isSidebarOpen: boolean;
  toggleSidebarButton: ReactElement;
  searchText: string;
  setSearchText: React.Dispatch<React.SetStateAction<string>>;
}

// eslint-disable-next-line react/display-name
export const NavbarSearchInput = forwardRef<HTMLInputElement, InputProps>(
  ({ placeholder = 'Search...', ...props }, ref) => {
    const [isFocused, setIsFocused] = useState(false);
    return (
      <Input
        ref={ref}
        {...props}
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
        placeholder={placeholder}
        type={'search'}
        style={{
          width: isFocused ? '500px' : '300px',
          transition: 'all 200ms ease-in-out',
        }}
      />
    );
  }
);

const MainNavbar: FunctionComponent<MainNavbarProps> = ({
  isSidebarOpen,
  toggleSidebarButton,
  searchText,
  setSearchText,
}) => {
  const history = useHistory();
  const { breadcrumbs } = useBreadcrumbs();
  const auth = useAuthModule();

  const { data, status, isFetching, refetch } = useGetCurrentUser();

  const userFullName = useMemo(() => {
    return data
      ? `${data.currentUser.firstName} ${data.currentUser.lastName}`
      : '';
  }, [data]);

  const userEmail = useMemo(() => {
    return data ? data.currentUser.email : '';
  }, [data]);

  const crumbs = useMemo(() => {
    return breadcrumbs.map((crumb, index, components) => {
      const breadcrumb = (
        <BreadcrumbButton
          key={crumb.key}
          onClick={() => history.push(crumb.path)}
          icon={crumb.icon}
        >
          {crumb.title.trim() ? crumb.title : ''}
        </BreadcrumbButton>
      );
      if (components.length - 1 == index) {
        return breadcrumb;
      } else {
        return (
          <React.Fragment key={crumb.key}>
            {breadcrumb}
            <span style={{ padding: '0 5px' }}>
              <FontAwesomeIcon icon={['fal', 'chevron-right']} size={'sm'} />
            </span>
          </React.Fragment>
        );
      }
    });
  }, [breadcrumbs]);

  const { theme, setTheme } = useContext(ThemePreferenceContext);
  const toggleTheme = () => {
    const newTheme = theme === lightTheme ? darkTheme : lightTheme;
    setTheme(newTheme);
    localStorage.setItem('themePreference', newTheme === lightTheme ? 'light' : 'dark');
  };

  return (
    <MainNavbarWrapper isSidebarOpen={isSidebarOpen}>
      <MainNavbarLeft>
        {!isSidebarOpen && toggleSidebarButton}
        {crumbs}
      </MainNavbarLeft>
      <MainNavbarRight>
        {/* <DropdownButton
          anchor={'right'}
          slim={true}
          variant={'simple'}
          icon={<NavbarMenuIcon icon={['fal', 'plus-circle']} />}
        >
          <DropdownItem icon={'plus'}>New Deal</DropdownItem>
          <DropdownItem icon={'plus'}>New Portfolio</DropdownItem>
          <DropdownItem icon={'file-plus'}>New Approval Package</DropdownItem>
        </DropdownButton> */}
        {/* <NavbarSearchInput
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
          icon={'search'}
          placeholder={'Search for deals, portfolios, contacts, etc...'}
        /> */}
        {/* <NavbarButton icon={'list-ul'} variant={'simple'} slim={true} /> */}
        {/* {support button} */}
        {/* <DropdownButton
          anchor={'right'}
          slim={true}
          variant={'simple'}
          icon={<NavbarMenuIcon icon={['fal', 'question-circle']} />}
        > */}
        {/* <DropdownItem icon={'book'}>
            <a
              href={
                'https://victorious-meadow-0aa1be91e.azurestaticapps.net/guide/getting-started/'
              }
              target={'_blank'}
              rel={'noreferrer'}
            >
              User Guide
            </a>
          </DropdownItem> */}
        {/* {support button} */}
        {/* <DropdownItem icon={'bug'}>
            <a href={'https://forms.office.com/Pages/ResponsePage.aspx?id=sbnKtIS4a0y9VBidvZGTJnJF8x1wXrZKnmNq4inXMEdUOEUySERMRDdDWUdVTEJVN0lEWUpaVkVNOC4u'} target={'_blank'} rel="noreferrer">
              Submit a support ticket
            </a>
          </DropdownItem> */}
        {/* <DropdownItem icon={'comment-alt-lines'}>
            Make a suggestion
          </DropdownItem> */}
        {/* </DropdownButton> */}
        <Button onClick={toggleTheme} icon={theme.isDark ? 'sun' : 'moon'}>
          {theme === lightTheme ? 'Switch to Dark Theme' : 'Switch to Light Theme'}
        </Button>
        <DropdownButton
          anchor={'right'}
          element={
            <div>
              <AvatarWrapper size={32} name={userFullName} />
            </div>
          }
        >
          <InteractiveDropdownItem>
            <CurrentUserInfo>
              <CurrentUserName>{userFullName}</CurrentUserName>
              <CurrentUserDetail>{userEmail}</CurrentUserDetail>
            </CurrentUserInfo>
          </InteractiveDropdownItem>
          {/* <DropdownItem icon={'user'}>View My Account</DropdownItem> */}
          <MenuSeparator />
          <DropdownItem
            icon={'exchange'}
            onClick={() => {
              window.location.href = '/';
            }}
          >
            Change Organizations...
          </DropdownItem>
          <DropdownItem icon={'circle-info'}>
            <a href={'https://docs.wolfftrax.com'} target={'_blank'} rel="noreferrer">
              WolffTrax Guide
            </a>
          </DropdownItem>
          <DropdownItem icon={'bug'}>
            <a href={'https://forms.office.com/Pages/ResponsePage.aspx?id=sbnKtIS4a0y9VBidvZGTJnJF8x1wXrZKnmNq4inXMEdUOEUySERMRDdDWUdVTEJVN0lEWUpaVkVNOC4u'} target={'_blank'} rel="noreferrer">
              Submit a support ticket
            </a>
          </DropdownItem>
          <DropdownItem
            icon={theme.isDark ? 'sun' : 'moon'}
            onClick={toggleTheme}
          >
            {theme === lightTheme ? 'Switch to Dark Theme' : 'Switch to Light Theme'}
          </DropdownItem>
          <DropdownItem icon={'sign-out'} onClick={auth.logout}>
            Sign out
          </DropdownItem>
        </DropdownButton>
      </MainNavbarRight>
    </MainNavbarWrapper>
  );
};

const CurrentUserInfo = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  & > *:not(:last-child) {
    margin-bottom: 5px;
  }
`;

const CurrentUserName = styled.div`
  ${font.size(14)};
  ${font.medium};
`;

const CurrentUserDetail = styled.div``;

const AvatarWrapper = styled(Avatar)`
  ${mixin.clickable};
`;

const BreadcrumbButton = ({ ...props }) => {
  return <Button slim={true} variant={'simple'} {...props} />;
};

export default MainNavbar;
