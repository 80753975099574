import React, { FunctionComponent, useCallback } from 'react';
import {
  FormFieldWrapper,
  PageDetails,
  FormModal,
  SelectFormikField,
  Alert,
  InputFormikField,
  HorizontalSeparator,
} from 'shared/components';
import * as Yup from 'yup';
import { useArchiveApprovalPackage } from 'shared/hooks/api';
import { ApprovalPackageStatusEnum } from 'shared/hooks/api/graphql/generated';
import { useNotification } from 'shared/components/notifications';

type Option = {
  value: ApprovalPackageStatusEnum;
  label: string;
};

interface ArchiveApprovalPackageModalProps {
  approvalPackageId: number;
  onClose: () => void;
}

export const approvalPackageStatusOptions = [
  { value: ApprovalPackageStatusEnum.Approved, label: 'Approved' },
  { value: ApprovalPackageStatusEnum.Rejected, label: 'Rejected' },
];

export function oneOfEnum<T>(enumObject: { [s: string]: T } | ArrayLike<T>) { return Yup.mixed<T>().oneOf(Object.values(enumObject)); }

const validationSchema: Yup.ObjectSchema<ArchiveApprovalPackageFormValues> = Yup.object()
  .shape({
    status: Yup.object()
      .shape({
        value: oneOfEnum(ApprovalPackageStatusEnum).required(),
        label: Yup.string().required(),
      })
      .required('Required')
      .label('Status'),
     confirmation: Yup.string().oneOf(["yes", "Yes", "YES"]).required('Required'),
  })
  .required();

type ArchiveApprovalPackageFormValues = {
  status: Option;
  confirmation: string;
};

const ArchiveApprovalPackageModal: FunctionComponent<ArchiveApprovalPackageModalProps> = ({
  approvalPackageId,
  onClose,
}) => {
  const notify = useNotification();
  const [
    archiveApprovalPackage,
    { status: archiveApprovalPackageStatus },
  ] = useArchiveApprovalPackage(approvalPackageId);

  const onArchiveApprovalPackage = useCallback(
    async (id: number, status: ApprovalPackageStatusEnum) => {
      try {
        const result = await archiveApprovalPackage({ id, status });
        if (result.errors) {
          notify({
            variant: 'danger',
            title: 'Uh oh!',
            message:
              'An error occurred while archiving the approval package...',
          });
        } else {
          onClose();
          notify({
            message: 'Review Status Updated!',
          });
        }
      } catch {
        notify({
          variant: 'danger',
          title: 'Uh oh!',
          message:
            'An error occurred while archiving the approval package...',
        });
      }
    },
    [onClose]
  );

  return (
    <FormModal<ArchiveApprovalPackageFormValues>
      title={'Archive Approval Package'}
      submitTitle={'Save'}
      initialValues={{}}
      handleSubmit={(form) => {
        onArchiveApprovalPackage(approvalPackageId, form.status.value);
      }}
      isWorking={archiveApprovalPackageStatus == 'loading'}
      onClose={onClose}
      validationSchema={validationSchema}
      size={'sm'}
      // disabled={(form) => form.values.confirmation?.toLowerCase() !== 'yes'}
    >
      {() => (
        <PageDetails>
          <FormFieldWrapper>
            <SelectFormikField
              name={'status'}
              label={'Select a status to archive this approval package with:'}
              options={approvalPackageStatusOptions}
            />
          </FormFieldWrapper>
          <HorizontalSeparator margin={20}/>
          <FormFieldWrapper>
            <Alert
              message={'Archiving this approval package will make it read-only and it cannot be edited again. Are you sure you want to do this?'}
              variant={'warning'}
              style={{ marginBottom: 20 }}
            />
          </FormFieldWrapper>
          <FormFieldWrapper>
            <InputFormikField
              name={'confirmation'}
              label={'Please type "yes" to confirm:'}
            />
          </FormFieldWrapper>
        </PageDetails>
      )}
    </FormModal>
  );
};

export default ArchiveApprovalPackageModal;
