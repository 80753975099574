import React, { FunctionComponent } from 'react';
import Tippy, { TippyProps } from '@tippyjs/react';
import styled from 'styled-components';
import 'tippy.js/dist/tippy.css';
import 'tippy.js/animations/shift-away.css';
import { font } from 'shared/utils/styles';

const DarkThemedTooltip = styled(Tippy)`
  background-color: #2c2e3e;
  color: #fff;
  ${font.defaultSize};
  padding: 2px;
  border-radius: 4px;

  & > .tippy-arrow::before {
    fill: #2c2e3e;
  }
`;

const LightThemedTooltip = styled(Tippy)`
  background-color: #fff;
  color: ${({ theme }) => theme.textDark};
  ${font.defaultSize};
  padding: 2px;
  border-radius: 4px;

  box-shadow: 0 0 0 1px rgba(111, 119, 130, 0.15),
    0 5px 20px 0 rgba(21, 27, 38, 0.08);

  & > .tippy-arrow::before {
    fill: #fff;
  }
`;

interface TooltipProps extends TippyProps {
  light?: boolean;
}

const Tooltip: FunctionComponent<TooltipProps> = ({
  children,
  content,
  placement = 'top',
  light = false,
  ...props
}) => {
  if (light) {
    return (
      <LightThemedTooltip
        content={content}
        arrow={false}
        animation="shift-away"
        placement={placement}
        {...props}
      >
        {children}
      </LightThemedTooltip>
    );
  } else {
    return (
      <DarkThemedTooltip
        content={content}
        arrow={true}
        animation="shift-away"
        placement={placement}
        {...props}
      >
        {children}
      </DarkThemedTooltip>
    );
  }
};

export default Tooltip;
