import React, { FunctionComponent, useContext, useEffect, useMemo, useState } from 'react';
import {
  Modal,
  Section,
  PageDetails,
  Button,
  DropdownButton,
  DropdownItem,
  Avatar,
  FormModal,
  Row,
  Column,
  InputFormikField,
  TextareaFormikField,
  Dialog,
} from 'shared/components';
import styled, { ThemeContext } from 'styled-components';
import { font } from 'shared/utils/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AvatarProps } from 'shared/components/avatar/Avatar';
import format from 'date-fns/format';
import * as Yup from 'yup';
import {
  UpdateKeyRiskInput,
  CreateKeyRiskInput,
} from 'shared/hooks/api/graphql/generated';
import { diff } from 'shared/utils/deepDiff';
import {
  useUpdateKeyRisk,
  useDeleteKeyRisk,
  useCreateKeyRisk,
} from 'shared/hooks/api';
import { useNotification } from 'shared/components/notifications';
import NoAccess from 'shared/components/noAccess';
import { sortBy } from 'shared/utils/sorting';
import ReorderModal from './ReorderModal';

export type KeyRisk = {
  id: number;
  title: string;
  riskDescription?: string;
  mitigantDescription?: string;
  updatedAt: Date;
  updatedBy: string;
  orderId?: number;
};

interface KeyRisksModalProps {
  onClose: () => void;
  onSuccess: () => void;
  oppId: number;
  keyRisks: KeyRisk[];
  currentUserCanEdit: boolean;
}

const KeyRisksModal: FunctionComponent<KeyRisksModalProps> = ({
  onClose,
  onSuccess,
  keyRisks,
  oppId,
  currentUserCanEdit,
}) => {
  const themeContext = useContext(ThemeContext);
  const risksContent = useMemo(() => {
    return keyRisks.sort(sortBy((x) => x.orderId)).map((risk) => (
      <KeyRisk key={risk.id} risk={risk} onSuccess={onSuccess} />
    ));
  }, [keyRisks]);

  const [
    isShowingCreateKeyRiskModal,
    setIsShowingCreateKeyRiskModal,
  ] = useState(false);

  const [
    isShowingReorderModal,
    setIsShowingReorderModal,
  ] = useState(false);

  return (
    <Modal
      title="Key Risks"
      isOpen={true}
      onClose={onClose}
      size={'lg'}
      tools={[
        <Button
          key={'new-key-risk'}
          variant={'outline'}
          icon={'plus'}
          onClick={() => setIsShowingCreateKeyRiskModal(true)}
          disabled={!currentUserCanEdit}
        >
          New Key Risk
        </Button>,
        <Button
          key={'reorder-key-risks'}
          variant={'outline'}
          icon={'grip-vertical'}
          onClick={() => setIsShowingReorderModal(true)}
          disabled={!currentUserCanEdit}
        >
          Reorder Key Risks
        </Button>
      ]}
    >
      <PageDetails>
        {risksContent.length != 0 ? (
          risksContent
        ) : (
          <NoAccess
            title={'No Key Risks'}
            subtitle={'Any deal risks added will be viewable here'}
            icon={'exclamation-triangle'}
            iconColor={themeContext.danger}
          />
        )}
      </PageDetails>
      {isShowingCreateKeyRiskModal && (
        <CreateKeyRiskModal
          onClose={() => setIsShowingCreateKeyRiskModal(false)}
          onSuccess={onSuccess}
          oppId={oppId}
        />
      )}
      {isShowingReorderModal && (
        <ReorderModal
          onClose={() => setIsShowingReorderModal(false)}
          content={keyRisks}
          title={"Reorder Key Risks"}
          contentType={'key-risks'}
          oppId={oppId}
        />
      )}
    </Modal>
  );
};

export default KeyRisksModal;

interface KeyRiskProps {
  risk: KeyRisk;
  onSuccess: () => void;
}

const KeyRisk: FunctionComponent<KeyRiskProps> = ({ risk, onSuccess }) => {
  const themeContext = useContext(ThemeContext);
  const [isShowingEditModal, setIsShowingEditModal] = useState(false);
  const [isShowingDeleteDialog, setIsShowingDeleteDialog] = useState(false);
  const [deleteKeyRisk, { status: deleteKeyRiskStatus }] = useDeleteKeyRisk();
  const notify = useNotification();
  return (
    <React.Fragment>
      <Section
        icon={
          <FontAwesomeIcon
            icon={['fal', 'exclamation-circle']}
            color={themeContext.danger}
          />
        }
        title={risk.title}
        right={[
          <AuthorAvatar
            key={'risk-author'}
            name={risk.updatedBy}
            size={25}
            lastUpdated={risk.updatedAt}
          />,
          <DropdownButton
            icon={'chevron-down'}
            key={'edit-menu'}
            slim={true}
            variant={'simple'}
            anchor={'right'}
          >
            <DropdownItem
              icon={<FontAwesomeIcon icon={['fal', 'pencil']} />}
              onClick={() => setIsShowingEditModal(true)}
            >
              Edit
            </DropdownItem>
            <DropdownItem
              icon={
                <FontAwesomeIcon icon={['fal', 'trash']} color={themeContext.danger} />
              }
              onClick={() => setIsShowingDeleteDialog(true)}
            >
              Delete
            </DropdownItem>
          </DropdownButton>,
        ]}
      >
        <KeyRiskBodyWrapper>
          <KeyRiskBody>
            <KeyRiskBodyTitle>{`Risk`}</KeyRiskBodyTitle>
            {risk.riskDescription}
          </KeyRiskBody>
          <KeyRiskBody>
            <KeyRiskBodyTitle>{`Mitigant`}</KeyRiskBodyTitle>
            {risk.mitigantDescription}
          </KeyRiskBody>
        </KeyRiskBodyWrapper>
      </Section>
      {isShowingEditModal && (
        <EditKeyRiskModal
          onClose={() => setIsShowingEditModal(false)}
          onSuccess={onSuccess}
          risk={risk}
        />
      )}
      {isShowingDeleteDialog && (
        <Dialog
          variant={'danger'}
          isOpen={true}
          title={'Delete Key Risk'}
          confirmTitle={'Yes, Delete'}
          message={`This will delete the key risk titled "${risk.title}". Are you sure you want to do this?`}
          isWorking={deleteKeyRiskStatus == 'loading'}
          onConfirm={async () => {
            try {
              await deleteKeyRisk(risk.id);
              setIsShowingDeleteDialog(false);
              notify({
                message: 'Key risk successfully deleted!',
              });
              onSuccess();
            } catch {
              notify({
                duration: 5000,
                variant: 'danger',
                message: 'An error occcurred while deleting this key risk.',
              });
            }
          }}
          onClose={() => setIsShowingDeleteDialog(false)}
          onCancel={() => setIsShowingDeleteDialog(false)}
        />
      )}
    </React.Fragment>
  );
};

interface EditKeyRiskModalProps {
  onClose: () => void;
  onSuccess: () => void;
  risk: KeyRisk;
}

const validationSchema = Yup.object()
  .shape({
    title: Yup.string()
      .min(3, 'Too Short!')
      .max(50, 'Too Long!')
      .required('Required')
      .label("Title"),
    riskDescription: Yup.string().required('Required').label("Risk"),
    mitigantDescription: Yup.string().required('Required').label("Mitigant"),
  })
  .required();

export type KeyRiskFormValues = {
  title: string;
  riskDescription: string;
  mitigantDescription: string;
};

const EditKeyRiskModal: FunctionComponent<EditKeyRiskModalProps> = ({
  onClose,
  onSuccess,
  risk,
}) => {
  const initialValues = useMemo(() => {
    return {
      title: risk.title,
      riskDescription: risk.riskDescription,
      mitigantDescription: risk.mitigantDescription,
    };
  }, [risk]);

  const [updateKeyRisk, { status }] = useUpdateKeyRisk();
  const notify = useNotification();

  return (
    <FormModal<KeyRiskFormValues>
      initialValues={initialValues}
      title={'Edit Key Risk'}
      submitTitle={'Update'}
      onClose={onClose}
      isWorking={status == 'loading'}
      validationSchema={validationSchema}
      size={'lg'}
      handleSubmit={async (form, resetForm) => {
        const changes = diff(initialValues, form);
        const input: UpdateKeyRiskInput = {
          id: risk.id,
          title: changes.title,
          riskDescription: changes.riskDescription,
          mitigantDescription: changes.mitigantDescription,
        };
        try {
          await updateKeyRisk(input);
          notify({
            duration: 3000,
            title: 'Success!',
            message: 'Key Risk Updated!',
            variant: 'success',
          });
          onClose();
          onSuccess();
          resetForm();
        } catch (error) {
          notify({
            duration: 5000,
            title: 'Uh-oh!',
            message: `An unknown error occurred while updating this key risk! ${error}`,
            variant: 'danger',
          });
        }
      }}
    >
      {(props) => (
        <PageDetails>
          <KeyRiskForm />
        </PageDetails>
      )}
    </FormModal>
  );
};

interface CreateKeyRiskModalProps {
  onClose: () => void;
  onSuccess: () => void;
  oppId: number;
}

const CreateKeyRiskModal: FunctionComponent<CreateKeyRiskModalProps> = ({
  onClose,
  onSuccess,
  oppId,
}) => {
  const [createKeyRisk, { status }] = useCreateKeyRisk(oppId);
  const notify = useNotification();

  return (
    <FormModal<KeyRiskFormValues>
      initialValues={{}}
      title={'New Key Risk'}
      submitTitle={'Create Key Risk'}
      onClose={onClose}
      isWorking={status == 'loading'}
      validationSchema={validationSchema}
      size={'lg'}
      handleSubmit={async (form, resetForm) => {
        const input: CreateKeyRiskInput = {
          fkDeal: oppId,
          title: form.title,
          riskDescription: form.riskDescription,
          mitigantDescription: form.mitigantDescription,
        };
        try {
          await createKeyRisk(input);
          notify({
            duration: 3000,
            title: 'Success!',
            message: 'Key Risk Created!',
            variant: 'success',
          });
          onClose();
          onSuccess();
          resetForm();
        } catch (error) {
          notify({
            duration: 5000,
            title: 'Uh-oh!',
            message: `An unknown error occurred while creating this key risk! ${error}`,
            variant: 'danger',
          });
        }
      }}
    >
      {(props) => (
        <PageDetails>
          <KeyRiskForm />
        </PageDetails>
      )}
    </FormModal>
  );
};

const KeyRiskForm = () => {
  return (
    <React.Fragment>
      <Row>
        <Column>
          <InputFormikField
            name={'title'}
            label={'Title'}
            placeholder={`Enter a title...`}
          />
        </Column>
        <Column span={6}>
          <TextareaFormikField
            name={'riskDescription'}
            label={'Risk Description'}
            placeholder={`Enter a description here...`}
            minRows={10}
          />
        </Column>
        <Column span={6}>
          <TextareaFormikField
            name={'mitigantDescription'}
            label={'Mitigant'}
            placeholder={'Enter a mitigant here...'}
            minRows={10}
          />
        </Column>
      </Row>
    </React.Fragment>
  );
};

export const AuthorAvatarWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  & > * {
    margin-right: 5px;
  }

  & > :last-child {
    margin-right: 0;
  }
`;

interface AuthorAvatarProps extends AvatarProps {
  lastUpdated: Date;
}

export const AuthorAvatar: FunctionComponent<AuthorAvatarProps> = ({
  name,
  lastUpdated,
  ...rest
}) => {
  return (
    <AuthorAvatarWrapper>
      <Avatar name={name} {...rest} />
      <div style={{ paddingRight: '5px' }}>{name}</div>
      <div>{format(lastUpdated, 'MM/dd/yyyy h:mm a')}</div>
    </AuthorAvatarWrapper>
  );
};

const KeyRiskBodyWrapper = styled.div`
  display: flex;
  flex-direction: row;
  white-space: pre-wrap;
`;

const KeyRiskBody = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  ${font.size(12)};
  padding: 0 10px;
`;

const KeyRiskBodyTitle = styled.div`
  ${font.medium};
  color: ${({ theme }) => theme.textDarkest};
  margin-bottom: 10px;
`;
