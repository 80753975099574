import React, { FunctionComponent, useState, useMemo, Suspense } from 'react';
import {
  Route,
  useRouteMatch,
  Switch,
  useParams,
} from 'react-router-dom';
import Redirect from 'shared/components/Redirect';
import { useBreadcrumb, useWindowSize } from 'shared/hooks';
import { useGetCurrentUser } from 'shared/hooks/api';
import styled from 'styled-components';
import { font } from 'shared/utils/styles';
import { Button, CenteredSpinner, Debug, PageWrapper } from 'shared/components';
import { useAuthModule } from 'shared/auth/msal';
import { SidebarContext } from 'shared/utils/sidebar/sidebar';
import Navbar from './Navbar';
import HomeSidebar from './Sidebar';
import EmployeeSuccess from './EmployeeSuccess';
import ContactManagement from './ContactManagement';
import Contacts from './ContactManagement/AddressBook';

const Reports = React.lazy(() => import(/* webpackChunkName: "reports" */ './Reports'))
const Report = React.lazy(() => import(/* webpackChunkName: "report" */ './Report'))
const Approvals = React.lazy(() => import(/* webpackChunkName: "approvals" */ './Approvals'))
const Settings = React.lazy(() => import(/* webpackChunkName: "settings" */ './Settings'))
const Overview = React.lazy(() => import(/* webpackChunkName: "overview" */ './Overview'))
const Deal = React.lazy(() => import(/* webpackChunkName: "deal" */ './Deal/index2'))
const Portfolio = React.lazy(() => import(/* webpackChunkName: "portfolio" */ './Portfolio'))
const ApprovalPackage = React.lazy(() => import(/* webpackChunkName: "approvalPackage" */ './Approvals/ApprovalPackage'))
const ApprovalPackageTemplate = React.lazy(() => import(/* webpackChunkName: "approvalPackageTemplate" */ './Approvals/ApprovalPackageTemplate'))

const Organization: FunctionComponent<any> = () => {
  const match = useRouteMatch();
  const { orgName } = useParams<{ orgName: string }>();
  const { data, status, isFetching, refetch } = useGetCurrentUser();

  const org = useMemo(() => {
    const org = data?.currentUser.organizations?.find(
      (x) => x.organizationPath == orgName
    );
    return org;
  }, [data]);

  const userHasAccess = useMemo(() => org != undefined, [org]);

  useBreadcrumb(
    {
      title: ' ',
      path: match.url,
      key: 'orgName',
      icon: 'home',
      index: 0,
    },
    [orgName]
  );

  const [searchText, setSearchText] = useState('');

  const [isMainSidebarOpen, setIsMainSidebarOpen] = useState<boolean | undefined>(
    true
  );
  const [isNestedSidebarOpen, setIsNestedSidebarOpen] = useState<boolean | undefined>(
    undefined
  );

  const size = useWindowSize();

  const isMobileWidth = useMemo(() => {
    return (size.width ?? 0) <= 576;
  }, [size.width]);

  return (
    <React.Fragment>
      {!userHasAccess ? (
        <AccessDenied />
      ) : (
        <SidebarContext.Provider
      value={{
        isMainSidebarOpen: isMainSidebarOpen || false,
        isNestedSidebarOpen,
        setIsNestedSidebarOpen,
        setIsMainSidebarOpen,
      }}
    >
      <PageWrapper
        isMainSidebarOpen={isMainSidebarOpen || false}
        isNestedSidebarOpen={isNestedSidebarOpen}
      >
        <Navbar
          isSidebarOpen={isMobileWidth ? ((isNestedSidebarOpen || isMainSidebarOpen) ?? false) : (isMainSidebarOpen ?? false)}
          setIsSidebarOpen={(value) => {
            if (isMobileWidth) {
              setIsNestedSidebarOpen(value);
            }
            setIsMainSidebarOpen(value);
          }}
          searchText={searchText}
          setSearchText={setSearchText}
        />
        <HomeSidebar />
        <Suspense fallback={<CenteredSpinner/>}>
          <Switch>
            {/* <Route
              path={`${match.path}/dashboard`}
              render={() => <Dashboard />}
            /> */}
            <Route path={`/:orgName/reports`} render={() => <Reports />} />
            <Route path={`/:orgName/report/:reportId`} render={() => <Report />} />
            <Route
              path={`/:orgName/approvals/all/:filterType`}
              render={() => <Approvals />}
            />
            <Route
              path={`/:orgName/approvals/:approvalPackageId`}
              render={() => <ApprovalPackage />}
            />
            <Route
              path={`/:orgName/contact-management`}
              render={() => <ContactManagement />}
            />
            <Route
              path={`/:orgName/approval-templates/:approvalPackageTemplateId`}
              render={() => <ApprovalPackageTemplate/>}
            />
            <Route
              path={`/:orgName/employee-success`}
              render={() => <EmployeeSuccess />}
            />
            <Route path={`/:orgName/settings`} render={() => <Settings />} />
            <Route path={`/:orgName/overview`} render={() => <Overview />} />
            <Route path={`/:orgName/deals/:oppId`} render={() => <Deal />} />
            <Route path={`/:orgName/portfolios/:portfolioId`} render={() => <Portfolio />} />
            <Route path={`/:orgName/address-books/:pipelineId`} render={() => <Contacts />} />
            <Redirect to={`${match.url}/overview`}/>
          </Switch>
        </Suspense>
      </PageWrapper>
    </SidebarContext.Provider>
        
      )}
    </React.Fragment>
  );
};

const AccessDenied = () => {
  const auth = useAuthModule();
  return (
  <AccessDeniedWrapper>
    <AccessDeniedContent>
      <AccessDeniedHeader>401 - You do not have access.</AccessDeniedHeader>
      <AccessDeniedBody>Your account does not have access to this resource or it does not exist. You can view a list of the organizations you have access to by clicking the button below.</AccessDeniedBody>
      <AccessDeniedFooter>
        <Button color={'primary'}>View My Orgs</Button>
        <Button variant={'outline'} onClick={auth.logout}>Use a different account (Sign Out)</Button>
      </AccessDeniedFooter>
    </AccessDeniedContent>
  </AccessDeniedWrapper>
  )
}

const AccessDeniedWrapper = styled.div`
  display: flex;
  justify-content: center;
  height: 100%;
  width: 100%;
  padding-top: 300px;
`

const AccessDeniedContent = styled.div`
  display: flex;
  max-width: 500px;
  flex-direction: column;
  & > * {
    margin-bottom: 10px;
  }
`

const AccessDeniedHeader = styled.h1`
  ${font.size(25)};
  ${font.bold};
  color: ${({ theme }) => theme.textDarkest};
`

const AccessDeniedBody = styled.p`
  ${font.size(14)};
  ${font.regular};
  color: ${({ theme }) => theme.textMedium};
  margin-bottom: 30px;
`

const AccessDeniedFooter = styled.p`
  display: flex;
  flex-direction: row;
  & > * {
    margin-right: 10px;
  }
`

export default Organization;
