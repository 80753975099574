import React, { FunctionComponent, useCallback, useMemo, useState } from 'react';
import {
  closestCorners,
  DndContext,
  DraggableSyntheticListeners,
} from '@dnd-kit/core';
import {
  rectSortingStrategy,
  SortableContext,
  useSortable,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { Button, Debug, PageDetails, SideMenu } from 'shared/components';
import { sortBy } from 'shared/utils/sorting';
import { SectionContent } from '../../../Approvals/ApprovalPackage/components/Section/types';

import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import styled from 'styled-components';
import { font } from 'shared/utils/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useUpdateDealKeyRisk, useUpdateDealDealMerit } from 'shared/hooks/api';
import { useNotification } from 'shared/components/notifications';
import { KeyRisk } from './KeyRisksModal';

interface ReorderModalProps {
  title: string;
  content: KeyRisk[];
  contentType?: string;
  onClose: () => void;
  oppId: number;
}

const ReorderModal: FunctionComponent<ReorderModalProps> = ({
  title,
  content,
  contentType,
  onClose,
  oppId,
}) => {
  const [activeId, setActiveId] = useState<string | null>(null);

  const sortedContent = useMemo(() => {
    return content.sort(sortBy((x) => x.orderId));
  }, [content]);

  const orderedSectionContentIds = useMemo(() => {
    return sortedContent.map((x) => x.id.toString());
  }, [sortedContent]);

  const getIndex = orderedSectionContentIds.indexOf.bind(
    orderedSectionContentIds
  );

  const activeIndex = activeId ? getIndex(activeId) : -1;

  const [updateDealKeyRisk, { status }] = useUpdateDealKeyRisk(oppId);
  const [updateDealMerit] = useUpdateDealDealMerit(oppId);

  const notify = useNotification();

  const onUpdateItem = useCallback(
    async (id: number, orderId: number) => {
      try {
        if (contentType == 'key-risks') {
          const result = await updateDealKeyRisk({
            id,
            orderId,
          });

          if (result.errors) {
            notify({
              variant: 'danger',
              message: 'An error occurred while updating the key risk...',
            });
          }
        } else if (contentType == 'deal-merits') {
          const result = await updateDealMerit({
            id,
            orderId,
          });

          if (result.errors) {
            notify({
              variant: 'danger',
              message: 'An error occurred while updating the deal merit...',
            });
          }
        }
      }
      catch {
        notify({
          variant: 'danger',
          message: 'An error occurred while updating the items...',
        });
      }
    },
    [content]
  );

  return (
    <SideMenu
      small={true}
      toggleSideMenu={onClose}
      title={title}
      subtitle={'Re-order the items below by dragging them'}
      isOpen={true}
    >
      <PageDetails>
        <DndContext
          collisionDetection={closestCorners}
          onDragStart={({ active }) => {
            if (!active) {
              return;
            }
            setActiveId(active.id);
          }}
          onDragEnd={({ over, delta, active }) => {
            const activeContentIndex = getIndex(active.id);
            const activeContent = sortedContent[activeContentIndex];
            setActiveId(null);
            if (over) {
              const overIndex = getIndex(over.id);
              let newOrderId = 0;
              if (activeIndex !== overIndex) {
                const overItem = sortedContent[overIndex];
                const direction = delta.y > 0 ? 'down' : 'up';
                let aboveRow: KeyRisk | undefined;
                let belowRow: KeyRisk | undefined;
                if (direction == 'up') {
                  belowRow = overItem;
                  aboveRow = sortedContent[overIndex - 1];
                } else {
                  aboveRow = overItem;
                  belowRow = sortedContent[overIndex + 1];
                }
                if (aboveRow && belowRow) {
                  belowRow.orderId = belowRow.orderId || 0;
                  aboveRow.orderId = aboveRow.orderId || 0;
                  const rank =
                    (belowRow.orderId - aboveRow.orderId) / 2 + aboveRow.orderId;
                  newOrderId = rank;
                } else if (aboveRow && !belowRow) {
                  aboveRow.orderId = aboveRow.orderId || 0;
                  newOrderId = aboveRow.orderId + 10000;
                } else if (belowRow && !aboveRow) {
                  belowRow.orderId = belowRow.orderId || 0;
                  newOrderId = belowRow.orderId - 10000;
                } else {
                  return;
                }

                onUpdateItem(activeContent.id, Math.round(newOrderId));
              }
            }
          }}
          onDragCancel={() => setActiveId(null)}
          modifiers={[restrictToVerticalAxis]}
        >
          <SortableContext
            items={orderedSectionContentIds}
            strategy={rectSortingStrategy}
          >
            {sortedContent.map((c: any) => {
              return (<SortableSectionContent key={c.id} content={c} />)
            }
            )}
          </SortableContext>
        </DndContext>
        {/* <Debug state={sortedContent}/>  */}
      </PageDetails>
    </SideMenu>
  );
};

interface SortableSectionContentProps {
  content: SectionContent;
}

const SortableSectionContent: FunctionComponent<SortableSectionContentProps> = ({
  content,
}) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
  } = useSortable({ id: content.id.toString() });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition: transition || undefined,
  };

  return (
    <SortableSectionContentContainer
      ref={setNodeRef}
      style={style}
      {...attributes}
      {...listeners}
    >
      <SubSectionHandle listeners={listeners} />
      <SortableSectionContentTitle>
        {content.title || 'No Title'}
      </SortableSectionContentTitle>
    </SortableSectionContentContainer>
  );
};

interface SubSectionHandleProps {
  listeners?: DraggableSyntheticListeners;
}

const SubSectionHandle: FunctionComponent<SubSectionHandleProps> = ({
  listeners,
}) => {
  return (
    <span style={{ cursor: 'grab', padding: '5px' }} {...listeners}>
      <FontAwesomeIcon icon={['fal', 'grip-vertical']} />
    </span>
  );
};

const SortableSectionContentContainer = styled.div`
  display: flex;
  align-items: center;
  border: 1px solid ${({ theme }) => theme.border};
  border-radius: 5px;
  padding: 5px;
  padding-left: 10px;
  margin: 10px 0;

  & > *:not(:last-child) {
    margin-right: 10px;
  }
`;

const SortableSectionContentTitle = styled.div`
  ${font.bold};
`;

export default ReorderModal;
