import React, { FunctionComponent, useContext } from 'react';
import { Button, Tooltip } from 'shared/components';
import {
  Editor,
  Text,
  Node,
  Range,
  Point,
  Transforms,
  Element as SlateElement,
} from 'slate';
import { RenderElementProps, RenderLeafProps, useSlate } from 'slate-react';
import escapeHtml from 'escape-html';
import isUrl from 'is-url';
import { IconName } from '@fortawesome/pro-light-svg-icons';
import { DefaultTheme, ThemeContext } from 'styled-components';

type CustomElement = 
  | { type: 'paragraph'; children: CustomText[] }
  | { type: 'link'; url: string; children: CustomText[] }
  | { type: 'bulleted-list'; children: CustomElement[] }
  | { type: 'numbered-list'; children: CustomElement[] }
  | { type: 'list-item'; children: (CustomText | CustomElement)[]; level?: number }
  | { type: 'list-item-text'; children: CustomText[] };

type CustomText = { text: string } & Markings;

type Markings = {
  highlight?: boolean;
  bold?: boolean;
  italic?: boolean;
  underline?: boolean;
  strikethrough?: boolean;
};

type LinkElement = { type: 'link'; url: string; children: CustomText[] };

export const defaultEditorValue: CustomElement[] = [
  {
    type: 'paragraph',
    children: [{ text: '' }],
  },
];

interface EditorButtonProps {
  icon: IconName;
  format: keyof Markings | 'bulleted-list';
}

const LIST_TYPES = ['numbered-list', 'bulleted-list'];

const toggleFormat = (editor: Editor, format: keyof Markings | 'bulleted-list') => {
  if (LIST_TYPES.includes(format)) {
    toggleBlock(editor, format);
  } else {
    toggleMark(editor, format as keyof Markings);
  }
};

const isActiveFormat = (editor: Editor, format: keyof Markings | 'bulleted-list') => {
  return LIST_TYPES.includes(format) ? isBlockActive(editor, format) : isMarkActive(editor, format as keyof Markings);
};

export const EditorButton: FunctionComponent<EditorButtonProps> = ({ icon, format }) => {
  const editor = useSlate();
  const handleMouseDown = (e: React.MouseEvent) => {
    e.preventDefault();
    toggleFormat(editor, format);
  };

  const isActive = isActiveFormat(editor, format);

  return (
    <Button variant={'simple'} icon={icon} isActive={isActive} onMouseDown={handleMouseDown} />
  );
};

export const LinkButton: FunctionComponent = () => {
  const editor = useSlate();
  return (
    <Button
      variant={'simple'}
      icon={'link'}
      isActive={isLinkActive(editor)}
      onMouseDown={(event: React.MouseEvent) => {
        event.preventDefault();
        const url = window.prompt('Enter the URL of the link:');
        if (!url) return;
        insertLink(editor, url);
      }}
    />
  );
};

export const insertLink = (editor: Editor, url: string) => {
  if (!editor.selection) {
    Transforms.select(editor, Editor.end(editor, []));
  }
  wrapLink(editor, url);
};

const isBlockActive = (editor: Editor, format: string) => {
  const { selection } = editor;
  if (!selection) return false;

  const [match] = Editor.nodes(editor, {
    match: n => SlateElement.isElement(n) && n.type === format,
  });

  return !!match;
};

const toggleBlock = (editor: Editor, format: string) => {
  const isActive = isBlockActive(editor, format);
  const isList = LIST_TYPES.includes(format);

  Transforms.unwrapNodes(editor, {
    match: n => SlateElement.isElement(n) && LIST_TYPES.includes(n.type),
    split: true,
  });

  const newProperties: Partial<SlateElement> = {
    type: isActive ? 'paragraph' : isList ? 'list-item' : (format as CustomElement['type']),
    level: isActive ? undefined : 0,
  };
  Transforms.setNodes(editor, newProperties);

  if (!isActive && isList) {
    const block: CustomElement = { type: format as 'bulleted-list' | 'numbered-list', children: [] };
    Transforms.wrapNodes(editor, block);
  }
};

const isMarkActive = (editor: Editor, format: keyof Markings) => {
  const marks = Editor.marks(editor);
  return marks ? marks[format] !== undefined : false;
};

const toggleMark = (
  editor: Editor,
  format: keyof Markings
) => {
  const isActive = isMarkActive(editor, format);

  if (isActive) {
    Editor.removeMark(editor, format);
  } else {
    Editor.addMark(editor, format, true);
  }
};

const BULLET_STYLES = [
  'disc',
  'circle',
  'square',
];

export const renderElement = ({ attributes, children, element }: RenderElementProps) => {
  switch (element.type) {
    case 'bulleted-list':
      return <ul {...attributes}>{children}</ul>;
    case 'numbered-list':
      return <ol {...attributes}>{children}</ol>;
    case 'list-item':
      const bulletStyle = BULLET_STYLES[(element.level ?? 0) % BULLET_STYLES.length] || 'disc';
      return (
        <li {...attributes} style={{ marginLeft: `${(element.level ?? 0) * 20}px`, listStyleType: bulletStyle }}>
          {children}
        </li>
      );
    case 'list-item-text':
      return <span {...attributes}>{children}</span>;
    case 'paragraph':
      return <p {...attributes}>{children}</p>;
    case 'link':
      return (
        <Tooltip content={element.url}>
          <a {...attributes} href={element.url} target="_blank" rel="noreferrer">
            {children}
          </a>
        </Tooltip>
      );
    default:
      return <p {...attributes}>{children}</p>;
  }
};

export const renderLeaf = ({ attributes, children, leaf }: RenderLeafProps) => {
  const themeContext = useContext(ThemeContext);

  if (leaf.bold) {
    children = <strong>{children}</strong>;
  }

  if (leaf.italic) {
    children = <em>{children}</em>;
  }

  if (leaf.underline) {
    children = <u>{children}</u>;
  }

  if (leaf.strikethrough) {
    children = <s>{children}</s>;
  }

  if (leaf.highlight) {
    children = <span style={{ backgroundColor: themeContext.highlight }}>{children}</span>;
  }

  return <span {...attributes}>{children}</span>;
};

export const serializeSlateDataToHtml = (value: CustomElement[], themeContext: DefaultTheme) => {
  const node = {
    children: value,
  };
  return serializeHtmlNode(node as Node, false, false, themeContext);
};

const serializeHtmlNode = (node: Node, isLastChild: boolean, isInline: boolean, themeContext: DefaultTheme): string => {
  if (Text.isText(node)) {
    return serializeHtmlLeaf(node, isLastChild, isInline, themeContext);
  } else if (SlateElement.isElement(node)) {
    const children = node.children.map((n, index) => serializeHtmlNode(n, index === node.children.length - 1, node.type === 'link', themeContext)).join('');
    switch (node.type) {
      case 'paragraph':
        return `<div><p>${children}</p></div>`;
      case 'link':
        return `<span><a href="${node.url}" target="_blank" rel="noreferrer">${children}</a></span>`;
      case 'bulleted-list':
        return `<ul>${children}</ul>`;
      case 'list-item':
        const bulletStyle = BULLET_STYLES[(node.level ?? 0) % BULLET_STYLES.length] || 'disc';
        const marginLeft = `${(node.level ?? 0) * 20}px`;
        return `<li style="margin-left: ${marginLeft}; list-style-type: ${bulletStyle};">${children}</li>`;
      default:
        return children;
    }
  } else {
    return '';
  }
};

const serializeHtmlLeaf = (leaf: Text, isLastChild: boolean, isInline: boolean, themeContext: DefaultTheme) => {
  let element = '';
  if (leaf.text == '') {
    if (isLastChild && !isInline) {
      element = wrapWithTag('span', '<br/>');
    } else {
      element = wrapWithTag('span', '');
    }
  } else {
    element = wrapWithTag('span', escapeHtml(leaf.text));
  }
  if ('bold' in leaf && leaf.bold) {
    element = wrapWithTag('b', element);
  }

  if ('italic' in leaf && leaf.italic) {
    element = wrapWithTag('em', element);
  }

  if ('underline' in leaf && leaf.underline) {
    element = wrapWithTag('u', element);
  }

  if ('strikethrough' in leaf && leaf.strikethrough) {
    element = wrapWithTag('s', element);
  }
  if ('highlight' in leaf && leaf.highlight) {
    element = wrapWithTag('span', element, { style: `background-color: ${themeContext.highlight}` });
  }
  return element;
};

const wrapWithTag = (tag: string, value: string, attributes?: { [key: string]: string }) => {
  const attrs = attributes ? Object.entries(attributes).map(([key, val]) => `${key}="${val}"`).join(' ') : '';
  return `<${tag} ${attrs}>${value}</${tag}>`;
};

export const withValidSelection = (value: CustomElement[], editor: Editor) => {
  const { selection } = editor;
  const root = { children: value };
  if (selection) {
    const endPoint = Range.end(selection);
    if (Node.has(root, endPoint.path)) {
      const lastNodeInSelection = Node.leaf(root, endPoint.path);
      const lastPointInValue = {
        path: endPoint.path,
        offset: (lastNodeInSelection as Text).text.length,
      };
      if (Point.isBefore(lastPointInValue, endPoint)) {
        Transforms.select(editor, lastPointInValue);
      }
    } else {
      Transforms.deselect(editor);
    }
  }
  return editor;
};

export const withLinks = (editor: Editor) => {
  const { insertData, insertText, isInline } = editor;

  editor.isInline = (element: SlateElement) => {
    return element.type === 'link' ? true : isInline(element);
  };

  editor.insertText = (text: string) => {
    if (text && isUrl(text)) {
      wrapLink(editor, text);
    } else {
      insertText(text);
    }
  };

  editor.insertData = (data: DataTransfer) => {
    const text = data.getData('text/plain');

    if (text && isUrl(text)) {
      wrapLink(editor, text);
    } else {
      insertData(data);
    }
  };

  return editor;
};

const unwrapLink = (editor: Editor) => {
  Transforms.unwrapNodes(editor, {
    match: (n) => 
      !Editor.isEditor(n) && SlateElement.isElement(n) && (n as CustomElement).type === 'link',
  })
}

export const isLinkActive = (editor: Editor) => {
  const [link] = Editor.nodes(editor, {
    match: (n) =>
      !Editor.isEditor(n) && SlateElement.isElement(n) && (n as CustomElement).type === 'link',
  });
  return !!link;
};

const wrapLink = (editor: Editor, url: string) => {
  if (isLinkActive(editor)) {
    unwrapLink(editor);
  }

  let finalUrl = url;
  if (!url.startsWith('http://') && !url.startsWith('https://')) { 
    finalUrl = `https://${url}`;
  }

  const { selection } = editor;
  const isCollapsed = selection && Range.isCollapsed(selection);
  const link: LinkElement = {
    type: 'link',
    url: finalUrl,
    children: isCollapsed ? [{ text: url }] : [],
  };

  if (isCollapsed) {
    Transforms.insertNodes(editor, link);
  } else {
    Transforms.wrapNodes(editor, link, { split: true });
    Transforms.collapse(editor, { edge: 'end' });
  }
};

export const onKeyDown = (event: React.KeyboardEvent, editor: Editor) => {
  if (event.key === 'Tab') {
    event.preventDefault();
    
    const [listItem] = Editor.nodes(editor, {
      match: n => SlateElement.isElement(n) && n.type === 'list-item',
    });

    if (listItem) {
      const [node, path] = listItem;
      const newLevel = (node.level || 0) + 1;
      Transforms.setNodes(
        editor,
        { level: newLevel },
        { at: path }
      );
    }
  }

  if (event.key === 'Enter') {
    const { selection } = editor;
    if (selection && Range.isCollapsed(selection)) {
      const [match] = Editor.nodes(editor, {
        match: n => SlateElement.isElement(n) && n.type === 'list-item',
      });

      if (match) {
        const [node, path] = match;
        if (Editor.string(editor, selection.anchor.path) === '') {
          event.preventDefault();
          Transforms.setNodes(editor, { type: 'paragraph', level: undefined }, { at: path });
          Transforms.unwrapNodes(editor, {
            match: n => SlateElement.isElement(n) && (n.type === 'bulleted-list' || n.type === 'numbered-list'),
            split: true,
          });
          editor.onChange();
        }
      }
    }
  }
};