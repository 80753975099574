import React, {
  PropsWithChildren,
  ReactNode,
  useCallback,
  useMemo,
} from 'react';
import { Modal, Button, PageDetails } from 'shared/components';
import { Formik, FormikProps, FormikState } from 'formik';
import * as Yup from 'yup';
import { PartialNullable } from 'shared/utils/utilityTypes';
import Tooltip from '../Tooltip';
import styled from 'styled-components';
import { PageHeaderProps } from '../pageContent';

interface BaseFormModalProps<T extends object> extends PageHeaderProps {
  onClose: () => void;
  handleSubmit: (form: T, resetForm: any) => void;
  isWorking: boolean;
  title: string;
  initialValues?: PartialNullable<T>;
  submitTitle?: string;
  validationSchema: Yup.ObjectSchema<T>;
  children: (props: FormikProps<PartialNullable<T>>) => React.ReactNode;
  size?: 'sm' | 'lg' | 'xl';
  showInvalidFormTooltip?: boolean;
  enableReinitialize?: boolean;
  hideSubmitButton?: boolean;
  disabled?: (props: FormikProps<PartialNullable<T>>) => boolean;
  tools?: ReactNode[];
  slim?: boolean;
  needsDirtyCheck?: boolean;
}

type FormModalProps<T extends object> = PropsWithChildren<
  BaseFormModalProps<T>
>;

export function FormModal<T extends object>({
  onClose,
  handleSubmit,
  isWorking,
  title,
  children,
  initialValues = {},
  validationSchema,
  slim,
  submitTitle = 'Submit',
  size = 'sm',
  showInvalidFormTooltip = false,
  enableReinitialize = true,
  hideSubmitButton = false,
  disabled = () => false,
  tools = [],
  needsDirtyCheck = true,
  ...formModalProps
}: FormModalProps<T>) {
  return (
    <Formik
      enableReinitialize={enableReinitialize}
      initialValues={initialValues}
      onSubmit={(values, { resetForm }) => {
        handleSubmit(values as T, resetForm);
      }}
      validationSchema={validationSchema}
      validateOnMount={true}
    >
      {(props) => (
        <form onSubmit={props.handleSubmit} autoComplete={'false'}>
          <Modal
            title={title}
            tools={tools}
            slim={slim}
            isOpen={true}
            onClose={onClose}
            size={size}
            footerTools={
              hideSubmitButton
                ? []
                : [
                    showInvalidFormTooltip ? (
                      <Tooltip
                        key={'form-save'}
                        content={
                          getInvalidFormTooltip(props) &&
                          getInvalidFormTooltip(props)
                        }
                        disabled={props.isValid}
                        placement={'left'}
                      >
                        <Button
                          onClick={props.submitForm}
                          color={'success'}
                          isWorking={isWorking}
                          disabled={
                            !props.dirty || !props.isValid || disabled(props)
                          }
                        >
                          {submitTitle}
                        </Button>
                      </Tooltip>
                    ) : (
                      <Button
                        onClick={props.submitForm}
                        color={'success'}
                        isWorking={isWorking}
                        disabled={
                          needsDirtyCheck ? !props.dirty || !props.isValid || disabled(props) : !props.isValid || false
                        }
                        key={'form-save'}
                      >
                        {submitTitle}
                      </Button>
                    ),
                  ]
            }
            {...formModalProps}
          >
            {children(props)}
          </Modal>
        </form>
      )}
    </Formik>
  );
}

export function getInvalidFormTooltip<T>(props: FormikProps<T>) {
  if (!props.errors) {
    return (
      <InvalidForTooltipWrapper>
        <span>This is actually valid...</span>
      </InvalidForTooltipWrapper>
    );
  }
  const keys = Object.keys(props.errors).filter(
    (key) => typeof props.errors[key] === 'string'
  );
  return (
    <InvalidForTooltipWrapper>
      {keys.map((key) => (
        <span key={key}>{props.errors[key]}</span>
      ))}
    </InvalidForTooltipWrapper>
  );
}

const InvalidForTooltipWrapper = styled.div`
  display: flex;
  flex-direction: column;

  & > *:not(:last-child) {
    margin-bottom: 5px;
  }
`;

interface BaseFormProps<T extends object> {
  handleSubmit: (
    form: T,
    resetForm: (
      nextState?: Partial<FormikState<PartialNullable<T>>> | undefined
    ) => void
  ) => void;
  initialValues?: PartialNullable<T>;
  validationSchema: Yup.ObjectSchema<T>;
  enableReinitialize?: boolean;
  renderForm?: boolean;
  children: (props: FormikProps<PartialNullable<T>>) => React.ReactNode;
  style?: React.CSSProperties;
}

type FormProps<T extends object> = PropsWithChildren<BaseFormProps<T>>;

export function Form<T extends object>({
  handleSubmit,
  initialValues = {},
  validationSchema,
  renderForm = true,
  enableReinitialize = true,
  children,
  style,
}: FormProps<T>) {
  return (
    <Formik
      enableReinitialize={enableReinitialize}
      initialValues={initialValues}
      onSubmit={(values, { resetForm }) => {
        handleSubmit(values as T, resetForm);
      }}
      validationSchema={validationSchema}
    >
      {(props) => (
        <React.Fragment>
          {renderForm ? (
            <form
              onSubmit={props.handleSubmit}
              autoComplete={'false'}
              style={{
                ...style,
                width: '100%',
                height: '100%',
                display: 'inline-flex',
                flexDirection: 'column',
              }}
            >
              {children(props)}
            </form>
          ) : (
              children(props)
          )}
        </React.Fragment>
      )}
    </Formik>
  );
}
