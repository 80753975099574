import React, { useState, useEffect } from 'react';
import { Router, Switch, Route, NavLink } from 'react-router-dom';

import Organization from './Organization';
import history from 'browserHistory';
import Marketing from './Marketing';
import { Breadcrumb, BreadcrumbsContext } from 'shared/hooks';
import styled from 'styled-components';
import { Spinner, HorizontalSeparator } from 'shared/components';
import { useGetCurrentUser } from 'shared/hooks/api';
import { lightTheme, font } from 'shared/utils/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ApprovalPackageExhibitDownloader from './Organization/Approvals/ApprovalPackageExhibitDownloader';

const Routes = () => {
  const [breadcrumbs, setBreadcrumbs] = useState<Breadcrumb[]>([]);
  return (
    <Router history={history}>
      <BreadcrumbsContext.Provider value={{ breadcrumbs, setBreadcrumbs }}>
        <Switch>
          <Route path="/" exact={true} component={OrganizationPicker} />
          <Route
            path="/_redirect"
            exact={true}
            render={() => (
              <FullPageSpinner>
                <Spinner />
              </FullPageSpinner>
            )}
          />
          <Route path="/:orgName/_approvalPackageExhibit" exact={true} render={() => <ApprovalPackageExhibitDownloader/>} />
          <Route path="/:orgName" component={Organization} />
          <Route render={() => <div>Fallback</div>} />
        </Switch>
      </BreadcrumbsContext.Provider>
    </Router>
  );
};

const OrganizationPicker = () => {
  const { data, status, isFetching, refetch } = useGetCurrentUser();
  return (
    <React.Fragment>
      {status == 'success' && data ? (
        <OrgPickerWrapper>
          <OrgPicker>
            <Logo>
              <Icon>
                {/* <FontAwesomeIcon icon={["fab", "git-alt"]} /> */}
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 13.1 13.1">
                  <title>WolffTrax</title>
                  <g id="Layer_2" data-name="Layer 2">
                    <g id="Layer_1-2" data-name="Layer 1">
                      <rect
                        style={{ fill: 'none' }}
                        height="13.1"
                        width="13.1"
                      />
                      <polygon
                        style={{ fill: '#359bdf' }}
                        points="8.68 3.28 6.48 3.28 10.57 10.03 12.76 10.03 8.68 3.28"
                      />
                      <polygon
                        style={{ fill: '#003a6a' }}
                        points="3.34 7.09 1.15 7.09 3.45 3.28 5.66 3.28 3.34 7.09"
                      />
                      <polygon
                        style={{ fill: '#359bdf' }}
                        points="5.72 3.28 3.51 3.28 7.61 10.03 9.8 10.03 5.72 3.28"
                      />
                    </g>
                  </g>
                </svg>
              </Icon>
              <LogoTitle>WOLFFTRAX</LogoTitle>
            </Logo>
            <OrgPickerHeaderWrapper>
              <FontAwesomeIcon
                icon={['fal', 'list']}
                color={lightTheme.textMedium}
              />
              <OrgPickerHeader>Choose an organization:</OrgPickerHeader>
            </OrgPickerHeaderWrapper>
            <OrgPickerList>
              {data?.currentUser.organizations?.map((org, index) => (
                <React.Fragment key={org.id}>
                  <OrgPickerEntry href={`/${org.organizationPath}`}>
                    <OrgIcon backgroundColor={getColorFromName(org.name)}>
                      {org.name.charAt(0)}
                    </OrgIcon>
                    <div>{org.name}</div>
                  </OrgPickerEntry>
                  {index != data?.currentUser.organizations?.length! - 1 && (
                    <Separator />
                  )}
                </React.Fragment>
              ))}
            </OrgPickerList>
          </OrgPicker>
        </OrgPickerWrapper>
      ) : (
        <FullPageSpinner>
          <Spinner />
        </FullPageSpinner>
      )}
    </React.Fragment>
  );
};

const colors = [
  '#DA7657',
  '#6ADA57',
  '#5784DA',
  '#AA57DA',
  '#DA5757',
  '#DA5792',
  '#57DACA',
  '#57A5DA',
];

const getColorFromName = (name: string) =>
  colors[name.toLocaleLowerCase().charCodeAt(0) % colors.length];

export const Logo = styled.div`
  display: inline-flex;
  align-items: center;
  margin-right: 10px;
  margin-bottom: 25px;
`;

export const LogoTitle = styled.div`
  ${font.bold};
  ${font.size(30)};
`;

export const Icon = styled.div`
  width: 50px;
  height: 40px;
  padding-right: 10px;
  color: ${({ theme }) => theme.info};
  align-items: center;
`;

const OrgPickerWrapper = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.border};
  padding-bottom: 300px;
`;

const OrgPicker = styled.div`
  display: flex;
  flex-direction: column;
  width: 400px;
  box-shadow: 0 0 0 1px rgba(111, 119, 130, 0.15),
    0 5px 20px 0 rgba(21, 27, 38, 0.08);
  border-radius: 3px;
  background-color: ${({ theme }) => theme.backgroundPrimary};
  padding: 20px;
`;

const OrgPickerHeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  padding-bottom: 20px;
  margin-bottom: 15px;
  border-bottom: 1px solid ${({ theme }) => theme.border};
  & > * {
    margin-right: 15px;
  }
`;

const OrgPickerHeader = styled.div`
  ${font.medium};
  ${font.size(16)};
  color: ${({ theme }) => theme.textMedium};
`;

const OrgPickerList = styled.div`
  display: flex;
  flex-direction: column;
`;

const OrgPickerEntry = styled.a`
  display: flex;
  align-items: center;
  flex-direction: row;
  ${font.regular};
  ${font.size(18)};
  padding: 15px 10px;
  width: 100%;
  border-radius: 3px;

  &:hover {
    background-color: ${({ theme }) => theme.border};
  }

  & > * {
    margin-right: 15px;
  }
`;

export const OrgIcon = styled.div<{ backgroundColor: string }>`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 36px;
  min-height: 36px;
  max-height: 36px;
  width: 36px;
  min-width: 36px;
  max-width: 36px;
  ${font.size(14)};
  ${font.medium};
  border-radius: 5px;
  color: #fff;
  background-color: ${(props) => props.backgroundColor};
`;

const Separator = styled(HorizontalSeparator)`
  margin: 5px 0;
`;

const FullPageSpinner = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
`;

export default Routes;
