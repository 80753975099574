import React, { FunctionComponent, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useGetDealStatusUpdate } from 'shared/hooks/api';
import Comments from 'App/Organization/shared/Comments';
import { CommentSubjectEnum } from 'shared/hooks/api/graphql/generated';

interface StatusUpdateCommentsProps {
  onClose: () => void;
  onCommentUpdated: () => void;
}

interface RouteParams {
  oppId: string;
  statusUpdateId: string;
}

const StatusUpdateComments: FunctionComponent<StatusUpdateCommentsProps> = ({
  onClose,
  onCommentUpdated,
}) => {
  const { oppId, statusUpdateId } = useParams<RouteParams>();
  const { data, error, isFetching, refetch, status } = useGetDealStatusUpdate(
    Number(oppId),
    Number(statusUpdateId)
  );

  const comments = useMemo(() => {
    if (data?.deal?.statusUpdate?.comments) {
      return data.deal.statusUpdate.comments.map((comment) => ({
        id: comment.id,
        body: comment.body,
        author: {
          id: comment.createdBy.id,
          name: comment.createdBy.displayName,
        },
        updatedAt: comment.updatedAt,
        createdAt: comment.createdAt,
      }));
    } else {
      return [];
    }
  }, [data]);

  return (
    <Comments
      onClose={onClose}
      comments={comments}
      isLoading={status == 'loading'}
      onCommentUpdated={() => {
        refetch();
        onCommentUpdated();
      }}
      commentSubjectType={CommentSubjectEnum.DealStatusUpdate}
      subjectId={Number(statusUpdateId)}
    />
  );
};

export default StatusUpdateComments;
