import React, { FunctionComponent, ReactNode } from 'react';
import styled from 'styled-components';
import Modal from 'react-modal';
import { PageHeader, Tab } from '../pageContent';
import Button from '../button/Button';
import { PageDetails } from '..';

interface TemplateModalProps {
  title: string | React.ReactNode;
  subtitle?: string;
  isOpen: boolean; //determines if modal is open
  toggleSideMenu: () => void; //method used to close the modal
  description?: string; //shows in the top bar on hover of an 'info' icon
  tools?: ReactNode[]; //components used as tools
  footerTools?: ReactNode[]; //components used as tools
  tabs?: Tab[];
  small?: boolean; //see below, limits size when true
  hideCloseButton?: boolean;
  slim?: boolean;
}

const ModalContainer = styled.div<{ small: boolean }>`
  display: flex;
  background-color: ${({ theme }) => theme.backgroundPrimary};
  flex-direction: column;
  min-width: ${props => (props.small ? '400px' : '600px')};
  max-width: ${props => props.small ? '400px' : '600px'};
  z-index: 1031;
  height: 100%;
  position: absolute;
  right: 0;
  width: 100%;
`;

const ModalFooter = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  padding: 10px 15px;
  border-top: 1px solid ${({ theme }) => theme.border};

  & > * {
    margin-left: 5px;
  }
`;

const TemplateModal: FunctionComponent<TemplateModalProps> = ({
    children,
    title,
    subtitle,
    isOpen,
    toggleSideMenu,
    description = '',
    tools = [],
    footerTools = [],
    tabs = [],
    small = false,
    hideCloseButton = false,
    slim = false,
  }) => {
  if (!hideCloseButton == true) {
    tools.push(
      <Button
        variant="simple"
        color="secondary"
        onClick={toggleSideMenu}
        icon="times"
        key={"modal-close-button"}
        slim={slim}
      />
    );
  }
  return (
    <Modal
      ariaHideApp={false}
      isOpen={isOpen}
      onRequestClose={toggleSideMenu}
      contentLabel="Modal"
      closeTimeoutMS={300}
      className={"SideMenu__Content"}
      overlayClassName={"SideMenu__Overlay"}
    >
      <ModalContainer small={small}>
        <PageHeader
          title={title}
          subtitle={subtitle}
          description={description}
          tools={tools}
          tabs={tabs}
          modal={true}
          slim={slim}
        />
        {children}
        <ModalFooter>{footerTools}</ModalFooter>
      </ModalContainer>

    </Modal>
  );
};

export default TemplateModal;
