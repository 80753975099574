import React, { FunctionComponent, useMemo } from 'react';
import {
  InputFormikField,
  SelectFormikField,
  FormFieldWrapper,
} from 'shared/components';
import { FormikProps } from 'formik';
import {
  useGetCrmStatuses,
  useGetFirmTypes,
  useGetInvestmentTypes,
  useGetMarkets,
  useGetOrganizationUsers,
  useGetRegions,
} from 'shared/hooks/api';

interface FirmFormDetailsProps {
  create?: boolean;
  form: FormikProps<any>;
}

const FirmFormDetails: FunctionComponent<FirmFormDetailsProps> = ({
  form,
  create = false,
}) => {
  const {
    data: typeData,
    status: getFirmTypesStatus,
  } = useGetFirmTypes();

  const typeOptions = useMemo(() => {
    return (
      typeData?.firmTypes?.map((x) => ({
        value: x.id,
        label: x.name,
      })) || []
    );
  }, [typeData]);

  const { data: marketsData, refetch: refetchMarkets, status: getMarketsStatus } = useGetMarkets();
  const { data: investmentTypesData, refetch: refecthInvestmentTypes, status: getInvestmentTypesStatus } = useGetInvestmentTypes();
  const { data: crmStatusesData, refetch: refecthCrmStatuses, status: getCrmStatusesStatus } = useGetCrmStatuses();
  const { data: usersData, refetch: refetchUsers, status: getUsersStatus } = useGetOrganizationUsers();

  const marketOptions = useMemo(() => {
    return marketsData?.markets ?? [];
  }, [marketsData]);
  
  const investmentTypeOptions = useMemo(() => {
    return investmentTypesData?.investmentTypes?.map(x => ({
      value: x.id,
      label: x.name,
    })) ?? [];
  }, [investmentTypesData]);
  
  const crmStatusOptions = useMemo(() => {
    return crmStatusesData?.crmStatuses?.map(x => ({
      value: x.id,
      label: x.name,
    })) ?? [];
  }, [crmStatusesData]);

  const userOptions = useMemo(() => {
    return usersData?.users?.map(x => ({
      value: x.id,
      label: x.firstName + ' ' + x.lastName,
    })) ?? [];
  }, [usersData]);

  const { data: regionData, status: getRegionsStatus } = useGetRegions();

  const regionOptions = useMemo(() => {
    return regionData?.regions || [];
  }, [regionData]);

  return (
    <React.Fragment>
      <FormFieldWrapper>
        <InputFormikField
          name={'name'}
          label={'Name'}
          placeholder={'Enter a name for this firm...'}
        />
      </FormFieldWrapper>
      <FormFieldWrapper>
        <SelectFormikField
          name={'type'}
          label={'Type'}
          placeholder={'Select a firm type...'}
          isLoading={getFirmTypesStatus == 'loading'}
          options={typeOptions}
          isClearable={true}
        />
      </FormFieldWrapper>
      <FormFieldWrapper>
        <SelectFormikField
          name={'status'}
          label={'Status'}
          placeholder={'Select a status...'}
          isLoading={getCrmStatusesStatus == 'loading'}
          options={crmStatusOptions}
        />
      </FormFieldWrapper>
      <FormFieldWrapper>
        <SelectFormikField
          name={'headquartersMarket'}
          label={'Headquarters Market'}
          placeholder={'Select an Market...'}
          isLoading={getMarketsStatus == 'loading'}
          options={marketOptions}
        />
      </FormFieldWrapper>
      <FormFieldWrapper>
        <SelectFormikField
          name={'productAppetite'}
          label={'Product Appetite'}
          placeholder={'Select an investment type...'}
          isLoading={getInvestmentTypesStatus == 'loading'}
          options={investmentTypeOptions}
        />
      </FormFieldWrapper>
      <FormFieldWrapper>
        <InputFormikField
          name={'website'}
          label={'Website'}
          placeholder={'Enter a URL here...'}
        />
      </FormFieldWrapper>
      <FormFieldWrapper>
        <SelectFormikField
          name={'relationshipLead'}
          label={'Relationship Lead'}
          placeholder={'Select a user...'}
          isLoading={getUsersStatus == 'loading'}
          options={userOptions}
        />
      </FormFieldWrapper>
      {create && (
        <FormFieldWrapper>
          <SelectFormikField
            name={'primaryRegion'}
            label={'Primary Region'}
            placeholder={'Select a region...'}
            isLoading={getRegionsStatus == 'loading'}
            options={regionOptions}
          />
        </FormFieldWrapper>
      )}
    </React.Fragment>
  );
};

export default FirmFormDetails;
