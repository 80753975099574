import React, { FunctionComponent, useMemo } from 'react';
import {
  CenteredSpinner,
  Debug,
  FormModal,
  PageDetails,
} from 'shared/components';
import InteractionFormDetails from './InteractionFormDetails';
import { useGetInteraction, useUpdateInteraction } from 'shared/hooks/api';
import {
  UpdateInteractionInput,
  InteractionAttendeeEnum,
} from 'shared/hooks/api/graphql/generated';
import { useNotification } from 'shared/components/notifications';
import { formatDate } from 'shared/utils/formatting/formatters';
import { yearMonthDayFormat } from 'shared/utils/dateFormats';
import {
  interactionFormSchema,
  InteractionFormValues,
} from './CreateInteractionModal';
import parse from 'date-fns/parse';
import { PartialNullable } from 'shared/utils/utilityTypes';

interface UpdateInteractionModalProps {
  id: number;
  firmId?: number;
  contactId?: number;
  onClose: () => void;
}

const UpdateInteractionModal: FunctionComponent<UpdateInteractionModalProps> = ({
  id,
  firmId,
  contactId,
  onClose,
}) => {
  const [
    createInteraction,
    { status: createInteractionStatus },
  ] = useUpdateInteraction(firmId, contactId);
  const notify = useNotification();

  const { data, status } = useGetInteraction(id);

  const initialValues: PartialNullable<InteractionFormValues> = useMemo(() => {
    if (data?.interaction) {
      return {
        name: data.interaction.name,
        interactionDate: parse(
          data.interaction.interactionDate,
          yearMonthDayFormat,
          new Date()
        ),
        type: data.interaction.type
          ? {
              value: data.interaction.type.id,
              label: data.interaction.type.name,
            }
          : null,
        attendees:
          data.interaction.attendees?.map((attendee) => ({
            value: attendee.id,
            label: attendee.name,
            type: attendee.type,
          })) ?? [],
        deals:
          data.interaction.deals?.map((deal) => ({
            value: deal.id,
            label: deal.name,
          })) ?? [],
        notes: JSON.parse(data.interaction.notes),
        firms: data.interaction.firms?.map((firm) => ({
          value: firm.id,
          label: firm.name,
        })) ?? [],
      };
    } else {
      return {};
    }
  }, [data]);

  return (
    <FormModal<InteractionFormValues>
      title={initialValues.name ? `Update Interaction: ${initialValues.name}` : 'Interaction'}
      subtitle={`${initialValues.type?.label ?? 'N/A'} ${initialValues.interactionDate ? `- ${formatDate(
        initialValues.interactionDate
      )}` : ''}`}
      onClose={onClose}
      isWorking={createInteractionStatus == 'loading'}
      submitTitle={'Update'}
      initialValues={initialValues}
      showInvalidFormTooltip={true}
      handleSubmit={async (values) => {
        const deals = values.deals !== null ? values.deals : [];
        const firms = values.firms !== null ? values.firms : [];
        const attendees = values.attendees !== null ? values.attendees : [];
        const contactIds = attendees.filter(x => x.type === InteractionAttendeeEnum.Contact).map((attendee) => attendee.value);
        const userIds = attendees.filter(x => x.type === InteractionAttendeeEnum.User).map((attendee) => attendee.value);
        const input: UpdateInteractionInput = {
          id: id,
          name: values.name,
          interactionDate: formatDate(
            values.interactionDate,
            yearMonthDayFormat
          )!,
          fkInteractionType: values.type.value,
          interactionContactIds: contactIds,
          interactionUserIds: userIds,
          dealIds: deals.map((deal) => deal.value),
          notes: JSON.stringify(values.notes),
          interactionFirmIds: firms.map((firm) => firm.value),
        };
        try {
          const result = await createInteraction(input);
          if (result.errors) {
            notify({
              duration: 5000,
              title: 'Uh-oh!',
              variant: 'danger',
              message:
                'An unknown error occurred while updating the interaction!',
            });
          } else {
            notify({
              title: 'Success!',
              message: 'Interaction Logged!',
              variant: 'success',
            });
            onClose();
          }
        } catch {
          notify({
            duration: 5000,
            title: 'Uh-oh!',
            variant: 'danger',
            message:
              'An unknown error occurred while updating the interaction!',
          });
        }
      }}
      validationSchema={interactionFormSchema}
      size={'lg'}
    >
      {(props) => (
        <PageDetails>
          {!data?.interaction && status == 'loading' ? (
            <CenteredSpinner />
          ) : (
            <React.Fragment>
              <InteractionFormDetails form={props} />
              {/* <Debug state={props} /> */}
            </React.Fragment>
          )}
        </PageDetails>
      )}
    </FormModal>
  );
};

export default UpdateInteractionModal;
