/* eslint-disable react/display-name */
import React, { FunctionComponent, forwardRef, HTMLAttributes } from 'react';
import styled from "styled-components";

interface RowProps {
  gap: number;
  template: string;
}

const StyledRow = styled.div<RowProps>`
  display: grid;
  grid-gap: ${props => props.gap}px;
  grid-template-columns: ${props => props.template};
`

interface GridProps {
  cols?: number;
  gap?: number;
  template?: string;
  style?: React.CSSProperties;
}

export const Row: FunctionComponent<GridProps> = ({
  children,
  cols = 12,
  gap = 20,
  template = `repeat(${cols}, 1fr)`,
  style,
}) => {
  return (
    <StyledRow gap={gap} template={template} style={style}>
      {children}
    </StyledRow>
  )
}

export type ColumnSpan = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;

export const getColumnSpanOrDefault = (span?: number) => {
  if (span && span > 0 && span < 13) {
    return span as ColumnSpan;
  } else {
    return 12;
  }
}

export interface ColumnProps extends HTMLAttributes<HTMLDivElement>  {
  span?: ColumnSpan;
  xs?: ColumnSpan;
  sm?: ColumnSpan;
  md?: ColumnSpan;
  lg?: ColumnSpan;
  xl?: ColumnSpan;
  cols?: number;
  gap?: number;
  template?: string;
  grid?: boolean;
}

const ColumnsWithSpan = styled.div<ColumnProps>`
  grid-column: span ${props => props.xs};

  @media (min-width: 576px) {
    grid-column: span ${props => props.sm || props.xs};
  }

  @media (min-width: 768px) {
    grid-column: span ${props => props.md || props.sm || props.xs};
  }

  @media (min-width: 992px) {
    grid-column: span ${props => props.lg || props.md || props.sm || props.xs};
  }

  @media (min-width: 1200px) {
    grid-column: span ${props => props.xl || props.lg || props.md || props.sm || props.xs};
  }

  ${props => props.grid && props.template && props.gap != undefined && `
    display: grid;
    grid-gap: ${props.gap}px;
    grid-template-columns: ${props.template};
  `}
`

export const Column = forwardRef<HTMLDivElement, ColumnProps>(({
  children,
  span = 12,
  xs = span,
  sm,
  md,
  lg,
  xl,
  cols = 12,
  gap = 20,
  template = `repeat(${cols}, 1fr)`,
  grid = false,
  className,
  style,
}, ref) => {
  return (
    <ColumnsWithSpan ref={ref} xs={xs} sm={sm} md={md} lg={lg} xl={xl} gap={gap} template={template} grid={grid} className={className} style={style}>
      {children}
    </ColumnsWithSpan>
  )
});