import {
  PublicClientApplication,
  Configuration,
  InteractionRequiredAuthError,
  AccountInfo,
  AuthenticationResult,
  SilentRequest,
  AuthorizationUrlRequest,
} from '@azure/msal-browser';
import { useState, useEffect } from 'react';
import { uniqueId } from 'lodash';

const MSAL_REDIRECT_URL_OVERRIDE = 'msal_redirect_url_override'
const redirectUri = process.env.REDIRECT_URI;

type AuthChangedEventHandler = (authInstance: AuthModule) => void;

class AuthModule {
  private msalInstance: PublicClientApplication;

  currentAccount: AccountInfo | null;
  authChangedEventHandlers: { [key: string]: AuthChangedEventHandler };

  constructor(msalInstance: PublicClientApplication) {
    this.msalInstance = msalInstance;
    this.currentAccount = null;
    this.authChangedEventHandlers = {};
  }

  private getAccount = (): AccountInfo | null => {
    const accounts = this.msalInstance.getAllAccounts();
    if (accounts.length > 0) {
      return accounts[0];
    } else {
      return null;
    }
  }

  loadAuthModule = () => {
    this.msalInstance
      .handleRedirectPromise()
      .then(this.handleResponse)
      .catch(console.error);
  }

  private handleResponse = async (response: AuthenticationResult | null) => {
    if (response !== null) {
      this.currentAccount = response.account;
    } else {
      this.currentAccount = this.getAccount();
      this.login();
    }
    Object.keys(this.authChangedEventHandlers).forEach(key => {
      this.authChangedEventHandlers[key](this);
    });
  }

  loginSilent = async (account: AccountInfo) => {
    const silentRequest: AuthorizationUrlRequest = {
      scopes: ["openid", "profile"],
    };
    silentRequest.loginHint = account.username;
    await this.msalInstance.ssoSilent(silentRequest);
  }

  login = async () => {
    this.currentAccount = this.getAccount();
    const silentRequest: AuthorizationUrlRequest = {
      scopes: ["openid", "profile"],
    };
    if (this.currentAccount) {
      try {
        await this.loginSilent(this.currentAccount);
      } catch (err) {
        if (err instanceof InteractionRequiredAuthError) {
          this.msalInstance.loginRedirect(silentRequest);
        } else {
          console.error(err);
        }
      }
    } else {
      this.msalInstance.loginRedirect(silentRequest);
    }
  }

  logout = async () => {
    if (this.currentAccount) {
      this.msalInstance.logout({ account: this.currentAccount })
    } else {
      this.msalInstance.logout();
    }
  }

  addAuthChangedEventHandler = (key: string, handler: AuthChangedEventHandler) => {
    this.authChangedEventHandlers[key] = handler;
  }

  removeAuthChangedEventHandler = (key: string) => {
    delete this.authChangedEventHandlers[key];
  }

  acquireTokenSilent = async (scopes: string[]): Promise<string> => {
    if (this.currentAccount) {
      const silentRequest: SilentRequest = {
        scopes,
        account: this.currentAccount,
      }
      const response = await this.msalInstance.acquireTokenSilent(silentRequest)
      return response.accessToken;
    } else {
      throw new Error("No current user specified.")
    }
  }
  
  acquireTokenRedirect = async (scopes: string[]): Promise<void> => {
    if (this.currentAccount) {
      const request: SilentRequest = {
        scopes,
        account: this.currentAccount,
      }
      await this.msalInstance.acquireTokenRedirect(request)
    } else {
      throw new Error("No current user specified.")
    }
  }

  acquireToken = async (scopes: string[]): Promise<string> => {
    try {
      return await this.acquireTokenSilent(scopes);
    } catch {
      await this.acquireTokenRedirect(scopes);
    }
  }

}

const config: Configuration = {
  auth: {
    clientId: process.env.CLIENT_ID!,
    redirectUri: redirectUri,
    navigateToLoginRequestUrl: true,
    authority: `https://login.microsoftonline.com/${process.env.TENANT_ID}`,
  },
};

const instance = new PublicClientApplication(config);

const authModuleInstance = new AuthModule(instance);
export default authModuleInstance;

export const useAuthModule = () => {
  const [, setChanged] = useState(0);

  useEffect(() => {
    const uuid = uniqueId();
    authModuleInstance.addAuthChangedEventHandler(uuid, () => {
      setChanged(initial => {
        return initial + 1;
      })
    });
    return () => {
      authModuleInstance.removeAuthChangedEventHandler(uuid);
    }
  }, [setChanged]);

  return authModuleInstance;
}
