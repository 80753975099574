import React, { FunctionComponent, useMemo } from 'react';
import {
    CreateFirmInput,
} from 'shared/hooks/api/graphql/generated';
import { useCreateFirm } from 'shared/hooks/api';
import { useNotification } from 'shared/components/notifications';
import { FormModal, PageDetails } from 'shared/components';
import { PartialNullable } from 'shared/utils/utilityTypes';
import { FirmFormValues } from './UpdateFirmModal';
import { OptionSchema } from './CreateInteractionModal';
import FirmFormDetails from './FirmFormDetails';
import * as Yup from 'yup';
import isUrl from 'is-url';

interface CreateFormikFieldFirmModalProps {
    onClose: () => void;
    onSuccess: () => void;
    pipelineId: number;
    name: string;
}

const createFormikFirmFormSchema: Yup.ObjectSchema<FirmFormValues> = Yup.object().shape({
    name: Yup.string()
        .min(2, 'Too Short!')
        .required("Required")
        .label("Name"),
    website: Yup.string()
        .min(2, 'Too Short!')
        .max(200, 'Too Long!')
        .label("Website")
        .test('is-url', 'Must be a valid URL', (value) => {
            return value != undefined && value != null ? isUrl(value) : true;
        }).nullable(),
    type: Yup.object()
    .shape({
      value: Yup.number().notRequired(),
      label: Yup.string().notRequired(),
    })
    .notRequired()
    .nullable()
    .label('Type'),
    status: Yup.object()
    .shape({
      value: Yup.number().notRequired(),
      label: Yup.string().notRequired(),
    })
    .notRequired()
    .nullable()
    .label('Status'),
    headquartersMarket: Yup.object()
    .shape({
      value: Yup.number().notRequired(),
      label: Yup.string().notRequired(),
    })
    .notRequired()
    .nullable()
    .label('Headquarters (Market)'),
    productAppetite: Yup.object()
    .shape({
      value: Yup.number().notRequired(),
      label: Yup.string().notRequired(),
    })
    .notRequired()
    .nullable()
    .label('Product Appetite'),
    relationshipLead: Yup.object()
    .shape({
      value: Yup.number().notRequired(),
      label: Yup.string().notRequired(),
    })
    .notRequired()
    .nullable()
    .label('Relationship Lead'),
    primaryRegion: Yup.object()
    .shape({
      value: Yup.number().notRequired(),
      label: Yup.string().notRequired(),
    })
    .notRequired()
    .nullable()
    .label('Primary Region'),
}).required();

const CreateFormikFieldFirmModal: FunctionComponent<CreateFormikFieldFirmModalProps> = ({
    onClose,
    onSuccess,
    pipelineId,
    name
}) => {
    const [createFirm, { status }] = useCreateFirm();
    const notify = useNotification();

    const initialValues: PartialNullable<FirmFormValues> = useMemo(() => {
        return { name: name };
    }, [pipelineId, name]);

    return (
        <FormModal<FirmFormValues>
            initialValues={initialValues}
            title={'Create Firm'}
            submitTitle={'Create'}
            onClose={onClose}
            isWorking={status === 'loading'}
            validationSchema={createFormikFirmFormSchema}
            handleSubmit={async (form, resetForm) => {
                const input: CreateFirmInput = {
                    name: form.name,
                    fkFirmType: form.type?.value,
                    fkMarketHq: form.headquartersMarket?.value,
                    fkInvestmentTypeAppetite: form.productAppetite?.value,
                    fkCrmStatus: form.status?.value,
                    fkUserRelationshipLead: form.relationshipLead?.value,
                    website: form.website,
                    fkPipeline: Number(pipelineId),
                    fkPrimaryRegion: form.primaryRegion?.value,
                };
                try {
                    const result: any = await createFirm(input);
                    if (result.errors && result.errors[0].extensions?.code === 'UNIQUE_CONSTRAINT') {
                        notify({
                            duration: 7000,
                            title: 'Uh-oh!',
                            message: `A Firm with this name already exists; please create a new firm or update the existing one.`,
                            variant: 'danger',
                        });
                    }
                    else if (result.errors) {
                        notify({
                            duration: 5000,
                            title: 'Uh-oh!',
                            message: `An unknown error occurred while creating the firm!`,
                            variant: 'danger',
                        });
                    } else {
                        switch (result.data?.createFirm?.__typename) {
                            case 'Firm':
                                notify({
                                    duration: 3000,
                                    title: 'Success!',
                                    message: 'Firm Created!',
                                    variant: 'success',
                                });
                                onClose();
                                onSuccess();
                                resetForm();
                                break;
                            default:
                                notify({
                                    duration: 5000,
                                    title: 'Failed to Create Firm',
                                    message: 'An error occurred while creating the firm!',
                                    variant: 'danger',
                                });
                                break;
                        }
                    }
                } catch (error) {
                    notify({
                        duration: 5000,
                        title: 'Uh-oh!',
                        message: `An unknown error occurred while creating the firm! ${error}`,
                        variant: 'danger',
                    });
                }
            }}
        >
            {(props) => (
                <PageDetails>
                    <FirmFormDetails create={true} form={props} />
                </PageDetails>
            )}
        </FormModal>
    );
};

export default CreateFormikFieldFirmModal;