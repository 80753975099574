import { ValueFormatterParams } from "ag-grid-community";
import { Maybe } from "shared/hooks/api/graphql/generated";
import { formatCurrency, formatDate, formatDatetime, formatDecimal, formatPercentage } from "./formatters";

export const tableDecimalFormat = (params: ValueFormatterParams, precision?: number) => {
  return formatDecimal(params.value, precision) || '';
};

export const tableCurrencyFormat = (params: ValueFormatterParams, precision?: number) => {
  const formatted = formatCurrency(params.value, precision);
  if (formatted != null && formatted != undefined) {
    return formatted;
  } else {
    return '$0';
  }
};

export const tablePercentageFormat = (params: ValueFormatterParams, precision?: number) => {
  return formatPercentage(params.value, precision) || '';
};

export const tableDateFormat = (params: ValueFormatterParams) => {
  const result = formatDate(params.value) || '';
  return result;
};

export const tableDatetimeFormat = (params: ValueFormatterParams) => {
  const result = formatDatetime(params.value) || '';
  return result;
};
