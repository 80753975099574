import React, { useContext, useMemo, useState } from 'react';
import { useParams, useHistory, useRouteMatch } from 'react-router-dom';
import {
  PageDetails,
  PageHeader,
  PageToolbar,
  Section,
  Spinner,
  ToolbarSearchInput,
  Tooltip,
} from 'shared/components';
import { useBreadcrumb, usePageTitle, useRemainingHeight } from 'shared/hooks';
import styled, { ThemeContext } from 'styled-components';
import { font, lightTheme, mixin } from 'shared/utils/styles';
import { useGetPipelines } from 'shared/hooks/api';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import NoAccess from 'shared/components/noAccess';

const PipelinesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  & > * {
    margin: 5px 0;
  }
`;

interface PipelinesRowProps {
  last: boolean;
}

const EditButton = styled.div`
  opacity: 0;
  transition: all 200ms ease-in-out;
  margin-left: 20px;
`;

const PipelinesRow = styled.div<PipelinesRowProps>`
  display: flex;
  align-items: center;
  width: 100%;
  height: 75px;
  padding: 20px;
  background-color: ${({ theme }) => theme.backgroundPrimary};
  ${(props) => !props.last && `border-bottom: 1px solid ${props.theme.border}`};
  ${mixin.clickable};

  border-radius: 5px;
  box-shadow: 0 0 0 1px rgba(111, 119, 130, 0.15),
    0 5px 20px 0 rgba(21, 27, 38, 0.08);

  &:hover > ${EditButton} {
    opacity: 100;
  }
`;

const PipelineTitles = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 20px;
`;

const PipelineTitle = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  ${font.size(16)};
  color: ${({ theme }) => theme.textDarkest};
  ${font.bold};
  margin-bottom: 5px;

  & > * {
    margin-right: 10px;
  }
`;

const PipelineSubtitle = styled.p`
  ${font.size(14)};
  color: ${({ theme }) => theme.textMedium};
`;

const PipelineTitleSubtitle = styled.p`
  ${font.size(12)};
  ${font.regular};
  color: ${({ theme }) => theme.textMedium};
  margin-left: 5px;
`;

export const PipelineIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  ${mixin.clickable};
  height: 36px;
  min-height: 36px;
  max-height: 36px;
  width: 36px;
  min-width: 36px;
  max-width: 36px;
  ${font.size(14)};
  ${font.medium};
  border-radius: 5px;
  color: #fff;
  background-color: ${({ theme }) => theme.primary};
`;

const colors = [
  '#DA7657',
  '#6ADA57',
  '#5784DA',
  '#AA57DA',
  '#DA5757',
  '#DA5792',
  '#57DACA',
  '#57A5DA',
];

const getColorFromName = (name: string) =>
  colors[name.toLocaleLowerCase().charCodeAt(0) % colors.length];

const PageSpinnerComp = () => {
  return (
    <PageSpinner>
      <Spinner />
    </PageSpinner>
  );
};

const PageSpinner = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 200px;
`;

const ToolbarMessageWrapper = styled.div`
  display: flex;
  align-items: center;

  & > * {
    margin-right: 7px;
  }
`;

const ToolbarMessageMessage = styled.div`
  color: ${({ theme }) => theme.textLight};
  ${font.medium};
`;

const PipelineToolbarMessage = () => {
  return (
    <ToolbarMessageWrapper>
      <FontAwesomeIcon icon={['fal', 'lock']} color={lightTheme.textLight} />
      <ToolbarMessageMessage>Some Pipelines are</ToolbarMessageMessage>
    </ToolbarMessageWrapper>
  );
};

const Pipelines = () => {
  const match = useRouteMatch();
  const { orgName } = useParams<{ orgName: string }>();

  const history = useHistory();
  const { data, error, isFetching, refetch } = useGetPipelines();

  const [searchText, setSearchText] = useState('');

  const filteredData = useMemo(() => {
    const pipelines = data?.pipelines?.filter((p) =>
      p.name.toLowerCase().includes(searchText.toLowerCase())
    ).sort((a, b) => {
      if (a.name > b.name) {
        return 1;
      } else {
        return -1;
      }
    });
    return pipelines || [];
  }, [data, searchText]);

  usePageTitle('Contacts');

  useBreadcrumb({
    title: 'Contacts',
    path: match.url,
    key: 'contacts',
    index: 2,
  });

  const { ref, height } = useRemainingHeight();

  return (
    <React.Fragment>
      <PageHeader
        title={"Contact Management"}
        description={
          'Use this module to manage the list of firms and their contacts that are referenced as partners or points of contact for your organization.'
        }
      />
      <PageToolbar
        left={
          <React.Fragment>
            <ToolbarSearchInput
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
            />
          </React.Fragment>
        }
      />
      <PageDetails ref={ref}>
        {!data && isFetching ? (
          <PageSpinnerComp />
        ) : data?.pipelines && data.pipelines.length == 0 ? (
          <div style={{ height }}>
            <NoAccess
              icon={'box-open'}
              title={`Nothing to see here...`}
              subtitle={
                "While your Organization might have a set of address books, you may not have access to them."
              }
            />
          </div>
        ) : (
          <Section title={"Address Books"}>
            <PipelinesWrapper>
              {filteredData.length == 0 && searchText == '' ? (
                <NoAccess
                  title={'No Accessible Address Books'}
                  subtitle={`You don't have access to any address books. This could mean no one has created any or you haven't been granted access to one.`}
                  icon={'layer-group'}
                />
              ) : filteredData.length == 0 && searchText != '' ? (
                <NoAccess
                  title={'No Address Books Found'}
                  subtitle={`Your search yielded no results.`}
                  icon={'layer-group'}
                  action={() => setSearchText('')}
                  actionTitle={'Clear Search'}
                />
              ) : (
                filteredData.map((pipeline, index) => (
                  <PipelinesRow
                    key={pipeline.id}
                    onClick={() =>
                      history.push(`/${orgName}/address-books/${pipeline.id}`)
                    }
                    last={index === filteredData.length - 1}
                  >
                    <PipelineIcon
                      style={{
                        backgroundColor: getColorFromName(pipeline.name),
                      }}
                    >
                      {pipeline.name.charAt(0)}
                    </PipelineIcon>
                    <PipelineTitles>
                      <PipelineTitle>
                        <div>{pipeline.name}</div>
                        <Tooltip
                          content={
                            `Only users assigned to the "${pipeline.name}" pipeline can access this address book.`
                          }
                          maxWidth={150}
                        >
                          <span
                            style={{
                              display: 'flex',
                              flexDirection: 'row',
                              alignItems: 'center',
                            }}
                          >
                            <FontAwesomeIcon
                              icon={[
                                'fal',
                                'users',
                              ]}
                              color={lightTheme.textMedium}
                              style={{ fontSize: '12px' }}
                            />
                            <PipelineTitleSubtitle style={{}}>
                              {"Restricted"}
                            </PipelineTitleSubtitle>
                          </span>
                        </Tooltip>
                      </PipelineTitle>
                      <PipelineSubtitle>
                        {`Address book for the ${pipeline.name} pipeline`}
                      </PipelineSubtitle>
                    </PipelineTitles>
                  </PipelinesRow>
                ))
              )}
            </PipelinesWrapper>
          </Section>
        )}
      </PageDetails>
    </React.Fragment>
  );
};

export default Pipelines;
