import React, {
  useMemo,
  useCallback,
  useState,
} from 'react';
import { useRouteMatch, useHistory, useParams } from 'react-router-dom';
import { useTable, usePageTitle, useBreadcrumb } from 'shared/hooks/';
import { useRemainingHeight } from 'shared/hooks';
import { useDeleteFirm, useGetPipelineFirms } from 'shared/hooks/api';
import {
  PageDetails,
  Table,
  PageToolbar,
  ToolbarButton,
  ToolbarSearchInput,
  SortMenuButton,
  GroupMenuButton,
  ToolbarMenu,
  DropdownItem,
  Dialog,
  MenuSeparator,
} from 'shared/components';
import { createQueryParamModalHelpers } from 'shared/utils/queryParamModal';
import { CellClickedEvent, ColDef } from 'ag-grid-community';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CreateFirmModal from '../shared/CreateFirmModal';
import { useNotification } from 'shared/components/notifications';
import { lightTheme } from 'shared/utils/styles';

type FirmRow = {
  id: number;
  name: string;
  status?: string;
  type?: string;
  headquartersMarket?: string;
  primaryRegion?: string;
  productAppetite?: string;
  keyPoc?: string;
  keyPocPhone?: string;
  relationshipLead?: string;
};

const PipelineFirms = () => {
  const { orgName, pipelineId } = useParams<{
    orgName: string;
    pipelineId: string;
  }>();
  const newFirmModalHelpers = createQueryParamModalHelpers('new-firm');
  const { ref, height } = useRemainingHeight();
  usePageTitle('Firms');

  const { data, errors, isFetching, refetch, status } = useGetPipelineFirms(
    Number(pipelineId)
  );

  const match = useRouteMatch();
  const history = useHistory();

  useBreadcrumb(
    {
      title: 'Firms',
      path: match.url,
      key: 'firms',
      index: 3,
    },
    []
  );

  const {
    searchText,
    setSearchText,
    sortModel,
    applyColumnState,
    groupedColumns,
    setGroupedColumns,
    gridColumns,
    gridEvents,
    tableRef,
  } = useTable({ autoSizeColumns: true });

  const firms = useMemo(() => {
    if (data?.pipeline?.firms) {
      const rows: FirmRow[] = data.pipeline.firms.map((firm) => {
        const primaryRegion = firm.regions.find(
          (region) => region.isPrimaryRegion
        );
        const keyPocs =
          firm.keyContacts.length > 0
            ? firm.keyContacts
                .map(
                  (keyContact) =>
                    `${keyContact.firstName} ${keyContact.lastName}`
                )
                .join(', ')
            : undefined;
        const keyPocPhone =
          firm.keyContacts.length > 0
            ? firm.keyContacts
                .map((keyContact) => keyContact.cellPhone)
                .join(', ')
            : undefined;
        return {
          id: firm.id,
          name: firm.name,
          status: firm.status?.name ?? undefined,
          type: firm.type?.name ?? undefined,
          headquartersMarket: firm.headquartersMarket?.name ?? undefined,
          primaryRegion: primaryRegion?.name ?? undefined,
          productAppetite: firm.productAppetite?.name ?? undefined,
          keyPoc: keyPocs,
          keyPocPhone: keyPocPhone,
          relationshipLead: firm.relationshipLead?.displayName ?? undefined,
        };
      });
      return rows;
    }
  }, [data]);

  const [selectedFirms, setSelectedFirms] = useState<FirmRow[]>([]);

  const onRowSelected = useCallback(() => {
    const selectedRows = tableRef.current?.gridApi.getSelectedRows();
    if (selectedRows) {
      setSelectedFirms(selectedRows);
    }
  }, [tableRef, setSelectedFirms]);

  const [isShowingRemoveDialog, setIsShowingRemoveDialog] = useState(false);
  const [deleteFirm, { status: deleteFirmStatus }] = useDeleteFirm();
  const notify = useNotification();

const [columnDefs, setColumnDefs] = useState<ColDef[]>([
  {
    maxWidth: 40,
    checkboxSelection: true,
    pinned: 'left',
    headerCheckboxSelection: true,
    headerCheckboxSelectionFilteredOnly: true
  },
  {
    headerName: 'ID',
    field: 'id',
    hide: true
  },
  {
    headerName: 'Name',
    field: 'name',
    onCellClicked: (params: CellClickedEvent) =>
      history.push(
        `/${orgName}/address-books/${pipelineId}/firm-details/${params.data.id}`
      ),
    cellStyle: { cursor: 'pointer' },
    sort: 'asc'
  },
  {
    headerName: 'Status',
    field: 'status'
  },
  {
    headerName: 'Firm Type',
    field: 'type'
  },
  {
    headerName: 'Headquarters',
    field: 'headquartersMarket'
  },
  {
    headerName: 'Primary Region',
    field: 'primaryRegion',
    rowGroup: true
  },
  {
    headerName: 'Product Appetite',
    field: 'productAppetite'
  },
  {
    headerName: 'Key Contact',
    field: 'keyPoc'
  },
  {
    headerName: 'Phone',
    field: 'keyPocPhone'
  },
  {
    headerName: 'Relationship Lead',
    field: 'relationshipLead'
  }
]);

  return (
    <React.Fragment>
      <PageToolbar
        left={
          <React.Fragment>
            <ToolbarMenu
              title={'Actions...'}
              icon={'clipboard-list'}
              variant={'outline'}
              anchor={'left'}
              disabled={selectedFirms.length === 0}
            >
              <DropdownItem
                icon={
                  <FontAwesomeIcon
                    icon={['fal', 'user-slash']}
                    color={lightTheme.danger}
                  />
                }
                onClick={() => setIsShowingRemoveDialog(true)}
              >
                Delete Selected Firm...
              </DropdownItem>
            </ToolbarMenu>
            <ToolbarButton icon={'plus'} onClick={newFirmModalHelpers.open}>
              Create Firm
            </ToolbarButton>
            <ToolbarSearchInput
              placeholder={'Search...'}
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
            />
          </React.Fragment>
        }
        right={
          <React.Fragment>
            <SortMenuButton
              sortModel={sortModel}
              applyColumnState={applyColumnState}
              gridColumns={gridColumns}
            />
            <GroupMenuButton
              groupedColumns={groupedColumns}
              setGroupedColumns={setGroupedColumns}
              gridColumns={gridColumns}
            />
            <ToolbarButton
              icon={'sync'}
              onClick={() => refetch()}
              isWorking={isFetching}
            >
              Refresh
            </ToolbarButton>
          </React.Fragment>
        }
        mobileLeft={
          <React.Fragment>
            <ToolbarMenu
              title={'Actions...'}
              icon={'clipboard-list'}
              variant={'outline'}
              anchor={'left'}
            >
              <DropdownItem icon={'plus'} onClick={newFirmModalHelpers.open}>
                Create Firm
              </DropdownItem>
              <MenuSeparator/>
              <DropdownItem
                icon={
                  <FontAwesomeIcon
                    icon={['fal', 'user-slash']}
                    color={lightTheme.danger}
                  />
                }
                onClick={() => setIsShowingRemoveDialog(true)}
                disabled={selectedFirms.length === 0}
              >
                Delete Selected Firm...
              </DropdownItem>
            </ToolbarMenu>
            <ToolbarSearchInput
              placeholder={'Search...'}
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
            />
          </React.Fragment>
        }
        mobileRight={
          <React.Fragment>
            <ToolbarButton
              icon={'sync'}
              onClick={() => refetch()}
              isWorking={isFetching}
            >
              Refresh
            </ToolbarButton>
          </React.Fragment>
        }
      />
      <PageDetails ref={ref}>
        <Table
          rowData={firms}
          columnDefs={columnDefs}
          defaultColDef={{
            sortable: true,
            enableRowGroup: true,
            suppressMovable: true,
            lockPinned: true,
          }}
          quickFilterText={searchText}
          groupUseEntireRow={true}
          {...gridEvents}
          style={{ height: height, width: '100%' }}
          rowSelection={'single'}
          onSelectionChanged={onRowSelected}
          suppressRowClickSelection={true}
          noRowsOverlayIcon={'address-book'}
          noRowsOverlayTitle={'No Assigned Firms'}
          noRowsOverlaySubtitle={
            'No firms have been assigned to this deal yet.'
          }
          isLoading={status == 'loading'}
          rowGroupPanelShow={'always'}
          groupDefaultExpanded={-1}
        >
        </Table>
      </PageDetails>
      {isShowingRemoveDialog && selectedFirms.length > 0 && (
        <Dialog
          title={'Delete Firm'}
          message={`Are you sure you want to delete the firm named "${selectedFirms[0].name}"?`}
          confirmTitle={`Yes, delete firm`}
          variant={'danger'}
          isOpen={true}
          onClose={() => setIsShowingRemoveDialog(false)}
          onCancel={() => setIsShowingRemoveDialog(false)}
          isWorking={deleteFirmStatus == 'loading'}
          onConfirm={async () => {
            try {
              const result = await deleteFirm(selectedFirms[0].id);
              if (result.errors) {
                notify({
                  message: 'An error occurred while deleting this firm.',
                  variant: 'danger',
                  duration: 5000,
                });
              } else {
                switch (result.data?.deleteFirm?.__typename) {
                  case 'Firm':
                    notify({
                      message: 'Firm successfully deleted',
                    });
                    break;
                  case 'ErrorResult':
                    notify({
                      duration: null,
                      variant: 'danger',
                      title: 'Unable to delete firm',
                      message: result.data.deleteFirm.message,
                    });
                    break;
                }
              }
            } catch {
              notify({
                duration: 5000,
                title: 'Uh-oh',
                message: 'An error occurred while deleting this firm.',
                variant: 'danger',
              });
            }
            setIsShowingRemoveDialog(false);
            setSelectedFirms([]);
          }}
        />
      )}
      {newFirmModalHelpers.isOpen() && (
        <CreateFirmModal
          onClose={newFirmModalHelpers.close}
          onSuccess={() => {
            refetch();
          }}
        />
      )}
    </React.Fragment>
  );
};

export default PipelineFirms;
