import styled, { css } from "styled-components";
import { BadgeVariant, BadgeColor } from "./Badge";
import { font } from "shared/utils/styles";

export const Text = styled.div<{padLeft?: boolean}>`
  padding-left: ${props => props.padLeft ? 5 : 0}px;
`

interface RequiredBadgeProps {
  variant: BadgeVariant;
  color: BadgeColor;
  isActive?: boolean;
  slim?: boolean;
  borderRadius?: [number, number, number, number];
  fullWidth?: boolean;
  disabled?: boolean;
}

export const StyledBadge = styled.div<RequiredBadgeProps>`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  ${props => props.fullWidth && `width: 100%`};
  vertical-align: middle;
  line-height: 1;
  padding: 5px 10px;
  white-space: nowrap;
  ${font.defaultSize};
  appearance: none;
  ${props => badgeVariants[props.variant][props.color]};
`

const standard = css<RequiredBadgeProps>`
  color: #fff;
  background: ${props => props.theme[props.color]};
  border: 1px solid ${props => props.theme[props.color]};
  ${font.medium}
`;

const outlined = css<RequiredBadgeProps>`
  color: ${props => props.theme[props.color]};
  background: transparent;
  border: 1px solid ${props => props.theme[props.color]};
  ${font.medium}
`;

const secondary = css<RequiredBadgeProps>`
  color: ${props => props.color == "secondary" ? props.theme.textDark : props.theme[props.color]};
  background: ${props => props.variant == "standard" ? props.theme[props.color] : `transparent`};
  border: 1px solid ${props => props.theme[props.color]}};
  ${font.regular}
`;

const badgeVariants = {
  standard: {
    primary: standard,
    success: standard,
    warning: standard,
    danger: standard,
    secondary: secondary,
    info: standard
  },
  outline: {
    primary: outlined,
    success: outlined,
    warning: outlined,
    danger: outlined,
    secondary: secondary,
    info: outlined
  }
}