import React, { FunctionComponent, useMemo } from 'react';
import { Modal, PageDetails, FormModal, Button } from 'shared/components';
import * as Yup from 'yup';
import { UpdateDealInput } from 'shared/hooks/api/graphql/generated';
import { useUpdateDeal } from 'shared/hooks/api';
import { useNotification } from 'shared/components/notifications';
import { TextEditorFormikField, TextEditedContent } from 'shared/components';
import { OptionalTextEditorSchema } from 'shared/components/form/textEditor/TextEditor';
import { PartialNullable } from 'shared/utils/utilityTypes';

interface DealNarrativeModalProps {
  title: string;
  onClose: () => void;
  valueKey: 'dealBackground' | 'investmentVision' | 'earnestMoneyDeposit';
  value?: string | null;
  oppId: number;
  currentUserCanEdit: boolean;
}

const validationSchema = Yup.object()
  .shape({
    investmentVision: OptionalTextEditorSchema,
    dealBackground: OptionalTextEditorSchema,
  })
  .required();

type DealNarrativeValues = {
  investmentVision?: any;
  dealBackground?: any;
};

const DealNarrativeModal: FunctionComponent<DealNarrativeModalProps> = ({
  title,
  onClose,
  value,
  valueKey,
  oppId,
  currentUserCanEdit,
}) => {
  const notify = useNotification();
  const [updateDeal, { status }] = useUpdateDeal({ throwOnError: true });
  const initialValues: PartialNullable<DealNarrativeValues> = useMemo(() => {
    try {
      if (value) {
        return {
          [valueKey]: JSON.parse(value),
        };
      } else {
        return {};
      }
    } catch {
      return {
        [valueKey]: undefined,
      };
    }
  }, [value, valueKey]);
  return (
    <React.Fragment>
      {currentUserCanEdit ? (
        <FormModal<DealNarrativeValues>
          title={title}
          submitTitle={'Save'}
          initialValues={initialValues}
          handleSubmit={async (form, resetForm) => {
            const input: UpdateDealInput = {
              id: oppId,
              [valueKey]: JSON.stringify(form[valueKey]),
            };
            try {
              await updateDeal(input);
              notify({
                title: 'Deal Narrative Updated!',
                variant: 'success',
              });
              onClose();
            } catch (error) {
              notify({
                duration: 5000,
                title: 'Uh-oh!',
                message:
                  'An unknown error occurred while updating the deal background!',
                variant: 'danger',
              });
            }
          }}
          isWorking={status == 'loading'}
          onClose={onClose}
          validationSchema={validationSchema}
          size={'lg'}
          enableReinitialize={false}
          hideSubmitButton={true}
        >
          {(props) => (
            <PageDetails>
              <TextEditorFormikField
                name={valueKey}
                placeholder={'Enter text for the deal narrative here...'}
                autoFocus={true}
                tools={
                  <React.Fragment>
                    <Button
                      onClick={props.submitForm}
                      color={'success'}
                      isWorking={status == 'loading'}
                      disabled={!props.dirty || !props.isValid}
                    >
                      Save
                    </Button>
                  </React.Fragment>
                }
              />
            </PageDetails>
          )}
        </FormModal>
      ) : (
        <Modal title={title} onClose={onClose} isOpen={true}>
          <PageDetails>
            {value && <TextEditedContent value={value} />}
          </PageDetails>
        </Modal>
      )}
    </React.Fragment>
  );
};

export default DealNarrativeModal;
