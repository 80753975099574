import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FunctionComponent, HTMLAttributes, useContext, useState } from 'react'
import { useFocus } from 'shared/hooks';
import Button from '../button/Button';
import Tooltip from '../Tooltip';
import { StyledLabel, Text } from './Styles';
import { ThemeContext } from 'styled-components';

export type LabelVariant = "standard" | "outline";

export interface LabelProps extends HTMLAttributes<HTMLButtonElement> {
  name: string;
  color?: string;
  onClick?: () => void;
  onRemove?: () => void;
  style?: React.CSSProperties;
}

const Label: FunctionComponent<LabelProps> = ({
  name,
  color,
  onClick,
  onRemove,
  style,
}) => {
  const themeContext = useContext(ThemeContext);
  color = themeContext.secondary
  return (
    <StyledLabel color={color} style={style} onClick={onClick} onRemove={onRemove}>
      <Text>{name}</Text>
      {onRemove && 
        <span onClick={onRemove}>
          <FontAwesomeIcon icon={'times'} fixedWidth={true}/>
        </span>
      }
    </StyledLabel>
  )
}

export default Label;