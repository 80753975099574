import {
  useQuery,
  AnyQueryKey,
  QueryFunction,
  QueryOptions,
} from 'react-query';
import * as queries from '../client/queries';
import { GraphQLError } from 'graphql-request/dist/src/types';
import { number } from 'yup';
import { CreateTransactionInput, MarketStatisticEnum } from '../graphql/generated';

// Queries

interface GraphQLErrorWithExtensions extends GraphQLError {
  extensions?: any;
}

export interface GraphQLResult<TDataResult> {
  data?: TDataResult;
  extensions?: any;
  headers: Headers;
  status: number;
  errors?: GraphQLErrorWithExtensions[];
}

export function useQueryWithResultSplitting<TResult, TKey extends AnyQueryKey>(
  queryKey:
    | TKey
    | false
    | null
    | undefined
    | (() => TKey | false | null | undefined),
  queryFn: QueryFunction<GraphQLResult<TResult>, TKey>,
  config?: QueryOptions<GraphQLResult<TResult>>
) {
  const result = useQuery(queryKey, queryFn, config);
  const splitResult = {
    ...result,
    status: result.status,
    data: result.data?.data,
    errors: result.data?.errors,
  };
  return splitResult;
}

export const useGetCurrentUser = (config?: any) => {
  return useQueryWithResultSplitting(
    ['currentUser'],
    queries.getCurrentUser,
    config
  );
};

export const useGetPortfolios = (orgName: string, config?: any) => {
  return useQueryWithResultSplitting(
    ['portfolios', orgName],
    queries.getPortfolios,
    config
  );
};

export const useGetPortfolio = (portfolioId: number, config?: any) => {
  return useQueryWithResultSplitting(
    ['portfolio', portfolioId],
    queries.getPortfolio,
    config
  );
};

export const useGetPortfolioPinnedReports = (
  portfolioId: number,
  config?: any
) => {
  return useQueryWithResultSplitting(
    ['portfolioPinnedReports', portfolioId],
    queries.getPortfolioPinnedReports,
    config
  );
};

export const useGetPortfolioUsers = (portfolioId: number, config?: any) => {
  return useQueryWithResultSplitting(
    ['portfolioUsers', portfolioId],
    queries.getPortfolioUsers,
    config
  );
};

export const useGetPortfolioDeals = (portfolioId: number, config?: any) => {
  return useQueryWithResultSplitting(
    ['portfolioDeals', portfolioId],
    queries.getPortfolioDeals,
    config
  );
};

export const useGetPortfolioUserAccess = (
  portfolioId: number,
  config?: any
) => {
  return useQueryWithResultSplitting(
    ['portfolioUserAccess', portfolioId],
    queries.getPortfolioUserAccess,
    config
  );
};

export const useGetDealsPaginated = (
  showArchivedDeals: boolean,
  limit: number,
  offset: number,
  config?: any
) => {
  const queryKey: [string, boolean, number, number] = ['dealsPaginated', showArchivedDeals, limit, offset];
  const configWithVariables = {
    ...config,
    variables: {
      ...config?.variables,
      limit,
      offset,
    },
  };
  return useQueryWithResultSplitting(queryKey, queries.getDealsPaginated, configWithVariables);
};

export const useGetDeals = (showArchivedDeals: boolean, config?: any) => {
  return useQueryWithResultSplitting(['deals', showArchivedDeals], queries.getDeals, config);
};

export const useGetDeal = (dealId: number, config?: any) => {
  return useQueryWithResultSplitting(['deal', dealId], queries.getDeal, config);
};

export const useGetDealInteractions = (dealId: number, config?: any) => {
  return useQueryWithResultSplitting(['dealInteractions', dealId], queries.getDealInteractions, config);
};

export const useGetDealUserAccess = (dealId: number, config?: any) => {
  return useQueryWithResultSplitting(
    ['dealUserAccess', dealId],
    queries.getDealUserAccess,
    config
  );
};

export const useGetPipelineUserAccess = (pipelineId: number, config?: any) => {
  return useQueryWithResultSplitting(
    ['pipelineUserAccess', pipelineId],
    queries.getPipelineUserAccess,
    config
  );
};

export const useGetDealStatusUpdates = (dealId: number, config?: any) => {
  return useQueryWithResultSplitting(
    ['dealStatusUpdates', dealId],
    queries.getDealStatusUpdates,
    config
  );
};

export const useGetDealStatusUpdate = (
  dealId: number,
  statusUpdateId: number,
  config?: any
) => {
  return useQueryWithResultSplitting(
    ['dealStatusUpdates', dealId, statusUpdateId],
    queries.getDealStatusUpdate,
    config
  );
};

export const useGetDealStatusUpdateTemplates = (config?: any) => {
  return useQueryWithResultSplitting(
    ['dealStatusUpdateTemplates'],
    queries.getDealStatusUpdateTemplates,
    config
  );
};

export const useGetDealStatusUpdateTemplate = (
  statusUpdateTemplateId: number,
  config?: any
) => {
  return useQueryWithResultSplitting(
    ['dealStatusUpdateTemplates', statusUpdateTemplateId],
    queries.getDealStatusUpdateTemplate,
    config
  );
};

export const useGetDealSchedule = (dealId: number, config?: any) => {
  return useQueryWithResultSplitting(
    ['dealEvents', dealId],
    queries.getDealSchedule,
    config
  );
};

export const useGetDealKeyMilestones = (dealId: number, config?: any) => {
  return useQueryWithResultSplitting(
    ['dealKeyMilestones', dealId],
    queries.getDealKeyMilestones,
    config
  );
};

export const useGetDealEvent = (
  dealId: number,
  dealEventId: number,
  config?: any
) => {
  return useQueryWithResultSplitting(
    ['dealEvents', dealId, dealEventId],
    queries.getDealEvent,
    config
  );
};

export const useGetDealDocuments = (
  dealId: number,
  folderId?: number,
  config?: any
) => {
  return useQueryWithResultSplitting(
    ['dealDocuments', dealId, folderId],
    queries.getDealDocuments,
    config
  );
};

export const useDownloadDealDocument = () => queries.downloadDealDocument;

export const useGetFolders = (folderIds: number[], config?: any) => {
  const key = folderIds.map((x) => x.toString()).join('/');
  return useQueryWithResultSplitting(
    ['dealDocuments', key],
    () => queries.getFolders(key, folderIds),
    config
  );
};

export const useGetDealStatuses = (config?: any) => {
  return useQueryWithResultSplitting(
    ['dealStatuses'],
    queries.getDealStatuses,
    config
  );
};

// export const useGetSchedules = (opportunityId: string, config?: any) => {
//   return useQueryWithResultSplitting(['schedules', opportunityId], queries.getSchedules, config);
// };

// export const useGetSchedule = (opportunityId: string, scheduleId: string, config?: any) => {
//   return useQueryWithResultSplitting(['schedule', opportunityId, scheduleId], queries.getSchedule, config);
// };

export const useGetChecklists = (config?: any) => {
  return useQueryWithResultSplitting(
    ['checklists'],
    queries.getChecklists,
    config
  );
};

export const useGetChecklist = (id: number, config?: any) => {
  return useQueryWithResultSplitting(
    ['checklists', id],
    queries.getChecklist,
    config
  );
};

export const useGetDealChecklists = (dealId: number, config?: any) => {
  return useQueryWithResultSplitting(
    ['dealChecklists', dealId],
    queries.getDealChecklists,
    config
  );
};

export const useGetChecklistItemStatuses = (config?: any) => {
  return useQueryWithResultSplitting(
    ['checklistsItemStatuses'],
    queries.getChecklistItemStatuses,
    config
  );
};

export const useGetTasks = (opportunityId: string, config?: any) => {
  return useQueryWithResultSplitting(
    ['tasks', opportunityId],
    queries.getTasks,
    config
  );
};

export const useGetTask = (taskId: string, config?: any) => {
  return useQueryWithResultSplitting(['task', taskId], queries.getTask, config);
};

export const useGetComments = (entityId: string, config?: any) => {
  return useQueryWithResultSplitting(
    ['comments', entityId],
    queries.getComments,
    config
  );
};

export const useGetReportingMetadata = (config?: any) => {
  return useQueryWithResultSplitting(
    ['reportingMetadata'],
    queries.getReportingMetadata,
    config
  );
};

export const useGetDocuments = (dealId: string, pathComponents?: string[]) => {
  return useQueryWithResultSplitting(
    ['documents', dealId, pathComponents],
    queries.getDocuments
  );
};

export const useGetPortfolioReports = (portfolioId: number, config?: any) => {
  return useQueryWithResultSplitting(
    ['reports'],
    queries.getPortfolioReports,
    config
  );
};

export const useGetReports = () => {
  return useQueryWithResultSplitting(['reports'], queries.getReports);
};

type UseGetReportParams = {
  portfolioId?: string;
  reportId: string;
};

export const useGetPortfolioReport = (reportId: number, config?: any) => {
  return useQueryWithResultSplitting(
    ['reports', reportId],
    queries.getPortfolioReport,
    config
  );
};

export const useGetReport = (reportId: number, config?: any) => {
  return useQueryWithResultSplitting(
    ['reports', reportId],
    queries.getReport,
    config
  );
};

export const useGetReportQueryResults = (query: string, config?: any) => {
  return useQueryWithResultSplitting(
    ['reportQueryResults', query],
    queries.getReportQueryResults,
    config
  );
};

export const useGetUpdateReports = (dealId: string, config?: any) => {
  return useQueryWithResultSplitting(
    ['updateReports', dealId],
    queries.getUpdateReports,
    config
  );
};

export const useGetTeam = (dealId: number, config?: any) => {
  return useQueryWithResultSplitting(['team', dealId], queries.getTeam, config);
};

export const useGetDealPartners = (dealId: number, config?: any) => {
  return useQueryWithResultSplitting(['partners', dealId], queries.getDealPartners, config);
};

export const useGetTeamMemberRoles = (config?: any) => {
  return useQueryWithResultSplitting(
    ['teamMemberRoles'],
    queries.getTeamMemberRoles,
    config
  );
};

export const useGetPartnerRoles = (config?: any) => {
  return useQueryWithResultSplitting(
    ['partnerRoles'],
    queries.getPartnerRoles,
    config
  );
};

export const useGetFirm = (id: number, config?: any) => {
  return useQueryWithResultSplitting(['firms', id], queries.getFirm, config);
};

export const useGetPipelineFirms = (id: number, config?: any) => {
  return useQueryWithResultSplitting(['pipelineFirms', id], queries.getPipelineFirms, config);
};

export const useGetDealPipelineFirms = (id: number, config?: any) => {
  return useQueryWithResultSplitting(['dealPipelineFirms', id], queries.getDealPipelineFirms, config);
};

export const useGetPipelineContacts = (id: number, config?: any) => {
  return useQueryWithResultSplitting(['pipelineContacts', id], queries.getPipelineContacts, config);
};

export const useGetPipelineModules = (id: number, config?: any) => {
  return useQueryWithResultSplitting(['pipelineModules', id], queries.getPipelineModules, config);
};

export const useGetModules = (config?: any) => {
  return useQueryWithResultSplitting(['modules'], queries.getModules, config);
};

export const useGetInteraction = (id: number, config?: any) => {
  return useQueryWithResultSplitting(['interactions', id], queries.getInteraction, config);
};

export const useGetDealPipelineContacts = (id: number, config?: any) => {
  return useQueryWithResultSplitting(
    ['dealPipelineContacts', id],
    queries.getDealPipelineContacts,
    config
  );
};

export const useGetContact = (contactId: number, config?: any) => {
  return useQueryWithResultSplitting(
    ['contact', contactId],
    queries.getContact,
    config
  );
};

export const useGetGCEstimates = (dealId: string, config?: any) => {
  return useQueryWithResultSplitting(
    ['gcEstimates', dealId],
    queries.getGCEstimates,
    config
  );
};

export const useGetGCEstimate = (gcEstimateId: string, config?: any) => {
  return useQueryWithResultSplitting(
    ['gcEstimate', gcEstimateId],
    queries.getGCEstimate,
    config
  );
};

export const useGetAllApprovals = (opportunityId: string, config?: any) => {
  return useQueryWithResultSplitting(
    ['AllApprovals', opportunityId],
    queries.getAllApprovals,
    config
  );
};

// export const useGetIcDashboard = (config?: any) => {
//   return useQueryWithResultSplitting('icDashboard', queries.getIcDashboard, config);
// };

export const useGetChecklistCategories = (
  checklistId: number,
  config?: any
) => {
  return useQueryWithResultSplitting(
    ['checklistCategories', checklistId],
    queries.getChecklistCategories,
    config
  );
};

export const useGetStates = (config?: any) => {
  return useQueryWithResultSplitting(['states'], queries.getStates, config);
};

export const useGetRegions = (config?: any) => {
  return useQueryWithResultSplitting(['regions'], queries.getRegions, config);
};

export const useGetMarkets = (config?: any) => {
  return useQueryWithResultSplitting(['markets'], queries.getMarkets, config);
};

export const useGetMarketTypes = (config?: any) => {
  return useQueryWithResultSplitting(
    ['marketTypes'],
    queries.getMarketTypes,
    config
  );
};

export const useGetMarketSegments = (config?: any) => {
  return useQueryWithResultSplitting(
    ['marketSegments'],
    queries.getMarketSegments,
    config
  );
};

export const useGetSubmarkets = (config?: any) => {
  return useQueryWithResultSplitting(
    ['submarkets'],
    queries.getSubmarkets,
    config
  );
};

export const useGetMsas = (config?: any) => {
  return useQueryWithResultSplitting(['msas'], queries.getMsas, config);
};

export const useGetProductTypes = (config?: any) => {
  return useQueryWithResultSplitting(
    ['productTypes'],
    queries.getProductTypes,
    config
  );
};

export const useGetEstimateTypes = (config?: any) => {
  return useQueryWithResultSplitting(
    ['estimateTypes'],
    queries.getEstimateTypes,
    config
  );
};
export const useGetBuildingTypes = (config?: any) => {
  return useQueryWithResultSplitting(
    ['buildingTypes'],
    queries.getBuildingTypes,
    config
  );
};

export const useGetConstructionTypes = (config?: any) => {
  return useQueryWithResultSplitting(
    ['constructionTypes'],
    queries.getConstructionTypes,
    config
  );
};

export const useGetStructures = (config?: any) => {
  return useQueryWithResultSplitting(
    ['structures'],
    queries.getStructures,
    config
  );
};

export const useGetDealTypes = (config?: any) => {
  return useQueryWithResultSplitting(
    ['dealTypes'],
    queries.getDealTypes,
    config
  );
};

export const useGetAssetClasses = (config?: any) => {
  return useQueryWithResultSplitting(
    ['assetClasses'],
    queries.getAssetClasses,
    config
  );
};

export const useGetHoaTypes = (config?: any) => {
  return useQueryWithResultSplitting(['hoaTypes'], queries.getHoaTypes, config);
};

export const useGetFunds = (config?: any) => {
  return useQueryWithResultSplitting(['funds'], queries.getFunds, config);
};

export const useGetFundEntities = (config?: any) => {
  return useQueryWithResultSplitting(
    ['portfolios'],
    queries.getFundEntities,
    config
  );
};

export const useGetPriorityLevels = (config?: any) => {
  return useQueryWithResultSplitting(
    ['priorityLevels'],
    queries.getPriorityLevels,
    config
  );
};

export const useGetPipelines = (config?: any) => {
  return useQueryWithResultSplitting(
    ['pipelines'],
    queries.getPipelines,
    config
  );
};

export const useGetPipeline = (id: number, config?: any) => {
  return useQueryWithResultSplitting(
    ['pipelines', id],
    queries.getPipeline,
    config
  );
};

export const useGetBudgetOffsetRuleFilters = (config?: any) => {
  return useQueryWithResultSplitting(
    ['budgetOffsetRuleFilters'],
    queries.getBudgetOffsetRuleFilters,
    config
  );
};

export const useGetDealAttributes = (config?: any) => {
  return useQueryWithResultSplitting(
    ['dealAttributes'],
    queries.getDealAttributes,
    config
  );
};

export const useGetForecastRules = (config?: any) => {
  return useQueryWithResultSplitting(
    ['forecastRules'],
    queries.getForecastRules,
    config
  );
};

export const useGetForecastRule = (id: number, config?: any) => {
  return useQueryWithResultSplitting(
    ['forecastRules', id],
    queries.getForecastRule,
    config
  );
};

export const useGetPipelineDealStages = (id: number, config?: any) => {
  return useQueryWithResultSplitting(['stages', id], queries.getPipelineDealStages, config);
};

export const useGetAnalysisScenarios = (config?: any) => {
  return useQueryWithResultSplitting(
    ['analysisScenarios'],
    queries.getAnalysisScenarios,
    config
  );
};

export const useGetAnalysisTypes = (config?: any) => {
  return useQueryWithResultSplitting(
    ['analysisTypes'],
    queries.getAnalysisTypes,
    config
  );
};

export const useGetMetrics = (config?: any) => {
  return useQueryWithResultSplitting(['metrics'], queries.getMetrics, config);
};

export const useGetTextMetrics = (config?: any) => {
  return useQueryWithResultSplitting(['textMetrics'], queries.getTextMetrics, config);
};

export const useGetCurrentOrganizationInfo = (config?: any) => {
  return useQueryWithResultSplitting(
    ['organization'],
    queries.getCurrentOrganizationInfo,
    config
  );
};

export const useGetOrganizationUserAccess = (config?: any) => {
  return useQueryWithResultSplitting(
    ['organizationUserAccess'],
    queries.getOrganizationUserAccess,
    config
  );
};

export const useGetOrganizationUsers = (config?: any) => {
  return useQueryWithResultSplitting(
    ['organizationUsers'],
    queries.getOrganizationUsers,
    config
  );
};

export const useGetOrganizationGroups = (config?: any) => {
  return useQueryWithResultSplitting(
    ['organizationGroups'],
    queries.getOrganizationGroups,
    config
  );
};

export const useGetOrganizationGroup = (groupId: number, config?: any) => {
  return useQueryWithResultSplitting(
    ['organizationGroups', groupId],
    queries.getOrganizationGroup,
    config
  );
};

export const useGetPermissionRoles = (config?: any) => {
  return useQueryWithResultSplitting(
    ['permissionRoles'],
    queries.getPermissionRoles,
    config
  );
};

export const useGetPortfolioView = (
  portfolioId: number,
  viewId: number,
  showArchivedDeals: boolean,
  config?: any
) => {
  return useQueryWithResultSplitting(
    ['portfolioView', portfolioId, viewId, showArchivedDeals],
    queries.getPortfolioView,
    config
  );
};

export const useGetPortfolioViewPaginated = (
  portfolioId: number,
  viewId: number,
  showArchivedDeals: boolean,
  limit: number,
  offset: number,
  config?: any
) => {
  const queryKey: [string, number, number, boolean, number, number] = ['portfolioView', portfolioId, viewId, showArchivedDeals, limit, offset];
  
  const configWithVariables = {
    ...config,
    variables: {
      ...config?.variables,
      portfolioId,
      viewId,
      showArchivedDeals,
      limit,
      offset,
    },
  };

  return useQueryWithResultSplitting(queryKey, queries.getPortfolioViewPaginated, configWithVariables);
};

export const useGetPortfolioViewColumns = (
  portfolioId: number,
  viewId: number,
  config?: any
) => {
  return useQueryWithResultSplitting(
    ['portfolioView', portfolioId, viewId],
    queries.getPortfolioViewColumns,
    config
  );
};

export const useGetPortfolioViews = (portfolioId: number, config?: any) => {
  return useQueryWithResultSplitting(
    ['portfolioViews', portfolioId],
    queries.getPortfolioViews,
    config
  );
};

export const useGetViewColumnOptions = (config?: any) => {
  return useQueryWithResultSplitting(
    ['viewColumnOptions'],
    queries.getViewColumnOptions,
    config
  );
};

export const useGetEvent = (id: number, config?: any) => {
  return useQueryWithResultSplitting(['events', id], queries.getEvent, config);
};

export const useGetEvents = (config?: any) => {
  return useQueryWithResultSplitting(['events'], queries.getEvents, config);
};

export const useGetDealAnalyses = (dealId: number, config?: any) => {
  return useQueryWithResultSplitting(
    ['dealAnalyses', dealId],
    queries.getDealAnalyses,
    config
  );
};

export const useGetDealAnalysis = (
  dealId: number,
  analysisId: number,
  config?: any
) => {
  return useQueryWithResultSplitting(
    ['dealAnalysis', dealId, analysisId],
    queries.getDealAnalysis,
    config
  );
};

export const useGetDealCurrentAnalysis = (dealId: number, config?: any) => {
  return useQueryWithResultSplitting(
    ['dealCurrentAnalysis', dealId],
    queries.getDealCurrentAnalysis,
    config
  );
};

export const useGetDealMasterBudgetOverview = (
  dealId: number,
  fkGlAccountTree: number,
  config?: any
) => {
  return useQueryWithResultSplitting(
    ['dealMasterBudgetOverview', dealId, fkGlAccountTree],
    queries.getDealMasterBudgetOverview,
    config
  );
};

export const useGetDealTransactions = (dealId: number, config?: any) => {
  return useQueryWithResultSplitting(
    ['dealTransactions', dealId],
    queries.getDealTransactions,
    config
  );
};

export const useGetDealTransaction = (
  dealId: number,
  transactionId: number,
  config?: any
) => {
  return useQueryWithResultSplitting(
    ['dealTransactions', dealId, transactionId],
    queries.getDealTransaction,
    config
  );
};

export const useGetDealRevisions = (dealId: number, config?: any) => {
  return useQueryWithResultSplitting(
    ['dealRevisions', dealId],
    queries.getDealRevisions,
    config
  );
};

export const useGetDealContracts = (dealId: number, config?: any) => {
  return useQueryWithResultSplitting(
    ['dealContracts', dealId],
    queries.getDealContracts,
    config
  );
};

export const useGetDealContract = (
  dealId: number,
  contractId: number,
  config?: any
) => {
  return useQueryWithResultSplitting(
    ['dealContracts', dealId, contractId],
    queries.getDealContract,
    config
  );
};

export const useGetDealPublishedCapitalForecasts = (
  dealId: number,
  config?: any
) => {
  return useQueryWithResultSplitting(
    ['dealPublishedCapitalForecasts', dealId],
    queries.getDealPublishedCapitalForecasts,
    config
  );
};

export const useGetDealPublishedMasterBudgetOverview = (
  dealId: number,
  publishedCapitalForecastId: number,
  fkGlAccountTree: number,
  config?: any
) => {
  return useQueryWithResultSplitting(
    [
      'dealPublishedMasterBudgetOverview',
      dealId,
      publishedCapitalForecastId,
      fkGlAccountTree,
    ],
    queries.getDealPublishedMasterBudgetOverview,
    config
  );
};

export const useGetDealMasterBudgetData = (dealId: number, config?: any) => {
  return useQueryWithResultSplitting(
    ['masterBudget', dealId],
    queries.getDealMasterBudgetData,
    config
  );
};

export const useGetDealTransactionFormOptions = (
  dealId: number,
  accountTreeId: number,
  config?: any
) => {
  return useQueryWithResultSplitting(
    ['dealTransactionFormOptions', dealId, accountTreeId],
    queries.getDealTransactionFormOptions,
    config
  );
};


export const useGetBudgetRevision = (
  dealId: number,
  revisionId: number,
  accountTreeId: number,
  config?: any
) => {
  return useQueryWithResultSplitting(
    ['dealRevisions', dealId, revisionId, accountTreeId],
    queries.getBudgetRevision,
    config
  );
};

export const useGetDealAccountForecasts = (
  dealId: number,
  accountIds: number[],
  start?: string,
  end?: string,
  excludeTransactionId?: number,
  config?: any
) => {
  return useQueryWithResultSplitting(
    [
      'dealAccountForecasts',
      dealId,
      accountIds,
      start,
      end,
      excludeTransactionId,
    ],
    queries.getDealAccountForecasts,
    config
  );
};

export const useGetPublishedScheduleInstances = (id: number, config?: any) => {
  return useQueryWithResultSplitting(
    ['publishedScheduleInstances', id],
    queries.getPublishedScheduleInstances,
    config
  );
};

export const useGetPublishedScheduleInstance = (
  id: number,
  fkPublishedScheduleInstance: number,
  config?: any
) => {
  return useQueryWithResultSplitting(
    ['publishedScheduleInstances', id, fkPublishedScheduleInstance],
    queries.getPublishedScheduleInstance,
    config
  );
};

export const useGetDealBuildings = (id: number, config?: any) => {
  return useQueryWithResultSplitting(
    ['dealBuildings', id],
    queries.getDealBuildings,
    config
  );
};

export const useGetDealBuilding = (
  id: number,
  buildingId: number,
  config?: any
) => {
  return useQueryWithResultSplitting(
    ['dealBuildings', id, buildingId],
    queries.getDealBuilding,
    config
  );
};

export const useGetDealBonds = (id: number, config?: any) => {
  return useQueryWithResultSplitting(
    ['dealBonds', id],
    queries.getDealBonds,
    config
  );
};

export const useGetDealBond = (id: number, bondId: number, config?: any) => {
  return useQueryWithResultSplitting(
    ['dealBonds', id, bondId],
    queries.getDealBond,
    config
  );
};

export const useGetDealGcEstimates = (id: number, config?: any) => {
  return useQueryWithResultSplitting(
    ['dealGcEstimates', id],
    queries.getDealGcEstimates,
    config
  );
};

export const useGetGcEstimateTypes = (config?: any) => {
  return useQueryWithResultSplitting(
    ['gcEstimateTypes'],
    queries.getGcEstimateTypes,
    config
  );
};

export const useGetDealGcEstimate = (
  id: number,
  gcEstimateId: number,
  config?: any
) => {
  return useQueryWithResultSplitting(
    ['dealGcEstimates', id, gcEstimateId],
    queries.getDealGcEstimate,
    config
  );
};

export const useGetDealPinnedMetrics = (id: number, config?: any) => {
  return useQueryWithResultSplitting(
    ['deal', id, 'pinnedMetrics'],
    queries.getDealPinnedMetrics,
    config
  );
};

export const useGetAccountTypes = (config?: any) => {
  return useQueryWithResultSplitting(
    ['accountTypes'],
    queries.getAccountTypes,
    config
  );
};

export const useGetContingencyCategories = (config?: any) => {
  return useQueryWithResultSplitting(
    ['contingencyCategories'],
    queries.getContingencyCategories,
    config
  );
};

export const useGetAccountCategories = (config?: any) => {
  return useQueryWithResultSplitting(
    ['accountCategories'],
    queries.getAccountCategories,
    config
  );
};

export const useGetAccounts = (config?: any) => {
  return useQueryWithResultSplitting(['accounts'], queries.getAccounts, config);
};

export const useGetAccount = (id: number, config?: any) => {
  return useQueryWithResultSplitting(
    ['accounts', id],
    queries.getAccount,
    config
  );
};

export const useGetAccountTrees = (config?: any) => {
  return useQueryWithResultSplitting(
    ['accountTrees'],
    queries.getAccountTrees,
    config
  );
};

export const useGetAccountTree = (id: number, config?: any) => {
  return useQueryWithResultSplitting(
    ['accountTrees', id],
    queries.getAccountTree,
    config
  );
};

export const useGetDealChangeItems = (id: number, config?: any) => {
  return useQueryWithResultSplitting(
    ['dealChangeItems', id],
    queries.getDealChangeItems,
    config
  );
};

export const useGetDealChangeEvent = (
  id: number,
  changeEventId: number,
  config?: any
) => {
  return useQueryWithResultSplitting(
    ['dealChangeEvents', id, changeEventId],
    queries.getDealChangeEvent,
    config
  );
};

export const useGetDealChangeOrderPackages = (id: number, config?: any) => {
  return useQueryWithResultSplitting(
    ['dealChangeOrderPackages', id],
    queries.getDealChangeOrderPackages,
    config
  );
};

export const useGetDealChangeOrderPackage = (
  id: number,
  changeOrderPackageId: number,
  config?: any
) => {
  return useQueryWithResultSplitting(
    ['dealChangeOrderPackages', id, changeOrderPackageId],
    queries.getDealChangeOrderPackage,
    config
  );
};

export const useGetDealPotentialChangeOrders = (id: number, config?: any) => {
  return useQueryWithResultSplitting(
    ['dealPotentialChangeOrders', id],
    queries.getDealPotentialChangeOrders,
    config
  );
};

export const useGetDealPotentialChangeOrder = (
  id: number,
  potentialChangeOrderId: number,
  config?: any
) => {
  return useQueryWithResultSplitting(
    ['dealPotentialChangeOrders', id, potentialChangeOrderId],
    queries.getDealPotentialChangeOrder,
    config
  );
};

export const useGetChangeOrderStatuses = (config?: any) => {
  return useQueryWithResultSplitting(
    ['changeOrderStatuses'],
    queries.getChangeOrderStatuses,
    config
  );
};

export const useGetPotentialChangeOrderTypes = (config?: any) => {
  return useQueryWithResultSplitting(
    ['potentialChangeOrderTypes'],
    queries.getPotentialChangeOrderTypes,
    config
  );
};

export const useGetChangeEventTypes = (config?: any) => {
  return useQueryWithResultSplitting(
    ['changeEventTypes'],
    queries.getChangeEventTypes,
    config
  );
};

export const useGetChangeEventReasons = (config?: any) => {
  return useQueryWithResultSplitting(
    ['changeEventReasons'],
    queries.getChangeEventReasons,
    config
  );
};

export const useGetApprovalPackage = (id: number, config?: any) => {
  return useQueryWithResultSplitting(
    ['approvalPackages', id],
    queries.getApprovalPackage,
    config
  );
};

export const useGetApprovalPackages = (config?: any) => {
  return useQueryWithResultSplitting(
    ['approvalPackages'],
    queries.getApprovalPackages,
    config
  );
};

export const useGetDealApprovalPackages = (id: number, config?: any) => {
  return useQueryWithResultSplitting(
    ['dealApprovalPackages', id],
    queries.getDealApprovalPackages,
    config
  );
};

export const useGetPublishedApprovalPackage = (id: number, config?: any) => {
  return useQueryWithResultSplitting(
    ['publishedApprovalPackages', id],
    queries.getPublishedApprovalPackage,
    config
  );
};

export const useGetApprovalPackageStatuses = (config?: any) => {
  return useQueryWithResultSplitting(
    ['approvalPackageStatuses'],
    queries.getApprovalPackageStatuses,
    config
  );
};

export const useGetApprovalPackageReviewerStatuses = (config?: any) => {
  return useQueryWithResultSplitting(
    ['approvalPackageReviewerStatuses'],
    queries.getApprovalPackageReviewerStatuses,
    config
  );
};

export const useGetApprovalPackageTypes = (config?: any) => {
  return useQueryWithResultSplitting(
    ['approvalPackageTypes'],
    queries.getApprovalPackageTypes,
    config
  );
};

export const useGetApprovalPackageType = (id: number, config?: any) => {
  return useQueryWithResultSplitting(
    ['approvalPackageTypes', id],
    queries.getApprovalPackageType,
    config
  );
};

export const useGetApprovalPackageTableCellEntryOptions = (config?: any) => {
  return useQueryWithResultSplitting(
    ['approvalPackageTableCellEntryOptions'],
    queries.getApprovalPackageTableCellEntryOptions,
    config
  );
};

export const useGetApprovalPackageTemplate = (id: number, config?: any) => {
  return useQueryWithResultSplitting(
    ['approvalPackageTemplates', id],
    queries.getApprovalPackageTemplate,
    config
  );
};

export const useGetApprovalPackageTemplates = (config?: any) => {
  return useQueryWithResultSplitting(
    ['approvalPackageTemplates'],
    queries.getApprovalPackageTemplates,
    config
  );
};

export const useGetLabels = (config?: any) => {
  return useQueryWithResultSplitting(
    ['labels'],
    queries.getLabels,
    config
  );
};

export const useGetReportTypes = (config?: any) => {
  return useQueryWithResultSplitting(
    ['reportTypes'],
    queries.getReportTypes,
    config
  );
};

export const useGetInvestmentTypes = (config?: any) => {
  return useQueryWithResultSplitting(
    ['investmentTypes'],
    queries.getInvestmentTypes,
    config
  );
};

export const useGetCrmStatuses = (config?: any) => {
  return useQueryWithResultSplitting(
    ['crmStatuses'],
    queries.getCrmStatuses,
    config
  );
};

export const useGetFirmTypes = (config?: any) => {
  return useQueryWithResultSplitting(
    ['firmTypes'],
    queries.getFirmTypes,
    config
  );
};

export const useGetInteractionTypes = (config?: any) => {
  return useQueryWithResultSplitting(
    ['interactionTypes'],
    queries.getInteractionTypes,
    config
  );
};

export const useGetTransactionPeriodSummaries = (id: number, fkGlAccount: number, period: string, config?: any) => {
  return useQueryWithResultSplitting(
    ['transactionPeriodSummaries', id, fkGlAccount, period],
    queries.getTransactionPeriodSummaries,
    config
  )
}

export const useGetDealArtifacts = (id: number, config?: any) => {
  return useQueryWithResultSplitting(
    ['deal', id, 'artifacts'],
    queries.getDealArtifacts,
    config
  )
}

export const useGetArtifactTypes = (config?: any) => {
  return useQueryWithResultSplitting(
    ['artifactTypes'],
    queries.getArtifactTypes,
    config
  );
};

export const useGetContactAttachments = (id: number, config?: any) => {
  return useQueryWithResultSplitting(
    ['contact', id, 'attachments'],
    queries.getContactAttachments,
    config
  )
}

export const useGetFirmAttachments = (id: number, config?: any) => {
  return useQueryWithResultSplitting(
    ['firm', id, 'attachments'],
    queries.getFirmAttachments,
    config
  )
}

export const useGetInterestRates = (config?: any) => {
  return useQueryWithResultSplitting(
    ['interestRates'],
    queries.getInterestRates,
    config
  );
};

export const useGetForecastInterestAccountStatus = (
  dealId: number, 
  config?: any
) => {
  return useQueryWithResultSplitting(
    ['forecastInterestAccountStatus', dealId],
    queries.getForecastInterestAccountStatus,
    config
  );
};

export const useGetLatestPipelineAnalysisMetricDistribution = (analysisMetricId: number, config?: any) => {
    return useQueryWithResultSplitting(
      ['latestPipelineAnalysisMetrics', analysisMetricId],
      queries.getLatestPipelineAnalysisMetricDistribution,
      config
    );
};

export const useGetCategoricalDealCharts = (pipelineId: number, birthDate: string, dealTypeIds: number[], config?: any) => {
    return useQueryWithResultSplitting(
      ['categoricalDealCharts', pipelineId, birthDate, dealTypeIds],
      queries.getCategoricalDealCharts,
      config
  );
};
  
export const useGetNumericalDealCharts = (pipelineId: number, birthDate: string, dealTypeIds: number[], config?: any) => {
    return useQueryWithResultSplitting(
      ['numericalDealCharts', pipelineId, birthDate, dealTypeIds],
      queries.getNumericalDealCharts,
      config
    );
};
  
export const useGetAnalyticsSummary = (pipelineId: number, birthDate: string, dealTypeIds: number[], config?: any) => {
    return useQueryWithResultSplitting(
      ['analyticsSummary', pipelineId, birthDate, dealTypeIds],
      queries.getAnalyticsSummary,
      config
    );
};
  
export const useGetDealQuarterEventsChart = (pipelineId: number, fkEvent: number, yearsBack: number, dealTypeIds: number[], config?: any) => {
    return useQueryWithResultSplitting(
      ['dealQuarterEventsChart', pipelineId, fkEvent, yearsBack, dealTypeIds],
      queries.getDealQuarterEventsChart,
      config
    );
};
  
export const useGetFundChart = (pipelineId: number, birthDate: string, dealTypeIds: number[], config?: any) => {
    return useQueryWithResultSplitting(
      ['fundChart', pipelineId, birthDate, dealTypeIds],
      queries.getFundChart,
      config
    );
};

export const useGetMarketView = (pipelineId: number, birthDate: string, includeArchivedDeals: boolean, marketStatistic: MarketStatisticEnum, config?: any) => {
  return useQueryWithResultSplitting(
    ['marketView', pipelineId, birthDate, includeArchivedDeals, marketStatistic],
    queries.getMarketView,
    config
  );
};