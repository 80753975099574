import history from 'browserHistory';
import { useEffect, useMemo } from 'react';
import { queryStringToObject, addToQueryString } from 'shared/utils/url';

function set<T extends QueryParamValue>(param: string, value: T) {
  history.replace({
    pathname: history.location.pathname,
    search: addToQueryString(history.location.search, {
      [param]: value?.toString(),
    }),
  });
}

function get(param: string) {
  const value = queryStringToObject(history.location.search)[param];
  if (value == null) return undefined;
  else {
    return value;
  }
}

type QueryParamValue = string | string[] | boolean | number | undefined;

type QueryParamState<T extends QueryParamValue> = [
  T | undefined,
  (value: T) => void
];

export function useQueryParamState<T extends QueryParamValue>(
  param: string,
  defaultValue: T
): QueryParamState<T> {
  const value = get(param);
  useEffect(() => {
    if (value == undefined && defaultValue != undefined) {
      set(param, defaultValue.toString());
    }
  }, [value, defaultValue]);
  const setValue = (value: T) => set(param, value);
  const val: T | undefined = useMemo(() => {
    if (typeof defaultValue == 'boolean') {
      const result: any = value == 'true';
      return result as T;
    } else if (typeof defaultValue == 'number') {
      return Number(value) as T;
    } else {
      return value as T;
    }
  }, [value, defaultValue]);
  return [val == undefined ? defaultValue : val, setValue];
}
