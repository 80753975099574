import React, { FunctionComponent } from 'react';
import { FormModal, PageDetails } from 'shared/components';
import {
  StatusUpdateValues,
  statusUpdateSchema,
} from 'shared/components/card/StatusCard';
import {
  CreateStatusUpdateInput,
  CreateStatusUpdateSectionInput,
} from 'shared/hooks/api/graphql/generated';
import { useNotification } from 'shared/components/notifications';
import { useCreateStatusUpdate } from 'shared/hooks/api';
import StatusUpdateForm from './StatusUpdateForm';
import { PartialNullable } from 'shared/utils/utilityTypes';

interface CreateStatusModalUpdateProps {
  oppId: number;
  onClose: () => void;
  initialValues?: PartialNullable<StatusUpdateValues>;
}

const CreateStatusUpdateModal: FunctionComponent<CreateStatusModalUpdateProps> = ({
  oppId,
  onClose,
  initialValues = { sections: [{ title: 'Details' }], labels: [] },
}) => {
  const notify = useNotification();
  const [createStatusUpdate, { status }] = useCreateStatusUpdate({
    throwOnError: true,
  });
  return (
    <FormModal<StatusUpdateValues>
      title={'New Status Update'}
      enableReinitialize={false}
      submitTitle={'Create Status Update'}
      onClose={onClose}
      isWorking={status == 'loading'}
      initialValues={initialValues}
      handleSubmit={async (values) => {
        const message = JSON.stringify(values.message);
        const sections: CreateStatusUpdateSectionInput[] = values.sections?.map(
          (section) => {
            return {
              title: section.title,
              content: section.content && JSON.stringify(section.content),
            };
          }
        );
        const input: CreateStatusUpdateInput = {
          fkDeal: oppId,
          title: values.title,
          message: message,
          fkDealStatus: values.status.value,
          sections: sections,
          labelIds: values.labels.map(x => x.value),
        };
        try {
          const result = await createStatusUpdate(input);
          if (result.errors) {
            const permissionError = result.errors.find(
              (x) => x.extensions.code === 'FORBIDDEN'
            );
            if (permissionError) {
              notify({
                duration: 10000,
                title: 'Access Denied',
                message: permissionError.message,
                variant: 'danger',
              });
            } else {
              notify({
                duration: 5000,
                title: 'Uh-oh!',
                message:
                  'An unknown error occurred while creating this status update!',
                variant: 'danger',
              });
            }
          } else {
            notify({
              message: 'Status update created.',
            });
            onClose();
          }
        } catch {
          notify({
            variant: 'danger',
            message: 'An error occurred while creating this status update.',
          });
        }
      }}
      validationSchema={statusUpdateSchema}
      size={'lg'}
    >
      {(props) => (
        <PageDetails>
          <StatusUpdateForm form={props} />
        </PageDetails>
      )}
    </FormModal>
  );
};

export default CreateStatusUpdateModal;
