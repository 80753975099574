import React, { FunctionComponent } from 'react';

type DebugProps = { state: any };

const Debug: FunctionComponent<DebugProps> = ({ state }) => {
  return (
    <pre
      style={{
        margin: '10px 0',
        padding: 10,
        background: '#eee',
      }}
    >
      {JSON.stringify(state, null, 2)}
    </pre>
  );
};

export default Debug;
