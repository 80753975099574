import React, { FunctionComponent, useMemo, useState } from 'react';
import {
  Button,
  HorizontalSeparator,
  Input,
  Label,
  Tooltip,
} from 'shared/components';
import styled from 'styled-components';
import CreateLabelModal from './CreateLabelModal';

export type LabelOption = {
  value: number;
  label: string;
  color?: string;
};

interface AddLabelButtonProps {
  options: LabelOption[];
  onSelected: (opt: LabelOption) => void;
}

const AddLabelButton: FunctionComponent<AddLabelButtonProps> = ({
  options,
  onSelected,
}) => {
  const [isShowingAddLabel, setIsShowingAddLabel] = useState(false);
  const [isShowingCreateForm, setIsShowingCreateForm] = useState(false);

  const [search, setSearch] = useState('');
  const searchResults = useMemo(() => {
    return search != ''
      ? options.filter((x) =>
          x.label.toLowerCase().includes(search.toLowerCase())
        )
      : [];
  }, [options, search]);

  const resetForm = () => {
    setIsShowingAddLabel(false);
    setIsShowingCreateForm(false);
    setSearch('');
  };

  return (
    <Tooltip
      onClickOutside={resetForm}
      placement={'bottom-start'}
      content={
        <div style={{ minWidth: '200px' }}>
          {isShowingCreateForm ? (
            <CreateLabelModal
              onClose={() => setIsShowingCreateForm(false)}
              onSuccess={(label) => {
                resetForm();
                onSelected(label);
              }}
              initialValues={{ name: search }}
            />
          ) : (
            <React.Fragment>
              <Input
                onChange={(e) => setSearch(e.target.value)}
                value={search}
                placeholder={'Enter a label name...'}
              />
              {search != '' && <HorizontalSeparator margin={10} />}
              <LabelOptionsWrapper>
                {searchResults.length > 0
                  ? searchResults.map((x) => (
                      <Label
                        onClick={() => {
                          resetForm();
                          onSelected(x);
                        }}
                        key={x.value}
                        name={x.label}
                        color={x.color}
                        style={{ width: '100%' }}
                      />
                    ))
                  : search != '' && (
                      <Button
                        onClick={() => setIsShowingCreateForm(true)}
                      >{`Create a new "${search}" label`}</Button>
                    )}
              </LabelOptionsWrapper>
            </React.Fragment>
          )}
        </div>
      }
      interactive={true}
      visible={isShowingAddLabel}
      light={true}
    >
      <Button
        variant={'outline'}
        icon={'plus'}
        onClick={() => setIsShowingAddLabel((x) => !x)}
        slim={true}
      >
        Add Label
      </Button>
    </Tooltip>
  );
};

const LabelOptionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  & > *:not(:last-child) {
    margin-bottom: 5px;
  }
`;

export default AddLabelButton;
