import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FunctionComponent, useContext, useMemo, useState } from 'react';
import { Route, useHistory, useParams, useRouteMatch } from 'react-router-dom';
import { parseISO, formatDistanceToNow } from 'date-fns';
import {
  Avatar,
  Badge,
  CenteredSpinner,
  Dialog,
  DropdownButton,
  DropdownItem,
  Label,
  MenuSeparator,
  Modal,
  PageDetails,
  Section,
  TextEditedContent,
} from 'shared/components';
import { StatusUpdateValues, StatusVariant } from 'shared/components/card/StatusCard';
import { useDeleteStatusUpdate, useGetDealStatusUpdate } from 'shared/hooks/api';
import { font } from 'shared/utils/styles';
import styled, { ThemeContext } from 'styled-components';
import { formatDate } from 'shared/utils/formatting/formatters';
import StatusUpdateComments from '../SummaryDashboard/StatusUpdates/StatusUpdateComments';
import UpdateStatusUpdateModal from '../SummaryDashboard/modals/UpdateStatusUpdateModal';
import { useNotification } from 'shared/components/notifications';
import CreateStatusUpdateModal from '../SummaryDashboard/modals/CreateStatusUpdateModal';
import { PartialNullable } from 'shared/utils/utilityTypes';
import { parseTextEditedValue } from 'shared/components/form/textEditor/TextEditor';

interface RoutedStatusUpdateDetailsProps {
  onClose: () => void;
}

type RouteParams = {
  oppId: string;
  statusUpdateId: string;
};

export const RoutedStatusUpdateDetails: FunctionComponent<RoutedStatusUpdateDetailsProps> = ({
  onClose,
}) => {
  const { oppId, statusUpdateId } = useParams<RouteParams>();
  return <StatusUpdateDetails oppId={Number(oppId)} statusUpdateId={Number(statusUpdateId)} onClose={onClose} />;
};

interface StatusUpdateDetailsProps {
  onClose: () => void;
  oppId: number;
  statusUpdateId: number;
  readonly?: boolean;
}

const StatusUpdateDetails: FunctionComponent<StatusUpdateDetailsProps> = ({
  oppId,
  statusUpdateId,
  readonly = false,
  onClose,
}) => {
  const themeContext = useContext(ThemeContext);
  const { data, error, isFetching, refetch, status } = useGetDealStatusUpdate(
    oppId,
    statusUpdateId
  );

  const createdAtDate = useMemo(() => {
    if (data?.deal?.statusUpdate?.createdAt) {
      const createAtDate = parseISO(
        data.deal.statusUpdate.createdAt || Date().toString()
      );
      const date = formatDate(createAtDate);
      const relativeDate = formatDistanceToNow(createAtDate, {
        addSuffix: true,
      });
      return relativeDate + " | " + date ;
    } else {
      return '';
    }
  }, [data]);

  const match = useRouteMatch();
  const history = useHistory();

  const [isEditing, setIsEditing] = useState(false);
  const [isShowingDeleteDialog, setIsShowingDeleteDialog] = useState(false);
  const [isShowingNewStatusUpdateModal, setIsShowingNewStatusUpdateModal] = useState(false);

  const initialValues: PartialNullable<StatusUpdateValues> = useMemo(() => {
    if (data?.deal?.statusUpdate) {
      return {
        title: data.deal.statusUpdate.title,
        message: parseTextEditedValue(data.deal.statusUpdate.message),
        labels: data.deal.statusUpdate.labels?.map(label => {
          return {
            value: label.id,
            label: label.name,
            color: label.color,
          };
        }) || [],
        sections: data.deal.statusUpdate.sections?.map(section => {
          return {
            title: section.title,
            content: parseTextEditedValue(section.content),
          }
        }) || [],
      }
    } else {
      return {}
    }
  }, [data])

  const labels = useMemo(() => {
    return data?.deal?.statusUpdate?.labels || [];
  }, [data]);

  return (
    <React.Fragment>
      {!isEditing ? (
        <Modal
          title={`Status Update${data?.deal?.name ? ` - ${data.deal.name}` : ''}`}
          isOpen={true}
          onClose={onClose}
          slim={true}
          tools={[
            <DropdownButton key={'actions-menu'} icon={'chevron-down'} slim={true} variant={'simple'} anchor={'right'} disabled={readonly}>
              <DropdownItem icon={'pencil'} onClick={() => setIsEditing(true)}>Edit</DropdownItem>
              <DropdownItem icon={'file-import'} onClick={() => setIsShowingNewStatusUpdateModal(true)}>New Update from this one...</DropdownItem>
              <MenuSeparator/>
              <DropdownItem icon={<FontAwesomeIcon icon={['fal', 'trash']} color={themeContext.danger}/>} onClick={() => setIsShowingDeleteDialog(true)}>Delete</DropdownItem>
            </DropdownButton>
          ]}
        >
          <PageDetails>
            {data?.deal?.statusUpdate && status != 'loading' ? (
              <StatusUpdateWrapper>
                <StatusUpdateHeaderWrapper>
                  <StatusTitleWrapper>
                    <StatusTitle>{data.deal.statusUpdate.title}</StatusTitle>
                  </StatusTitleWrapper>
                  <StatusTitleDetailWrapper>
                    <Avatar
                      name={data.deal.statusUpdate.createdBy.displayName}
                      size={25}
                    />
                    <StatusAvatarText>
                      {data.deal.statusUpdate.createdBy.displayName}
                    </StatusAvatarText>
                    <Separator />
                    <StatusIndicator
                      variant={
                        data.deal.statusUpdate.status.color as StatusVariant
                      }
                    >
                      <FontAwesomeIcon
                        icon={['fas', 'circle']}
                        fixedWidth={true}
                      />
                    </StatusIndicator>
                    <StatusName>
                      {data.deal.statusUpdate.status.name}
                    </StatusName>
                  </StatusTitleDetailWrapper>
                  <StatusDateWrapper>
                    <StatusName>{'Posted:'}</StatusName>
                    <StatusModifiedText>{createdAtDate}</StatusModifiedText>
                  </StatusDateWrapper>
                  <StatusLabelsWrapper>
                      {labels.map(x => (
                        <Label key={x.id} name={x.name} color={x.color || undefined}/>
                      ))}
                  </StatusLabelsWrapper>
                </StatusUpdateHeaderWrapper>
                <StatusUpdateContentWrapper>
                  <Section title={'Summary'} large={false}>
                    <StatusUpdateContentDetailsWrapper>
                      <TextEditedContent
                        value={data.deal.statusUpdate.message}
                      />
                    </StatusUpdateContentDetailsWrapper>
                  </Section>
                  {data.deal.statusUpdate?.sections?.map(x => (
                    <Section title={x.title} large={false} key={x.id}>
                      <TextEditedContent
                        value={x.content}
                      />
                    </Section>
                  ))}
                </StatusUpdateContentWrapper>
              </StatusUpdateWrapper>
            ) : (
              <CenteredSpinner />
            )}
          </PageDetails>
          <Route
            path={`${match.path}/comments`}
            render={() => (
              <StatusUpdateComments onClose={() => history.push(match.url)} onCommentUpdated={refetch}/>
            )}
          />
          {isShowingNewStatusUpdateModal && (
            <CreateStatusUpdateModal
              oppId={oppId}
              onClose={() => {
                setIsShowingNewStatusUpdateModal(false);
                onClose();
              }}
              initialValues={initialValues}
            />
          )}
        </Modal>
      ) : (
        <UpdateStatusUpdateModal oppId={oppId} onClose={() => setIsEditing(false)} onUpdate={refetch}/>
      )}
      { isShowingDeleteDialog && 
        <DeleteStatusUpdateDialog oppId={oppId} statusUpdateId={statusUpdateId} onComplete={() => {
          setIsShowingDeleteDialog(false)
          onClose();
        }}/>
      }
    </React.Fragment>
  );
};


interface DeleteStatusUpdateDialogProps {
  oppId: number;
  statusUpdateId: number;
  onComplete: () => void;
}

const DeleteStatusUpdateDialog: FunctionComponent<DeleteStatusUpdateDialogProps> = ({
  oppId,
  statusUpdateId,
  onComplete,
}) => {
  const [
    deleteStatusUpdate,
    { status },
  ] = useDeleteStatusUpdate(Number(oppId), { throwOnError: true });

  const notify = useNotification();

  return (
    <Dialog
      variant={'danger'}
      isOpen={true}
      title={'Delete Status Update'}
      confirmTitle={'Yes, Delete'}
      message={`This will delete the status update and all associated comments. Are you sure you want to do this?`}
      isWorking={status == 'loading'}
      onConfirm={async () => {
        try {
          await deleteStatusUpdate(statusUpdateId);
          onComplete();
          notify({
            message: 'Status update successfully deleted!',
          });
        } catch {
          notify({
            duration: 5000,
            variant: 'danger',
            message: 'An Error occcurred while deleting this status update!',
          });
        }
      }}
      onClose={onComplete}
      onCancel={onComplete}
    />
  );
};

const StatusTitleWrapper = styled.div`
  display: inline-flex;
  align-items: center;

  & > * {
    margin-right: 20px;
  }
`;

const StatusTitle = styled.div`
  ${font.size(20)};
  ${font.black};
  color: ${({ theme }) => theme.textDarkest};
`;

const StatusTitleDetailWrapper = styled.div`
  display: inline-flex;
  align-items: center;

  & > * {
    margin-right: 7px;
  }
`;

const StatusDateWrapper = styled.div`
  display: inline-flex;
  align-items: center;
  margin-top: 10px;

  & > * {
    margin-right: 7px;
  }
`;

const StatusIndicator = styled.div<{ variant: StatusVariant }>`
  ${font.size(8)};
  color: ${(props) => props.theme[props.variant]};
`;

const StatusName = styled.div`
  ${font.defaultSize};
  ${font.medium};
  color: ${({ theme }) => theme.textLight};
`;

const StatusAvatarText = styled.div`
  ${font.medium};
  ${font.defaultSize};
  color: ${({ theme }) => theme.textDark};
`;

const StatusModifiedText = styled.div`
  ${font.medium};
  ${font.size(12)};
  color: ${({ theme }) => theme.textMedium};
`;

const Separator = styled.span`
  height: 15px;
  border-right: 1px solid ${({ theme }) => theme.border};
  margin: 0 17px 0 10px;
  width: 1px;
`;

const StatusUpdateWrapper = styled.div`
  display: flex;
  flex-direction: column;

  & > *:not(:last-child) {
    margin-bottom: 20px;
  }
`;

const StatusUpdateHeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;

  & > *:not(:last-child) {
    margin-bottom: 10px;
  }
`;
const StatusUpdateContentWrapper = styled.div`
  display: flex;
  flex-direction: column;

  & > *:not(:last-child) {
    margin-bottom: 20px;
  }
`;

const StatusUpdateContentDetailsWrapper = styled.div`
  padding: 0 5px;
`;

const StatusLabelsWrapper = styled.div`
  margin: 10px 0;
  & > *:not(:last-child) {
    margin-right: 5px;
  }
`

export default StatusUpdateDetails;
