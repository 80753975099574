import React, { FunctionComponent, useMemo, useState } from 'react';
import {
  InputFormikField,
  SelectFormikField,
  FormFieldWrapper,
  DatePickerFormikField,
  TextEditorFormikField,
  SelectCreatableFormikField,
  Debug,
} from 'shared/components';
import { FormikProps } from 'formik';
import {
  useGetCurrentUser,
  useGetDeals,
  useGetInteractionTypes,
  useGetOrganizationUsers,
  useGetPipelineFirms,
  useGetPipelineContacts,
  useGetDealInteractions,
} from 'shared/hooks/api';
import { InteractionAttendeeEnum } from 'shared/hooks/api/graphql/generated';
import { useParams } from 'react-router-dom';
import { sortBy } from 'shared/utils/sorting';
import { createFilter } from 'react-select';
import NewContactModal, { ContactFormValues } from 'App/Organization/Deal/Contacts/modals/NewContactModal';

interface InteractionFormDetailsProps {
  form: FormikProps<any>;
}

const InteractionFormDetails: FunctionComponent<InteractionFormDetailsProps> = ({
  form,
}) => {
  const {
    data: typeData,
    status: getInteractionTypesStatus,
  } = useGetInteractionTypes();
  
  const { orgName, pipelineId, oppId } = useParams<{ orgName: string; pipelineId: string; oppId: string }>();
  const { data, status, isFetching, refetch } = useGetCurrentUser();
  const { data: dealData } = useGetDealInteractions(Number(oppId));
  const dealPipelineId = dealData?.deal?.pipeline.id || -1

  const org = useMemo(() => {
    const org = data?.currentUser.organizations?.find(
      (x) => x.organizationPath == orgName
    );
    return org;
  }, [data]);

  const typeOptions = useMemo(() => {
    return (
      typeData?.interactionTypes?.map((x) => ({
        value: x.id,
        label: x.name,
      })) || []
    );
  }, [typeData]);

  const { data: dealsData, status: getDealsStatus } = useGetDeals(false);

  const dealOptions = useMemo(() => {
    return (
      dealsData?.deals?.map((x) => ({
        value: x.id,
        label: x.name,
      })) || []
    );
  }, [dealsData]);

  const {
    data: firmsData,
    status: getFirmsStatus,
    refetch: refetchFirms,
  } = useGetPipelineFirms(pipelineId ? Number(pipelineId) : dealPipelineId);

  const firmOptions = useMemo(() => {
    return (
      firmsData?.pipeline?.firms?.map((x) => ({
        value: x.id,
        label: x.name,
      })) || []
    );
  }, [firmsData]);

  const {
    data: usersData,
    status: getUsersStatus,
    refetch: refetchUsers,
  } = useGetOrganizationUsers();
  const {
    data: contactsData,
    status: getContactsStatus,
    refetch: refetchContacts,
  } = useGetPipelineContacts(pipelineId ? Number(pipelineId) : dealPipelineId);
  const isLoadingAttendees = useMemo(
    () => getContactsStatus == 'loading' || getUsersStatus == 'loading',
    [getContactsStatus, getUsersStatus]
  );

  const attendeesOptions = useMemo(() => {
    const users =
      usersData?.users?.sort(sortBy(x => x.firstName)).map((x) => ({
        value: x.id,
        label: x.firstName + ' ' + x.lastName + (org ? ` - ${org.name}` : ''),
        type: InteractionAttendeeEnum.User,
      })) || [];
    const contacts =
      contactsData?.pipeline?.contacts?.sort(sortBy(x => x.firstName)).map((x) => ({
        value: x.id,
        label: x.firstName + ' ' + x.lastName + (x.firm ? ` - ${x.firm.name}` : ''),
        type: InteractionAttendeeEnum.Contact,
      })) || [];
    return [
      { label: 'Contacts', options: contacts },
      { label: 'Users', options: users },
    ];
  }, [usersData, contactsData, org]);

  const [newContactValue, setNewContactValue] = useState<Partial<ContactFormValues> | undefined>(undefined)

  return (
    <React.Fragment>
      <FormFieldWrapper>
        <InputFormikField
          name={'name'}
          label={'Name'}
          placeholder={'Enter a name for this interaction...'}
        />
      </FormFieldWrapper>
      <FormFieldWrapper>
        <DatePickerFormikField
          name={'interactionDate'}
          label={'Date'}
          inputProps={{ icon: 'calendar-alt' }}
        />
      </FormFieldWrapper>
      <FormFieldWrapper>
        <SelectFormikField
          name={'type'}
          label={'Type'}
          placeholder={'Select a type...'}
          isLoading={getInteractionTypesStatus == 'loading'}
          options={typeOptions}
        />
      </FormFieldWrapper>
      <FormFieldWrapper>
        <SelectCreatableFormikField
          filterOption={createFilter({ ignoreAccents: false })}
          name={'attendees'}
          label={'Attendees'}
          placeholder={'Select attendees...'}
          isLoading={isLoadingAttendees}
          options={attendeesOptions}
          isMulti={true}
          onCreateOption={(value) => {
            const names = value.split(' ');
            const firstName = names[0];
            const lastName = names.length > 1 ? names.slice(1).join(' '): undefined;
            setNewContactValue({ firstName, lastName });
          }}
        />
        {newContactValue && (
          <NewContactModal
            onClose={() => {
              setNewContactValue(undefined);
            }}
            onSuccess={(contact) => {
              if (contact) {
                form.setFieldValue('attendees', [...form.values.attendees, { 
                  value: contact.id,
                  label: contact.firstName + ' ' + contact.lastName + (contact.firm ? ` - ${contact.firm.name}` : ''),
                  type: InteractionAttendeeEnum.Contact,
                }]);
              }
            }}
            pipelineId={Number(pipelineId)}
            initialValues={newContactValue}
          />
        )}
      </FormFieldWrapper>
      <FormFieldWrapper>
        <SelectFormikField
          name={'deals'}
          label={'Related Deals'}
          placeholder={'Select related deals...'}
          isLoading={getDealsStatus == 'loading'}
          options={dealOptions}
          isMulti={true}
        />
      </FormFieldWrapper>
      <FormFieldWrapper>
        <SelectFormikField
          name={'firms'}
          label={'Firms'}
          placeholder={'Select related firms...'}
          isLoading={getFirmsStatus == 'loading'}
          options={firmOptions}
          isMulti={true}
        />
      </FormFieldWrapper>
      <FormFieldWrapper>
        <TextEditorFormikField
          label={'Notes'}
          name={'notes'}
          placeholder={'Add notes about the interaction here...'}
          style={{ minHeight: '100px' }}
        />
      </FormFieldWrapper>
      {/* <Debug state={form}/> */}
    </React.Fragment>
  );
};

export default InteractionFormDetails;
