import React, { FunctionComponent, HTMLAttributes } from 'react'
import { lightTheme as templateColor } from 'shared/utils/styles';
import { IconName } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { StyledBadge, Text } from './Styles';

export type BadgeVariant = "standard" | "outline";
export type BadgeColor = "primary" | "success" | "secondary" | "warning" | "danger" | "info";

export interface BadgeProps extends HTMLAttributes<HTMLButtonElement> {
  variant?: BadgeVariant;
  color?: BadgeColor;
  icon?: IconName | React.ReactNode;
  fullWidth?: boolean;
}

const Badge: FunctionComponent<BadgeProps> = ({ 
  children,
  variant = "standard",
  color = "secondary",
  icon,
  fullWidth = false,
  ...props
}) => {
  const iconColor = getIconColor(variant, color)
  return (
    <StyledBadge color={color} variant={variant} fullWidth={fullWidth} {...props}>
      {icon && typeof icon === 'string' ? <FontAwesomeIcon icon={["fal", (icon as IconName)]} fixedWidth={true} color={iconColor} /> : icon}
      {children && <Text padLeft={icon !== undefined}>{children}</Text>}
    </StyledBadge>
  )
}

const getIconColor = (variant: BadgeVariant, color: BadgeColor) => {
  if (variant === 'outline' || color === 'secondary') {
    return templateColor.textDark;
  } else {
    return '#fff';
  }
}

export default Badge;