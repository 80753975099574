import React, { FunctionComponent, useMemo } from 'react';
import {
  ErrorFragment,
  UpdateContactInput,
} from 'shared/hooks/api/graphql/generated';
import {
  useUpdateContact,
  useGetContact,
} from 'shared/hooks/api';
import { useNotification } from 'shared/components/notifications';
import deepDiff, { getDiffValue, diff } from 'shared/utils/deepDiff';
import { useParams } from 'react-router-dom';
import { ContactFormValues, contactFormSchema } from './NewContactModal';
import ContactForm from '../ContactForm';
import { FormModal, PageDetails } from 'shared/components';
import { PartialNullable } from 'shared/utils/utilityTypes';

interface UpdateContactModalProps {
  onClose: () => void;
  onSuccess: () => void;
  oppId?: number;
  pipelineId: number;
}

const UpdateContactModal: FunctionComponent<UpdateContactModalProps> = ({
  onClose,
  onSuccess,
  pipelineId,
}) => {
  const { contactId } = useParams<{ contactId: string }>();
  const [updateContact, { status }] = useUpdateContact();
  const { data, error, isFetching, refetch } = useGetContact(Number(contactId));
  const notify = useNotification();

  const initialValues = useMemo(() => {
    if (data?.contact) {
      const contact = data.contact;
      const values: PartialNullable<ContactFormValues> = {
        firstName: contact.firstName,
        lastName: contact.lastName,
        title: contact.title,
        cellPhone: contact.cellPhone,
        email: contact.email,
        notes: contact.notes,
        isPrimary: contact.isPrimary,
      }
      if (contact.firm) {
        values.firm = {
          value: contact.firm.id,
          label: contact.firm.name,
        }
      }
      return values;
    } else {
      return {}
    }
  }, [data]);

  return (
    <FormModal<ContactFormValues>
      initialValues={initialValues}
      title={'Update Contact'}
      submitTitle={'Update'}
      onClose={onClose}
      isWorking={status === 'loading'}
      validationSchema={contactFormSchema}
      handleSubmit={async (form, resetForm) => {
        const changes = diff(initialValues, form);
        const input: UpdateContactInput = {
          id: Number(contactId),
          firstName: changes.firstName,
          lastName: changes.lastName,
          title: changes.title,
          email: changes.email,
          cellPhone: changes.cellPhone,
          notes: changes.notes,
          fkFirm: changes.firm?.value,
          isPrimary: changes.isPrimary,
        };
        try {
          const result = await updateContact(input);
          if (result.errors) {
            notify({
              duration: 5000,
              title: 'Uh-oh!',
              message: `An unknown error occurred while updating the contact!`,
              variant: 'danger',
            });
          } else {
            switch (result.data?.updateContact?.__typename) {
              case 'Contact':
                notify({
                  duration: 3000,
                  title: 'Success!',
                  message: 'Contact Updated!',
                  variant: 'success',
                });
                onClose();
                onSuccess();
                resetForm();
                break;
              case 'ContactAlreadyExists':
                notify({
                  duration: 5000,
                  title: 'Contact Already Exists',
                  message: result.data?.updateContact.message,
                  variant: 'danger',
                });
                break;
              default:
                notify({
                  duration: 5000,
                  title: 'Failed to Update Contact',
                  message: (result.data?.updateContact as ErrorFragment)
                    .message,
                  variant: 'danger',
                });
                break;
            }
          }
        } catch (error) {
          notify({
            duration: 5000,
            title: 'Uh-oh!',
            message: `An unknown error occurred while updating the contact! ${error}`,
            variant: 'danger',
          });
        }
      }}
    >
      {(props) => (
        <PageDetails>
          <ContactForm
            formProps={props}
            pipelineId={pipelineId}
          />
        </PageDetails>
      )}
    </FormModal>
  );
};

export default UpdateContactModal;
