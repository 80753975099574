// Polyfills
import "core-js/stable";
import "regenerator-runtime/runtime";

// Other imports
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import ReactDom from 'react-dom';
import App from 'App';
import { useAuthModule } from 'shared/auth/msal';
import { Spinner } from 'shared/components';
import styled from 'styled-components';
import { ErrorBoundary } from 'react-error-boundary';
import { ThemeProvider } from 'styled-components';
import { lightTheme, darkTheme } from 'shared/utils/styles';

const AuthModuleWrapper = () => {
  const auth = useAuthModule();
  useEffect(() => {
    auth.loadAuthModule();
  }, []);

  if (auth.currentAccount) {
    return <App />;
  } else {
    return (
      <FullPageSpinnerComp />
    );
  }
};

const FullPageSpinnerComp = () => {
  return (
    <FullPageSpinner>
      <Spinner />
    </FullPageSpinner>
  );
};

const ErrorWrappedAuthModule = () => {
  return (
    <ErrorBoundary FallbackComponent={FullPageSpinnerComp} onError={(error) => console.log(error)}>
      <AuthModuleWrapper />
    </ErrorBoundary>
  );
};

interface ThemeContextType {
  theme: typeof lightTheme | typeof darkTheme;
  setTheme: Dispatch<SetStateAction<typeof lightTheme | typeof darkTheme>>;
}

export const ThemePreferenceContext = React.createContext<ThemeContextType>({
  theme: lightTheme,
  setTheme: () => {}
});
const savedThemePreference = localStorage.getItem('themePreference');

const RootComponent = () => {
  const [theme, setTheme] = useState(() => {
    return savedThemePreference === 'dark' ? darkTheme : lightTheme;
  });
  

  return (
    <ThemePreferenceContext.Provider value={{ theme, setTheme}}>
      <ThemeProvider theme={theme}>
        <ErrorWrappedAuthModule />
      </ThemeProvider>
    </ThemePreferenceContext.Provider>
  );
};


const FullPageSpinner = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: ${savedThemePreference === 'dark' ? '#121212' : '#FFFFFF'};
`;

ReactDom.render(<RootComponent />, document.getElementById('root'));
