import React, {
  FunctionComponent,
  ReactNode,
  forwardRef,
  useMemo,
  HTMLProps,
} from 'react';
import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
  ResponderProvided,
} from 'react-beautiful-dnd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tooltip } from 'shared/components';
import { match, useHistory, useRouteMatch } from 'react-router-dom';
import { IconName } from '@fortawesome/pro-light-svg-icons';
import {
  PageHeaderBar,
  PageHeaderBarLeft,
  PageHeaderBarTitleWrapper,
  PageHeaderBarTitle,
  PageHeaderTitleInfo,
  PageHeaderBarTabs,
  PageHeaderBarTab,
  PageHeaderBarRight,
  PageHeaderToolBarLeft,
  PageHeaderToolBarRight,
  PageHeaderToolBar,
  PageTitleTools,
  Separator,
  ToolbarMessageWrapper,
  PageHeaderBarSubtitle,
  PageHeaderBarTitlesWrapper,
  PageHeaderBarTabTools,
  LocallyRoutedPageHeaderBarTab,
  PageHeaderBarTitleInnerWrapper,
  PageHeaderBarInner,
} from './Styles';
import Button, { ButtonProps } from '../button/Button';
import DropdownButton, { DropdownButtonProps } from '../menu/DropdownButton';
import { InputProps, Input } from '../form/input/Input';
import ButtonGroup, { ButtonGroupProps } from '../button/ButtonGroup';
import { useIsMobileWidth, useWindowSize } from 'shared/hooks';

interface ToolbarMessageProps extends HTMLProps<HTMLDivElement> {
  message: string;
  icon: IconName;
  color?: string;
}

// eslint-disable-next-line react/display-name
export const ToolbarMessage = forwardRef<HTMLDivElement, ToolbarMessageProps>(
  ({ message, icon, color, ...props }, ref) => {
    return (
      <ToolbarMessageWrapper ref={ref}>
        <FontAwesomeIcon icon={['fal', icon]} color={color} />
        <div>{message}</div>
      </ToolbarMessageWrapper>
    );
  }
);

// eslint-disable-next-line react/display-name
export const ToolbarButton = forwardRef<HTMLButtonElement, ButtonProps>(
  ({ children, ...props }, ref) => {
    return (
      <Button ref={ref} slim={true} variant={'simple'} {...props}>
        {children}
      </Button>
    );
  }
);

// eslint-disable-next-line react/display-name
export const ToolbarButtonGroup = forwardRef<
  HTMLButtonElement,
  ButtonGroupProps
>(({ children, ...props }, ref) => {
  return (
    <ButtonGroup ref={ref} slim={true} variant={'simple'} {...props}>
      {children}
    </ButtonGroup>
  );
});

// eslint-disable-next-line react/display-name
export const ToolbarSearchInput = forwardRef<HTMLInputElement, InputProps>(
  ({ placeholder = 'Search...', ...props }, ref) => {
    return (
      <Input
        ref={ref}
        {...props}
        placeholder={placeholder}
        borderless={true}
        type={'search'}
      />
    );
  }
);

export const ToolbarMenu: FunctionComponent<DropdownButtonProps> = ({
  children,
  ...props
}) => (
  <DropdownButton slim={true} variant={'simple'} {...props}>
    {children}
  </DropdownButton>
);

export type Tab = {
  path: string;
  title: string;
  disabled?: boolean;
  pinned?: boolean;
  icon?: IconName;
};

type HeaderLoadingStatus = {
  title?: boolean;
  tabs?: boolean;
  tools?: boolean;
};

export interface PageHeaderProps {
  title: string;
  subtitle?: string | ReactNode;
  subtitle2?: string | ReactNode;
  description?: string;
  tabs?: Tab[];
  onTabClick?: (path: string) => void;
  localMatch?: string;
  tabTools?: ReactNode;
  titleTools?: ReactNode;
  tools?: ReactNode[];
  mobileTools?: ReactNode[];
  modal?: boolean;
  loading?: HeaderLoadingStatus;
  slim?: boolean;
  match?: match<{}>;
  onDragTabEnd?: (result: DropResult, provided: ResponderProvided) => void;
  className?: string;
}

const defaultLoading: HeaderLoadingStatus = {
  tabs: false,
  title: false,
  tools: false,
};

export const PageHeader: FunctionComponent<PageHeaderProps> = ({
  title,
  subtitle,
  subtitle2,
  description,
  titleTools,
  tabTools,
  onTabClick,
  onDragTabEnd,
  tabs = [],
  localMatch,
  tools = [],
  mobileTools = [],
  modal = false,
  loading = defaultLoading,
  slim = false,
  match = undefined,
  className,
}) => {
  const headerLoadingStatus: HeaderLoadingStatus = {
    ...defaultLoading,
    ...loading,
  };
  const currentMatch = useRouteMatch();
  const loadingTabs = headerLoadingStatus.tabs ? true : false;

  const onLinkClick = (e: any, disabled: boolean | undefined) => {
    if (disabled) e.preventDefault();
  };

  const pinnedTabs = useMemo(() => {
    return tabs.filter((x) => x.pinned == true);
  }, [tabs]);

  const otherTabs = useMemo(() => {
    return tabs.filter((x) => !x.pinned);
  }, [tabs]);

  const actualMatch = match || currentMatch;

  const history = useHistory();

  const tabsComponent = useMemo(() => {
    if (!onDragTabEnd) {
      return (
        <PageHeaderBarTabs>
          {pinnedTabs.length > 0 && (
            <React.Fragment>
              {pinnedTabs.map((tab, index) => {
                if (localMatch && onTabClick) {
                  return (
                    <LocallyRoutedPageHeaderBarTab
                      onClick={() => {
                        onTabClick(tab.path);
                      }}
                      key={tab.path}
                      disabled={tab.disabled}
                      isActive={localMatch == tab.path}
                      style={
                        index == pinnedTabs.length - 1 ? { marginRight: 0 } : {}
                      }
                    >
                      {tab.icon && <FontAwesomeIcon icon={['fal', tab.icon]} />}
                      <div>{tab.title}</div>
                    </LocallyRoutedPageHeaderBarTab>
                  );
                } else {
                  return (
                    <PageHeaderBarTab
                      onClick={(e) => onLinkClick(e, tab.disabled)}
                      key={tab.path}
                      to={{ pathname: `${actualMatch.url}${tab.path}`, search: history.location.search }}
                      disabled={tab.disabled}
                      style={
                        index == pinnedTabs.length - 1 ? { marginRight: 0 } : {}
                      }
                    >
                      {tab.icon && <FontAwesomeIcon icon={['fal', tab.icon]} />}
                      <div>{tab.title}</div>
                    </PageHeaderBarTab>
                  );
                }
              })}
              <Separator style={{ margin: '0 15px' }} />
            </React.Fragment>
          )}
          {otherTabs.map((tab) => {
            if (localMatch && onTabClick) {
              return (
                <LocallyRoutedPageHeaderBarTab
                  onClick={() => {
                    onTabClick(tab.path);
                  }}
                  key={tab.path}
                  disabled={tab.disabled}
                  isActive={localMatch == tab.path}
                >
                  {tab.icon && <FontAwesomeIcon icon={['fal', tab.icon]} />}
                  <div>{tab.title}</div>
                </LocallyRoutedPageHeaderBarTab>
              );
            } else {
              return (
                <PageHeaderBarTab
                  onClick={(e) => onLinkClick(e, tab.disabled)}
                  key={tab.path}
                  to={{ pathname: `${actualMatch.url}${tab.path}`, search: history.location.search }}
                  disabled={tab.disabled}
                >
                  {tab.icon && <FontAwesomeIcon icon={['fal', tab.icon]} />}
                  <div>{tab.title}</div>
                </PageHeaderBarTab>
              );
            }
          })}
          {tabTools && (
            <PageHeaderBarTabTools>{tabTools}</PageHeaderBarTabTools>
          )}
        </PageHeaderBarTabs>
      );
    } else {
      return (
        <DragDropContext onDragEnd={onDragTabEnd}>
          <Droppable
            droppableId={'droppable-other-tabs'}
            direction={'horizontal'}
            renderClone={(provided, snapshot, rubric) => {
              const tab = otherTabs[rubric.source.index];
              return (
                <PageHeaderBarTab
                  ref={provided.innerRef}
                  {...provided.draggableProps}
                  {...provided.dragHandleProps}
                  // style={{ cursor: 'pointer' }}
                  onClick={(e) => onLinkClick(e, tab.disabled)}
                  key={tab.path}
                  to={{ pathname: `${actualMatch.url}${tab.path}`, search: history.location.search }}
                  disabled={tab.disabled}
                >
                  {tab.icon && <FontAwesomeIcon icon={['fal', tab.icon]} />}
                  <div>{tab.title}</div>
                </PageHeaderBarTab>
              );
            }}
          >
            {(provided, snapshot) => (
              <PageHeaderBarTabs
                ref={provided.innerRef}
                //  style={getListStyle(snapshot.isDraggingOver)}
                {...provided.droppableProps}
              >
                {pinnedTabs.length > 0 && (
                  <React.Fragment>
                    {pinnedTabs.map((tab, index) => {
                      return (
                        <PageHeaderBarTab
                          onClick={(e) => onLinkClick(e, tab.disabled)}
                          key={tab.path}
                          to={{ pathname: `${actualMatch.url}${tab.path}`, search: history.location.search }}
                          disabled={tab.disabled}
                          style={
                            index == pinnedTabs.length - 1
                              ? { marginRight: 0 }
                              : {}
                          }
                        >
                          {tab.icon && (
                            <FontAwesomeIcon icon={['fal', tab.icon]} />
                          )}
                          <div>{tab.title}</div>
                        </PageHeaderBarTab>
                      );
                    })}
                    <Separator style={{ margin: '0 15px' }} />
                  </React.Fragment>
                )}
                {otherTabs.map((tab, index) => (
                  <Draggable
                    key={tab.path}
                    draggableId={tab.path}
                    index={index}
                  >
                    {(provided, snapshot) => (
                      <PageHeaderBarTab
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        onClick={(e) => onLinkClick(e, tab.disabled)}
                        key={tab.path}
                        to={{ pathname: `${actualMatch.url}${tab.path}`, search: history.location.search }}
                        disabled={tab.disabled}
                      >
                        {tab.icon && (
                          <FontAwesomeIcon icon={['fal', tab.icon]} />
                        )}
                        <div>{tab.title}</div>
                      </PageHeaderBarTab>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
                {tabTools && (
                  <PageHeaderBarTabTools>{tabTools}</PageHeaderBarTabTools>
                )}
              </PageHeaderBarTabs>
            )}
          </Droppable>
        </DragDropContext>
      );
    }
  }, [otherTabs, onDragTabEnd, tabTools, pinnedTabs]);

  const isMobileWidth = useIsMobileWidth();

  return (
    <PageHeaderBar className={className} slim={slim}>
      <PageHeaderBarInner>

        <PageHeaderBarLeft>
          <PageHeaderBarTitleWrapper modal={modal}>
            {headerLoadingStatus.title ? (
              <PageHeaderBarTitle slim={slim}>Loading...</PageHeaderBarTitle>
            ) : (
              <React.Fragment>
                <PageHeaderBarTitlesWrapper>
                  <PageHeaderBarTitleInnerWrapper>
                    <PageHeaderBarTitle slim={slim}>{title}</PageHeaderBarTitle>
                    {titleTools && <PageTitleTools>{titleTools}</PageTitleTools>}
                  </PageHeaderBarTitleInnerWrapper>
                  {subtitle && (
                    <PageHeaderBarSubtitle slim={slim}>
                      {subtitle}
                    </PageHeaderBarSubtitle>
                  )}
                  {subtitle2 && (
                    <PageHeaderBarSubtitle slim={slim}>
                      {subtitle2}
                    </PageHeaderBarSubtitle>
                  )}
                </PageHeaderBarTitlesWrapper>
                {description && (
                  <Tooltip content={description} placement={'right'}>
                    <PageHeaderTitleInfo>
                      <FontAwesomeIcon icon={['fal', 'info-circle']} />
                    </PageHeaderTitleInfo>
                  </Tooltip>
                )}
              </React.Fragment>
            )}
          </PageHeaderBarTitleWrapper>
        </PageHeaderBarLeft>
        {!headerLoadingStatus.tools && (mobileTools.length > 0 || tools.length > 0) && isMobileWidth ? (
          <PageHeaderBarRight>{mobileTools.length > 0 ? mobileTools : tools}</PageHeaderBarRight>
        ) : !headerLoadingStatus.tools && tools.length > 0 && (
          <PageHeaderBarRight>{tools}</PageHeaderBarRight>
        )}
      </PageHeaderBarInner>
      {headerLoadingStatus.tabs ? (
          <PageHeaderBarTabs>
            <PageHeaderBarTab to={{ pathname: `${actualMatch.url}`, search: history.location.search }}>
              Loading...
            </PageHeaderBarTab>
          </PageHeaderBarTabs>
        ) : (
          tabs.length > 0 && tabsComponent
        )}
    </PageHeaderBar>
  );
};

type Tool = {
  icon: IconName;
  title: string;
  onClick: () => void;
};

interface ToolbarProps {
  right?: ReactNode;
  left?: ReactNode;
  mobileRight?: ReactNode;
  mobileLeft?: ReactNode;
}

export const PageToolbar: FunctionComponent<ToolbarProps> = ({
  right,
  left,
  mobileLeft,
  mobileRight,
}) => {
  const size = useWindowSize();

  const isMobileWidth = useMemo(() => {
    return (size.width ?? 0) <= 576;
  }, [size.width]);
  return (
    <PageHeaderToolBar>
      <PageHeaderToolBarLeft>
        {isMobileWidth && mobileLeft ? mobileLeft : left}
      </PageHeaderToolBarLeft>
      <PageHeaderToolBarRight>
        {isMobileWidth && mobileRight ? mobileRight : right}
      </PageHeaderToolBarRight>
    </PageHeaderToolBar>
  );
};
