import React, { FunctionComponent, HTMLAttributes } from 'react'
import styled from 'styled-components';

import { font, mixin } from 'shared/utils/styles';

export const Image = styled.button<AvatarProps>`
  display: inline-block;
  width: ${props => props.size}px;
  height: ${props => props.size}px;
  border-radius: 100%;
  ${props => props.url && mixin.backgroundImage(props.url)}
  ${props => props.onClick && `cursor: pointer;`}
`;

export const Letter = styled.button<AvatarProps>`
  display: inline-block;
  width: ${props => props.size}px;
  height: ${props => props.size}px;
  border-radius: 100%;
  border: 2px solid ${({theme}) => theme.border};
  text-transform: uppercase;
  color: #fff;
  background: ${props => props.color};
  ${font.medium}
  ${props => props.size && font.size(Math.round(props.size / 2.25))}
  & > span {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }
  ${props => props.onClick && `cursor: pointer;`}
`;

export interface AvatarProps extends HTMLAttributes<HTMLButtonElement> {
  url?: string;
  name?: string;
  size?: number;
  className?: string;
}

const colors = [
  '#DA7657',
  '#6ADA57',
  '#5784DA',
  '#AA57DA',
  '#DA5757',
  '#DA5792',
  '#57DACA',
  '#57A5DA',
];

const getColorFromName = (name: string) => colors[name.toLocaleLowerCase().charCodeAt(0) % colors.length];

const Avatar: FunctionComponent<AvatarProps> = ({ url, name = "", size = 32, ...props }) => {
  if (url) {
    return <Image url={url} size={size} {...props}/>
  } else {
    const parts = name.split(' ');
    const initials = parts.length > 1 ? `${parts[0].charAt(0)}${parts[1].charAt(0)}` : parts[0].charAt(0);
    return (
      <Letter color={getColorFromName(name)} size={size} {...props} type={'button'}>
        <span>{initials}</span>
      </Letter>
    );
  }
}

export default Avatar;