import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import StatusUpdateSection from 'App/Organization/shared/StatusUpdate/StatusUpdateSection';
import { FieldArray, FormikProps } from 'formik';
import React, { FunctionComponent, useContext, useMemo, useState } from 'react';
import {
  Button,
  Column,
  Debug,
  DropdownButton,
  DropdownItem,
  FormFieldWrapper,
  HorizontalSeparator,
  Input,
  InputFormikField,
  Label,
  MenuSeparator,
  Row,
  Section,
  SelectFormikField,
  SelectOption,
  TextEditorFormikField,
  Tooltip,
} from 'shared/components';
import { StatusUpdateValues } from 'shared/components/card/StatusCard';
import {
  customSelectStyles,
  SelectField,
} from 'shared/components/form/select/Select';
import { useFocus } from 'shared/hooks';
import { useGetDealStatuses, useGetLabels } from 'shared/hooks/api';
import { font } from 'shared/utils/styles';
import { PartialNullable } from 'shared/utils/utilityTypes';
import styled, { ThemeContext } from 'styled-components';
import AddLabelButton from './AddLabelButton';
import CreateLabelModal from './CreateLabelModal';

interface StatusUpdateFormProps {
  form: FormikProps<PartialNullable<StatusUpdateValues>>;
}

const StatusUpdateForm: FunctionComponent<StatusUpdateFormProps> = ({
  form,
}) => {
  const themeContext = useContext(ThemeContext);
  const { data } = useGetDealStatuses();
  const { data: labelsData } = useGetLabels();
  const statusOptions = useMemo(() => {
    if (data?.dealStatuses) {
      const statuses = data.dealStatuses;
      const options: SelectOption[] = statuses.map((s) => ({
        value: s.id,
        label: s.name,
        icon: 'circle',
        color: s.color ? themeContext[s.color] : themeContext.secondary,
      }));
      return options;
    } else {
      return [];
    }
  }, [data]);

  const labelsOptions = useMemo(() => {
    if (labelsData?.labels) {
      const labels = labelsData.labels;
      const options: SelectOption[] = labels.map((s) => ({
        value: s.id,
        label: s.name,
        color: s.color || undefined,
      }));
      return options.filter(
        (x) => !form.values.labels?.map((x) => x.value).includes(x.value)
      );
    } else {
      return [];
    }
  }, [labelsData, form.values.labels]);

  return (
    <React.Fragment>
      <TitleAndStatusWrapper>
        <StatusUpdateTitleInputField
          label={'Title'}
          name={'title'}
          placeholder={'Title of status update'}
          // borderless={true}
          style={{ width: '100%' }}
        />
        <SelectFormikField
          label={'Status'}
          options={statusOptions}
          name={'status'}
          placeholder={'Select a status...'}
          styles={customSelectStyles(themeContext, '40px')}
          style={{ width: '300px' }}
        />
      </TitleAndStatusWrapper>
      <FieldArray
        name={`labels`}
        render={(arrayHelpers) => (
          <LabelsWrapper>
            {form.values.labels?.map((label, index) => (
              <Label
                key={label.value}
                name={label.label}
                color={label.color || undefined}
                onRemove={() => {
                  arrayHelpers.remove(index);
                }}
              />
            ))}
            <AddLabelButton
              onSelected={(opt) => arrayHelpers.push(opt)}
              options={labelsOptions}
            />
          </LabelsWrapper>
        )}
      />
      <Section title={'Summary'}>
        <TextEditorFormikField
          name={'message'}
          placeholder={
            'How is the project progressing? Provide a summary here...'
          }
          style={{ minHeight: '50px' }}
        />
      </Section>
      <FieldArray
        name={`sections`}
        render={(arrayHelpers) => (
          <React.Fragment>
            {form.values.sections?.map((section, index) => (
              <Section
                key={index}
                fieldProps={{
                  name: `sections[${index}].title`,
                  placeholder: 'Section Title',
                  fullWidth: true,
                }}
                showToolsOnHover={true}
                right={[
                  <Button
                    key={'delete-section'}
                    variant={'simple'}
                    icon={'trash'}
                    color={'danger'}
                    onClick={() => arrayHelpers.remove(index)}
                  >
                    Delete Section
                  </Button>,
                ]}
              >
                <TextEditorFormikField
                  name={`sections[${index}].content`}
                  placeholder={'Write the content of the section here...'}
                  style={{ minHeight: '50px' }}
                />
              </Section>
            ))}
            <FormFieldWrapper>
              <Tooltip content={'Add Section'}>
                <Button
                  variant={'outline'}
                  slim={true}
                  fullWidth={true}
                  icon={'plus'}
                  onClick={() =>
                    arrayHelpers.push({
                      title: `Section ${
                        form.values.sections?.length
                          ? form.values.sections?.length + 1
                          : 1
                      }`,
                    })
                  }
                />
              </Tooltip>
            </FormFieldWrapper>
          </React.Fragment>
        )}
      />
      {/* <Debug state={form} /> */}
    </React.Fragment>
  );
};

const TitleAndStatusWrapper = styled.div`
  display: flex;
  flex-direction: row;
  & > *:not(:last-child) {
    margin-right: 10px;
  }
`;

const LabelsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  margin: 10px 0;
  & > * {
    margin-bottom: 5px;
  }
  & > *:not(:last-child) {
    margin-right: 5px;
  }
`;

const StatusUpdateTitleInputField = styled(InputFormikField)`
  ${font.bold};
  ${font.size(18)};
  height: 40px;
  max-height: 40px;
`;

export default StatusUpdateForm;
