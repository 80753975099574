import React, { useState } from 'react';
import styled, { ThemeProvider } from 'styled-components';
import { darkTheme, font, lightTheme } from 'shared/utils/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from '../button/Button';
import { ThemePreferenceContext } from 'index';

export type AlertPosition =
  | 'top-left'
  | 'top-center'
  | 'top-right'
  | 'bottom-left'
  | 'bottom-center'
  | 'bottom-right';

export type AlertVariant =
  | 'secondary'
  | 'success'
  | 'primary'
  | 'info'
  | 'warning'
  | 'danger';

export interface AlertProps extends React.HTMLAttributes<HTMLDivElement> {
  title?: string;
  message?: string | React.ReactNode;
  variant?: AlertVariant;
  onClose?: () => void;
  shadow?: boolean;
}

const getVariantIcon = (variant: AlertVariant) => {
  switch (variant) {
    case 'warning':
      return 'exclamation-circle';
    case 'danger':
      return 'exclamation-circle';
    case 'success':
      return 'check-circle';
    default:
      return 'info-circle';
  }
};
const getVariantColor = (variant: AlertVariant) => lightTheme[variant];

const AlertWrapper = styled.div<{ shadow: boolean }>`
  background-color: ${({theme}) => theme.backgroundPrimary};
  overflow: hidden;
  position: relative;
  border-radius: 5px;
  ${(props) =>
    props.shadow
      ? `box-shadow: 0 0 0 1px rgba(111, 119, 130, 0.15),
      0 5px 20px 0 rgba(21, 27, 38, 0.08);`
      : `border: 1px solid ${props.theme.border};`}
`;

const AlertBar = styled.div<{ variant: AlertVariant }>`
  width: 5px;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  background-color: ${(props) => getVariantColor(props.variant)};
`;

const AlertContent = styled.div`
  display: flex;
  align-items: flex-start;
  width: 100%;
  height: 100%;
  padding: 10px 10px;
`;

const AlertContentsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const AlertIconWrapper = styled.div`
  margin: 0 10px;
`;

const AlertIcon = styled.span<{ variant: AlertVariant }>`
  display: block;
  font-size: 15px;
  color: ${(props) => props.theme[props.variant]};
`;

const AlertTextWrapper = styled.div`
  padding: 7px 10px;
  width: 100%;
`;

const AlertTitleWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;

const AlertTitleLeft = styled.div`
  display: inline-flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
`;

const AlertTitleRight = styled.div`
  display: inline-flex;
  justify-content: flex-end;
`;

const AlertTitle = styled.div`
  margin: 0 2px;
  ${font.size(14)};
  ${font.bold};
`;

const AlertMessage = styled.div`
  color: ${({ theme }) => theme.textMedium};
  ${font.defaultSize};
  width: 100%;
`;

const Alert: React.FunctionComponent<AlertProps> = ({
  title,
  message,
  onClose,
  variant = 'info',
  shadow = true,
  ...rest
}) => {
  const iconName = getVariantIcon(variant);
  const savedThemePreference = localStorage.getItem('themePreference');
  const [theme, setTheme] = useState(() => {
    return savedThemePreference === 'dark' ? darkTheme : lightTheme;
  });
  const contents = (
    <AlertContentsWrapper>
      <AlertTitleWrapper>
        <AlertTitleLeft>
          <AlertIconWrapper>
            <AlertIcon variant={variant}>
              <FontAwesomeIcon icon={['fal', iconName]} />
            </AlertIcon>
          </AlertIconWrapper>
          {title ? <AlertTitle>{title}</AlertTitle> :
            message && <AlertMessage>{message}</AlertMessage>}
        </AlertTitleLeft>
        {onClose && (
          <AlertTitleRight>
            <Button icon="times" variant={'simple'} onClick={onClose} slim={true}/>
          </AlertTitleRight>
        )}
      </AlertTitleWrapper>
      {title && message && (
        <AlertTextWrapper>
          <AlertMessage>{message}</AlertMessage>
        </AlertTextWrapper>
      )}
    </AlertContentsWrapper>
  );
  return (
    <ThemePreferenceContext.Provider value={{ theme, setTheme }}>
      <ThemeProvider theme={theme}>
        <AlertWrapper shadow={shadow} {...rest}>
          {/* Alert Bar */}
          <AlertBar variant={variant} />
          {/* Alert Content */}
          <AlertContent>{contents}</AlertContent>
        </AlertWrapper>
      </ThemeProvider>
    </ThemePreferenceContext.Provider>
  );
};

export default Alert;
