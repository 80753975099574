import { IconName } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FunctionComponent, ReactNode, useContext } from 'react';
import { font } from 'shared/utils/styles';
import styled, { ThemeContext } from 'styled-components';
import { Button } from '..';

const NoDealAccess = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding: 30px;
`;

const NoDealAccessTitle = styled.div`
  ${font.size(18)};
  ${font.medium};
  color: ${({ theme }) => theme.textDarkest};
  margin-top: 20px;
`;

const NoDealAccessSubtitle = styled.div`
  ${font.size(14)};
  ${font.regular};
  color: ${({ theme }) => theme.textMedium};
  max-width: 500px;
  text-align: center;
  padding: 10px;
`;

export type NoAccessProps = {
  title?: string;
  subtitle?: string;
  icon: IconName | ReactNode;
  iconColor?: string;
  action?: () => void;
  actionTitle?: string;
  actionIcon?: IconName;
};

const NoAccess: FunctionComponent<NoAccessProps> = ({
  title,
  subtitle,
  icon,
  iconColor,
  action,
  actionTitle,
  actionIcon,
}) => {
  const themeContext = useContext(ThemeContext);
  return (
    <NoDealAccess>
      {typeof icon === 'string' ? (
        <FontAwesomeIcon
          icon={['fal', icon as IconName]}
          style={{ fontSize: '30px' }}
          color={iconColor || themeContext.textDarkest}
        />
      ) : (
        icon
      )}
      {title && <NoDealAccessTitle>{title}</NoDealAccessTitle>}
      {subtitle && <NoDealAccessSubtitle>{subtitle}</NoDealAccessSubtitle>}
      {action && actionTitle && (
        <Button
          variant={'outline'}
          style={{ marginTop: '15px' }}
          onClick={action}
          icon={actionIcon}
        >
          {actionTitle}
        </Button>
      )}
    </NoDealAccess>
  );
};

export default NoAccess;
