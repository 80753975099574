import history from 'browserHistory'
import { queryStringToObject, addToQueryString, omitFromQueryString } from 'shared/utils/url';

export const getQueryParamAsObject = (param: string) => queryStringToObject(history.location.search)[param];

export const setFileExplorerPath = (pathComponents: string[]) => {
  const nonEmptyComponents = pathComponents.filter(comp => comp != "");
  if (nonEmptyComponents.length > 0) {
    history.push({
      pathname: history.location.pathname,
      search: addToQueryString(history.location.search, { path: `/${pathComponents.join("/")}` }),
    });
  } else {
    history.push({
      pathname: history.location.pathname,
      search: omitFromQueryString(history.location.search, ['path']),
    });
  }
}

export const getFileExplorerPathComponents = () => {
  const path = getQueryParamAsObject('path');
  if (typeof path === 'string') {
    const components = path.split("/");
    const idComponents = components.filter(comp => comp != "").map(x => Number(x));
    return idComponents
  } else {
    return []
  }
}
