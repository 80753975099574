import React, {
  FunctionComponent,
  HTMLAttributes,
  forwardRef,
  ButtonHTMLAttributes,
  useContext,
  useMemo,
} from 'react';
import { lightTheme as templateColor, mixin } from 'shared/utils/styles';
import { IconName } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Spinner from '../spinner/Spinner';
import { ButtonSpinnerContainer, StyledButton, Text } from './Styles';
import { ThemeContext } from 'styled-components';

export type ButtonVariant = 'standard' | 'outline' | 'simple';
export type ButtonColor =
  | 'primary'
  | 'success'
  | 'secondary'
  | 'warning'
  | 'danger'
  | 'info';

export interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  variant?: ButtonVariant;
  color?: ButtonColor;
  isActive?: boolean;
  slim?: boolean;
  icon?: IconName | React.ReactNode;
  iconOnRight?: boolean;
  borderRadius?: [number, number, number, number];
  fullWidth?: boolean;
  isWorking?: boolean;
  workingTitle?: string;
  disabled?: boolean;
}

// eslint-disable-next-line react/display-name
export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      children,
      variant = 'standard',
      color = 'secondary',
      isActive = false,
      disabled = false,
      slim = false,
      icon,
      iconOnRight = false,
      fullWidth = false,
      borderRadius = [4, 4, 4, 4],
      isWorking = false,
      workingTitle,
      type = 'button',
      ...props
    },
    ref
  ) => {
    const themeContext = useContext(ThemeContext);

    const [foreground, background] = useMemo(() => {
      const darken = (color, amount) => mixin.darken(color, amount);
      if (variant === 'simple' || variant === 'outline' || color === 'secondary') {
        return [themeContext.textDark, darken('#fff', 0.2)];
      } else {
        const themeColor = themeContext[color];
        return ['#fff', darken(themeColor, 0.2)];
      }
    }, [themeContext, variant, color]);

    const iconOnly = icon && !children || false;
    return (
      <StyledButton
        color={color}
        variant={variant}
        isActive={isActive || isWorking}
        slim={slim}
        borderRadius={borderRadius}
        fullWidth={fullWidth}
        disabled={disabled || isWorking}
        ref={ref}
        type={type}
        iconOnly={iconOnly}
        isWorking={isWorking}
        {...props}
      >
        {isWorking && (
          <ButtonSpinnerContainer>
            <Spinner
              size={13}
              fillColor={foreground}
              backgroundColor={background}
            />
            {workingTitle && <Text padLeft={true}>{workingTitle}</Text>}
          </ButtonSpinnerContainer>
        )}
        {icon && !iconOnRight && renderIcon(icon, isWorking)}
        {children && (
          <Text isWorking={isWorking} padLeft={isWorking || (icon !== undefined && !iconOnRight)} padRight={(icon !== undefined && iconOnRight)}>{children}</Text>
        )}
        {icon && iconOnRight && renderIcon(icon, isWorking)}
      </StyledButton>
    );
  }
);

export const renderIcon = (icon: IconName | React.ReactNode, isWorking: boolean) => {
  if (icon && typeof icon === 'string') {
    return (
      <FontAwesomeIcon icon={['fal', icon as IconName]} fixedWidth={true} style={{ visibility: isWorking ? 'hidden' : 'visible' }}/>
    );
  } else {
    return icon;
  }
};



export default Button;
