import React from 'react';
import { PageHeader, PageDetails } from 'shared/components';
import { usePageTitle } from 'shared/hooks';

const EmployeeSuccess = () => {
  usePageTitle("Employee Success");
  return (
    <React.Fragment>
      <PageHeader
        title={'Employee Success'}
      />
      <PageDetails>
        <React.Fragment>EmployeeSuccess</React.Fragment>
      </PageDetails>
    </React.Fragment>
  );
};

export default EmployeeSuccess;
