import styled from 'styled-components';
import { sizes, font, mixin } from 'shared/utils/styles';
import { NavLink, NavLinkProps } from 'react-router-dom';

const paddingLeft = (
  isMainSidebarOpen: boolean,
  isNestedSidebarOpen?: boolean
) => {
  const mainSidebarWidth = isMainSidebarOpen
    ? sizes.appSidebarWidth
    : sizes.collapsedAppSidebarWidth;
  const nestedSidebarWidth =
    isNestedSidebarOpen === true
      ? sizes.appSidebarWidth
      : isNestedSidebarOpen === false
      ? sizes.collapsedAppSidebarWidth
      : 0;
  return mainSidebarWidth + nestedSidebarWidth;
};

export const PageWrapper = styled.div<{
  isMainSidebarOpen: boolean;
  isNestedSidebarOpen?: boolean;
}>`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  height: 100%;
  background-color: ${({theme}) => theme.backgroundPrimary};
  padding: ${sizes.navbarHeight}px 0 0
    ${(props) =>
      paddingLeft(props.isMainSidebarOpen, props.isNestedSidebarOpen)}px;

  @media (max-width: 576px) {
    padding-left: 0;
  }
`;

export const PageHeaderBar = styled.div<{ slim: boolean }>`
  display: flex;
  position: sticky;
  align-items: center;
  flex-direction: column;
  width: 100%;
  border-bottom: 1px solid ${({ theme }) => theme.border};
  border-left: 1px solid ${({ theme }) => theme.border};
  background-color: ${({theme}) => theme.backgroundPrimary};
  transition: 200ms width ease-in-out, 200ms left ease-in-out;
  padding: ${(props) => (props.slim ? '10px' : '15px')};
  z-index: 110;
`;

export const PageHeaderBarInner = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  background-color: ${({theme}) => theme.backgroundPrimary};
  transition: 200ms width ease-in-out, 200ms left ease-in-out;
`;

export const PageHeaderBarLeft = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-right: 20px;
  @media (max-width: 576px) {
    width: 50%;
  }
`;

export const PageHeaderBarRight = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  & > * {
    margin-left: 5px;
  }
  @media (max-width: 576px) {
    width: 50%;
  }
`;

export const PageHeaderBarTitleWrapper = styled.div<{ modal?: boolean }>`
  display: inline-flex;
  align-items: center;
`;

export const PageHeaderBarTitlesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 15px;

  & > *:not(:last-child) {
    margin-bottom: 10px;
  }
`;

export const PageHeaderBarTitleInnerWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  & > *:not(:last-child) {
    margin-right: 10px;
  }

  @media (max-width: 576px) {
    align-items: flex-start;
    flex-direction: column;
    & > *:not(:last-child) {
      margin-bottom: 10px;
    }
  }
  
`;

export const PageHeaderBarTitle = styled.div<{ slim: boolean }>`
  ${(props) => (props.slim ? font.size(13) : font.size(18))};
  ${font.bold};
  @media (max-width: 576px) {
    overflow: visible;
    white-space: nowrap;
  }
`;

export const PageHeaderBarSubtitle = styled.div<{ slim: boolean }>`
  ${(props) => (props.slim ? font.size(11) : font.size(13))};
  color: ${({ theme }) => theme.textMedium};
`;

export const PageHeaderTitleInfo = styled.div`
  ${font.size(15)};
  color: ${({ theme }) => theme.textMedium};
  cursor: pointer;
  margin-right: 10px;
`;

export const PageHeaderBarTabs = styled.div`
  display: inline-flex;
  padding-top: 15px;
  margin-bottom: -15px;
  overflow-x: auto;
  width: 100%;
`;

interface TabProps {
  disabled?: boolean;
  isActive?: boolean;
}

type NavLinkTabProps = TabProps & NavLinkProps;

export const PageHeaderBarTab = styled(NavLink)<NavLinkTabProps>`
  display: inline-flex;
  flex-direction: row;
  flex-shrink: 0;
  ${font.medium};
  color: ${({ theme }) => theme.textMedium};
  padding-bottom: 10px;
  margin-right: 20px;
  ${mixin.clickable};

  &.active {
    color: ${({ theme }) => theme.textDarkest};
    border-bottom: 2px solid ${({ theme }) => theme.info};
  }

  &:hover {
    color: ${({ theme }) => theme.textDarkest};
    border-bottom: 2px solid ${({theme}) => mixin.darken(theme.border, 0.2)};
  }

  & > *:not(:last-child) {
    margin-right: 7px;
  }

  ${(props) => props.disabled && `cursor: not-allowed`};
  &[data-rbd-drag-handle-context-id='0'] {
    cursor: pointer;
  }
`;

export const LocallyRoutedPageHeaderBarTab = styled.div<TabProps>`
  display: inline-flex;
  flex-direction: row;
  flex-shrink: 0;
  ${font.medium};
  color: ${({ theme }) => theme.textMedium};
  padding-bottom: 10px;
  margin-right: 20px;
  ${mixin.clickable};

  ${(props) =>
    props.isActive &&
    `
    color: ${props.theme.textDarkest};
    border-bottom: 2px solid ${props.theme.info};
  `}

  &:hover {
    color: ${({ theme }) => theme.textDarkest};
    border-bottom: 2px solid ${({theme}) => mixin.darken(theme.border, 0.2)};
  }

  & > *:not(:last-child) {
    margin-right: 7px;
  }

  ${(props) => props.disabled && `cursor: not-allowed`};
  &[data-rbd-drag-handle-context-id='0'] {
    cursor: pointer;
  }
`;

export const PageHeaderBarTabButton = styled.a`
  ${font.medium};
  color: ${({ theme }) => theme.textMedium};
  padding-bottom: 10px;
  margin-bottom: -10px;
  margin-right: 20px;
  ${mixin.clickable};

  &.active {
    color: ${({ theme }) => theme.textDarkest};
    border-bottom: 2px solid ${({ theme }) => theme.info};
  }

  &:hover {
    color: ${({ theme }) => theme.info};
    // border-bottom: 2px solid ${({theme}) => mixin.darken(theme.info, 0.2)};
  }

  & > * {
    margin-right: 7px;
  }
`;

export const PageHeaderBarTabStatic = styled.a`
  ${font.medium};
  color: ${({ theme }) => theme.textMedium};
  padding-bottom: 10px;
  margin-bottom: -10px;
  margin-right: 20px;
  ${mixin.clickable};

  &.active {
    color: ${({ theme }) => theme.textDarkest};
    border-bottom: 2px solid ${({ theme }) => theme.info};
  }

  &:hover {
    color: ${({ theme }) => theme.textDarkest};
    border-bottom: 2px solid ${({theme}) => mixin.darken(theme.border, 0.2)};
  }
`;

export const PageHeaderBarTabTools = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 100%;
  margin-top: -5px;
  & > * {
    margin-right: 10px;
  }
`;

export const PageHeaderToolBar = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  border-bottom: 1px solid ${({ theme }) => theme.border};
  background-color: ${({ theme }) => theme.backgroundPrimary};
  transition: 200ms width ease-in-out, 200ms left ease-in-out;
  padding: 5px 10px;
  z-index: 100;
  height: 36px;
`;

export const PageHeaderToolBarLeft = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
  flex-grow: 1;
  height: 100%;
  background-color: ${({ theme }) => theme.backgroundPrimary};
  & > * {
    margin-right: 2px;
  }
`;

export const PageHeaderToolBarRight = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: flex-end;
  flex-grow: 1;
  height: 100%;
  background-color: ${({ theme }) => theme.backgroundPrimary};
  & > * {
    margin-left: 2px;
  }
`;

export const PageHeaderToolBarTool = styled.div`
  ${font.defaultSize};
  ${mixin.clickable};
  padding: 5px 10px;
  background-color: ${({ theme }) => theme.backgroundPrimary};
  border-radius: 5px;
  &:hover {
    background-color: ${({ theme }) => theme.border};
  }
`;

export const PageHeaderToolBarIcon = styled.span`
  ${font.size(12)};
  margin-right: 7px;
`;

export const Separator = styled.span`
  height: 15px;
  border-right: 1px solid ${({ theme }) => theme.border};
  margin: 0 10px;
  width: 1px;
`;

export const PageDetails = styled.div`
  padding: 10px 15px;
  overflow-y: auto;
  overflow-x: hidden;
  background-color: ${({ theme }) => theme.backgroundPrimary};
  width: 100%;
  flex: 1;
`;

export const PageTitleTools = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  & > * {
    margin-right: 5px;
  }
`;

export const ToolbarMessageWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  cursor: pointer;

  & > * {
    margin-right: 7px;
  }

  color: ${({ theme }) => theme.textMedium};
`;