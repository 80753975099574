import React, { useState, useMemo, FunctionComponent } from 'react';
import {
  Dialog,
  Row,
  Column,
  InputFormikField,
  TextareaFormikField,
  SelectCreatableFormikField,
  Alert,
} from 'shared/components';
import { CreateFirmInput } from 'shared/hooks/api/graphql/generated';
import { createFilter } from 'react-select';
import { useNotification } from 'shared/components/notifications';
import { useGetPipelineFirms, useCreateFirm } from 'shared/hooks/api';
import { FormikProps } from 'formik';
import { ContactFormValues } from './modals/NewContactModal';
import { PartialNullable } from 'shared/utils/utilityTypes';
import SelectCreatableFormikFirmField from 'shared/form/SelectCreatableFormikFirmField';
import { CheckboxFormikField } from 'shared/components/form/Checkbox';

interface ContactFormProps {
  infoMessage?: string;
  formProps: FormikProps<PartialNullable<ContactFormValues>>;
  pipelineId: number;
}

const ContactForm: FunctionComponent<ContactFormProps> = ({
  infoMessage,
  formProps,
  pipelineId,
}) => {
  const { data, refetch: refetchFirms } = useGetPipelineFirms(pipelineId);

  const firmOptions = useMemo(() => {
    if (data?.pipeline?.firms) {
      const firms = data.pipeline.firms;
      const options = firms.map((firm) => ({
        value: firm.id,
        label: firm.name,
      }));
      return options;
    } else {
      return [];
    }
  }, [data]);

  const notify = useNotification();

  return (
    <React.Fragment>
      <Row>
        {infoMessage && (
          <Column>
            <Alert message={infoMessage} variant={'info'} shadow={false} />
          </Column>
        )}
        <Column>
          <InputFormikField
            name={'firstName'}
            label={'First Name'}
            placeholder={`Enter the contact's first name...`}
          />
        </Column>
        <Column>
          <InputFormikField
            name={'lastName'}
            label={'Last Name'}
            placeholder={`Enter the contact's last name...`}
          />
        </Column>
        <Column>
          <SelectCreatableFormikFirmField
            name={'firm'}
            label={'Firm'}
            placeholder={'Select a firm...'}
            options={firmOptions}
            refresh={refetchFirms}
            form={formProps}
            pipelineId={pipelineId}
          />
        </Column>
        <Column>
          <InputFormikField
            name={'title'}
            label={'Title'}
            placeholder={'Enter a title...'}
          />
        </Column>
        <Column>
          <InputFormikField
            name={'email'}
            label={'Email'}
            placeholder={'Enter an email address...'}
          />
        </Column>
        <Column>
          <InputFormikField
            name={'cellPhone'}
            label={'Cell Phone'}
            placeholder={'Enter phone number...'}
          />
        </Column>
        <Column span={3}>
          <CheckboxFormikField
            name={'isPrimary'}
            label={'Key Contact'}
            horizontal={true}
          />
        </Column>
        <Column>
          <TextareaFormikField
            name={'notes'}
            label={'Notes'}
            placeholder={'Enter notes here...'}
            minRows={5}
          />
        </Column>
      </Row>
    </React.Fragment>
  );
};

export default ContactForm;
