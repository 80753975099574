import React, { FunctionComponent, useCallback, useContext, useEffect, useMemo, useRef } from 'react';
import {
  SidebarTheme,
  Sidebar,
  SidebarLink,
  LinkText,
  SidebarBottom,
  SidebarTop,
  SidebarButton,
  Separator,
  SidebarInfo,
  SidebarTitles,
  SidebarIcon,
  SidebarTitle,
  SidebarSubtitle,
  SidebarIconLink,
  OpenSidebarLink,
  SidebarSectionTitle,
  SidebarText,
  OpenSidebarExternalLink,
  SidebarExternalLink,
} from './Styles';
import { lightTheme, sizes } from 'shared/utils/styles';
import {
  useSidebarContext,
  useSidebarStorage,
} from 'shared/utils/sidebar/sidebar';
import { Tooltip } from 'shared/components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconName } from '@fortawesome/pro-light-svg-icons';
import { renderIcon } from '../button/Button';
import { isVisible, useIsMobileWidth, usePrevious, useWindowSize } from 'shared/hooks';
import { useHistory } from 'react-router-dom';
import { ThemeContext } from 'styled-components';

export const MainSidebar: FunctionComponent = ({ children }) => {
  const themeContext = useContext(ThemeContext);
  const { isMainSidebarOpen, setIsMainSidebarOpen } = useSidebarContext();
  useSidebarStorage('main', setIsMainSidebarOpen, isMainSidebarOpen);
  const left = 0;
  
  return (
    <Sidebar
      isSidebarOpen={isMainSidebarOpen}
      left={left}
      colors={themeContext.sidebar}
    >
      {children}
    </Sidebar>
  );
};

interface NestedSidebarProps {
  id: string;
  disableToggle?: boolean;
  bottom?: React.ReactNode;
}

export const NestedSidebar: FunctionComponent<NestedSidebarProps> = ({
  children,
  bottom,
  id,
  disableToggle = false,
}) => {
  const themeContext = useContext(ThemeContext);
  const {
    isMainSidebarOpen,
    isNestedSidebarOpen,
    setIsMainSidebarOpen,
    setIsNestedSidebarOpen,
  } = useSidebarContext();
  useSidebarStorage(id, setIsNestedSidebarOpen, isNestedSidebarOpen);

  const left =
    (isMainSidebarOpen
      ? sizes.appSidebarWidth
      : sizes.collapsedAppSidebarWidth) + 1;
  const iconName = isNestedSidebarOpen
    ? 'chevron-double-left'
    : 'chevron-double-right';
  const title = isNestedSidebarOpen ? 'Show Less' : 'Show More';

  const size = useWindowSize();

  const isMobileWidth = useMemo(() => {
    return (size.width ?? 0) <= 576;
  }, [size.width]);

  return (
    <Sidebar
      isSidebarOpen={isNestedSidebarOpen || false}
      left={left}
      colors={themeContext.nestedSidebar}
    >
      <div>
        {isMobileWidth && (
          <SidebarTop>
            <NestedSidebarButton
              title={'Back to Main Menu'}
              icon={'arrow-left'}
              onClick={() => {
                setIsNestedSidebarOpen(false);
              }}
              closeOnClick={false}
            />
            <NestedSidebarSeparator />
          </SidebarTop>
        )}
        {children}
      </div>
      <SidebarBottom>
        <NestedSidebarSeparator />
        {bottom && bottom}
        {!disableToggle && !isMobileWidth && (
          <NestedSidebarButton
            title={title}
            icon={iconName}
            onClick={() => setIsNestedSidebarOpen(!isNestedSidebarOpen)}
          />
        )}
      </SidebarBottom>
    </Sidebar>
  );
};

export const MainSidebarSeparator = () => {
  const themeContext = useContext(ThemeContext)
  return (
    <Separator color={themeContext.sidebar.separator} />
  )
};
export const NestedSidebarSeparator = () => {
  const themeContext = useContext(ThemeContext)
  return (
    <Separator color={themeContext.sidebar.separator} />
  )
};
interface SidebarHeaderProps {
  title: string;
  subtitle: string;
  path?: string;
  hideIcon?: boolean;
}

interface SidebarInfoProps {
  title: string;
  subtitle: string;
  colors: SidebarTheme;
  isSidebarOpen?: boolean;
  path?: string;
  hideIcon?: boolean;
}

const colors = [
  '#DA7657',
  '#6ADA57',
  '#5784DA',
  '#AA57DA',
  '#DA5757',
  '#DA5792',
  '#57DACA',
  '#57A5DA',
];

const getColorFromName = (name: string) =>
  colors[name.toLocaleLowerCase().charCodeAt(0) % colors.length];

const SidebarHeader: FunctionComponent<SidebarInfoProps> = ({
  path,
  isSidebarOpen,
  colors,
  title,
  subtitle,
  hideIcon = false,
}) => {
  return (
    <SidebarInfo isSidebarOpen={isSidebarOpen} colors={colors}>
      {!hideIcon && (
        <Tooltip
          content={`${title}: ${subtitle}`}
          disabled={isSidebarOpen}
          placement={'right'}
        >
          {path ? (
            <SidebarIconLink
              to={path}
              style={{ backgroundColor: getColorFromName(title) }}
            >
              {title.charAt(0)}
            </SidebarIconLink>
          ) : (
            <SidebarIcon style={{ backgroundColor: getColorFromName(title) }}>
              {title.charAt(0)}
            </SidebarIcon>
          )}
        </Tooltip>
      )}
      {isSidebarOpen && (
        <SidebarTitles>
          <SidebarTitle>{title}</SidebarTitle>
          <SidebarSubtitle>{subtitle}</SidebarSubtitle>
        </SidebarTitles>
      )}
    </SidebarInfo>
  );
};

export const NestedSidebarHeader: FunctionComponent<SidebarHeaderProps> = ({
  title,
  subtitle,
  path,
  ...rest
}) => {
  const themeContext = useContext(ThemeContext);
  const { isNestedSidebarOpen } = useSidebarContext();
  return (
    <SidebarHeader
      path={path}
      colors={themeContext.nestedSidebar}
      isSidebarOpen={isNestedSidebarOpen}
      title={title}
      subtitle={subtitle}
      {...rest}
    />
  );
};

export const MainSidebarHeader: FunctionComponent<SidebarHeaderProps> = ({
  title,
  subtitle,
  path,
}) => {
  const themeContext = useContext(ThemeContext);
  const { isMainSidebarOpen } = useSidebarContext();
  return (
    <SidebarHeader
      path={path}
      colors={themeContext.sidebar}
      isSidebarOpen={isMainSidebarOpen}
      title={title}
      subtitle={subtitle}
    />
  );
};

interface SidebarItemProps {
  hideOnSidebarClosed?: boolean;
}

interface SidebarLinkProps extends SidebarItemProps {
  icon: React.ReactNode | IconName;
  title: string;
  path: string;
  exact?: boolean;
}

export const MainSidebarLink: FunctionComponent<SidebarLinkProps> = ({
  icon,
  title,
  path,
  exact = false,
  hideOnSidebarClosed = false,
}) => {
  const themeContext = useContext(ThemeContext);
  const { isMainSidebarOpen, setIsNestedSidebarOpen, setIsMainSidebarOpen } = useSidebarContext();
  const isMobileWidth = useIsMobileWidth();
  const onClick = useCallback(() => {
    if (isMobileWidth) {
      setIsNestedSidebarOpen(false);
      setIsMainSidebarOpen(false);
    }
  }, [isMobileWidth]);
  const history = useHistory();
  return renderSidebarLink(
    isMainSidebarOpen,
    icon,
    title,
    path,
    exact,
    themeContext.sidebar,
    hideOnSidebarClosed,
    onClick,
    history
  );
};

export const NestedSidebarLink: FunctionComponent<SidebarLinkProps> = ({
  icon,
  title,
  path,
  exact = false,
  hideOnSidebarClosed = false,
}) => {
  const themeContext = useContext(ThemeContext);
  const { isNestedSidebarOpen, setIsNestedSidebarOpen, setIsMainSidebarOpen } = useSidebarContext();
  const isMobileWidth = useIsMobileWidth();
  const onClick = useCallback(() => {
    if (isMobileWidth) {
      setIsNestedSidebarOpen(false);
      setIsMainSidebarOpen(false);
    }
  }, [isMobileWidth]);
  const history = useHistory();
  return renderSidebarLink(
    isNestedSidebarOpen || false,
    icon,
    title,
    path,
    exact,
    themeContext.nestedSidebar,
    hideOnSidebarClosed,
    onClick,
    history,
  );
};

const renderSidebarLink = (
  isSidebarOpen: boolean,
  icon: IconName | React.ReactNode,
  title: string,
  path: string,
  exact: boolean,
  colors: SidebarTheme,
  hideOnSidebarClosed: boolean,
  onClick: () => void,
  history: any,
) => {
  const linkItem = () => {
    const isExternal = path.startsWith('http://') || path.startsWith('https://');
  
    if (isSidebarOpen) {
      return isExternal ? (
        <OpenSidebarExternalLink 
        href={path} target="_blank" 
        rel="noopener noreferrer" 
        style={{ color: colors.link }} 
        onClick={onClick}
        colors={colors}
        >
          {renderIcon(icon, false)}
          {isSidebarOpen && <LinkText>{title}</LinkText>}
        </OpenSidebarExternalLink>
      ) : (
        <OpenSidebarLink
          to={{ pathname: path, search: history.location.search }}
          colors={colors}
          exact={exact}
          onClick={onClick}
        >
          {renderIcon(icon, false)}
          {isSidebarOpen && <LinkText>{title}</LinkText>}
        </OpenSidebarLink>
      );
    } else {
      return isExternal ? (
        <SidebarExternalLink 
        href={path} target="_blank" 
        rel="noopener noreferrer" 
        style={{ color: colors.link }} 
        onClick={onClick}
        colors={colors}
        >
          {renderIcon(icon, false)}
          {isSidebarOpen && <LinkText>{title}</LinkText>}
        </SidebarExternalLink>
      ) : (
        <SidebarLink
          to={{ pathname: path, search: history.location.search }}
          colors={colors}
          exact={exact}
          onClick={onClick}
        >
          {renderIcon(icon, false)}
          {isSidebarOpen && <LinkText>{title}</LinkText>}
        </SidebarLink>
      );
    }
  };

  if (!isSidebarOpen && hideOnSidebarClosed) {
    return null;
  }
  if (!isSidebarOpen) {
    return (
      <Tooltip content={title} placement="right" offset={[0, 20]}>
        {linkItem()}
      </Tooltip>
    );
  } else {
    return linkItem();
  }
};

interface SidebarButtonProps extends SidebarItemProps {
  icon: IconName;
  title: string;
  onClick: () => void;
  closeOnClick?: boolean;
}

export const MainSidebarButton: FunctionComponent<SidebarButtonProps> = ({
  icon,
  title,
  onClick,
  hideOnSidebarClosed = false,
  closeOnClick = true,
}) => {
  const themeContext = useContext(ThemeContext);
  const { isMainSidebarOpen, setIsNestedSidebarOpen, setIsMainSidebarOpen } = useSidebarContext();
  const isMobileWidth = useIsMobileWidth();
  const onClickDefault = useCallback(() => {
    if (isMobileWidth) {
      setIsNestedSidebarOpen(false);
      setIsMainSidebarOpen(false);
    }
  }, [isMobileWidth]);
  return renderSidebarButton(
    isMainSidebarOpen,
    icon,
    title,
    onClick,
    themeContext.sidebar,
    hideOnSidebarClosed,
    closeOnClick ? onClickDefault : () => false,
  );
};

export const NestedSidebarButton: FunctionComponent<SidebarButtonProps> = ({
  icon,
  title,
  onClick,
  hideOnSidebarClosed = false,
  closeOnClick = true,
}) => {
  const themeContext = useContext(ThemeContext);
  const { isNestedSidebarOpen, setIsNestedSidebarOpen, setIsMainSidebarOpen } = useSidebarContext();
  const isMobileWidth = useIsMobileWidth();
  const onClickDefault = useCallback(() => {
    if (isMobileWidth) {
      setIsNestedSidebarOpen(false);
      setIsMainSidebarOpen(false);
    }
  }, [isMobileWidth]);
  return renderSidebarButton(
    isNestedSidebarOpen || false,
    icon,
    title,
    onClick,
    themeContext.nestedSidebar,
    hideOnSidebarClosed,
    closeOnClick ? onClickDefault : () => false,
  );
};

const renderSidebarButton = (
  isSidebarOpen: boolean,
  icon: IconName,
  title: string,
  onClick: () => void,
  colors: SidebarTheme,
  hideOnSidebarClosed: boolean,
  onClickDefault: () => void,
) => {
  const buttonItem = (
    <SidebarButton
      isSidebarOpen={isSidebarOpen}
      colors={colors}
      onClick={() => {
        onClickDefault();
        onClick();
      }}
    >
      <FontAwesomeIcon icon={['fal', icon]} fixedWidth={true} />
      {isSidebarOpen && <LinkText>{title}</LinkText>}
    </SidebarButton>
  );

  if (!isSidebarOpen && hideOnSidebarClosed) {
    return null;
  } else if (!isSidebarOpen) {
    return (
      <Tooltip content={title} placement="right" offset={[0, 20]}>
        {buttonItem}
      </Tooltip>
    );
  } else {
    return buttonItem;
  }
};

interface SidebarSectionTitleProps extends SidebarItemProps {
  title: string;
}

export const MainSidebarSectionTitle: FunctionComponent<SidebarSectionTitleProps> = ({
  title,
  hideOnSidebarClosed = false,
}) => {
  const themeContext = useContext(ThemeContext);
  const { isMainSidebarOpen } = useSidebarContext();
  return renderSidebarSectionTitle(
    isMainSidebarOpen,
    title,
    themeContext.sidebar,
    hideOnSidebarClosed
  );
};

export const MainSidebarText: FunctionComponent<SidebarSectionTitleProps> = ({
  title,
  hideOnSidebarClosed = false,
}) => {
  const themeContext = useContext(ThemeContext);
  const { isMainSidebarOpen } = useSidebarContext();
  return renderSidebarText(
    isMainSidebarOpen,
    title,
    themeContext.sidebar,
    hideOnSidebarClosed
  );
};

export const NestedSidebarSectionTitle: FunctionComponent<SidebarSectionTitleProps> = ({
  title,
  hideOnSidebarClosed = false,
}) => {
  const themeContext = useContext(ThemeContext);
  const { isNestedSidebarOpen } = useSidebarContext();
  return renderSidebarSectionTitle(
    isNestedSidebarOpen || false,
    title,
    themeContext.nestedSidebar,
    hideOnSidebarClosed
  );
};

const renderSidebarSectionTitle = (
  isSidebarOpen: boolean,
  title: string,
  colors: SidebarTheme,
  hideOnSidebarClosed: boolean
) => {
  const titleItem = (
    <SidebarSectionTitle colors={colors}>{title}</SidebarSectionTitle>
  );

  if (!isSidebarOpen && hideOnSidebarClosed) {
    return null;
  } else if (!isSidebarOpen) {
    return <Separator color={colors.separator} />;
  } else {
    return titleItem;
  }
};

const renderSidebarText = (
  isSidebarOpen: boolean,
  title: string,
  colors: SidebarTheme,
  hideOnSidebarClosed: boolean
) => {
  const titleItem = <SidebarText colors={colors}>{title}</SidebarText>;

  if (!isSidebarOpen && hideOnSidebarClosed) {
    return null;
  } else if (!isSidebarOpen) {
    return <Separator color={colors.separator} />;
  } else {
    return titleItem;
  }
};
