import React, { Component } from 'react';
import styled from 'styled-components';
import { DatePicker } from 'shared/components';
import { ICellEditorParams } from 'ag-grid-community';
import { parse, format } from 'date-fns';

type DateCellEditorParams = { 
  formatString?: string;
};

export default class DateCellEditor extends Component<ICellEditorParams & DateCellEditorParams> {

  state = {
    value: this.props.value,
  }

  componentDidUpdate = (prevProps: ICellEditorParams) => {
    if(prevProps.value !== this.props.value) {
      this.setState({ value: prevProps.value })
    }
  }

  getValue() {
    return this.state.value;
  }

  isPopup() {
    return true;
  }

  onChange = (date: Date | [Date, Date] | null) => {
    if (date) {
      this.setState({ value: date }, () => this.props.api?.stopEditing());
    }
  }

  render() {
    const val = typeof this.state.value === "string" 
      ? parse(this.state.value, 'MM/dd/yyyy', new Date()) 
      : this.state.value || undefined;

    return (
      <Container>
        <DatePicker 
          onChange={this.onChange}
          selected={val}
          inline={true}
        />
      </Container>
    );
  }
}

const Container = styled.div`
  width: 100%;
`