import { useState, useLayoutEffect, useEffect, useRef, useMemo } from "react";

export function useWindowSize() {
  const isClient = typeof window === 'object';

  function getSize() {
    return {
      width: isClient ? window.innerWidth : undefined,
      height: isClient ? window.innerHeight : undefined
    };
  }

  const [windowSize, setWindowSize] = useState(getSize);

  useEffect(() => {
    if (!isClient) {
      return;
    }
    
    function handleResize() {
      setWindowSize(getSize());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []); // Empty array ensures that effect is only run on mount and unmount

  return windowSize;
}

export function useIsMobileWidth() {
  const size = useWindowSize();
  const isMobileWidth = useMemo(() => {
    return (size.width ?? 0) <= 576;
  }, [size.width]);

  return isMobileWidth;
}

export function useIsMobileDevice() {
  if (/Mobi|Android|iPhone/i.test(navigator.userAgent)) {
    return true;
  } else {
    return false;
  }
}

export const useRemainingHeight = (offset = 0) => {
  const ref = useRef<HTMLDivElement | null>(null);
  const [height, setHeight] = useState("0px");
  const size = useWindowSize();
  useLayoutEffect(() => {
    if (ref.current) {
      const height = size.height || 0;
      const elementOffset = ref.current.offsetTop;
      const style = window.getComputedStyle(ref.current)
      const margin = Number(style.marginTop.slice(0, -2)) + Number(style.marginBottom.slice(0, -2));
      const padding = Number(style.paddingTop.slice(0, -2)) + Number(style.paddingBottom.slice(0, -2));
      setHeight(`${height - elementOffset - margin - padding - offset}px`);
    } else {
      setHeight(`0px`);
    }
  });
  return { ref, height };
}