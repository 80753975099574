import React, { useMemo } from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from '../button/Button';
import { font, mixin } from 'shared/utils/styles';
import Avatar from '../avatar/Avatar';
import { Tooltip, SelectOption, Label } from '..';
import { parseISO, formatDistanceToNow } from 'date-fns';
import * as Yup from 'yup';
import {
  getNodesStringLength,
  OptionalTextEditorSchema,
  RequiredTextEditorSchema,
  SlateEditedTextProps,
  TextEditedContent,
} from '../form/textEditor/TextEditor';
import { formatDate } from 'shared/utils/formatting/formatters';

export type StatusVariant =
  | 'secondary'
  | 'success'
  | 'primary'
  | 'info'
  | 'warning'
  | 'danger';

export interface StatusProps extends React.HTMLAttributes<HTMLDivElement> {
  statusUpdateId: number;
  title: string;
  message: string;
  status: Status;
  labels: LabelValue[];
  variant?: StatusVariant;
  authorName: string;
  modifiedDate: string;
  onViewComments?: () => void;
  onViewDetails?: () => void;
  oppId: number;
  commentCount: number;
}

const StatusWrapper = styled.div`
  background-color: ${({theme}) => theme.backgroundPrimary};
  overflow: hidden;
  position: relative;
  border-radius: 5px;
  border: 1px solid ${({ theme }) => theme.border};
`;

const StatusBar = styled.div<{ variant: StatusVariant }>`
  width: 5px;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  background-color: ${(props) => props.theme[props.variant]};
`;

const StatusContent = styled.div`
  display: flex;
  align-items: flex-start;
  padding: 10px 10px;
`;

const StatusContentsWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  width: 100%;
  ${mixin.clickable};
`;

const StatusTextWrapper = styled.div`
  width: 100%;
  margin: 0 10px;
`;

const StatusTitleWrapper = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

const StatusTitle = styled.div`
  flex-grow: 1;
  ${font.size(14)};
  ${font.black};
  color: ${({ theme }) => theme.textDarkest};
`;

export const StatusNameWrapper = styled.div`
  flex-grow: 1;
  display: inline-flex;
  align-items: center;
  justify-content: flex-end;

  & > * {
    margin-left: 5px;
  }
`;

export const StatusIndicator = styled.div<{ variant: StatusVariant }>`
  ${font.size(8)};
  color: ${(props) => props.theme[props.variant]};
`;

export const StatusName = styled.div`
  ${font.defaultSize};
  ${font.medium};
  color: ${({ theme }) => theme.textLight};
`;

const StatusFooter = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

const StatusFooterLeft = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;

  & > * {
    margin-right: 10px;
  }
`;

const StatusFooterRight = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: flex-end;

  & > * {
    margin-left: 10px;
  }
`;

const StatusAvatarWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const StatusAvatarText = styled.div`
  ${font.medium};
  ${font.defaultSize};
  color: ${({ theme }) => theme.textDark};
`;

const StatusModifiedText = styled.div`
  align-items: center;
  // ${font.medium};
  ${font.size(11)};
  color: ${({ theme }) => theme.textLight};
`;

const StatusMessage = styled.div`
  color: ${({ theme }) => theme.textMedium};
  ${font.defaultSize};
  margin-top: 10px;
  margin-bottom: 20px;
`;

type Label = {
  value: number;
  label: string;
  color?: string | null;
}

export type StatusUpdateValues = {
  title: string;
  message: any;
  sections: StatusUpdateSection[];
  status: SelectOption;
  labels: Label[];
}

type StatusUpdateSection = {
  title: string;
  content?: any;
}

type Status = {
  id: number;
  name: string;
};

type LabelValue = {
  id: number;
  name: string;
  color?: string | null;
};

export const statusUpdateSchema: Yup.ObjectSchema<StatusUpdateValues> = Yup.object()
  .shape({
    title: Yup.string()
      .min(3, 'Too Short!')
      .max(50, 'Too Long!')
      .required()
      .label('Title'),
    message: RequiredTextEditorSchema().label('Summary'),
    status: Yup.object()
      .shape({
        value: Yup.number().required(),
        label: Yup.string().required(),
      })
      .required()
      .label('Status'),
    sections: Yup.array()
      .of(
        Yup.object()
          .shape({
            title: Yup.string().required(),
            content: OptionalTextEditorSchema.label('Content'),
          })
          .required()
      )
      .defined(),
    labels: Yup.array()
      .of(
        Yup.object()
          .shape({
            value: Yup.number().required(),
            label: Yup.string().required(),
            color: Yup.string().notRequired().nullable(),
          })
          .required()
      )
      .defined(),
  })
  .required();

export type StatusUpdateTemplateValues = {
  title: string;
  message?: any;
  sections: StatusUpdateSection[];
  labels: Label[];
};

export const statusUpdateTemplateSchema: Yup.ObjectSchema<StatusUpdateTemplateValues> = Yup.object()
  .shape({
    title: Yup.string()
      .min(3, 'Too Short!')
      .max(50, 'Too Long!')
      .required()
      .label('Title'),
    message: OptionalTextEditorSchema.label('Summary'),
    sections: Yup.array()
      .of(
        Yup.object()
          .shape({
            title: Yup.string().required(),
            content: OptionalTextEditorSchema.label('Content'),
          })
          .required()
      )
      .defined(),
      labels: Yup.array()
      .of(
        Yup.object()
          .shape({
            value: Yup.number().required(),
            label: Yup.string().required(),
            color: Yup.string().notRequired().nullable(),
          })
          .required()
      )
      .defined(),
  })
  .required();

const StatusCard: React.FunctionComponent<StatusProps> = ({
  title,
  message,
  status,
  labels,
  variant = 'info',
  authorName,
  modifiedDate,
  onViewComments,
  onViewDetails,
  commentCount,
  ...rest
}) => {
  const modifiedDateDate = parseISO(modifiedDate);
  const modifiedDateDisplay = formatDate(modifiedDateDate);
  const modifiedRelativeDateDisplay = formatDistanceToNow(modifiedDateDate, {
    addSuffix: true,
  });

  const shouldBeTruncated = useMemo(() => {
    try {
      const content = JSON.parse(message);
      const currentLength = getNodesStringLength(content);
      return currentLength > 300; 
    } catch {
      return false;
    }
  }, [message]);

  const contents = (
    <StatusContentsWrapper onClick={onViewDetails}>
      <StatusTextWrapper>
        <StatusTitleWrapper>
          <StatusTitle>
            <React.Fragment>{title}</React.Fragment>
          </StatusTitle>
          <StatusNameWrapper>
            <StatusIndicator variant={variant}>
              <FontAwesomeIcon icon={['fas', 'circle']} fixedWidth={true} />
            </StatusIndicator>
            <StatusName>{status.name}</StatusName>
          </StatusNameWrapper>
        </StatusTitleWrapper>
        <StatusMessage>
          <StatusCardTextEditedContent value={message} shouldBeTruncated={shouldBeTruncated}/>
        </StatusMessage>
        <StatusLabelsWrapper>
            {labels.map(x => (
              <Label key={x.id} name={x.name} color={x.color || undefined}/>
            ))}
        </StatusLabelsWrapper>
        <StatusFooter>
          <StatusFooterLeft>
            <Avatar name={authorName} />
            <StatusAvatarWrapper>
              <StatusAvatarText>{authorName}</StatusAvatarText>
              <StatusModifiedText>{modifiedRelativeDateDisplay + " | " + modifiedDateDisplay}</StatusModifiedText>
            </StatusAvatarWrapper>
          </StatusFooterLeft>
          <StatusFooterRight>
            {onViewComments && (
              <Tooltip content={'View Comments'}>
                <Button
                  slim={true}
                  variant={'simple'}
                  icon={'comments-alt'}
                  onClick={(e) => {
                    e.stopPropagation();
                    onViewComments();
                  }}
                >
                  {commentCount || null}
                </Button>
              </Tooltip>
            )}
          </StatusFooterRight>
        </StatusFooter>
      </StatusTextWrapper>
    </StatusContentsWrapper>
  );

  return (
    <StatusWrapper {...rest}>
      <StatusBar variant={variant} />
      <StatusContent>{contents}</StatusContent>
    </StatusWrapper>
  );
};

interface StatusCardTextEditedContentProps extends SlateEditedTextProps {
  shouldBeTruncated: boolean;
}

const StatusLabelsWrapper = styled.div`
  margin: 10px 0;
  & > * {
    margin-bottom: 5px;
  }
  & > *:not(:last-child) {
    margin-right: 5px;
  }
`

const StatusCardTextEditedContent = styled(TextEditedContent)<StatusCardTextEditedContentProps>`
  max-height: 125px;
  overflow-y: hidden;
  ${props => props.shouldBeTruncated && 
    `mask-image: linear-gradient(to bottom, black 50%, transparent 100%);`
  }
`;

export default StatusCard;
