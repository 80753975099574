import React, { FunctionComponent } from 'react'
import styled from 'styled-components'
import TextareaAutosize, { TextareaAutosizeProps } from 'react-textarea-autosize';
import FieldWrapper, { FieldWrapperProps } from '.';
import { useField } from 'formik';

interface TextareaProps extends TextareaAutosizeProps {
  borderless?: boolean;
}

const StyledTextarea = styled(TextareaAutosize)<TextareaProps>`
  width: 100%;  
  outline: none;
  padding: ${props => props.borderless ? '2px' : '7px'};
  background-color: ${({ theme }) => theme.backgroundPrimary};
  border: 1px solid ${props => props.borderless ? 'transparent' : props.theme.border};
  border-radius: 3px;
  color: ${({ theme }) => theme.textDark};
  resize: none;

  &::placeholder {
    color: ${({ theme }) => theme.textMedium};
  }

  &:disabled {
    background-color: ${({ theme }) => theme.disabledInputBackground};
    color: ${({ theme }) => theme.disabledInputText};
  }

  &:focus {
    border: 1px solid ${({ theme }) => theme.info};
  }

  ${(props) =>
    props.borderless &&
    `
    &:hover:not(:focus) {
      border: 1px solid ${props.theme.border};
    }
  `}
`

export const Textarea: FunctionComponent<TextareaProps> = ({ ...props }) => {
  return (
    <StyledTextarea minRows={2} {...props}/>
  )
}

interface TextareaFieldProps extends TextareaProps, FieldWrapperProps {
  name: string;
  borderless?: boolean;
}

export const TextareaField: FunctionComponent<TextareaFieldProps> = ({ label, error, placeholder = "Enter a value", ...props }) => {
  return (
    <FieldWrapper label={label} error={error} {...props}>
      <Textarea
        placeholder={placeholder}
        {...props}
      />
    </FieldWrapper>
  )
}

export const TextareaFormikField: FunctionComponent<TextareaFieldProps> = ({
  name,
  ...props
}) => {
  const [field, meta] = useField(name);
  return (
    <TextareaField error={meta.error} {...props} {...field} onBlur={props.onBlur}/>
  );
};