import React, { FunctionComponent } from 'react';
import { match, Route, Switch, useRouteMatch } from 'react-router-dom';
import Redirect from 'shared/components/Redirect';
import { PageDetails, PageHeader } from 'shared/components';
import NoAccess from 'shared/components/noAccess';
import { useBreadcrumb, usePageTitle } from 'shared/hooks';
import PipelineContacts from './Contacts';
import ContactDetailsPage from './ContactDetailsPage';
import PipelineFirms from './Firms';
import FirmDetailsPage from './FirmDetailsPage';

const Contacts = () => {
  const match = useRouteMatch();
  usePageTitle('Contact Management');
  useBreadcrumb(
    {
      title: 'Address Books',
      path: match.url,
      key: 'address-books',
      index: 2,
    },
    []
  );

  usePageTitle('Contacts');
  return (
    <React.Fragment>
      <Switch>
        <Route
          path={`${match.path}/firms`}
          exact={true}
          render={() => <ContactsMain match={match} />}
        />
        <Route
          path={`${match.path}/contacts`}
          exact={true}
          render={() => <ContactsMain match={match} />}
        />
        <Route
          path={`${match.path}/contact-details/:contactId`}
          render={() => <ContactDetailsPage />}
        />
        <Route
          path={`${match.path}/firm-details/:firmId`}
          render={() => <FirmDetailsPage />}
        />
        <Redirect to={`${match.url}/firms`} />
      </Switch>
    </React.Fragment>
  );
};

interface ContactsMainProps {
  match: match<{}>;
}

const ContactsMain: FunctionComponent<ContactsMainProps> = ({
  match,
}) => {

  return (
    <React.Fragment>
      <PageHeader
        title={'Address Book'}
        subtitle={'Firms and Contacts'}
        description={
          'Use this module to manage the list of firms and their contacts that are referenced as partners or points of contact for your organization.'
        }
        tabs={[
          { title: 'Firms', path: `/firms` },
          { title: 'Contacts', path: `/contacts` },
        ]}
        match={match}
      />
      <Switch>
        <Route
          path={`${match.path}/firms`}
          render={() => <PipelineFirms />}
        />
        <Route
          path={`${match.path}/contacts`}
          render={() => <PipelineContacts />}
        />
        <Redirect to={`${match.url}/firms`} />
      </Switch>
    </React.Fragment>
  );
};

export default Contacts;
