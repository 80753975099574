import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormikProps } from 'formik';
import React, { FunctionComponent, useContext } from 'react';
import { FormFieldWrapper, InputFormikField, Row, Column } from 'shared/components';
import FieldWrapper from 'shared/components/form';
import { mixin } from 'shared/utils/styles';
import { PartialNullable } from 'shared/utils/utilityTypes';
import styled, { ThemeConsumer, ThemeContext } from 'styled-components';
import * as Yup from 'yup';

export interface LabelValues {
  name: string;
  color: string;
}

export const labelValidationSchema: Yup.ObjectSchema<LabelValues> = Yup.object()
  .shape({
    name: Yup.string()
      .max(50, 'Too Long!')
      .required('Required')
      .label('Name'),
    color: Yup.string()
      .required('Required')
      .label('Color'),
  })
  .required();

interface LabelFormProps {
  form: FormikProps<PartialNullable<LabelValues>>;
}

const LabelForm: FunctionComponent<LabelFormProps> = ({
  form,
}) => {
  const themeContext = useContext(ThemeContext);
  const onColorSelected = (color: string) => {
    form.setFieldValue('color', color);
  }

  return (
    <React.Fragment>
      <FormFieldWrapper>
        <InputFormikField
          name={'name'}
          label={'Name'}
        />
      </FormFieldWrapper>
      <FormFieldWrapper>
        <FieldWrapper label={'Color'} error={form.errors.color}>
          <Row gap={5}>
            <Column span={3}>
              <ColorOption color={themeContext.success} onClick={onColorSelected} selected={themeContext.success == form.values.color}/>
            </Column>
            <Column span={3}>
              <ColorOption color={themeContext.danger} onClick={onColorSelected} selected={themeContext.danger == form.values.color}/>
            </Column>
            <Column span={3}>
              <ColorOption color={themeContext.warning} onClick={onColorSelected} selected={themeContext.warning == form.values.color}/>
            </Column>
            <Column span={3}>
              <ColorOption color={themeContext.info} onClick={onColorSelected} selected={themeContext.info == form.values.color}/>
            </Column>
            <Column span={3}>
              <ColorOption color={themeContext.lightBlue} onClick={onColorSelected} selected={themeContext.lightBlue == form.values.color}/>
            </Column>
            <Column span={3}>
              <ColorOption color={themeContext.mint} onClick={onColorSelected} selected={themeContext.mint == form.values.color}/>
            </Column>
            <Column span={3}>
              <ColorOption color={themeContext.purple} onClick={onColorSelected} selected={themeContext.purple == form.values.color}/>
            </Column>
            <Column span={3}>
              <ColorOption color={themeContext.pink} onClick={onColorSelected} selected={themeContext.pink == form.values.color}/>
            </Column>
          </Row>
        </FieldWrapper>
      </FormFieldWrapper>
    </React.Fragment>
  );
};

type ColorOptionProps = {
  color: string;
  selected: boolean;
  onClick: (color: string) => void;
}

const ColorOption: FunctionComponent<ColorOptionProps> = ({
  color,
  selected,
  onClick,
}) => {
  return (
  <ColorOptionDiv color={color} onClick={() => onClick(color)}>
    {selected && <FontAwesomeIcon icon={['fas', 'check']}/>}
  </ColorOptionDiv>
  )
}

const ColorOptionDiv = styled.div<{color: string}>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 30px;
  background: ${props => props.color};
  border-radius: 5px;
  cursor: pointer;

  color: #fff;

  &:hover {
    background: ${props => mixin.darken(props.color, 0.2)};
  }
`;

export default LabelForm;