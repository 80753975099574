import { CellClickedEvent, ColDef } from 'ag-grid-community';
import {
  DealDataTable,
  DealDataTableRow,
} from 'App/Organization/Deal/Summary/SummaryDashboard';
import React, { FunctionComponent, useMemo, useState } from 'react';
import { useRouteMatch, useHistory, useParams } from 'react-router-dom';
import {
  Button,
  CenteredSpinner,
  Column,
  PageDetails,
  Row,
  Section,
  Table,
  ToolbarSearchInput,
} from 'shared/components';
import NoAccess from 'shared/components/noAccess';
import { useBreadcrumb, useTable } from 'shared/hooks';
import { useGetContact } from 'shared/hooks/api';
import { tableDateFormat } from 'shared/utils/formatting/tableFormatters';
import { QueryParamModalHelpers } from 'shared/utils/queryParamModal';
import { ContactCard, ContactCardWrapper } from '../../FirmDetailsPage/Details';
import { InteractionRow } from '../Interactions';

type DealAssociation = {
  id: number | undefined;
  name: string;
};

interface ContactDetailsProps {
  newInteractionHelpers: QueryParamModalHelpers;
  setSelectedInteraction: (interaction: InteractionRow) => void;
}

const ContactDetails: FunctionComponent<ContactDetailsProps> = ({
  newInteractionHelpers,
  setSelectedInteraction,
}) => {
  const { orgName, contactId, pipelineId } = useParams<{ orgName: string; contactId: string; pipelineId: string; }>();
  const { data, status } = useGetContact(Number(contactId));
  const match = useRouteMatch();
  const history = useHistory();

  useBreadcrumb(
    {
      title: data?.contact
        ? `${data.contact.firstName} ${data.contact.lastName}`
        : 'Loading...',
      path: match.url,
      key: 'contact',
      index: 3,
    },
    [data?.contact]
  );

  const { searchText, setSearchText, gridEvents, tableRef } = useTable({
    autoSizeColumns: true,
  });

  const associatedDeals = useMemo(() => {
    const deals: DealAssociation[] =
      data?.contact?.associatedDeals.map((x) => ({
        id: x.id,
        name: x.name,
      })) || [];
    if (
      data?.contact?.inaccessibleAssociatedDealsCount &&
      data?.contact?.inaccessibleAssociatedDealsCount > 0
    ) {
      deals.push({
        id: undefined,
        name: `${data?.contact?.inaccessibleAssociatedDealsCount} Private Deals`,
      });
    }
    return deals;
  }, [data]);

  const recentInteractions: InteractionRow[] = useMemo(() => {
    return (
      data?.contact?.interactions?.slice(0, 4)?.map((interaction) => ({
        id: interaction.id,
        name: interaction.name,
        type: interaction.type.name,
        date: interaction.interactionDate,
        attendees: interaction.attendees
          .map((attendee) => attendee.name)
          .join(', '),
        deals: interaction.deals.map((deal) => deal.name).join(', '),
        notes: interaction.notes,
      })) ?? []
    );
  }, [data]);

  const {
    searchText: interactionsSearchText,
    setSearchText: setInteractionsSearchText,
    gridEvents: interactionsGridEvents,
  } = useTable({
    autoSizeColumns: true,
  });

  const [columnDefs, setColumnDefs] = useState<ColDef[]>([
    {
      headerName: 'Name',
      field: 'name',
      suppressMenu: true
    },
    {
      headerName: 'Type',
      field: 'type',
      suppressMenu: true
    },
    {
      headerName: 'Date',
      field: 'date',
      suppressMenu: true,
      valueFormatter: tableDateFormat
    },
    {
      headerName: 'Attendees',
      field: 'attendees',
      suppressMenu: true
    }
  ]);

  const [associatedDealsColumnDefs, setAssociatedDealsColumnDefs] = useState<ColDef[]>([
    {
      headerName: 'ID',
      field: 'id',
      suppressMenu: true
    },
    {
      headerName: 'Name',
      field: 'name',
      onCellClicked: (params: CellClickedEvent) =>
        history.push(`/${orgName}/deals/${params.data.id}`),
      cellStyle: { cursor: 'pointer' }
    }
  ]);

  return (
    <React.Fragment>
      {!data?.contact && status == 'loading' ? (
        <CenteredSpinner />
      ) : (
        data?.contact && (
          <React.Fragment>
            <PageDetails>
              {/* <Debug state={data.contact} /> */}
              <Row>
                <Column xs={12} sm={4}>
                  <ContactCard
                    title={data.contact.firstName + ' ' + data.contact.lastName}
                    subtitle={'Details'}
                  >
                    <DealDataTable>
                      <DealDataTableRow
                        label={'First Name'}
                        display={data.contact.firstName}
                      />
                      <DealDataTableRow
                        label={'Last Name'}
                        display={data.contact.lastName}
                      />
                      <DealDataTableRow
                        label={'Firm'}
                        display={data.contact.firm?.name ?? 'N/A'}
                      />
                      <DealDataTableRow
                        label={'Title'}
                        display={data.contact.title ?? 'N/A'}
                      />
                      <DealDataTableRow
                        label={'Email'}
                        display={data.contact.email ?? 'N/A'}
                      />
                      <DealDataTableRow
                        label={'Phone'}
                        display={data.contact.cellPhone ?? 'N/A'}
                      />
                    </DealDataTable>
                  </ContactCard>
                </Column>
                <Column xs={12} sm={8}>
                  <ContactCardWrapper style={{ marginBottom: '20px' }}>
                    <Section
                      title={'Recent Interactions'}
                      icon={'comments-alt'}
                      left={[
                        <ToolbarSearchInput
                          key={'search-input'}
                          value={interactionsSearchText}
                          onChange={(e) =>
                            setInteractionsSearchText(e.target.value)
                          }
                          placeholder={'Filter interactions by typing here...'}
                        />,
                      ]}
                      right={[
                        <Button
                          key={'log-interaction'}
                          icon={'comment-alt-plus'}
                          slim={true}
                          variant={'simple'}
                          onClick={newInteractionHelpers.open}
                          disabled={false}
                        >
                          Log Interaction
                        </Button>,
                        <Button
                          key={'view-all'}
                          icon={'list-ul'}
                          slim={true}
                          variant={'simple'}
                          onClick={() => history.push(`/${orgName}/address-books/${pipelineId}/contact-details/${contactId}/interactions`)}
                        >
                          View All Interactions
                        </Button>,
                      ]}
                      style={{ minHeight: '150px', maxHeight: '300px' }}
                    >
                      {recentInteractions.length > 0 ? (
                        <Table
                          rowData={recentInteractions}
                          columnDefs={columnDefs}
                          defaultColDef={{ sortable: true }}
                          quickFilterText={interactionsSearchText}
                          {...interactionsGridEvents}
                          style={{ height: '100%', width: '100%' }}
                          isLoading={status == 'loading'}
                          noRowsOverlayIcon={'comments-alt'}
                          noRowsOverlaySubtitle={`There haven't been any recent interactions with this contact...`}
                          suppressRowClickSelection={true}
                          statusBar={{
                            statusPanels: [],
                          }}
                          domLayout={'autoHeight'}
                          onRowClicked={(params) =>
                            setSelectedInteraction(params.data)
                          }
                        >
                        </Table>
                      ) : (
                        <NoAccess
                          icon={'comments-alt'}
                          subtitle={`There haven't been any recent interactions with this contact...`}
                        />
                      )}
                    </Section>
                  </ContactCardWrapper>
                  <ContactCardWrapper style={{ marginBottom: '20px' }}>
                    <Section
                      title={'Associated Deals'}
                      icon={'building'}
                      left={[
                        <ToolbarSearchInput
                          key={'search-input'}
                          value={searchText}
                          onChange={(e) =>
                            setSearchText(e.target.value)
                          }
                          placeholder={'Filter deals by typing here...'}
                        />,
                      ]}
                      style={{ minHeight: '150px', maxHeight: '300px' }}
                    >
                      {associatedDeals.length > 0 ? (
                        <Table
                          rowData={associatedDeals}
                          columnDefs={associatedDealsColumnDefs}
                          defaultColDef={{ sortable: true }}
                          quickFilterText={searchText}
                          {...gridEvents}
                          style={{ height: '100%', width: '100%' }}
                          isLoading={status == 'loading'}
                          noRowsOverlayIcon={'building'}
                          noRowsOverlaySubtitle={
                            'This contact is not associated with any deals...'
                          }
                          suppressRowClickSelection={true}
                          statusBar={{
                            statusPanels: [],
                          }}
                          domLayout={'autoHeight'}
                          // headerHeight={0}
                        >
                        </Table>
                      ) : (
                        <NoAccess
                          icon={'building'}
                          subtitle={
                            'This contact is not associated with any deals...'
                          }
                        />
                      )}
                    </Section>
                  </ContactCardWrapper>
                </Column>
              </Row>
            </PageDetails>
          </React.Fragment>
        )
      )}
    </React.Fragment>
  );
};

export default ContactDetails;
