import React, { FunctionComponent, useMemo } from 'react';
import {
  CreateFirmInput,
} from 'shared/hooks/api/graphql/generated';
import { useCreateFirm, useGetFirm } from 'shared/hooks/api';
import { useNotification } from 'shared/components/notifications';
import { useParams } from 'react-router-dom';
import { FormModal, PageDetails } from 'shared/components';
import { PartialNullable } from 'shared/utils/utilityTypes';
import FirmFormDetails from './FirmFormDetails';
import { firmFormSchema, FirmFormValues } from './UpdateFirmModal';

interface CreateFirmModalProps {
  onClose: () => void;
  onSuccess: () => void;
}

const CreateFirmModal: FunctionComponent<CreateFirmModalProps> = ({
  onClose,
  onSuccess,
}) => {
  const { firmId, pipelineId } = useParams<{ firmId: string; pipelineId: string }>();
  const [createFirm, { status }] = useCreateFirm();
  const { data, error, isFetching, refetch } = useGetFirm(Number(firmId));
  const notify = useNotification();

  const initialValues: PartialNullable<FirmFormValues> = useMemo(() => {
    return {
      type: null,
      status: null,
      headquartersMarket: null,
      productAppetite: null,
      relationshipLead: null,
      primaryRegion: null,
    };
  }, [data]);

  return (
    <FormModal<FirmFormValues>
      initialValues={initialValues}
      title={'Create Firm'}
      submitTitle={'Create'}
      onClose={onClose}
      isWorking={status === 'loading'}
      validationSchema={firmFormSchema}
      handleSubmit={async (form, resetForm) => {
        const input: CreateFirmInput = {
          name: form.name,
          fkFirmType: form.type?.value,
          fkMarketHq: form.headquartersMarket?.value,
          fkInvestmentTypeAppetite: form.productAppetite?.value,
          fkCrmStatus: form.status?.value,
          fkUserRelationshipLead: form.relationshipLead?.value,
          website: form.website,
          fkPipeline: Number(pipelineId),
          fkPrimaryRegion: form.primaryRegion?.value,
        };
        try {
          const result: any = await createFirm(input);
          if (result.errors && result.errors[0].extensions?.code === 'UNIQUE_CONSTRAINT') {
            notify({
              duration: 7000,
              title: 'Uh-oh!',
              message: `A Firm with this name already exists; please create a new firm or update the existing one.`,
              variant: 'danger',
            });
          }
          else if (result.errors) {
            notify({
              duration: 5000,
              title: 'Uh-oh!',
              message: `An unknown error occurred while creating the firm!`,
              variant: 'danger',
            });
          } else {
            switch (result.data?.createFirm?.__typename) {
              case 'Firm':
                notify({
                  duration: 3000,
                  title: 'Success!',
                  message: 'Firm Created!',
                  variant: 'success',
                });
                onClose();
                onSuccess();
                resetForm();
                break;
              default:
                notify({
                  duration: 5000,
                  title: 'Failed to Create Firm',
                  message: 'An error occurred while creating the firm!',
                  variant: 'danger',
                });
                break;
            }
          }
        } catch (error) {
          notify({
            duration: 5000,
            title: 'Uh-oh!',
            message: `An unknown error occurred while creating the firm! ${error}`,
            variant: 'danger',
          });
        }
      }}
    >
      {(props) => (
        <PageDetails>
          <FirmFormDetails create={true} form={props} />
        </PageDetails>
      )}
    </FormModal>
  );
};

export default CreateFirmModal;
