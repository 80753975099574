import React, {
  useState,
  FunctionComponent,
  HTMLAttributes,
  useCallback,
  useMemo,
  useContext,
} from 'react';
import { useDropdownToggle, useDropdownMenu } from 'react-overlays';
import Dropdown from 'react-overlays/Dropdown';
import styled from 'styled-components';
import { font, mixin } from 'shared/utils/styles';
import Button, { ButtonProps, renderIcon } from '../button/Button';
import { IconName } from '@fortawesome/pro-light-svg-icons';
import { Link, LinkProps } from 'react-router-dom';

type MenuAnchor = 'left' | 'right';

export interface DropdownButtonProps extends ButtonProps {
  element?: any;
  title?: any;
  anchor?: MenuAnchor;
  closeOnClick?: boolean;
}

interface DropdownContextValue {
  closeOnClick: boolean;
}

const DropdownContext = React.createContext<DropdownContextValue>({
  closeOnClick: true,
});

export const DropdownButton: FunctionComponent<DropdownButtonProps> = ({
  children,
  element,
  title,
  icon,
  style,
  anchor = 'left',
  slim = false,
  variant = 'standard',
  color = 'secondary',
  isActive = false,
  closeOnClick = true,
  ...rest
}) => {
  const [show, setShow] = useState(false);
  return (
    <DropdownContext.Provider value={{ closeOnClick }}>
      <Dropdown
        show={show}
        onToggle={() => setShow(!show)}
        drop={'down'}
        alignEnd={anchor == 'left' ? false : true}
        itemSelector={'button:not(:disabled)'}
      >
        {({ props }) => (
          <div {...props}>
            <Toggle
              element={element}
              isActive={isActive || show}
              slim={slim}
              variant={variant}
              color={color}
              icon={icon}
              style={style}
              {...rest}
            >
              {title}
            </Toggle>
            <Menu show={show}>{children}</Menu>
          </div>
        )}
      </Dropdown>
    </DropdownContext.Provider>
  );
};

interface MenuSeparatorProps {
  title?: string;
}

export const MenuSeparator: FunctionComponent<MenuSeparatorProps> = ({
  title,
}) => {
  return (
    <React.Fragment>
      {title ? (
        <StyledMenuSeparator hasContent={true}>
          <MenuSeparatorTitle>{title}</MenuSeparatorTitle>
        </StyledMenuSeparator>
      ) : (
        <StyledMenuSeparator hasContent={false} />
      )}
    </React.Fragment>
  );
};

export const StyledMenuSeparator = styled.div<{ hasContent: boolean }>`
  ${(props) => !props.hasContent && `height: 0`};
  border-bottom: 1px solid ${({ theme }) => theme.border};
  width: 100%;
`;

const MenuSeparatorTitle = styled.div`
  ${font.size(12)};
  ${font.medium};
  padding: 7px 10px;
`;

const MenuItemComponentWrapper = styled.button`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 7px 10px;
  white-space: nowrap;
  color: ${({theme}) => theme.textDarkest};
  ${mixin.clickable};

  &:hover,
  &:focus {
    background-color: ${({ theme }) => theme.border};
  }

  & > * {
    margin-right: 10px;
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
    pointer-events: none;
  }

`;

const MenuExternalLinkComponentWrapper = styled.a`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 7px 10px;
  white-space: nowrap;
  ${mixin.clickable};

  &:hover,
  &:focus {
    background-color: ${({ theme }) => theme.border};
  }

  & > * {
    margin-right: 10px;
  }
`;

const MenuLinkComponentWrapper = styled(Link)`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 7px 10px;
  white-space: nowrap;
  ${mixin.clickable};

  &:hover,
  &:focus {
    background-color: ${({ theme }) => theme.border};
  }

  & > * {
    margin-right: 10px;
  }
`;

const InteractiveMenuItemComponentWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 7px 10px;
  white-space: nowrap;
`;

export const InteractiveDropdownItem: FunctionComponent<HTMLAttributes<
  HTMLDivElement
>> = ({ children, ...props }) => {
  return (
    <InteractiveMenuItemComponentWrapper {...props}>
      {children}
    </InteractiveMenuItemComponentWrapper>
  );
};

export interface DropdownItemProps extends HTMLAttributes<HTMLButtonElement> {
  disabled?: boolean;
  icon?: IconName | React.ReactNode;
}

export const DropdownItem: FunctionComponent<DropdownItemProps> = ({
  children,
  disabled = false,
  icon,
  ...props
}) => {
  const { close } = useDropdownMenu();
  const { closeOnClick } = useContext(DropdownContext);

  const handleClick = useCallback(
    (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      if (props.onClick) {
        props.onClick(event);
      }
      if (closeOnClick) {
        close((event as unknown) as Event);
      }
    },
    [props.onClick, closeOnClick, close]
  );
  return (
    <MenuItemComponentWrapper
      {...props}
      type={'button'}
      disabled={disabled}
      onClick={handleClick}
    >
      {icon && renderIcon(icon, false)}
      {children}
    </MenuItemComponentWrapper>
  );
};

export interface DropdownLinkProps extends HTMLAttributes<HTMLAnchorElement>, LinkProps {
  icon?: IconName | React.ReactNode;
  external?: boolean;
}

export const DropdownLink: FunctionComponent<DropdownLinkProps> = ({
  children,
  icon,
  external = false,
  ...props
}) => {
  return (
    <React.Fragment>
      {external ? (
        <MenuExternalLinkComponentWrapper {...props}>
          {icon && renderIcon(icon, false)}
          {children}
        </MenuExternalLinkComponentWrapper>
      ) : (
        <MenuLinkComponentWrapper {...props}>
          {icon && renderIcon(icon, false)}
          {children}
        </MenuLinkComponentWrapper>
      )}
    </React.Fragment>
  );
};

export default DropdownButton;

const MenuWrapper = styled.div<{ show: boolean }>`
  display: ${(props) => (props.show ? 'flex' : 'none')};
  flex-direction: column;
  position: absolute;
  background-color: ${({theme}) => theme.backgroundPrimary};
  color: ${({theme}) => theme.textDark};
  box-shadow: 0 0 0 1px rgba(111,119,130,.15), 0 5px 20px 0 rgba(21,27,38,.08);
  z-index: 100000;
  min-width: 100px;
  border-radius: 3px;
  max-height: 350px;
  overflow: auto;

  &::-webkit-scrollbar {
    width: 12px;
  }
  
  &::-webkit-scrollbar-track {
    background: ${({theme}) => theme.backgroundPrimary};
  }
  
  &::-webkit-scrollbar-thumb {
    background-color: ${({theme}) => theme.backgroundMedium};
    border-radius: 10px;
    border: 3px solid ${({theme}) => theme.backgroundPrimary}; 
  }

  scrollbar-color: ${({theme}) => theme.backgroundMedium} ${({theme}) => theme.backgroundPrimary};
  scrollbar-width: normal;
`;

interface MenuProps {
  show: boolean;
}

const Menu: FunctionComponent<MenuProps> = ({ children, show }) => {
  const { props } = useDropdownMenu({ offset: [0, 5] });
  return (
    <MenuWrapper {...props} show={show}>
      {children}
    </MenuWrapper>
  );
};

interface ToggleProps extends ButtonProps {
  element?: any;
}

const Toggle: FunctionComponent<ToggleProps> = ({ element, ...rest }) => {
  const [props, { show, toggle }] = useDropdownToggle();
  const handleClick = useCallback(
    (e: any) => {
      if (rest.onClick) {
        rest.onClick(e);
      }
      toggle(!show, e);
    },
    [rest.onClick, toggle, show]
  );
  const el = useMemo(() => {
    if (element) {
      return React.cloneElement(element, {
        ...props,
        onClick: (e) => handleClick(e),
      });
    }
  }, [element, handleClick]);
  return el ? (
    el
  ) : (
    <Button type={'button'} {...rest} {...props} onClick={handleClick} />
  );
};
