import React, { FunctionComponent, useMemo } from 'react';
import {
  ErrorFragment,
  UpdateFirmInput,
} from 'shared/hooks/api/graphql/generated';
import {
  useUpdateFirm,
  useGetFirm,
} from 'shared/hooks/api';
import { useNotification } from 'shared/components/notifications';
import deepDiff, { getDiffValue, diff } from 'shared/utils/deepDiff';
import { useParams } from 'react-router-dom';
import { FormModal, PageDetails } from 'shared/components';
import { PartialNullable } from 'shared/utils/utilityTypes';
import * as Yup from 'yup';
import { OptionSchema } from './CreateInteractionModal';
import FirmFormDetails from './FirmFormDetails';
import isUrl from 'is-url';

interface UpdateFirmModalProps {
  onClose: () => void;
  onSuccess: () => void;
  oppId?: number;
}

type Option =  {
  value: number;
  label: string;
}

export type FirmFormValues = {
  name: string;
  type?: Option | null;
  status?: Option | null;
  headquartersMarket?: Option | null;
  productAppetite?: Option | null;
  relationshipLead?: Option | null;
  primaryRegion?: Option | null;
  website?: string | null;
}

export const firmFormSchema: Yup.ObjectSchema<FirmFormValues> = Yup.object().shape({
  name: Yup.string()
    .min(2, 'Too Short!')
    .required("Required")
    .label("Name"),
  website: Yup.string()
    .min(2, 'Too Short!')
    .max(200, 'Too Long!')
    .label("Website")
    .test('is-url', 'Must be a valid URL', (value) => {
      return value != undefined && value != null ? isUrl(value) : true;
    }).nullable(),
  type: OptionSchema().nullable().label('Type'),
  status: OptionSchema().nullable().label('Status'),
  headquartersMarket: OptionSchema().nullable().label('Headquarters (Market)'),
  productAppetite: OptionSchema().nullable().label('Product Appetite'),
  relationshipLead: OptionSchema().nullable().label('Relationship Lead'),
  primaryRegion: OptionSchema().nullable().label('Primary Region'),
}).required();

const UpdateFirmModal: FunctionComponent<UpdateFirmModalProps> = ({
  onClose,
  onSuccess,
}) => {
  const { firmId } = useParams<{ firmId: string }>();
  const [updateFirm, { status }] = useUpdateFirm();
  const { data, error, isFetching, refetch } = useGetFirm(Number(firmId));
  const notify = useNotification();

  const initialValues: PartialNullable<FirmFormValues> = useMemo(() => {
    return {
      name: data?.firm?.name,
      website: data?.firm?.website,
      type: data?.firm?.type ? { value: data.firm.type.id, label: data.firm.type.name } : null,
      status: data?.firm?.status ? { value: data.firm.status.id, label: data.firm.status.name } : null,
      headquartersMarket: data?.firm?.headquartersMarket ? { value: data.firm.headquartersMarket.id, label: data.firm.headquartersMarket.name } : null,
      productAppetite: data?.firm?.productAppetite ? { value: data.firm.productAppetite.id, label: data.firm.productAppetite.name } : null,
      relationshipLead: data?.firm?.relationshipLead ? { value: data.firm.relationshipLead.id, label: data.firm.relationshipLead.displayName } : null,
      primaryRegion: null,
    }
  }, [data]);

  return (
    <FormModal<FirmFormValues>
      initialValues={initialValues}
      title={'Update Firm'}
      submitTitle={'Update'}
      onClose={onClose}
      isWorking={status === 'loading'}
      validationSchema={firmFormSchema}
      handleSubmit={async (form, resetForm) => {
        const changes = diff(initialValues, form);
        const input: UpdateFirmInput = {
          id: Number(firmId),
          name: changes.name,
          fkFirmType: changes.type?.value,
          fkMarketHq: changes.headquartersMarket?.value,
          fkInvestmentTypeAppetite: changes.productAppetite?.value,
          fkCrmStatus: changes.status?.value,
          fkUserRelationshipLead: changes.relationshipLead?.value,
          website: changes.website,
        };
        try {
          const result = await updateFirm(input);
          if (result.errors) {
            notify({
              duration: 5000,
              title: 'Uh-oh!',
              message: `An unknown error occurred while updating the firm!`,
              variant: 'danger',
            });
          } else {
            switch (result.data?.updateFirm?.__typename) {
              case 'Firm':
                notify({
                  duration: 3000,
                  title: 'Success!',
                  message: 'Firm Updated!',
                  variant: 'success',
                });
                onClose();
                onSuccess();
                resetForm();
                break;
              default:
                notify({
                  duration: 5000,
                  title: 'Failed to Update Firm',
                  message: 'An error occurred while updating the firm!',
                  variant: 'danger',
                });
                break;
            }
          }
        } catch (error) {
          notify({
            duration: 5000,
            title: 'Uh-oh!',
            message: `An unknown error occurred while updating the firm! ${error}`,
            variant: 'danger',
          });
        }
      }}
    >
      {(props) => (
        <PageDetails>
          <FirmFormDetails form={props}/>
        </PageDetails>
      )}
    </FormModal>
  );
};

export default UpdateFirmModal;
