import styled from "styled-components";

import { mixin, zIndexValues, sizes, font } from "shared/utils/styles";
import { NavLink, NavLinkProps } from "react-router-dom";

export interface SidebarProps {
	isSidebarOpen: boolean;
	left: number;
	colors: SidebarTheme;
}

export interface SidebarTheme {
	background: string;
	border: string;
	separator: string;
	link: string;
	activeBackground: string;
	activeIcon: string;
}

export const Sidebar = styled.div<SidebarProps>`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	position: fixed;
	z-index: ${zIndexValues.navLeft - 1};
	top: ${sizes.navbarHeight}px;
	left: ${props => props.left}px;
	bottom: 0;
	width: ${props => props.isSidebarOpen ? `${sizes.appSidebarWidth}px` : `${sizes.collapsedAppSidebarWidth}px` };
	border-right: 1px solid ${props => props.colors.border};
	${mixin.scrollableY}
	${mixin.customScrollbar()}
	background-color: ${props => props.colors.background};
	padding: 15px 10px;
	transition: 200ms width ease-in-out, 200ms left ease-in-out;
	z-index: 101;

	@media (max-width: 576px) {
    left: ${props => props.isSidebarOpen ? 0 : -sizes.appSidebarWidth}px;
		width: ${sizes.appSidebarWidth}px;
		z-index: 1000;
  }
`;

export const SidebarLinks = styled.div``;

export const SidebarInfo = styled.div<{isSidebarOpen: boolean | undefined; colors: SidebarTheme}>`
	display: flex;
	align-items: center;
	padding: 10px 0 10px 3.25px;
	margin-bottom: 15px;
	color: ${props => mixin.darken(props.colors.link, 0.2)};
`

export const SidebarTitles = styled.div`
	display: flex;
	flex-direction: column;
	margin-left: 10px;
`

export const SidebarTitle = styled.div`
  ${font.size(15)};
  ${font.medium};
	margin-bottom: 5px;
`

export const SidebarIconLink = styled(NavLink)`
	display: flex;
	align-items: center;
	justify-content: center;
	${mixin.clickable};
	height: 36px;
	min-height: 36px;
	max-height: 36px;
	width: 36px;
	min-width: 36px;
	max-width: 36px;
	${font.size(14)};
	${font.medium};
	border-radius: 5px;
	color: #fff;
	background-color: ${({ theme }) => theme.primary};
`

export const SidebarIcon = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	${mixin.clickable};
	height: 36px;
	min-height: 36px;
	max-height: 36px;
	width: 36px;
	min-width: 36px;
	max-width: 36px;
	${font.size(14)};
	${font.medium};
	border-radius: 5px;
	color: #fff;
	background-color: ${({ theme }) => theme.primary};
`

export const SidebarSubtitle = styled.div`
  ${font.defaultSize};
`

export const SidebarTop = styled.div``;
export const SidebarBottom = styled.div``;

interface SidebarItemProps {
	isSidebarOpen?: boolean;
	colors: SidebarTheme;
}

interface LinkItemProps extends NavLinkProps, SidebarItemProps {}

interface ExternalLinkItemProps {
	colors: SidebarTheme;
	isSidebarOpen?: boolean;
}

export const OpenSidebarExternalLink = styled.a<ExternalLinkItemProps>`
	position: relative;
	display: flex;
	align-items: center;
	padding: 10px 10px;
	border-radius: 3px;
	color: ${props => mixin.darken(props.colors.link, 0.2)};
	${mixin.clickable}
	width: ${sizes.appSidebarWidth - 20}px;
	&:hover {
		color: ${props => props.colors.link};
		background: ${props => mixin.lighten(props.colors.background, 0.5)};
	}

	& > svg {
		font-size: 16px;
	}

	&.active {
		color: ${props => props.colors.activeIcon};
		${font.medium};
		background: ${props => props.colors.activeBackground};
	}

	& > div {
		color: ${props => props.colors.link};
	}
`;

export const SidebarExternalLink = styled.a<ExternalLinkItemProps>`
	position: relative;
	display: flex;
	align-items: center;
	padding: 10px 10px;
	border-radius: 3px;
	color: ${props => mixin.darken(props.colors.link, 0.2)};
	${mixin.clickable}
	width: ${sizes.collapsedAppSidebarWidth - 20}px;
	&:hover {
		color: ${props => props.colors.link};
		background: ${props => mixin.lighten(props.colors.background, 0.5)};
	}

	& > svg {
		font-size: 16px;
	}

	&.active {
		color: ${props => props.colors.activeIcon};
		${font.medium};
		background: ${props => props.colors.activeBackground};
	}

	& > div {
		color: ${props => props.colors.link};
	}
`;

export const OpenSidebarLink = styled(NavLink)<LinkItemProps>`
	position: relative;
	display: flex;
	align-items: center;
	padding: 10px 10px;
	border-radius: 3px;
	color: ${props => mixin.darken(props.colors.link, 0.2)};
	${mixin.clickable}
	width: ${sizes.appSidebarWidth - 20}px;
	&:hover {
		${props => !props.to && `cursor: not-allowed`};
		color: ${props => props.colors.link};
		background: ${props => mixin.lighten(props.colors.background, 0.5)};
	}

	& > svg {
		font-size: 16px;
	}

	&.active {
		color: ${props => props.colors.activeIcon};
		${font.medium};
		background: ${props => props.colors.activeBackground};
	}

	& > div {
		color: ${props => props.colors.link};
	}
`;

export const SidebarLink = styled(NavLink)<LinkItemProps>`
	position: relative;
	display: flex;
	align-items: center;
	padding: 10px 10px;
	border-radius: 3px;
	color: ${props => mixin.darken(props.colors.link, 0.2)};
	${mixin.clickable}
	width: ${sizes.collapsedAppSidebarWidth - 20}px};
	&:hover {
		${props => !props.to && `cursor: not-allowed`};
		color: ${props => props.colors.link};
		background: ${props => mixin.lighten(props.colors.background, 0.5)};
	}

	& > svg {
		font-size: 16px;
	}

	&.active {
		color: ${props => props.colors.activeIcon};
		${font.medium};
		background: ${props => props.colors.activeBackground};
	}

	& > div {
		color: ${props => props.colors.link};
	}
`;

export const SidebarButton = styled.button<SidebarItemProps>`
	position: relative;
	display: flex;
	align-items: center;
	padding: 10px 10px;
	border-radius: 3px;
	color: ${props => mixin.darken(props.colors.link, 0.2)};
	// ${font.size(14)};
	${mixin.clickable}
	width: ${props => props.isSidebarOpen ? `${sizes.appSidebarWidth - 20}px` : `${sizes.collapsedAppSidebarWidth - 20}px`};
	&:hover {
		color: ${props => props.colors.link};
		background: ${props => mixin.lighten(props.colors.background, 0.5)};
	}

	& > svg {
		font-size: 16px;
	}

	& > div {
		color: ${props => props.colors.link};
	}
`;

export const LinkText = styled.div`
	margin-left: 15px;
`;

export const Separator = styled.div<{ color: string }>`
	height: 0;
	border-bottom: 1px solid ${props => props.color};
	margin: 10px 0;
	width: 100%;
`;

export const SidebarSectionTitle = styled.div<SidebarItemProps>`
	position: relative;
	display: flex;
	align-items: center;
	padding: 10px 10px;
	color: ${props => mixin.darken(props.colors.link, 0)};
	${font.bold};
	width: ${props => !props.isSidebarOpen ? `${sizes.appSidebarWidth - 20}px` : `${sizes.collapsedAppSidebarWidth - 20}px`};
`

export const SidebarText = styled.div<SidebarItemProps>`
	position: relative;
	display: flex;
	align-items: center;
	padding: 10px 10px;
	color: ${props => mixin.darken(props.colors.link, 0)};
	${font.regular};
	width: ${props => !props.isSidebarOpen ? `${sizes.appSidebarWidth - 20}px` : `${sizes.collapsedAppSidebarWidth - 20}px`};
`
