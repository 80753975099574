import styled from 'styled-components';
import { Editable } from 'slate-react';
import { EditableProps } from 'slate-react/dist/components/editable';

interface StyledEditableWrapperProps {
  isFocused: boolean;
  readOnly: boolean;
  borderless: boolean;
}

export const StyledEditableWrapper = styled.div<StyledEditableWrapperProps>`
  ${(props) =>
    !props.readOnly &&
    `
  ${!props.borderless ? `border: 1px solid ${props.theme.border};` : 'border: none;'}
  border-radius: 3px;
  ${props.isFocused && `border: 1px solid ${props.theme.info};`}
`}
`;

export const StyledEditable = styled(Editable)`
  ${(props) =>
    !props.readOnly &&
    `
  min-height: 30px;
  padding: 7.5px;
  overflow-y: auto;
`}
`;

export const TextEditorTools = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 5px 5px;
`;

export const TextEditorToolsLeft = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;

  & > *:not(last-child) {
    margin-right: 5px;
  }
`;

export const TextEditorToolsRight = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  & > *:not(last-child) {
    margin-left: 5px;
  }
`;
