/* eslint-disable react/display-name */
import React, { FunctionComponent, forwardRef, HTMLAttributes } from 'react';
import styled from 'styled-components';
import { font, mixin } from 'shared/utils/styles';
import { Button, Tooltip } from 'shared/components';
import { ReactDatePickerProps } from 'react-datepicker';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const CardWrapper = styled.div`
  background-color: ${({theme}) => theme.backgroundPrimary};
  overflow: hidden;
  border-radius: 5px;
  border: 1px solid ${({ theme }) => theme.border};
  padding: 10px 10px;
`

const CardHeader = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 5px;
  height: 32px;
`;

const CardHeaderLeft = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
`;

const CardHeaderRight = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
`;

const CardHeaderTitle = styled.h4`
  ${font.size(14)};
  ${font.bold};
  color: ${({ theme }) => theme.textDarkest};
  margin-right: 10px;
`

const CardHeaderInfo = styled.h4`
  ${font.size(14)};
  ${font.bold};
  color: ${({ theme }) => mixin.lighten(theme.textLight, 0.4)};
`

const CardContent = styled.div`
`;

interface DashboardCardProps extends HTMLAttributes<HTMLDivElement> {
  title: string;
  info?: string;
  children: React.ReactNode;
  tools?: React.ReactNode[];
}

const DashboardCard = forwardRef<HTMLDivElement, DashboardCardProps>(({
  title,
  info,
  tools = [],
  children,
  ...props
}, ref) => {
  return (
    <CardWrapper {...props} ref={ref}>
      <CardHeader>
        <CardHeaderLeft>
          <CardHeaderTitle>
            {title}
          </CardHeaderTitle>
          { info &&
            <Tooltip content={info} placement={"right"}>
              <CardHeaderInfo>
                <FontAwesomeIcon icon={['fas', "info-circle"]} />
              </CardHeaderInfo>
            </Tooltip>
          }
        </CardHeaderLeft>
        {tools.length > 0 && <CardHeaderRight>{tools}</CardHeaderRight>}
      </CardHeader>
      <CardContent>
        {children}
      </CardContent>
    </CardWrapper>
  );
});

export default DashboardCard;