import React, { FunctionComponent, useContext, useEffect, useMemo, useRef, useState } from 'react';
import styled, { ThemeConsumer, ThemeContext } from 'styled-components';
import Button from '../button/Button';
import { font } from 'shared/utils/styles';
import Avatar from '../avatar/Avatar';
import {
  Dialog,
  Form,
  DropdownButton,
  TextEditedContent,
  TextEditorFormikField,
} from '..';
import { DropdownItem, MenuSeparator } from '../menu/DropdownButton';
import { Textarea } from '../form/Textarea';
import { TextareaAutosizeProps } from 'react-textarea-autosize';
import { formatDatetime } from 'shared/utils/formatting/formatters';
import {
  CommentSubjectEnum,
  UpdateCommentInput,
} from 'shared/hooks/api/graphql/generated';
import { useNotification } from '../notifications';
import * as Yup from 'yup';
import { useDeleteComment, useUpdateComment } from 'shared/hooks/api';
import { PartialNullable } from 'shared/utils/utilityTypes';
import { ReactEditor } from 'slate-react';
import { Editor, Transforms } from 'slate';

export interface CommentProps extends React.HTMLAttributes<HTMLDivElement> {
  commentId: number;
  commentSubjectType: CommentSubjectEnum;
  message: string;
  authorName: string;
  createdAt: string;
  updatedAt: string;
  userIsAuthor: boolean;
  onCommentUpdated: () => void;
}

export interface CommentInputProps extends TextareaAutosizeProps {
  authorName?: string;
}

const CommentWrapper = styled.div`
  background-color: #fff;
  position: relative;
`;

const CommentContent = styled.div`
  display: flex;
  align-items: flex-start;
`;

const CommentContentsWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  width: 100%;
`;

const CommentTextWrapper = styled.div`
  width: 100%;
  margin: 0 10px;
`;
const AvatarWrapper = styled.div`
  flex-shrink: 0;
`;

const CommentHeader = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 10px;
`;

const CommentHeaderLeft = styled.div`
  display: inline-flex;
  align-items: center;
  width: 100%;
  justify-content: flex-start;

  & > * {
    margin-right: 10px;
  }
`;

const CommentHeaderRight = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: flex-end;

  & > * {
    margin-left: 10px;
  }
`;

const CommentAvatarText = styled.div`
  ${font.medium};
  ${font.defaultSize};
  color: ${({ theme }) => theme.textDark};
`;

const CommentModifiedText = styled.div<{ light?: boolean }>`
  ${font.medium};
  ${font.size(12)};
  color: ${(props) => (props.light ? props.theme.textLight : props.theme.textMedium)};
`;

const CommentMessage = styled.div`
  color: ${({ theme }) => theme.textMedium};
  ${font.defaultSize};
  margin-bottom: 10px;
`;

export const CommentInput: React.FunctionComponent<CommentInputProps> = ({
  authorName,
  ...props
}) => {
  const contents = (
    <CommentContentsWrapper>
      <AvatarWrapper>
        <Avatar name={authorName} />
      </AvatarWrapper>
      <CommentTextWrapper>
        <Textarea minRows={3} {...props} />
      </CommentTextWrapper>
    </CommentContentsWrapper>
  );
  return (
    <CommentWrapper>
      <CommentContent>{contents}</CommentContent>
    </CommentWrapper>
  );
};

const CommentCard: React.FunctionComponent<CommentProps> = ({
  commentId,
  commentSubjectType,
  message,
  authorName,
  createdAt,
  updatedAt,
  onCommentUpdated,
  userIsAuthor,
  ...rest
}) => {
  const themeContext = useContext(ThemeContext);
  const [isEditing, setIsEditing] = useState(false);
  const [isShowingDeleteDialog, setIsShowingDeleteDialog] = useState(false);
  const [deleteComment, { status: deleteCommentStatus }] = useDeleteComment();

  const notify = useNotification();
  const contents = (
    <React.Fragment>
      <CommentContentsWrapper>
        <AvatarWrapper>
          <Avatar name={authorName} />
        </AvatarWrapper>
        <CommentTextWrapper>
          <CommentHeader>
            <CommentHeaderLeft>
              <CommentAvatarText>{authorName}</CommentAvatarText>
              <CommentModifiedText>
                {formatDatetime(createdAt)}
              </CommentModifiedText>
              <CommentModifiedText light={true}>
                {updatedAt != createdAt &&
                  `(Updated ${formatDatetime(updatedAt)})`}
              </CommentModifiedText>
            </CommentHeaderLeft>
            <CommentHeaderRight>
              {userIsAuthor && !isEditing && (
                <DropdownButton
                  slim={true}
                  variant={'simple'}
                  icon={'chevron-down'}
                  anchor={'right'}
                >
                  <DropdownItem
                    icon={'pencil'}
                    onClick={() => setIsEditing(true)}
                  >
                    Edit
                  </DropdownItem>
                  <MenuSeparator />
                  <DropdownItem
                    icon={'trash'}
                    style={{ color: themeContext.danger }}
                    onClick={() => setIsShowingDeleteDialog(true)}
                  >
                    Delete
                  </DropdownItem>
                </DropdownButton>
              )}
            </CommentHeaderRight>
          </CommentHeader>
          {isEditing ? (
            <CommentEditingForm
              commentId={commentId}
              commentBody={message}
              setIsEditing={setIsEditing}
              onCommentUpdated={onCommentUpdated}
            />
          ) : (
            <CommentMessage>
              <TextEditedContent value={message} />
            </CommentMessage>
          )}
        </CommentTextWrapper>
      </CommentContentsWrapper>
      {isShowingDeleteDialog && (
        <Dialog
          variant={'danger'}
          isOpen={true}
          title={'Delete Comment'}
          confirmTitle={'Yes, Delete'}
          message={`Are you sure you want to delete this comment?`}
          isWorking={deleteCommentStatus == 'loading'}
          onConfirm={async () => {
            try {
              const result = await deleteComment({
                id: commentId,
                subjectType: commentSubjectType,
              });
              if (result.errors) {
                notify({
                  duration: 5000,
                  variant: 'danger',
                  message: 'An error occcurred while deleting this comment.',
                });
                setIsShowingDeleteDialog(false);
              } else {
                setIsShowingDeleteDialog(false);
                notify({
                  message: 'Comment deleted!',
                });
                onCommentUpdated();
              }
            } catch {
              notify({
                duration: 5000,
                variant: 'danger',
                message: 'An error occcurred while deleting this comment.',
              });
              setIsShowingDeleteDialog(false);
            }
          }}
          onClose={() => setIsShowingDeleteDialog(false)}
          onCancel={() => setIsShowingDeleteDialog(false)}
        />
      )}
    </React.Fragment>
  );
  return (
    <CommentWrapper {...rest}>
      <CommentContent>{contents}</CommentContent>
    </CommentWrapper>
  );
};

interface CommentEditingFormProps {
  commentId: number;
  commentBody: string;
  setIsEditing: (value: React.SetStateAction<boolean>) => void;
  onCommentUpdated: () => void;
}

type CommentValues = {
  body: string;
};

const commentUpdateSchema: Yup.ObjectSchema<CommentValues> = Yup.object()
  .shape({
    body: Yup.string().required().label("Comment"),
  })
  .required();

const CommentEditingForm: FunctionComponent<CommentEditingFormProps> = ({
  commentId,
  commentBody,
  setIsEditing,
  onCommentUpdated,
}) => {
  const [updateComment, { status: updateCommentStatus }] = useUpdateComment();
  const notify = useNotification();
  const initialValues: PartialNullable<CommentValues> = useMemo(
    () => ({
      body: JSON.parse(commentBody),
    }),
    [commentBody]
  );

  const editorRef = useRef<ReactEditor>();

  return (
    <Form<CommentValues>
      initialValues={initialValues}
      handleSubmit={async (values) => {
        const body = JSON.stringify(values.body);
        const comment: UpdateCommentInput = {
          id: commentId,
          body: body,
        };
        try {
          const result = await updateComment({ comment });
          if (!result.errors) {
            setIsEditing(false);
            onCommentUpdated();
            notify({
              variant: 'success',
              message: 'Comment Updated!',
            });
          } else {
            notify({
              variant: 'danger',
              message: 'An error occurred while editing this comment.',
            });
          }
        } catch (error) {
          notify({
            variant: 'danger',
            message: 'An error occurred while editing this comment.',
          });
        }
      }}
      validationSchema={commentUpdateSchema}
    >
      {(props) => (
        <CommentEditingFormWrapper>
          <TextEditorFormikField
            name={'body'}
            placeholder={'Enter your comment here...'}
            autoFocus={true}
            editorRef={editorRef}
            tools={
              <React.Fragment>
                <Button
                  slim={true}
                  variant={'outline'}
                  onClick={() => {
                    props.resetForm();
                    setIsEditing(false);
                  }}
                >
                  Cancel
                </Button>
                <Button
                  slim={true}
                  color={'success'}
                  onClick={props.submitForm}
                  isWorking={updateCommentStatus == 'loading'}
                  disabled={!props.dirty || !props.isValid}
                >
                  Save
                </Button>
              </React.Fragment>
            }
          />
        </CommentEditingFormWrapper>
      )}
    </Form>
  );
};

const CommentEditingFormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 10px;
  margin-bottom: 10px;

  & > *:not(:last-child) {
    margin-bottom: 10px;
  }
`;

export default CommentCard;
