import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import { useQueryParamState } from 'shared/utils/queryParamHelpers';
import {
  getAuthorizedHeaders,
  restBaseUrl,
} from 'shared/hooks/api/client/queries';
import { fileDownload } from 'shared/utils/fileDownload';
import { Spinner } from 'shared/components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { lightTheme } from 'shared/utils/styles';

const ApprovalPackageExhibitDownloader = () => {
  const [approvalPackageId] = useQueryParamState<number | undefined>(
    'approvalPackageId',
    undefined
  );
  const [fileId] = useQueryParamState<number | undefined>('fileId', undefined);
  const [fileName] = useQueryParamState<string | undefined>(
    'fileName',
    undefined
  );
  const [isDownloaded, setIsDownloaded] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);
  const [didErrorOccur, setDidErrorOccur] = useState(false);

  const onDownloadExhibit = useCallback(
    async (fileId: number, fileName?: string) => {
      if (!fileName) {
        console.error('Filename is not provided');
        setDidErrorOccur(true);
        setIsDownloaded(true);
        return;
      }

      const headers = await getAuthorizedHeaders();
      setIsDownloading(true);
      try {
        const result = await axios({
          url: `${restBaseUrl}documents/publishedApprovalPackage/${approvalPackageId}/${fileId}`,
          headers: headers,
          responseType: 'blob',
        });
        fileDownload(result.data, decodeURIComponent(fileName));
      } catch (error) {
        setDidErrorOccur(true);
      } finally {
        setIsDownloading(false);
        setIsDownloaded(true);
      }
    },
    [approvalPackageId]
  );

  useEffect(() => {
    if (fileId && fileName && approvalPackageId && isDownloaded === false) {
      onDownloadExhibit(fileId, fileName);
    } else {
      console.log('nope', fileId, fileName, approvalPackageId, isDownloaded);
    }
  }, [fileId, fileName, approvalPackageId, isDownloaded, onDownloadExhibit]);

  return (
    <Container>
      <div style={{ height: '35px' }}>
        {isDownloading || !isDownloaded ? <Spinner /> : <FontAwesomeIcon icon={['fal', didErrorOccur ? 'exclamation-triangle' : 'file-download']} style={{ fontSize: '30px', color: lightTheme.primary }} />}
      </div>
      <div style={{ fontSize: '15px' }}>{!isDownloaded ? `Downloading the requested file...` : didErrorOccur ? 'Well, this is awkward... an error occurred while downloading the requested file.' : `All done. Enjoy your file!`}</div>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 50px;

  & > *:not(:last-child) {
    margin-bottom: 30px;
  }
`;

export default ApprovalPackageExhibitDownloader;
