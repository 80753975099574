import React, {
  FunctionComponent,
  useCallback,
  useMemo,
  useRef,
} from 'react';
import {
  SideMenu,
  PageDetails,
  Row,
  Column,
  HorizontalSeparator,
  Button,
  TextEditorFormikField,
  Form,
  CenteredSpinner,
} from 'shared/components';
import CommentCard from 'shared/components/card/CommentCard';
import { useNotification } from 'shared/components/notifications';
import { useCreateComment, useGetCurrentUser } from 'shared/hooks/api';
import {
  CommentSubjectEnum,
  CreateCommentInput,
} from 'shared/hooks/api/graphql/generated';
import styled from 'styled-components';
import * as Yup from 'yup';
import { parseISO } from 'date-fns';
import NoAccess from 'shared/components/noAccess';
import { OptionalTextEditorSchema } from 'shared/components/form/textEditor/TextEditor';
import { ReactEditor } from 'slate-react';

interface CommentsProps {
  onClose: () => void;
  onCommentUpdated: () => void;
  comments: Comment[];
  isLoading: boolean;
  subjectId: number;
  commentSubjectType: CommentSubjectEnum;
  title?: string;
  subtitle?: string;
}

type Comment = {
  id: number;
  body: string;
  author: Author;
  updatedAt: string;
  createdAt: string;
};

type Author = {
  id: number;
  name: string;
};

const Comments: FunctionComponent<CommentsProps> = ({
  onClose,
  onCommentUpdated,
  comments,
  isLoading = false,
  subjectId,
  commentSubjectType,
  title,
  subtitle,
}) => {
  const footerTools = [
    <CommentsFooterForm
      key={'comments-footer'}
      onCommentCreated={onCommentUpdated}
      subjectId={subjectId}
      commentSubjectType={commentSubjectType}
    />,
  ];

  const sortedComments = useMemo(() => {
    return comments.sort((a, b) => {
      const dateA = parseISO(a.createdAt);
      const dateB = parseISO(b.createdAt);
      if (dateA < dateB) {
        return 1;
      } else {
        return -1;
      }
    });
  }, [comments]);

  const { data: userData } = useGetCurrentUser();

  return (
    <SideMenu
      toggleSideMenu={onClose}
      title={title || 'Comments'}
      subtitle={subtitle}
      isOpen={true}
      footerTools={footerTools}
    >
      <PageDetails>
        {isLoading ? (
          <CenteredSpinner />
        ) : sortedComments.length == 0 && !isLoading ? (
          <NoAccess
            title={'No Comments'}
            subtitle={'No one has commented here yet... Be the first!'}
            icon={'comments-alt'}
          />
        ) : (
          <Row>
            {sortedComments &&
              sortedComments.map((comment, index: number) => {
                const isLast = index == comments.length - 1;
                return (
                  <Column key={comment.id}>
                    <CommentCard
                      message={comment.body}
                      authorName={comment.author.name}
                      createdAt={comment.createdAt}
                      updatedAt={comment.updatedAt}
                      commentId={comment.id}
                      commentSubjectType={commentSubjectType}
                      userIsAuthor={
                        userData?.currentUser.id == comment.author.id
                      }
                      onCommentUpdated={onCommentUpdated}
                    />
                    {!isLast && <HorizontalSeparator />}
                  </Column>
                );
              })}
          </Row>
        )}
      </PageDetails>
    </SideMenu>
  );
};

type CommentValues = {
  body: any;
};

const commentUpdateSchema: Yup.ObjectSchema<CommentValues> = Yup.object()
  .shape({
    body: OptionalTextEditorSchema.label(
      'Comment'
    ),
  })
  .required();

interface CommentsFooterFormProps {
  subjectId: number;
  commentSubjectType: CommentSubjectEnum;
  onCommentCreated: () => void;
}

const CommentsFooterForm: FunctionComponent<CommentsFooterFormProps> = ({
  subjectId,
  commentSubjectType,
  onCommentCreated,
}) => {
  const [createComment, { status: createCommentStatus }] = useCreateComment();
  const notify = useNotification();

  const editorRef = useRef<ReactEditor>();
  
  const onHandleSubmit = useCallback(
    async (values: CommentValues, resetForm) => {
      const body = values.body && JSON.stringify(values.body);
      const comment: CreateCommentInput = {
        subjectId: subjectId,
        body: body,
      };
      createComment({
        comment,
        subjectType: commentSubjectType,
      })
        .then((result) => {
          if (!result.errors) {
            resetForm({ values: {}});
            if (editorRef.current) {
              ReactEditor.blur(editorRef.current);
            }
            onCommentCreated();
            notify({
              variant: 'success',
              message: 'Comment Posted!',
            });
          } else {
            notify({
              variant: 'danger',
              message: 'An error occurred while posting your comment.',
            });
          }
        })
        .catch((error) => {
          notify({
            variant: 'danger',
            message: 'An error occurred while posting your comment.',
          });
        });
    },
    [createComment, commentSubjectType, notify]
  );

  return (
    <Form<CommentValues>
      initialValues={{}}
      handleSubmit={onHandleSubmit}
      validationSchema={commentUpdateSchema}
    >
      {(props) => (
        <CommentsFooterWrapper>
          <TextEditorFormikField
            name={'body'}
            autoFocus={true}
            placeholder={'Enter your comment here...'}
            editorRef={editorRef}
            tools={
              <React.Fragment>
                <Button
                  onClick={props.submitForm}
                  color={'success'}
                  isWorking={createCommentStatus == 'loading'}
                  disabled={!props.dirty || !props.values.body}
                >
                  Comment
                </Button>
              </React.Fragment>
            }
            onSubmit={props.submitForm}
          />
        </CommentsFooterWrapper>
      )}
    </Form>
  );
};

const CommentsFooterWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  & > *:not(:last-child) {
    margin-bottom: 10px;
  }
`;

const CommentsFooterWrapperRight = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: flex-end;

  & > *:not(:last-child) {
    margin-left: 10px;
  }
`;

export default Comments;
