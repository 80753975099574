import React, { FunctionComponent, useMemo } from 'react';
import {
  NavbarLeft,
  NavbarButton,
  NavbarSidebarTitle,
  NavbarIcon,
  NavbarLogo,
  Tooltip,
} from 'shared/components';

import MainNavbar from './shared/Navbar';
import { NavbarWrapper } from 'shared/components/navbar/Styles';

interface EntireNavbarProps {
  isSidebarOpen: boolean;
  setIsSidebarOpen: (value: boolean) => void;
  searchText: string;
  setSearchText: React.Dispatch<React.SetStateAction<string>>;
}

const EntireNavbar: FunctionComponent<EntireNavbarProps> = ({
  isSidebarOpen,
  setIsSidebarOpen,
  searchText,
  setSearchText,
}) => {
  const iconName = isSidebarOpen ? 'bars' : 'bars';
  const tooltip = isSidebarOpen ? 'Collapse Sidebar' : 'Expand Sidebar';
  const toggleSidebarButton = useMemo(
    () => (
      <Tooltip content={tooltip} placement="right" delay={[500, 0]}>
        <NavbarButton
          icon={iconName}
          onClick={() => setIsSidebarOpen(!isSidebarOpen)}
          slim={true}
          variant={'simple'}
        />
      </Tooltip>
    ),
    [isSidebarOpen, setIsSidebarOpen]
  );
  return (
    <NavbarWrapper>
      <NavbarLeft isSidebarOpen={isSidebarOpen}>
        <NavbarLogo>
          <NavbarIcon>
            {/* <FontAwesomeIcon icon={["fab", "git-alt"]} /> */}
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 13.1 13.1">
              <title>WolffTrax</title>
              <g id="Layer_2" data-name="Layer 2">
                <g id="Layer_1-2" data-name="Layer 1">
                  <rect style={{ fill: 'none' }} height="13.1" width="13.1" />
                  <polygon
                    style={{ fill: '#359bdf' }}
                    points="8.68 3.28 6.48 3.28 10.57 10.03 12.76 10.03 8.68 3.28"
                  />
                  <polygon
                    style={{ fill: '#003a6a' }}
                    points="3.34 7.09 1.15 7.09 3.45 3.28 5.66 3.28 3.34 7.09"
                  />
                  <polygon
                    style={{ fill: '#359bdf' }}
                    points="5.72 3.28 3.51 3.28 7.61 10.03 9.8 10.03 5.72 3.28"
                  />
                </g>
              </g>
            </svg>
          </NavbarIcon>
          {isSidebarOpen && <NavbarSidebarTitle>WolffTrax</NavbarSidebarTitle>}
        </NavbarLogo>
        {isSidebarOpen && toggleSidebarButton}
      </NavbarLeft>
      <MainNavbar
        isSidebarOpen={isSidebarOpen}
        toggleSidebarButton={toggleSidebarButton}
        searchText={searchText}
        setSearchText={setSearchText}
      />
    </NavbarWrapper>
  );
};

export default EntireNavbar;
