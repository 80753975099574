import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CellClickedEvent, ColDef, ICellRendererParams } from 'ag-grid-community';
import NewContactModal from 'App/Organization/Deal/Contacts/modals/NewContactModal';
import {
  DealDataTable,
  DealDataTableRow,
} from 'App/Organization/Deal/Summary/SummaryDashboard';
import React, {
  FunctionComponent,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import { useHistory, useParams, useRouteMatch } from 'react-router-dom';
import {
  Avatar,
  Button,
  CenteredSpinner,
  Column,
  Dialog,
  DropdownButton,
  DropdownItem,
  HorizontalSeparator,
  PageDetails,
  Row,
  Section,
  SelectField,
  Table,
  ToolbarSearchInput,
  Tooltip,
} from 'shared/components';
import NoAccess from 'shared/components/noAccess';
import { useNotification } from 'shared/components/notifications';
import { useBreadcrumb, usePageTitle, useTable } from 'shared/hooks';
import {
  useCreateFirmRegion,
  useDeleteFirmRegion,
  useGetFirm,
  useGetRegions,
  useUpdateContact,
  useUpdateFirmRegion,
} from 'shared/hooks/api';
import {
  UpdateContactInput,
  UpdateFirmRegionInput,
} from 'shared/hooks/api/graphql/generated';
import { tableDateFormat } from 'shared/utils/formatting/tableFormatters';
import {
  createQueryParamModalHelpers,
  QueryParamModalHelpers,
} from 'shared/utils/queryParamModal';
import { font, lightTheme } from 'shared/utils/styles';
import styled from 'styled-components';
import { InteractionRow } from '../Interactions';
import { ThemeContext } from 'styled-components';

type ContactRow = {
  id: number;
  name: string;
  title?: string | null;
  firmName?: string;
  cellPhone?: string | null;
  email?: string | null;
  isPrimary?: boolean | null;
};

interface FirmDetailsProps {
  newInteractionHelpers: QueryParamModalHelpers;
  setSelectedInteraction: (interaction: InteractionRow) => void;
}

type RegionRow = {
  id: number;
  name: string;
  isPrimaryRegion: boolean;
};

type DealAssociation = {
  id: number;
  name: string;
};

const FirmDetails: FunctionComponent<FirmDetailsProps> = ({
  newInteractionHelpers,
  setSelectedInteraction,
}) => {
  const themeContext = useContext(ThemeContext);
  const { orgName, firmId, pipelineId } = useParams<{
    orgName: string;
    firmId: string;
    pipelineId: string;
  }>();
  const { data, status, refetch } = useGetFirm(Number(firmId));
  const match = useRouteMatch();
  usePageTitle(data?.firm ? `${data.firm.name} - Details` : 'Firm Details');

  const history = useHistory();

  useBreadcrumb(
    {
      title: 'Details',
      path: match.url,
      key: 'firm-details',
      index: 4,
    },
    []
  );

  const {
    searchText: interactionsSearchText,
    setSearchText: setInteractionsSearchText,
    gridEvents: interactionsGridEvents,
  } = useTable({
    autoSizeColumns: true,
  });
  const {
    searchText: contactsSearchText,
    setSearchText: setContactsSearchText,
    gridEvents: contactsGridEvents,
    tableRef: contactsTableRef,
  } = useTable({
    autoSizeColumns: true,
  });
  const { gridEvents: regionsGridEvents, tableRef: regionsTableRef } = useTable(
    {
      autoSizeColumns: true,
    }
  );

  const recentInteractions: InteractionRow[] = useMemo(() => {
    return (
      data?.firm?.interactions?.slice(0, 4)?.map((interaction) => ({
        id: interaction.id,
        name: interaction.name,
        type: interaction.type.name,
        date: interaction.interactionDate,
        attendees: interaction.attendees
          .map((attendee) => attendee.name)
          .join(', '),
        deals: interaction.deals.map((deal) => deal.name).join(', '),
        notes: interaction.notes,
      })) ?? []
    );
  }, [data]);

  const keyContacts: ContactRow[] = useMemo(() => {
    if (data?.firm && data?.firm?.contacts) {
      const firmName = data.firm.name;
      const rows: ContactRow[] = data.firm.contacts.map((contact) => ({
        id: contact.id,
        name: `${contact.firstName} ${contact.lastName}`,
        title: contact.title,
        firmName: firmName,
        cellPhone: contact.cellPhone,
        email: contact.email,
        isPrimary: contact.isPrimary,
        nameAndIsPrimary: `${contact.firstName} ${contact.lastName} - ${contact.isPrimary}`,
      }));
      return rows;
    } else {
      return [];
    }
  }, [data, data?.firm?.contacts]);

  const [selectedRegionRows, setSelectedRegionRows] = useState<RegionRow[]>([]);

  const onRegionsSelectionChanged = useCallback(() => {
    if (regionsTableRef.current) {
      const rows = regionsTableRef.current.gridApi.getSelectedRows() || [];
      setSelectedRegionRows(rows);
    }
  }, [setSelectedRegionRows, regionsTableRef]);

  const regions: RegionRow[] = useMemo(() => {
    return (
      data?.firm?.regions.map((region) => {
        return {
          id: region.id,
          name: region.name,
          isPrimaryRegion: region.isPrimaryRegion,
          nameAndIsPrimary: `${region.name} - ${region.isPrimaryRegion}`,
        };
      }) ?? []
    );
  }, [data]);

  const newContactModalHelpers = createQueryParamModalHelpers('new-contact');

  const [selectedContactRows, setSelectedContactRows] = useState<ContactRow[]>(
    []
  );

  const onContactSelectionChanged = useCallback(() => {
    const selectedRows = contactsTableRef.current?.gridApi.getSelectedRows();
    if (selectedRows) {
      setSelectedContactRows(selectedRows);
    }
  }, [contactsTableRef, setSelectedContactRows]);

  const notify = useNotification();
  const [updateContact, { status: updateContactStatus }] = useUpdateContact();

  const onMarkContactAsPrimary = useCallback(async () => {
    try {
      const input: UpdateContactInput = {
        id: selectedContactRows[0].id,
        isPrimary: !selectedContactRows[0].isPrimary,
      };
      const result = await updateContact(input);
      if (result.errors) {
        notify({
          message: 'An error occurred while deleting this contact.',
          variant: 'danger',
          duration: 5000,
        });
      } else {
        switch (result.data?.updateContact?.__typename) {
          case 'Contact': {
            notify({
              message: 'Contact successfully updated',
            });
            refetch();
            break;
          }
          default:
            notify({
              duration: null,
              variant: 'danger',
              title: 'Unable to update contact',
              message: 'An error occurred while updating this contact.',
            });
            break;
        }
      }
    } catch {
      notify({
        duration: 5000,
        title: 'Uh-oh',
        message: 'An error occurred while updating this contact.',
        variant: 'danger',
      });
    }
    setSelectedContactRows([]);
    contactsTableRef.current?.gridApi.deselectAll();
  }, [selectedContactRows, updateContact, notify, contactsTableRef, refetch]);

  const [
    updateFirmRegion,
    { status: updateFirmRegionStatus },
  ] = useUpdateFirmRegion();

  const onMarkRegionAsPrimary = useCallback(async () => {
    try {
      const input: UpdateFirmRegionInput = {
        fkFirm: Number(firmId),
        fkRegion: selectedRegionRows[0].id,
        isPrimaryRegion: !selectedRegionRows[0].isPrimaryRegion,
      };
      const result = await updateFirmRegion(input);
      if (result.errors) {
        notify({
          message: 'An error occurred while updating this firm region.',
          variant: 'danger',
          duration: 5000,
        });
      } else {
        switch (result.data?.updateFirmRegion?.__typename) {
          case 'FirmRegion': {
            notify({
              message: 'Region successfully updated',
            });
            refetch();
            break;
          }
          default:
            notify({
              duration: null,
              variant: 'danger',
              title: 'Unable to update firm region',
              message: 'An error occurred while updating this firm region.',
            });
            break;
        }
      }
    } catch {
      notify({
        duration: 5000,
        title: 'Uh-oh',
        message: 'An error occurred while updating this firm region.',
        variant: 'danger',
      });
    }
    setSelectedRegionRows([]);
    regionsTableRef.current?.gridApi.deselectAll();
  }, [selectedRegionRows, updateFirmRegion, notify, regionsTableRef, refetch, firmId]);

  const [isShowingDeleteFirmRegionDialog, setIsShowingDeleteFirmRegionDialog] = useState(false);
  const [isShowingAddFirmRegionDialog, setIsShowingAddFirmRegionDialog] = useState(false);
  const [regionToAddToFirm, setRegionToAddToFirm] = useState<{ value: number; label: string} | undefined>();

  const [deleteFirmRegion, { status: deleteFirmRegionStatus }] = useDeleteFirmRegion();
  const [createFirmRegion, { status: createFirmRegionStatus }] = useCreateFirmRegion();

  const { data: regionData } = useGetRegions();

  const regionOptions = useMemo(() => {
    return regionData?.regions || [];
  }, [regionData]);

  const associatedDeals = useMemo(() => {
    const deals: DealAssociation[] =
      data?.firm?.associatedDeals.map((x) => ({
        id: x.id,
        name: x.name,
      })) || [];
    return deals;
  }, [data]);

  const { searchText, setSearchText, gridEvents, tableRef } = useTable({
    autoSizeColumns: true,
  });

  const [columnDefs, setColumnDefs] = useState<ColDef[]>([
    {
      maxWidth: 40,
      checkboxSelection: true,
      pinned: 'left',
      headerCheckboxSelection: true,
      headerCheckboxSelectionFilteredOnly: true
    },
    {
      headerName: 'Region',
      field: 'nameAndIsPrimary',
      suppressMenu: true,
      cellRendererFramework: RegionNameCellRenderer
    }
  ]);

  const [recentInteractionsColumnDefs, setRecentInteractionsColumnDefs] = useState<ColDef[]>([
    {
      headerName: 'Name',
      field: 'name',
      suppressMenu: true
    },
    {
      headerName: 'Type',
      field: 'type',
      suppressMenu: true
    },
    {
      headerName: 'Date',
      field: 'date',
      suppressMenu: true,
      valueFormatter: tableDateFormat
    },
    {
      headerName: 'Attendees',
      field: 'attendees',
      suppressMenu: true
    }
  ]);

  const [keyContactsColumnDefs, setKeyContactsColumnDefs] = useState<ColDef[]>([
    {
      maxWidth: 40,
      checkboxSelection: true,
      pinned: 'left',
      headerCheckboxSelection: true,
      headerCheckboxSelectionFilteredOnly: true
    },
    {
      headerName: 'Name',
      field: 'nameAndIsPrimary',
      suppressMenu: true,
      cellRendererFramework: ContactNameCellRenderer,
      onCellClicked: (params: CellClickedEvent) =>
        history.push(
          `/${orgName}/address-books/${pipelineId}/contact-details/${params.data.id}/details`
        )
    },
    {
      headerName: 'Title',
      field: 'title',
      suppressMenu: true
    },
    {
      headerName: 'Email',
      field: 'email',
      suppressMenu: true
    },
    {
      headerName: 'Phone',
      field: 'cellPhone',
      suppressMenu: true
    }
  ]);

  const [associatedDealsColumnDefs, setAssociatedDealsColumnDefs] = useState<ColDef[]>([
    {
      headerName: 'ID',
      field: 'id',
      suppressMenu: true
    },
    {
      headerName: 'Name',
      field: 'name',
      onCellClicked: (params: CellClickedEvent) =>
        history.push(`/${orgName}/deals/${params.data.id}`),
      cellStyle: { cursor: 'pointer' }
    }
  ]);

  return (
    <React.Fragment>
      {!data?.firm && status == 'loading' ? (
        <CenteredSpinner />
      ) : (
        data?.firm && (
          <React.Fragment>
            <PageDetails>
              <Row>
                <Column xs={12} sm={4}>
                  <ContactCard
                    title={data.firm.name}
                    subtitle={data.firm.type ? data.firm.type.name : 'Firm'}
                  >
                    <DealDataTable>
                      <DealDataTableRow
                        label={'Name'}
                        display={data.firm.name}
                      />
                      <DealDataTableRow
                        label={'Type'}
                        display={data.firm.type?.name ?? 'N/A'}
                      />
                      <DealDataTableRow
                        label={'Status'}
                        display={data.firm.status?.name ?? 'N/A'}
                      />
                      <DealDataTableRow
                        label={'Headquarters'}
                        display={data.firm.headquartersMarket?.name ?? 'N/A'}
                      />
                      <DealDataTableRow
                        label={'Product Appetite'}
                        display={data.firm.productAppetite?.name ?? 'N/A'}
                      />
                      <DealDataTableRow
                        label={'Website'}
                        display={data.firm.website ?? 'N/A'}
                      />
                      <DealDataTableRow
                        label={'Relationship Lead'}
                        display={
                          data.firm.relationshipLead?.displayName ?? 'N/A'
                        }
                      />
                    </DealDataTable>
                  </ContactCard>
                  <ContactCardWrapper style={{ marginTop: '20px' }}>
                    <Section
                      title={'Regions'}
                      icon={'map-marked'}
                      right={[
                        selectedRegionRows.length > 0 && (
                          <DropdownButton
                            icon={'ellipsis-h-alt'}
                            slim={true}
                            variant={'outline'}
                            anchor={'right'}
                            isWorking={updateFirmRegionStatus == 'loading' || deleteFirmRegionStatus == 'loading'}
                            title={'Actions...'}
                          >
                            <DropdownItem
                              key={'mark-region-as-primary'}
                              icon={
                                <FontAwesomeIcon
                                  icon={[
                                    'fas',
                                    selectedRegionRows[0].isPrimaryRegion
                                      ? 'map-marker-slash'
                                      : 'star',
                                  ]}
                                  color={themeContext.warning}
                                />
                              }
                              onClick={onMarkRegionAsPrimary}
                            >
                              {selectedRegionRows[0].isPrimaryRegion
                                ? 'Remove Primary Region status'
                                : 'Mark as Primary Region'}
                            </DropdownItem>
                            <DropdownItem
                              key={'delete-firm-region'}
                              icon={
                                <FontAwesomeIcon
                                  icon={[
                                    'fal','trash'
                                  ]}
                                  color={themeContext.danger}
                                />
                              }
                              onClick={() => setIsShowingDeleteFirmRegionDialog(true)}
                            >
                              Delete Region
                            </DropdownItem>
                          </DropdownButton>
                        ),
                        <Button
                          key={'add-firm-region'}
                          icon={'plus'}
                          slim={true}
                          variant={'simple'}
                          onClick={() => setIsShowingAddFirmRegionDialog(true)}
                        >
                          Add Region
                        </Button>,
                      ]}
                      style={{ minHeight: '100px', maxHeight: '300px' }}
                    >
                      {regions.length > 0 ? (
                        <Table
                          rowData={regions}
                          columnDefs={columnDefs}
                          defaultColDef={{ sortable: true }}
                          {...regionsGridEvents}
                          style={{ height: '100%', width: '100%' }}
                          isLoading={status == 'loading'}
                          noRowsOverlayIcon={'map-marked'}
                          noRowsOverlaySubtitle={`There are no regions assigned to this firm...`}
                          suppressRowClickSelection={true}
                          statusBar={{
                            statusPanels: [],
                          }}
                          domLayout={'autoHeight'}
                          onRowClicked={(params) =>
                            setSelectedRegionRows([params.data])
                          }
                          rowMultiSelectWithClick={false}
                          onSelectionChanged={onRegionsSelectionChanged}
                          rowSelection={'single'}
                          headerHeight={0}
                        >
                        </Table>
                      ) : (
                        <NoAccess
                          icon={'map-marked'}
                          subtitle={`There are no regions assigned to this firm...`}
                        />
                      )}
                    </Section>
                  </ContactCardWrapper>
                </Column>
                <Column xs={12} sm={8}>
                  <ContactCardWrapper style={{ marginBottom: '20px' }}>
                    <Section
                      title={'Recent Interactions'}
                      icon={'comments-alt'}
                      left={[
                        <ToolbarSearchInput
                          key={'search-input'}
                          value={interactionsSearchText}
                          onChange={(e) =>
                            setInteractionsSearchText(e.target.value)
                          }
                          placeholder={'Filter interactions by typing here...'}
                        />,
                      ]}
                      right={[
                        <Button
                          key={'log-interaction'}
                          icon={'comment-alt-plus'}
                          slim={true}
                          variant={'simple'}
                          onClick={newInteractionHelpers.open}
                          disabled={false}
                        >
                          Log Interaction
                        </Button>,
                        <Button
                          key={'view-all'}
                          icon={'list-ul'}
                          slim={true}
                          variant={'simple'}
                          onClick={() =>
                            history.push(`/${orgName}/address-books/${pipelineId}/firm-details/${firmId}/interactions`)
                          }
                        >
                          View All Interactions
                        </Button>,
                      ]}
                      style={{ minHeight: '150px', maxHeight: '300px' }}
                    >
                      {recentInteractions.length > 0 ? (
                        <Table
                          rowData={recentInteractions}
                          columnDefs={recentInteractionsColumnDefs}
                          defaultColDef={{ sortable: true }}
                          quickFilterText={interactionsSearchText}
                          {...interactionsGridEvents}
                          style={{ height: '100%', width: '100%' }}
                          isLoading={status == 'loading'}
                          noRowsOverlayIcon={'comments-alt'}
                          noRowsOverlaySubtitle={`There haven't been any recent interactions with this firm...`}
                          suppressRowClickSelection={true}
                          statusBar={{
                            statusPanels: [],
                          }}
                          domLayout={'autoHeight'}
                          onRowClicked={(params) =>
                            setSelectedInteraction(params.data)
                          }
                        >
                        </Table>
                      ) : (
                        <NoAccess
                          icon={'comments-alt'}
                          subtitle={`There haven't been any recent interactions with this firm...`}
                        />
                      )}
                    </Section>
                  </ContactCardWrapper>
                  <ContactCardWrapper style={{ marginBottom: '20px' }}>
                    <Section
                      title={'Contacts'}
                      icon={'users'}
                      left={[
                        <ToolbarSearchInput
                          key={'search-input'}
                          value={contactsSearchText}
                          onChange={(e) =>
                            setContactsSearchText(e.target.value)
                          }
                          placeholder={'Filter contacts by typing here...'}
                        />,
                      ]}
                      right={[
                        selectedContactRows.length > 0 && (
                          <DropdownButton
                            icon={'ellipsis-h-alt'}
                            slim={true}
                            variant={'outline'}
                            anchor={'right'}
                            isWorking={updateContactStatus == 'loading'}
                            title={'Actions...'}
                          >
                            <DropdownItem
                              key={'mark-contact-as-primary'}
                              icon={
                                <FontAwesomeIcon
                                  icon={[
                                    'fas',
                                    selectedContactRows[0].isPrimary
                                      ? 'user-slash'
                                      : 'star',
                                  ]}
                                  color={themeContext.warning}
                                />
                              }
                              color={'primary'}
                              onClick={onMarkContactAsPrimary}
                            >
                              {selectedContactRows[0].isPrimary
                                ? 'Remove Key Contact status'
                                : 'Mark as Key Contact'}
                            </DropdownItem>
                          </DropdownButton>
                        ),
                        <Button
                          key={'new-contact'}
                          icon={'user-plus'}
                          slim={true}
                          variant={'simple'}
                          onClick={newContactModalHelpers.open}
                        >
                          Add Contact
                        </Button>,
                        <Button
                          key={'view-all'}
                          icon={'list-ul'}
                          slim={true}
                          variant={'simple'}
                          onClick={() =>
                            history.push(
                              `/${orgName}/contact-management/firm-details/${firmId}/contacts`
                            )
                          }
                        >
                          View All Contacts
                        </Button>,
                      ]}
                      style={{ minHeight: '150px', maxHeight: '300px' }}
                    >
                      {keyContacts.length > 0 ? (
                        <Table
                          rowData={keyContacts}
                          columnDefs={keyContactsColumnDefs}
                          defaultColDef={{ sortable: true }}
                          quickFilterText={contactsSearchText}
                          {...contactsGridEvents}
                          style={{ height: '100%', width: '100%' }}
                          isLoading={status == 'loading'}
                          noRowsOverlayIcon={'address-book'}
                          noRowsOverlaySubtitle={`There are no contacts linked to this firm...`}
                          suppressRowClickSelection={true}
                          statusBar={{
                            statusPanels: [],
                          }}
                          domLayout={'autoHeight'}
                          onSelectionChanged={onContactSelectionChanged}
                          immutableData={true}
                        >
                        </Table>
                      ) : (
                        <NoAccess
                          icon={'address-book'}
                          subtitle={`There are no contacts linked to this firm..`}
                        />
                      )}
                    </Section>
                  </ContactCardWrapper>
                  <ContactCardWrapper style={{ marginBottom: '20px' }}>
                    <Section
                      title={'Associated Deals'}
                      icon={'building'}
                      left={[
                        <ToolbarSearchInput
                          key={'search-input'}
                          value={searchText}
                          onChange={(e) =>
                            setSearchText(e.target.value)
                          }
                          placeholder={'Filter deals by typing here...'}
                        />,
                      ]}
                      style={{ minHeight: '150px', maxHeight: '300px' }}
                    >
                      {associatedDeals.length > 0 ? (
                        <Table
                          rowData={associatedDeals}
                          columnDefs={associatedDealsColumnDefs}
                          defaultColDef={{ sortable: true }}
                          quickFilterText={searchText}
                          {...gridEvents}
                          style={{ height: '100%', width: '100%' }}
                          isLoading={status == 'loading'}
                          noRowsOverlayIcon={'building'}
                          noRowsOverlaySubtitle={
                            'This firm is not associated with any deals...'
                          }
                          suppressRowClickSelection={true}
                          statusBar={{
                            statusPanels: [],
                          }}
                          domLayout={'autoHeight'}
                        >
                        </Table>
                      ) : (
                        <NoAccess
                          icon={'building'}
                          subtitle={
                            'This firm is not associated with any deals...'
                          }
                        />
                      )}
                    </Section>
                  </ContactCardWrapper>
                </Column>
              </Row>
            </PageDetails>
            {isShowingDeleteFirmRegionDialog && selectedRegionRows.length > 0 && (
              <Dialog
                variant={'danger'}
                isOpen={true}
                title={'Delete Region'}
                confirmTitle={'Yes, remove region'}
                message={`This will remove the "${selectedRegionRows[0].name}" region from this firm. Are you sure you want to do this?`}
                isWorking={deleteFirmRegionStatus == 'loading'}
                onConfirm={async () => {
                  try {
                    await deleteFirmRegion({ fkRegion: selectedRegionRows[0].id, fkFirm: Number(firmId) });
                    setIsShowingDeleteFirmRegionDialog(false);
                    setSelectedRegionRows([]);
                    regionsTableRef.current?.gridApi.deselectAll();
                    notify({
                      message: 'Region removed successfully!',
                    });
                    refetch();
                  } catch {
                    notify({
                      duration: 5000,
                      variant: 'danger',
                      message: 'An error occcurred while removing this region.',
                    });
                    setSelectedRegionRows([]);
                    regionsTableRef.current?.gridApi.deselectAll();
                  }
                }}
                onClose={() => setIsShowingDeleteFirmRegionDialog(false)}
                onCancel={() => setIsShowingDeleteFirmRegionDialog(false)}
              />
            )}
            {isShowingAddFirmRegionDialog && (
              <Dialog
                variant={'info'}
                isOpen={true}
                title={'Add Region'}
                confirmTitle={'Add'}
                valid={regionToAddToFirm !== undefined}
                message={`Select a region to add to this firm.`}
                isWorking={createFirmRegionStatus == 'loading'}
                onConfirm={async () => {
                  if (regionToAddToFirm !== undefined) {
                    try {
                      await createFirmRegion({ fkRegion: regionToAddToFirm.value, fkFirm: Number(firmId), isPrimaryRegion: false });
                      setIsShowingAddFirmRegionDialog(false);
                      notify({
                        message: 'Region added!',
                      });
                      refetch();
                    } catch {
                      notify({
                        duration: 5000,
                        variant: 'danger',
                        message: 'An error occcurred while adding the region to this firm.',
                      });
                    }
                    finally {
                      setRegionToAddToFirm(undefined);
                      setIsShowingAddFirmRegionDialog(false);
                    }
                  }
                }}
                onClose={() => {
                  setRegionToAddToFirm(undefined);
                  setIsShowingAddFirmRegionDialog(false);
                }}
                onCancel={() => {
                  setRegionToAddToFirm(undefined);
                  setIsShowingAddFirmRegionDialog(false);
                }}
              >
                <DialogField>
                  <SelectField
                    name={'status'}
                    placeholder={'e.g. SW, NW'}
                    onChange={(option: any) => {
                      setRegionToAddToFirm(option);
                    }}
                    value={regionToAddToFirm}
                    options={regionOptions}
                  />
                </DialogField>
              </Dialog>
            )}
            {newContactModalHelpers.isOpen() && (
              <NewContactModal
                onClose={() => newContactModalHelpers.close()}
                onSuccess={() => refetch()}
                pipelineId={Number(pipelineId)}
                initialValues={{
                  firm: data?.firm
                    ? { value: data.firm.id, label: data.firm.name }
                    : null,
                }}
              />
            )}
          </React.Fragment>
        )
      )}
    </React.Fragment>
  );
};

const DialogField = styled.div`
  width: 100%;
  margin-bottom: 20px;
`;

export default FirmDetails;

const RegionNameCellRenderer = (props: ICellRendererParams) => {
  const data = props.data as RegionRow;
  if (data) {
    return (
      <Tooltip
        disabled={!data.isPrimaryRegion}
        content={'Primary Region'}
        placement={'right'}
      >
        <StarNameContainer>
          <div>{data.name}</div>
          {data.isPrimaryRegion && (
            <FontAwesomeIcon icon={['fas', 'star']} color={lightTheme.warning} />
          )}
        </StarNameContainer>
      </Tooltip>
    );
  } else {
    return null;
  }
};

const ContactNameCellRenderer = (props: ICellRendererParams) => {
  const data = props.data as ContactRow;
  if (data) {
    return (
      <Tooltip
        disabled={!data.isPrimary}
        content={'Key Contact'}
        placement={'right'}
      >
        <StarNameContainer>
          <div>{data.name}</div>
          {data.isPrimary && (
            <FontAwesomeIcon icon={['fas', 'star']} color={lightTheme.warning} />
          )}
        </StarNameContainer>
      </Tooltip>
    );
  } else {
    return null;
  }
};

const StarNameContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  & > *:not(:last-child) {
    margin-right: 5px;
  }
`;

interface ContactCardProps {
  title: string;
  subtitle: string;
}

export const ContactCard: FunctionComponent<ContactCardProps> = ({
  title,
  subtitle,
  children,
}) => {
  return (
    <ContactCardWrapper>
      <ContactCardHeaderWrapper>
        <ContactCardImageWrapper>
          <Avatar name={title} size={50} />
        </ContactCardImageWrapper>
        <ContactCardHeaderTextWrapper>
          <ContactCardHeaderTitle>{title}</ContactCardHeaderTitle>
          <ContactCardHeaderSubtitle>{subtitle}</ContactCardHeaderSubtitle>
        </ContactCardHeaderTextWrapper>
      </ContactCardHeaderWrapper>
      <HorizontalSeparator margin={10} />
      <DetailsWrapper>{children}</DetailsWrapper>
    </ContactCardWrapper>
  );
};

export const ContactCardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid ${({ theme }) => theme.border};
  border-radius: 5px;
  padding: 10px;
`;

const DetailsWrapper = styled.div`
  padding: 0 10px;
`;

const ContactCardHeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  & > *:not(:last-child) {
    margin-right: 10px;
  }
`;

const ContactCardImageWrapper = styled.div`
  padding: 10px;
`;

const ContactCardHeaderTextWrapper = styled.div`
  display: flex;
  flex-direction: column;

  & > *:not(:last-child) {
    margin-bottom: 5px;
  }
`;

const ContactCardHeaderTitle = styled.div`
  ${font.size(14)};
  ${font.bold};
  color: ${({ theme }) => theme.textDarkest};
`;

const ContactCardHeaderSubtitle = styled.div`
  ${font.size(12)};
  ${font.medium};
  color: ${({ theme }) => theme.textMedium};
`;
