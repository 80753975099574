import React, {
  useCallback,
  useState,
  FunctionComponent,
  useMemo,
  useContext,
} from 'react';
import { useRouteMatch, useParams } from 'react-router-dom';
import { useTable, usePageTitle, useBreadcrumb } from 'shared/hooks/';
import { useRemainingHeight } from 'shared/hooks';
import { useDeleteInteraction, useGetContact } from 'shared/hooks/api';
import {
  PageDetails,
  Table,
  PageToolbar,
  ToolbarButton,
  ToolbarSearchInput,
  SortMenuButton,
  GroupMenuButton,
  ToolbarMenu,
  DropdownItem,
  Tooltip,
  TextEditedContent,
  Dialog,
  MenuSeparator,
} from 'shared/components';
import { QueryParamModalHelpers } from 'shared/utils/queryParamModal';
import { ColDef, ICellRendererParams } from 'ag-grid-community';
import { useNotification } from 'shared/components/notifications';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ThemeContext } from 'styled-components';

export type InteractionRow = {
  id: number;
  name: string;
  type: string;
  date: string;
  deals: string;
  attendees: string;
  notes: string;
};

interface ContactInteractionsProps {
  newInteractionHelpers: QueryParamModalHelpers;
  setSelectedInteraction: (interaction: InteractionRow) => void;
}

const ContactInteractions: FunctionComponent<ContactInteractionsProps> = ({
  newInteractionHelpers,
  setSelectedInteraction,
}) => {
  const themeContext = useContext(ThemeContext);
  const { contactId } = useParams<{ contactId: string }>();
  const { data, status, refetch, isFetching } = useGetContact(Number(contactId));

  usePageTitle(
    data?.contact ? `${data.contact.firstName + ' ' + data.contact.lastName} - Interactions` : 'Contact Interactions'
  );

  const { ref, height } = useRemainingHeight();

  const [deleteInteraction, { status: deleteInteractionStatus }] = useDeleteInteraction(Number(contactId));

  const notify = useNotification();

  const match = useRouteMatch();

  useBreadcrumb(
    {
      title: 'Interactions',
      path: match.url,
      key: 'contact-interactions',
      index: 4,
    },
    []
  );

  const {
    searchText,
    setSearchText,
    sortModel,
    applyColumnState,
    groupedColumns,
    setGroupedColumns,
    gridColumns,
    gridEvents,
    tableRef,
  } = useTable({ autoSizeColumns: true });

  const [selectedInteractions, setSelectedInteractions] = useState<
    InteractionRow[]
  >([]);

  const onRowSelected = useCallback(() => {
    const selectedRows = tableRef.current?.gridApi.getSelectedRows();
    if (selectedRows) {
      setSelectedInteractions(selectedRows);
    }
  }, [tableRef, setSelectedInteractions]);

  const [isShowingRemoveDialog, setIsShowingRemoveDialog] = useState(false);

  const interactions: InteractionRow[] = useMemo(() => {
    return data?.contact?.interactions?.map((interaction) => ({
      id: interaction.id,
      name: interaction.name,
      type: interaction.type.name,
      date: interaction.interactionDate,
      attendees: interaction.attendees.map((attendee) => attendee.name).join(', '),
      deals: interaction.deals.map((deal) => deal.name).join(', '),
      notes: interaction.notes,
    })) ?? [];
  }, [data]);

  const [columnDefs, setColumnDefs] = useState<ColDef[]>([
    {
      maxWidth: 40,
      checkboxSelection: true,
      pinned: 'left',
      headerCheckboxSelection: true,
      headerCheckboxSelectionFilteredOnly: true
    },
    {
      headerName: 'ID',
      field: 'id',
      hide: true
    },
    {
      headerName: 'Name',
      field: 'name',
      cellStyle: { cursor: 'pointer' }
    },
    {
      headerName: 'Type',
      field: 'type'
    },
    {
      headerName: 'Date',
      field: 'date'
    },
    {
      headerName: 'Deals',
      field: 'deals'
    },
    {
      headerName: 'Attendees',
      field: 'attendees'
    },
    {
      headerName: 'Notes',
      field: 'notes',
      minWidth: 200,
      maxWidth: 500,
      cellRendererFramework: NotesCellRenderer
    }
  ]);

  return (
    <React.Fragment>
      <PageToolbar
        left={
          <React.Fragment>
            <ToolbarMenu
              title={'Actions...'}
              icon={'clipboard-list'}
              variant={'outline'}
              anchor={'left'}
              disabled={selectedInteractions.length === 0}
            >
              <DropdownItem
                icon={
                  <FontAwesomeIcon
                    icon={['fal', 'user-slash']}
                    color={themeContext.danger}
                  />
                }
                onClick={() => setIsShowingRemoveDialog(true)}
              >
                Delete selected interaction...
              </DropdownItem>
            </ToolbarMenu>
            <ToolbarButton
              icon={'comment-alt-plus'}
              onClick={newInteractionHelpers.open}
              disabled={false}
            >
              Log Interaction
            </ToolbarButton>
            <ToolbarSearchInput
              placeholder={'Search...'}
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
            />
          </React.Fragment>
        }
        right={
          <React.Fragment>
            <SortMenuButton
              sortModel={sortModel}
              applyColumnState={applyColumnState}
              gridColumns={gridColumns}
            />
            <GroupMenuButton
              groupedColumns={groupedColumns}
              setGroupedColumns={setGroupedColumns}
              gridColumns={gridColumns}
            />
            <ToolbarButton
              icon={'sync'}
              onClick={() => refetch()}
              isWorking={isFetching}
            >
              Refresh
            </ToolbarButton>
          </React.Fragment>
        }
        mobileLeft={
          <React.Fragment>
            <ToolbarMenu
              title={'Actions...'}
              icon={'clipboard-list'}
              variant={'outline'}
              anchor={'left'}
            >
              <DropdownItem
                icon={'comment-alt-plus'}
                onClick={newInteractionHelpers.open}
                disabled={false}
              >
                Log Interaction
              </DropdownItem>
              <MenuSeparator />
              <DropdownItem
                icon={
                  <FontAwesomeIcon
                    icon={['fal', 'user-slash']}
                    color={themeContext.danger}
                  />
                }
                onClick={() => setIsShowingRemoveDialog(true)}
                disabled={selectedInteractions.length === 0}
              >
                Delete selected interaction...
              </DropdownItem>
            </ToolbarMenu>
            <ToolbarSearchInput
              placeholder={'Search...'}
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
            />
          </React.Fragment>
        }
        mobileRight={
          <React.Fragment>
            <ToolbarButton
              icon={'sync'}
              onClick={() => refetch()}
              isWorking={isFetching}
            >
              Refresh
            </ToolbarButton>
          </React.Fragment>
        }
      />
      <PageDetails ref={ref}>
        <Table
          rowData={interactions}
          rowHeight={40}
          columnDefs={columnDefs}
          defaultColDef={{ sortable: true, enableRowGroup: true }}
          quickFilterText={searchText}
          groupUseEntireRow={true}
          {...gridEvents}
          style={{ height: height, width: '100%' }}
          rowSelection={'single'}
          onSelectionChanged={onRowSelected}
          suppressRowClickSelection={true}
          noRowsOverlayIcon={'comments-alt'}
          noRowsOverlayTitle={'No Interactions'}
          noRowsOverlaySubtitle={
            'No interactions have been created for this contact yet.'
          }
          onRowClicked={params => setSelectedInteraction(params.data)}
          isLoading={status == 'loading'}
        >
        </Table>
      </PageDetails>
      {isShowingRemoveDialog && selectedInteractions.length > 0 && (
        <Dialog
          title={'Delete Interaction'}
          message={`Are you sure you want to delete the interaction named "${selectedInteractions[0].name}"?`}
          confirmTitle={`Yes, delete interaction`}
          variant={'danger'}
          isOpen={true}
          onClose={() => setIsShowingRemoveDialog(false)}
          onCancel={() => setIsShowingRemoveDialog(false)}
          isWorking={deleteInteractionStatus == 'loading'}
          onConfirm={async () => {
            try {
              const result = await deleteInteraction(selectedInteractions[0].id);
              if (result.errors) {
                notify({
                  message: 'An error occurred while deleting this interaction.',
                  variant: 'danger',
                  duration: 5000,
                });
              } else {
                switch (result.data?.deleteInteraction?.__typename) {
                  case 'Interaction':
                    notify({
                      message: 'Interaction successfully deleted',
                    });
                    break;
                  default:
                    notify({
                      duration: null,
                      variant: 'danger',
                      title: 'Unable to delete interaction',
                      message: 'An error occurred while deleting this interaction.',
                    });
                    break;
                }
              }
            } catch {
              notify({
                duration: 5000,
                title: 'Uh-oh',
                message: 'An error occurred while deleting this interaction.',
                variant: 'danger',
              });
            }
            setIsShowingRemoveDialog(false);
            setSelectedInteractions([]);
          }}
        />
      )}
    </React.Fragment>
  );
};

export default ContactInteractions;

const NotesCellRenderer = (props: ICellRendererParams) => {
  const data = props.data as InteractionRow;
  if (data) {
    return (
        <Tooltip
          disabled={data.notes == ""}
          content={<TextEditedContent value={data.notes != "" ? data.notes : 'N/A'}/>}
          placement={'left'}
        >
          <span>
            <TextEditedContent value={data.notes != "" ? data.notes : 'N/A'}/>
          </span>
        </Tooltip>
    );
  } else {
    return null;
  }
};
