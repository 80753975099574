import React, { FunctionComponent } from "react";
import {
  SidebarLinks,
  SidebarBottom,
  MainSidebarLink,
  MainSidebar,
  MainSidebarSeparator,
} from "shared/components";
import { useRouteMatch, useParams } from "react-router-dom";

const OrganizationHomeSidebar: FunctionComponent = () => {
  const match = useRouteMatch();
  const { orgName } = useParams<{ orgName: string }>();
  return (
    <React.Fragment>
      <MainSidebar>
        <SidebarLinks>
          <MainSidebarLink icon={"home"} title={"My Dashboard"} path={`/${orgName}/dashboard`}/>
          <MainSidebarSeparator/>
          <MainSidebarLink icon={"list"} title={"Overview"} path={`/${orgName}/overview`}/>
          <MainSidebarLink icon={"badge-check"} title={"Approvals"} path={`/${orgName}/approvals/all/${0}`}/>
          <MainSidebarLink icon={"address-book"} title={"Contacts"} path={`/${orgName}/contact-management`}/>
          <MainSidebarSeparator/>
          {/* <MainSidebarLink icon={"telescope"} title={"Wolff Research (Beta)"} path={`${process.env.WOLFF_RESEARCH_URL}`}/> */}
        </SidebarLinks>
        <SidebarBottom>
          <MainSidebarSeparator/>
          <MainSidebarLink icon={'cog'} title={"Organization Settings"} path={`/${orgName}/settings`}/>
        </SidebarBottom>
      </MainSidebar>
    </React.Fragment>
  );
};

export default OrganizationHomeSidebar;
