import React, { useState } from 'react';
import { useParams, useRouteMatch } from 'react-router';
import Redirect from 'shared/components/Redirect';
import { Route, Switch } from 'react-router-dom';
import { Button, CenteredSpinner, PageHeader } from 'shared/components';
import { useBreadcrumb } from 'shared/hooks';
import { useGetFirm } from 'shared/hooks/api';
import { createQueryParamModalHelpers } from 'shared/utils/queryParamModal';
import CreateInteractionModal from '../shared/CreateInteractionModal';
import UpdateFirmModal from '../shared/UpdateFirmModal';
import UpdateInteractionModal from '../shared/UpdateInteractionModal';
import FirmContacts from './Contacts';
import FirmDetails from './Details';
import FirmInteractions, { InteractionRow } from './Interactions';
import FirmAttachments from './Attachments';

const FirmDetailsPage = () => {
  const { firmId, orgName } = useParams<{ firmId: string; orgName: string }>();
  const { data, status, refetch } = useGetFirm(Number(firmId));
  const match = useRouteMatch();

  useBreadcrumb(
    {
      title: 'Contact Management',
      path: `/${orgName}/address-books`,
      key: 'address-books',
      index: 2,
    },
    []
  );

  useBreadcrumb(
    {
      title: data?.firm ? `${data.firm.name}` : 'Loading...',
      path: match.url,
      key: 'firm',
      index: 3,
    },
    [data?.firm]
  );

  const newInteractionHelpers = createQueryParamModalHelpers('new-interaction');
  const [selectedInteraction, setSelectedInteraction] = useState<InteractionRow | undefined>();

  const [isEditing, setIsEditing] = useState(false);

  return (
    <React.Fragment>
      {!data?.firm && status == 'loading' ? (
        <CenteredSpinner />
      ) : (
        data?.firm && (
          <React.Fragment>
            <PageHeader
              title={data.firm.name}
              subtitle={'Firm Details'}
              tabs={[
                { title: 'Details', path: `/details` },
                { title: 'Interactions', path: `/interactions` },
                { title: 'Contacts', path: `/contacts` },
                { title: 'Attachments', path: `/attachments` },
              ]}
              tools={[
                <Button
                  key={'edit-button'}
                  icon={'pencil'}
                  onClick={() => setIsEditing(true)}
                  variant={'outline'}
                >
                  Edit
                </Button>,
              ]}
            />
            <Switch>
              <Route
                path={`${match.path}/details`}
                render={() => (
                  <FirmDetails newInteractionHelpers={newInteractionHelpers} setSelectedInteraction={(interaction) => setSelectedInteraction(interaction)} />
                )}
              />
              <Route
                path={`${match.path}/interactions`}
                render={() => (
                  <FirmInteractions
                    newInteractionHelpers={newInteractionHelpers}
                    setSelectedInteraction={(interaction) => setSelectedInteraction(interaction)}
                  />
                )}
              />
              <Route
                path={`${match.path}/contacts`}
                render={() => <FirmContacts />}
              />
              <Route
                path={`${match.path}/attachments`}
                render={() => (
                  <FirmAttachments
                    currentUserIsContributor={true}
                  />
                )}
              />
              <Redirect to={`${match.url}/details`} />
            </Switch>
            {newInteractionHelpers.isOpen() && (
              <CreateInteractionModal
                firmId={Number(firmId)}
                firm={data.firm ? { value: data.firm.id, label: data.firm.name } : undefined}
                onClose={newInteractionHelpers.close}
              />
            )}
            {selectedInteraction && (
              <UpdateInteractionModal
                id={selectedInteraction.id}
                firmId={Number(firmId)}
                onClose={() => setSelectedInteraction(undefined)}
              />
            )}
            {isEditing && (
              <UpdateFirmModal
                onClose={() => setIsEditing(false)}
                onSuccess={() => {
                  refetch();
                  setIsEditing(false);
                }}
              />
            )}
          </React.Fragment>
        )
      )}
    </React.Fragment>
  );
};

export default FirmDetailsPage;
