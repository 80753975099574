import React, { FunctionComponent, useContext, useEffect, useMemo } from 'react';
import {
  CenteredSpinner,
  FormModal,
  PageDetails,
} from 'shared/components';
import {
  StatusUpdateValues,
  statusUpdateSchema,
} from 'shared/components/card/StatusCard';
import { CreateStatusUpdateSectionInput, UpdateStatusUpdateInput } from 'shared/hooks/api/graphql/generated';
import { useNotification } from 'shared/components/notifications';
import { useGetDealStatusUpdate, useUpdateStatusUpdate } from 'shared/hooks/api';
import StatusUpdateForm from './StatusUpdateForm';
import { useParams } from 'react-router-dom';
import { PartialNullable } from 'shared/utils/utilityTypes';
import { parseTextEditedValue } from 'shared/components/form/textEditor/TextEditor';
import { ThemeContext } from 'styled-components';

interface UpdateStatusModalUpdateProps {
  oppId: number;
  onClose: () => void;
  onUpdate: () => void;
}

type RouteParams = {
  statusUpdateId: string;
};

const UpdateStatusUpdateModal: FunctionComponent<UpdateStatusModalUpdateProps> = ({
  oppId,
  onClose,
  onUpdate,
}) => {
  const themeContext = useContext(ThemeContext);
  const notify = useNotification();
  const [updateStatusUpdate, { status: updateStatusUpdateStatus }] = useUpdateStatusUpdate({
    throwOnError: true,
  });
  const { statusUpdateId } = useParams<RouteParams>();
  const { data, error, isFetching, refetch, status } = useGetDealStatusUpdate(
    Number(oppId),
    Number(statusUpdateId)
  );

  useEffect(() => {
    console.log('data', data);
  }, [data])
  
  const initialValues: PartialNullable<StatusUpdateValues> = useMemo(() => {
    if (data?.deal?.statusUpdate) {
      return {
        title: data.deal.statusUpdate.title,
        message: parseTextEditedValue(data.deal.statusUpdate.message),
        status: {
          value: data.deal.statusUpdate.status.id,
          label: data.deal.statusUpdate.status.name,
          icon: 'circle',
          color: data.deal.statusUpdate.status.color ? themeContext[data.deal.statusUpdate.status.color] : themeContext.secondary,
        },
        sections: data.deal.statusUpdate.sections?.map(section => {
          return {
            title: section.title,
            content: parseTextEditedValue(section.content),
          }
        }) || [],
        labels: data.deal.statusUpdate.labels?.map(label => {
          return {
            value: label.id,
            label: label.name,
            color: label.color,
          }
        }) || [],
      }
    } else {
      return {}
    }
  }, [data]);
  return (
    <FormModal<StatusUpdateValues>
      title={'Edit Status Update'}
      submitTitle={'Save'}
      onClose={onClose}
      isWorking={updateStatusUpdateStatus == 'loading'}
      initialValues={initialValues}
      handleSubmit={async (values) => {
        const message = JSON.stringify(values.message);

        const sections: CreateStatusUpdateSectionInput[] = values.sections?.map(section => {
          return {
            title: section.title,
            content: section.content && JSON.stringify(section.content),
          }
        });

        const input: UpdateStatusUpdateInput = {
          id: Number(statusUpdateId),
          title: values.title,
          message: message,
          fkDealStatus: values.status.value,
          sections: sections,
          labelIds: values.labels.map(x => x.value),
        };
        try {
          const result = await updateStatusUpdate(input);
          if (result.errors) {
            const permissionError = result.errors.find(
              (x) => x.extensions.code === 'FORBIDDEN'
            );
            if (permissionError) {
              notify({
                duration: 10000,
                title: 'Access Denied',
                message: permissionError.message,
                variant: 'danger',
              });
            } else {
              notify({
                duration: 5000,
                title: 'Uh-oh!',
                message:
                  'An unknown error occurred while creating this status update!',
                variant: 'danger',
              });
            }
          } else {
            notify({
              message: 'Update successful!',
            });
            onUpdate();
            onClose();
          }
        } catch {
          notify({
            variant: 'danger',
            message: 'An error occurred while updating this status update.',
          });
        }
      }}
      validationSchema={statusUpdateSchema}
      size={'lg'}
    >
      {(props) => (
        <PageDetails>
          {data?.deal?.statusUpdate && status != 'loading' ? (
          <StatusUpdateForm form={props} />
          ) : 
            <CenteredSpinner/>
          }
        </PageDetails>
      )}
    </FormModal>
  );
};

export default UpdateStatusUpdateModal;
