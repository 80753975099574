import React, { FunctionComponent } from 'react';
import {
  CreateContactInput,
  ErrorFragment,
} from 'shared/hooks/api/graphql/generated';
import { useCreateContact } from 'shared/hooks/api';
import { useNotification } from 'shared/components/notifications';
import ContactForm from '../ContactForm';
import * as Yup from 'yup';
import { FormModal, PageDetails } from 'shared/components';
import { PartialNullable } from 'shared/utils/utilityTypes';

interface NewContactModalProps {
  onClose: () => void;
  onSuccess: (contact?: Contact) => void;
  oppId?: number;
  pipelineId: number;
  initialValues?: PartialNullable<ContactFormValues>;
}

type Contact = {
  id: number;
  firstName: string;
  lastName: string;
  title?: string | null;
  cellPhone?: string | null;
  email?: string | null;
  firm?: { id: number; name: string } | null;
  notes?: string | null;
  isPrimary?: boolean | null;
}

type Option =  {
  value: number;
  label: string;
}

export type ContactFormValues = {
  firstName: string;
  lastName: string;
  title?: string | null;
  cellPhone?: string | null;
  email?: string | null;
  firm: Option;
  notes?: string | null;
  isPrimary?: boolean | null;
}

export const contactFormSchema: Yup.ObjectSchema<ContactFormValues> = Yup.object().shape({
  firstName: Yup.string()
    .min(2, 'Too Short!')
    .max(50, 'Too Long!')
    .required("Required")
    .label("First Name"),
  lastName: Yup.string()
    .min(2, 'Too Short!')
    .max(50, 'Too Long!')
    .required("Required")
    .label("Last Name"),
  title: Yup.string()
    .min(2, 'Too Short!')
    .max(50, 'Too Long!')
    .notRequired()
    .nullable()
    .label("Title"),
  firm: Yup.object().shape({
    value: Yup.number().required(),
    label: Yup.string().required(),
  }).required("Required")
  .label("Firm"),
  email: Yup.string()
    .min(2, 'Too Short!')
    .max(50, 'Too Long!')
    .notRequired()
    .nullable()
    .label("Email"),
  notes: Yup.string()
    .max(700, 'Too Long!')
    .notRequired()
    .nullable()
    .label("Notes"),
  isPrimary: Yup.boolean()
    .notRequired()
    .nullable()
    .label("Is Primary Contact"),
}).required();

const NewContactModal: FunctionComponent<NewContactModalProps> = ({
  onClose,
  onSuccess,
  oppId,
  pipelineId,
  initialValues,
}) => {
  const [createContact, { status }] = useCreateContact();
  const notify = useNotification();
  const infoMessage =
    "The newly created contact will be added to the global list of contacts for your organization.";
  return (
    <FormModal<ContactFormValues>
      title={'Create Contact'}
      submitTitle={'Create'}
      onClose={onClose}
      isWorking={status === 'loading'}
      validationSchema={contactFormSchema}
      handleSubmit={async (form, resetForm) => {
        const input: CreateContactInput = {
          firstName: form.firstName,
          lastName: form.lastName,
          title: form.title,
          fkFirm: form.firm.value,
          email: form.email,
          cellPhone: form.cellPhone,
          notes: form.notes,
          fkDeal: oppId,
          isPrimary: form.isPrimary ?? false,
          fkPipeline: pipelineId,
        };
        try {
          const result = await createContact(input);
          switch (result.data?.createContact?.__typename) {
            case 'Contact': {
              notify({
                duration: 3000,
                title: 'Success!',
                message: 'New Contact Added!',
                variant: 'success',
              });
              onClose();
              const contact: Contact = {
                id: result.data.createContact.id,
                firstName: result.data.createContact.firstName,
                lastName: result.data.createContact.lastName,
                title: result.data.createContact.title,
                cellPhone: result.data.createContact.cellPhone,
                email: result.data.createContact.email,
                notes: result.data.createContact.notes,
                isPrimary: result.data.createContact.isPrimary,
                firm: result.data.createContact.firm ?? null,
              }
              onSuccess(contact);
              resetForm();
              break;
            }
            case 'ContactAlreadyExists':
              notify({
                duration: 5000,
                title: 'Contact Already Exists',
                message: result.data?.createContact.message,
                variant: 'danger',
              });
              break;
            default:
              notify({
                duration: 5000,
                title: 'Failed to Create Contact',
                message: (result.data?.createContact as ErrorFragment)
                  .message,
                variant: 'danger',
              });
              break;
          }
        } catch (error) {
          notify({
            duration: 5000,
            title: 'Uh-oh!',
            message: 'An unknown error occurred while creating the contact!',
            variant: 'danger',
          });
        }
      }}
      initialValues={initialValues}
    >
      {(props) => (
        <PageDetails>
          <ContactForm
            infoMessage={infoMessage}
            formProps={props}
            pipelineId={pipelineId}
          />
        </PageDetails>
      )}
    </FormModal>
  );
};

export default NewContactModal;
