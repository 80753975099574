import { Maybe, MetricFormatEnum } from 'shared/hooks/api/graphql/generated';
import { formatCurrency, formatDecimal, formatPercentage } from './formatting/formatters';

export const formatMetric = (value: Maybe<number>, format: Maybe<MetricFormatEnum>, unit: Maybe<string>, precision: Maybe<number>, scale: Maybe<number>) => {
  if (value) {
    let formatted: string | undefined = undefined;
    switch (format) {
      case 'DECIMAL': {
        formatted = formatDecimal(value, precision, scale);
        break;
      }
      case 'CURRENCY': {
        formatted = formatCurrency(value, precision, scale);
        break;
      }
      case 'PERCENTAGE': {
        formatted = formatPercentage(value, precision);
        break;
      }
      default:
        formatted = value.toString();
        break;
    }

    if (formatted) {
      return unit ? formatted + ' ' + unit : formatted; 
    }
  }
};
