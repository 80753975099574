import React, {
  forwardRef,
  FunctionComponent,
  ReactNode,
  useState,
} from 'react';
import styled from 'styled-components';
import { font, mixin } from 'shared/utils/styles';
import { IconName } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Input } from 'shared/components';
import { InputFieldProps, InputFormikField, InputProps } from './form/input/Input';

interface SectionProps {
  title?: string;
  onTitleChange?: React.ChangeEventHandler<HTMLInputElement>;
  icon?: IconName | React.ReactNode;
  left?: ReactNode[];
  right?: ReactNode[];
  large?: boolean;
  style?: React.CSSProperties;
  children?: React.ReactNode;
  placeholder?: string;
  showToolsOnHover?: boolean;
  fieldProps?: InputFieldProps;
  className?: string;
}

const SectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 5px;
`;

const SectionHeader = styled.div<{ large: boolean }>`
  display: flex;
  justify-content: space-between;
  padding: 5px 0;
  margin-bottom: 5px;
  border-bottom: ${(props) => (props.large ? 1 : 1)}px solid
    ${(props) => (props.large ? mixin.darken(props.theme.border, 0.1) : props.theme.border)};
  width: 100%;
  min-height: 25px;
`;

const SectionTitle = styled.h5<{ large: boolean }>`
  padding-left: 2px;
  white-space: nowrap;
  ${(props) => font.size(props.large ? 16 : 14)};
  ${(props) => (props.large ? font.bold : font.medium)};
`;

const SectionHeaderLeft = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
  flex-grow: 1;
  min-height: 25px;

  & > * {
    margin-right: 10px;
  }
`;

const SectionHeaderRight = styled.div<{ showToolsOnHover: boolean }>`
  display: inline-flex;
  align-items: center;
  justify-content: flex-end;
  flex-grow: 1;
  opacity: ${(props) => (props.showToolsOnHover ? '0' : '1')};
  transition: opacity 100ms ease-in-out;
  min-height: 25px;

  ${(props) =>
    props.showToolsOnHover &&
    `
      ${SectionWrapper}:hover & {
        opacity: 1;
      }
  `}
`;

const SectionBody = styled.div`
  padding: 5px;
`;

const SectionHeaderIcon = styled.div`
  display: flex;
  align-items: center;
  margin-right: 5px;
  ${font.size(12)};
  color: ${({ theme }) => theme.textMedium};
`;
// eslint-disable-next-line react/display-name
const Section = forwardRef<HTMLDivElement, SectionProps>(
  (
    {
      children,
      title,
      onTitleChange,
      icon,
      style,
      left = [],
      right = [],
      large = false,
      placeholder = 'Section title here...',
      showToolsOnHover = false,
      fieldProps,
      className,
    },
    ref
  ) => {
    return (
      <SectionWrapper ref={ref} className={className}>
        <SectionHeader large={large}>
          <SectionHeaderLeft>
            <SectionHeaderIcon>
              {icon && typeof icon === 'string' ? (
                <FontAwesomeIcon icon={['fal', icon as IconName]} />
              ) : (
                icon
              )}
            </SectionHeaderIcon>
            {fieldProps ? (
              <StyledInputFormikField
                {...fieldProps}
                borderless={true}
                large={large}
              />
            ) : onTitleChange ? (
              <StyledInput
                value={title}
                onChange={onTitleChange}
                borderless={true}
                placeholder={placeholder}
                large={large}
              />
            ) : (
              <SectionTitle large={large}>{title}</SectionTitle>
            )}
            {left}
          </SectionHeaderLeft>
          <SectionHeaderRight showToolsOnHover={showToolsOnHover}>
            {right}
          </SectionHeaderRight>
        </SectionHeader>
        <SectionBody style={style}>{children}</SectionBody>
      </SectionWrapper>
    );
  }
);

interface StyledInputProps extends InputProps {
  large: boolean;
}

const StyledInput = styled(Input)<StyledInputProps>`
  width: 100%;
  & > input {
    ${(props) => font.size(props.large ? 16 : 14)};
    ${(props) => (props.large ? font.bold : font.medium)};
  }
`;

const StyledInputFormikField = styled(InputFormikField)<StyledInputProps>`
  width: 100%;
  & > input {
    ${(props) => font.size(props.large ? 16 : 14)};
    ${(props) => (props.large ? font.bold : font.medium)};
  }
`;

export default Section;
