import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, {
  FunctionComponent,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useDropzone } from 'react-dropzone';
import { useParams } from 'react-router-dom';
import {
  Button,
  Dialog,
  DropdownItem,
  FormFieldWrapper,
  InputField,
  Modal,
  PageDetails,
  PageHeader,
  PageToolbar,
  Table,
  ToolbarButton,
  ToolbarMenu,
  ToolbarSearchInput,
  Tooltip,
} from 'shared/components';
import { useNotification } from 'shared/components/notifications';
import { useRemainingHeight, useTable } from 'shared/hooks';
import {
  useDeleteContactAttachment,
  useGetArtifactTypes,
  useGetContactAttachments,
  useUploadContactAttachment,
} from 'shared/hooks/api';
import {
  getAuthorizedHeaders,
  restBaseUrl,
} from 'shared/hooks/api/client/queries';
import { formatDatetime } from 'shared/utils/formatting/formatters';
import { font, mixin } from 'shared/utils/styles';
import styled, { ThemeContext } from 'styled-components';
import axios from 'axios';
import { fileDownload } from 'shared/utils/fileDownload';
import { useQueryParamState } from 'shared/utils/queryParamHelpers';
import { ColDef } from 'ag-grid-community';

export type AttachmentRow = {
  id: number;
  name: string;
  type: string;
  fileExtension?: string | null;
  createdAt: string;
  updatedAt: string;
  fkDocument: number;
};

interface AttachmentsProps {
  currentUserIsContributor: boolean;
}

const ContactAttachments: FunctionComponent<AttachmentsProps> = ({
  currentUserIsContributor,
}) => {
  const themeContext = useContext(ThemeContext);
  const { contactId } = useParams<{ contactId: string }>();
  const { searchText, setSearchText, gridEvents, tableRef } = useTable({
    autoSizeColumns: true,
  });

  const { data: contactAttachmentsData, status: getContactAttachmentsStatus, refetch, isFetching } = useGetContactAttachments(Number(contactId));

  const [fileId, setFileId] = useQueryParamState<number | undefined>('fileId', undefined);
  const [fileName, setFileName] = useQueryParamState<string | undefined>('fileName', undefined);

  const [selectedRows, setSelectedRows] = useState<AttachmentRow[]>([]);

  const onSelectionChanged = useCallback(() => {
    if (tableRef.current) {
      const rows = tableRef.current.gridApi.getSelectedRows() || [];
      setSelectedRows(rows);
    }
  }, [setSelectedRows, tableRef]);

  const notify = useNotification();
  const [isShowingRemoveDialog, setIsShowingRemoveDialog] = useState(false);
  const [fileToUpload, setFileToUpload] = useState<File | undefined>();

  const [
    deleteAttachment,
    { status: deleteAttachmentStatus },
  ] = useDeleteContactAttachment(Number(contactId));

  const { ref, height } = useRemainingHeight();

  const [upload] = useUploadContactAttachment(
    Number(contactId)
  );

  const [isUploading, setIsUploading] = useState(false);
  const [displayNameText, setDisplayNameText] = useState<string | undefined>();
  const maxFileSize = 30000000;

  const onDropAccepted = useCallback(
    async (acceptedFiles: File[]) => {
      const file = acceptedFiles[0];
      if (file.size > maxFileSize) {
        notify({
          duration: 8000,
          variant: 'danger',
          title: `File Too Large (${(file.size / 1024 / 1024).toFixed(1)} MB)`,
          message: `The file size exceeds the 30 MB limit. Please reduce it to 30 MB or less by compressing it, then try uploading again.`,
        });
      } else {
        setFileToUpload(file);
      }
    },
    [notify] 
  );

  const onUploadFile = useCallback(
    async () => {
      if (fileToUpload && displayNameText) {
        const file = fileToUpload;
        setFileToUpload(undefined);
        try {
          setIsUploading(true);
          const result = await upload({ id: Number(contactId), displayName: displayNameText, file });
          if (result && result.status >= 400) {
            throw new Error(result.data.error);
          } else {
            notify({
              title: 'Attachment uploaded!',
            });
          }
          refetch();
        } catch (error) {
          notify({
            variant: 'danger',
            title: 'Error uploading attachment',
            message: 'An error occurred while uploading the attachment. Please try again.',
          });
        } finally {
          setIsUploading(false);
          setFileToUpload(undefined);
          setDisplayNameText(undefined);
        }
      }
    },
    [contactId, upload, refetch, displayNameText, fileToUpload]
  );

  const [isDownloading, setIsDownloading] = useState(false);

  const onDownloadAttachment = useCallback(
    async (fileId: number, fileName?: string) => {
      const headers = await getAuthorizedHeaders();
      setIsDownloading(true);
      const result = await axios({
        url: `${restBaseUrl}documents/contact-details/${Number(contactId)}/attachments/${fileId}`,
        headers: headers,
        responseType: 'blob',
      });
      fileDownload(result.data, fileName);
      setIsDownloading(false);
    },
    [contactId]
  );

  useEffect(() => {
    if (fileId && fileName) {
      onDownloadAttachment(fileId, fileName).finally(() => {
        setFileId(undefined);
        setFileName(undefined);
      })
    }
  }, [fileId, fileName, onDownloadAttachment])

  const onDropRejected = useCallback(() => {
    notify({
      duration: 10000,
      variant: 'danger',
      message: `The file that selected cannot be uploaded. Please select a different file.`,
    });
  }, []);

  const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
    onDropAccepted,
    onDropRejected,
    noClick: true,
    multiple: false,
  });

  const contactAttachments: AttachmentRow[] = useMemo(() => {
    return contactAttachmentsData?.contact?.attachments?.map(contactAttachment => {
      return {
        id: contactAttachment.id,
        name: contactAttachment.displayName,
        type: 'file',
        fileExtension: contactAttachment.fileExtension,
        createdAt: contactAttachment.createdAt,
        updatedAt: contactAttachment.updatedAt,
        fkDocument: contactAttachment.fkDocument,
      }
    }) || [];
  }, [contactAttachmentsData]);

  const {
    data: attachmentTypeData,
    status: getAttachmentTypesStatus,
  } = useGetArtifactTypes();

  const attachmentTypeOptions = useMemo(() => {
    return (
      attachmentTypeData?.artifactTypes?.map((x) => ({
        value: x.id,
        label: x.name,
      })) || []
    );
  }, [attachmentTypeData]);

  const [columnDefs, setColumnDefs] = useState<ColDef[]>([
    {
      maxWidth: 40,
      checkboxSelection: true,
      pinned: 'left',
      suppressMenu: true
    },
    {
      headerComponentFramework: DocumentHeaderRenderer,
      headerName: '',
      field: 'type',
      minWidth: 50,
      maxWidth: 50,
      cellRendererFramework: DocumentTypeCellRenderer,
      suppressMenu: true,
      sortable: false
    },
    {
      headerName: 'Name',
      field: 'name',
      suppressMenu: true
    },
    {
      headerName: 'Type',
      field: 'fileExtension',
      suppressMenu: true
    },
    {
      headerName: 'Uploaded',
      field: 'createdAt',
      valueFormatter: (params: any) => formatDatetime(params.value) || '',
      suppressMenu: true
    }
  ]);

  const table = useMemo(() => {
    return (
      <Table
        rowData={contactAttachments}
        columnDefs={columnDefs}
        defaultColDef={{ sortable: true, enableRowGroup: true }}
        quickFilterText={searchText}
        groupUseEntireRow={true}
        {...gridEvents}
        style={{ height: height, width: '100%' }}
        rowSelection={'single'}
        onSelectionChanged={onSelectionChanged}
        isLoading={getContactAttachmentsStatus == 'loading'}
        noRowsOverlayIcon={'folder-open'}
        noRowsOverlaySubtitle={
          'There are no attachments for this contact...'
        }
        suppressRowClickSelection={true}
      >
      </Table>
    );
  }, [contactAttachments, searchText, gridEvents, onSelectionChanged, getContactAttachmentsStatus]);

  return (
    <React.Fragment>
      <PageHeader
        title={'Contact Attachments'}
        subtitle={'A list of attachments for this contact'}
        description={'This section contains uploaded documents that are approved attachments of the contact (e.g. Purchase and Sales Agreement, Land Closing Documents)'}
      />
      <PageToolbar
        left={
          <React.Fragment>
            {currentUserIsContributor && (
              <ToolbarMenu
                title={'Actions...'}
                icon={'clipboard-list'}
                variant={'outline'}
                anchor={'left'}
                disabled={selectedRows.length == 0}
              >
                <DropdownItem
                  icon={
                    <FontAwesomeIcon
                      icon={['fal', 'times']}
                      color={themeContext.danger}
                    />
                  }
                  onClick={() => setIsShowingRemoveDialog(true)}
                >
                  Delete selected attachment...
                </DropdownItem>
              </ToolbarMenu>
            )}
            <input {...getInputProps()} title={'Upload'} />
            <ToolbarButton
              icon={'arrow-to-bottom'}
              onClick={() =>
                onDownloadAttachment(selectedRows[0].fkDocument, selectedRows[0].name + selectedRows[0].fileExtension)
              }
              isWorking={isDownloading}
              disabled={selectedRows.length == 0}
            >
              Download
            </ToolbarButton>
            {currentUserIsContributor && (
              <ToolbarButton
                icon={'arrow-to-top'}
                onClick={open}
                isWorking={isUploading}
              >
                Upload attachment
              </ToolbarButton>
            )}
            <ToolbarSearchInput
              onChange={(e) => setSearchText(e.target.value)}
              value={searchText}
              placeholder={'Filter the list by typing here...'}
            />
          </React.Fragment>
        }
        right={
          <React.Fragment>
            <ToolbarButton
              icon={'sync'}
              onClick={() => refetch()}
              isWorking={isFetching}
            />
          </React.Fragment>
        }
      />
      <PageDetails ref={ref}>
        {!currentUserIsContributor ? (
          table
        ) : (
          <DropzoneContainer {...getRootProps()} isDragActive={isDragActive}>
            {table}
          </DropzoneContainer>
        )}
        {currentUserIsContributor && isShowingRemoveDialog && selectedRows.length > 0 && (
          <Dialog
            title={`Delete ${selectedRows[0].name}?`}
            message={`Are you sure you want to delete the attachment with the name "${selectedRows[0].name}" from the list of attachments for this contact?`}
            confirmTitle={`Yes, delete attachment`}
            variant={'danger'}
            isOpen={true}
            onClose={() => {
              setIsShowingRemoveDialog(false);
            }}
            onCancel={() => {
              setIsShowingRemoveDialog(false);
            }}
            isWorking={deleteAttachmentStatus == 'loading'}
            onConfirm={async () => {
              try {
                const result = await deleteAttachment(selectedRows[0].id);
                if (result.errors) {
                  notify({
                    duration: 5000,
                    title: 'Uh-oh!',
                    variant: 'danger',
                    message:
                      'An unknown error occurred while deleting the attachment!',
                  });
                } else {
                  notify({
                    message: `Attachment deleted.`,
                  });
                }
              } catch {
                notify({
                  duration: 5000,
                  title: 'Uh-oh!',
                  variant: 'danger',
                  message:
                    'An unknown error occurred while deleting the attachment!',
                });
              }
              setIsShowingRemoveDialog(false);
              setSelectedRows([]);
              tableRef.current?.gridApi.deselectAll();
              refetch();
            }}
          />
        )}
      </PageDetails>
      {fileToUpload && (
        <Modal
          isOpen={!!fileToUpload}
          onClose={() => setFileToUpload(undefined)}
          title={'Upload File'}
          size={'sm'}
          footerTools={[
            <Button key={'upload-file'} onClick={onUploadFile} color={'success'} disabled={displayNameText && displayNameText.length > 1 ? false : true}>
              Upload File
            </Button>
          ]}
        >
          <PageDetails>
            <FormFieldWrapper>
              <FileToUploadContainer>
                <FileToUploadTitle>File to upload: </FileToUploadTitle>
                <FileToUploadName>{fileToUpload.name}</FileToUploadName>
              </FileToUploadContainer>
            </FormFieldWrapper>
            <FormFieldWrapper>
              <InputField
                name={'display'}
                label={'Display Name'}
                value={displayNameText}
                onChange={(event) => {
                  setDisplayNameText(event.target.value);
                }}
              // required={true}
              />
              {/* <SelectField
                name={'attachment'}
                label={'Attachment Type'}
                placeholder={'Select an attachment type...'}
                onChange={(option: any) => {
                  setDisplayNameText(option);
                }}
                value={displayNameText}
                options={attachmentTypeOptions}
                isLoading={getAttachmentTypesStatus == 'loading'}
              /> */}
            </FormFieldWrapper>
          </PageDetails>
        </Modal>
      )}
    </React.Fragment>
  );
};

export const FileToUploadContainer = styled.div`
  display: flex;
  flex-direction: column;

  & > *:not(:last-child) {
    margin-bottom: 10px;
  }
`;

export const FileToUploadTitle = styled.div`
  ${font.medium};
`;

export const FileToUploadName = styled.div`
  ${font.size(15)};
  padding: 10px 0;
`;

const DropzoneContainer = styled.div<{ isDragActive: boolean }>`
  cursor: pointer;
  border: 2px dashed ${({theme}) => theme.border};
  transition: border-color 100ms ease-in-out;
  ${(props) =>
    props.isDragActive &&
    `
    border: 2px dashed ${props.theme.info};
  `}
`;

const getFileIcon = (data: AttachmentRow) => {
  switch (data?.fileExtension) {
    case '.docx':
    case '.doc':
      return 'file-word';
    case '.xls':
    case '.xlsx':
    case '.xlsb':
      return 'file-excel';
    case '.jpg':
    case '.jpeg':
    case '.png':
    case '.gif':
      return 'file-image';
    case '.pdf':
      return 'file-pdf';
    case '.ppt':
      return 'file-powerpoint';
    case '.mp4':
    case '.mov':
      return 'file-video';
    case '.csv':
      return 'file-spreadsheet';
    default:
      return 'file-alt';
  }
};

const DocumentTypeCellRenderer: FunctionComponent<any> = (props) => {
  const iconName = props.value == 'file' ? getFileIcon(props.data) : 'folder';
  const themeContext = useContext(ThemeContext);
  const iconColor =
    props.value == 'file'
      ? themeContext.textDarkest
      : mixin.lighten(themeContext.warning, 0.2);
  const iconType = props.value == 'file' ? 'fal' : 'fas';
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '25px',
      }}
    >
      <FontAwesomeIcon
        icon={[iconType, iconName]}
        fixedWidth={true}
        style={{ fontSize: '14px', color: iconColor }}
      />
    </div>
  );
};

const DocumentHeaderRenderer: FunctionComponent<any> = (props) => {
  return (
    <Tooltip content="Type">
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: '100%',
        }}
      >
        <FontAwesomeIcon
          icon={['fal', 'file']}
          fixedWidth={true}
          style={{ fontSize: '14px' }}
        />
      </div>
    </Tooltip>
  );
};

export default ContactAttachments;