import React, {
  MutableRefObject,
  useMemo,
} from 'react';
import { AgGridReactProps, AgGridReact } from 'ag-grid-react';
import { TableContext } from './SelectCellEditor';
import TableContextState from './TableContextState';
import { TableApi } from 'shared/hooks';
import styled from 'styled-components';
import Spinner from '../spinner/Spinner';
import { IconName } from '@fortawesome/pro-light-svg-icons';
import NoAccess from '../noAccess';
import { CsvExportParams, ExcelExportParams } from 'ag-grid-community';

export interface Identifiable {
  id: any;
}

interface TableProps<T extends Identifiable> extends AgGridReactProps {
  style?: React.CSSProperties;
  context?: TableContextState;
  tableRef?: MutableRefObject<TableApi | undefined>;
  isLoading?: boolean;
  noRowsOverlayTitle?: string;
  noRowsOverlaySubtitle?: string;
  noRowsOverlayAction?: () => void;
  noRowsOverlayActionTitle?: string;
  noRowsOverlayActionIcon?: IconName;
  noRowsOverlayIcon?: IconName;
  rowData: T[] | undefined;
  className?: string;
  children?: any;
  defaultCsvExportParams?: CsvExportParams;
  defaultExcelExportParams?: ExcelExportParams;
}

const defaultThemeClass = 'ag-theme-balham';

const AgGridWrapper = styled.div`
  ${({ theme }) => theme.isDark && `
    --ag-foreground-color: ${theme.textMedium}; // Text color
    --ag-background-color: ${theme.backgroundPrimary}; // Grid background
    --ag-header-foreground-color: ${theme.textMedium}; // Header text color
    --ag-header-background-color: ${theme.tableHeaderBackground}; // Header background color
    --ag-odd-row-background-color: ${theme.tableOddRowBackground}; // Odd row background color
    --ag-header-column-resize-handle-color: ${theme.tableBorder}; // Resize handle color
    --ag-row-hover-color: ${theme.backgroundMedium};
    --ag-border-color: ${theme.border};
    .ag-row-odd {
      border-bottom: 1px solid !important;
      border-bottom-color: ${theme.tableBorder} !important;
    };
    .ag-row-even {
      border-bottom: 1px solid !important;
      border-bottom-color: ${theme.tableBorder} !important;
    };
    .ag-status-bar {
      color: ${theme.textLightest};
    };
    .ag-body-viewport, .ag-body-horizontal-scroll-viewport {
      &::-webkit-scrollbar {
        width: 12px;
        height: 12px;
      };
      &::-webkit-scrollbar-track {
        background: ${theme.backgroundMedium};
      };
      &::-webkit-scrollbar-thumb {
        background-color: ${theme.textLight};
        border-radius: 6px;
        border: 1px solid ${theme.backgroundMedium};
      };
    };
    .ag-scroller-corner {
      background-color: ${theme.backgroundMedium};
    };
    .ag-menu {
      background-color: ${theme.backgroundPrimary} !important;
      color: ${theme.textDark} !important;
    }
    .ag-menu-option {
      background-color: ${theme.backgroundPrimary} !important;
      color: ${theme.textMedium} !important;

      &:hover {
        background-color: ${theme.backgroundLight} !important;
        color: ${theme.textDark} !important;
      }
    }
    .ag-header-cell-label .ag-sort-ascending-icon,
    .ag-header-cell-label .ag-sort-descending-icon {
      color: ${theme.textLight} !important;
    }
    .ag-header-cell-label .ag-sort-none-icon {
      color: ${theme.textMedium} !important;
    }
    .ag-icon {
      color: ${theme.primary} !important;
    }
    .ag-row-selected {
      background-color: ${theme.borderInputFocus} !important;
    }
    .ag-unselectable, .ag-column-drop, .ag-column-drop-horizontal {
      background-color: ${theme.backgroundPrimary}
    }
    .ag-column-drop-cell {
      background-color: ${theme.backgroundMedium};
      color: ${theme.textDark};
    };
    .ag-theme-balham .ag-dnd-ghost {
      background-color: ${(props: any) => props.theme.backgroundPrimary} !important;
      color: ${(props: any) => props.theme.textPrimary};
    }
    .ag-column-drop-empty-message, .ag-column-drop-horizontal-empty-message {
      color: ${theme.primary}
    }
    .ag-theme-balham .ag-popup-editor {
      background-color: ${theme.backgroundLight};
    }
    .ag-text-area-input-wrapper {
      border: none;
    }
    .ag-text-area-input {
      border: none !important;
      border-radius: 3px;
    }
  `}
`;

function Table<T extends Identifiable>({
  style,
  className,
  context = {},
  suppressDragLeaveHidesColumns = true,
  suppressMovableColumns = true,
  tableRef,
  isLoading,
  noRowsOverlayTitle = 'Nothing to see here...',
  noRowsOverlaySubtitle = 'No Data Available',
  noRowsOverlayIcon = 'box-open',
  noRowsOverlayAction,
  noRowsOverlayActionTitle,
  noRowsOverlayActionIcon,
  defaultCsvExportParams,
  defaultExcelExportParams,
  ...props
}: TableProps<T>) {

  const tableClassName = className
    ? `${defaultThemeClass} ${className}`
    : defaultThemeClass;

  const rowData = useMemo(() => {
    if (isNullOrEmpty(props.rowData) && isLoading) {
      return undefined;
    } else {
      return props.rowData || [];
    }
  }, [props.rowData, isLoading]);

  return (
    <TableContext.Provider value={context}>
      <div className={tableClassName} style={style}>
        <AgGridWrapper className={tableClassName} style={style}>
          <AgGridReact
            immutableData={true}
            defaultCsvExportParams={defaultCsvExportParams || undefined}
            defaultExcelExportParams={defaultExcelExportParams || undefined}
            suppressDragLeaveHidesColumns={suppressDragLeaveHidesColumns}
            suppressMovableColumns={suppressMovableColumns}
            suppressScrollOnNewData={true}
            getRowNodeId={(data) => data.id}
            statusBar={{
              statusPanels: [
                {
                  statusPanel: 'agTotalAndFilteredRowCountComponent',
                  align: 'right',
                },
                {
                  statusPanel: 'agTotalRowCountComponent',
                  align: 'right',
                },
                { statusPanel: 'agFilteredRowCountComponent' },
                { statusPanel: 'agSelectedRowCountComponent' },
                { statusPanel: 'agAggregationComponent' },
              ],
            }}
            {...props}
            rowData={rowData}
            frameworkComponents={{
              customLoadingOverlay: LoadingOverlay,
              noRowsOverlay: NoAccess,
              ...props.frameworkComponents,
            }}
            loadingOverlayComponent={'customLoadingOverlay'}
            noRowsOverlayComponent={'noRowsOverlay'}
            noRowsOverlayComponentParams={{
              title: noRowsOverlayTitle,
              subtitle: noRowsOverlaySubtitle,
              icon: noRowsOverlayIcon,
              action: noRowsOverlayAction,
              actionTitle: noRowsOverlayActionTitle,
              actionIcon: noRowsOverlayActionIcon,
            }}
          />
        </AgGridWrapper>
      </div>
    </TableContext.Provider>
  );
}

const LoadingOverlayWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${({ theme }) => theme.backgroundPrimary};
  z-index: 1;
`;

const LoadingOverlay = () => {
  return (
    <LoadingOverlayWrapper>
      <Spinner />
    </LoadingOverlayWrapper>
  );
};

const isNullOrEmpty = (arr?: any[] | null) => {
  return arr == undefined || arr == null || arr?.length == 0
}

export default Table;
