import React, {
  useMemo,
  useCallback,
  useState,
} from 'react';
import { useRouteMatch, useHistory, useParams } from 'react-router-dom';
import { useTable, usePageTitle, useBreadcrumb } from 'shared/hooks/';
import { useRemainingHeight } from 'shared/hooks';
import { useDeleteContact, useGetPipelineContacts } from 'shared/hooks/api';
import {
  PageDetails,
  Table,
  PageToolbar,
  ToolbarButton,
  ToolbarSearchInput,
  SortMenuButton,
  GroupMenuButton,
  Dialog,
  ToolbarMenu,
  DropdownItem,
  MenuSeparator,
} from 'shared/components';
import { createQueryParamModalHelpers } from 'shared/utils/queryParamModal';
import { CellClickedEvent, ColDef } from 'ag-grid-community';
import NewContactModal from 'App/Organization/Deal/Contacts/modals/NewContactModal';
import { useNotification } from 'shared/components/notifications';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { lightTheme } from 'shared/utils/styles';

type ContactRow = {
  id: number;
  name: string;
  title?: string | null;
  firmName?: string;
  cellPhone?: string | null;
  email?: string | null;
};

const PipelineContacts = () => {
  const { orgName, pipelineId } = useParams<{
    orgName: string;
    pipelineId: string;
  }>();
  const newContactModalHelpers = createQueryParamModalHelpers('new-contact');
  const { ref, height } = useRemainingHeight();
  usePageTitle('Contacts');

  const {
    data,
    error,
    errors,
    isFetching,
    refetch,
    status,
  } = useGetPipelineContacts(Number(pipelineId));

  const [deleteContact, { status: deleteContactStatus }] = useDeleteContact();

  const notify = useNotification();

  const match = useRouteMatch();
  const history = useHistory();

  useBreadcrumb(
    {
      title: 'Contacts',
      path: match.url,
      key: 'contacts',
      index: 3,
    },
    []
  );

  const {
    searchText,
    setSearchText,
    sortModel,
    applyColumnState,
    groupedColumns,
    setGroupedColumns,
    gridColumns,
    gridEvents,
    tableRef,
  } = useTable({ autoSizeColumns: true });

  const contacts = useMemo(() => {
    if (data?.pipeline?.contacts) {
      const rows: ContactRow[] = data.pipeline.contacts.map((contact) => ({
        id: contact.id,
        name: `${contact.firstName} ${contact.lastName}`,
        title: contact.title,
        firmName: contact.firm?.name,
        cellPhone: contact.cellPhone,
        email: contact.email,
      }));
      return rows;
    }
  }, [data]);

  const [selectedContacts, setSelectedContacts] = useState<ContactRow[]>([]);

  const onRowSelected = useCallback(() => {
    const selectedRows = tableRef.current?.gridApi.getSelectedRows();
    if (selectedRows) {
      setSelectedContacts(selectedRows);
    }
  }, [tableRef, setSelectedContacts]);

  const [isShowingRemoveDialog, setIsShowingRemoveDialog] = useState(false);

  const [columnDefs, setColumnDefs] = useState<ColDef[]>([
    {
      maxWidth: 40,
      checkboxSelection: true,
      pinned: 'left',
      headerCheckboxSelection: true,
      headerCheckboxSelectionFilteredOnly: true
    },
    {
      headerName: 'ID',
      field: 'id',
      hide: true
    },
    {
      headerName: 'Name',
      field: 'name',
      onCellClicked: (params: CellClickedEvent) =>
        history.push(
          `/${orgName}/address-books/${pipelineId}/contact-details/${params.data.id}/details`
        ),
      cellStyle: { cursor: 'pointer' }
    },
    {
      headerName: 'Title',
      field: 'title'
    },
    {
      headerName: 'Firm',
      field: 'firmName'
    },
    {
      headerName: 'Email',
      field: 'email'
    },
    {
      headerName: 'Cell Phone',
      field: 'cellPhone'
    }
  ]);

  return (
    <React.Fragment>
      <PageToolbar
        left={
          <React.Fragment>
            <ToolbarMenu
              title={'Actions...'}
              icon={'clipboard-list'}
              variant={'outline'}
              anchor={'left'}
              disabled={selectedContacts.length === 0}
            >
              <DropdownItem
                icon={
                  <FontAwesomeIcon
                    icon={['fal', 'user-slash']}
                    color={lightTheme.danger}
                  />
                }
                onClick={() => setIsShowingRemoveDialog(true)}
              >
                Delete Selected Contact...
              </DropdownItem>
            </ToolbarMenu>
            <ToolbarButton
              icon={'user-plus'}
              onClick={newContactModalHelpers.open}
            >
              Create Contact
            </ToolbarButton>
            <ToolbarSearchInput
              placeholder={'Search...'}
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
            />
          </React.Fragment>
        }
        right={
          <React.Fragment>
            <SortMenuButton
              sortModel={sortModel}
              applyColumnState={applyColumnState}
              gridColumns={gridColumns}
            />
            <GroupMenuButton
              groupedColumns={groupedColumns}
              setGroupedColumns={setGroupedColumns}
              gridColumns={gridColumns}
            />
            <ToolbarButton
              icon={'sync'}
              onClick={() => refetch()}
              isWorking={isFetching}
            >
              Refresh
            </ToolbarButton>
          </React.Fragment>
        }
        mobileLeft={
          <React.Fragment>
            <ToolbarMenu
              title={'Actions...'}
              icon={'clipboard-list'}
              variant={'outline'}
              anchor={'left'}
            >
              <DropdownItem
                icon={'user-plus'}
                onClick={newContactModalHelpers.open}
              >
                Create Contact
              </DropdownItem>
              <MenuSeparator />
              <DropdownItem
                icon={
                  <FontAwesomeIcon
                    icon={['fal', 'user-slash']}
                    color={lightTheme.danger}
                  />
                }
                onClick={() => setIsShowingRemoveDialog(true)}
                disabled={selectedContacts.length === 0}
              >
                Delete Selected Contact...
              </DropdownItem>
            </ToolbarMenu>
            <ToolbarSearchInput
              placeholder={'Search...'}
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
            />
          </React.Fragment>
        }
        mobileRight={
          <React.Fragment>
            <ToolbarButton
              icon={'sync'}
              onClick={() => refetch()}
              isWorking={isFetching}
            >
              Refresh
            </ToolbarButton>
          </React.Fragment>
        }
      />
      <PageDetails ref={ref}>
        <Table
          rowData={contacts}
          columnDefs={columnDefs}
          defaultColDef={{
            sortable: true,
            enableRowGroup: true,
            suppressMovable: true,
            lockPinned: true,
          }}
          quickFilterText={searchText}
          groupUseEntireRow={true}
          {...gridEvents}
          style={{ height: height, width: '100%' }}
          rowSelection={'single'}
          onSelectionChanged={onRowSelected}
          suppressRowClickSelection={true}
          noRowsOverlayIcon={'address-book'}
          noRowsOverlayTitle={'No Assigned Contacts'}
          noRowsOverlaySubtitle={
            'No contacts have been assigned to this deal yet.'
          }
          isLoading={status == 'loading'}
          rowGroupPanelShow={'always'}
        >
        </Table>
      </PageDetails>
      {/* <Route
        path={`${match.path}/:contactId`}
        render={() => (
          <ContactInfo
            onClose={() => history.push(match.url)}
            onUpdate={refetch}
          />
        )}
      /> */}
      {newContactModalHelpers.isOpen() && (
        <NewContactModal
          onClose={newContactModalHelpers.close}
          onSuccess={() => refetch()}
          pipelineId={Number(pipelineId)}
        />
      )}
      {isShowingRemoveDialog && selectedContacts.length > 0 && (
        <Dialog
          title={'Delete Contact'}
          message={`Are you sure you want to delete the contact named "${selectedContacts[0].name}"?`}
          confirmTitle={`Yes, delete contact`}
          variant={'danger'}
          isOpen={true}
          onClose={() => setIsShowingRemoveDialog(false)}
          onCancel={() => setIsShowingRemoveDialog(false)}
          isWorking={deleteContactStatus == 'loading'}
          onConfirm={async () => {
            try {
              const result = await deleteContact(selectedContacts[0].id);
              if (result.errors) {
                notify({
                  message: 'An error occurred while deleting this contact.',
                  variant: 'danger',
                  duration: 5000,
                });
              } else {
                switch (result.data?.deleteContact?.__typename) {
                  case 'Contact':
                    notify({
                      message: 'Contact successfully deleted',
                    });
                    break;
                  case 'ErrorResult':
                    notify({
                      duration: null,
                      variant: 'danger',
                      title: 'Unable to delete contact',
                      message: result.data.deleteContact.message,
                    });
                    break;
                }
              }
            } catch {
              notify({
                duration: 5000,
                title: 'Uh-oh',
                message: 'An error occurred while deleting this contact.',
                variant: 'danger',
              });
            }
            setIsShowingRemoveDialog(false);
            setSelectedContacts([]);
          }}
        />
      )}
    </React.Fragment>
  );
};

export default PipelineContacts;
