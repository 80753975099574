import {
  GridApi,
  ColumnApi,
  GridReadyEvent,
  SortChangedEvent,
  GridColumnsChangedEvent,
  Column,
  ColumnRowGroupChangedEvent,
  RowDataChangedEvent,
  RowDataUpdatedEvent,
  FilterChangedEvent,
  FirstDataRenderedEvent,
  GridSizeChangedEvent,
} from 'ag-grid-community';
import {
  useRef,
  useState,
  useCallback,
  MutableRefObject,
  Dispatch,
  SetStateAction,
} from 'react';

export interface SortModel {
  colId: string;
  sort: string | 'asc' | 'desc';
}

export interface FilterModel {
  [key: string]: any;
}

export interface TableApi {
  gridApi: GridApi;
  columnApi: ColumnApi;
}

interface UseTableProps {
  onGridReady?: (params: GridReadyEvent) => void;
  onSortChanged?: (params: SortChangedEvent) => void;
  onFilterChanged?: (params: FilterChangedEvent) => void;
  onGridColumnsChanged?: (params: GridColumnsChangedEvent) => void;
  autoSizeColumns?: boolean;
  sizeColumnsToFit?: boolean;
  initialSortModel?: SortModel[];
  initialFilterModel?: FilterModel;
  initialGroupedColumns?: string[];
}

interface TableGridEvents {
  onGridReady: (params: GridReadyEvent) => void;
  onFirstDataRendered: (params: FirstDataRenderedEvent) => void;
  onSortChanged: (params: SortChangedEvent) => void;
  onFilterChanged: (params: FilterChangedEvent) => void;
  onFilterModified: (params: FilterChangedEvent) => void;
  onGridColumnsChanged: (params: GridColumnsChangedEvent) => void;
  onColumnRowGroupChanged: (params: ColumnRowGroupChangedEvent) => void;
  onRowDataChanged: (params: RowDataChangedEvent | RowDataUpdatedEvent) => void;
  onRowDataUpdated: (params: RowDataChangedEvent | RowDataUpdatedEvent) => void;
  onRowGroupOpened: (params: any) => void;
  onRowGroupClosed: (params: any) => void;
  onGridSizeChanged: (params: GridSizeChangedEvent) => void;
}

export interface UseTableResult {
  tableRef: MutableRefObject<TableApi | undefined>;
  searchText: string;
  setSearchText: Dispatch<SetStateAction<string>>;
  sortModel: SortModel[];
  applyColumnState: (sortModel: SortModel[]) => void;
  filterModel: FilterModel;
  setFilterModel: (filterModel: FilterModel) => void;
  groupedColumns: string[];
  setGroupedColumns: (groupedColumns: string[]) => void;
  gridColumns: Column[];
  gridEvents: TableGridEvents;
  resizeColumns: () => void;
}

export const useTable = ({
  onGridReady,
  onSortChanged,
  onFilterChanged,
  onGridColumnsChanged,
  autoSizeColumns = true,
  sizeColumnsToFit = false,
  initialSortModel,
  initialFilterModel,
  initialGroupedColumns,
}: UseTableProps = {}): UseTableResult => {
  const tableRef = useRef<TableApi>();
  const [searchText, setSearchText] = useState('');
  const [sortModel, setLocalSortModel] = useState<SortModel[]>([]);
  const [filterModel, setLocalFilterModel] = useState<FilterModel>({});
  const [groupedColumns, setLocalGroupedColumns] = useState<string[]>([]);
  const [gridColumns, setGridColumns] = useState<Column[]>([]);

  const automaticallySizeColumns = (columnApi: ColumnApi) => {
    const allColumnIds: string[] = [];
    setTimeout(() => {
      columnApi.getAllColumns()?.forEach(function (column: Column) {
        allColumnIds.push(column.getColId());
      });
      columnApi.autoSizeColumns(allColumnIds, false);
    }, 0);
  };

  const automaticallySizeColumnsToFit = (gridApi: GridApi) => {
    setTimeout(() => {
      gridApi.sizeColumnsToFit();
    }, 0);
  };

  const applyColumnState = useCallback(
    (newSortModel: SortModel[]) => {
      if (tableRef.current?.columnApi) {
        const columnState = newSortModel.map((sm) => ({
          colId: sm.colId,
          sort: sm.sort,
        }));
        tableRef.current.columnApi.applyColumnState({
          state: columnState,
          defaultState: { sort: null },
        });
      }
    },
    [tableRef]
  );  

  const setFilterModel = useCallback(
    (filterModel: FilterModel) => {
      setTimeout(() => {
        return tableRef.current?.gridApi.setFilterModel(filterModel);
      }, 0);
    },
    [tableRef]
  );

  const setGroupedColumns = useCallback(
    (groupedColumns: string[]) => {
      setTimeout(() => {
        return tableRef.current?.columnApi.setRowGroupColumns(groupedColumns);
      }, 0);
    },
    [tableRef]
  );

  const onGridReadyWrapper = useCallback(
    (params: GridReadyEvent) => {
      tableRef.current = {
        gridApi: params.api,
        columnApi: params.columnApi,
      };
      setTimeout(() => {
        if (onGridReady) onGridReady(params);
        if (autoSizeColumns) automaticallySizeColumns(params.columnApi);
        if (sizeColumnsToFit) automaticallySizeColumnsToFit(params.api);
      }, 0);
    },
    [
      onGridReady,
      initialGroupedColumns,
      initialFilterModel,
      initialSortModel,
      applyColumnState,
      setFilterModel,
      setGroupedColumns,
    ]
  );

  const onFirstDataRenderedWrapper = useCallback(
    (params: FirstDataRenderedEvent) => {
      setTimeout(() => {
        if (initialSortModel) applyColumnState(initialSortModel);
        if (initialFilterModel) setFilterModel(initialFilterModel);
        if (initialGroupedColumns) setGroupedColumns(initialGroupedColumns);
      }, 0);
    },
    [
      onGridReady,
      initialGroupedColumns,
      initialFilterModel,
      initialSortModel,
      applyColumnState,
      setFilterModel,
      setGroupedColumns,
    ]
  );

  const onRowDataChangedWrapper = (
    params: RowDataChangedEvent | RowDataUpdatedEvent
  ) => {
    setTimeout(() => {
      const model = params.api.getModel();
      if (model.getRowCount() != 0 && autoSizeColumns) {
        automaticallySizeColumns(params.columnApi);
      }
      if (model.getRowCount() != 0 && sizeColumnsToFit) {
        automaticallySizeColumnsToFit(params.api);
      }
    }, 0);
  };

  const onSortChangedWrapper = useCallback(
    (params: SortChangedEvent) => {
      const currentColumnState = params.columnApi.getColumnState();
      const newSortModel: SortModel[] = currentColumnState
        .filter((state) => state.sort)
        .map((state) => ({
          colId: state.colId,
          sort: state.sort,
        }));
      setLocalSortModel(newSortModel);
    },
    [setLocalSortModel]
  );

  const onFilterChangedWrapper = useCallback(
    (params: FilterChangedEvent) => {
      setTimeout(() => {
        const filterModel = params.api.getFilterModel();
        setLocalFilterModel(filterModel);
        if (onFilterChanged) onFilterChanged(params);
        if (autoSizeColumns) automaticallySizeColumns(params.columnApi);
        if (sizeColumnsToFit) automaticallySizeColumnsToFit(params.api);
      }, 0);
    },
    [setLocalSortModel]
  );

  const onGridColumnsChangedWrapper = useCallback(
    (params: GridColumnsChangedEvent) => {
      setTimeout(() => {
        const columns = params.columnApi.getAllColumns() ?? [];
        setGridColumns(columns);
        if (onGridColumnsChanged) onGridColumnsChanged(params);
        if (autoSizeColumns) automaticallySizeColumns(params.columnApi);
        if (sizeColumnsToFit) automaticallySizeColumnsToFit(params.api);
      }, 0);
    },
    [setGridColumns]
  );

  const onColumnRowGroupChangedWrapper = useCallback(
    (params: ColumnRowGroupChangedEvent) => {
      setTimeout(() => {
        setLocalGroupedColumns(
          params.columnApi.getRowGroupColumns().map((col) => col.getColId())
        );
        if (autoSizeColumns) automaticallySizeColumns(params.columnApi);
        if (sizeColumnsToFit) automaticallySizeColumnsToFit(params.api);
      }, 0);
    },
    [setLocalGroupedColumns]
  );

  const onRowGroupOpenedOrClosed = useCallback((params) => {
    setTimeout(() => {
      if (autoSizeColumns) automaticallySizeColumns(params.columnApi);
      if (sizeColumnsToFit) automaticallySizeColumnsToFit(params.api);
    }, 0);
  }, []);

  const resizeColumns = useCallback(() => {
    setTimeout(() => {
      if (tableRef.current && autoSizeColumns) {
        automaticallySizeColumns(tableRef.current.columnApi);
      }
    }, 0);
  }, [tableRef, autoSizeColumns]);

  const onGridSizeChangedEvent = useCallback(
    (params: GridSizeChangedEvent) => {
      setTimeout(() => {
        if (autoSizeColumns) automaticallySizeColumns(params.columnApi);
        if (sizeColumnsToFit) automaticallySizeColumnsToFit(params.api);
      }, 0);
    },
    [autoSizeColumns, sizeColumnsToFit]
  );

  return {
    tableRef,
    searchText,
    setSearchText,
    sortModel,
    applyColumnState,
    filterModel,
    setFilterModel,
    groupedColumns,
    setGroupedColumns,
    gridColumns,
    gridEvents: {
      onGridReady: onGridReadyWrapper,
      onFirstDataRendered: onFirstDataRenderedWrapper,
      onSortChanged: onSortChangedWrapper,
      onFilterChanged: onFilterChangedWrapper,
      onFilterModified: onFilterChangedWrapper,
      onGridColumnsChanged: onGridColumnsChangedWrapper,
      onColumnRowGroupChanged: onColumnRowGroupChangedWrapper,
      onRowDataChanged: onRowDataChangedWrapper,
      onRowDataUpdated: onRowDataChangedWrapper,
      onRowGroupOpened: onRowGroupOpenedOrClosed,
      onRowGroupClosed: onRowGroupOpenedOrClosed,
      onGridSizeChanged: onGridSizeChangedEvent,
    },
    resizeColumns,
  };
};
